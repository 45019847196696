import React, { useEffect } from 'react';

// Components
import { Label } from 'reactstrap';
import TextField from '../common/TextField';

// Context
import { useLang } from '../../context/LangContext';

// Router
import { Link, useHistory } from 'react-router-dom';
import * as routes from '../../router/config/routes';

// Redux
import {
    removePartyFromTransaction,
    invitePartyToTransaction
} from '../../store/actions/Transactions';
import { connect } from 'react-redux';

const PartiesFields = ({
    form,
    parties,
    transactionId,
    removePartyFromTransaction,
    invitePartyToTransaction,
    setShouldConfirmDisplayed,
    transactionDetails
}) => {
    const history = useHistory();
    const {
        first_name_label,
        last_name_label,
        full_name_label,
        view_connection_label,
        view_connection_description,
        add_contact,
        add_party,
        remove_label,
        invite_label,
        reinvite_label
    } = useLang()['TransactionDetails']['EditTransactionDetails'];

    useEffect(() => {
        setShouldConfirmDisplayed(form.dirty);
    }, [form.dirty, setShouldConfirmDisplayed]);

    const addPartyHandler = (_, pipelineContactInfo) => {
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.ADD_PARTY}/${transactionId}`,
            state: {
                transactionId,
                pipelineContactInfo,
                parties: parties.map(({ email }) => email)
            }
        });
    };

    return (
        <>
            {form.values.parties?.map(
                (
                    { client_first_name, client_last_name, client_full_name, id },
                    index
                ) => {
                    const currentParty = parties.find(item => item.id === id);
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="position-relative">
                            <div className="row mt-4">
                                <div className="col-4 mb-2 offset-2">
                                    <Label className="form-label">
                                        {first_name_label}
                                    </Label>

                                    <TextField
                                        type="text"
                                        myStyle={{
                                            flexGrow: 1
                                        }}
                                        required
                                        className="form-control"
                                        name={`parties.${index}.client_first_name`}
                                        id={`parties.${index}.client_first_name`}
                                        value={client_first_name}
                                        error={
                                            form.errors.parties &&
                                            form.errors.parties[index]?.client_first_name
                                        }
                                        onChange={e => {
                                            form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        invalid={
                                            form.errors.parties &&
                                            form.errors.parties[index]
                                                ?.client_first_name &&
                                            form.touched.parties &&
                                            form.touched.parties[index]?.client_first_name
                                        }
                                        hasDefaultFill
                                    />
                                </div>
                                <div className="col-4 mb-2">
                                    <Label className="form-label">
                                        {last_name_label}
                                    </Label>

                                    <TextField
                                        type="text"
                                        myStyle={{
                                            flexGrow: 1
                                        }}
                                        required
                                        className="form-control"
                                        name={`parties.${index}.client_last_name`}
                                        id={`parties.${index}.client_last_name`}
                                        value={client_last_name}
                                        error={
                                            form.errors.parties &&
                                            form.errors.parties[index]?.client_last_name
                                        }
                                        onChange={e => {
                                            form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        invalid={
                                            form.errors.parties &&
                                            form.errors.parties[index]
                                                ?.client_last_name &&
                                            form.touched.parties &&
                                            form.touched.parties[index]?.client_last_name
                                        }
                                        hasDefaultFill
                                    />
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-4 mb-2 offset-2">
                                    <Label className="form-label">
                                        {full_name_label}
                                    </Label>

                                    <TextField
                                        type="text"
                                        myStyle={{
                                            flexGrow: 1
                                        }}
                                        required
                                        className="form-control"
                                        name={`parties.${index}.client_full_name`}
                                        id={`parties.${index}.client_full_name`}
                                        value={client_full_name}
                                        error={
                                            form.errors.parties &&
                                            form.errors.parties[index]?.client_full_name
                                        }
                                        onChange={e => {
                                            form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        invalid={
                                            form.errors.parties &&
                                            form.errors.parties[index]
                                                ?.client_full_name &&
                                            form.touched.parties &&
                                            form.touched.parties[index]?.client_full_name
                                        }
                                        hasDefaultFill
                                    />
                                </div>
                                <div className="col-4 mb-2 d-flex align-items-end pointer">
                                    {!currentParty?.pipeline ? (
                                        <Link
                                            to={{
                                                pathname: `${routes.AUTHENTICATED}${routes.CONNECTION_DETAIL}/${id}`,
                                                state: { id }
                                            }}
                                            className="text-primary"
                                        >
                                            {view_connection_label}
                                            <p
                                                style={{
                                                    fontSize: '13px',
                                                    lineHeight: '0.6'
                                                }}
                                            >
                                                {view_connection_description}
                                            </p>
                                        </Link>
                                    ) : (
                                        <div
                                            className="text-primary mb-3"
                                            onClick={e =>
                                                addPartyHandler(e, currentParty)
                                            }
                                        >
                                            {add_contact}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {form.values.parties.length > 1 && (
                                <button
                                    className="btn btn-link text-danger transition-3d-hover position-absolute pointer d-flex align-items-center"
                                    style={{ top: '15%', right: '7%' }}
                                    onClick={() =>
                                        removePartyFromTransaction({
                                            transactionId,
                                            userId: id
                                        })
                                    }
                                >
                                    <i className="fa-solid fa-xmark mr-1" />
                                    <span>{remove_label}</span>
                                </button>
                            )}
                            {!currentParty?.pipeline &&
                                !transactionDetails?.access?.includes(id) && (
                                    <button
                                        className="btn btn-link text-primary transition-3d-hover position-absolute pointer d-flex align-items-center"
                                        style={{ bottom: '15%', right: '7%' }}
                                        onClick={() =>
                                            invitePartyToTransaction({
                                                transactionId,
                                                parties: [currentParty]
                                            })
                                        }
                                    >
                                        {transactionDetails?.invited?.includes(id)
                                            ? reinvite_label
                                            : invite_label}
                                    </button>
                                )}
                        </div>
                    );
                }
            )}
            {form.values.parties?.length < 4 && (
                <button
                    className="btn btn-link pointer offset-2 mt-4"
                    onClick={addPartyHandler}
                >
                    <span className="fas fa-add" />
                    {add_party}
                </button>
            )}
        </>
    );
};

const mapStateToProps = ({ transactions }) => {
    const { transactionDetails } = transactions;
    return {
        transactionDetails
    };
};

export default connect(mapStateToProps, {
    removePartyFromTransaction,
    invitePartyToTransaction
})(PartiesFields);
