//React
import React, { useState, useMemo, useEffect } from 'react';
import { ModalFooter, Button, Modal, ModalBody } from 'reactstrap';

//Components
import TabNavigation from './../TransactionDetails/TabNavigation';
import MembersListModal from '../TransactionDetails/MembersListModal';
import ConnectionsSearchFilter from './../Connections/ConnectionsSearchFilter';

//Hooks
import { useUserAvatars } from './../../hooks/useUserAvatars';
import { useLang } from '../../context/LangContext';

const Tabs = {
    tab_team: 'My Team',
    tab_office: 'Office'
};
const AddMembersToTrxModal = ({
    updateTransactionMembers,
    isModalOpen,
    toggleIsModaOpen,
    teamMembers,
    trxTeam,
    companyContacts,
    trxOwnerId
}) => {
    const { tab_office_label, tab_team_label, btn_save_label, search_placeholder } =
        useLang()['TransactionDetails']['TransactionMeta'];
    const [tabContent, setTabContent] = useState(Tabs.tab_team);
    const [imgByIds, setImgByIds] = useState([]);
    const [checked, setChecked] = useState([]);
    const [searchSuggestions, settingSearchSuggestions] = useState(null);

    const teamList = Object.values(teamMembers?.members || []).filter(
        member => member.id !== trxOwnerId
    );

    const companyList = companyContacts.filter(
        el =>
            !teamList.some(item => item.id === el.id) && el.id !== trxOwnerId && el.active
    );

    const sortedMembers = useMemo(() => {
        const members = tabContent === Tabs.tab_office ? companyList : teamList;
        return members && members.length
            ? members.sort((a, b) => {
                  if (a.last_name && a.first_name && b.last_name && b.first_name) {
                      const result = a.last_name.localeCompare(b.last_name);
                      return result !== 0
                          ? result
                          : a.first_name.localeCompare(b.first_name);
                  } else if (!a.last_name || !a.first_name) {
                      return 1;
                  } else if (!b.last_name || !b.first_name) {
                      return -1;
                  }
                  return true;
              })
            : [];
    }, [companyList, teamList, tabContent]);

    const ids = useMemo(
        () => companyContacts && companyContacts.map(member => member.id),
        [companyContacts]
    );

    useEffect(() => {
        settingSearchSuggestions(null);
    }, [tabContent]);

    useEffect(() => {
        if (trxTeam) setChecked(trxTeam);
    }, [trxTeam]);

    useUserAvatars(ids, setImgByIds);

    const tabs = [
        {
            name: tab_team_label,
            value: Tabs.tab_team,
            id: 'my-team-tab',
            role: 'tab',
            ariaControls: 'my-team'
        },
        {
            name: tab_office_label,
            value: Tabs.tab_office,
            id: 'office-tab',
            role: 'tab',
            ariaControls: 'office'
        }
    ];

    const isButtonDisabled = useMemo(
        () =>
            trxTeam.every(member => checked.some(el => el.id === member.id)) &&
            checked.every(member => trxTeam.some(el => el.id === member.id)),
        [trxTeam, checked]
    );

    const handleItemClick = member => {
        setChecked(prev => {
            if (prev.some(el => el.id === member.id)) {
                return prev.filter(el => el.id !== member.id);
            } else {
                return [...prev, member];
            }
        });
    };

    const handleMembersAdd = () => {
        updateTransactionMembers({ members: checked });
        toggleIsModaOpen();
    };

    return (
        <div>
            <Modal
                isOpen={isModalOpen}
                toggle={toggleIsModaOpen}
                contentClassName="add-members-modal"
                className="add-members-modal"
            >
                <TabNavigation
                    navClassName="px-5"
                    tabClassName="p-2"
                    onClick={setTabContent}
                    selectedTab={tabContent}
                    tabs={tabs}
                />
                <ModalBody>
                    {tabContent === Tabs.tab_office && (
                        <ConnectionsSearchFilter
                            settingSearchSuggestions={settingSearchSuggestions}
                            connections={sortedMembers}
                            isModal={true}
                            placeholder={search_placeholder}
                        />
                    )}
                    <MembersListModal
                        membersList={sortedMembers}
                        imgByIds={imgByIds}
                        checked={checked}
                        handleItemClick={handleItemClick}
                        searchSuggestions={
                            tabContent === Tabs.tab_office ? searchSuggestions : undefined
                        }
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={isButtonDisabled}
                        onClick={handleMembersAdd}
                    >
                        {btn_save_label}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AddMembersToTrxModal;
