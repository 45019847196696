import React from 'react';
import { useLang } from '../../../context/LangContext';

export const MembersForm = ({ team, member, userData, handleRemove, isRemoved }) => {
    const { invitationStatuses } = useLang()['Settings']['Team'];
    return (
        <div className="w-100 row d-flex align-items-center justify-content-between">
            <div className={member.invite_id ? 'text-success' : ''}>
                {member.invite_id ? invitationStatuses.pending : member?.role}
            </div>

            {member.id !== team.lead_id && userData.id === team.lead_id ? (
                <i
                    className="fa-regular fa-xmark removePhoneIcon pointer"
                    style={{ color: isRemoved && '#de4437' }}
                    onClick={() => {
                        handleRemove(member);
                    }}
                />
            ) : (
                <i className="fa-solid fa-lock removePhoneIcon" />
            )}
        </div>
    );
};
