import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';

// Components
import { Label } from 'reactstrap';
import { TextField, DatePicker, AddressSuggestion } from '..';

// Context
import { useLang } from '../../context/LangContext';

// Constants
import { mappingKeys } from '../../utils/Constants';

const hiddenFields = [
    mappingKeys.all_buyers,
    mappingKeys.all_sellers,

    mappingKeys.buyer_address_1,
    mappingKeys.buyer_address_2,
    mappingKeys.buyer_address_city,
    mappingKeys.buyer_address_state,
    mappingKeys.buyer_address_zip,
    mappingKeys.buyer_1_signature,
    mappingKeys.buyer_2_signature,
    mappingKeys.buyer_3_signature,
    mappingKeys.buyer_4_signature,
    mappingKeys.buyer_agent_signature_1,
    mappingKeys.buyer_agent_signature_2,
    mappingKeys.buyer_broker_signature_1,
    mappingKeys.buyer_broker_signature_2,
    mappingKeys.buyer_brokerage_address_1,
    mappingKeys.buyer_brokerage_address_2,
    mappingKeys.buyer_brokerage_address_city,
    mappingKeys.buyer_brokerage_address_state,
    mappingKeys.buyer_brokerage_address_zip,
    mappingKeys.buyer_closing_rep_address_1,
    mappingKeys.buyer_closing_rep_address_2,
    mappingKeys.buyer_closing_rep_city,
    mappingKeys.buyer_closing_rep_state,
    mappingKeys.buyer_closing_rep_zip,

    mappingKeys.seller_address_1,
    mappingKeys.seller_address_2,
    mappingKeys.seller_address_city,
    mappingKeys.seller_address_state,
    mappingKeys.seller_address_zip,
    mappingKeys.seller_1_signature,
    mappingKeys.seller_2_signature,
    mappingKeys.seller_3_signature,
    mappingKeys.seller_4_signature,
    mappingKeys.seller_agent_signature_1,
    mappingKeys.seller_agent_signature_2,
    mappingKeys.seller_broker_signature_1,
    mappingKeys.seller_broker_signature_2,
    mappingKeys.seller_brokerage_address_1,
    mappingKeys.seller_brokerage_address_2,
    mappingKeys.seller_brokerage_address_city,
    mappingKeys.seller_brokerage_address_state,
    mappingKeys.seller_brokerage_address_zip,
    mappingKeys.seller_closing_rep_address_1,
    mappingKeys.seller_closing_rep_address_2,
    mappingKeys.seller_closing_rep_city,
    mappingKeys.seller_closing_rep_state,
    mappingKeys.seller_closing_rep_zip,

    mappingKeys.property_address_1,
    mappingKeys.property_address_2,
    mappingKeys.property_address_city,
    mappingKeys.property_address_state,
    mappingKeys.property_address_zip
];

const repietedFields = [
    mappingKeys.property_address,
    mappingKeys.closing_date,
    // TODO: continue with it after Marc will add field
    mappingKeys.listing_price, // editable by seller only
    mappingKeys.purchase_price, // gets set after offer has been accepted and it based on the offer_price
    mappingKeys.offer_price // editable by both seller and buyer
];

const byuerFields = [
    mappingKeys.buyer_1,
    mappingKeys.buyer_2,
    mappingKeys.buyer_3,
    mappingKeys.buyer_4
];

const sellerFields = [
    mappingKeys.seller_1,
    mappingKeys.seller_2,
    mappingKeys.seller_3,
    mappingKeys.seller_4
];

const EditMappingsFormContent = ({
    values,
    dirty,
    handleBlur,
    handleChange,
    setShouldConfirmDisplayed,
    setFieldValue,
    transactionDetails
}) => {
    const fieldLabels = useLang()['TransactionDetails']['EditMappingData'];
    const { locale } = useLang()['DateTime'];
    const disabledFields = useMemo(
        () => [
            ...repietedFields,
            ...(transactionDetails.type === 'seller' ? sellerFields : byuerFields)
        ],
        [transactionDetails.type]
    );

    useEffect(() => {
        setShouldConfirmDisplayed(dirty);
    }, [dirty, setShouldConfirmDisplayed]);

    return (
        <div className="row">
            {Object.entries(values.mappings)
                .sort(([a], [b]) => (a < b ? -1 : 1))
                .filter(([fieldName]) => !hiddenFields.includes(fieldName))
                .map(([fieldName, item], inputIndex) => {
                    const isFieldDisabled = disabledFields.includes(fieldName);
                    return (
                        <div
                            key={fieldName}
                            className={cx('col-4 mb-2 js-focus-state', {
                                'offset-2': !(inputIndex % 2)
                            })}
                        >
                            <Label className="form-label" htmlFor={fieldName}>
                                {fieldLabels[fieldName]}
                            </Label>
                            {[
                                mappingKeys.closing_date,
                                mappingKeys.listing_start_date,
                                mappingKeys.listing_expiration_date,
                                mappingKeys.offer_date
                            ].includes(fieldName) ? (
                                <DatePicker
                                    myStyle={{ flexGrow: 1 }}
                                    className="js-range-datepicker form-control bg-white rounded-right"
                                    name={`mappings.${fieldName}`}
                                    id={fieldName}
                                    value={item?.toDate ? item.toDate() : item || ''}
                                    onBlur={handleBlur}
                                    hasDefaultFill
                                    options={{
                                        dateFormat: 'm-d-Y',
                                        disableMobile: 'true',
                                        onChange([date]) {
                                            setFieldValue(`mappings.${fieldName}`, date);
                                            if (mappingKeys.closing_date === fieldName)
                                                setFieldValue(`closing_date`, date);
                                        },
                                        locale: locale
                                    }}
                                />
                            ) : [
                                  mappingKeys.buyer_address,
                                  mappingKeys.buyer_brokerage_address,
                                  mappingKeys.buyer_closing_rep_address,
                                  mappingKeys.seller_address,
                                  mappingKeys.seller_brokerage_address,
                                  mappingKeys.seller_closing_rep_address
                              ].includes(fieldName) ? (
                                <AddressSuggestion
                                    value={item || ''}
                                    fieldName={`mappings.${fieldName}`}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    fieldNamePrefix={fieldName.replace('_address', '')}
                                    componentName={'EditMappingsFormContent'}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    myStyle={{ flexGrow: 1 }}
                                    className="form-control"
                                    name={`mappings.${fieldName}`}
                                    id={fieldName}
                                    value={item || ''}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    hasDefaultFill
                                    disabled={isFieldDisabled}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default EditMappingsFormContent;
