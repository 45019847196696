// Firebase
import { useEffect, useMemo } from 'react';
import { storage } from '../config/Firebase';

export const useUserAvatars = (ids, setImgByIds) => {
    const imgObjArr = useMemo(() => [], []);
    useEffect(() => {
        ids.forEach(id => {
            if (id) {
                const ref = storage.ref().child(`users/${id}/avatar.jpg`);
                ref.getDownloadURL()
                    .then(imgUrl => {
                        imgObjArr.push({ id, url: imgUrl });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                    })
                    .catch(error => {
                        imgObjArr.push({ id, url: null, err: error.code_ });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                    });
            } else {
                imgObjArr.push({ id: null, url: null });
            }
        });
    }, [ids, imgObjArr, setImgByIds]);
};
