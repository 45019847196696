// React
import React, { useState, useCallback, useEffect } from 'react';

// Components
import { AddDocumentModal } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button
} from 'reactstrap';

// Redux - Actions, Reducers, Sagas
import {
    uploadStorageDocument,
    settingDocumentFilter,
    setDocumentList,
    settingLibraryFilter,
    settingSystemFilter,
    setLibraryList,
    setSystemList,
    settingSearchSuggestions,
    settingPackFilter
} from '../../store/actions/Documents';

import * as routes from '../../router/config/routes';
import {
    documentsTabs,
    documentsPackTabs,
    documentSections
} from '../../utils/Constants';

//Hooks
import { useBasePath } from '../../hooks/useBasePath';

const DocumentsSearchFilter = ({
    match,
    uploadStorageDocument,
    loading,
    userData,
    documentList,
    libraryList,
    systemList,
    setDocumentList,
    setLibraryList,
    setSystemList,
    documentFilterType,
    libraryFilterType,
    systemDocFilterType,
    packDocFilterType,
    settingDocumentFilter,
    settingLibraryFilter,
    settingPackFilter,
    settingSystemFilter,
    filteredUserDocuments,
    filteredOrgDocuments,
    filteredSystemDocuments,
    filteredPackDocuments,
    userDocuments,
    orgDocuments,
    systemDocuments,
    settingSearchSuggestions,
    searchSuggestions,
    uploading,
    uploadError,
    activeTab,
    isPackPage,
    selectedDocuments,
    availablePackTab
}) => {
    const history = useHistory();
    const basePath = useBasePath();
    const [pristine] = useState(true);
    const [source, setSource] = useState(null);
    const [documentModal, setDocumentModal] = useState(false);
    const [type, setType] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hideSuggestions, setHideSuggestions] = useState();
    const [fileTitle, setFileTitle] = useState('');
    const [activeUpload, setActiveUpload] = useState(false);
    const [executingUpload, setExecutingUpload] = useState(false);
    const [modalCancelled, setModalCancelled] = useState(false);
    const [inputSearchValue, setInputSearchValue] = useState('');
    const {
        all_label,
        seller_label,
        buyer_label,
        other_label,
        dropdown_label,
        input_label
    } = useLang()['Documents']['DocumentsSearchFilter'];

    const library = activeTab === documentsTabs.formsLibrary;
    const sysDocuments = activeTab === documentsTabs.formsMarketplace;
    const libraryPage =
        `${routes.AUTHENTICATED}${routes.NEW_ORG_PACK}` === basePath ||
        `${routes.AUTHENTICATED}${routes.EDIT_ORG_PACK}` === basePath;

    const filterType = library
        ? libraryFilterType
        : sysDocuments
        ? systemDocFilterType
        : isPackPage
        ? packDocFilterType
        : documentFilterType;

    const filterTypes = [
        {
            name: all_label,
            value: 'all'
        },
        {
            name: buyer_label,
            value: 'buyer'
        },
        {
            name: seller_label,
            value: 'seller'
        },
        {
            name: other_label,
            value: 'other'
        }
    ];

    const toggleModal = useCallback(() => {
        setDocumentModal(docModal => !docModal);
        setFileTitle('');
    }, []);

    useEffect(() => {
        setInputSearchValue('');
    }, [activeTab]);

    useEffect(() => {
        if (!loading && !uploading) {
            setExecutingUpload(false);
            setActiveUpload(false);
        }
    }, [loading, uploading]);

    useEffect(() => {
        if (!pristine) {
            if (activeUpload && !executingUpload && !modalCancelled) {
                setModalCancelled(false);
                toggleModal();
            } else if (activeUpload && !executingUpload && modalCancelled) {
                setModalCancelled(false);
            } else if (activeUpload && executingUpload) {
                // do nothing...system is working and we are waiting for upload to complete or fail
            } else if (!activeUpload && !executingUpload && !modalCancelled) {
                if (!uploadError) {
                    toggleModal();
                    setType(null);
                } else {
                    console.log(
                        'got an error lets notify them and close modal after that'
                    );
                }
            }
        }
    }, [
        uploadError,
        toggleModal,
        activeUpload,
        executingUpload,
        pristine,
        modalCancelled
    ]);

    useEffect(() => {
        return () => {
            settingSearchSuggestions([]);
        };
    }, [settingSearchSuggestions]);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const uploadDocument = () => {
        setExecutingUpload(true);
        uploadStorageDocument({
            src: source,
            uploader_id: userData.id,
            org_id: userData.active_org_id,
            upload_type: type,
            type: 'application/pdf',
            title: fileTitle === '' ? null : fileTitle,
            fillable: false,
            section: library
                ? documentSections.library
                : sysDocuments
                ? documentSections.system
                : documentSections.personal
        });
    };

    const filterDocuments = filter => {
        const documents = library
            ? [...orgDocuments]
            : sysDocuments
            ? [...systemDocuments]
            : isPackPage
            ? [...orgDocuments, ...userDocuments].filter(el => !el.pack)
            : [...userDocuments];
        if (filter.value !== 'all') {
            const filtered = documents.filter(doc => doc.upload_type === filter.value);
            library
                ? settingLibraryFilter({ filtered, filter })
                : sysDocuments
                ? settingSystemFilter({ filtered, filter })
                : isPackPage
                ? settingPackFilter({ filtered, filter })
                : settingDocumentFilter({ filtered, filter });
        } else {
            library
                ? settingLibraryFilter({ filtered: documents, filter })
                : sysDocuments
                ? settingSystemFilter({ filtered: documents, filter })
                : isPackPage
                ? settingPackFilter({ filtered: documents, filter })
                : settingDocumentFilter({ filtered: documents, filter });
        }
    };

    const countByFilter = filter => {
        let documents;
        if (library) {
            documents = orgDocuments.length ? [...orgDocuments] : [];
        } else if (sysDocuments) {
            documents = systemDocuments.length ? [...systemDocuments] : [];
        } else if (isPackPage) {
            if (availablePackTab === documentsPackTabs.avaliableDocs) {
                documents = [...orgDocuments, ...userDocuments]
                    .filter(el => (!libraryPage || el.section === 'library') && !el.pack)
                    .filter(
                        el => !selectedDocuments.find(selected => selected.id === el.id)
                    );
            } else {
                documents = selectedDocuments;
            }
        } else {
            documents = userDocuments.length ? [...userDocuments] : [];
        }
        if (filter.value !== 'all') {
            const filtered = documents.filter(doc => doc.upload_type === filter.value);
            return filtered?.length ? filtered.length : 0;
        } else {
            return documents.length;
        }
    };

    useEffect(() => {
        if (inputSearchValue.trim() !== '') {
            const documents = library
                ? [...filteredOrgDocuments]
                : sysDocuments
                ? [...filteredSystemDocuments]
                : isPackPage
                ? [...filteredPackDocuments]
                : [...filteredUserDocuments];

            const filteredTitle = () => {
                const titleSuggestions = [];
                documents.filter(doc => {
                    if (
                        doc.title.toLowerCase() &&
                        doc.title.toLowerCase().includes(inputSearchValue.toLowerCase())
                    ) {
                        titleSuggestions.push({
                            id: doc.id,
                            type: 'title',
                            name: dropdown_label,
                            value: doc.title,
                            document: doc
                        });
                    }
                    return true;
                });
                return titleSuggestions;
            };
            const finalSuggestions = [...filteredTitle()];
            settingSearchSuggestions(finalSuggestions);
        } else {
            settingSearchSuggestions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputSearchValue]);

    const handleSuggestionClick = ({ document }) => {
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${document.id}`,
            state: { id: document.id }
        });
    };

    return (
        <div id="documentFilters">
            <AddDocumentModal
                source={source}
                setSource={setSource}
                documentModal={documentModal}
                toggleModal={toggleModal}
                loading={loading}
                type={type}
                setType={setType}
                userData={userData}
                uploadDocument={uploadDocument}
                fileTitle={fileTitle}
                setFileTitle={setFileTitle}
                setActiveUpload={setActiveUpload}
                setModalCancelled={setModalCancelled}
            />
            <div className="container space-lg-1 space-md-0 space-sm-0">
                <div className="row mx-gutters-2 align-items-center">
                    <div className="col-md mb-3 mb-lg-0">
                        <div className="js-focus-state">
                            <label className="sr-only" htmlFor="searchDocumentSr">
                                {input_label}
                            </label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="searchDocument"
                                    >
                                        <span className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="text"
                                    id="searchDocumentSr"
                                    onFocus={() => setHideSuggestions(false)}
                                    onBlur={() => setHideSuggestions(true)}
                                    placeholder={`${input_label}`}
                                    aria-label={`${input_label}`}
                                    aria-describedby={`${input_label}`}
                                    value={inputSearchValue}
                                    onChange={e => setInputSearchValue(e.target.value)}
                                />
                                {!hideSuggestions &&
                                searchSuggestions &&
                                searchSuggestions.length ? (
                                    <ul
                                        className="auto-complete-container"
                                        style={{
                                            position: 'absolute',
                                            zIndex: 1000,
                                            marginTop: '43px'
                                        }}
                                    >
                                        {searchSuggestions.map(suggestion => (
                                            <li
                                                key={suggestion.id}
                                                className="auto-complete-item"
                                                onMouseDownCapture={() =>
                                                    handleSuggestionClick(suggestion)
                                                }
                                            >
                                                {`${suggestion.name}: ${suggestion.value}`}
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-auto ml-md-auto mb-3 mb-lg-0">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <ButtonDropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdown}
                                >
                                    <DropdownToggle caret color="soft-primary" size="sm">
                                        {`${filterType.name} (${countByFilter(
                                            filterType
                                        )})`}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {filterTypes.map(filter => (
                                            <DropdownItem
                                                key={filter.value}
                                                active={filterType.value === filter.value}
                                                onClick={() => filterDocuments(filter)}
                                            >
                                                {`${filter.name} (${countByFilter(
                                                    filter
                                                )})`}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </li>
                            {!isPackPage && (
                                <>
                                    <li className="list-inline-item">
                                        <Button
                                            color="soft-primary"
                                            size="sm"
                                            active={
                                                library
                                                    ? libraryList === 'grid'
                                                    : sysDocuments
                                                    ? systemList === 'grid'
                                                    : documentList === 'grid'
                                            }
                                            type="button"
                                            onClick={() =>
                                                library
                                                    ? setLibraryList('grid')
                                                    : sysDocuments
                                                    ? setSystemList('grid')
                                                    : setDocumentList('grid')
                                            }
                                        >
                                            <span className="fas fa-th-large" />
                                        </Button>
                                    </li>

                                    <li className="list-inline-item">
                                        <Button
                                            color="soft-primary"
                                            size="sm"
                                            type="button"
                                            active={
                                                library
                                                    ? libraryList === 'table'
                                                    : sysDocuments
                                                    ? systemList === 'table'
                                                    : documentList === 'table'
                                            }
                                            onClick={() =>
                                                library
                                                    ? setLibraryList('table')
                                                    : sysDocuments
                                                    ? setSystemList('table')
                                                    : setDocumentList('table')
                                            }
                                        >
                                            <span className="fas fa-list" />
                                        </Button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, documents }) => {
    const { userData } = user;
    const {
        loading,
        filteredUserDocuments,
        filteredOrgDocuments,
        filteredSystemDocuments,
        filteredPackDocuments,
        documentList,
        libraryList,
        systemList,
        documentFilterType,
        libraryFilterType,
        systemDocFilterType,
        packDocFilterType,
        userDocuments,
        orgDocuments,
        systemDocuments,
        searchSuggestions,
        uploading,
        uploadError
    } = documents;
    return {
        userData,
        loading,
        filteredUserDocuments,
        filteredOrgDocuments,
        filteredSystemDocuments,
        filteredPackDocuments,
        documentList,
        libraryList,
        systemList,
        documentFilterType,
        libraryFilterType,
        systemDocFilterType,
        packDocFilterType,
        userDocuments,
        orgDocuments,
        systemDocuments,
        searchSuggestions,
        uploading,
        uploadError
    };
};

export default withRouter(
    connect(mapStateToProps, {
        uploadStorageDocument,
        settingDocumentFilter,
        settingLibraryFilter,
        settingPackFilter,
        settingSystemFilter,
        setDocumentList,
        setLibraryList,
        setSystemList,
        settingSearchSuggestions
    })(DocumentsSearchFilter)
);
