import {
    SET_TRX_INVITATION_VALIDITY,
    VALIDATE_TRX_INVITATION,
    VALIDATE_TRX_INVITATION_ERROR,
    ACCEPT_TRX_INVITATION,
    ACCEPT_TRX_INVITATION_SUCCESS,
    ACCEPT_NEW_USER_TRX_INVITATION
} from './types';

export const validateTrxInvitation = data => {
    return {
        type: VALIDATE_TRX_INVITATION,
        payload: data
    };
};

export const trxInvitationValidationFailure = error => {
    return {
        type: VALIDATE_TRX_INVITATION_ERROR,
        payload: error
    };
};

export const setTrxInvitationValidity = data => {
    return {
        type: SET_TRX_INVITATION_VALIDITY,
        payload: data
    };
};

export const acceptTrxInvitation = data => {
    return {
        type: ACCEPT_TRX_INVITATION,
        payload: data
    };
};

export const acceptTrxInvitationSuccess = () => {
    return {
        type: ACCEPT_TRX_INVITATION_SUCCESS
    };
};

export const acceptNewUserTrxInvitation = data => {
    return {
        type: ACCEPT_NEW_USER_TRX_INVITATION,
        payload: data
    };
};
