import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import { ADD_INTERNAL_USER } from '../actions/types';

import { addInternalUserSuccess } from '../actions/SuperAdmin';

import { db } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

const users = db.collection('users');
// const orgs = db.collection('orgs');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Internal User ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const generateUid = () => {
//     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     let autoId = '';
//     for (let i = 0; i < 20; i++) {
//         autoId += chars.charAt(Math.floor(Math.random() * chars.length));
//     }
//     return autoId;
// };

// const id_1 = generateUid();
// const id_2 = generateUid();
// const id_3 = generateUid();
// const id_4 = generateUid();

const addInternalUserRequest = async () => {
    const ref = users.doc('xebANMW5jPfyrdoZFOtS12X9erC2');
    // const ref = orgs.doc('nw37tjm92ZekG0jHghDU');
    return new Promise((resolve, reject) => {
        ref.update({
            about: 'This is for the <strong>about</strong> section for the agent profile.',
            active_location_id: 'DZ5d4BAJKYz9F8LYKGMA',
            persist: {
                transactions: [
                    { id: 'WYwu1UA783f1kJhtQYRf', address: '1325 Oakes Blvd' },
                    { address: '2946 N Summit Ave', id: 'KL1mYjC1cA5p8ala0EDQ' },
                    { id: 'P8PJ4556Qt78asJRlfIF', address: '2941 N Hackett Ave' }
                ]
            },
            linkedin: null,
            address: null,
            active_language_id: 'en',
            active_org_id: 'ZHUyhQi2KRHwK6cqeG2y',
            contacts: [
                {
                    address: {
                        address_1: '2210 Marcia Dr',
                        address_2: '',
                        city: 'Orange Park',
                        lat: null,
                        lon: null,
                        state: 'FL',
                        zip: '34073'
                    },
                    connection: {
                        first_name: 'Linda',
                        id: '8OnGHYtKsaRfiVElDZivWjlqqr43',
                        last_name: 'Pollendine'
                    },
                    email: 'clive@jadaapp.com',
                    first_name: 'Clive',
                    id: 'u58gdMG9W5gLEAGUO0BX1ZOF4Ih2',
                    last_name: 'Pollendine',
                    notes: false,
                    phone: '123456789'
                },
                {
                    address: {
                        address_1: '2210 Marcia Dr',
                        address_2: '',
                        city: 'Orange Park',
                        lat: null,
                        lon: null,
                        state: 'FL',
                        zip: '34073'
                    },
                    connection: {
                        first_name: 'Clive',
                        id: 'u58gdMG9W5gLEAGUO0BX1ZOF4Ih2',
                        last_name: 'Pollendine'
                    },
                    email: 'linda@jadaapp.com',
                    first_name: 'Linda',
                    id: '8OnGHYtKsaRfiVElDZivWjlqqr43',
                    last_name: 'Pollendine',
                    notes: false,
                    phone: '987654321'
                },
                {
                    address: {
                        address_1: '440 Raven Way',
                        address_2: '',
                        city: 'Naples',
                        lat: null,
                        lon: null,
                        state: 'FL',
                        zip: '34119'
                    },
                    connection: null,
                    email: 'f.martinez@jadaapp.com',
                    first_name: 'Francisco',
                    id: null,
                    last_name: 'Martinez',
                    notes: false,
                    phone: '987654321'
                },
                {
                    address: {
                        address_1: '',
                        address_2: '',
                        city: '',
                        lat: null,
                        lon: null,
                        state: '',
                        zip: ''
                    },
                    connection: null,
                    email: 'stu.kelevra@jadaapp.com',
                    first_name: 'Stuart',
                    id: null,
                    last_name: 'Kelevra',
                    notes: false,
                    phone: ''
                },
                {
                    address: {
                        address_1: '20445 Cypress Shadows Blvd',
                        address_2: '',
                        city: 'Estero',
                        lat: null,
                        lon: null,
                        state: 'FL',
                        zip: '33928'
                    },
                    connection: null,
                    email: 'rjursack@jadaapp.com',
                    first_name: 'Ryan',
                    id: 'rRlsd72Bprh4nOLNc3SCNOS2Laq2',
                    last_name: 'Jursack',
                    notes: false,
                    phone: '987654321'
                }
            ],
            type: 'member',
            last_name: 'Vivonetto',
            instagram: null,
            website: 'movereg.com',
            id: 'xebANMW5jPfyrdoZFOtS12X9erC2',
            phone: [{ number: 2395372615, primary: true, type: 'office' }],
            broker_lic: 'BK5465487',
            agent_lic: 'SL12355444',
            languages: [
                {
                    default: true,
                    id: 'en',
                    name: 'english'
                }
            ],
            mls_id: 'N627511',
            orgs: [
                {
                    id: 'ZHUyhQi2KRHwK6cqeG2y',
                    locations: ['DZ5d4BAJKYz9F8LYKGMA', 'ZDFGRc8tFBu8euqTvKOd'],
                    owner: false,
                    sub_type: 'sales_associate',
                    title: 'Sales Associate'
                },
                {
                    id: 'nw37tjm92ZekG0jHghDU',
                    locations: ['kXe9hlSejKkL4svlfxKN'],
                    owner: false,
                    sub_type: 'broker',
                    title: 'Broker'
                }
            ],
            first_name: 'Marc',
            designation: null,
            active: true,
            twitter: null,
            facebook: null,
            email: 'marc@jadaapp.com',
            preferences: {
                ZHUyhQi2KRHwK6cqeG2y: {
                    DZ5d4BAJKYz9F8LYKGMA: {
                        initial_tasks: {
                            seller: [
                                {
                                    created_at: {
                                        seconds: 1640018365,
                                        nanoseconds: 407000000
                                    },
                                    content: 'Execute the listing on behalf of clients',
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open',
                                    date_time: { seconds: 1640444400, nanoseconds: 0 },
                                    expiration: {
                                        ordinal: 'AM',
                                        hours: 9,
                                        days: 1,
                                        mins: 0
                                    },
                                    title: 'Execute Listing'
                                },
                                {
                                    content:
                                        'Upload and gather best photos of the property',
                                    expiration: {
                                        days: 5,
                                        ordinal: 'PM',
                                        hours: 13,
                                        mins: 30
                                    },
                                    created_at: {
                                        seconds: 1640018364,
                                        nanoseconds: 407000000
                                    },
                                    date_time: { seconds: 1640806200, nanoseconds: 0 },
                                    title: 'Property Photos',
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open'
                                },
                                {
                                    edited_at: null,
                                    content: 'Upload property and listing data to MLS',
                                    title: 'Upload to MLS',
                                    expiration: {
                                        hours: 9,
                                        ordinal: 'AM',
                                        mins: 0,
                                        days: 1
                                    },
                                    status: 'open',
                                    date_time: { seconds: 1608127200, nanoseconds: 0 },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1608072395,
                                        nanoseconds: 36000000
                                    }
                                },
                                {
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'AM',
                                        days: 3,
                                        hours: 10
                                    },
                                    content: 'Installing lockbox at the property',
                                    edited_at: null,
                                    title: 'Install Lockbox',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    date_time: { seconds: 1640622600, nanoseconds: 0 },
                                    status: 'open',
                                    created_at: {
                                        seconds: 1640018366,
                                        nanoseconds: 407000000
                                    }
                                },
                                {
                                    created_at: {
                                        seconds: 1635372983,
                                        nanoseconds: 287000000
                                    },
                                    expiration: {
                                        ordinal: 'AM',
                                        days: 3,
                                        hours: 10,
                                        mins: 0
                                    },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    content: 'Place sign near street of the property',
                                    status: 'open',
                                    date_time: { seconds: 1637942400, nanoseconds: 0 },
                                    title: 'Install Sign',
                                    edited_at: null
                                },
                                {
                                    title: 'Staging',
                                    content:
                                        'Schedule and stage listing with AAA Staging Company',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1640018367,
                                        nanoseconds: 407000000
                                    },
                                    date_time: { seconds: 1640723400, nanoseconds: 0 },
                                    expiration: {
                                        days: 4,
                                        mins: 30,
                                        ordinal: 'PM',
                                        hours: 14
                                    },
                                    edited_at: null,
                                    status: 'open'
                                },
                                {
                                    content: 'Call owners to check in',
                                    expiration: {
                                        days: 7,
                                        ordinal: '',
                                        mins: 30,
                                        hours: 22
                                    },
                                    date_time: null,
                                    edited_at: null,
                                    status: 'open',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    title: 'Follow Up',
                                    created_at: null
                                }
                            ],
                            buyer: [
                                {
                                    status: 'open',
                                    title: 'Deliver Escrow Deposit',
                                    date_time: null,
                                    content: 'Delivering the escrow deposit ',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: null,
                                    expiration: {
                                        ordinal: 'AM',
                                        hours: 10,
                                        days: 5,
                                        mins: 30
                                    },
                                    edited_at: null
                                },
                                {
                                    content: 'Requesting HOA docs',
                                    date_time: { seconds: 1609601400, nanoseconds: 0 },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179202,
                                        nanoseconds: 670000000
                                    },
                                    expiration: {
                                        mins: 30,
                                        days: 5,
                                        ordinal: 'AM',
                                        hours: 9
                                    },
                                    status: 'open',
                                    title: 'Request HOA/Condo Docs',
                                    edited_at: null
                                },
                                {
                                    title: 'Home Inspection',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179201,
                                        nanoseconds: 670000000
                                    },
                                    status: 'open',
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'AM',
                                        hours: 11,
                                        days: 5
                                    },
                                    date_time: { seconds: 1609608600, nanoseconds: 0 },
                                    edited_at: null,
                                    content: 'Scheduling home inspection'
                                },
                                {
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open',
                                    content: 'Submitting mortgage application for buyers',
                                    date_time: null,
                                    created_at: null,
                                    title: 'Mortgage Application',
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'PM',
                                        hours: 12,
                                        days: 5
                                    }
                                },
                                {
                                    date_time: { seconds: 1609603200, nanoseconds: 0 },
                                    content:
                                        'Schedule appraiser to appraise the property',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179203,
                                        nanoseconds: 670000000
                                    },
                                    title: 'Appraisal',
                                    expiration: {
                                        days: 5,
                                        hours: 10,
                                        ordinal: 'AM',
                                        mins: 0
                                    },
                                    edited_at: null,
                                    status: 'open'
                                },
                                {
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179204,
                                        nanoseconds: 670000000
                                    },
                                    content: 'Send welcome letter to clients',
                                    edited_at: null,
                                    status: 'open',
                                    title: 'Send Welcome Letter',
                                    date_time: { seconds: 1609518600, nanoseconds: 0 },
                                    expiration: {
                                        hours: 10,
                                        ordinal: 'AM',
                                        mins: 30,
                                        days: 4
                                    }
                                },
                                {
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: null,
                                    title: 'Some task',
                                    expiration: {
                                        ordinal: 'PM',
                                        mins: 0,
                                        hours: 24,
                                        days: 5
                                    },
                                    content: 'Some task description.',
                                    edited_at: null,
                                    status: 'open',
                                    date_time: null
                                }
                            ]
                        },
                        sources: [
                            'REALTOR.COM',
                            'FRIEND',
                            'TRULIA',
                            'REDFIN',
                            'FRIEND OF A FRIEND',
                            "SETH'S UNCLE",
                            'VETERANS UNITED',
                            'FAMILY FRIEND',
                            'MICHELLE BEST FRIEND',
                            'MIKES AUNT',
                            'FRIEND OF BROTHER'
                        ],
                        income: {
                            split: 80,
                            commission: 3,
                            trx_fee_percent: false,
                            split_percent: true,
                            commission_percent: true,
                            trx_fee: 195
                        },
                        team: [
                            {
                                last_name: 'Delaney',
                                title: 'Principal Broker',
                                email: 'ken@movereg.com',
                                phone: '2395372615',
                                first_name: 'Ken',
                                id: 'jADofRUXpjfA5jcP8vpdQckJafF3',
                                type: 'broker'
                            }
                        ]
                    },
                    ZDFGRc8tFBu8euqTvKOd: {
                        initial_tasks: {
                            seller: [
                                {
                                    created_at: {
                                        seconds: 1640018365,
                                        nanoseconds: 407000000
                                    },
                                    content: 'Execute the listing on behalf of clients',
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open',
                                    date_time: { seconds: 1640444400, nanoseconds: 0 },
                                    expiration: {
                                        ordinal: 'AM',
                                        hours: 9,
                                        days: 1,
                                        mins: 0
                                    },
                                    title: 'Execute Listing'
                                },
                                {
                                    content:
                                        'Upload and gather best photos of the property',
                                    expiration: {
                                        days: 5,
                                        ordinal: 'PM',
                                        hours: 13,
                                        mins: 30
                                    },
                                    created_at: {
                                        seconds: 1640018364,
                                        nanoseconds: 407000000
                                    },
                                    date_time: { seconds: 1640806200, nanoseconds: 0 },
                                    title: 'Property Photos',
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open'
                                },
                                {
                                    edited_at: null,
                                    content: 'Upload property and listing data to MLS',
                                    title: 'Upload to MLS',
                                    expiration: {
                                        hours: 9,
                                        ordinal: 'AM',
                                        mins: 0,
                                        days: 1
                                    },
                                    status: 'open',
                                    date_time: { seconds: 1608127200, nanoseconds: 0 },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1608072395,
                                        nanoseconds: 36000000
                                    }
                                },
                                {
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'AM',
                                        days: 3,
                                        hours: 10
                                    },
                                    content: 'Installing lockbox at the property',
                                    edited_at: null,
                                    title: 'Install Lockbox',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    date_time: { seconds: 1640622600, nanoseconds: 0 },
                                    status: 'open',
                                    created_at: {
                                        seconds: 1640018366,
                                        nanoseconds: 407000000
                                    }
                                },
                                {
                                    created_at: {
                                        seconds: 1635372983,
                                        nanoseconds: 287000000
                                    },
                                    expiration: {
                                        ordinal: 'AM',
                                        days: 3,
                                        hours: 10,
                                        mins: 0
                                    },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    content: 'Place sign near street of the property',
                                    status: 'open',
                                    date_time: { seconds: 1637942400, nanoseconds: 0 },
                                    title: 'Install Sign',
                                    edited_at: null
                                },
                                {
                                    title: 'Staging',
                                    content:
                                        'Schedule and stage listing with AAA Staging Company',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1640018367,
                                        nanoseconds: 407000000
                                    },
                                    date_time: { seconds: 1640723400, nanoseconds: 0 },
                                    expiration: {
                                        days: 4,
                                        mins: 30,
                                        ordinal: 'PM',
                                        hours: 14
                                    },
                                    edited_at: null,
                                    status: 'open'
                                },
                                {
                                    content: 'Call owners to check in',
                                    expiration: {
                                        days: 7,
                                        ordinal: '',
                                        mins: 30,
                                        hours: 22
                                    },
                                    date_time: null,
                                    edited_at: null,
                                    status: 'open',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    title: 'Follow Up',
                                    created_at: null
                                }
                            ],
                            buyer: [
                                {
                                    status: 'open',
                                    title: 'Deliver Escrow Deposit',
                                    date_time: null,
                                    content: 'Delivering the escrow deposit ',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: null,
                                    expiration: {
                                        ordinal: 'AM',
                                        hours: 10,
                                        days: 5,
                                        mins: 30
                                    },
                                    edited_at: null
                                },
                                {
                                    content: 'Requesting HOA docs',
                                    date_time: { seconds: 1609601400, nanoseconds: 0 },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179202,
                                        nanoseconds: 670000000
                                    },
                                    expiration: {
                                        mins: 30,
                                        days: 5,
                                        ordinal: 'AM',
                                        hours: 9
                                    },
                                    status: 'open',
                                    title: 'Request HOA/Condo Docs',
                                    edited_at: null
                                },
                                {
                                    title: 'Home Inspection',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179201,
                                        nanoseconds: 670000000
                                    },
                                    status: 'open',
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'AM',
                                        hours: 11,
                                        days: 5
                                    },
                                    date_time: { seconds: 1609608600, nanoseconds: 0 },
                                    edited_at: null,
                                    content: 'Scheduling home inspection'
                                },
                                {
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open',
                                    content: 'Submitting mortgage application for buyers',
                                    date_time: null,
                                    created_at: null,
                                    title: 'Mortgage Application',
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'PM',
                                        hours: 12,
                                        days: 5
                                    }
                                },
                                {
                                    date_time: { seconds: 1609603200, nanoseconds: 0 },
                                    content:
                                        'Schedule appraiser to appraise the property',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179203,
                                        nanoseconds: 670000000
                                    },
                                    title: 'Appraisal',
                                    expiration: {
                                        days: 5,
                                        hours: 10,
                                        ordinal: 'AM',
                                        mins: 0
                                    },
                                    edited_at: null,
                                    status: 'open'
                                },
                                {
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179204,
                                        nanoseconds: 670000000
                                    },
                                    content: 'Send welcome letter to clients',
                                    edited_at: null,
                                    status: 'open',
                                    title: 'Send Welcome Letter',
                                    date_time: { seconds: 1609518600, nanoseconds: 0 },
                                    expiration: {
                                        hours: 10,
                                        ordinal: 'AM',
                                        mins: 30,
                                        days: 4
                                    }
                                },
                                {
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: null,
                                    title: 'Some task',
                                    expiration: {
                                        ordinal: 'PM',
                                        mins: 0,
                                        hours: 24,
                                        days: 5
                                    },
                                    content: 'Some task description.',
                                    edited_at: null,
                                    status: 'open',
                                    date_time: null
                                }
                            ]
                        },
                        sources: [
                            'REALTOR.COM',
                            'FRIEND',
                            'TRULIA',
                            'REDFIN',
                            'FRIEND OF A FRIEND',
                            "SETH'S UNCLE",
                            'VETERANS UNITED',
                            'FAMILY FRIEND',
                            'MICHELLE BEST FRIEND',
                            'MIKES AUNT',
                            'FRIEND OF BROTHER'
                        ],
                        income: {
                            split: 80,
                            commission: 3,
                            trx_fee_percent: false,
                            split_percent: true,
                            commission_percent: true,
                            trx_fee: 195
                        },
                        team: [
                            {
                                last_name: 'Delaney',
                                title: 'Principal Broker',
                                email: 'ken@movereg.com',
                                phone: '2395372615',
                                first_name: 'Ken',
                                id: 'jADofRUXpjfA5jcP8vpdQckJafF3',
                                type: 'broker'
                            }
                        ]
                    }
                },
                nw37tjm92ZekG0jHghDU: {
                    kXe9hlSejKkL4svlfxKN: {
                        initial_tasks: {
                            listing: [
                                {
                                    created_at: {
                                        seconds: 1640018365,
                                        nanoseconds: 407000000
                                    },
                                    content: 'Execute the listing on behalf of clients',
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open',
                                    date_time: { seconds: 1640444400, nanoseconds: 0 },
                                    expiration: {
                                        ordinal: 'AM',
                                        hours: 9,
                                        days: 1,
                                        mins: 0
                                    },
                                    title: 'Execute Listing'
                                },
                                {
                                    content:
                                        'Upload and gather best photos of the property',
                                    expiration: {
                                        days: 5,
                                        ordinal: 'PM',
                                        hours: 13,
                                        mins: 30
                                    },
                                    created_at: {
                                        seconds: 1640018364,
                                        nanoseconds: 407000000
                                    },
                                    date_time: { seconds: 1640806200, nanoseconds: 0 },
                                    title: 'Property Photos',
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open'
                                },
                                {
                                    edited_at: null,
                                    content: 'Upload property and listing data to MLS',
                                    title: 'Upload to MLS',
                                    expiration: {
                                        hours: 9,
                                        ordinal: 'AM',
                                        mins: 0,
                                        days: 1
                                    },
                                    status: 'open',
                                    date_time: { seconds: 1608127200, nanoseconds: 0 },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1608072395,
                                        nanoseconds: 36000000
                                    }
                                },
                                {
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'AM',
                                        days: 3,
                                        hours: 10
                                    },
                                    content: 'Installing lockbox at the property',
                                    edited_at: null,
                                    title: 'Install Lockbox',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    date_time: { seconds: 1640622600, nanoseconds: 0 },
                                    status: 'open',
                                    created_at: {
                                        seconds: 1640018366,
                                        nanoseconds: 407000000
                                    }
                                },
                                {
                                    created_at: {
                                        seconds: 1635372983,
                                        nanoseconds: 287000000
                                    },
                                    expiration: {
                                        ordinal: 'AM',
                                        days: 3,
                                        hours: 10,
                                        mins: 0
                                    },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    content: 'Place sign near street of the property',
                                    status: 'open',
                                    date_time: { seconds: 1637942400, nanoseconds: 0 },
                                    title: 'Install Sign',
                                    edited_at: null
                                },
                                {
                                    title: 'Staging',
                                    content:
                                        'Schedule and stage listing with AAA Staging Company',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1640018367,
                                        nanoseconds: 407000000
                                    },
                                    date_time: { seconds: 1640723400, nanoseconds: 0 },
                                    expiration: {
                                        days: 4,
                                        mins: 30,
                                        ordinal: 'PM',
                                        hours: 14
                                    },
                                    edited_at: null,
                                    status: 'open'
                                },
                                {
                                    content: 'Call owners to check in',
                                    expiration: {
                                        days: 7,
                                        ordinal: '',
                                        mins: 30,
                                        hours: 22
                                    },
                                    date_time: null,
                                    edited_at: null,
                                    status: 'open',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    title: 'Follow Up',
                                    created_at: null
                                }
                            ],
                            buyer: [
                                {
                                    status: 'open',
                                    title: 'Deliver Escrow Deposit',
                                    date_time: null,
                                    content: 'Delivering the escrow deposit ',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: null,
                                    expiration: {
                                        ordinal: 'AM',
                                        hours: 10,
                                        days: 5,
                                        mins: 30
                                    },
                                    edited_at: null
                                },
                                {
                                    content: 'Requesting HOA docs',
                                    date_time: { seconds: 1609601400, nanoseconds: 0 },
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179202,
                                        nanoseconds: 670000000
                                    },
                                    expiration: {
                                        mins: 30,
                                        days: 5,
                                        ordinal: 'AM',
                                        hours: 9
                                    },
                                    status: 'open',
                                    title: 'Request HOA/Condo Docs',
                                    edited_at: null
                                },
                                {
                                    title: 'Home Inspection',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179201,
                                        nanoseconds: 670000000
                                    },
                                    status: 'open',
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'AM',
                                        hours: 11,
                                        days: 5
                                    },
                                    date_time: { seconds: 1609608600, nanoseconds: 0 },
                                    edited_at: null,
                                    content: 'Scheduling home inspection'
                                },
                                {
                                    edited_at: null,
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    status: 'open',
                                    content: 'Submitting mortgage application for buyers',
                                    date_time: null,
                                    created_at: null,
                                    title: 'Mortgage Application',
                                    expiration: {
                                        mins: 30,
                                        ordinal: 'PM',
                                        hours: 12,
                                        days: 5
                                    }
                                },
                                {
                                    date_time: { seconds: 1609603200, nanoseconds: 0 },
                                    content:
                                        'Schedule appraiser to appraise the property',
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179203,
                                        nanoseconds: 670000000
                                    },
                                    title: 'Appraisal',
                                    expiration: {
                                        days: 5,
                                        hours: 10,
                                        ordinal: 'AM',
                                        mins: 0
                                    },
                                    edited_at: null,
                                    status: 'open'
                                },
                                {
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: {
                                        seconds: 1609179204,
                                        nanoseconds: 670000000
                                    },
                                    content: 'Send welcome letter to clients',
                                    edited_at: null,
                                    status: 'open',
                                    title: 'Send Welcome Letter',
                                    date_time: { seconds: 1609518600, nanoseconds: 0 },
                                    expiration: {
                                        hours: 10,
                                        ordinal: 'AM',
                                        mins: 30,
                                        days: 4
                                    }
                                },
                                {
                                    created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
                                    created_at: null,
                                    title: 'Some task',
                                    expiration: {
                                        ordinal: 'PM',
                                        mins: 0,
                                        hours: 24,
                                        days: 5
                                    },
                                    content: 'Some task description.',
                                    edited_at: null,
                                    status: 'open',
                                    date_time: null
                                }
                            ]
                        },
                        sources: [
                            'REALTOR.COM',
                            'FRIEND',
                            'TRULIA',
                            'REDFIN',
                            'FRIEND OF A FRIEND',
                            "SETH'S UNCLE",
                            'VETERANS UNITED',
                            'FAMILY FRIEND',
                            'MICHELLE BEST FRIEND',
                            'MIKES AUNT',
                            'FRIEND OF BROTHER'
                        ],
                        income: {
                            split: 80,
                            commission: 3,
                            trx_fee_percent: false,
                            split_percent: true,
                            commission_percent: true,
                            trx_fee: 195
                        },
                        team: [
                            {
                                last_name: 'Delaney',
                                title: 'Principal Broker',
                                email: 'ken@movereg.com',
                                phone: '2395372615',
                                first_name: 'Ken',
                                id: 'jADofRUXpjfA5jcP8vpdQckJafF3',
                                type: 'broker'
                            }
                        ]
                    }
                }
            },
            role: 'user',
            user_avatar:
                'https://firebasestorage.googleapis.com/v0/b/jada-development.appspot.com/o/users%2FxebANMW5jPfyrdoZFOtS12X9erC2%2Favatar.jpg?alt=media&token=f730e89b-6405-4490-a7d6-cc791f5e3832'
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
//     {
//     active: true,
//     active_location_id: id_1,
//     active_org_id: 'ZHUyhQi2KRHwK6cqeG2y',
//     address: null,
//     agent_lic: 'SL88894567',
//     broker_lic: null,
//     contacts: [
//         {
//             address: {
//                 address_1: '2210 Marcia Dr',
//                 address_2: '',
//                 city: 'Orange Park',
//                 state: 'FL',
//                 zip: '34073'
//             },
//             connection: {
//                 first_name: 'Linda',
//                 id: '8OnGHYtKsaRfiVElDZivWjlqqr43',
//                 last_name: 'Pollendine'
//             },
//             email: 'clive@jadaapp.com',
//             first_name: 'Clive',
//             id: 'u58gdMG9W5gLEAGUO0BX1ZOF4Ih2',

//             last_name: 'Pollendine',
//             notes: false,
//             phone: '123456789'
//         },
//         {
//             address: {
//                 address_1: '2210 Marcia Dr',
//                 address_2: '',
//                 city: 'Orange Park',
//                 state: 'FL',
//                 zip: '34073'
//             },
//             connection: {
//                 first_name: 'Clive',
//                 id: 'u58gdMG9W5gLEAGUO0BX1ZOF4Ih2',
//                 last_name: 'Pollendine'
//             },
//             email: 'linda@jadaapp.com',
//             first_name: 'Linda',
//             id: '8OnGHYtKsaRfiVElDZivWjlqqr43',
//             last_name: 'Pollendine',
//             notes: false,
//             phone: '987654321'
//         },
//         {
//             address: {
//                 address_1: '440 Raven Way',
//                 address_2: '',
//                 city: 'Naples',
//                 state: 'FL',
//                 zip: '34119'
//             },
//             connection: null,
//             email: 'f.martinez@jadaapp.com',
//             first_name: 'Francisco',
//             id: null,
//             last_name: 'Martinez',
//             notes: false,
//             phone: '987654321'
//         },
//         {
//             address: {
//                 address_1: '',
//                 address_2: '',
//                 city: '',
//                 state: '',
//                 zip: ''
//             },
//             connection: null,
//             email: 'stu.kelevra@jadaapp.com',
//             first_name: 'Stuart',
//             id: null,
//             last_name: 'Kelevra',
//             notes: false,
//             phone: ''
//         },
//         {
//             address: {
//                 address_1: '20445 Cypress Shadows Blvd',
//                 address_2: '',
//                 city: 'Estero',
//                 state: 'FL',
//                 zip: '33928'
//             },
//             connection: null,
//             email: 'rjursack@jadaapp.com',
//             first_name: 'Ryan',
//             id: 'rRlsd72Bprh4nOLNc3SCNOS2Laq2',
//             last_name: 'Jursack',
//             notes: false,
//             phone: '987654321'
//         }
//     ],
//     designation: null,
//     email: 'tetyana@jadaapp.com',
//     facebook: null,
//     first_name: 'Tetyana',
//     id: 'IO9zB9ayfueekTMr2xjaHCZi4Te2',
//     instagram: null,
//     language: 'en',
//     last_name: 'Zamkova',
//     linkedin: null,
//     orgs: [
//         {
//             company_name: 'Move Real Estate Group',
//             id: 'ZHUyhQi2KRHwK6cqeG2y',
//             locations: [
//                 {
//                     address_1: '28280 Old 41 Rd',
//                     address_2: 'Unit 1',
//                     city: 'Bonita Springs',
//                     email: 'contact@movereg.com',
//                     id: id_1,
//                     office_name: 'Move Real Estate',
//                     office_type: 'main',
//                     phone: '2395372615',
//                     state: 'FL',
//                     zip: '34135',
//                     website: 'movereg.com'
//                 },
//                 {
//                     address_1: '11983 Tamiami Trail N',
//                     address_2: '',
//                     city: 'Naples',
//                     email: 'contact@movereg.com',
//                     id: id_2,
//                     office_name: 'Move Real Estate - Naples',
//                     office_type: 'branch',
//                     phone: '2395372615',
//                     state: 'FL',
//                     zip: '34110',
//                     website: 'movereg.com'
//                 }
//             ],
//             owner: false,
//             sub_type: 'agent',
//             title: 'Sales Associate'
//         },
//         {
//             company_name: 'First Team Realty',
//             id: 'nw37tjm92ZekG0jHghDU',
//             locations: [
//                 {
//                     address_1: '123 Main St',
//                     address_2: null,
//                     city: 'Roswell',
//                     email: 'firstteam@movereg.com',
//                     id: id_3,
//                     office_name: 'First Team - Roswell',
//                     office_type: 'main',
//                     phone: '4048054566',
//                     state: 'GA',
//                     zip: '88647',
//                     website: 'movereg.com'
//                 },
//                 {
//                     address_1: '304 Ellenton Place',
//                     address_2: null,
//                     city: 'Canton',
//                     email: 'firstteam@movereg.com',
//                     id: id_4,
//                     office_name: 'First Team - Ellenton',
//                     office_type: 'satelite',
//                     phone: '4048054566',
//                     state: 'GA',
//                     zip: '30115',
//                     website: 'movereg.com'
//                 }
//             ],
//             owner: false,
//             sub_type: 'agent',
//             title: 'Sales Associate'
//         }
//     ],
//     persist: null,
//     phone: [
//         {
//             number: 2395372615,
//             primary: true,
//             type: 'office'
//         }
//     ],
//     preferences: {
//         ZHUyhQi2KRHwK6cqeG2y: {
//             [id_1]: {
//                 income: {
//                     commission: 3,
//                     commission_percent: true,
//                     split: 80,
//                     split_percent: true,
//                     trx_fee: 195,
//                     trx_fee_percent: false
//                 },
//                 initial_tasks: {
//                     buyer: null,
//                     listing: null
//                 },
//                 sources: null,
//                 team: [
//                     {
//                         email: 'marc@jadaapp.com',
//                         first_name: 'Marc',
//                         id: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                         last_name: 'Vivonetto',
//                         phone: '2392929292',
//                         title: 'Sales Associate',
//                         type: 'agent'
//                     },
//                     {
//                         email: 'ken@movereg.com',
//                         first_name: 'Ken',
//                         id: 'jADofRUXpjfA5jcP8vpdQckJafF3',
//                         last_name: 'Delaney',
//                         phone: '2395372615',
//                         title: 'Principal Broker',
//                         type: 'broker'
//                     },
//                     {
//                         email: 'bohdan@jadaapp.com',
//                         first_name: 'Bohdan',
//                         id: 'jADofRUXpjfA5jcP8vpdQckJafF3',
//                         last_name: 'Hrynkiv',
//                         phone: '1234567890',
//                         title: 'Sales Associate',
//                         type: 'agent'
//                     }
//                 ]
//             }
//         },
//         nw37tjm92ZekG0jHghDU: {
//             [id_3]: {
//                 income: {
//                     commission: 3,
//                     commission_percent: true,
//                     split: 80,
//                     split_percent: true,
//                     trx_fee: 195,
//                     trx_fee_percent: false
//                 },
//                 initial_tasks: {
//                     buyer: null,
//                     listing: null
//                 },
//                 sources: null,
//                 team: []
//             }
//         }
//     },
//     role: 'user',
//     twitter: null,
//     type: 'member',
//     user_avatar: '',
//     website: 'movereg.com'
// }
//         )
//             .then(() => {
//                 resolve({ res: true });
//             })
//             .catch(error => {
//                 reject({ error });
//             });
//     });
// };

export function* addInternalUser() {
    const { res, error } = yield call(() => addInternalUserRequest());
    if (res) {
        yield put(addInternalUserSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Super Admin Error: error adding internal test user (FS)', {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* addingInternalUser() {
    yield takeLatest(ADD_INTERNAL_USER, addInternalUser);
}

export default function* rootSaga() {
    yield all([fork(addingInternalUser)]);
}
