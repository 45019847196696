// React
import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

// Components
import { DetailsHeader } from '../components';
import DropdownMenuComponent from '../components/common/DropdownMenu';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Context
import { useLang } from '../context/LangContext';

// Packages
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';

// Redux
import { connect } from 'react-redux';
import { removePipelineContact } from '../store/actions/Pipeline';

// Router
import * as routes from '../router/config/routes';

// Utils
import { confirmationDialogTypes, headerTypes } from '../utils/Constants';
import NewPipelineForm from '../components/Pipeline/NewPipelineForm';

const PipelineDetails = ({
    pipelineContacts,
    location,
    removePipelineContact,
    userData
}) => {
    const history = useHistory();
    const [edit, setEdit] = useState(false);

    const getContactId = () => {
        const pathname = window.location.pathname;
        if (location.state?.id) return location.state.id;
        return pathname.substr(pathname.lastIndexOf('/') + 1);
    };

    const contacts = pipelineContacts;
    const contact =
        contacts &&
        contacts.find(contact => {
            return contact?.id?.toString() === getContactId();
        });

    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => removePipelineContact({ userData, contact })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () => setEdit(true)
        }
    ];

    return (
        <>
            {edit ? (
                <ConfirmationDialog
                    initialModalDialogType={confirmationDialogTypes.success}
                    successHandler={() =>
                        history.push(
                            `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.PIPELINE}`
                        )
                    }
                />
            ) : (
                <ConfirmationDialog
                    initialModalDialogType={confirmationDialogTypes.delete}
                    successHandler={() =>
                        history.push(
                            `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.PIPELINE}`
                        )
                    }
                />
            )}
            {contact && (
                <>
                    <DetailsHeader
                        contact={contact}
                        headerType={headerTypes.contactDetails}
                    />
                    <main id="content" role="main" className="push-main">
                        <div className="bg-light">
                            {edit ? (
                                <NewPipelineForm
                                    handleCancel={() => setEdit(false)}
                                    contact={contact}
                                    history={history}
                                />
                            ) : (
                                <div
                                    id="main"
                                    className="contact-frame size container mt-5 p-5 pt-3 pt-xl-4"
                                >
                                    <div className="row justify-content-end pt-1">
                                        <DropdownMenuComponent dropItems={dropItems} />
                                    </div>
                                    <div className="row">
                                        <div className="col-2 contact-frame__avatars">
                                            <div className="row d-flex justify-content-center">
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary contact-frame__avatar rounded-circle"
                                                    name={`${contact.first_name} ${contact.last_name}`}
                                                    unstyled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-10 mt-2 contact__details">
                                            <h2 className="contact-frame__fullname mb-3">
                                                {contact.first_name} {contact.last_name}
                                            </h2>
                                            <div className="row d-flex align-items-start ml-0 mt-2">
                                                {(contact.phone || contact.email) && (
                                                    <div className="col">
                                                        {contact.phone && (
                                                            <p className="row contact__details-row">
                                                                <FontAwesomeIcon
                                                                    className="contact__details-icon"
                                                                    icon={faPhone}
                                                                    color="rgb(140, 152, 164)"
                                                                />
                                                                <a
                                                                    href={`tel:${contact.phone}`}
                                                                >
                                                                    <NumberFormat
                                                                        value={
                                                                            contact.phone
                                                                        }
                                                                        displayType={
                                                                            'text'
                                                                        }
                                                                        format="###.###.####"
                                                                        mask="_"
                                                                    />
                                                                </a>
                                                            </p>
                                                        )}
                                                        {contact.email && (
                                                            <p className="row contact__details-row">
                                                                <FontAwesomeIcon
                                                                    className="contact__details-icon"
                                                                    icon={faEnvelope}
                                                                    color="rgb(140, 152, 164)"
                                                                />
                                                                <a
                                                                    href={`mailto:${contact.email}`}
                                                                >
                                                                    {contact.email}
                                                                </a>
                                                            </p>
                                                        )}
                                                    </div>
                                                )}
                                                {contact?.address && (
                                                    <div className="col">
                                                        <div className="row connection__details-row">
                                                            <FontAwesomeIcon
                                                                className="connection__details-icon"
                                                                icon={faHome}
                                                                color="rgb(140, 152, 164)"
                                                            />
                                                            {contact?.address?.lat &&
                                                            contact?.address?.lon ? (
                                                                <a
                                                                    href={`https://google.com/maps/search/?api=1&query=${contact.address.lat},${contact.address.lon}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {
                                                                        contact.address
                                                                            .address_1
                                                                    }
                                                                    <br />
                                                                    {contact.address.city}
                                                                    ,{' '}
                                                                    {
                                                                        contact.address
                                                                            .state
                                                                    }{' '}
                                                                    {contact.address.zip}
                                                                </a>
                                                            ) : (
                                                                <span className="small text-muted">
                                                                    {
                                                                        contact?.address
                                                                            ?.address_1
                                                                    }
                                                                    <br />
                                                                    {
                                                                        contact?.address
                                                                            ?.city
                                                                    }
                                                                    ,{' '}
                                                                    {
                                                                        contact?.address
                                                                            ?.state
                                                                    }{' '}
                                                                    {
                                                                        contact?.address
                                                                            ?.zip
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ pipeline, user }) => {
    const { pipelineContacts } = pipeline;
    const { userData } = user;
    return { pipelineContacts, userData };
};

export default withRouter(
    connect(mapStateToProps, { removePipelineContact })(PipelineDetails)
);
