// React
import React, { useState } from 'react';

// Components
import { AddSearch, NoteList, NoteDrop } from '../index';

const Notes = ({ notes, trxId, isTransactionArchived, userId, isMember }) => {
    const [dropState, setDropState] = useState(false);

    return (
        <div
            style={{
                animation: 'fadeIn 1s',
                width: '100%',
                overflowY: 'auto'
            }}
        >
            {!isTransactionArchived && !isMember && (
                <>
                    <AddSearch onClick={setDropState} dropState={dropState} />

                    <NoteDrop
                        notes={notes}
                        setDropState={setDropState}
                        dropState={dropState}
                        trxId={trxId}
                    />
                </>
            )}
            <NoteList
                userId={userId}
                notes={notes}
                trxId={trxId}
                isTransactionArchived={isTransactionArchived}
                isMember={isMember}
            />
        </div>
    );
};

export default Notes;
