import {
    UPDATE_USER_AVATAR,
    UPDATE_USER_AVATAR_SUCCESS,
    UPDATE_USER_AVATAR_FAILURE,
    UPDATE_USER_TEAM,
    UPDATE_ORG_TEAM,
    UPDATE_PERSONAL_INFO,
    UPDATE_PERSONAL_INFO_SUCCESS,
    UPDATE_PERSONAL_INFO_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    uploadError: null,
    team: null,
    error: null
};

const settingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                loading: true,
                uploadError: INITIAL_STATE.uploadError
            };
        case UPDATE_USER_AVATAR_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: INITIAL_STATE.uploadError
            };
        case UPDATE_USER_AVATAR_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: action.payload
            };
        case UPDATE_PERSONAL_INFO:
            return {
                ...state,
                loading: true,
                error: INITIAL_STATE.error
            };
        case UPDATE_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: INITIAL_STATE.error
            };
        case UPDATE_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case UPDATE_USER_TEAM:
            return {
                team: action.payload
            };
        case UPDATE_ORG_TEAM:
            return {
                team: action.payload
            };
        default:
            return state;
    }
};

export default settingsReducer;
