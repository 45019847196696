// React
import React, { useState, useEffect } from 'react';

// Packages
import SimpleBar from 'simplebar-react';

// Context
import { useLang } from '../../../context/LangContext';

// Components
import { DocumentModal } from './DocumentModal';

export const SellerDocuments = ({
    data = [],
    setData,
    setRemoved,
    removeSeller,
    checkDisableButtons,
    initialDocuments
}) => {
    const { buttons, modal } = useLang()['Settings']['Preferences']['Documents'];

    const [sellerDocumentModalOpen, setSellerDocumentModalOpen] = useState(false);
    const sellerDocumentModalToggle = () => {
        setSellerDocumentModalOpen(prevState => !prevState);
    };
    const handleRemoveBuyerDocuments = (doc, index) => {
        const exist = removeSeller.includes(data[index]);
        const findAddedDoc = initialDocuments.some(item => item.id.includes(doc.id));
        if (exist) {
            setRemoved(removeSeller.filter(item => item !== data[index]));
        } else if (!findAddedDoc) {
            setData(
                data.map(item => {
                    if (item.id === doc.id) {
                        item.checked = !item.checked;
                    }
                    return item;
                })
            );
        } else {
            setRemoved([...removeSeller, data[index]]);
        }
        checkDisableButtons();
    };
    const onSellerSubmitCb = addedItems => {
        if (addedItems) {
            setData(addedItems.docs);
            setSellerDocumentModalOpen(prevState => !prevState);
        }
        checkDisableButtons();
    };

    useEffect(() => {}, [data]);

    return (
        <>
            <div className="ml-5">
                <button
                    className="btn mb-3 py-1"
                    type="button"
                    style={{
                        backgroundColor: '#FFAA22',
                        color: '#ffffff'
                    }}
                    onClick={sellerDocumentModalToggle}
                >
                    {buttons?.button2}
                </button>
                <SimpleBar
                    style={{
                        overflowY: 'auto',
                        height: '200px',
                        paddingRight: '20px',
                        width: '200px'
                    }}
                >
                    <div>
                        {data.map(
                            (doc, index) =>
                                doc.checked && (
                                    <div
                                        key={doc.id}
                                        style={{
                                            position: 'relative',
                                            marginRight: '15px',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p
                                            className="pr-5 pointer overflow-hidden ellipsis"
                                            style={{
                                                maxWidth: '250px'
                                            }}
                                        >
                                            {doc.title}
                                        </p>
                                        <i
                                            className="fa-regular fa-xmark removePhoneIcon pointer pl-4"
                                            style={{
                                                color:
                                                    removeSeller.includes(doc) &&
                                                    '#de4437',
                                                top: '7px'
                                            }}
                                            onClick={() =>
                                                handleRemoveBuyerDocuments(doc, index)
                                            }
                                        />
                                    </div>
                                )
                        )}
                    </div>
                </SimpleBar>
            </div>
            <DocumentModal
                handleOpen={sellerDocumentModalOpen}
                handleToggle={sellerDocumentModalToggle}
                title={modal.title2}
                allDocuments={data}
                onSubmitCb={onSellerSubmitCb}
                type="seller"
            />
        </>
    );
};
