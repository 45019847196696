// React
import React from 'react';

//Packages
import cx from 'classnames';

const ConnectionsSearchFilter = ({
    loading,
    error,
    settingSearchSuggestions,
    connections,
    isModal,
    placeholder
}) => {
    const connectionInputSearch = input => {
        if (input.trim() !== '') {
            const filteredConnections = [...connections];
            const filteredName = () => {
                const nameSuggestions = [];
                filteredConnections.filter(connection => {
                    const firstName = connection.first_name
                        ? connection.first_name.toLowerCase()
                        : '';
                    const lastName = connection.last_name
                        ? connection.last_name.toLowerCase()
                        : '';
                    const fullName =
                        `${connection.first_name} ${connection.last_name} `.toLowerCase();
                    if (
                        firstName.includes(input.toLowerCase()) ||
                        lastName.includes(input.toLowerCase()) ||
                        fullName.includes(input.toLowerCase())
                    ) {
                        nameSuggestions.push(connection);
                    }
                    return true;
                });
                return nameSuggestions;
            };
            const totalSuggestions = [...filteredName()];
            settingSearchSuggestions(totalSuggestions);
        } else {
            settingSearchSuggestions([]);
        }
    };

    return (
        <div id="connectionsFilters">
            <div
                className={cx('container space-md-0 space-sm-0', {
                    'mt-4': isModal,
                    'space-lg-1': !isModal
                })}
            >
                <div className="row mx-gutters-2 align-items-center">
                    <div className="col-md mb-3 mb-lg-0">
                        <div className="js-focus-state">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="searchProperty"
                                    >
                                        <span className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="text"
                                    id="searchPropertySr"
                                    placeholder={placeholder}
                                    aria-label={placeholder}
                                    aria-describedby="searchProperty"
                                    autoComplete="off"
                                    onChange={e => connectionInputSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectionsSearchFilter;
