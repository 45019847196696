// React
import React, { useState, useCallback } from 'react';

// Components
import { ConnectionsSearchFilter } from '../components';
import { ConnectionList } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLang } from '../context/LangContext';

const Connections = ({ match, userData, loading, userConnections, error }) => {
    const { input_label } = useLang()['Connections']['ConnectionsSearchFilter'];
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const [searchSuggestions, settingSearchSuggestions] = useState(null);

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const sortedConnections = () => {
        const connections = userConnections;
        return connections && connections.length
            ? connections.sort((a, b) => {
                  if (a.last_name && a.first_name && b.last_name && b.first_name) {
                      const result = a.last_name.localeCompare(b.last_name);
                      return result !== 0
                          ? result
                          : a.first_name.localeCompare(b.first_name);
                  } else if (!a.last_name || !a.first_name) {
                      return 1;
                  } else if (!b.last_name || !b.first_name) {
                      return -1;
                  }
                  return true;
              })
            : [];
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <ConnectionsSearchFilter
                loading={loading}
                settingSearchSuggestions={settingSearchSuggestions}
                connections={sortedConnections()}
                error={error}
                placeholder={input_label}
            />
            <div ref={measuredRef}>
                <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                    <div className="container space-bottom-1">
                        <ConnectionList
                            userData={userData}
                            match={match}
                            searchSuggestions={searchSuggestions}
                            connections={sortedConnections()}
                        />
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, connections }) => {
    const { userData } = user;
    const { loading, userConnections, error } = connections;
    return {
        userData,
        loading,
        userConnections,
        error
    };
};

export default withRouter(connect(mapStateToProps, {})(Connections));
