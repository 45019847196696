import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useLang } from '../../../context/LangContext';
import cx from 'classnames';

const StatusDropdown = ({
    statuses,
    disabled,
    editable = true,
    options,
    form: { values },
    onChange
}) => {
    const { teamList } = useLang()['Settings']['Team'];
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    return (
        <>
            {!editable ? (
                <span
                    style={{ textTransform: 'capitalize' }}
                    className={cx({
                        'office-status': statuses,
                        'office-status--active':
                            values?.status === teamList?.statuses?.active_label,
                        'office-status--inactive':
                            values?.status === teamList?.statuses?.inactive_label
                    })}
                >
                    {values?.status || values?.type}
                </span>
            ) : (
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    disabled={disabled}
                >
                    <DropdownToggle
                        style={{
                            width: '100%',
                            borderRadius: '6px',
                            padding: '5px 10px',
                            height: '32px',
                            boxShadow: disabled && 'none'
                        }}
                        className="d-flex justify-content-between align-items-center settings-dropdown-toggle"
                        disabled={disabled}
                        caret
                    >
                        <span
                            style={{ textTransform: 'capitalize' }}
                            className={cx({
                                'office-status': statuses,
                                'office-status--active':
                                    values?.status === teamList?.statuses?.active_label,
                                'office-status--inactive':
                                    values?.status === teamList?.statuses?.inactive_label
                            })}
                        >
                            {values?.status || values?.type}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu className="w-100 p-0" right>
                        {options?.map(option => (
                            <DropdownItem
                                className={cx({
                                    'office-status office-status-item ': statuses,
                                    'office-status--active':
                                        option.name === teamList?.statuses?.active_label,
                                    'office-status--inactive':
                                        option.name === teamList?.statuses?.inactive_label
                                })}
                                style={{
                                    textTransform: 'capitalize'
                                }}
                                key={option.name}
                                onClick={() => {
                                    onChange(option);
                                }}
                            >
                                {option.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            )}
        </>
    );
};

export default StatusDropdown;
