import React from 'react';
import ContentLoader from 'react-content-loader';

const AgentCardSkeleton = props => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={245}
        viewBox="0 0 350 320"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ position: 'absolute' }}
        {...props}
    >
        <circle cx="50%" cy="100" r="100" />
        <rect x="0" y="220" rx="3" ry="3" width="150" height="40" />
        <rect x="0" y="276" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="292" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="315" rx="3" ry="3" width="178" height="6" />
    </ContentLoader>
);

export default AgentCardSkeleton;
