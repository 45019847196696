// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Link, useHistory } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

// Utils
import { formatDocumentDate } from '../../utils/Helpers';

// Components
import DropdownMenuComponent from '../common/DropdownMenu';
import { documentsPackTabs } from '../../utils/Constants';

const DocumentRow = ({
    document,
    lang,
    removeUserDocument,
    removeUserDocumentsPack,
    handleAddDocToPack,
    availablePackTab,
    dragDisable,
    library
}) => {
    const { title, name, upload_type, uploaded_at, id, pack } = document;
    const history = useHistory();
    const type = history.location.pathname;
    const {
        buyer_label,
        seller_label,
        other_label,
        button_label,
        pack_label,
        pack_button_label,
        file_name_label,
        upload_date_label,
        documents_pack_label
    } = useLang()['Documents']['DocumentRow'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];
    const avaliableList = availablePackTab === documentsPackTabs.avaliableDocs;

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: pack
                ? () => removeUserDocumentsPack({ document })
                : () => removeUserDocument({ document, type })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () =>
                history.push({
                    pathname: `${routes.AUTHENTICATED}${
                        pack
                            ? library
                                ? routes.EDIT_ORG_PACK
                                : routes.EDIT_PACK
                            : routes.VIEW_DOCUMENT
                    }/${id}`,
                    state: { id }
                })
        }
    ];

    return (
        <div className="card document-list" style={{ borderRadius: 0 }}>
            <div className="card-body p-3">
                <div className="d-sm-flex justify-content-between align-items-center">
                    {handleAddDocToPack &&
                        !avaliableList &&
                        (!dragDisable ? (
                            <i className="fa-solid fa-grip-lines lang_drag" />
                        ) : (
                            <i className="fa-solid fa-not-equal lang_drag" />
                        ))}
                    <div className="ml-1 mb-2 mb-sm-0 card-col-0">
                        <span className={`badge badge-${upload_type}`}>
                            {(upload_type !== 'seller'
                                ? upload_type === 'buyer'
                                    ? buyer_label
                                    : other_label
                                : seller_label
                            ).concat(pack ? pack_label : '')}
                        </span>
                    </div>
                    <div className="mr-3 mb-2 mb-sm-0 card-col-10">
                        <h2 className="h6 mb-0">{title}</h2>
                    </div>
                    <div className="mr-3 mb-3 mb-sm-0 card-col-20">
                        <Link
                            className="font-size-1"
                            href="transaction-detail.html"
                            to={{
                                pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${id}`,
                                state: { id }
                            }}
                        >
                            {pack ? documents_pack_label : `${file_name_label}: ${name}`}
                        </Link>
                        <small className="d-block text-muted">{`${upload_date_label}:  ${formatDocumentDate(
                            uploaded_at.seconds,
                            lang
                        )}`}</small>
                    </div>
                    <div className="mr-3 card-col-2">
                        <Link
                            to={{
                                pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${id}`,
                                state: { id }
                            }}
                            className={
                                'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                            }
                        >
                            {pack ? pack_button_label : button_label}
                            <span className="fas fa-angle-right ml-1" />
                        </Link>
                    </div>
                    {handleAddDocToPack ? (
                        avaliableList ? (
                            <div
                                className="btn btn-xs btn-primary "
                                onClick={() => handleAddDocToPack(document)}
                            >
                                <span className="fas fa-plus" />
                            </div>
                        ) : (
                            <i
                                className="fa-regular fa-xmark removePhoneIcon pointer"
                                style={{
                                    color: '#000000',
                                    top: '7px'
                                }}
                                onClick={() => handleAddDocToPack(document)}
                            />
                        )
                    ) : (
                        <DropdownMenuComponent dropItems={dropItems} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentRow;
