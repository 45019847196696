import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import { useLang } from '../../context/LangContext';

import AgentCard from './AgentCard';

const AgentList = ({ agentList, searchSuggestions, userData }) => {
    const { none_found_label } = useLang()['Organization'];
    const agents =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : agentList;

    return (
        <div className="mb-4 w-100">
            {agents?.length ? (
                <div className={agents.length > 3 ? 'card-grid' : 'card-grid-single'}>
                    {agents.map(agent => (
                        <AgentCard key={agent.id} agent={agent} user={userData} />
                    ))}
                </div>
            ) : (
                <Alert color="info" className="mb-0 mt-2">
                    {none_found_label}
                </Alert>
            )}
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps, {})(AgentList);
