import {
    VALIDATE_ORG_INVITATION,
    VALIDATE_ORG_INVITATION_ERROR,
    SET_INVITATION_VALIDITY,
    CONFIRM_EXISTING_USER_INVITATION,
    CONFIRM_EXISTING_USER_INVITATION_SUCCESS,
    CONFIRM_EXISTING_USER_INVITATION_FAILURE,
    CONFIRM_NEW_USER_INVITATION,
    CONFIRM_NEW_USER_INVITATION_SUCCESS,
    CONFIRM_NEW_USER_INVITATION_FAILURE,
    PREPARE_NEW_USER_START,
    NEW_USER_START_SUCCESS,
    NEW_USER_START_FAILURE,
    DECLINE_NEW_USER_INVITATION,
    DECLINE_NEW_USER_INVITATION_SUCCESS,
    DECLINE_NEW_USER_INVITATION_FAILURE
} from './types';

export const validateOrgInvitation = data => {
    return {
        type: VALIDATE_ORG_INVITATION,
        payload: data
    };
};

export const orgInvitationValidationFailure = error => {
    return {
        type: VALIDATE_ORG_INVITATION_ERROR,
        payload: error
    };
};

export const setInvitationValidity = data => {
    return {
        type: SET_INVITATION_VALIDITY,
        payload: data
    };
};

export const confirmExistingUserInvitation = invitation => {
    return {
        type: CONFIRM_EXISTING_USER_INVITATION,
        payload: invitation
    };
};

export const confirmExistingInvitationSuccess = () => {
    return {
        type: CONFIRM_EXISTING_USER_INVITATION_SUCCESS
    };
};

export const confirmExistingInvitationFailure = error => {
    return {
        type: CONFIRM_EXISTING_USER_INVITATION_FAILURE,
        payload: error
    };
};

export const confirmNewUserInvitation = invitation => {
    return {
        type: CONFIRM_NEW_USER_INVITATION,
        payload: invitation
    };
};

export const confirmNewInvitationSuccess = user => {
    return {
        type: CONFIRM_NEW_USER_INVITATION_SUCCESS,
        payload: user
    };
};

export const confirmNewInvitationFailure = error => {
    return {
        type: CONFIRM_NEW_USER_INVITATION_FAILURE,
        payload: error
    };
};

export const prepareNewUserStart = () => {
    return {
        type: PREPARE_NEW_USER_START
    };
};

export const newUserStartSuccess = () => {
    return {
        type: NEW_USER_START_SUCCESS
    };
};

export const newUserStartFailure = error => {
    return {
        type: NEW_USER_START_FAILURE,
        payload: error
    };
};

export const decliningNewUserInvitation = () => {
    return {
        type: DECLINE_NEW_USER_INVITATION
    };
};

export const declineNewUserInvitationSuccess = () => {
    return {
        type: DECLINE_NEW_USER_INVITATION_SUCCESS
    };
};

export const declineNewUserInvitationFailure = error => {
    return {
        type: DECLINE_NEW_USER_INVITATION_FAILURE,
        payload: error
    };
};
