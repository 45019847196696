import React from 'react';
import { confirmationDialogTypes } from '../../utils/Constants';

const TransactionSuggestionList = ({
    searchSuggestions,
    setFieldValue,
    index,
    setActiveFieldSuggestion,
    setConfirmModalType,
    setConnection,
    arrayHelpers,
    buyerTransaction,
    setIdCount,
    idCount,
    sameAddress
}) => {
    const handleSelect = value => {
        setFieldValue(`parties.${index}.firstName`, value.first_name || '');
        setFieldValue(`parties.${index}.lastName`, value.last_name || '');
        setFieldValue(`parties.${index}.fullLegalName`, value.legal_name || '');
        setFieldValue(`parties.${index}.email`, value.email || '');
        setFieldValue(`parties.${index}.phone`, value.phone || '');
        setFieldValue(`parties.${index}.pipelineId`, value.pipeline_id || '');
        if (buyerTransaction || !sameAddress) {
            setFieldValue(`parties.${index}.address`, value.address.address_1 || '');
            setFieldValue(`parties.${index}.address2`, value.address.address_2 || '');
            setFieldValue(`parties.${index}.city`, value.address.city || '');
            setFieldValue(`parties.${index}.state`, value.address.state || '');
            setFieldValue(`parties.${index}.zip`, value.address.zip || '');
        }
        if (value.connection) {
            setConfirmModalType(confirmationDialogTypes.useConnection);
            setConnection(value?.connection);
        } else {
            for (let i = 1; i < idCount; i++) {
                arrayHelpers.pop();
            }
            setIdCount(1);
        }
        setActiveFieldSuggestion(null);
    };

    return searchSuggestions && searchSuggestions.length ? (
        <ul
            className="auto-complete-container"
            style={{
                position: 'absolute',
                zIndex: 1000
            }}
        >
            {searchSuggestions.slice(0, 5).map(suggestion => (
                <li key={suggestion.id} className="auto-complete-item">
                    <div
                        className="auto-complete-item ellipsis"
                        onClick={() => handleSelect(suggestion)}
                    >
                        {
                            <b>
                                {suggestion.first_name} {suggestion.last_name}
                            </b>
                        }
                        {suggestion.email && `, ${suggestion.email}`}
                    </div>
                </li>
            ))}
        </ul>
    ) : null;
};

export default TransactionSuggestionList;
