import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { Loader } from '../components';

import * as routes from '../router/config/routes';

import { connect } from 'react-redux';
import { validateSigningRequest } from '../store/actions/DocumentSigning';

const FormSigningService = ({
    authUser,
    requestDetails,
    isRequestChecked,
    isUserExisted,
    isValid,
    validateSigningRequest
}) => {
    const history = useHistory();
    const location = useLocation();
    const query = useMemo(
        () => new URLSearchParams(location.search) || null,
        [location.search]
    );

    useEffect(() => {
        if (location && query && !requestDetails) {
            const params = {
                userId: query.get('user_id'),
                docId: query.get('doc_id'),
                email: query.get('email'),
                inviteCode: query.get('invite_code')
            };
            validateSigningRequest(params);
        }
    }, [requestDetails, location, query, validateSigningRequest]);

    useEffect(() => {
        if (isRequestChecked && requestDetails) {
            if (authUser) {
                if (isValid) {
                    if (authUser.email === requestDetails.email) {
                        history.push({
                            pathname: `${routes.AUTHENTICATED}${routes.FORM_SIGNING}/${requestDetails.docId}`
                        });
                    } else {
                        history.push({
                            pathname: routes.INVITATION_ERROR,
                            state: { isSigningInvitation: true }
                        });
                    }
                } else {
                    history.push({
                        pathname: routes.INVITATION_ERROR,
                        state: { isSigningInvitation: true }
                    });
                }
            } else if (isUserExisted) {
                history.push({
                    pathname: routes.LOGIN
                });
            } else {
                console.log('new user flow');
            }
        }
    }, [isRequestChecked, requestDetails, authUser, history, isUserExisted, isValid]);

    return <Loader />;
};

const mapStateToProps = ({ auth, documentSigning }) => {
    const { authUser } = auth;
    const { requestDetails, isRequestChecked, isValid, isUserExisted } = documentSigning;
    return {
        authUser,
        requestDetails,
        isRequestChecked,
        isUserExisted,
        isValid
    };
};

export default withRouter(
    connect(mapStateToProps, { validateSigningRequest })(FormSigningService)
);
