// React
import React, { useState, useEffect, useCallback, useMemo } from 'react';

// Components
import {
    DetailsHeader,
    TransactionMeta,
    TransactionInterface,
    Tasks,
    Loader,
    DocumentView,
    AppHeader
} from '../components';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Firebase
import { storage } from '../config/Firebase';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Loggers
import { log } from '../utils/Loggers';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isTablet } from 'react-device-detect';

// Redux - Actions, Reducers, Sagas
import {
    gettingTransactionDetails,
    settingClientTransactionDetails,
    resetDetailsFlag,
    cancelDetailsListener,
    updateTransactionStatus
} from '../store/actions/Transactions';
import {
    addDocumentToTrx,
    removeDocumentFromTrx,
    settingTrxDocumentsFilter
} from '../store/actions/Documents';

// Constants
import { headerTypes, userTypes } from '../utils/Constants';

const TransactionDetails = ({
    match,
    location,
    history,
    transactionDetails,
    userDocuments,
    orgDocuments,
    gettingTransactionDetails,
    settingClientTransactionDetails,
    detailsFlag,
    resetDetailsFlag,
    cancelDetailsListener,
    userData,
    addDocumentToTrx,
    removeDocumentFromTrx,
    filteredTransactionDocuments,
    trxDetailsFilterType,
    settingTrxDocumentsFilter,
    transactionActivity,
    updateTransactionStatus,
    modalDialogType,
    userTransactions,
    closedTransactions,
    companyContacts,
    team,
    brokerages
}) => {
    const [tabletDevice, setTabletDevice] = useState(isTablet);
    const [imgByIds, setImgByIds] = useState([]);
    const [documentView, setDocumentView] = useState(null);
    const { windowHeight, windowWidth } = useWindowSize();
    const transaction = transactionDetails;
    const activity = transactionActivity;
    const isClient = useMemo(() => userData?.type === userTypes.client, [userData]);

    const lastViewTime = useMemo(() => {
        const currentTrx =
            userTransactions &&
            userTransactions.find(el => el.id === transactionDetails?.id);
        return (
            currentTrx && currentTrx.visitors && currentTrx.visitors[userData.id]?.seconds
        );
    }, [transactionDetails, userData.id, userTransactions]);

    const allDocs = useMemo(
        () => [].concat(userDocuments, orgDocuments),
        [userDocuments, orgDocuments]
    );

    const getTrxId = useCallback(() => {
        const pathname = window.location.pathname;
        const id = location.state?.id || pathname.substr(pathname.lastIndexOf('/') + 1);
        if (!transactionDetails && userData) {
            if (userData?.type === userTypes.client) {
                if (userTransactions && closedTransactions) {
                    settingClientTransactionDetails({
                        trxId: id,
                        detailsFlag: false
                    });
                }
            } else {
                gettingTransactionDetails({
                    trxId: id,
                    detailsFlag: false
                });
            }
        }
        return id;
    }, [
        transactionDetails,
        gettingTransactionDetails,
        location,
        settingClientTransactionDetails,
        userData,
        userTransactions,
        closedTransactions
    ]);

    useEffect(() => {
        return () => {
            cancelDetailsListener();
        };
    }, [cancelDetailsListener]);

    useEffect(() => {
        getTrxId();
    }, [getTrxId]);

    useEffect(() => {
        if (detailsFlag) {
            resetDetailsFlag();
        }
    }, [detailsFlag, resetDetailsFlag]);

    useEffect(() => {
        setTabletDevice(isTablet);
    }, [windowHeight, windowWidth]);

    const getUserAvatars = ids => {
        const imgObjArr = [];
        ids.forEach(id => {
            if (id) {
                const ref = storage.ref().child(`users/${id}/avatar.jpg`);
                ref.getDownloadURL()
                    .then(imgUrl => {
                        imgObjArr.push({ id, url: imgUrl });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                    })
                    .catch(error => {
                        imgObjArr.push({ id, url: null, err: error.code_ });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                        log('Transaction Details Avatar: error in getting avatar (FBS)', {
                            error,
                            storage_path: `users/${id}/avatar.jpg`,
                            function: 'getUserAvatars'
                        });
                    });
            } else {
                imgObjArr.push({ id: null, url: null });
            }
        });
    };

    useEffect(() => {
        if (transaction?.avatars?.length > 0) {
            if (imgByIds?.length === 0) {
                getUserAvatars([...transaction.avatars]);
            } else {
                transaction.avatars.every(id => {
                    if (!imgByIds.some(img => img.id === id)) {
                        getUserAvatars([...transaction.avatars]);
                        return false;
                    }
                    return true;
                });
            }
        }
    }, [transaction, imgByIds]);

    if (!transaction || !userData || !transactionActivity) return <Loader />;

    return (
        <>
            <ConfirmationDialog initialModalDialogType={modalDialogType} />
            {documentView && (
                <DocumentView
                    allDocs={[...userDocuments, ...orgDocuments]}
                    setDocumentView={setDocumentView}
                    documentView={documentView}
                />
            )}
            <AppHeader match={match} history={history} shouldHideTabs />
            <DetailsHeader
                headerType={headerTypes.transactionDetails}
                transaction={transaction}
                updateTransactionStatus={updateTransactionStatus}
                userData={userData}
                numberOfUserTransactions={userTransactions?.length}
            />
            <main id="content" role="main" className="push-main-lg">
                <div className="bg-light">
                    <div id="main" className="size container pt-3 pt-xl-4">
                        {tabletDevice ? (
                            <>
                                {windowWidth >= 1366 ? (
                                    <>
                                        <div className="row no-gutters">
                                            <TransactionMeta
                                                transaction={transaction}
                                                avatars={imgByIds}
                                                isClient={isClient}
                                                companyContacts={companyContacts}
                                                teamMembers={team}
                                                currentUserId={userData?.id}
                                                brokerages={brokerages}
                                            />
                                            <TransactionInterface
                                                transaction={transaction}
                                                activity={activity}
                                                match={match}
                                                avatars={imgByIds}
                                                allDocs={allDocs}
                                                userData={userData}
                                                addDocumentToTrx={addDocumentToTrx}
                                                removeDocumentFromTrx={
                                                    removeDocumentFromTrx
                                                }
                                                setDocumentView={setDocumentView}
                                                documentFilter={trxDetailsFilterType}
                                                settingTrxDocumentsFilter={
                                                    settingTrxDocumentsFilter
                                                }
                                                filteredDocs={
                                                    filteredTransactionDocuments
                                                }
                                                lastViewTime={lastViewTime}
                                            />
                                            <Tasks
                                                transaction={transaction}
                                                avatars={imgByIds}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className="no-gutters"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: 1
                                        }}
                                    >
                                        <TransactionMeta
                                            transaction={transaction}
                                            avatars={imgByIds}
                                            isClient={isClient}
                                            companyContacts={companyContacts}
                                            teamMembers={team}
                                            currentUserId={userData?.id}
                                            brokerages={brokerages}
                                        />
                                        <div className="row no-gutters">
                                            <TransactionInterface
                                                transaction={transaction}
                                                activity={activity}
                                                match={match}
                                                avatars={imgByIds}
                                                allDocs={allDocs}
                                                userData={userData}
                                                addDocumentToTrx={addDocumentToTrx}
                                                removeDocumentFromTrx={
                                                    removeDocumentFromTrx
                                                }
                                                setDocumentView={setDocumentView}
                                                documentFilter={trxDetailsFilterType}
                                                settingTrxDocumentsFilter={
                                                    settingTrxDocumentsFilter
                                                }
                                                filteredDocs={
                                                    filteredTransactionDocuments
                                                }
                                                lastViewTime={lastViewTime}
                                            />
                                            <Tasks
                                                transaction={transaction}
                                                avatars={imgByIds}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="row no-gutters">
                                <TransactionMeta
                                    transaction={transaction}
                                    avatars={imgByIds}
                                    isClient={isClient}
                                    companyContacts={companyContacts}
                                    teamMembers={team}
                                    currentUserId={userData?.id}
                                    brokerages={brokerages}
                                />
                                <TransactionInterface
                                    transaction={transaction}
                                    activity={activity}
                                    match={match}
                                    avatars={imgByIds}
                                    allDocs={allDocs}
                                    userData={userData}
                                    addDocumentToTrx={addDocumentToTrx}
                                    removeDocumentFromTrx={removeDocumentFromTrx}
                                    setDocumentView={setDocumentView}
                                    documentFilter={trxDetailsFilterType}
                                    settingTrxDocumentsFilter={settingTrxDocumentsFilter}
                                    filteredDocs={filteredTransactionDocuments}
                                    lastViewTime={lastViewTime}
                                />
                                <Tasks transaction={transaction} avatars={imgByIds} />
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({
    transactions,
    documents,
    user,
    activity,
    modal,
    contacts,
    teams,
    property
}) => {
    const { userData } = user;
    const { companyContacts } = contacts;
    const { team } = teams;
    const { transactionDetails, detailsFlag, userTransactions, closedTransactions } =
        transactions;
    const {
        userDocuments,
        orgDocuments,
        filteredTransactionDocuments,
        trxDetailsFilterType
    } = documents;
    const { transactionActivity } = activity;
    const { modalDialogType } = modal;
    const { brokerages } = property;
    return {
        userDocuments,
        orgDocuments,
        transactionDetails,
        detailsFlag,
        userData,
        filteredTransactionDocuments,
        trxDetailsFilterType,
        transactionActivity,
        modalDialogType,
        userTransactions,
        closedTransactions,
        companyContacts,
        team,
        brokerages
    };
};

export default withRouter(
    connect(mapStateToProps, {
        gettingTransactionDetails,
        settingClientTransactionDetails,
        resetDetailsFlag,
        cancelDetailsListener,
        addDocumentToTrx,
        removeDocumentFromTrx,
        settingTrxDocumentsFilter,
        updateTransactionStatus
    })(TransactionDetails)
);
