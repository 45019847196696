// React
import React from 'react';

// Assets
import PDF from '../../assets/svg/pdf-file.svg';
import PDF_PACK from '../../assets/svg/pdf-pack.svg';

// Context
import { useLang } from '../../context/LangContext';

// Router
import { Link } from 'react-router-dom';
import * as routes from '../../router/config/routes';

// Components
import DropdownMenuComponent from '../common/DropdownMenu';

// Packages
import { documentsPackTabs } from '../../utils/Constants';
import { useHistory } from 'react-router-dom';

const DocumentCard = ({
    document,
    removeUserDocument,
    removeUserDocumentsPack,
    handleAddDocToPack,
    availablePackTab,
    library
}) => {
    const { title, upload_type, id, pack } = document;
    const history = useHistory();
    const type = history.location.pathname;
    const { seller_label, buyer_label, other_label, pack_label } =
        useLang()['Documents']['DocumentCard'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];

    const avaliableList = availablePackTab === documentsPackTabs.avaliableDocs;
    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: pack
                ? () => removeUserDocumentsPack({ document })
                : () => removeUserDocument({ document, type })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () =>
                history.push({
                    pathname: `${routes.AUTHENTICATED}${
                        pack
                            ? library
                                ? routes.EDIT_ORG_PACK
                                : routes.EDIT_PACK
                            : routes.VIEW_DOCUMENT
                    }/${id}`,
                    state: { id }
                })
        }
    ];

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="col-6">
                    <span className={`badge badge-pill badge-${upload_type}`}>
                        {(upload_type !== 'seller'
                            ? upload_type === 'buyer'
                                ? buyer_label
                                : other_label
                            : seller_label
                        ).concat(pack ? pack_label : '')}
                    </span>
                </div>
                <div className="justify-content-end pt-1">
                    {handleAddDocToPack ? (
                        avaliableList ? (
                            <div
                                className=" btn badge badge-other rounded-cirkle mt-2 mr-2"
                                onClick={() => handleAddDocToPack(document)}
                            >
                                <span className="fas fa-plus" />
                            </div>
                        ) : (
                            <i
                                className="fa-regular fa-xmark removePhoneIcon pointer"
                                style={{
                                    color: '#000000',
                                    top: '7px'
                                }}
                                onClick={() => handleAddDocToPack(document)}
                            />
                        )
                    ) : (
                        <DropdownMenuComponent dropItems={dropItems} />
                    )}
                </div>
            </div>

            <Link
                className="font-size-1 d-block text-truncate"
                to={{
                    pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${id}`,
                    state: { id }
                }}
            >
                <div
                    className="mt-5"
                    style={{ width: '100%', height: '185px', maxHeight: '185px' }}
                >
                    <img
                        src={pack ? PDF_PACK : PDF}
                        className="card-img-top w-100"
                        style={{ width: '75%', height: '75%' }}
                        alt="document"
                    />
                </div>

                <div className="card-body pt-0 pl-4 pr-4 pb-4">
                    <div
                        className="mb-2"
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            color: '#377dff'
                        }}
                    >
                        {title && <h2 className="h6 mb-0 ellipsis">{title}</h2>}
                    </div>
                </div>
            </Link>
        </>
    );
};

export default DocumentCard;
