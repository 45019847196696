// React
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import Avatar from 'react-avatar';

// Components
import { EditTaskModal } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Firebase
import { timeStampNow, timeStampJs } from '../../config/Firebase';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { isTablet } from 'react-device-detect';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

// Redux - Actions, Reducers, Sagas
import { updateTransactionTasks } from '../../store/actions/Transactions';

// Utils
import { formatTaskDate, setTaskStatus } from '../../utils/Helpers';
import { activityMessage, trxStatus } from '../../utils/Constants';

const Tasks = ({
    transaction,
    loading,
    updateTransactionTasks,
    userData,
    uploadError,
    avatars,
    isMember
}) => {
    const [allTasks, setAllTasks] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const [taskListHeight, setTaskListHeight] = useState(0);
    const [overFlow, setOverFlow] = useState('');
    const [buttonState, setButtonState] = useState('');
    const [isIpadRegular, setIsIpadRegular] = useState();
    const [editModal, setEditModal] = useState(false);
    const [selectedTaskHold, setSelectedTaskHold] = useState(null);
    const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
    const [taskTitle, setTaskTitle] = useState('');
    const [taskContent, setTaskContent] = useState('');
    const [taskAssignees, setTaskAssignees] = useState([]);
    const [taskDate, setTaskDate] = useState(null);
    const [status, setStatus] = useState('');
    const [tasks, setTasks] = useState([]);
    const [pristine, setPristine] = useState(true);
    const [activeUpload, setActiveUpload] = useState(false);
    const containerRef = useRef();
    const taskListRef = useRef();
    const taskRef = useRef();
    const scrollAnchor = useRef();
    const { windowHeight, windowWidth } = useWindowSize();
    const iPadProPixels = 2390;
    const totalWindowPixels = windowHeight + windowWidth;
    const {
        all_tasks_button_label,
        less_tasks_button_label,
        hide_history_button_label,
        show_history_button_label,
        add_task_button_label,
        no_tasks_label
    } = useLang()['TransactionDetails']['Tasks'];
    const isTransactionArchived = useMemo(
        () => transaction?.status === trxStatus.archived,
        [transaction]
    );

    const toggleEdit = useCallback(({ reset, cancel }) => {
        if (reset || cancel) {
            setActiveUpload(false);
            setEditModal(edit => !edit);
        } else {
            setEditModal(edit => !edit);
        }
    }, []);

    const orderedTasks = tasks => {
        const taskData = [...tasks];
        const complete = [...taskData].filter(task => task.status === 'complete');
        const open = [...taskData].filter(task => task.status !== 'complete');
        return tasks && tasks.length
            ? [
                  ...open.sort((a, b) => a.date_time.seconds - b.date_time.seconds),
                  ...complete.sort((a, b) => a.date_time.seconds - b.date_time.seconds)
              ]
            : [];
    };

    const taskButtonHandler = () => {
        if (allTasks && tasks.length !== 0) {
            scrollAnchor.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
        }
        setAllTasks(!allTasks);
    };

    const openTaskCount =
        tasks &&
        tasks.length &&
        tasks.reduce((n, task) => {
            return n + (task.status === 'open');
        }, 0);

    const setRefIndex = useCallback(() => {
        if (openTaskCount > 0) {
            return openTaskCount >= 2 ? 1 : 0;
        } else {
            return null;
        }
    }, [openTaskCount]);

    const initialTasksHeight = useCallback(() => {
        if (setRefIndex()) {
            if (isIpadRegular || windowWidth < 992) {
                return tasks.length >= 2 && taskRef.current
                    ? taskRef.current.clientHeight +
                          taskRef.current.previousSibling.clientHeight +
                          32
                    : taskRef.current.clientHeight + 16;
            } else {
                return tasks.length > 2 && taskRef.current
                    ? taskRef.current.clientHeight +
                          taskRef.current.previousSibling.clientHeight +
                          taskRef.current.nextSibling.clientHeight +
                          48
                    : tasks.length > 1
                    ? taskRef.current.clientHeight +
                      taskRef.current.previousSibling.clientHeight +
                      32
                    : taskRef.current.clientHeight + 16;
            }
        }
        return 'auto';
    }, [setRefIndex, isIpadRegular, windowWidth, tasks.length]);

    const hideButton = () => {
        if (isIpadRegular || windowWidth < 992) {
            return tasks && tasks.length < 3;
        } else {
            return tasks && tasks.length < 4;
        }
    };

    const editTask = (task, index) => {
        if (isTransactionArchived || isMember) return;
        setSelectedTaskHold(task);
        setSelectedTaskIndex(index);
        setTaskTitle(task.title);
        setTaskContent(task.content);
        setTaskAssignees(task.assignees);
        setTaskDate(task.date_time.toDate());
        setStatus(task.status);
        setActiveUpload(active => !active);
        toggleEdit({ reset: false, cancel: false });
    };

    const updateTask = ({ data }) => {
        setPristine(false);
        const taskData = tasks && tasks.length ? [...tasks] : [];
        const formattedTask = {
            assignees: taskAssignees,
            content: taskContent,
            created_at: selectedTaskHold.created_at,
            created_by: selectedTaskHold.created_by,
            date_time: timeStampJs.fromDate(taskDate),
            edited_at: timeStampNow(),
            status: data ? data : status,
            title: taskTitle
        };
        if (data) {
            if (data === 'complete') {
                const formattedActivity = {
                    archived_at: null,
                    attachments: [],
                    automated: {
                        type: 'task',
                        name: taskTitle
                    },
                    created_at: timeStampNow(),
                    creator_id: userData.id,
                    creator_type: 'user',
                    edited_at: null,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    message: activityMessage.completeTask
                };

                taskData.splice(selectedTaskIndex, 1);
                taskData.push(formattedTask);
                updateTransactionTasks({
                    tasks: taskData,
                    trxId: transaction.id,
                    posts: formattedActivity,
                    status: transaction?.status
                });
            } else {
                const formattedActivity = {
                    archived_at: null,
                    attachments: [],
                    automated: {
                        type: 'task',
                        name: taskTitle
                    },
                    created_at: timeStampNow(),
                    creator_id: userData.id,
                    creator_type: 'user',
                    edited_at: null,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    message: activityMessage.openTask
                };
                taskData[selectedTaskIndex] = { ...formattedTask };
                updateTransactionTasks({
                    tasks: taskData,
                    trxId: transaction.id,
                    posts: formattedActivity,
                    status: transaction?.status
                });
            }
        } else {
            const formattedActivity = {
                archived_at: null,
                attachments: [],
                automated: {
                    type: 'task',
                    name: taskTitle
                },
                created_at: timeStampNow(),
                creator_id: userData.id,
                creator_type: 'user',
                edited_at: null,
                first_name: userData.first_name,
                last_name: userData.last_name,
                message: activityMessage.updateTask
            };
            taskData[selectedTaskIndex] = { ...formattedTask };
            updateTransactionTasks({
                tasks: taskData,
                trxId: transaction.id,
                posts: formattedActivity,
                status: transaction?.status
            });
        }
    };

    const deleteTask = () => {
        const taskData = tasks && tasks.length ? [...tasks] : [];
        const formattedActivity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'task',
                name: taskTitle
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: activityMessage.deleteTask
        };
        taskData.splice(selectedTaskIndex, 1);
        updateTransactionTasks({
            tasks: taskData,
            trxId: transaction.id,
            posts: formattedActivity,
            status: transaction?.status
        });
        toggleEdit({ reset: true, cancel: false });
    };

    useEffect(() => {
        if (transaction?.tasks?.length) {
            setTasks(orderedTasks(transaction.tasks));
        } else {
            setTasks([]);
        }
    }, [transaction]);

    useEffect(() => {
        if (activeUpload) {
            if (!loading && !uploadError && !pristine) {
                toggleEdit({ reset: true, cancel: false });
                setSelectedTaskHold(null);
                setSelectedTaskIndex(null);
                setTaskTitle('');
                setTaskContent('');
                setTaskDate(null);
                setStatus('');
            } else if (loading) {
                // Do nothing waiting for work to finish
            } else if (uploadError) {
                console.log('notify user of error');
            }
        } else {
            setPristine(true);
        }
    }, [loading, uploadError, activeUpload, pristine, toggleEdit]);

    useEffect(() => {
        const containerHeight = windowHeight - containerRef.current?.offsetTop;
        const listHeight = taskListRef.current?.scrollHeight;
        const maxListHeight =
            tasks.length && openTaskCount === 0
                ? containerHeight * 0.85
                : containerHeight * 0.9;

        setContainerHeight(containerHeight);
        setIsIpadRegular(isTablet && totalWindowPixels < iPadProPixels);

        if (tasks.length) {
            if (openTaskCount > 0) {
                if (!allTasks) {
                    setTaskListHeight(initialTasksHeight());
                    setOverFlow('hidden');
                    setButtonState(all_tasks_button_label);
                } else {
                    setTaskListHeight(
                        listHeight > maxListHeight ? maxListHeight : listHeight
                    );
                    setOverFlow('auto');
                    setButtonState(less_tasks_button_label);
                }
            } else {
                if (allTasks) {
                    setOverFlow('auto');
                    setTaskListHeight(
                        listHeight > maxListHeight ? maxListHeight : listHeight
                    );
                    setButtonState(hide_history_button_label);
                } else {
                    setOverFlow('hidden');
                    setButtonState(show_history_button_label);
                }
            }
        } else {
            setTaskListHeight(listHeight);
            setOverFlow('hidden');
            setButtonState(add_task_button_label);
        }
    }, [
        windowHeight,
        windowWidth,
        allTasks,
        tasks,
        openTaskCount,
        isIpadRegular,
        totalWindowPixels,
        initialTasksHeight,
        all_tasks_button_label,
        less_tasks_button_label,
        hide_history_button_label,
        show_history_button_label,
        add_task_button_label
    ]);

    if (orderedTasks(tasks).length === 0)
        return (
            <div
                className="col-3 pl-3 animate align-items-center"
                style={{
                    textAlign: 'center',
                    marginBottom: '.5rem',
                    color: '#77838f'
                }}
            >
                {no_tasks_label}
            </div>
        );

    if (orderedTasks(tasks).length > 0)
        return (
            <div
                ref={containerRef}
                style={{
                    maxHeight: containerHeight ? containerHeight : 0
                }}
                className="col-3 pl-3 animate"
            >
                <EditTaskModal
                    toggleEdit={toggleEdit}
                    editModal={editModal}
                    selectedTaskHold={selectedTaskHold}
                    taskTitle={taskTitle}
                    taskTeam={[...transaction?.team, ...transaction?.parties]}
                    taskAssignees={taskAssignees}
                    setTaskAssignees={setTaskAssignees}
                    setTaskTitle={setTaskTitle}
                    taskContent={taskContent}
                    setTaskContent={setTaskContent}
                    taskDate={taskDate}
                    setTaskDate={setTaskDate}
                    taskStatus={status}
                    setTaskStatus={setStatus}
                    updateTask={updateTask}
                    deleteTask={deleteTask}
                    loading={loading}
                />
                <div className="card bg-transparent border-0">
                    <div className="pt-0 pt-0">
                        <SimpleBar
                            style={{
                                maxHeight:
                                    overFlow === 'auto'
                                        ? taskListHeight + 32
                                        : taskListHeight,
                                overflowY: overFlow,
                                transition: 'max-height 0.3s ease-in'
                            }}
                        >
                            <ul ref={taskListRef} className="list-unstyled task-list">
                                <div ref={scrollAnchor} />
                                {orderedTasks(tasks).map((task, index) => (
                                    <React.Fragment key={`${task.created_at.seconds}`}>
                                        {setRefIndex() && setRefIndex() === index ? (
                                            <li
                                                ref={taskRef}
                                                className={cx(
                                                    `media rounded d-block shadow-soft mb-3 px-3 py-2 task-${setTaskStatus(
                                                        task
                                                    )} task-card`,
                                                    {
                                                        'bg-white task-animation pointer':
                                                            !isTransactionArchived
                                                    }
                                                )}
                                                onClick={() => editTask(task, index)}
                                                style={{
                                                    backgroundColor: '#e7eaf3'
                                                }}
                                            >
                                                <div className="media-body ">
                                                    <h5 className="font-size-1 mb-1 card-title-text-wrap">
                                                        {task.title}
                                                    </h5>
                                                    <p className="small mb-3 font-weight-medium card-description-text-wrap">
                                                        {task.content}
                                                    </p>
                                                    <small className="d-block text-muted">
                                                        <i className="far fa-clock" />
                                                        <span>
                                                            {formatTaskDate(
                                                                task.date_time.seconds
                                                            )}
                                                        </span>
                                                    </small>
                                                </div>
                                            </li>
                                        ) : (
                                            <li
                                                className={cx(
                                                    `media rounded d-block shadow-soft mb-3 px-3 py-2 task-${setTaskStatus(
                                                        task
                                                    )} task-card`,
                                                    {
                                                        'bg-white task-animation pointer':
                                                            !isTransactionArchived
                                                    }
                                                )}
                                                onClick={() => editTask(task, index)}
                                                style={{
                                                    backgroundColor: '#e7eaf3'
                                                }}
                                            >
                                                <div className="media-body">
                                                    <h5 className="font-size-1 mb-1 card-title-text-wrap">
                                                        {task.title}
                                                    </h5>
                                                    <p className="small font-weight-medium mb-1 card-description-text-wrap">
                                                        {task.content}
                                                    </p>
                                                    {task.assignees && (
                                                        <div
                                                            className="d-flex mb-2"
                                                            style={{
                                                                gap: '5px'
                                                            }}
                                                        >
                                                            {task.assignees.map(
                                                                assignee => (
                                                                    <div
                                                                        key={
                                                                            assignee.value
                                                                        }
                                                                        className="u-sm-avatar u-sm-avatar--bordered rounded-circle p-0"
                                                                    >
                                                                        {avatars.map(
                                                                            avatar => {
                                                                                if (
                                                                                    assignee.value ===
                                                                                    avatar.id
                                                                                ) {
                                                                                    if (
                                                                                        avatar.url
                                                                                    ) {
                                                                                        return (
                                                                                            <img
                                                                                                key={
                                                                                                    avatar.id
                                                                                                }
                                                                                                src={
                                                                                                    avatar.url
                                                                                                }
                                                                                                alt="Assignee avatar"
                                                                                                className="btn btn-sm btn-icon btn-soft-primary rounded-circle"
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                    return (
                                                                                        <Avatar
                                                                                            key={
                                                                                                avatar.id
                                                                                            }
                                                                                            className="btn btn-sm btn-icon btn-soft-primary rounded-circle"
                                                                                            name={
                                                                                                assignee.label
                                                                                            }
                                                                                            unstyled
                                                                                        />
                                                                                    );
                                                                                }
                                                                                return '';
                                                                            }
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    <small className="d-block text-muted">
                                                        <i className="far fa-clock" />{' '}
                                                        <span>
                                                            {formatTaskDate(
                                                                task.date_time.seconds
                                                            )}
                                                        </span>
                                                    </small>
                                                </div>
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </SimpleBar>

                        {!hideButton() && (
                            <button
                                type="button"
                                className="btn btn-xs btn-soft-primary btn-block mb-2"
                                style={{ width: '90%', marginLeft: '5%' }}
                                onClick={taskButtonHandler}
                            >
                                {buttonState}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
};

const mapStateToProps = ({ transactions, user }) => {
    const { loading, uploadError } = transactions;
    const { userData } = user;
    return { loading, userData, uploadError };
};

export default withRouter(connect(mapStateToProps, { updateTransactionTasks })(Tasks));
