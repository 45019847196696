// React
import React, { useState, useEffect } from 'react';

// Assets
import logo from '../assets/img/jada-logo.svg';

// Components
import { Loader, TrxInvRegistrarionForm } from '../components';

// Context
import { useLang } from '../context/LangContext';

// Packages
import SimpleBar from 'simplebar-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { setLanguageLocal } from '../store/actions/Lang';
import { acceptNewUserTrxInvitation } from '../store/actions/TransactionInvitations';

// Utils
import { nameRegExp, phoneRegExp, passwordRegExp } from '../utils/Constants';
import { useHistory, withRouter } from 'react-router-dom';
import * as routes from '../router/config/routes';

const lang = {
    en: 'english',
    es: 'español'
};

const maxSize = 2097152;

const TrxInvitationAcceptance = ({
    setLanguageLocal,
    trxInvitationDetails,
    acceptNewUserTrxInvitation,
    authUser
}) => {
    const history = useHistory();
    const { submit_label, heading, secondary_text } =
        useLang()['TrxInvitationAcceptance'];
    const { errorMessage } = useLang()['Constants'];
    const [appLanguage, setAppLanguage] = useState(lang.en);
    const [longEnough, setLongEnough] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecial, setHasSpecial] = useState(false);
    const [source, setSource] = useState(null);
    const [avatarModal, setAvatarModal] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [activeUpload, setActiveUpload] = useState(false);
    const [pristine, setPristine] = useState(false);

    useEffect(() => {
        if (authUser) {
            history.push({
                pathname: routes.ACCEPT_TEAM_INVITATION
            });
        }
    }, [authUser, history]);

    const setLanguage = () => {
        switch (appLanguage) {
            case lang.es:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
            case lang.en:
                setLanguageLocal({ origin: 'es' });
                setAppLanguage(lang.es);
                break;
            default:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
        }
    };

    const initialValues = {
        firstName: '',
        lastName: '',
        fullName: '',
        email: trxInvitationDetails?.email,
        password: '',
        phone: '',
        avatar: ''
    };

    const validationSchema = Yup.object().shape({
        avatar: Yup.string(),
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        firstName: Yup.string()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        lastName: Yup.string()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        fullName: Yup.string().matches(nameRegExp.format, errorMessage.fullName.valid),
        password: Yup.string()
            .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
            .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
            .matches(passwordRegExp.numeric, errorMessage.password.numeric)
            .matches(passwordRegExp.special, errorMessage.password.special)
            .min(8, errorMessage.password.length)
            .required(errorMessage.password.required),
        phone: Yup.string().matches(phoneRegExp.format, errorMessage.phone.valid)
    });

    if (!trxInvitationDetails) return <Loader />;

    return (
        <div id="content" className="push-main pt-0">
            <div className="bg-light">
                <SimpleBar style={{ overflowY: 'auto', height: '100%' }}>
                    <button
                        className="btn btn-xs btn-text-secondary"
                        type="button"
                        onClick={setLanguage}
                        style={{
                            position: 'absolute',
                            margin: '15px',
                            top: 0,
                            right: '30px'
                        }}
                    >
                        <span className="position-relative">
                            <span
                                className="u-sidebar--account__toggle-text"
                                style={{
                                    textDecoration: 'underline'
                                }}
                            >
                                {appLanguage === 'english' ? lang.es : 'inglés'}
                            </span>
                        </span>
                    </button>
                    <div className="container space-2">
                        <div className="mx-lg-auto">
                            <div className="card pt-5">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    validate={values => {
                                        values.password.length < 8
                                            ? setLongEnough(false)
                                            : setLongEnough(true);
                                        values.password.match(passwordRegExp.upperCase)
                                            ? setHasUppercase(true)
                                            : setHasUppercase(false);
                                        values.password.match(passwordRegExp.numeric)
                                            ? setHasNumber(true)
                                            : setHasNumber(false);
                                        values.password.match(passwordRegExp.special)
                                            ? setHasSpecial(true)
                                            : setHasSpecial(false);
                                    }}
                                    onSubmit={values => {
                                        acceptNewUserTrxInvitation({
                                            formData: values,
                                            invitation: trxInvitationDetails
                                        });
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        touched,
                                        values,
                                        isValid,
                                        dirty
                                    }) => (
                                        <>
                                            <Form>
                                                <div className="jada-logo d-flex justify-content-center align-items-center flex-column mb-4">
                                                    <img
                                                        src={logo}
                                                        className="logo mb-4"
                                                        style={{ width: '190px' }}
                                                        alt="Jada Real Estate Real Simple"
                                                    />
                                                    <h2 style={{ fontWeight: 600 }}>
                                                        {heading}
                                                    </h2>
                                                    <p>{secondary_text}</p>
                                                </div>

                                                <TrxInvRegistrarionForm
                                                    errors={errors}
                                                    values={values}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched}
                                                    longEnough={longEnough}
                                                    hasUppercase={hasUppercase}
                                                    hasNumber={hasNumber}
                                                    hasSpecial={hasSpecial}
                                                    source={source}
                                                    setSource={setSource}
                                                    avatarModal={avatarModal}
                                                    setAvatarModal={setAvatarModal}
                                                    avatar={avatar}
                                                    setAvatar={setAvatar}
                                                    activeUpload={activeUpload}
                                                    setActiveUpload={setActiveUpload}
                                                    pristine={pristine}
                                                    setPristine={setPristine}
                                                    maxSize={maxSize}
                                                />
                                            </Form>
                                            <div className="card-footer d-flex justify-content-end align-items-center">
                                                <button
                                                    className="btn btn-primary mr-4"
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                    disabled={!isValid || !dirty}
                                                >
                                                    {submit_label}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ trxInvitation, auth }) => {
    const { trxInvitationDetails } = trxInvitation;
    const { authUser } = auth;
    return { trxInvitationDetails, authUser };
};

export default withRouter(
    connect(mapStateToProps, { setLanguageLocal, acceptNewUserTrxInvitation })(
        TrxInvitationAcceptance
    )
);
