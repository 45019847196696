import {
    REMOVING_PREFERENCE_TASK,
    ADDING_PREFERENCE_TASK,
    REMOVING_PREFERENCE_TASK_SUCCESS,
    REMOVING_PREFERENCE_TASK_FAILURE,
    ADDING_PREFERENCE_TASK_SUCCESS,
    ADDING_PREFERENCE_TASK_FAILURE,
    UPDATE_PREFERENCE_USER_NOTIFICATIONS,
    UPDATE_PREFERENCE_USER_DOCUMENTS,
    UPDATING_PREFERENCE_USER_LANGUAGES,
    UPDATING_PREFERENCE_INITIAL_TASKS,
    SET_PREFERENCE_ACTIVE_TAB
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    uploadError: null,
    activeTab: 'notifications'
};

const preferencesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REMOVING_PREFERENCE_TASK:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case ADDING_PREFERENCE_TASK:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case REMOVING_PREFERENCE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case REMOVING_PREFERENCE_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        case ADDING_PREFERENCE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case ADDING_PREFERENCE_TASK_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        case UPDATE_PREFERENCE_USER_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        case UPDATE_PREFERENCE_USER_DOCUMENTS:
            return {
                ...state,
                docs: action.payload
            };
        case UPDATING_PREFERENCE_USER_LANGUAGES:
            return {
                ...state,
                active_language_id: action.payload
            };
        case UPDATING_PREFERENCE_INITIAL_TASKS:
            return {
                ...state,
                tasks: action.payload
            };
        case SET_PREFERENCE_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            };
        default:
            return state;
    }
};

export default preferencesReducer;
