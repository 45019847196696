// React
import React, { useState, useEffect } from 'react';

// Components
import { TextField, AddDocToTrxModal } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { useHistory, withRouter, Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { updateDocument, addDocumentToTrx } from '../../store/actions/Documents';

// Router
import * as routes from '../../router/config/routes';
import ConfirmationDialog from '../common/ConfirmationDialog';

const ControllerHeader = ({
    document,
    loading,
    updateDocument,
    userTransactions,
    addDocumentToTrx,
    userData,
    authorized,
    superAuthorized
}) => {
    const [docTitle, setDocTitle] = useState('');
    const [inputActive, setInputActive] = useState(false);
    const [documentModal, setdocumentModal] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [searchSuggestions, settingSearchSuggestions] = useState(null);
    const [docStatus, setDocStatus] = useState(
        document?.upload_type
            ? document.upload_type === 'buyer'
                ? 'buyer'
                : document.upload_type === 'seller'
                ? 'seller'
                : 'other'
            : null
    );
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const history = useHistory();
    const {
        fillable_button_label,
        edit_button_label,
        add_trx_button_label,
        edit_pack_button_label
    } = useLang()['PdfViewer']['ControllerHeader'];

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const showEdit =
        document.section === 'personal' ||
        (document.section === 'library' && authorized) ||
        (document.section === 'system' && superAuthorized);

    const types = [
        { name: 'Buyer', value: 'buyer' },
        { name: 'Seller', value: 'seller' },
        { name: 'Other', value: 'other' }
    ];

    const focusStyle = {
        width: '100%',
        alignText: 'center',
        alignSelf: 'center',
        padding: 0,
        margin: 0,
        backgroundColor: 'rgba(255,255,255,0.1)',
        border: 'none',
        color: 'rgba(255,255,255,1)',
        borderRadius: '0.3125rem',
        cursor: 'pointer',
        fontSize: '1.25rem',
        fontWeight: 500
    };

    const blurStyle = {
        width: '100%',
        alignText: 'center',
        alignSelf: 'center',
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        border: 'none',
        color: 'rgba(255,255,255,1)',
        borderRadius: '0.3125rem',
        fontSize: '1.25rem',
        fontWeight: 500,
        cursor: 'pointer'
    };

    useEffect(() => {
        if (!loading && document?.title && document?.upload_type) {
            setDocTitle(document.title);
            setDocStatus(
                document.upload_type === 'buyer'
                    ? 'buyer'
                    : document.upload_type === 'seller'
                    ? 'seller'
                    : 'other'
            );
        }
    }, [loading, document]);

    useEffect(() => {
        if (userTransactions && userTransactions.length) {
            const filteredTransactions = userTransactions.filter(trx => {
                if (trx.documents && Object.values(trx.documents).length) {
                    const results = Object.values(trx.documents).filter(doc => {
                        return doc.id === document.id;
                    });
                    if (!results.length) return trx;
                } else {
                    return trx;
                }
                return true;
            });
            setTransactions(filteredTransactions.length ? filteredTransactions : []);
        }
    }, [userTransactions, document.id]);

    const updatingDocument = ({ id, upload_type, title, section }) => {
        updateDocument({
            id,
            section,
            upload_type:
                upload_type === 'buyer'
                    ? 'buyer'
                    : upload_type === 'seller'
                    ? 'seller'
                    : 'other',
            title
        });
    };

    const handleBlur = () => {
        setInputActive(!inputActive);
        updatingDocument({
            id: document.id,
            upload_type: docStatus,
            title: docTitle,
            section: document.section
        });
    };

    const handleClick = status => {
        updatingDocument({
            id: document.id,
            upload_type: status,
            title: docTitle,
            section: document.section
        });
    };

    const toggleModal = () => {
        setdocumentModal(!documentModal);
    };

    const transactionInputSearch = input => {
        if (input.trim() !== '') {
            const match = input
                .trim()
                .toString()
                .match(/[0-9-]+/g);
            const filteredTransactions = [...transactions];
            if (
                input.toLowerCase().includes('mls') ||
                input.toLowerCase().includes('mls#') ||
                input.toLowerCase().includes('mls #')
            ) {
                // MLS Number
                const mls = match && match[0];
                const filteredMls = () => {
                    const mlsSuggestions = [];
                    mls &&
                        filteredTransactions.filter(trx => {
                            if (trx.mls && trx.mls.includes(mls)) {
                                mlsSuggestions.push(trx);
                            }
                            return true;
                        });
                    return mlsSuggestions;
                };

                const totalSuggestions = [...filteredMls()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) => a.findIndex(trx => trx.id === v.id) === i
                );
                settingSearchSuggestions(finalSuggestions);
            } else if (!isNaN(input[0]) || input.includes('-')) {
                // MLS number or Address
                const mls = match && match[0];
                const filteredMls = () => {
                    const mlsSuggestions = [];

                    filteredTransactions.filter(trx => {
                        if (trx.mls && trx.mls.includes(mls)) {
                            mlsSuggestions.push(trx);
                        }
                        return true;
                    });
                    return mlsSuggestions;
                };
                const filteredAddress = () => {
                    const addressSuggestions = [];
                    filteredTransactions.filter(trx => {
                        const address = trx.address.address_1.toLowerCase();
                        if (address.includes(input.toLowerCase()))
                            addressSuggestions.push(trx);
                        return true;
                    });
                    return addressSuggestions;
                };

                const totalSuggestions = [...filteredMls(), ...filteredAddress()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) => a.findIndex(trx => trx.id === v.id) === i
                );
                settingSearchSuggestions(finalSuggestions);
            } else {
                // Name
                const filteredName = () => {
                    const nameSuggestions = [];
                    filteredTransactions.filter(trx => {
                        const firstName = trx.primary_client.first_name.toLowerCase();
                        const lastName = trx.primary_client.last_name.toLowerCase();
                        const fullName =
                            `${trx.primary_client.first_name} ${trx.primary_client.last_name} `.toLowerCase();
                        if (
                            firstName.includes(input.toLowerCase()) ||
                            lastName.includes(input.toLowerCase()) ||
                            fullName.includes(input.toLowerCase())
                        ) {
                            nameSuggestions.push(trx);
                        }
                        return true;
                    });
                    return nameSuggestions;
                };

                const totalSuggestions = [...filteredName()];
                const finalSuggestions = totalSuggestions.filter(
                    (v, i, a) => a.findIndex(trx => trx.id === v.id) === i
                );
                settingSearchSuggestions(finalSuggestions);
            }
        } else {
            settingSearchSuggestions([]);
        }
    };

    const editPackClickHandler = () => {
        const { id, section } = document;
        if (section === 'personal') {
            history.push({
                pathname: `${routes.AUTHENTICATED}${routes.EDIT_PACK}/${document.id}`,
                state: { id }
            });
        } else if (section === 'library') {
            history.push({
                pathname: `${routes.AUTHENTICATED}${routes.EDIT_ORG_PACK}/${document.id}`,
                state: { id }
            });
        } else {
            history.push({
                pathname: `${routes.AUTHENTICATED}${routes.EDIT_SYSTEM_PACK}/${document.id}`,
                state: { id }
            });
        }
    };

    if (!document) return null;

    return (
        <>
            <ConfirmationDialog />
            <header
                id="header"
                className="u-header bg-primary u-header--sticky-top d-flex"
            >
                <AddDocToTrxModal
                    document={document}
                    loading={loading}
                    toggleModal={toggleModal}
                    documentModal={documentModal}
                    transactions={transactions}
                    unfilteredTransactions={userTransactions}
                    transactionInputSearch={transactionInputSearch}
                    searchSuggestions={searchSuggestions}
                    settingSearchSuggestions={settingSearchSuggestions}
                    selectedTransactions={selectedTransactions}
                    setSelectedTransactions={setSelectedTransactions}
                    addDocumentToTrx={addDocumentToTrx}
                    Link={Link}
                    routes={routes}
                    userData={userData}
                    docTitle={docTitle}
                    docStatus={docStatus}
                />
                <div id="h-main" className="size container pt-md-2 pb-md-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div
                            className={`col-md-${
                                userData?.active_language_id === 'en' ? 5 : 3
                            }`}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <TextField
                                type="text"
                                className="doc-title-input"
                                onFocus={() => setInputActive(!inputActive)}
                                myStyle={inputActive ? focusStyle : blurStyle}
                                onBlur={handleBlur}
                                value={docTitle}
                                onChange={e => setDocTitle(e.target.value)}
                                parentStyle={{ width: '75%' }}
                                disabled={document.pack}
                                nonFormik
                            />
                        </div>
                        <div
                            className={`col-md-${
                                userData?.active_language_id === 'en' ? 6 : 8
                            } d-flex justify-content-end align-items-center p-2`}
                        >
                            {!document.pack && (
                                <div className="d-flex justify-content-between align-items-center">
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                        <DropdownToggle
                                            className="btn-sm text-white text-nowrap transition-3d-hover text-capitalize"
                                            color="btn-soft-white"
                                        >
                                            {docStatus}
                                            <span className="fas fa-angle-down ml-2" />
                                        </DropdownToggle>
                                        <DropdownMenu
                                            className={'animate slideIn'}
                                            style={{ width: 'inherit' }}
                                        >
                                            {types.map(item => (
                                                <DropdownItem
                                                    key={item.value}
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                        handleClick(item.value)
                                                    }
                                                    style={{
                                                        color:
                                                            item.value ===
                                                            document.upload_type
                                                                ? '#377dff'
                                                                : ''
                                                    }}
                                                >
                                                    {item.name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            )}
                            {document.pack ? (
                                <>
                                    {showEdit && (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover mx-3"
                                            onClick={editPackClickHandler}
                                        >
                                            <span className="fas fa-edit mr-2" />
                                            {edit_pack_button_label}
                                        </button>
                                    )}
                                </>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover mx-3"
                                >
                                    <span className="fas fa-edit mr-2" />
                                    {document.fillable
                                        ? edit_button_label
                                        : fillable_button_label}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover"
                                onClick={toggleModal}
                            >
                                <span className="fas fa-plus mr-2" />
                                {add_trx_button_label}
                            </button>
                        </div>

                        <div id="close-trx">
                            <button
                                type="button"
                                className="btn btn-lg btn-icon btn-primary rounded-circle transition-3d-hover ml-auto"
                                onClick={() => history.goBack()}
                            >
                                <span className="fas fa-times" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

const mapStateToProps = ({ documents, transactions, user }) => {
    const { loading } = documents;
    const { userTransactions } = transactions;
    const { userData } = user;
    return {
        loading,
        userTransactions,
        userData
    };
};

export default withRouter(
    connect(mapStateToProps, {
        updateDocument,
        addDocumentToTrx
    })(ControllerHeader)
);
