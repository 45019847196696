import React, { useEffect, useState } from 'react';
import ImportingIcon from '../../assets/svg/Importing.svg';
import { useLang } from '../../context/LangContext';

const DocumentProgress = ({ setProgressVisible, loading }) => {
    const { importing_text, importing_subtext } =
        useLang()['Documents']['FillingDocument'];
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!loading) {
            setProgress(100);
        }
    }, [loading, setProgress]);

    useEffect(() => {
        if (progress === 100) {
            setTimeout(() => {
                setProgressVisible(false);
            }, 1500);
        }
    }, [progress, setProgressVisible]);

    return (
        <main id="content" role="main" className="push-main bg-light">
            <div className="container col d-flex justify-content-center">
                <div className="col">
                    <div className="row justify-content-center">
                        <img
                            className="gear-animated mt-5"
                            src={ImportingIcon}
                            alt="SVG"
                        />
                    </div>
                    <div className="row justify-content-center mt-5">
                        <h1 className="h2 font-weight-medium">{importing_text}</h1>
                    </div>
                    <div
                        className="row justify-content-center align-items-center mt-5"
                        style={{
                            paddingLeft: '21%',
                            paddingRight: '21%'
                        }}
                    >
                        <h6
                            style={{
                                fontWeight: '200',
                                fontSize: 16,
                                color: '#8C98A4',
                                textAlign: 'center'
                            }}
                        >
                            {importing_subtext}
                        </h6>
                    </div>
                    <div
                        className="container d-flex"
                        style={{
                            animation: 'fadeIn 1s'
                        }}
                    >
                        <div className="col">
                            <div className="row justify-content-center">
                                <div
                                    className="progress"
                                    style={{
                                        width: '300px',
                                        height: '10px',
                                        borderRadius: 5
                                    }}
                                >
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{
                                            width: `${progress}%`,
                                            transition: 'width 1500ms ease-in-out'
                                        }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default DocumentProgress;
