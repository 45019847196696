// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

const PipelineSearchFilter = ({ settingSearchSuggestions, contacts }) => {
    const { input_label } = useLang()['Pipeline']['PipelineSearchFilter'];

    const pipelineInputSearch = input => {
        if (input.trim() !== '') {
            const filteredContacts = [...contacts];
            const filteredName = () => {
                const nameSuggestions = [];
                filteredContacts.filter(contact => {
                    const firstName = contact.first_name
                        ? contact.first_name.toLowerCase()
                        : '';
                    const lastName = contact.last_name
                        ? contact.last_name.toLowerCase()
                        : '';
                    const fullName =
                        `${contact.first_name} ${contact.last_name} `.toLowerCase();
                    if (
                        firstName.includes(input.toLowerCase()) ||
                        lastName.includes(input.toLowerCase()) ||
                        fullName.includes(input.toLowerCase())
                    ) {
                        nameSuggestions.push(contact);
                    }
                    return true;
                });
                return nameSuggestions;
            };
            const totalSuggestions = [...filteredName()];
            settingSearchSuggestions(totalSuggestions);
        } else {
            settingSearchSuggestions([]);
        }
    };

    return (
        <div id="contactsFilters">
            <div className="container space-lg-1 space-md-0 space-sm-0">
                <div className="row mx-gutters-2 align-items-center">
                    <div className="col-md mb-3 mb-lg-0">
                        <div className="js-focus-state">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="searchProperty"
                                    >
                                        <span className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="text"
                                    id="searchPropertySr"
                                    placeholder={`${input_label}`}
                                    aria-label={`${input_label}`}
                                    aria-describedby="searchProperty"
                                    autoComplete="off"
                                    onChange={e => pipelineInputSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PipelineSearchFilter;
