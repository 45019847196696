import React, { useState, useRef, useEffect } from 'react';
import { useLang } from '../../context/LangContext';

import Select from 'react-select';
import { getElementSize } from '../../utils/Helpers';

const FontOptions = {
    dancingScript: 'Dancing Script',
    greatVibes: 'Great Vibes',
    neucha: 'Neucha',
    rockSalt: 'Rock Salt'
};

const TypeTab = ({ firstName, lastName, applySignature, setSigningData, type }) => {
    const { signature_label, initials_label, apply_button } =
        useLang()['Documents']['FillingDocument']['SigningDocument'];
    const [fontOption, setFontOption] = useState(FontOptions.dancingScript);
    const signatureRef = useRef(null);

    const options = [
        { value: 'Dancing Script', label: 'Dancing Script' },
        { value: 'Great Vibes', label: 'Great Vibes' },
        { value: 'Neucha', label: 'Neucha' },
        { value: 'Rock Salt', label: 'Rock Salt' }
    ];

    useEffect(() => {
        if (signatureRef.current) {
            const fontSize = fontOption === FontOptions.rockSalt ? 30 : 50;
            const initialsString = firstName[0] + lastName[0];
            const fullNameString = firstName + ' ' + lastName;

            const content = type === 'signature' ? fullNameString : initialsString;

            const signature = signatureRef.current.getContext('2d');
            signature.clearRect(
                0,
                0,
                signatureRef.current.width,
                signatureRef.current.height
            );
            const { width: signatureWidth, height: signatureHeight } = getElementSize(
                content,
                {
                    fontFamily: `"${fontOption}", cursive`,
                    fontSize
                }
            );
            signatureRef.current.width =
                (signatureWidth > 350 ? 350 : signatureWidth) + 10;
            signatureRef.current.height = signatureHeight;

            signature.font = `${fontSize}px  "${fontOption}", cursive`;
            signature.fillStyle = '#377dff';
            signature.textAlign = 'center';
            signature.fillText(
                content,
                (signatureWidth > 350 ? 350 : signatureWidth) / 2 + 5,
                signatureHeight * 0.75,
                360
            );

            setSigningData(signatureRef.current.toDataURL());
        }
    }, [fontOption, signatureRef, firstName, lastName, setSigningData, type]);

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ width: '100%' }}
        >
            <div
                className="d-flex flex-column  justify-content-center align-items-center mb-2"
                style={{ height: '50%', width: '100%' }}
            >
                <span style={{ fontSize: '12px' }}>
                    {type === 'signature' ? signature_label : initials_label}
                </span>
                <canvas ref={signatureRef} />
            </div>
            <Select
                defaultValue={options[0]}
                autoComplete="off"
                type="select"
                className="form-control p-0 border-0"
                placeholder="Font"
                onChange={option => {
                    setFontOption(option.value);
                }}
                options={options}
            />
            <button
                type="button"
                className="btn btn-sm btn-wide btn-primary btn-pill transition-3d-hover"
                style={{ cursor: 'pointer' }}
                onClick={applySignature}
            >
                {apply_button}
            </button>
        </div>
    );
};

export default TypeTab;
