import {
    all,
    fork,
    // take,
    // cancelled,
    takeLatest,
    // race,
    put,
    call,
    select
} from 'redux-saga/effects';

import {
    // LOGOUT_USER,
    VALIDATE_ORG_INVITATION,
    CONFIRM_EXISTING_USER_INVITATION,
    CONFIRM_NEW_USER_INVITATION,
    PREPARE_NEW_USER_START,
    DECLINE_NEW_USER_INVITATION
} from '../actions/types';

// import { eventChannel } from 'redux-saga';

import {
    orgInvitationValidationFailure,
    setInvitationValidity,
    confirmExistingInvitationSuccess,
    confirmExistingInvitationFailure,
    confirmNewInvitationSuccess,
    confirmNewInvitationFailure,
    newUserStartSuccess,
    declineNewUserInvitationSuccess,
    declineNewUserInvitationFailure
} from '../actions/Invitations';

import { setConfirmModalType } from '../actions/Modal';

import { userIsAuthenticated } from '../actions/Auth';

import { userCollectionWatch } from './User';

import { confirmSaga } from './Modal';

import {
    db,
    rtdb,
    timeStampNow,
    fsFieldValue,
    auth,
    storage
} from '../../config/Firebase';

import axios from 'axios';

// Loggers
import { log } from '../../utils/Loggers';
import { checkExpiration, formatNewMemberData } from '../../utils/Helpers';
import { FS_USER_PREFERENCES } from '../../utils/Constants';

import { confirmationDialogTypes } from '../../utils/Constants';

import * as selectors from './Selectors';

const users = db.collection('users');
// const orgs = db.collection('orgs');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////// Validating Org Invitation ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const validateOrgInvitationRequest = async ({ inviteCode, inviteId, orgId }) => {
    return await axios({
        method: 'POST',
        url: `https://api-staging.jada.app/api/organizations/${orgId}/invitations/${inviteId}`,
        data: JSON.stringify({ invite_code: inviteCode }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(invitation => {
            const { data } = invitation;
            return { invitation: data };
        })
        .catch(error => {
            return { error };
        });
};

export function* validateOrgInvitation({ payload }) {
    const { inviteId, inviteCode, orgId, orgName, brokeredBy } = payload;
    const userData = yield select(selectors._userData);
    const { invitation, error } = yield call(() =>
        validateOrgInvitationRequest({ inviteId, inviteCode, orgId })
    );
    if (invitation) {
        const accepted = invitation.accepted;
        const isTimeExpired = checkExpiration(invitation.invited_at.seconds);

        yield put(
            setInvitationValidity({
                invitation: {
                    ...invitation,
                    org_id: orgId,
                    org_name: orgName,
                    invite_code: inviteCode,
                    brokered_by: brokeredBy
                },
                valid: !accepted && !isTimeExpired,
                emailMatch: invitation.existing
                    ? !userData
                        ? ''
                        : userData?.email === invitation?.email
                        ? true
                        : false
                    : null
            })
        );
    } else {
        yield put(orgInvitationValidationFailure(error));
        log('Invitation Error: validating Org Invitations (RTDB)', {
            error,
            inviteId,
            inviteCode,
            orgId
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////// Confirm Existing User Invite //////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const writeExistingUserFSDoc = ({ invitation }) => {
    return new Promise((resolve, reject) => {
        const { org_id, type, user_id, org_locations, brokered_by } = invitation;
        const userRef = users.doc(user_id);
        const mlsPath = `mls.${org_id}`;
        const prefPath = `preferences.${org_id}`;
        const prefData = FS_USER_PREFERENCES;

        const orgData = {
            brokered_by,
            id: org_id,
            locations: org_locations,
            owner: type.permission === 'owner' ? true : false,
            sub_type: type.sub_type,
            title: type.langAgnosticLabel
        };

        userRef
            .update({
                [mlsPath]: [],
                [prefPath]: prefData,
                orgs: fsFieldValue.arrayUnion(orgData)
            })
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                log(
                    'Invitation Error: updating User Document from confirm Existing User Invitation (FS)',
                    error,
                    invitation
                );
                reject({ error });
            });
    });
};

const writeExistingUserRTDBOrg = ({ invitation }) => {
    return new Promise((resolve, reject) => {
        const { org_id, type, user_id, email, invite_id, first_name, last_name } =
            invitation;

        const orgContactRef = rtdb.ref(`orgs/${org_id}/contacts/${user_id}`);
        const orgInviteRef = rtdb.ref(`orgs/${org_id}/invitations/${invite_id}`);
        const contactData = {
            active: true,
            email,
            first_name,
            id: user_id,
            last_name,
            phone: '',
            role: type.langAgnosticLabel
        };
        const inviteData = {
            accepted: true,
            accepted_at: timeStampNow(),
            status: 'accepted'
        };

        orgContactRef.set({ ...contactData }, error => {
            if (error) {
                log(
                    'Invitation Error: adding new Org Contact from confirm Existing User Invitation (RTDB)',
                    error,
                    invitation
                );
                reject({ error });
            } else {
                orgInviteRef.update({ ...inviteData }, error => {
                    if (error) {
                        log(
                            'Invitation Error: updating Org Invitation from confirm Existing User Invitation (RTDB)',
                            error,
                            invitation
                        );
                        reject({ error });
                    } else {
                        resolve({ success: true });
                    }
                });
            }
        });
    });
};

const confirmExistingUserInvitationRequest = async ({ invitation }) => {
    const fsExistingConfirmation = await writeExistingUserFSDoc({ invitation });
    const rtdbExistingConfirmation = await writeExistingUserRTDBOrg({ invitation });
    if (fsExistingConfirmation.success && rtdbExistingConfirmation.success) {
        return { confirmation: true };
    } else {
        return {
            error: [fsExistingConfirmation?.error, rtdbExistingConfirmation?.error]
        };
    }
};

export function* confirmExistingUserInvitation({ payload }) {
    const { invitation } = payload;
    const { confirmation, error } = yield call(() =>
        confirmExistingUserInvitationRequest({ invitation })
    );
    if (confirmation) {
        yield put(confirmExistingInvitationSuccess());
    } else {
        yield put(confirmExistingInvitationFailure(error));
        log('Invitation Error: Confirming Existing User Invitation (FS/RTDB)', {
            error,
            invitation
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Confirm New User Invite //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const writeNewUserAuthAccount = ({ formData }) => {
    return new Promise((resolve, reject) => {
        const { email, password } = formData;
        auth.createUserWithEmailAndPassword(email, password)
            .then(authUser => {
                resolve(authUser);
            })
            .catch(error => {
                reject({ error });
            });
    });
};

const storeUserAvatar = async ({ img, userData }) => {
    const ref = storage.ref();
    const avatarRef = ref.child('users').child(userData.id).child('avatar.jpg');
    if (img.length === 0) {
        const defaultImg = require('../../assets/img/avatar.jpg');
        const imgString = defaultImg.default;
        const metadata = {
            customMetadata: {
                default: true
            }
        };
        return await avatarRef
            .putString(imgString, 'data_url', metadata)
            .then(function (snapshot) {
                return snapshot.ref.getDownloadURL();
            })
            .then(url => {
                return url;
            })
            .catch(error => {
                console.error(error);
            });
    } else {
        try {
            const blob = await fetch(img).then(r => r.blob());
            const snapshot = await avatarRef.put(blob);
            const url = await snapshot.ref.getDownloadURL();
            return url;
        } catch (error) {
            return { error };
        }
    }
};

const prepareNewUser = data => {
    return new Promise(resolve => {
        resolve(formatNewMemberData({ user: data }));
    });
};

const writeNewUserFSDoc = ({ user }) => {
    return new Promise((resolve, reject) => {
        const { id } = user;
        const userRef = users.doc(id);
        userRef
            .set({ ...user })
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                log(
                    'Invitation Error: creating User Document from confirm New User Invitation (FS)',
                    error,
                    user
                );
                reject({ error });
            });
    });
};

const writeNewUserRTDBOrg = ({ invitation }) => {
    return new Promise((resolve, reject) => {
        const { org_id, type, user_id, email, invite_id, first_name, last_name, phone } =
            invitation;

        const orgContactRef = rtdb.ref(`orgs/${org_id}/contacts/${user_id}`);
        const orgInviteRef = rtdb.ref(`orgs/${org_id}/invitations/${invite_id}`);
        const contactData = {
            active: true,
            email,
            first_name,
            id: user_id,
            last_name,
            phone: phone,
            role: type.langAgnosticLabel
        };
        const inviteData = {
            accepted: true,
            accepted_at: timeStampNow(),
            status: 'accepted'
        };

        orgContactRef.set({ ...contactData }, error => {
            if (error) {
                log(
                    'Invitation Error: adding new Org Contact from confirm New User Invitation (RTDB)',
                    error,
                    invitation
                );
                reject({ error });
            } else {
                orgInviteRef.update({ ...inviteData }, error => {
                    if (error) {
                        log(
                            'Invitation Error: updating Org Invitation from confirm New User Invitation (RTDB)',
                            error,
                            invitation
                        );
                        reject({ error });
                    } else {
                        resolve({ success: true });
                    }
                });
            }
        });
    });
};

const confirmNewUserInvitationRequest = async ({
    invitation,
    formData,
    skip,
    language
}) => {
    const authAccount = await writeNewUserAuthAccount({ formData });
    if (authAccount) {
        // Store avatar if it exists (if not then default avatar from assets) and get back downloadUrl...then continue...this should be last step of function!!
        const user_avatar = await storeUserAvatar({
            userData: { id: authAccount.user.uid },
            img: formData.avatar
        });
        const newUser = await prepareNewUser({
            ...invitation,
            ...formData,
            skip,
            lang: language,
            user_id: authAccount.user.uid,
            user_avatar: user_avatar
        });

        const fsNewUserConfirmation = await writeNewUserFSDoc({ user: newUser });
        const rtdbNewUserConfirmation = await writeNewUserRTDBOrg({
            invitation: {
                ...invitation,
                user_id: authAccount.user.uid,
                phone: formData.phone
            }
        });
        if (fsNewUserConfirmation.success && rtdbNewUserConfirmation.success) {
            return { confirmation: authAccount };
        } else {
            return {
                error: [fsNewUserConfirmation?.error, rtdbNewUserConfirmation?.error]
            };
        }
    } else {
        return { error: authAccount.error };
    }
};

export function* confirmNewUserInvitation({ payload }) {
    const { invitation, formData, skip, language } = payload;
    const { confirmation, error } = yield call(() =>
        confirmNewUserInvitationRequest({ invitation, formData, skip, language })
    );
    if (confirmation) {
        yield put(confirmNewInvitationSuccess(confirmation.user));
        yield put(setConfirmModalType(confirmationDialogTypes.acceptInviteSuccess));
    } else {
        yield put(confirmNewInvitationFailure(error));
        log('Invitation Error: Confirming New User Invitation (FS/RTDB)', {
            error,
            invitation,
            formData,
            skip
        });
        yield put(setConfirmModalType(confirmationDialogTypes.acceptInviteFailure));
    }
}

export function* prepareNewUserStart() {
    const newAuthUser = yield select(selectors._newAuthUser);
    yield put(userIsAuthenticated(newAuthUser));
    yield fork(userCollectionWatch, newAuthUser);
    yield put(newUserStartSuccess());
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Decline New User Invite //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const declineNewUserInvitationRequest = async ({ invite_code, invite_id, org_id }) => {
    return await axios({
        method: 'PATCH',
        url: `https://api-staging.jada.app/api/organizations/${org_id}/decline-invitation`,
        data: JSON.stringify({ invite_code, invitation_id: invite_id }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(declined => {
            return { declined: true };
        })
        .catch(error => {
            return { error };
        });
};

export function* declineNewUserInvitation() {
    const userInvite = yield select(selectors._userInvitation);
    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.declineInvite
    });
    // yield put(setConfirmModalType(null));
    if (isConfirm) {
        const { declined, error } = yield call(
            declineNewUserInvitationRequest,
            userInvite
        );
        if (declined) {
            yield put(declineNewUserInvitationSuccess());
        } else {
            yield put(declineNewUserInvitationFailure(error));
            log('Invitation Error: Declining New User Invitation', {
                error,
                userInvite
            });
            yield put(setConfirmModalType(confirmationDialogTypes.declineInviteFailure));
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* validatingOrgInvitation() {
    yield takeLatest(VALIDATE_ORG_INVITATION, validateOrgInvitation);
}

export function* confirmingExistingUserInvitation() {
    yield takeLatest(CONFIRM_EXISTING_USER_INVITATION, confirmExistingUserInvitation);
}

export function* confirmingNewUserInvitation() {
    yield takeLatest(CONFIRM_NEW_USER_INVITATION, confirmNewUserInvitation);
}

export function* preparingNewUserStart() {
    yield takeLatest(PREPARE_NEW_USER_START, prepareNewUserStart);
}

export function* decliningNewUserInvitation() {
    yield takeLatest(DECLINE_NEW_USER_INVITATION, declineNewUserInvitation);
}

export default function* rootSaga() {
    yield all([
        fork(validatingOrgInvitation),
        fork(confirmingExistingUserInvitation),
        fork(confirmingNewUserInvitation),
        fork(preparingNewUserStart),
        fork(decliningNewUserInvitation)
    ]);
}
