import { all, call, fork, takeLatest, put, select } from 'redux-saga/effects';

import {
    GET_DOC_FOR_FILLING,
    SAVE_SETTINGS_FOR_FILLING,
    SAVE_DOC_FOR_FILLING,
    SAVE_SIGNATURE,
    ACCEPT_SIGNING_CONCENT,
    REMOVE_SIGNER_FROM_DOCUMENT,
    SEND_SIGNATURE_REQUEST
} from '../actions/types';

import * as selectors from './Selectors';

import {
    setDocumentForFilling,
    setSettingsForFilling,
    saveSignatureSuccess,
    acceptConsentSuccess,
    removeSignerFromDocumentSuccess
} from '../actions/DocumentFilling';
import { setConfirmModalType } from '../actions/Modal';

// Firebase
import { timeStampNow, db, func } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

// Consts
import { confirmationDialogTypes, documentStatus } from '../../utils/Constants';
import { getTransactionDetails } from './Documents';
import { confirmSaga } from './Modal';
import { removingPartyFromTransactionRequest } from './Transactions';
import { generateUid } from '../../utils/Helpers';

const annotatedDocs = db.collection('annotated_docs');
const transactions = db.collection('transactions');
const executeFormSignRequest = func.httpsCallable('formSignRequest');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get Document For Filling Form ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const gettingDocumentForFillingRequest = trxDocId => {
    return new Promise((resolve, reject) => {
        annotatedDocs
            .doc(trxDocId)
            .get()
            .then(doc => resolve({ res: doc.data() }))
            .catch(error => reject({ error }));
    });
};

export function* gettingDocumentForFilling({ payload: trxDocId }) {
    const { res, error } = yield call(() => gettingDocumentForFillingRequest(trxDocId));
    if (res) {
        yield put(setDocumentForFilling(res));
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: getting document for filling (FS)', {
            error,
            trxDocId,
            function: 'gettingDocumentForFilling'
        });
        // TODO: add put for Failure
        // yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Save Settings For Filling //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const savingSettingsForFillingRequest = async ({ trxDocId, fillingSettings }) => {
    return new Promise((resolve, reject) => {
        annotatedDocs
            .doc(trxDocId)
            .update({ filling_settings: { ...fillingSettings, is_setup: true } })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* savingSettingsForFilling({ payload }) {
    const { trxDocId, fillingSettings } = payload;
    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    const restructuredFillingSettings = {
        required_fields_select: fillingSettings?.requiredFieldsSelect,
        signers_select: fillingSettings?.signersSelect,
        confirm_signers: fillingSettings?.confirmSigners.map(
            ({ id, email, legalName, type }) => ({
                id,
                email,
                legal_name: legalName,
                type
            })
        ),
        forward_document: fillingSettings?.forwardDocument
    };

    const { res, error } = yield call(() =>
        savingSettingsForFillingRequest({
            trxDocId,
            fillingSettings: restructuredFillingSettings
        })
    );
    if (res) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
        yield put(
            setSettingsForFilling({ ...restructuredFillingSettings, is_setup: true })
        );
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.error));
        // Error Handling for sentry with put and UI message
        log('Documents Error: saving settings for filling (FS)', {
            error,
            trxDocId,
            function: 'savingSettingsForFilling'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Save Document For Filling //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const savingDocumentForFillingRequest = async ({
    trxDocId,
    documents,
    trxId,
    signatures,
    signatureRequests
}) => {
    return new Promise((resolve, reject) => {
        annotatedDocs
            .doc(trxDocId)
            .update(
                signatures
                    ? { documents, signatures }
                    : signatureRequests
                    ? { signature_requests: signatureRequests, documents }
                    : { documents }
            )
            .then(() =>
                transactions.doc(trxId).update({
                    [`documents.${trxDocId}.status`]:
                        signatures || signatureRequests
                            ? documentStatus.pendingSignatures
                            : documentStatus.draft
                })
            )
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* savingDocumentForFilling({ payload }) {
    const { trxDocId } = payload;
    yield put(setConfirmModalType(confirmationDialogTypes.loading));

    const { res, error } = yield call(() => savingDocumentForFillingRequest(payload));
    if (res) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        // Error Handling for sentry with put and UI message
        yield put(setConfirmModalType(confirmationDialogTypes.error));
        log('Documents Error: saving document for filling (FS)', {
            error,
            trxDocId,
            function: 'savingDocumentForFilling'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////// Save User Signature /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const savingUserSignatureRequest = async ({ document, signature }) => {
    return new Promise((resolve, reject) => {
        annotatedDocs
            .doc(document.annotated_doc_id)
            .update({
                signature_values: [
                    ...(document.signature_values?.filter(
                        el => !(signature.id === el.id && signature.type === el.type)
                    ) || []),
                    signature
                ]
            })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

function* savingSignature({ payload }) {
    const { signingData, userId, type, subtype } = payload;
    const document = yield select(selectors._documentForFilling);

    const signature = {
        type,
        sub_type: subtype,
        value: signingData,
        id: userId
    };

    const { res, error } = yield call(() =>
        savingUserSignatureRequest({ signature, document })
    );
    if (res) {
        yield put(saveSignatureSuccess(signature));
    } else {
        // Error Handling for sentry with put and UI message
        log('Documents Error: saving user signature (FS)', {
            error,
            userId,
            function: 'savingSignature'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////// Accept Signing Consent /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const acceptingConsentRequest = ({ document, userId }) => {
    return new Promise((resolve, reject) => {
        annotatedDocs
            .doc(document.annotated_doc_id)
            .update({
                'filling_settings.confirm_signers':
                    document.filling_settings.confirm_signers.map(el =>
                        el.id === userId
                            ? {
                                  ...el,
                                  signing_consent: true,
                                  consented_at: timeStampNow()
                              }
                            : el
                    )
            })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

function* acceptingConsent() {
    const document = yield select(selectors._documentForFilling);
    const { id: userId } = yield select(selectors._userData);

    const { res, error } = yield call(() =>
        acceptingConsentRequest({ document, userId })
    );
    if (res) {
        yield put(acceptConsentSuccess(userId));
    } else {
        // Error Handling for sentry with put and UI message
        log('Documents Error: saving user signature (FS)', {
            error,
            annotatedDocId: document.annotated_doc_id,
            userId,
            function: 'savingSignature'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Remove Signer From Document /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingSignerFromDocumentRequest = ({ document, signerEmail }) => {
    return new Promise((resolve, reject) => {
        annotatedDocs
            .doc(document.annotated_doc_id)
            .update({
                'filling_settings.confirm_signers':
                    document.filling_settings.confirm_signers.filter(
                        el => el.email !== signerEmail
                    )
            })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

function* removingSignerFromDocument({ payload }) {
    const { signerEmail, signerId } = payload;
    const document = yield select(selectors._documentForFilling);
    const transaction = yield getTransactionDetails(document.trx_id);
    const { primary_agent, primary_broker, primary_client, primary_opposing, party_ids } =
        transaction;

    const isPrimarySigner =
        signerId &&
        [
            primary_agent?.id,
            primary_broker?.id,
            primary_client?.id,
            primary_opposing?.id
        ].includes(signerId);
    const isTrxParty = party_ids.includes(signerId);

    const { isConfirm, dataFromModal } = yield call(confirmSaga, {
        modalType:
            isPrimarySigner || !isTrxParty
                ? confirmationDialogTypes.removePrimarySigner
                : confirmationDialogTypes.removeSigner
    });

    if (!isConfirm) return;

    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    const { res, error } = yield call(() =>
        removingSignerFromDocumentRequest({ document, signerEmail })
    );

    let removedParty;
    if (dataFromModal && res) {
        const result = yield call(() =>
            removingPartyFromTransactionRequest({
                userId: signerId,
                transaction
            })
        );
        removedParty = result.removedParty;
    }

    if (res && (removedParty || !dataFromModal)) {
        yield put(removeSignerFromDocumentSuccess(signerEmail));
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.error));
        // Error Handling for sentry with put and UI message
        log('Documents Error: saving user signature (FS)', {
            error,
            annotatedDocId: document.annotated_doc_id,
            signerEmail,
            function: 'savingSignature'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Send Signature Request ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const executeSendingSignatureRequest = async ({
    signers,
    docId,
    orgName,
    user,
    formName,
    propertyAddress,
    requests
}) => {
    const structuredSigners = signers.map(signer => ({
        signer_name: signer.legal_name,
        email: signer.email,
        user_id: signer.id,
        request_id: requests.find(el => el.id === signer.id).request_id
    }));

    return new Promise((resolve, reject) =>
        executeFormSignRequest({
            signers: structuredSigners,
            sender_name: `${user.first_name} ${user.last_name}`,
            org_name: orgName,
            form_name: formName,
            property_address: propertyAddress,
            doc_id: docId
        })
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                reject({
                    error
                });
            })
    );
};

export function* sendingSignatureRequest({ payload }) {
    const { signers, docId, formName, propertyAddress, documents, trxId } = payload;

    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    const org = yield select(selectors._activeOrg);
    const user = yield select(selectors._userData);
    const date = timeStampNow();

    const requests = signers.map(signer => ({
        id: signer.id,
        legal_name: signer.legal_name,
        request_id: generateUid(),
        requested_at: date,
        signer_email: signer.email,
        status: 'pending',
        type: signer.type
    }));

    const { res, error } = yield call(savingDocumentForFillingRequest, {
        trxDocId: docId,
        documents,
        trxId,
        signatureRequests: Object.fromEntries(requests.map(el => [el.request_id, el]))
    });
    if (res) {
        const { success, error } = yield call(executeSendingSignatureRequest, {
            signers,
            docId,
            user,
            formName,
            orgName: org.company_name,
            propertyAddress,
            requests
        });

        if (success) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Documents Error: sending request for the signature (SG)`, {
                error,
                signers,
                docId,
                formName,
                function: 'executeSendingSignatureRequest'
            });
        }
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        log(
            `Documents Error: saving document for filling in sending request for the signature (SG)`,
            {
                error,
                trxDocId: docId,
                function: 'savingDocumentForFilling'
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getDocumentForFilling() {
    yield takeLatest(GET_DOC_FOR_FILLING, gettingDocumentForFilling);
}

export function* saveSettingsForFilling() {
    yield takeLatest(SAVE_SETTINGS_FOR_FILLING, savingSettingsForFilling);
}

export function* saveDocumentForFilling() {
    yield takeLatest(SAVE_DOC_FOR_FILLING, savingDocumentForFilling);
}

export function* saveSignature() {
    yield takeLatest(SAVE_SIGNATURE, savingSignature);
}

export function* acceptConsent() {
    yield takeLatest(ACCEPT_SIGNING_CONCENT, acceptingConsent);
}

export function* removeSignerFromDocument() {
    yield takeLatest(REMOVE_SIGNER_FROM_DOCUMENT, removingSignerFromDocument);
}

export function* sendSignatureRequest() {
    yield takeLatest(SEND_SIGNATURE_REQUEST, sendingSignatureRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getDocumentForFilling),
        fork(saveSettingsForFilling),
        fork(saveDocumentForFilling),
        fork(saveSignature),
        fork(acceptConsent),
        fork(removeSignerFromDocument),
        fork(sendSignatureRequest)
    ]);
}
