// React
import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

// Consts
import { confirmationDialogTypes } from '../../utils/Constants';

// Packages
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';

// Redux
import { confirmModal, cancelModal } from '../../store/actions/Modal';

// Components
import DatePickerWithIcon from './DatePickerWithIcon';

// Context
import { useLang } from '../../context/LangContext';

const SwalWithReactContent = withReactContent(Swal);

const dialogStyles = {
    warning: {
        icon: 'warning',
        iconColor: '#00C9A6',
        confirmButtonColor: '#377dff',
        cancelButtonColor: '#de4437'
    },
    danger: {
        icon: 'warning',
        iconColor: '#de4437',
        confirmButtonColor: '#377dff',
        cancelButtonColor: '#de4437'
    },
    success: {
        icon: 'success',
        iconColor: '#00C9A6',
        confirmButtonColor: '#377dff'
    },
    failed: {
        icon: 'error',
        iconColor: '#de4437',
        confirmButtonColor: '#377dff'
    },
    question: {
        icon: 'question',
        iconColor: '#00C9A6',
        confirmButtonColor: '#377dff',
        cancelButtonColor: '#de4437'
    }
};

// Confirmation Dialog States
export const confirmationDialogStates = {
    confirmation: 'Confirmation',
    loading: 'Loading',
    success: 'Success',
    successWithoutHandler: 'SuccessWithoutHandler',
    failed: 'Failed',
    errorCurrentPassword: 'ErrorCurrentPassword'
};

const ConfirmationDialog = ({
    initialModalDialogType,
    modalDialogType,
    confirmModal,
    cancelModal,
    confirmHandler = () => {},
    closeHandler = () => {},
    successHandler = () => {},
    cancelHandler = () => {},
    modalContent
}) => {
    const currentModalState = useMemo(
        () =>
            confirmationDialogStates[modalDialogType] ||
            confirmationDialogStates.confirmation,
        [modalDialogType]
    );
    const translations = useLang()['Common']['ConfirmationDialog'][currentModalState];

    const alertContent = useMemo(
        () => ({
            title:
                translations[`${initialModalDialogType}_title`] || translations[`title`],
            text: translations[`${initialModalDialogType}_text`] || translations[`text`],
            confirmButtonText:
                translations[`${initialModalDialogType}_confirmButtonText`] ||
                translations[`confirmButtonText`],
            cancelButtonText:
                translations[`${initialModalDialogType}_cancelButtonText`] ||
                translations[`cancelButtonText`]
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [translations, initialModalDialogType, modalDialogType]
    );

    const additionalAlertContent = useMemo(
        () => ({
            inputPlaceholder:
                translations[`${initialModalDialogType}_inputPlaceholder`] ||
                translations[`inputPlaceholder`],
            validationError:
                translations[`${initialModalDialogType}_validationError`] ||
                translations[`validationError`]
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [translations, initialModalDialogType, modalDialogType]
    );

    useEffect(() => {
        switch (modalDialogType) {
            case confirmationDialogTypes.delete:
            case confirmationDialogTypes.archiveTrx:
            case confirmationDialogTypes.activateTrx:
            case confirmationDialogTypes.reopenTrx:
            case confirmationDialogTypes.removePrimarySigner:
                Swal.fire({
                    showCancelButton: true,
                    ...alertContent,
                    ...dialogStyles.warning
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmModal();
                    } else {
                        cancelModal();
                    }
                });
                break;

            case confirmationDialogTypes.leaveCurrentTeam:
            case confirmationDialogTypes.leaveAndJoinTeam:
            case confirmationDialogTypes.leaveAsLeaderAndJoinTeam:
            case confirmationDialogTypes.declineTeamInvite:
            case confirmationDialogTypes.deleteTeam:
            case confirmationDialogTypes.deleteMemberFromTrx:
            case confirmationDialogTypes.deleteMemberFromOrg:
            case confirmationDialogTypes.declineInvite:
                Swal.fire({
                    showCancelButton: true,
                    ...alertContent,
                    ...dialogStyles.danger
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmModal();
                    } else {
                        cancelModal();
                    }
                });
                break;

            case confirmationDialogTypes.loading:
                Swal.fire({
                    ...alertContent,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                break;

            case confirmationDialogTypes.success:
            case confirmationDialogTypes.successWithoutHandler:
            case confirmationDialogTypes.inviteMemberSuccess:
            case confirmationDialogTypes.acceptInviteSuccess:
            case confirmationDialogTypes.declineInviteSuccess:
                Swal.fire({
                    willClose: cancelModal,
                    didOpen: () => {
                        Swal.hideLoading();
                    },
                    ...alertContent,
                    ...dialogStyles.success
                }).then(() => {
                    if (
                        modalDialogType !== confirmationDialogTypes.successWithoutHandler
                    ) {
                        successHandler();
                    }
                });
                break;

            case confirmationDialogTypes.failed:
            case confirmationDialogTypes.inviteMemberFailure:
            case confirmationDialogTypes.acceptInviteFailure:
            case confirmationDialogTypes.errorCurrentPassword:
            case confirmationDialogTypes.declineInviteFailure:
                Swal.fire({
                    didClose: cancelModal,
                    ...alertContent,
                    ...dialogStyles.failed
                }).then(() => {
                    if (
                        modalDialogType === confirmationDialogTypes.errorCurrentPassword
                    ) {
                        successHandler();
                    }
                });
                break;
            case confirmationDialogTypes.cancel:
            case confirmationDialogTypes.cleanForm:
                Swal.fire({
                    willClose: cancelModal,
                    showCancelButton: true,
                    reverseButtons: true,
                    focusCancel: true,
                    ...alertContent,
                    ...dialogStyles.warning
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmHandler();
                    } else {
                        cancelHandler();
                    }
                });
                break;
            case confirmationDialogTypes.replaceValue:
                Swal.fire({
                    willClose: cancelModal,
                    input: 'checkbox',
                    inputPlaceholder: additionalAlertContent.inputPlaceholder,
                    showDenyButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    focusCancel: true,
                    returnInputValueOnDeny: true,
                    denyButtonText: alertContent.cancelButtonText,
                    ...alertContent,
                    html: modalContent,
                    ...dialogStyles.warning
                }).then(result => {
                    cancelModal();
                    if (result.isConfirmed) {
                        confirmHandler(!!result.value);
                    } else {
                        closeHandler(!!result.value);
                    }
                });
                break;

            case confirmationDialogTypes.useConnection:
                Swal.fire({
                    showCancelButton: true,
                    allowOutsideClick: false,
                    ...alertContent,
                    title: undefined,
                    html: modalContent,
                    ...dialogStyles.question
                }).then(result => {
                    cancelModal();
                    if (result.isConfirmed) {
                        confirmHandler();
                    } else {
                        closeHandler();
                    }
                });
                break;

            case confirmationDialogTypes.updateFieldsWithNewSigners:
                Swal.fire({
                    showCancelButton: true,
                    allowOutsideClick: false,
                    ...alertContent,
                    title: undefined,
                    ...dialogStyles.question
                }).then(result => {
                    cancelModal();
                    if (result.isConfirmed) {
                        confirmHandler();
                    }
                });
                break;

            case confirmationDialogTypes.removeSigner:
                Swal.fire({
                    showCancelButton: true,
                    ...alertContent,
                    ...additionalAlertContent,
                    input: 'checkbox',
                    ...dialogStyles.warning
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmModal(result.value);
                    } else {
                        cancelModal();
                    }
                });
                break;

            case confirmationDialogTypes.closeTrx:
                SwalWithReactContent.fire({
                    html: (
                        <DatePickerWithIcon
                            nonFormik
                            className="js-range-datepicker form-control bg-white rounded-right flatpickr-input"
                            myStyle={{
                                flexGrow: 1
                            }}
                            id="swal-date-input"
                            placeholder={additionalAlertContent.inputPlaceholder}
                            options={{
                                disableMobile: 'true',
                                dateFormat: 'm-d-Y'
                            }}
                        />
                    ),
                    showCancelButton: true,
                    ...alertContent,
                    ...dialogStyles.question,

                    preConfirm: () => {
                        const value = document.getElementById('swal-date-input').value;
                        if (!value) {
                            SwalWithReactContent.showValidationMessage(
                                additionalAlertContent.validationError
                            );
                        }
                        return value;
                    }
                }).then(result => {
                    if (result.isConfirmed) {
                        confirmModal(result.value);
                    } else {
                        cancelModal();
                    }
                });
                break;

            default:
                Swal.close();
        }
    }, [
        modalDialogType,
        cancelModal,
        confirmHandler,
        cancelHandler,
        confirmModal,
        successHandler,
        alertContent,
        additionalAlertContent,
        modalContent,
        closeHandler
    ]);

    return null;
};

const mapStateToProps = ({ modal }) => {
    const { modalDialogType } = modal;
    return {
        modalDialogType
    };
};

export default withRouter(
    connect(mapStateToProps, { confirmModal, cancelModal })(ConfirmationDialog)
);
