import React from 'react';
import { connect } from 'react-redux';
import { ButtonGroup } from 'reactstrap';
import { useLang } from '../../context/LangContext';
import { setConfirmModalType } from '../../store/actions/Modal';
import { confirmationDialogTypes } from '../../utils/Constants';
import DropdownMenuComponent from './DropdownMenu';

const FormsHeader = ({
    saveDocument,
    documentForFilling,
    requiredLeftNumber,
    dropItems,
    isInSigningMode,
    isSettingsStepVisible,
    setConfirmModalType,
    sendForSignature
}) => {
    const { address_1, address_2, city, state, zip } =
        documentForFilling?.header_transaction_info?.address || {};
    const { saveAndSend_button, saveAndClose_button } =
        useLang()['Documents']['FillingDocument'];
    const { finish_buttonLabel } =
        useLang()['Documents']['FillingDocument']['SigningDocument'];

    return (
        <header id="header" className="u-header bg-primary">
            <div id="h-main" className="container pt-md-2 pb-md-2 h-100">
                <div className="d-flex justify-content-between align-items-center h-100">
                    <div className="text-white">
                        <h1 className="h5 font-weight-medium mb-0">
                            {isInSigningMode
                                ? documentForFilling?.form_title
                                : address_1 + ' ' + (address_2 || '')}
                        </h1>
                        {!isInSigningMode && (
                            <p className="d-block text-white mb-0">
                                {city}, {state} {zip}
                                {!!documentForFilling?.header_transaction_info?.mls &&
                                    ` | mls#${documentForFilling?.header_transaction_info?.mls}`}
                            </p>
                        )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        {isInSigningMode ? (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-sm-wide btn-soft-white transition-3d-hover pointer mr-4"
                                    style={{ transform: 'none' }}
                                    // TODO: rework click handler
                                    onClick={saveDocument}
                                    // disabled={!requiredLeftNumber}
                                >
                                    {finish_buttonLabel}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-lg btn-icon btn-primary rounded-circle transition-3d-hover ml-auto h-100"
                                    // TODO: add click handler
                                    onClick={() => {}}
                                >
                                    <span className="fas fa-times" />
                                </button>
                            </>
                        ) : isSettingsStepVisible ? (
                            <button
                                type="button"
                                className="btn btn-lg btn-icon btn-primary rounded-circle transition-3d-hover ml-auto h-100"
                                onClick={() =>
                                    setConfirmModalType(confirmationDialogTypes.cancel)
                                }
                            >
                                <span className="fas fa-times" />
                            </button>
                        ) : (
                            dropItems && (
                                <ButtonGroup>
                                    <button
                                        type="button"
                                        className="btn btn-sm-wide btn-soft-white transition-3d-hover pointer"
                                        style={{ transform: 'none' }}
                                        onClick={
                                            !requiredLeftNumber
                                                ? sendForSignature
                                                : saveDocument
                                        }
                                    >
                                        {!requiredLeftNumber
                                            ? saveAndSend_button
                                            : saveAndClose_button}
                                    </button>
                                    <DropdownMenuComponent dropItems={dropItems} angle />
                                </ButtonGroup>
                            )
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};
export default connect(null, { setConfirmModalType })(FormsHeader);
