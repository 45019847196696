// React
import React from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Components
import { PipelineRow } from '../../components';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { Alert } from 'reactstrap';

const PipelineList = ({ searchSuggestions, contacts }) => {
    const { windowWidth } = useWindowSize();

    const pipelineList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : contacts;

    const { no_pipeline_label } = useLang()['Pipeline']['PipelineList'];

    return (
        <div className="mb-4" style={{ width: '100%' }}>
            {pipelineList && pipelineList.length ? (
                <>
                    {pipelineList.map(contact => (
                        <PipelineRow
                            contact={contact}
                            key={contact.id}
                            windowWidth={windowWidth}
                        />
                    ))}
                </>
            ) : (
                <Alert color="info" className="mb-0">
                    {no_pipeline_label}
                </Alert>
            )}
        </div>
    );
};

export default PipelineList;
