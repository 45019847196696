// React
import React, { useMemo } from 'react';

// Components
import { TransactionCard, TransactionRow } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';

const TransactionsList = ({
    transactionList,
    match,
    filteredTransactions,
    memberFilteredTransactions,
    searchSuggestions,
    member,
    memberId,
    memberTransactionList,
    memberName
}) => {
    const { none_found_label } = useLang()['Transactions']['TransactionList'];

    const filterBySuggestions = useMemo(
        () => searchSuggestions.map(suggestion => suggestion.transaction),
        [searchSuggestions]
    );

    const transactions = useMemo(() => {
        if (searchSuggestions.length) {
            return filterBySuggestions;
        } else {
            if (member) {
                return memberFilteredTransactions;
            } else {
                return filteredTransactions;
            }
        }
    }, [
        searchSuggestions.length,
        filterBySuggestions,
        member,
        memberFilteredTransactions,
        filteredTransactions
    ]);

    return (
        <div className="mb-4 w-100">
            {transactions?.length ? (
                <>
                    {(!member && transactionList === 'grid') ||
                    (member && memberTransactionList === 'grid') ? (
                        <div
                            className={
                                transactions.length > 3 ? 'card-grid' : 'card-grid-single'
                            }
                        >
                            {transactions.map(({ ...rest }, index) => (
                                <div
                                    className="card mb-5"
                                    key={rest.id}
                                    style={{
                                        maxHeight: '404px',
                                        height: '404px'
                                    }}
                                >
                                    <TransactionCard
                                        match={match}
                                        member={member}
                                        memberId={memberId}
                                        memberName={memberName}
                                        {...rest}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {transactions.map(({ ...rest }) => (
                                <TransactionRow
                                    {...rest}
                                    member={member}
                                    memberId={memberId}
                                    match={match}
                                    memberName={memberName}
                                    key={rest.id}
                                />
                            ))}
                        </>
                    )}
                </>
            ) : (
                <Alert color="info" className="mb-0 mt-2">
                    {none_found_label}
                </Alert>
            )}
        </div>
    );
};

const mapStateToProps = ({ transactions }) => {
    const {
        transactionList,
        memberTransactions,
        filteredTransactions,
        memberFilteredTransactions,
        searchSuggestions,
        loading,
        transactionDetails,
        detailsFlag,
        memberTransactionList
    } = transactions;
    return {
        transactionList,
        memberTransactions,
        filteredTransactions,
        memberFilteredTransactions,
        searchSuggestions,
        loading,
        transactionDetails,
        detailsFlag,
        memberTransactionList
    };
};

export default withRouter(connect(mapStateToProps)(TransactionsList));
