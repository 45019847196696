// React
import React, { useState, useEffect } from 'react';

// Components
import { TextField, CreatableSelectField } from '../';
import { ModalFooter, Button, Modal, ModalBody, Label, ModalHeader } from 'reactstrap';

// Hooks
import { useLang } from '../../context/LangContext';

// Packages
import { Formik } from 'formik';
import * as Yup from 'yup';

// Utils
import { phoneRegExp, errorMessage, nameRegExp } from '../../utils/Constants';

const AddOpposingToTrxModal = ({
    updatingOpposingMembers,
    isOpposingModalOpen,
    setOpposingModalOpen,
    opposing,
    brokerages
}) => {
    const {
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        email_label,
        email_placeholder,
        phone_label,
        phone_placeholder,
        primary_label,
        new_opposing_label,
        add_member_label,
        brokerage_label,
        brokerage_placeholder
    } = useLang()['TransactionDetails']['TransactionMeta'];

    const [primary, setPrimary] = useState(opposing?.length === 0);

    const initialBrokerage = () => {
        const filtered = opposing.filter(member => member.brokerage?.name !== '');
        if (filtered.length > 0) {
            return {
                label: `${filtered[0].brokerage.name} - ${filtered[0].brokerage.mls_id}`,
                value: `${filtered[0].brokerage.name} - ${filtered[0].brokerage.mls_id}`
            };
        }
        return '';
    };

    const brokerageOptions = brokerages?.map(brokerage => ({
        label: `${brokerage.NABOR_OfficeLongName} - ${brokerage.OfficeMlsId}`,
        value: `${brokerage.NABOR_OfficeLongName} - ${brokerage.OfficeMlsId}`
    }));

    const sortedBrokerages = () => {
        const sorted = brokerageOptions.sort((a, b) => {
            if (a.label && b.label) {
                const result = a.label.localeCompare(b.label);
                return result !== 0 ? result : a.label.localeCompare(b.label);
            } else if (!a.label) {
                return 1;
            } else if (!b.label) {
                return -1;
            }
            return true;
        });
        return sorted;
    };

    const initialValues = {
        brokerage: (opposing.length && initialBrokerage()) || '',
        email: '',
        first_name: '',
        last_name: '',
        phone: ''
    };

    const validationSchema = Yup.object().shape(
        {
            brokerage: Yup.object(),
            email: Yup.string()
                .trim()
                .lowercase()
                .email(errorMessage.email.valid)
                .when('phone', {
                    is: phone => !phone || phone.length === 0,
                    then: Yup.string().required(errorMessage.emailOrPhone.required),
                    otherwise: Yup.string()
                }),
            first_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.firstName.valid)
                .when('last_name', {
                    is: lastName => !lastName || lastName.length === 0,
                    then: Yup.string().required(errorMessage.firstOrLastName.required),
                    otherwise: Yup.string()
                }),

            last_name: Yup.string()
                .trim()
                .matches(nameRegExp.format, errorMessage.lastName.valid)
                .when('first_name', {
                    is: firstName => !firstName || firstName.length === 0,
                    then: Yup.string().required(errorMessage.firstOrLastName.required)
                }),

            phone: Yup.string()
                .trim()
                .matches(phoneRegExp.format, errorMessage.phone.valid)
                .when('email', {
                    is: email => !email || email.length === 0,
                    then: Yup.string().required(errorMessage.emailOrPhone.required),
                    otherwise: Yup.string()
                })
        },
        [
            ['first_name', 'last_name'],
            ['phone', 'email']
        ]
    );

    const handleToggle = () => {
        if (opposing.length === 0) {
            setPrimary(true);
        } else {
            setPrimary(false);
        }
        setOpposingModalOpen(toggle => !toggle);
    };

    useEffect(() => {
        opposing && opposing.length === 0 ? setPrimary(true) : setPrimary(false);
    }, [opposing, setPrimary]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                const preparedBrokerage = office => {
                    const brokerage = brokerages.filter(
                        brokerage =>
                            `${brokerage?.NABOR_OfficeLongName} - ${brokerage?.OfficeMlsId}` ===
                            office
                    )[0];

                    return {
                        name: brokerage.NABOR_OfficeLongName,
                        mls_id: brokerage.OfficeMlsId
                    };
                };

                updatingOpposingMembers({
                    member: {
                        brokerage:
                            values.brokerage !== ''
                                ? preparedBrokerage(values.brokerage.label)
                                : {
                                      name: null,
                                      mls_id: null
                                  },
                        email:
                            values.email.trim() !== ''
                                ? values.email.toLowerCase().trim()
                                : null,
                        first_name: values.first_name.trim(),
                        last_name: values.last_name.trim(),
                        phone: values.phone.trim() !== '' ? values.phone.trim() : null,
                        primary
                    },
                    opposing
                });
                handleToggle();
                resetForm();
            }}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
                resetForm
            }) => (
                <Modal
                    isOpen={isOpposingModalOpen}
                    toggle={handleToggle}
                    onClosed={resetForm}
                    contentClassName="add-opposing-modal"
                    className="add-opposing-modal"
                    size="lg"
                >
                    <ModalHeader>{new_opposing_label}</ModalHeader>
                    <ModalBody>
                        <div className="row mt-2 px-4 pt-4 pb-2 d-flex flex-column">
                            <div className="row">
                                <div className="col-sm-6 mb-0">
                                    <div className="js-form-message form-group position-relative">
                                        <Label className="form-label">
                                            {first_name_label}
                                        </Label>
                                        <TextField
                                            type="text"
                                            required
                                            className="form-control"
                                            parentStyle={{ margin: '0 10px' }}
                                            placeholder={first_name_placeholder}
                                            name="first_name"
                                            id="first_name"
                                            value={values.first_name}
                                            error={errors.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={
                                                errors.first_name && touched.first_name
                                            }
                                            hasDefaultFill
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6 mb-0">
                                    <div className="js-form-message form-group position-relative">
                                        <Label className="form-label">
                                            {last_name_label}
                                        </Label>

                                        <TextField
                                            type="text"
                                            required
                                            className="form-control"
                                            parentStyle={{ margin: '0 10px' }}
                                            placeholder={last_name_placeholder}
                                            name="last_name"
                                            id="last_name"
                                            value={values.last_name}
                                            error={errors.last_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={
                                                errors.last_name && touched.last_name
                                            }
                                            hasDefaultFill
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <div className="js-form-message form-group position-relative">
                                        <Label className="form-label">
                                            {email_label}
                                        </Label>

                                        <TextField
                                            type="text"
                                            required
                                            className="form-control"
                                            parentStyle={{ margin: '0 10px' }}
                                            placeholder={email_placeholder}
                                            name="email"
                                            id="email"
                                            value={values.email}
                                            error={errors.email}
                                            onChange={handleChange}
                                            onBlur={e => {
                                                handleBlur(`email`);
                                                setFieldTouched(`email`, true);
                                                setFieldValue(
                                                    `email`,
                                                    e.target.value.toLowerCase().trim()
                                                );
                                            }}
                                            invalid={errors.email && touched.email}
                                            hasDefaultFill
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <div className="js-form-message form-group position-relative">
                                        <Label className="form-label">
                                            {phone_label}
                                        </Label>

                                        <TextField
                                            type="text"
                                            required
                                            className="form-control"
                                            parentStyle={{ margin: '0 10px' }}
                                            placeholder={phone_placeholder}
                                            name="phone"
                                            id="phone"
                                            value={values.phone}
                                            error={errors.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={errors.phone && touched.phone}
                                            hasDefaultFill
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            id={`customCheckboxInlinePrimary`}
                                            name={`customCheckboxInlinePrimary`}
                                            className="custom-control-input pointer"
                                            checked={primary}
                                            disabled={opposing.length === 0}
                                            onChange={() => {
                                                setPrimary(state => !state);
                                            }}
                                        />
                                        <label
                                            className="custom-control-label pointer"
                                            htmlFor={`customCheckboxInlinePrimary`}
                                        >
                                            {primary_label}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4">
                                    <Label className="form-label">
                                        {brokerage_label}
                                    </Label>
                                    <CreatableSelectField
                                        type="text"
                                        className="custom-edit-select p-0"
                                        classNamePrefix={`react-select ${
                                            !!errors.brokerage && touched.brokerage
                                                ? 'danger'
                                                : 'pristine'
                                        }`}
                                        name="brokerage"
                                        id="brokerage"
                                        placeholder={brokerage_placeholder}
                                        aria-label={brokerage_label}
                                        error={errors.brokerage}
                                        value={values.brokerage}
                                        options={sortedBrokerages() || []}
                                        onBlur={() => {
                                            handleBlur('brokerage');
                                            setFieldTouched(`brokerage`, true);
                                        }}
                                        onChange={option => {
                                            handleChange('brokerage');
                                            setFieldValue('brokerage', option);
                                        }}
                                        onCreateOption={option => {
                                            handleChange('brokerage');
                                            setFieldValue('brokerage', {
                                                value: option,
                                                label: option
                                            });
                                        }}
                                        touched={touched.brokerage}
                                        invalid={!!errors.brokerage && touched.brokerage}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="mt-3 d-flex justify-content-between align-items-center">
                            <Button
                                style={{ padding: '5px 20px' }}
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {add_member_label}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </Formik>
    );
};

export default AddOpposingToTrxModal;
