// React
import React, { useState } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Redux - Actions, Reducers, Sagas
import { settingContactsFilter } from '../../store/actions/CompanyContacts';

const OrganizationSearchFilter = ({
    setSearchSuggestions,
    settingContactsFilter,
    contacts,
    companyContacts,
    memberFilterType,
    userId
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { all_label, active_label, inactive_label, input_label } =
        useLang()['Organization']['OrganizationSearchFilter'];

    const filterTypes = [
        {
            name: all_label,
            value: 'all'
        },
        {
            name: active_label,
            value: 'active'
        },
        {
            name: inactive_label,
            value: 'inactive'
        }
    ];

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const countByFilter = filter => {
        if (filter.value !== 'all') {
            const count =
                filter.value === 'active'
                    ? companyContacts.filter(
                          contact => contact.active && contact.id !== userId
                      ).length
                    : companyContacts.filter(
                          contact => !contact.active && contact.id !== userId
                      ).length;
            return count;
        } else {
            const count = companyContacts.filter(contact => contact.id !== userId).length;
            return count;
        }
    };

    const contactInputSearch = input => {
        if (input.trim() !== '') {
            const filteredContacts = [...contacts];
            const filteredName = () => {
                const nameSuggestions = [];
                filteredContacts.filter(contact => {
                    const firstName = contact.first_name
                        ? contact.first_name.toLowerCase()
                        : '';
                    const lastName = contact.last_name
                        ? contact.last_name.toLowerCase()
                        : '';
                    const fullName =
                        `${contact.first_name} ${contact.last_name} `.toLowerCase();
                    if (
                        firstName.includes(input.toLowerCase()) ||
                        lastName.includes(input.toLowerCase()) ||
                        fullName.includes(input.toLowerCase())
                    ) {
                        nameSuggestions.push(contact);
                    }
                    return true;
                });
                return nameSuggestions;
            };

            const totalSuggestions = [...filteredName()];
            setSearchSuggestions(totalSuggestions);
        } else {
            setSearchSuggestions([]);
        }
    };

    return (
        <div id="contactsFilters">
            <div className="container space-lg-1 space-md-0 space-sm-0">
                <div className="row mx-gutters-2 align-items-center">
                    <div className="col-md mb-3 mb-lg-0">
                        <div className="js-focus-state">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text"
                                        id="searchProperty"
                                    >
                                        <span className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="text"
                                    id="searchPropertySr"
                                    placeholder={`${input_label}`}
                                    aria-label={`${input_label}`}
                                    aria-describedby="searchProperty"
                                    autoComplete="off"
                                    onChange={e => contactInputSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-auto ml-md-auto mb-3 mb-lg-0">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <ButtonDropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdown}
                                >
                                    <DropdownToggle caret color="soft-primary" size="sm">
                                        {`${memberFilterType.name} (${countByFilter(
                                            memberFilterType
                                        )})`}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {filterTypes.map(filter => (
                                            <DropdownItem
                                                key={filter.value}
                                                active={
                                                    memberFilterType.value ===
                                                    filter.value
                                                }
                                                onClick={() =>
                                                    settingContactsFilter(filter)
                                                }
                                            >
                                                {`${filter.name} (${countByFilter(
                                                    filter
                                                )})`}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(
    connect(null, { settingContactsFilter })(OrganizationSearchFilter)
);
