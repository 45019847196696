// React
import React, { useState, useEffect, useMemo } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { setConfirmModalType } from '../store/actions/Modal';
// import { removeConnection } from '../store/actions/Connections';

// Packages
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faLink } from '@fortawesome/free-solid-svg-icons';

// Firebase
import { storage } from '../config/Firebase';

// Loggers
// import { log } from '../utils/Loggers';

// Context
import { useLang } from '../context/LangContext';

// Router
import * as routes from '../router/config/routes';

// Components
import { ConnectionDetailsHistory, DetailsHeader } from '../components';
import DropdownMenuComponent from '../components/common/DropdownMenu';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Consts
import { confirmationDialogTypes, headerTypes, userTypes } from '../utils/Constants';

const ConnectionDetails = ({
    userConnections,
    location,
    userData,
    setConfirmModalType,
    modalDialogType
}) => {
    const history = useHistory();

    const [connectionAvatar, setConnectionAvatar] = useState(null);
    const [avatar, setAvatar] = useState(location.state?.imgUrl);

    const connectionId = useMemo(() => {
        const pathname = window.location.pathname;
        if (location.state?.id) return location.state.id;
        return pathname.substr(pathname.lastIndexOf('/') + 1);
    }, [location.state?.id]);

    const connection = useMemo(
        () =>
            userConnections &&
            userConnections.find(connection => {
                return connection?.id?.toString() === connectionId;
            }),
        [userConnections, connectionId]
    );

    const isInternalConnection =
        connection && userConnections.some(el => el.id === connection.id);

    const { notes_heading, history_heading, status, button_label } =
        useLang()['ConnectionDetails'];
    const { delete_label, edit_label } = useLang()['Common']['Dropdown'];

    const dropItems = [
        {
            name: delete_label,
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => console.log('remove connection clicked')
            // onClickHandler: () => removeConnection({ userData, connection, isInternalConnection })
        },
        {
            name: edit_label,
            icon: 'fas fa-edit dropdown-item-icon',
            onClickHandler: () => {}
        }
    ];

    const getConnectionAvatar = (id, setState) => {
        const ref = storage.ref().child(`users/${id}/avatar.jpg`);
        ref.getDownloadURL()
            .then(imgUrl => {
                setState(imgUrl);
            })
            .catch(error => {
                setState(null);
            });
    };

    useEffect(() => {
        getConnectionAvatar(connection?.connection?.id, setConnectionAvatar);
        getConnectionAvatar(connectionId, setAvatar);
    }, [connection, connectionId]);

    const redirectToNewTrx = parties => {
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.NEW_TRANSACTION}`,
            state: { parties }
        });
    };

    const newTransactionClickHandler = () => {
        if (connection.connection) {
            setConfirmModalType(confirmationDialogTypes.useConnection);
        } else {
            redirectToNewTrx([connection]);
        }
    };

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={modalDialogType}
                successHandler={() =>
                    history.push(
                        `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.CONNECTIONS}`
                    )
                }
                confirmHandler={() =>
                    redirectToNewTrx([connection, connection.connection])
                }
                closeHandler={() => redirectToNewTrx([connection])}
            />
            {connection && (
                <>
                    <DetailsHeader
                        connection={connection}
                        headerType={headerTypes.connectionDetails}
                    />
                    <main id="content" role="main" className="push-main">
                        <div className="bg-light">
                            <div
                                id="main"
                                className="connection-frame size container mt-5 p-5 pt-3 pt-xl-4"
                            >
                                {(userData?.type === userTypes.owner ||
                                    isInternalConnection) && (
                                    <div className="row justify-content-end pt-1">
                                        <DropdownMenuComponent dropItems={dropItems} />
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-2 connection-frame__avatars">
                                        <div className="row d-flex justify-content-center">
                                            {avatar ? (
                                                <img
                                                    src={avatar}
                                                    className="connection-frame__avatar"
                                                    alt={`${connection?.first_name} ${connection?.last_name}`}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary connection-frame__avatar rounded-circle"
                                                    name={`${connection.first_name} ${connection.last_name}`}
                                                    unstyled
                                                />
                                            )}
                                        </div>
                                        {connection.connection && (
                                            <div className="row d-flex justify-content-center mt-3 mb-3">
                                                <FontAwesomeIcon
                                                    icon={faLink}
                                                    color="rgb(140, 152, 164)"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-10 mt-2 connection__details">
                                        <h2 className="connection-frame__fullname mb-3">
                                            {connection.first_name} {connection.last_name}
                                        </h2>
                                        <div className="row d-flex align-items-start ml-0 mt-2">
                                            <div className="col">
                                                {connection.phone && (
                                                    <p className="row connection__details-row">
                                                        <FontAwesomeIcon
                                                            className="connection__details-icon"
                                                            icon={faPhone}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        <a
                                                            href={`tel:${connection.phone}`}
                                                        >
                                                            <NumberFormat
                                                                value={connection.phone}
                                                                displayType={'text'}
                                                                format="###.###.####"
                                                                mask="_"
                                                            />
                                                        </a>
                                                    </p>
                                                )}
                                                {connection.email && (
                                                    <p className="row connection__details-row">
                                                        <FontAwesomeIcon
                                                            className="connection__details-icon"
                                                            icon={faEnvelope}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        <a
                                                            href={`mailto:${connection.email}`}
                                                        >
                                                            {connection.email}
                                                        </a>
                                                    </p>
                                                )}
                                            </div>
                                            {connection?.address && (
                                                <div className="col">
                                                    <div className="row mr-8 connection__details-row">
                                                        <FontAwesomeIcon
                                                            className="connection__details-icon"
                                                            icon={faHome}
                                                            color="rgb(140, 152, 164)"
                                                        />
                                                        {connection?.address?.lat &&
                                                        connection?.address?.lon ? (
                                                            <a
                                                                href={`https://google.com/maps/search/?api=1&query=${connection.address.lat},${connection.address.lon}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {
                                                                    connection.address
                                                                        .address_1
                                                                }
                                                                <br />
                                                                {
                                                                    connection.address
                                                                        .city
                                                                },{' '}
                                                                {connection.address.state}{' '}
                                                                {connection.address.zip}
                                                            </a>
                                                        ) : (
                                                            <span className="small text-muted">
                                                                {
                                                                    connection?.address
                                                                        ?.address_1
                                                                }
                                                                <br />
                                                                {
                                                                    connection?.address
                                                                        ?.city
                                                                }
                                                                ,{' '}
                                                                {
                                                                    connection?.address
                                                                        ?.state
                                                                }{' '}
                                                                {connection?.address?.zip}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {connection.connection && (
                                    <div className="row d-flex align-items-center connection-frame__relative">
                                        <Link
                                            className="connection-frame__relative-fullname"
                                            onClick={() => setConnectionAvatar(null)}
                                            to={{
                                                pathname: `${routes.AUTHENTICATED}${routes.CONNECTION_DETAIL}/${connection.connection.id}`,
                                                state: {
                                                    id: connection.connection.id,
                                                    imgUrl: connectionAvatar
                                                }
                                            }}
                                        >
                                            {connectionAvatar ? (
                                                <img
                                                    src={connectionAvatar}
                                                    className="connection-frame__avatar--small"
                                                    alt={`${connection.connection.first_name} ${connection.connection.last_name}`}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary connection-frame__avatar--small rounded-circle"
                                                    name={`${connection.connection.first_name} ${connection.connection.last_name}`}
                                                    unstyled
                                                />
                                            )}
                                            <span className="ml-2">
                                                {connection.connection.first_name}{' '}
                                                {connection.connection.last_name}
                                            </span>
                                        </Link>
                                    </div>
                                )}
                                {connection.notes && (
                                    <div className="mt-5 ml-3 notes">
                                        <h2 className="h6 mb-2 notes__heading">
                                            {notes_heading}
                                        </h2>
                                        <div className="notes__content">
                                            {connection.notes}
                                        </div>
                                    </div>
                                )}
                                {connection.history && (
                                    <ConnectionDetailsHistory
                                        history={connection.history}
                                        heading={history_heading}
                                        status={status}
                                    />
                                )}
                                <div className="d-flex mt-6 mb-2 justify-content-end">
                                    <button
                                        className="btn btn-success transition-3d-hover"
                                        onClick={newTransactionClickHandler}
                                    >
                                        {button_label}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ connections, user, modal }) => {
    const { userConnections } = connections;
    const { userData } = user;
    const { modalDialogType } = modal;
    return { userConnections, userData, modalDialogType };
};

export default withRouter(
    connect(mapStateToProps, { setConfirmModalType })(ConnectionDetails)
);
