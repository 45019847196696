import React from 'react';

import { useLang } from '../../context/LangContext';

const AdminControlsBanner = ({ memberName }) => {
    const { button_label, text1, text2 } =
        useLang()['Transactions']['AdminControlBanner'];

    if (!memberName) return null;

    return (
        <div style={{ backgroundColor: '#8c98a4' }}>
            <div id="h-main" className="size container">
                <div className=" d-flex justify-content-between align-items-center">
                    <p className="text-white" style={{ marginTop: '15px' }}>
                        {text1}
                        <b>{memberName}</b>
                        {text2}
                    </p>
                    <button className="btn btn-dark btn-sm">{button_label}</button>
                </div>
            </div>
        </div>
    );
};

export default AdminControlsBanner;
