// React
import React, { useState } from 'react';

//Packages
import { useLang } from '../../context/LangContext';

// Redux
import { connect } from 'react-redux';
import { saveSignature } from '../../store/actions/DocumentFilling';

// Components
import TabNavigation from '../TransactionDetails/TabNavigation';
import { UploadSignatureTab, SigningTab, ConfirmStep, TypeTab } from '..';
import { Modal, ModalBody } from 'reactstrap';

const signingModalTabs = {
    type: 'text',
    sign: 'drawn',
    upload: 'image'
};

const SigningModal = ({
    userData,
    saveSignature,
    type,
    setIsSigningModalOpen,
    isSigningModalOpen
}) => {
    const { type_tabLabel, sign_tabLabel, upload_tabLabel } =
        useLang()['Documents']['FillingDocument']['SigningDocument'];
    const [tabContent, setTabContent] = useState(signingModalTabs.type);
    const [signingData, setSigningData] = useState(null);

    const tabs = [
        {
            name: type_tabLabel,
            value: signingModalTabs.type,
            id: 'pills-one-example-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-one-example'
        },
        {
            name: sign_tabLabel,
            value: signingModalTabs.sign,
            id: 'pills-two-example-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-two-example'
        },
        {
            name: upload_tabLabel,
            value: signingModalTabs.upload,
            id: 'pills-three-example-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-three-example'
        }
    ];

    const tabClickHandler = newTab => {
        setSigningData(null);
        setTabContent(newTab);
    };

    const applySignature = () => {
        saveSignature({ signingData, userId: userData.id, subtype: tabContent, type });
        setIsSigningModalOpen(false);
        setSigningData(null);
    };

    return (
        <Modal
            centered
            isOpen={isSigningModalOpen}
            toggle={() => setIsSigningModalOpen(prevState => !prevState)}
        >
            <TabNavigation
                onClick={tabClickHandler}
                selectedTab={tabContent}
                tabs={tabs}
                navClassName="top-radius"
                tabClassName="py-2"
            />
            <ModalBody style={{ height: '300px', position: 'relative', display: 'flex' }}>
                {tabContent === signingModalTabs.type ? (
                    <TypeTab
                        setSigningData={setSigningData}
                        firstName={userData.first_name}
                        lastName={userData.last_name}
                        applySignature={applySignature}
                        type={type}
                    />
                ) : signingData ? (
                    <ConfirmStep
                        signingData={signingData}
                        applySignature={applySignature}
                        type={type}
                    />
                ) : tabContent === signingModalTabs.sign ? (
                    <SigningTab setSigningData={setSigningData} type={type} />
                ) : (
                    <UploadSignatureTab setSigningData={setSigningData} type={type} />
                )}
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps, { saveSignature })(SigningModal);
