// React
import React, { useEffect, useState } from 'react';

// Packages
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, FieldArray, Form, Formik } from 'formik';

// Context
import { useLang } from '../../../context/LangContext';

// SVG
import PDF from '../../../assets/svg/PDF.svg';

export function DocumentModal({
    title,
    handleOpen,
    handleToggle,
    onSubmitCb,
    allDocuments = [],
    type
}) {
    const { modal } = useLang()['Settings']['Preferences']['Documents'];

    const [inputText, setInputText] = useState('');
    const [filteredData, setFilteredData] = useState(allDocuments);
    const inputHandler = e => {
        const lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };

    const notEqual = values => {
        const notEqualDocs = allDocuments.filter(doc =>
            values.every(val => doc.id !== val.id)
        );
        return notEqualDocs;
    };

    const initialValuesCollection = () => {
        if (!inputText) {
            return allDocuments.filter(doc => doc.upload_type === type || doc.checked);
        } else if (!filteredData.length && inputText) {
            return allDocuments;
        } else {
            return filteredData;
        }
    };
    useEffect(() => {
        setFilteredData(
            allDocuments.filter(el => {
                if (inputText === '') {
                    return el;
                } else {
                    return el.title.toLowerCase().includes(inputText);
                }
            })
        );
    }, [allDocuments, inputText]);

    const checkboxHandler = (setFieldValue, doc, index, values) => () => {
        setFieldValue(`collection[${index}].checked`, !values.collection[index].checked);
    };
    return (
        <Modal
            centered
            isOpen={handleOpen}
            toggle={handleToggle}
            className="personalInfoModal"
        >
            <Formik
                enableReinitialize={true}
                initialValues={{
                    collection: initialValuesCollection()
                }}
                onSubmit={values => {
                    onSubmitCb({
                        docs:
                            values.collection.length < allDocuments.length
                                ? values.collection.concat(notEqual(values.collection))
                                : values.collection
                    });
                    setInputText('');
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <>
                        <Form>
                            <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
                            <ModalBody>
                                <Field
                                    type="search"
                                    className="fs-5 borderColor rounded form-control "
                                    placeholder={modal.search}
                                    name="search"
                                    onChange={inputHandler}
                                />
                                <div className="mt-2 ">
                                    <FieldArray
                                        name="checked"
                                        render={arrayHelpers => (
                                            <div
                                                className="fs-5 borderColor rounded p-2"
                                                style={{ height: '200px' }}
                                            >
                                                {values.collection &&
                                                    values.collection.map(
                                                        (doc, index) => (
                                                            <div
                                                                key={doc.id}
                                                                className="d-flex align-items-start justify-content-between"
                                                                onClick={checkboxHandler(
                                                                    setFieldValue,
                                                                    doc,
                                                                    index,
                                                                    values
                                                                )}
                                                            >
                                                                <div className="d-flex justify-content-start">
                                                                    <img
                                                                        src={PDF}
                                                                        style={{
                                                                            width: '35px',
                                                                            paddingRight:
                                                                                '10px'
                                                                        }}
                                                                        alt="document"
                                                                    />
                                                                    <label htmlFor="docList">
                                                                        {doc.title}
                                                                    </label>
                                                                </div>
                                                                <Field
                                                                    as="i"
                                                                    name={`collection[${index}].checked`}
                                                                >
                                                                    {!values.collection[
                                                                        index
                                                                    ].checked ? (
                                                                        <i className="fa-thin fa-circle-check" />
                                                                    ) : (
                                                                        <i
                                                                            className="fa-solid fa-circle-check "
                                                                            style={{
                                                                                color: '#13d7b6',
                                                                                borderRadius:
                                                                                    '50%'
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </ModalBody>
                        </Form>
                        <ModalFooter>
                            <Button onClick={handleToggle}>{modal.buttons.cancel}</Button>
                            <Button color="primary" onClick={handleSubmit}>
                                {modal.buttons.update}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
}
