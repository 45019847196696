// React
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
// import { updateOrgMembersStatus } from './../../../store/actions/Org';

// Components
import ActionButtons from '../shared/ActionButtons';

// Context
import { useLang } from '../../../context/LangContext';

// Hooks
// import { useUserAvatars } from '../../../hooks/useUserAvatars';
import { useWindowSize } from '../../../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import cx from 'classnames';
import Avatar from 'react-avatar';
import { Alert } from 'reactstrap';

// Redux - Actions, Reducers, Sagas
import { removeOrgInvitations } from '../../../store/actions/Org';

// Utils
import { formatInviteDate, checkExpiration } from '../../../utils/Helpers';
import { invitationStatuses } from '../../../utils/Constants';

const MemberInvitations = ({
    activeTeam,
    lang,
    team,
    updateOrgMembersStatus,
    setFocusedState,
    setMemberDetails,
    orgScreen,
    orgInvitations,
    removeOrgInvitations
}) => {
    const { accountTypes } = useLang()['Constants'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const [removing, setRemoving] = useState([]);
    const [scrollHeight, setScrollHeight] = useState(0);
    // const [imgByIds, setImgByIds] = useState([]);

    const preparedInvitations = orgInvitations;
    preparedInvitations.sort((a, b) => a.invited_at.seconds - b.invited_at.seconds);

    // const ids = useMemo(
    //     () => preparedInvitations.map(invite => invite.user_id),
    //     [preparedInvitations]
    // );

    // useUserAvatars(ids, setImgByIds);

    const { windowHeight } = useWindowSize();
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const handleSubmit = () => {
        removeOrgInvitations({ removing });
    };

    const handleRemove = invite => {
        const selected = [...removing];
        const index = selected.findIndex(id => id === invite.invite_id);
        if (index === -1) {
            setRemoving([...removing, invite.invite_id]);
        } else {
            const newSelected = [...selected];
            newSelected.splice(index, 1);
            setRemoving(newSelected);
        }
    };

    const filteredInvitations = useMemo(
        () => orgInvitations.filter(el => el.status !== 'accepted'),
        [orgInvitations]
    );

    const getInvitationDate = useCallback(
        invitation =>
            formatInviteDate(
                (invitation.status === invitationStatuses.declined
                    ? invitation.declined_at
                    : invitation.invited_at
                ).seconds
            ),
        []
    );

    const isExpired = invitation => {
        if (invitation.status === invitationStatuses.pending) {
            const expired = checkExpiration(invitation.invited_at.seconds);
            return expired;
        }
        return false;
    };

    useEffect(() => {
        setRemoving([]);
    }, [orgInvitations]);

    if (!filteredInvitations) return null;

    return (
        <>
            {orgInvitations.length ? (
                <>
                    <div
                        style={{
                            animation: 'fadeIn .5s'
                        }}
                    >
                        <div className="container pl-5">
                            <div className="row d-flex justify-content-center mb-2 mt-3">
                                <div className="col-5 pl-3">
                                    <h3 className="h5 font-weight-medium">
                                        {lang?.name_label}
                                    </h3>
                                </div>
                                <div className="col-3 pl-0">
                                    <h3 className="h5 font-weight-medium">
                                        {lang?.type_label}
                                    </h3>
                                </div>
                                <div className="col-4 pl-4">
                                    <h3 className="h5 font-weight-medium">
                                        {lang?.status_label}
                                    </h3>
                                </div>
                            </div>
                            <div ref={measuredRef}>
                                <SimpleBar
                                    className="container "
                                    style={{
                                        height: [scrollHeight] - 83 + 'px'
                                    }}
                                >
                                    {filteredInvitations?.map(invite => (
                                        <div
                                            key={invite.invite_id}
                                            className={cx(
                                                'row d-flex align-items-center w-100 mb-4'
                                            )}
                                            style={{
                                                opacity:
                                                    removing.includes(invite.invite_id) &&
                                                    '0.7'
                                            }}
                                        >
                                            <div className="col-5 pointer">
                                                <div className="row d-flex align-items-center">
                                                    {/* <img
                                                src={
                                                    imgByIds.find(
                                                        avatar =>
                                                            avatar.id === invite?.user_id
                                                    )?.url
                                                }
                                                alt="Fix"
                                                className="rounded-circle contact-frame__avatar--small mr-3 skeleton-loader"
                                            /> */}
                                                    <div className="d-flex d-sm-flex align-items-center">
                                                        <Avatar
                                                            className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                                                            name={`${
                                                                invite?.first_name
                                                                    ? invite.first_name
                                                                    : ''
                                                            } ${
                                                                invite?.last_name
                                                                    ? invite.last_name
                                                                    : ''
                                                            }`}
                                                            unstyled
                                                        />
                                                    </div>
                                                    <div>
                                                        <h6 className="h6 font-weight-medium mb-0 pointer">
                                                            {invite?.first_name}{' '}
                                                            {invite?.last_name}
                                                        </h6>
                                                        <p
                                                            className="small mb-0"
                                                            style={{
                                                                color: '#8C98A4'
                                                            }}
                                                        >
                                                            {invite?.email}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4 d-flex row justify-content-between align-items-center pl-4">
                                                <span>
                                                    {
                                                        accountTypes.find(
                                                            type =>
                                                                type.value ===
                                                                invite.type.value
                                                        ).label
                                                    }
                                                </span>
                                            </div>
                                            <div className="col-3">
                                                <div className="row d-flex align-items-center">
                                                    <div>
                                                        <h6
                                                            className="h6 font-weight-medium mb-0"
                                                            style={{
                                                                color:
                                                                    invite.status ===
                                                                        invitationStatuses.pending &&
                                                                    !isExpired(invite)
                                                                        ? '#00C9A6'
                                                                        : '#DE4437'
                                                            }}
                                                        >
                                                            {isExpired(invite)
                                                                ? lang.invitationStatuses
                                                                      .expired
                                                                : lang.invitationStatuses[
                                                                      invite.status
                                                                  ]}
                                                        </h6>
                                                        <p
                                                            className="small mb-0"
                                                            style={{
                                                                color: '#8C98A4'
                                                            }}
                                                        >
                                                            {getInvitationDate(invite)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <i
                                                className="fa-regular fa-xmark removePhoneIcon pointer"
                                                style={{
                                                    color:
                                                        removing.includes(
                                                            invite.invite_id
                                                        ) && '#de4437'
                                                }}
                                                onClick={() => {
                                                    handleRemove(invite);
                                                }}
                                            />
                                        </div>
                                    ))}
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <ActionButtons
                        cancelLabel={cancel_label}
                        saveLabel={save_label}
                        handleSubmit={handleSubmit}
                        disabled={!removing.length}
                        noCancel={false}
                        linkLabel={''}
                        handleLink={null}
                        handleClose={() => setFocusedState(orgScreen.teamList)}
                    />
                </>
            ) : (
                <div
                    style={{
                        animation: 'fadeIn .5s'
                    }}
                >
                    <div className="container pl-5">
                        <div ref={measuredRef}>
                            <SimpleBar
                                className="container "
                                style={{
                                    height: [scrollHeight] - 83 + 'px'
                                }}
                            >
                                <Alert color="info" className="mb-0 mt-2">
                                    {lang?.none_found_label}
                                </Alert>
                            </SimpleBar>
                        </div>
                        <ActionButtons
                            cancelLabel={cancel_label}
                            saveLabel={save_label}
                            handleSubmit={handleSubmit}
                            disabled={!removing.length}
                            noCancel={false}
                            linkLabel={''}
                            handleLink={null}
                            handleClose={() => setFocusedState(orgScreen.teamList)}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default connect(null, { removeOrgInvitations })(MemberInvitations);
