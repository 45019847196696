import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
    createRef
} from 'react';

// Components
import { DocumentsList, FormInput, FormView, SigningModal } from '..';

// Redux
import { connect } from 'react-redux';

// Helpers
import { generateUid, splitInputText, getPackPageIndex } from '../../utils/Helpers';
import { timeStampNow } from '../../config/Firebase';

const FillingDocumentStep = ({
    documentForFilling,
    openSettings,
    dividedSortedAnnotations,
    setDividedSortedAnnotations,
    activeInput,
    setActiveInput,
    requiredLeftNumber,
    isInSigningMode,
    userId,
    signatures,
    setSignatures
}) => {
    const documentRefs = useRef([]);
    const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);
    const [isSigningModalOpen, setIsSigningModalOpen] = useState(false);
    const isPrevDisabled = useMemo(
        () =>
            dividedSortedAnnotations
                .filter((_, index) => index < activeInput?.page)
                .every(el => !el.length) && activeInput?.indexOnPage === 0,
        [activeInput, dividedSortedAnnotations]
    );
    const isNextDisabled = useMemo(
        () =>
            dividedSortedAnnotations
                .filter((_, index) => index > activeInput?.page)
                .every(el => !el.length) &&
            activeInput?.indexOnPage ===
                dividedSortedAnnotations[activeInput?.page]?.length - 1,
        [activeInput, dividedSortedAnnotations]
    );

    const [assignee, setAssignee] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = event => {
        setInputValue(event.target.value);
    };
    const handleChecked = (id, value) => {
        setInputValue(prev => ({ ...prev, [id]: value }));
    };

    useEffect(() => {
        documentRefs.current = documentForFilling?.documents.map(
            (_, i) => documentRefs.current[i] || createRef()
        );
    }, [documentForFilling]);

    useEffect(() => {
        let inputValue;
        if (activeInput?.item.inputs[0].type === 'checkbox') {
            inputValue = {};
            activeInput?.item.inputs.forEach(
                input => (inputValue = { ...inputValue, [input.id]: !!input.value })
            );
        } else {
            inputValue = '';
            activeInput?.item.inputs.forEach(
                input => (inputValue = inputValue.concat(input.value || ''))
            );
        }
        setAssignee(activeInput?.item.assignee);
        setInputValue(inputValue);
    }, [activeInput, isInSigningMode, userId]);

    useEffect(() => {
        if (!isInSigningMode) return;
        if (['signature', 'initials'].includes(activeInput?.item?.inputs[0].type)) {
            if (
                documentForFilling?.signature_values?.some(
                    // TODO: add dependencies for assignment
                    el => el.type === activeInput.item?.inputs[0].type
                )
            ) {
                setDividedSortedAnnotations(prev =>
                    prev.map((page, index) =>
                        activeInput.page === index
                            ? page.map((el, i) =>
                                  i === activeInput.indexOnPage
                                      ? {
                                            ...el,
                                            isCompleted: true,
                                            inputs: el.inputs.map(input => ({
                                                ...input,
                                                value: userId
                                            }))
                                        }
                                      : el
                              )
                            : page
                    )
                );
                setSignatures(prev =>
                    prev.map(signature =>
                        signature.annotation_id === activeInput?.item?.inputs[0].id
                            ? {
                                  ...signature,
                                  complete: true,
                                  signed_at: timeStampNow(),
                                  cert_id: generateUid(),
                                  user_id: userId
                              }
                            : signature
                    )
                );
            } else {
                setIsSigningModalOpen(true);
            }
        }
    }, [
        activeInput,
        documentForFilling?.signature_values,
        setInputValue,
        userId,
        isInSigningMode,
        setDividedSortedAnnotations,
        setSignatures
    ]);

    const setNextEmptyInput = useCallback(
        dividedAnnotations => {
            for (
                let page = activeInput?.page || 0;
                page < dividedAnnotations.length;
                page++
            ) {
                const nextActiveIndex = dividedAnnotations[page].findIndex(
                    (el, i) =>
                        !el.isCompleted &&
                        (page !== activeInput?.page ||
                            i > (activeInput?.indexOnPage ?? -1))
                );
                if (nextActiveIndex !== -1) {
                    setActiveInput({
                        page,
                        item: dividedAnnotations[page][nextActiveIndex],
                        indexOnPage: nextActiveIndex
                    });
                    break;
                }
            }
        },
        [setActiveInput, activeInput]
    );

    const saveCurrentValue = useCallback(() => {
        if (activeInput?.item.inputs[0].type === 'checkbox') {
            const isCompleted = Object.values(activeInput?.item.inputs).some(
                el => el.value
            );
            setDividedSortedAnnotations(prev =>
                prev.map((page, index) =>
                    activeInput.page === index
                        ? page.map((el, i) =>
                              i === activeInput.indexOnPage
                                  ? {
                                        ...el,
                                        isCompleted,
                                        assignee,
                                        inputs: el.inputs.map(input => ({
                                            ...input,
                                            value: inputValue[input.id] || false,
                                            assigned: !!assignee,
                                            assignment: assignee || null
                                        }))
                                    }
                                  : el
                          )
                        : page
                )
            );
        } else {
            const { separatedLines, fontSize } = splitInputText(
                inputValue,
                activeInput.item.inputs
            );
            setDividedSortedAnnotations(prev =>
                prev.map((page, index) =>
                    activeInput.page === index
                        ? page.map((el, i) =>
                              i === activeInput.indexOnPage
                                  ? {
                                        ...el,
                                        isCompleted: !!inputValue,
                                        assignee,
                                        inputs: el.inputs.map((input, inputIndex) => ({
                                            ...input,
                                            value: separatedLines[inputIndex] || '',
                                            recalculated_font_size: fontSize,
                                            assigned: !!assignee,
                                            assignment: assignee || null
                                        }))
                                    }
                                  : el
                          )
                        : page
                )
            );
        }
    }, [activeInput, inputValue, setDividedSortedAnnotations, assignee]);

    useEffect(() => {
        if (!documentForFilling || dividedSortedAnnotations.length) return null;
        const pages = documentForFilling.documents.map(({ pages }) => pages).flat();
        let dividedAnnotations = new Array(pages.length).fill([]);

        const pagesLength = documentForFilling.documents.map(({ pages }) => pages.length);

        const annotations = documentForFilling.documents
            .map(({ annotations }, index) =>
                annotations?.map(annotation => ({
                    ...annotation,
                    documentIndex: index,
                    packPageNumber: getPackPageIndex(
                        pagesLength,
                        annotation.page_number,
                        index
                    )
                }))
            )
            .flat();

        // Sort annotations from top to bottom, from left to right)
        const sortedAnnotationsArray = annotations.sort((a, b) => {
            if (a.documentIndex < b.documentIndex) return -1;
            if (a.documentIndex > b.documentIndex) return 1;

            if (a.packPageNumber < b.packPageNumber) return -1;
            if (a.packPageNumber > b.packPageNumber) return 1;

            const difference = Math.abs(a.coords.y - b.coords.y);
            if (difference >= 12) {
                if (a.coords.y < b.coords.y) return -1;
                if (a.coords.y > b.coords.y) return 1;
            }

            if (a.coords.x < b.coords.x) return -1;
            return 1;
        });

        // Divide annotation into pages
        sortedAnnotationsArray.forEach(item => {
            dividedAnnotations = dividedAnnotations.map((page, index) =>
                item.packPageNumber - 1 === index
                    ? item.group_name && page.some(el => el.groupName === item.group_name)
                        ? page.map(el =>
                              el.groupName === item.group_name
                                  ? {
                                        ...el,
                                        inputs: [...el.inputs, item],
                                        isCompleted: el.isCompleted || !!item.value
                                    }
                                  : el
                          )
                        : [
                              ...page,
                              {
                                  inputs: [item],
                                  isCompleted: !!item.value,
                                  isRequired: ['signature', 'initials'].includes(
                                      item.type
                                  )
                                      ? false
                                      : item.required,
                                  groupName: item.group_name,
                                  mappingKey: item.map_key?.key,
                                  assignee: item.assignment
                              }
                          ]
                    : page
            );
        });

        setDividedSortedAnnotations(dividedAnnotations);
        if (!isInSigningMode) setNextEmptyInput(dividedAnnotations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentForFilling]);

    const setActiveHandler = useCallback(
        option => {
            if (activeInput) saveCurrentValue();
            if (option === 'prev') {
                let nextInputIndex, nextCurrentPage;
                if (activeInput.indexOnPage === 0) {
                    nextCurrentPage = dividedSortedAnnotations.findLastIndex(
                        (el, index) => el.length && index < activeInput.page
                    );
                    nextInputIndex = dividedSortedAnnotations[nextCurrentPage].length - 1;
                } else {
                    nextInputIndex = activeInput.indexOnPage - 1;
                    nextCurrentPage = activeInput.page;
                }
                setActiveInput({
                    page: nextCurrentPage,
                    item: dividedSortedAnnotations[nextCurrentPage][nextInputIndex],
                    indexOnPage: nextInputIndex
                });
            } else {
                setNextEmptyInput(dividedSortedAnnotations);
            }
        },
        [
            activeInput,
            setActiveInput,
            dividedSortedAnnotations,
            setNextEmptyInput,
            saveCurrentValue
        ]
    );

    const setActiveByClick = useCallback(
        item => {
            if (activeInput) saveCurrentValue();
            if (isInSigningMode && activeInput?.item?.inputs[0].id === item.inputs[0].id)
                setIsSigningModalOpen(true);
            const page = item.inputs[0].packPageNumber - 1;
            setActiveInput({
                page,
                item,
                indexOnPage: dividedSortedAnnotations[page].findIndex(
                    el => item.inputs[0].id === el.inputs[0].id
                )
            });
        },
        [
            dividedSortedAnnotations,
            saveCurrentValue,
            activeInput,
            setActiveInput,
            isInSigningMode
        ]
    );

    const setActiveDocumentClickHandler = (_, document) => {
        setActiveDocumentIndex(document);
        if (documentRefs.current && documentRefs.current[document].current) {
            documentRefs.current[document].current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    return (
        <main id="main" className="bg-light">
            <SigningModal
                isSigningModalOpen={isSigningModalOpen}
                setIsSigningModalOpen={setIsSigningModalOpen}
                type={activeInput?.item?.inputs[0].type}
            />

            <div className="container pt-5">
                <div className="d-flex no-gutters">
                    <div className="col-3 pr-3">
                        {!isInSigningMode && (
                            <FormInput
                                formTitle={documentForFilling?.form_title}
                                activeInput={activeInput?.item}
                                setActiveHandler={setActiveHandler}
                                inputValue={inputValue}
                                handleInputChange={handleInputChange}
                                handleChecked={handleChecked}
                                isPrevDisabled={isPrevDisabled}
                                isNextDisabled={isNextDisabled}
                                requiredLeftNumber={requiredLeftNumber}
                                openSettings={openSettings}
                                signers={
                                    documentForFilling?.filling_settings.confirm_signers
                                }
                                requiredFieldsSetting={
                                    documentForFilling?.filling_settings
                                        .required_fields_select
                                }
                                assignee={assignee}
                                setAssignee={setAssignee}
                            />
                        )}
                        {documentForFilling.documents.length > 1 && (
                            <DocumentsList
                                documents={documentForFilling.documents}
                                selected={
                                    documentForFilling.documents[activeDocumentIndex]
                                        .position
                                }
                                documentClickHandler={setActiveDocumentClickHandler}
                            />
                        )}
                    </div>
                    <FormView
                        documentForFilling={documentForFilling}
                        dividedSortedAnnotations={dividedSortedAnnotations}
                        activeInput={activeInput}
                        setActiveByClick={setActiveByClick}
                        isInSigningMode={isInSigningMode}
                        setNextEmptyInput={setActiveHandler}
                        setInputValue={setInputValue}
                        handleChecked={handleChecked}
                        setActiveDocumentIndex={setActiveDocumentIndex}
                        documentRefs={documentRefs}
                        userId={userId}
                        signatures={signatures}
                    />
                </div>
            </div>
        </main>
    );
};

const mapStateToProps = ({ documentFilling, user }) => {
    const { documentForFilling, loading } = documentFilling;
    const { userData } = user;
    return { documentForFilling, loading, userId: userData.id };
};

export default connect(mapStateToProps)(FillingDocumentStep);
