// React
import React, { useState } from 'react';

// Components
import { TextField, StateCountrySelect, ConfirmationDialog } from '../../components';
import TransactionSuggestionList from './TransactionSuggestionsList';

// Constants
import {
    confirmationDialogTypes,
    lookupRegExp,
    stateProvince
} from '../../utils/Constants';
import { getUsStateName } from '../../utils/Helpers';

// Context
import { useLang } from '../../context/LangContext';

// Loggers
import { log } from '../../utils/Loggers';

//Packages
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { Label, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { setConfirmModalType } from './../../store/actions/Modal';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const Address = SmartyStreetsSDK.usStreet.Lookup;

const ListingFlowStep1 = ({
    parties,
    checkError,
    errors,
    values,
    setFieldTouched,
    setFieldValue,
    arrayHelpers,
    handleChange,
    handleBlur,
    handleSubmit,
    checkTouched,
    touched,
    isSubmitting,
    addedParty,
    setIdCount,
    idCount,
    contacts,
    pipeline,
    setConfirmModalType,
    modalDialogType
}) => {
    const key = process.env.REACT_APP_SMARTY_WEBSITE_KEY;
    const credentials = new SmartyStreetsCore.SharedCredentials(key);
    const lookupClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
    const addressClient = SmartyStreetsCore.buildClient.usStreet(credentials);
    const [activeFieldSuggestion, setActiveFieldSuggestion] = useState(null);
    const [searchSuggestions, setSearchSuggestions] = useState(null);
    const [lookupResults, setLookupResults] = useState(null);
    const [resultsIndex, setResultsIndex] = useState(null);
    const [connection, setConnection] = useState(null);
    const {
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        legal_name_label,
        legal_name_placeholder,
        email_address_label,
        email_address_placeholder,
        phone_number_label,
        phone_number_placeholder,
        mailing_address_same_label,
        address_label,
        address_placeholder,
        city_label,
        city_placeholder,
        unit_label,
        unit_placeholder,
        state_label,
        zip_label,
        zip_placeholder,
        add_party_label,
        remove_party_label,
        next_button_label,
        modal_text
    } = useLang()['NewTransaction']['ListingFlowStep1'];

    const addressInputHandler = (address, index) => {
        if (lookupRegExp.valid.test(address)) {
            const lookup = new Lookup(address);
            lookup.maxSuggestions = 6;
            lookupClient
                .send(lookup)
                .then(results => {
                    const { result } = results;
                    setLookupResults(result);
                    setResultsIndex(index);
                })
                .catch(error => {
                    log(
                        'Smarty Streets Error: error in address lookup results (LISTING_TRX)',
                        {
                            error,
                            address,
                            function: 'addressInputHandler'
                        }
                    );
                });
        } else {
            setLookupResults(null);
            setResultsIndex(null);
        }
    };

    const contactInputSearch = (input, fieldName) => {
        if (input.trim() !== '') {
            const filteredConcatedContacts = [...contacts, ...pipeline];

            const suggestions = filteredConcatedContacts
                .filter(contact =>
                    contact[fieldName]?.toLowerCase().includes(input.toLowerCase())
                )
                .sort((a, b) => {
                    if (a.last_name && a.first_name && b.last_name && b.first_name) {
                        const result = a.first_name.localeCompare(b.first_name);
                        return result !== 0
                            ? result
                            : a.last_name.localeCompare(b.last_name);
                    } else if (!a.last_name || !a.first_name) {
                        return 1;
                    } else if (!b.last_name || !b.first_name) {
                        return -1;
                    }
                    return true;
                });
            setSearchSuggestions(suggestions);
        } else {
            setSearchSuggestions([]);
        }
    };

    const handleConfirm = () => {
        const connectionData = [...contacts, ...pipeline].find(
            el => el.id === connection.id || el.id === connection.pipeline_id
        );
        if (!connectionData) return;
        const linkedParty = {
            id: 1,
            firstName: connectionData.first_name || addedParty.firstName,
            lastName: connectionData.last_name || addedParty.lastName,
            fullLegalName: connectionData.legal_name || addedParty.fullLegalName,
            email: connectionData.email || addedParty.email,
            address: addedParty.address,
            address2: addedParty.address2,
            phone: connectionData.phone || addedParty.phone,
            city: addedParty.city,
            state: addedParty.state,
            zip: addedParty.zip,
            sameAddress: true,
            pipelineId: connectionData.pipeline_id || ''
        };

        arrayHelpers.replace(1, linkedParty);
        for (let i = 2; i < idCount; i++) {
            arrayHelpers.pop();
        }
        setIdCount(2);
    };

    const handleSkip = () => {
        for (let i = 1; i < idCount; i++) {
            arrayHelpers.pop();
        }
        setIdCount(1);
    };

    const handleRemove = index => {
        arrayHelpers.remove(index);
        setIdCount(idCount - 1);
    };

    const getAddressFromLookup = address => {
        const batch = new SmartyStreetsCore.Batch();
        const verifiedAddress = new Address();
        verifiedAddress.street = address.streetLine;
        verifiedAddress.urbanization = ''; // Only applies to Puerto Rico addresses
        verifiedAddress.city = address.city;
        verifiedAddress.state = address.state;
        verifiedAddress.maxCandidates = 3;
        verifiedAddress.match = 'invalid';
        batch.add(verifiedAddress);
        addressClient
            .send(batch)
            .then(result => {
                const { components } = result.lookups[0].result[0] || null;
                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                } `;
                const address2 = components.secondaryNumber
                    ? `${components.secondaryNumber} `
                    : '';

                if (result.lookups[0].result[0]) {
                    setFieldTouched(`parties.${resultsIndex}.address`, true);
                    setFieldValue(`parties.${resultsIndex}.address`, address1);
                    setFieldTouched(`parties.${resultsIndex}.address2`, true);
                    setFieldValue(`parties.${resultsIndex}.address2`, address2);
                    setFieldTouched(`parties.${resultsIndex}.city`, true);
                    setFieldValue(`parties.${resultsIndex}.city`, components.cityName);
                    setFieldTouched(`parties.${resultsIndex}.state`, true);
                    setFieldValue(
                        `parties.${resultsIndex}.state`,
                        getUsStateName(components.state)
                    );
                    setFieldTouched(`parties.${resultsIndex}.zip`, true);
                    setFieldValue(`parties.${resultsIndex}.zip`, components.zipCode);
                    setLookupResults(null);
                    setResultsIndex(null);
                }
            })
            .catch(error => {
                log(
                    'Smarty Streets Error: error in address lookup details (LISTING_TRX)',
                    {
                        error,
                        address,
                        function: 'getAddressFromLookup'
                    }
                );
            });
    };

    return (
        <>
            {modalDialogType === confirmationDialogTypes.useConnection && (
                <ConfirmationDialog
                    initialModalDialogType={modalDialogType}
                    confirmHandler={handleConfirm}
                    closeHandler={handleSkip}
                    modalContent={`${modal_text} <br/> <br/> <b>${connection.first_name} ${connection.last_name}</b>`}
                />
            )}
            <div
                id="contentStepForm"
                className="card-body p-5 w-lg-75 mx-lg-auto"
                style={{
                    animation: 'fadeIn .5s'
                }}
            >
                {parties && parties.length > 0
                    ? parties.map((party, index) => (
                          <div key={party.id}>
                              <div className="row">
                                  <div className="col-sm-6 mb-0">
                                      <div className="js-form-message form-group position-relative">
                                          <Label className="form-label">
                                              {first_name_label}
                                              <span className="text-danger ml-1">*</span>
                                          </Label>
                                          <TextField
                                              type="text"
                                              className="form-control"
                                              name={`parties.${index}.firstName`}
                                              id={`parties.${index}.firstName`}
                                              placeholder={first_name_placeholder}
                                              aria-label={first_name_placeholder}
                                              error={checkError(
                                                  errors,
                                                  index,
                                                  'firstName'
                                              )}
                                              value={values.parties[index].firstName}
                                              onBlur={e => {
                                                  handleBlur(
                                                      `parties.${index}.firstName`
                                                  );
                                                  setFieldTouched(
                                                      `parties.${index}.firstName`,
                                                      true
                                                  );
                                                  setFieldValue(
                                                      `parties.${index}.firstName`,
                                                      e.target.value.trim()
                                                  );
                                              }}
                                              onChange={e => {
                                                  handleChange(e);
                                                  contactInputSearch(
                                                      e.target.value,
                                                      'first_name'
                                                  );
                                              }}
                                              onFocus={() =>
                                                  setActiveFieldSuggestion({
                                                      fieldName: 'first_name',
                                                      index
                                                  })
                                              }
                                              invalid={
                                                  !!checkError(
                                                      errors,
                                                      index,
                                                      'firstName'
                                                  ) &&
                                                  checkTouched(
                                                      touched,
                                                      index,
                                                      'firstName'
                                                  )
                                              }
                                          />
                                          {activeFieldSuggestion?.fieldName ===
                                              'first_name' &&
                                              activeFieldSuggestion?.index === index &&
                                              !index && (
                                                  <TransactionSuggestionList
                                                      setActiveFieldSuggestion={
                                                          setActiveFieldSuggestion
                                                      }
                                                      setFieldValue={setFieldValue}
                                                      searchSuggestions={
                                                          searchSuggestions
                                                      }
                                                      index={index}
                                                      setConfirmModalType={
                                                          setConfirmModalType
                                                      }
                                                      setConnection={setConnection}
                                                      arrayHelpers={arrayHelpers}
                                                      setIdCount={setIdCount}
                                                      idCount={idCount}
                                                  />
                                              )}
                                      </div>
                                  </div>
                                  {/* <div className="col-sm-1 mb-0">
                                  <div className="js-form-message">
                                      <Label className="form-label ml-1">
                                          {middle_initial_label}
                                      </Label>
                                      <TextField
                                          type="text"
                                          className="form-control p-0 pl-1"
                                          name={`parties.${index}.middleInitial`}
                                          id={`parties.${index}.middleInitial`}
                                          placeholder=""
                                          aria-label={middle_initial_label}
                                          error={checkError(
                                              errors,
                                              index,
                                              'middleInitial'
                                          )}
                                          value={values.parties[index].middleInitial}
                                          onBlur={e => {
                                              handleBlur(
                                                  `parties.${index}.middleInitial`
                                              );
                                              setFieldTouched(
                                                  `parties.${index}.middleInitial`,
                                                  true
                                              );
                                              setFieldValue(
                                                  `parties.${index}.middleInitial`,
                                                  e.target.value.trim()
                                              );
                                          }}
                                          onChange={e => {
                                              handleChange(e);
                                          }}
                                          invalid={
                                              !!checkError(
                                                  errors,
                                                  index,
                                                  'middleInitial'
                                              ) &&
                                              checkTouched(
                                                  touched,
                                                  index,
                                                  'middleInitial'
                                              )
                                          }
                                      />
                                  </div>
                              </div> */}
                                  <div className="col-sm-6 mb-0">
                                      <div className="js-form-message form-group position-relative">
                                          <Label className="form-label">
                                              {last_name_label}
                                              <span className="text-danger ml-1">*</span>
                                          </Label>
                                          <TextField
                                              type="text"
                                              className="form-control"
                                              name={`parties.${index}.lastName`}
                                              id={`parties.${index}.lastName`}
                                              placeholder={last_name_placeholder}
                                              aria-label={last_name_placeholder}
                                              error={checkError(
                                                  errors,
                                                  index,
                                                  'lastName'
                                              )}
                                              value={values.parties[index].lastName}
                                              onBlur={e => {
                                                  handleBlur(`parties.${index}.lastName`);
                                                  setFieldTouched(
                                                      `parties.${index}.lastName`,
                                                      true
                                                  );
                                                  setFieldValue(
                                                      `parties.${index}.lastName`,
                                                      e.target.value.trim()
                                                  );
                                              }}
                                              onChange={e => {
                                                  handleChange(e);
                                                  contactInputSearch(
                                                      e.target.value,
                                                      'last_name'
                                                  );
                                              }}
                                              onFocus={() =>
                                                  setActiveFieldSuggestion({
                                                      fieldName: 'last_name',
                                                      index
                                                  })
                                              }
                                              invalid={
                                                  !!checkError(
                                                      errors,
                                                      index,
                                                      'lastName'
                                                  ) &&
                                                  checkTouched(touched, index, 'lastName')
                                              }
                                          />
                                          {activeFieldSuggestion?.fieldName ===
                                              'last_name' &&
                                              activeFieldSuggestion?.index === index &&
                                              !index && (
                                                  <TransactionSuggestionList
                                                      setActiveFieldSuggestion={
                                                          setActiveFieldSuggestion
                                                      }
                                                      setFieldValue={setFieldValue}
                                                      searchSuggestions={
                                                          searchSuggestions
                                                      }
                                                      index={index}
                                                      setConfirmModalType={
                                                          setConfirmModalType
                                                      }
                                                      setConnection={setConnection}
                                                      arrayHelpers={arrayHelpers}
                                                      setIdCount={setIdCount}
                                                      idCount={idCount}
                                                      sameAddress={
                                                          values.parties[index]
                                                              .sameAddress
                                                      }
                                                  />
                                              )}
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-8 mb-0">
                                      <div className="js-form-message form-group position-relative">
                                          <Label className="form-label">
                                              {legal_name_label}
                                          </Label>
                                          <TextField
                                              type="text"
                                              className="form-control"
                                              name={`parties.${index}.fullLegalName`}
                                              id={`parties.${index}.fullLegalName`}
                                              placeholder={legal_name_placeholder}
                                              aria-label={legal_name_placeholder}
                                              error={checkError(
                                                  errors,
                                                  index,
                                                  'fullLegalName'
                                              )}
                                              value={values.parties[index].fullLegalName}
                                              onBlur={e => {
                                                  handleBlur(
                                                      `parties.${index}.fullLegalName`
                                                  );
                                                  setFieldTouched(
                                                      `parties.${index}.fullLegalName`,
                                                      true
                                                  );
                                                  setFieldValue(
                                                      `parties.${index}.fullLegalName`,
                                                      e.target.value.trim()
                                                  );
                                              }}
                                              onChange={e => {
                                                  handleChange(e);
                                                  contactInputSearch(
                                                      e.target.value,
                                                      'legal_name'
                                                  );
                                              }}
                                              onFocus={() =>
                                                  setActiveFieldSuggestion({
                                                      fieldName: 'legal_name',
                                                      index
                                                  })
                                              }
                                              invalid={
                                                  !!checkError(
                                                      errors,
                                                      index,
                                                      'fullLegalName'
                                                  ) &&
                                                  checkTouched(
                                                      touched,
                                                      index,
                                                      'fullLegalName'
                                                  )
                                              }
                                          />
                                          {activeFieldSuggestion?.fieldName ===
                                              'legal_name' &&
                                              activeFieldSuggestion?.index === index &&
                                              !index && (
                                                  <TransactionSuggestionList
                                                      setActiveFieldSuggestion={
                                                          setActiveFieldSuggestion
                                                      }
                                                      setFieldValue={setFieldValue}
                                                      searchSuggestions={
                                                          searchSuggestions
                                                      }
                                                      index={index}
                                                      setConfirmModalType={
                                                          setConfirmModalType
                                                      }
                                                      setConnection={setConnection}
                                                      arrayHelpers={arrayHelpers}
                                                      setIdCount={setIdCount}
                                                      idCount={idCount}
                                                  />
                                              )}
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-6 mb-3">
                                      <div className="js-form-message form-group position-relative">
                                          <Label className="form-label">
                                              {email_address_label}
                                          </Label>
                                          <TextField
                                              type="text"
                                              className="form-control"
                                              name={`parties.${index}.email`}
                                              id={`parties.${index}.email`}
                                              placeholder={email_address_placeholder}
                                              aria-label={email_address_placeholder}
                                              error={checkError(errors, index, 'email')}
                                              value={values.parties[index].email}
                                              onBlur={e => {
                                                  handleBlur(`parties.${index}.email`);
                                                  setFieldTouched(
                                                      `parties.${index}.email`,
                                                      true
                                                  );
                                                  setFieldValue(
                                                      `parties.${index}.email`,
                                                      e.target.value.toLowerCase().trim()
                                                  );
                                              }}
                                              onChange={e => {
                                                  handleChange(e);
                                                  contactInputSearch(
                                                      e.target.value,
                                                      'email'
                                                  );
                                              }}
                                              onFocus={() =>
                                                  setActiveFieldSuggestion({
                                                      fieldName: 'email',
                                                      index
                                                  })
                                              }
                                              invalid={
                                                  !!checkError(errors, index, 'email') &&
                                                  checkTouched(touched, index, 'email')
                                              }
                                          />
                                          {activeFieldSuggestion?.fieldName === 'email' &&
                                              activeFieldSuggestion?.index === index &&
                                              !index && (
                                                  <TransactionSuggestionList
                                                      setActiveFieldSuggestion={
                                                          setActiveFieldSuggestion
                                                      }
                                                      setFieldValue={setFieldValue}
                                                      searchSuggestions={
                                                          searchSuggestions
                                                      }
                                                      index={index}
                                                      setConfirmModalType={
                                                          setConfirmModalType
                                                      }
                                                      setConnection={setConnection}
                                                      arrayHelpers={arrayHelpers}
                                                      setIdCount={setIdCount}
                                                      idCount={idCount}
                                                  />
                                              )}
                                      </div>
                                  </div>
                                  <div className="col-sm-6 mb-3">
                                      <div className="js-form-message form-group position-relative">
                                          <Label className="form-label">
                                              {phone_number_label}
                                              {/* {index === 0 && (
                                              <span className="text-danger ml-1">*</span>
                                          )} */}
                                          </Label>
                                          <TextField
                                              type="text"
                                              className="form-control"
                                              name={`parties.${index}.phone`}
                                              id={`parties.${index}.phone`}
                                              placeholder={phone_number_placeholder}
                                              aria-label={phone_number_placeholder}
                                              error={checkError(errors, index, 'phone')}
                                              value={values.parties[index].phone}
                                              onBlur={e => {
                                                  handleBlur(`parties.${index}.phone`);
                                                  setFieldTouched(
                                                      `parties.${index}.phone`,
                                                      true
                                                  );
                                                  setFieldValue(
                                                      `parties.${index}.phone`,
                                                      e.target.value.trim()
                                                  );
                                              }}
                                              onChange={e => {
                                                  handleChange(e);
                                                  contactInputSearch(
                                                      e.target.value,
                                                      'phone'
                                                  );
                                              }}
                                              onFocus={() =>
                                                  setActiveFieldSuggestion({
                                                      fieldName: 'phone',
                                                      index
                                                  })
                                              }
                                              invalid={
                                                  !!checkError(errors, index, 'phone') &&
                                                  checkTouched(touched, index, 'phone')
                                              }
                                          />
                                          {activeFieldSuggestion?.fieldName === 'phone' &&
                                              activeFieldSuggestion?.index === index &&
                                              !index && (
                                                  <TransactionSuggestionList
                                                      setActiveFieldSuggestion={
                                                          setActiveFieldSuggestion
                                                      }
                                                      setFieldValue={setFieldValue}
                                                      searchSuggestions={
                                                          searchSuggestions
                                                      }
                                                      index={index}
                                                      setConfirmModalType={
                                                          setConfirmModalType
                                                      }
                                                      setConnection={setConnection}
                                                      arrayHelpers={arrayHelpers}
                                                      setIdCount={setIdCount}
                                                      idCount={idCount}
                                                  />
                                              )}
                                      </div>
                                  </div>
                              </div>
                              {!values.parties[index].sameAddress && (
                                  <div
                                      style={{
                                          animation: 'fadeIn .5s'
                                      }}
                                  >
                                      <div className="row">
                                          <div className="col-md-8 mb-3">
                                              <div className="form-group">
                                                  <div className="js-focus-state">
                                                      <Label
                                                          className="form-label"
                                                          htmlFor="listingAddress"
                                                      >
                                                          {address_label}
                                                          {index === 0 && (
                                                              <span className="text-danger ml-1">
                                                                  *
                                                              </span>
                                                          )}
                                                      </Label>

                                                      <div
                                                          className="input-group"
                                                          style={{
                                                              display: 'flex',
                                                              flexWrap: 'nowrap'
                                                          }}
                                                      >
                                                          <div
                                                              className="input-group-prepend"
                                                              style={{
                                                                  maxHeight: 50
                                                              }}
                                                          >
                                                              <span
                                                                  className="input-group-text"
                                                                  id="listingAddressLabel"
                                                                  style={{
                                                                      borderColor:
                                                                          !!checkError(
                                                                              errors,
                                                                              index,
                                                                              'address'
                                                                          ) &&
                                                                          checkTouched(
                                                                              touched,
                                                                              index,
                                                                              'address'
                                                                          )
                                                                              ? '#de4437'
                                                                              : ''
                                                                  }}
                                                              >
                                                                  <span className="fas fa-map-marker-alt" />
                                                              </span>
                                                          </div>

                                                          <TextField
                                                              type="text"
                                                              myStyle={{
                                                                  borderTopLeftRadius: 0,
                                                                  borderBottomLeftRadius: 0,
                                                                  flexGrow: 1
                                                              }}
                                                              className="form-control"
                                                              name={`parties.${index}.address`}
                                                              id={`parties.${index}address`}
                                                              placeholder={
                                                                  address_placeholder
                                                              }
                                                              aria-label={
                                                                  address_placeholder
                                                              }
                                                              error={checkError(
                                                                  errors,
                                                                  index,
                                                                  'address'
                                                              )}
                                                              value={
                                                                  values.parties[index]
                                                                      .address
                                                              }
                                                              onBlur={e => {
                                                                  handleBlur(
                                                                      `parties.${index}.address`
                                                                  );
                                                                  setFieldTouched(
                                                                      `parties.${index}.address`,
                                                                      true
                                                                  );
                                                                  setFieldValue(
                                                                      `parties.${index}.address`,
                                                                      e.target.value.trim()
                                                                  );
                                                              }}
                                                              onChange={e => {
                                                                  handleChange(e);
                                                                  addressInputHandler(
                                                                      e.target.value,
                                                                      index
                                                                  );
                                                              }}
                                                              invalid={
                                                                  !!checkError(
                                                                      errors,
                                                                      index,
                                                                      'address'
                                                                  ) &&
                                                                  checkTouched(
                                                                      touched,
                                                                      index,
                                                                      'address'
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                              {lookupResults &&
                                              lookupResults.length &&
                                              resultsIndex === index ? (
                                                  <ul className="auto-complete-container">
                                                      {lookupResults.map(result => (
                                                          <li
                                                              key={result.text}
                                                              className="auto-complete-item"
                                                              onClick={() => {
                                                                  getAddressFromLookup(
                                                                      result
                                                                  );
                                                              }}
                                                          >
                                                              {result.text}
                                                          </li>
                                                      ))}
                                                  </ul>
                                              ) : null}
                                          </div>
                                          <div className="col-md-4 mb-3">
                                              <div className="form-group">
                                                  <div className="js-focus-state">
                                                      <Label
                                                          className="form-label"
                                                          htmlFor="listingAddress"
                                                      >
                                                          {unit_label}
                                                      </Label>

                                                      <div
                                                          className="input-group"
                                                          style={{
                                                              display: 'flex',
                                                              flexWrap: 'nowrap'
                                                          }}
                                                      >
                                                          <div
                                                              className="input-group-prepend"
                                                              style={{
                                                                  maxHeight: 50
                                                              }}
                                                          >
                                                              <span
                                                                  className="input-group-text"
                                                                  id="listingAddressLabel"
                                                                  style={{
                                                                      borderColor:
                                                                          !!checkError(
                                                                              errors,
                                                                              index,
                                                                              'address2'
                                                                          ) &&
                                                                          checkTouched(
                                                                              touched,
                                                                              index,
                                                                              'address2'
                                                                          )
                                                                              ? '#de4437'
                                                                              : values
                                                                                    .parties[
                                                                                    index
                                                                                ].address2
                                                                              ? 'rgba(0, 201, 167, 0.5)'
                                                                              : ''
                                                                  }}
                                                              >
                                                                  <span className="fas fa-map-marker-alt" />
                                                              </span>
                                                          </div>

                                                          <TextField
                                                              type="text"
                                                              myStyle={{
                                                                  borderTopLeftRadius: 0,
                                                                  borderBottomLeftRadius: 0,
                                                                  flexGrow: 1
                                                              }}
                                                              className="form-control"
                                                              name={`parties.${index}.address2`}
                                                              id={`parties.${index}address2`}
                                                              placeholder={
                                                                  unit_placeholder
                                                              }
                                                              aria-label={
                                                                  unit_placeholder
                                                              }
                                                              error={checkError(
                                                                  errors,
                                                                  index,
                                                                  'address2'
                                                              )}
                                                              value={
                                                                  values.parties[index]
                                                                      .address2
                                                              }
                                                              onBlur={e => {
                                                                  handleBlur(
                                                                      `parties.${index}.address2`
                                                                  );
                                                                  setFieldTouched(
                                                                      `parties.${index}.address2`,
                                                                      true
                                                                  );
                                                                  setFieldValue(
                                                                      `parties.${index}.address2`,
                                                                      e.target.value.trim()
                                                                  );
                                                              }}
                                                              onChange={e => {
                                                                  handleChange(e);
                                                              }}
                                                              invalid={
                                                                  !!checkError(
                                                                      errors,
                                                                      index,
                                                                      'address2'
                                                                  ) &&
                                                                  checkTouched(
                                                                      touched,
                                                                      index,
                                                                      'address2'
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="col-md-6 mb-3">
                                              <div className="form-group">
                                                  <div className="js-focus-state">
                                                      <Label className="form-label">
                                                          {city_label}
                                                          {index === 0 && (
                                                              <span className="text-danger ml-1">
                                                                  *
                                                              </span>
                                                          )}
                                                      </Label>

                                                      <div
                                                          className="input-group"
                                                          style={{
                                                              display: 'flex',
                                                              flexWrap: 'nowrap'
                                                          }}
                                                      >
                                                          <div
                                                              className="input-group-prepend"
                                                              style={{
                                                                  maxHeight: 50
                                                              }}
                                                          >
                                                              <span
                                                                  className="input-group-text"
                                                                  id="listingAddressLabel"
                                                                  style={{
                                                                      borderColor:
                                                                          !!checkError(
                                                                              errors,
                                                                              index,
                                                                              `city`
                                                                          ) &&
                                                                          checkTouched(
                                                                              touched,
                                                                              index,
                                                                              `city`
                                                                          )
                                                                              ? '#de4437'
                                                                              : values
                                                                                    .parties[
                                                                                    index
                                                                                ].city
                                                                              ? 'rgba(0, 201, 167, 0.5)'
                                                                              : ''
                                                                  }}
                                                              >
                                                                  <span className="fas fa-city" />
                                                              </span>
                                                          </div>

                                                          <TextField
                                                              type="text"
                                                              myStyle={{
                                                                  borderTopLeftRadius: 0,
                                                                  borderBottomLeftRadius: 0,
                                                                  flexGrow: 1
                                                              }}
                                                              className="form-control"
                                                              name={`parties.${index}.city`}
                                                              id={`parties.${index}.city`}
                                                              placeholder={
                                                                  city_placeholder
                                                              }
                                                              aria-label={
                                                                  city_placeholder
                                                              }
                                                              error={checkError(
                                                                  errors,
                                                                  index,
                                                                  'city'
                                                              )}
                                                              value={
                                                                  values.parties[index]
                                                                      .city
                                                              }
                                                              onBlur={e => {
                                                                  handleBlur(
                                                                      `parties.${index}.city`
                                                                  );
                                                                  setFieldTouched(
                                                                      `parties.${index}.city`,
                                                                      true
                                                                  );
                                                                  setFieldValue(
                                                                      `parties.${index}.city`,
                                                                      e.target.value.trim()
                                                                  );
                                                              }}
                                                              onChange={e => {
                                                                  handleChange(e);
                                                              }}
                                                              invalid={
                                                                  !!checkError(
                                                                      errors,
                                                                      index,
                                                                      'city'
                                                                  ) &&
                                                                  checkTouched(
                                                                      touched,
                                                                      index,
                                                                      'city'
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mb-3">
                                              <div className="form-group">
                                                  <div className="js-focus-state">
                                                      <Label className="form-label">
                                                          {state_label}
                                                          {index === 0 && (
                                                              <span className="text-danger ml-1">
                                                                  *
                                                              </span>
                                                          )}
                                                      </Label>
                                                      <StateCountrySelect
                                                          className="form-control custom-select"
                                                          name={`parties.${index}.state`}
                                                          id={`parties.${index}.state`}
                                                          placeholder=""
                                                          aria-label="State/Province"
                                                          nestedOptions={true}
                                                          options={stateProvince}
                                                          error={checkError(
                                                              errors,
                                                              index,
                                                              'state'
                                                          )}
                                                          value={
                                                              values.parties[index].state
                                                          }
                                                          onBlur={() => {
                                                              handleBlur(
                                                                  `parties.${index}.state`
                                                              );
                                                              setFieldTouched(
                                                                  `parties.${index}.state`,
                                                                  true
                                                              );
                                                          }}
                                                          onChange={e => {
                                                              handleChange(e);
                                                          }}
                                                          touched={checkTouched(
                                                              touched,
                                                              index,
                                                              'state'
                                                          )}
                                                          invalid={
                                                              !!checkError(
                                                                  errors,
                                                                  index,
                                                                  'state'
                                                              ) &&
                                                              checkTouched(
                                                                  touched,
                                                                  index,
                                                                  'state'
                                                              )
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="col-md-3 mb-3">
                                              <div className="form-group">
                                                  <div className="js-focus-state">
                                                      <Label className="form-label">
                                                          {zip_label}
                                                          {index === 0 && (
                                                              <span className="text-danger ml-1">
                                                                  *
                                                              </span>
                                                          )}
                                                      </Label>

                                                      <div
                                                          className="input-group"
                                                          style={{
                                                              display: 'flex',
                                                              flexWrap: 'nowrap'
                                                          }}
                                                      >
                                                          <div
                                                              className="input-group-prepend"
                                                              style={{
                                                                  maxHeight: 50
                                                              }}
                                                          >
                                                              <span
                                                                  className="input-group-text"
                                                                  id="listingAddressLabel"
                                                                  style={{
                                                                      borderColor:
                                                                          !!checkError(
                                                                              errors,
                                                                              index,
                                                                              `zip`
                                                                          ) &&
                                                                          checkTouched(
                                                                              touched,
                                                                              index,
                                                                              `zip`
                                                                          )
                                                                              ? '#de4437'
                                                                              : values
                                                                                    .parties[
                                                                                    index
                                                                                ].zip
                                                                              ? 'rgba(0, 201, 167, 0.5)'
                                                                              : ''
                                                                  }}
                                                              >
                                                                  <span className="fas fa-city" />
                                                              </span>
                                                          </div>

                                                          <TextField
                                                              type="text"
                                                              myStyle={{
                                                                  borderTopLeftRadius: 0,
                                                                  borderBottomLeftRadius: 0,
                                                                  flexGrow: 1
                                                              }}
                                                              className="form-control"
                                                              name={`parties.${index}.zip`}
                                                              id={`parties.${index}.zip`}
                                                              placeholder={
                                                                  zip_placeholder
                                                              }
                                                              aria-label={zip_placeholder}
                                                              error={checkError(
                                                                  errors,
                                                                  index,
                                                                  'zip'
                                                              )}
                                                              value={
                                                                  values.parties[index]
                                                                      .zip
                                                              }
                                                              onBlur={e => {
                                                                  handleBlur(
                                                                      `parties.${index}.zip`
                                                                  );
                                                                  setFieldTouched(
                                                                      `parties.${index}.zip`,
                                                                      true
                                                                  );
                                                                  setFieldValue(
                                                                      `parties.${index}.zip`,
                                                                      e.target.value.trim()
                                                                  );
                                                              }}
                                                              onChange={e => {
                                                                  handleChange(e);
                                                              }}
                                                              invalid={
                                                                  !!checkError(
                                                                      errors,
                                                                      index,
                                                                      'zip'
                                                                  ) &&
                                                                  checkTouched(
                                                                      touched,
                                                                      index,
                                                                      'zip'
                                                                  )
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              )}
                              <div className="row">
                                  <div className="col-sm-6 mb-6">
                                      <div
                                          className="custom-control custom-checkbox"
                                          onClick={() =>
                                              setFieldValue(
                                                  `parties.${index}.sameAddress`,
                                                  !values.parties[index].sameAddress
                                              )
                                          }
                                      >
                                          <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              name={`parties.${index}.sameAddress`}
                                              id={`parties.${index}.sameAddress`}
                                              readOnly
                                              checked={values.parties[index].sameAddress}
                                          />
                                          <label
                                              className="custom-control-label"
                                              htmlFor="stylishCheckbox2"
                                          >
                                              {mailing_address_same_label}
                                          </label>
                                      </div>
                                  </div>
                                  {index !== 0 && (
                                      <div
                                          className="col-sm-6 mb-3 mt-4 text-right"
                                          onClick={() => handleRemove(index)}
                                      >
                                          <span className="addParty small text-danger pointer">
                                              <ins>{remove_party_label}</ins>
                                          </span>
                                      </div>
                                  )}
                              </div>
                              <hr className="mb-6" />
                          </div>
                      ))
                    : null}
                <div className="row">
                    <div className="col-sm-6">
                        <Button
                            style={{
                                padding: 0
                            }}
                            color="link"
                            className="addParty d-inline-block u-text-muted"
                            type="button"
                            disabled={idCount === 4}
                            onClick={() => {
                                if (idCount === 4) return;
                                arrayHelpers.push(addedParty);
                                setIdCount(idCount + 1);
                            }}
                        >
                            <span className="mr-2">+</span>
                            {add_party_label}
                        </Button>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary transition-3d-hover mr-1"
                            data-next-step="#selectStepTwo"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                        >
                            {next_button_label}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ modal }) => {
    const { modalDialogType } = modal;
    return { modalDialogType };
};

export default connect(mapStateToProps, { setConfirmModalType })(ListingFlowStep1);
