import {
    VALIDATE_ORG_INVITATION,
    VALIDATE_ORG_INVITATION_ERROR,
    SET_INVITATION_VALIDITY,
    SET_USER_COLLECTION,
    CONFIRM_EXISTING_USER_INVITATION,
    CONFIRM_EXISTING_USER_INVITATION_SUCCESS,
    CONFIRM_EXISTING_USER_INVITATION_FAILURE,
    CONFIRM_NEW_USER_INVITATION,
    CONFIRM_NEW_USER_INVITATION_SUCCESS,
    CONFIRM_NEW_USER_INVITATION_FAILURE,
    NEW_USER_START_SUCCESS,
    NEW_USER_START_FAILURE,
    DECLINE_NEW_USER_INVITATION,
    DECLINE_NEW_USER_INVITATION_SUCCESS,
    DECLINE_NEW_USER_INVITATION_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    userInvitation: null,
    isValid: '',
    emailMatch: '',
    error: null,
    loading: false,
    newAuthUser: null,
    userReady: false,
    declined: null
};

const invitationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_ORG_INVITATION:
            return {
                ...state,
                userInvitation: INITIAL_STATE.invitation,
                isValid: INITIAL_STATE.isValid,
                emailMatch: INITIAL_STATE.emailMatch,
                error: INITIAL_STATE.error
            };
        case VALIDATE_ORG_INVITATION_ERROR:
            return {
                ...state,
                isValid: false,
                error: action.payload
            };
        case SET_INVITATION_VALIDITY:
            return {
                ...state,
                userInvitation: action.payload?.invitation,
                isValid: action.payload?.valid,
                emailMatch: action.payload?.emailMatch
            };
        case SET_USER_COLLECTION:
            return {
                ...state,
                emailMatch: state.userInvitation?.email
                    ? action.payload?.email === state.userInvitation?.email
                        ? true
                        : false
                    : INITIAL_STATE.emailMatch
            };
        case CONFIRM_EXISTING_USER_INVITATION:
            return {
                ...state,
                loading: true
            };
        case CONFIRM_EXISTING_USER_INVITATION_SUCCESS:
            return {
                ...state,
                userReady: true,
                loading: INITIAL_STATE.loading
            };
        case CONFIRM_EXISTING_USER_INVITATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: INITIAL_STATE.loading
            };
        case CONFIRM_NEW_USER_INVITATION:
            return {
                ...state,
                loading: true
            };
        case CONFIRM_NEW_USER_INVITATION_SUCCESS:
            return {
                ...state,
                newAuthUser: action.payload,
                loading: INITIAL_STATE.loading
            };
        case CONFIRM_NEW_USER_INVITATION_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: INITIAL_STATE.loading
            };
        case NEW_USER_START_SUCCESS:
            return {
                ...state,
                userReady: true
            };
        case NEW_USER_START_FAILURE:
            return {
                ...state,
                error: action.payload,
                userReady: false
            };
        case DECLINE_NEW_USER_INVITATION:
            return {
                ...state,
                loading: true
            };
        case DECLINE_NEW_USER_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                declined: true
            };
        case DECLINE_NEW_USER_INVITATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default invitationsReducer;
