import React from 'react';

export const InviteMembersHeader = ({ name, status, type }) => {
    return (
        <div className="row d-flex mb-2 w-100">
            <div className={type ? 'col-4' : 'col-6'}>
                <h3 className="h5 font-weight-medium">{name}</h3>
            </div>
            <div className="col-3">
                <h3 className="h5 font-weight-medium">{status}</h3>
            </div>
            {type && (
                <div className="col-3 pl-2">
                    <h3 className="h5 font-weight-medium">{type}</h3>
                </div>
            )}
        </div>
    );
};
