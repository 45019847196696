// React
import React, { useEffect, useState } from 'react';

// Components
import {
    OrgMembers,
    NewMemberForm,
    AddMemberProgress,
    MemberMatches,
    MemberInvitations,
    MemberDetails
} from '../../../components';

// Context
import { useLang } from '../../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import {
    addingOrganizationMember,
    executeInvitatonSend
} from '../../../store/actions/Org';

// Utils
import { userTypes } from '../../../utils/Constants';

const orgScreen = {
    teamList: 'team_list',
    addMemberForm: 'add_member_form',
    memberCheckProgress: 'member_check_progress',
    memberMatches: 'member_matches',
    memberInvitations: 'member_invitations',
    memberDetails: 'member_details'
};

const OrganizationMembers = ({
    userData,
    type,
    companyContacts,
    addingOrganizationMember,
    loading,
    matches,
    executeInvitatonSend,
    orgInvitations,
    activeOrg
}) => {
    const {
        main_title,
        header,
        info_text,
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        email_label,
        email_placeholder,
        account_type_label,
        account_type_placeholder,
        account_permissions_label,
        account_permissions_placeholder,
        invite_button_label,
        send_invite_button_label,
        cancel_invite_button_label,
        my_invites_title,
        back_button_label,
        invitationStatuses,
        none_found_label
    } = useLang()['Settings']['OrganizationMembers'];
    const { teamList } = useLang()['Settings']['Team'];
    const { errorMessage, accountTypes, permissionTypes } = useLang()['Constants'];

    const newMemberFormLang = {
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        email_label,
        email_placeholder,
        account_type_label,
        account_type_placeholder,
        account_permissions_label,
        account_permissions_placeholder,
        info_text,
        invite_button_label,
        cancel_invite_button_label
    };

    const memberMatchesLang = {
        send_invite_button_label,
        cancel_invite_button_label,
        accountTypes
    };

    const location = useLocation();
    const { newMember } = location?.state || false;
    const [isFormOpen, setFormOpen] = useState(newMember || false);
    const [memberDetails, setMemberDetails] = useState(null);
    const [focusedState, setFocusedState] = useState(
        newMember ? orgScreen.addMemberForm : orgScreen.teamList
    );
    const [member, setMember] = useState(null);

    const brokered_by = () => {
        if (userData?.orgs) {
            const org = userData.orgs.filter(org => org.id === userData.active_org_id)[0];
            return org?.brokered_by
                ? org.brokered_by
                : type === userTypes.broker
                ? userData.id
                : activeOrg.principle_broker.id;
        }
        return null;
    };

    useEffect(() => {
        if (focusedState === orgScreen.teamList) {
            window.history.replaceState({}, document.title);
            setFormOpen(false);
        }
    }, [focusedState, setFormOpen]);

    const renderComponentByFocusState = focus => {
        switch (focus) {
            case orgScreen.teamList:
                return (
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '0'
                        }}
                    >
                        <OrgMembers
                            setFocusedState={setFocusedState}
                            team={companyContacts}
                            lang={teamList}
                            orgScreen={orgScreen}
                            orgInvitations={orgInvitations}
                            setMemberDetails={setMemberDetails}
                        />
                    </div>
                );
            case orgScreen.addMemberForm:
                return (
                    <NewMemberForm
                        handleClose={setFocusedState}
                        addingOrganizationMember={addingOrganizationMember}
                        focusedState={focusedState}
                        setFocusedState={setFocusedState}
                        setMember={setMember}
                        setFormOpen={setFormOpen}
                        lang={newMemberFormLang}
                        errorMessage={errorMessage}
                        accountTypes={accountTypes}
                        permissionTypes={permissionTypes}
                        brokered_by={brokered_by()}
                        members={companyContacts.map(contact => contact.email)}
                    />
                );
            case orgScreen.memberCheckProgress:
                return (
                    <AddMemberProgress
                        loading={loading}
                        setFocusedState={setFocusedState}
                        executeInvitatonSend={executeInvitatonSend}
                        matches={matches}
                    />
                );
            case orgScreen.memberMatches:
                return (
                    <MemberMatches
                        setFocusedState={setFocusedState}
                        matches={matches}
                        member={member}
                        executeInvitatonSend={executeInvitatonSend}
                        lang={memberMatchesLang}
                    />
                );
            case orgScreen.memberInvitations:
                return (
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '0'
                        }}
                    >
                        <MemberInvitations
                            setFocusedState={setFocusedState}
                            orgInvitations={orgInvitations}
                            orgScreen={orgScreen}
                            focusedState={focusedState}
                            team={companyContacts}
                            lang={{
                                ...teamList,
                                back_button_label,
                                invitationStatuses,
                                none_found_label
                            }}
                        />
                    </div>
                );
            case orgScreen.memberDetails:
                return (
                    <div
                        className="card-body"
                        style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '0',
                            paddingBottom: '0'
                        }}
                    >
                        <MemberDetails
                            setFocusedState={setFocusedState}
                            orgScreen={orgScreen}
                            team={companyContacts}
                            lang={teamList}
                            member={memberDetails}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <div className="card-header d-flex align-items-center">
                <h1 className="col-6 h5 font-weight-medium mb-0">
                    {focusedState === orgScreen.memberInvitations
                        ? my_invites_title
                        : focusedState === memberDetails
                        ? `${memberDetails.first_name} ${memberDetails.last_name}`
                        : main_title}
                </h1>
                <div className="col-6 d-flex align-items-center justify-content-end card-header__actions">
                    {focusedState === orgScreen.teamList && (
                        <>
                            <button
                                className="btn btn-outline-secondary d-flex align-items-center mr-2"
                                style={{ maxHeight: '36px' }}
                                onClick={() => setFocusedState(orgScreen.addMemberForm)}
                                disabled={isFormOpen}
                            >
                                {header.add_member_label}
                            </button>
                        </>
                    )}
                </div>
            </div>
            {renderComponentByFocusState(focusedState)}
        </>
    );
};

const mapStateToProps = ({ user, contacts, org }) => {
    const { userData, type } = user;
    const { companyContacts } = contacts;
    const { loading, matches, orgInvitations, activeOrg } = org;
    return {
        userData,
        type,
        companyContacts,
        loading,
        matches,
        orgInvitations,
        activeOrg
    };
};

export default connect(mapStateToProps, {
    addingOrganizationMember,
    executeInvitatonSend
})(OrganizationMembers);
