// React
import React, { useState, useCallback, useEffect } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';

// Utils
import { formatActivity } from '../../utils/Helpers';
import ActivityItem from './ActivityItem';

const ActivityThread = ({
    activity,
    avatars,
    lang,
    isTransactionArchived,
    transaction,
    lastViewTime,
    currentUserId
}) => {
    const { windowHeight } = useWindowSize();
    const [scrollHeight, setScrollHeight] = useState(0);
    const [avatarImgs, setAvatarImgs] = useState(null);
    const activityEvents = activity ? formatActivity(activity, lang) : null;
    const { no_messages_label } = useLang()['TransactionDetails']['ActivityThread'];

    const isDocumentExist = trxDocId =>
        Object.values(transaction?.documents || {}).some(
            doc => doc.trx_doc_id === trxDocId
        );

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect().top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        // eslint-disable-next-line
        [windowHeight, isTransactionArchived]
    );

    useEffect(() => {
        if (activity && avatars) {
            setAvatarImgs(avatars);
        }
    }, [avatars, activity]);

    const getLinkStyles = activityItem => {
        if (
            activityItem.type === 'document' &&
            Object.values(transaction?.documents || {}).some(
                doc => doc.trx_doc_id === activityItem.trxDocId
            )
        )
            return {
                color: '#377dff',
                cursor: 'pointer'
            };
    };

    if (!activityEvents) return <span className="text-muted">{no_messages_label}</span>;

    return (
        <div ref={measuredRef} style={{ height: '100%' }}>
            <SimpleBar style={{ height: scrollHeight }}>
                {activityEvents.length &&
                    activityEvents.map(activity => (
                        <div key={activity.day}>
                            <div className="message-date">
                                <small>{activity.day}</small>
                            </div>
                            <>
                                {activity.events.length &&
                                    activity.events.map(event => (
                                        <ActivityItem
                                            event={event}
                                            avatarImgs={avatarImgs}
                                            linkStyles={
                                                event.automated &&
                                                getLinkStyles(event.automated)
                                            }
                                            isDocumentExist={isDocumentExist(
                                                event.automated?.trxDocId
                                            )}
                                            trxId={transaction?.id}
                                            key={`${event.created_at.seconds}_${
                                                event.message
                                            }_${event.automated?.name || 'null'}`}
                                            lastViewTime={lastViewTime}
                                            currentUserId={currentUserId}
                                        />
                                    ))}
                            </>
                        </div>
                    ))}
            </SimpleBar>
        </div>
    );
};

export default ActivityThread;
