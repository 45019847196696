// React
import React, { useEffect, useCallback } from 'react';

// Components
import AppRouter from './router/Router';
import { Loader } from './components';

// Hooks
import { useMountEffect } from './hooks/useMountEffect';

// Packages
import { connect } from 'react-redux';
import 'dotenv/config.js';
import * as Sentry from '@sentry/react';

// Redux - Actions, Reducers, Sagas
import { checkUserAuth } from './store/actions/Auth';

// import { addInternalUser } from './store/actions/SuperAdmin';

const App = ({
    history,
    match,
    authUser,
    checkUserAuth,
    appInitialized,
    userData,
    type
}) => {
    useMountEffect(checkUserAuth);

    const getUserType = useCallback(() => {
        const org = userData.orgs.filter(org => org.id === userData.active_org_id);
        const type = org[0].sub_type;
        return type;
    }, [userData]);

    useEffect(() => {
        if (!authUser) {
            Sentry.configureScope(scope => scope.setUser(null));
        } else {
            if (userData) {
                Sentry.configureScope(scope => {
                    scope.setUser({
                        email: userData.email,
                        id: userData.id,
                        role: userData.role,
                        org_id: userData.active_org_id,
                        type: userData.type !== 'client' ? getUserType() : userData.type
                    });
                });
            }
        }
    }, [authUser, userData, getUserType]);

    if (!appInitialized) return <Loader />;
    return (
        <AppRouter history={history} match={match} authUser={authUser} userType={type} />
    );
};

const mapStateToProps = ({ auth, init, user }) => {
    const { authUser } = auth;
    const { appInitialized } = init;
    const { userData, type } = user;
    return { authUser, appInitialized, userData, type };
};

export default connect(mapStateToProps, { checkUserAuth })(App);
