// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';
import Select from 'react-select';

const DropdownIndicator = () => {
    return <span className="select-icon" />;
};

const IndicatorSeparator = () => {
    return null;
};

const SelectField = ({
    disabled,
    required,
    type,
    className,
    name,
    id,
    placeholder,
    onChange,
    options,
    classNamePrefix,
    formik,
    error,
    touched,
    value,
    onBlur,
    isClearable
}) => {
    const styles = {
        control: styles => {
            return {
                ...styles,
                backgroundColor: disabled ? '#f8fafd' : 'white',
                color: disabled ? '#aaa' : 'white'
            };
        },
        placeholder: styles => {
            return {
                ...styles,
                color: '#8c98a4'
            };
        },
        menuPortal: styles => {
            return { ...styles, zIndex: 100 };
        }
    };

    return (
        <>
            {!formik ? (
                <Select
                    autoComplete="off"
                    styles={styles}
                    required={required}
                    type={type}
                    className={className}
                    classNamePrefix={classNamePrefix}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    onChange={onChange}
                    options={options}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator
                    }}
                    menuPortalTarget={document.body}
                />
            ) : (
                <>
                    <Field
                        autoComplete="off"
                        style={{
                            borderColor:
                                error && touched
                                    ? '#de4437'
                                    : value
                                    ? 'rgba(0, 201, 167, 0.5)'
                                    : ''
                        }}
                        required={required}
                        isDisabled={disabled}
                        type={type}
                        className={className}
                        classNamePrefix={classNamePrefix}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        error={error}
                        value={value}
                        onBlur={onBlur}
                        touched={touched}
                        onChange={onChange}
                        options={options}
                        isClearable={isClearable}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator
                        }}
                        as={Select}
                        menuPortalTarget={document.body}
                    />

                    <FormFeedback
                        style={{ display: error && touched ? 'block' : 'none' }}
                    >
                        {error}
                    </FormFeedback>
                </>
            )}
        </>
    );
};

export default SelectField;
