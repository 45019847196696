import React from 'react';

const ActionButtons = ({
    helperText,
    handleClose,
    handleSubmit,
    saveLabel,
    cancelLabel,
    leaveLabel,
    handleLeave,
    deleteLabel,
    handleDelete,
    noDelete,
    disabled,
    noCancel,
    linkLabel,
    handleLink
}) => (
    <div className="card-footer d-flex justify-content-between">
        {linkLabel && (
            <div
                className="text-primary m-2"
                style={{ cursor: 'pointer' }}
                onClick={handleLink}
            >
                {linkLabel}
            </div>
        )}
        {helperText && (
            <p className="small mb-0 d-flex align-items-center ">
                <i className="fa-solid fa-message-lines m-1" />
                {helperText}
            </p>
        )}
        <div>
            {deleteLabel && (
                <button
                    className="btn btn-link text-danger mr-4"
                    onClick={handleDelete}
                    type="button"
                    hidden={noDelete}
                >
                    {deleteLabel}
                </button>
            )}
            {noDelete && leaveLabel && (
                <button
                    className="btn btn-link border-dark text-dark mr-4"
                    onClick={handleLeave}
                    type="button"
                    hidden={!noDelete}
                >
                    {leaveLabel}
                </button>
            )}
        </div>
        <div className="d-flex justify-content-end">
            <div className="actionsContainer">
                {!noCancel && (
                    <button
                        className="btn btn-link text-danger mr-4"
                        onClick={handleClose}
                        type="button"
                    >
                        {cancelLabel}
                    </button>
                )}
                <button
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={disabled}
                >
                    {saveLabel}
                </button>
            </div>
        </div>
    </div>
);

export default ActionButtons;
