import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import {
    GET_USER_TRANSACTIONS,
    CREATE_TRANSACTION,
    LOGOUT_USER,
    UPDATE_TRANSACTION_NOTE,
    UPDATE_TRANSACTION_TASK,
    UPDATE_ACTIVITY_POST,
    GET_TRANSACTION_DETAILS,
    SET_CLIENT_TRANSACTION_DETAILS,
    CANCEL_DETAILS_LISTENER,
    UPDATE_TRANSACTION_STATUS,
    UPDATE_TRANSACTION_DETAILS,
    UPDATE_TRANSACTION_MEMBERS,
    ADD_PARTY_TO_TRANSACTION,
    GET_MEMBER_TRANSACTIONS,
    REMOVE_PARTY_FROM_TRANSACTION,
    INVITE_PARTY_TO_TRANSACTION,
    UPDATE_TIME_VISITING_TRANSACTION,
    UPDATE_OPPOSING_MEMBERS,
    REMOVE_OPPOSING_MEMBER
} from '../actions/types';

import { eventChannel } from 'redux-saga';
import { confirmSaga } from './Modal';
import { setConfirmModalType } from '../actions/Modal';

import {
    storeUserTransactions,
    storeClosedTransactions,
    storeArchivedTransactions,
    transactionWriteSuccess,
    noteWriteSuccess,
    taskWriteSuccess,
    taskWriteFailure,
    postWriteSuccess,
    settingTransactionDetails,
    storeMemberTransactions,
    storeMemberArchivedTransactions,
    storeMemberClosedTransactions,
    updatingOpposingMembersSuccess,
    updatingOpposingMembersFailure,
    removingOpposingMemberSuccess,
    removingOpposingMemberFailure
} from '../actions/Transactions';
import { addExistingUserConnections, addNewAuthUserConnections } from './Connections';
import { getTransactionDetails } from './Documents';

import {
    db,
    rtdb,
    timeStampNow,
    timeStampJs,
    storage,
    fsFieldValue,
    func
} from '../../config/Firebase';

// import { refreshingTransactionDocuments } from './Documents';

import * as selectors from './Selectors';

// Loggers
import { log } from '../../utils/Loggers';

// Constants
import {
    activityMessage,
    confirmationDialogTypes,
    mappingKeys,
    trxStatus,
    TRANSACTION_MAPPING_SCHEMA
} from '../../utils/Constants';

import { generateUid, getFullName } from '../../utils/Helpers';

const transactions = db.collection('transactions');
const users = db.collection('users');
const executeInviteClientToTransaction = func.httpsCallable('inviteClientToTransaction');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Transactions ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* clientTransactionCollectionWatch(user) {
    let unsubcscribeClientTransactionData;

    const clientTransactionsCollectionChannel = eventChannel(emit => {
        unsubcscribeClientTransactionData = transactions
            .where('access', 'array-contains', user.id)
            .where('status', '!=', trxStatus.archived)
            .onSnapshot(querySnapshot => {
                var clientTransactions = [];
                querySnapshot.forEach(function (doc) {
                    if (doc.data()) {
                        clientTransactions.push(doc.data());
                    }
                });
                emit(clientTransactions);
            });
        return unsubcscribeClientTransactionData;
    });
    try {
        while (true) {
            const { userSignOut, clientTransactions } = yield race({
                userSignOut: take(LOGOUT_USER),
                clientTransactions: take(clientTransactionsCollectionChannel)
            });
            if (userSignOut) {
                clientTransactionsCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(
                    storeClosedTransactions(
                        clientTransactions.filter(trx => trx.status === trxStatus.closed)
                    )
                );
                yield put(
                    storeUserTransactions(
                        clientTransactions.filter(trx => trx.status !== trxStatus.closed)
                    )
                );
            }
        }
    } catch (error) {
        log('User Error: getting client collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubcscribeClientTransactionData(); // Detach firebase listener
        if (yield cancelled()) {
            clientTransactionsCollectionChannel.close(); // Detach saga event emitter
            unsubcscribeClientTransactionData(); // Detach firebase listener
        }
    }
}

export function* transactionCollectionWatch(user) {
    const activeRef = rtdb.ref(`transactions/${user.active_org_id}/active`);
    const closedRef = rtdb.ref(`transactions/${user.active_org_id}/closed`);
    const archivedRef = rtdb.ref(`transactions/${user.active_org_id}/archived`);

    const AddActiveTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = activeRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const ChangeActiveTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = activeRef.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id] === false) {
                        // Existing user on Transaction has been administratively removed
                        // resulting in their id key in transaction data to be set to false.
                        // We must locate the specific and previously watched/stored transaction
                        // from their current active list and remove it
                        // This will be TO-DO once we have that ability
                    }
                    if (childSnapshot.val()[user.id]) {
                        // console.log('changed child', childSnapshot.val());
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const RemoveActiveTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = activeRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const AddClosedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = closedRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const ChangeClosedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = closedRef.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const RemoveClosedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = closedRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const AddArchivedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = archivedRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const RemoveArchivedTransactionChannel = eventChannel(emit => {
        const unsubscribeUserTransactionData = archivedRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot) {
                    if (childSnapshot.val()[user.id]) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserTransactionData;
    });

    const detachSagaEmitters = () => {
        AddActiveTransactionChannel.close();
        ChangeActiveTransactionChannel.close();
        RemoveActiveTransactionChannel.close();
        AddClosedTransactionChannel.close();
        ChangeClosedTransactionChannel.close();
        RemoveClosedTransactionChannel.close();
        AddArchivedTransactionChannel.close();
        RemoveArchivedTransactionChannel.close();
    };

    const detachFBListeners = () => {
        activeRef.off('child_added');
        activeRef.off('child_changed');
        activeRef.off('child_removed');
        closedRef.off('child_added');
        closedRef.off('child_changed');
        closedRef.off('child_removed');
        archivedRef.off('child_added');
        archivedRef.off('child_removed');
    };

    try {
        while (true) {
            const currentActive = yield select(selectors._activeTransactions);
            const currentClosed = yield select(selectors._closedTransactions);
            const currentArchived = yield select(selectors._archivedTransactions);
            const transactions = currentActive ? [...currentActive] : [];
            const closedTransactions = currentClosed ? [...currentClosed] : [];
            const archivedTransactions = currentArchived ? [...currentArchived] : [];

            const getTrxIndex = (trx, type) => {
                const data = trx;
                let index;
                if (type === 'active') {
                    index = transactions.findIndex(trx => trx.id === data.id);
                } else if (type === 'closed') {
                    index = closedTransactions.findIndex(trx => trx.id === data.id);
                } else {
                    index = archivedTransactions.findIndex(trx => trx.id === data.id);
                }
                return index;
            };

            let index;
            const {
                userSignOut,
                addActiveTransactionData,
                changeActiveTransactionData,
                removeActiveTransactionData,
                addClosedTransactionData,
                changeClosedTransactionData,
                removeClosedTransactionData,
                addArchivedTransactionData,
                removeArchivedTransactionData
            } = yield race({
                userSignOut: take(LOGOUT_USER),
                addActiveTransactionData: take(AddActiveTransactionChannel),
                changeActiveTransactionData: take(ChangeActiveTransactionChannel),
                removeActiveTransactionData: take(RemoveActiveTransactionChannel),
                addClosedTransactionData: take(AddClosedTransactionChannel),
                changeClosedTransactionData: take(ChangeClosedTransactionChannel),
                removeClosedTransactionData: take(RemoveClosedTransactionChannel),
                addArchivedTransactionData: take(AddArchivedTransactionChannel),
                removeArchivedTransactionData: take(RemoveArchivedTransactionChannel)
            });

            if (userSignOut) {
                detachSagaEmitters(); // Detaching saga event emitters
            } else if (addActiveTransactionData) {
                index = getTrxIndex(addActiveTransactionData, 'active');
                if (index < 0) transactions.push(addActiveTransactionData);
                yield put(storeUserTransactions(transactions));
            } else if (changeActiveTransactionData) {
                index = getTrxIndex(changeActiveTransactionData, 'active');
                if (index < 0) {
                    transactions.push(changeActiveTransactionData);
                } else {
                    transactions[index] = changeActiveTransactionData;
                }
                yield put(storeUserTransactions(transactions));
            } else if (removeActiveTransactionData) {
                index = getTrxIndex(removeActiveTransactionData, 'active');
                if (index >= 0) transactions.splice(index, 1);
                yield put(storeUserTransactions(transactions));
            } else if (addClosedTransactionData) {
                index = getTrxIndex(addClosedTransactionData, 'closed');
                if (index < 0) closedTransactions.push(addClosedTransactionData);
                yield put(storeClosedTransactions(closedTransactions));
            } else if (changeClosedTransactionData) {
                index = getTrxIndex(changeClosedTransactionData, 'closed');
                if (index < 0) {
                    closedTransactions.push(changeClosedTransactionData);
                } else {
                    closedTransactions[index] = changeClosedTransactionData;
                }
                yield put(storeClosedTransactions(closedTransactions));
            } else if (removeClosedTransactionData) {
                index = getTrxIndex(removeClosedTransactionData, 'closed');
                if (index >= 0) closedTransactions.splice(index, 1);
                yield put(storeClosedTransactions(closedTransactions));
            } else if (addArchivedTransactionData) {
                index = getTrxIndex(addArchivedTransactionData, 'archived');
                if (index < 0) archivedTransactions.push(addArchivedTransactionData);
                yield put(storeArchivedTransactions(archivedTransactions));
            } else if (removeArchivedTransactionData) {
                index = getTrxIndex(removeArchivedTransactionData, 'archived');
                if (index >= 0) archivedTransactions.splice(index, 1);
                yield put(storeArchivedTransactions(archivedTransactions));
            }
        }
    } catch (error) {
        //TODO: Error Handling
        log('Transactions Error: getting active/closed/archived transactions (RTDB)', {
            error,
            user
        });
    } finally {
        detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            detachFBListeners(); // Detaching firebase listeners
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Get Transaction Details /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* transactionDetailsWatch({ payload }) {
    const { trxId, detailsFlag } = payload;
    let unsubscribeTransactionDetails;
    const transactionDetailsChannel = eventChannel(emit => {
        unsubscribeTransactionDetails = transactions
            .doc(trxId)
            .onSnapshot(function (doc) {
                if (doc) {
                    emit(doc.data());
                } else {
                    doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeTransactionDetails;
    });

    try {
        while (true) {
            const { cancelledDetails, userSignOut, transactionDetails } = yield race({
                userSignOut: take(LOGOUT_USER),
                cancelledDetails: take(CANCEL_DETAILS_LISTENER),
                transactionDetails: take(transactionDetailsChannel)
            });

            if (cancelledDetails || userSignOut) {
                transactionDetailsChannel.close(); // Detach saga event emitter
            } else {
                yield put(settingTransactionDetails({ transactionDetails, detailsFlag }));
                // yield fork(
                //     refreshingTransactionDocuments,
                //     transactionDetails.documents,
                //     transactionDetails.id
                // );
            }
        }
    } catch (error) {
        log('Transactions Error: getting transaction details collection data (FS)', {
            error,
            trxId,
            detailsFlag
        });
    } finally {
        unsubscribeTransactionDetails(); // Detach firebase listener
        if (yield cancelled()) {
            transactionDetailsChannel.close(); // Detach saga event emitter
            unsubscribeTransactionDetails(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Form New Transactions ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getExistingParties = async ({ parties, user }) => {
    const processedParties = [];
    return new Promise((resolve, reject) => {
        parties.forEach((party, index) => {
            if (party.email.trim() !== '') {
                users
                    .where('email', '==', party.email)
                    .get()
                    .then(function (querySnapshot) {
                        if (querySnapshot.size > 0) {
                            querySnapshot.forEach(function (doc) {
                                const obj = Object.assign(parties[index], {
                                    ...parties[index],
                                    id: doc.id,
                                    pipeline_id: null,
                                    phone: doc.data().phone
                                        ? parties[index].phone !== ''
                                            ? parties[index].phone
                                            : doc
                                                  .data()
                                                  .phone.filter(
                                                      phone => phone.primary === true
                                                  )[0].number
                                        : parties[index].phone,
                                    legal_name: doc.data().legal_name
                                        ? parties[index].fullLegalName !== ''
                                            ? parties[index].fullLegalName
                                            : doc.data().legal_name
                                        : parties[index].fullLegalName
                                });
                                processedParties.push(obj);
                                if (processedParties.length === parties.length) {
                                    resolve({ processedParties });
                                }
                            });
                        } else {
                            if (parties[index]?.pipelineId) {
                                const obj = Object.assign(parties[index], {
                                    ...parties[index],
                                    id: null,
                                    legal_name: parties[index].fullLegalName
                                        ? parties[index].fullLegalName
                                        : `${parties[index].firstName} ${parties[index].lastName}`,
                                    pipeline_id: parties[index].pipelineId
                                });
                                processedParties.push(obj);
                                if (processedParties.length === parties.length) {
                                    resolve({ processedParties });
                                }
                            } else {
                                const userPipeLineRef = rtdb
                                    .ref(`pipeline/${user.id}`)
                                    .push();
                                const tempId = userPipeLineRef.key;
                                const obj = Object.assign(parties[index], {
                                    ...parties[index],
                                    id: null,
                                    legal_name: parties[index].fullLegalName
                                        ? parties[index].fullLegalName
                                        : `${parties[index].firstName} ${parties[index].lastName}`,
                                    pipeline_id: tempId
                                });
                                processedParties.push(obj);
                                if (processedParties.length === parties.length) {
                                    resolve({ processedParties });
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } else {
                if (parties[index]?.pipelineId) {
                    const obj = Object.assign(parties[index], {
                        ...parties[index],
                        id: null,
                        legal_name: parties[index].fullLegalName
                            ? parties[index].fullLegalName
                            : `${parties[index].firstName} ${parties[index].lastName}`,
                        pipeline_id: parties[index].pipelineId
                    });
                    processedParties.push(obj);
                    if (processedParties.length === parties.length) {
                        resolve({ processedParties });
                    }
                } else {
                    const userPipeLineRef = rtdb.ref(`pipeline/${user.id}`).push();
                    const tempId = userPipeLineRef.key;
                    const obj = Object.assign(parties[index], {
                        ...parties[index],
                        id: null,
                        legal_name: parties[index].fullLegalName
                            ? parties[index].fullLegalName
                            : `${parties[index].firstName} ${parties[index].lastName}`,
                        pipeline_id: tempId
                    });
                    processedParties.push(obj);
                    if (processedParties.length === parties.length) {
                        resolve({ processedParties });
                    }
                }
            }
        });
    });
};

const tsFromJsDate = date => {
    return timeStampJs.fromDate(date);
};

const formTaskDocTs = index => {
    const currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + index);
    const ts = tsFromJsDate(currentDate);
    return ts;
};

const formTaskDate = (expire, date) => {
    const taskDate = date ? date : new Date();
    taskDate.setDate(taskDate.getDate() + expire.days);
    taskDate.setHours(expire.hours, expire.mins, 0, 0);
    return taskDate;
};

const buildTaskArray = ({ tasks, startDate }) => {
    return new Promise(resolve => {
        const date = startDate ? startDate : new Date();
        const formattedTasks =
            tasks && tasks.length
                ? tasks.map((task, index) => {
                      var dateCopy = new Date(date.getTime());
                      const taskDate = formTaskDate(task.expiration, dateCopy);
                      const taskTs = tsFromJsDate(taskDate);
                      const formatTask = Object.assign(task, {
                          ...task,
                          created_at: formTaskDocTs(index),
                          date_time: taskTs
                      });
                      return formatTask;
                  })
                : null;
        resolve(formattedTasks);
    });
};

const buildParties = parties => {
    return new Promise(resolve => {
        const formattedParties = [];
        parties.forEach(party => {
            const formatParty = Object.assign(
                {},
                {
                    id: party.id ? party.id : party.pipeline_id,
                    first_name: party.firstName,
                    last_name: party.lastName,
                    legal_name: party.fullLegalName,
                    email: party.email,
                    address_1: party.address,
                    address_2: party.address2,
                    phone: party.phone,
                    city: party.city,
                    state: party.state,
                    zip: party.zip,
                    pipeline: party.pipeline_id ? true : false,
                    invited: false,
                    invite_accepted: false,
                    invited_at: null
                }
            );
            formattedParties.push(formatParty);
            if (formattedParties.length === parties.length) {
                resolve(formattedParties);
            }
        });
    });
};

const buildActivity = ({ tasks, docs, ts, user }) => {
    return new Promise(resolve => {
        const activity = [
            {
                archived_at: null,
                attachments: [],
                automated: null,
                created_at: ts,
                creator_id: null,
                creator_type: 'system',
                edited_at: null,
                first_name: 'Jada',
                last_name: null,
                message:
                    'Congratulations on your new transaction!  Feel free to add documents and create tasks if you have not done so already.  We look forward to seeing everyone at the finish line!'
            }
        ];

        if (tasks && tasks.length) {
            tasks.forEach((task, index) => {
                const formatTask = Object.assign(
                    {},
                    {
                        archived_at: null,
                        attachments: [],
                        automated: {
                            type: 'task',
                            name: task.title
                        },
                        created_at: formTaskDocTs(index + 1),
                        creator_id: user.id,
                        creator_type: 'user',
                        edited_at: null,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        message: activityMessage.addTask
                    }
                );
                activity.push(formatTask);
            });
        }

        if (docs && docs.length) {
            const i = tasks && tasks.length ? tasks.length : 0;
            docs.forEach((doc, index) => {
                const formatDoc = Object.assign(
                    {},
                    {
                        archived_at: null,
                        attachments: [
                            {
                                created_at: doc.created_at,
                                edited_at: doc.edited_at,
                                id: doc.id,
                                status: doc.status,
                                title: doc.title,
                                type: doc.type,
                                url: doc.url
                            }
                        ],
                        automated: {
                            type: 'document',
                            name: doc.title,
                            id: doc.id,
                            trxDocId: doc.trx_doc_id
                        },
                        created_at: formTaskDocTs(index + i),
                        creator_id: user.id,
                        creator_type: 'user',
                        edited_at: null,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        message: activityMessage.addDocument
                    }
                );
                activity.push(formatDoc);
            });
        }
        resolve(activity);
    });
};

const concatParties = parties => {
    let allString = '';

    const partyNameArr = parties.map(party => getFullName(party));

    partyNameArr.forEach((party, index) => {
        allString = allString.concat(party);
        if (index < partyNameArr.length - 1) allString = allString.concat(', ');
    });

    return allString.trim();
};

const formTransactionMappings = async ({
    propertyData,
    formData,
    userData,
    org,
    userSubType
}) => {
    const type = formData?.trxMeta?.type;

    const buyerKeys = {
        all_buyers: type === 'seller' ? null : concatParties(formData?.parties),
        buyer_address:
            type === 'seller'
                ? null
                : `${formData.parties[0].address}${
                      formData.parties[0].address2
                          ? `Unit ${formData.parties[0].address2},`
                          : ''
                  } ${formData.parties[0].city}, ${formData.parties[0].state} ${
                      formData.parties[0].zip
                  }`,
        buyer_address_1: type === 'seller' ? null : formData.parties[0].address || null,
        buyer_address_2: type === 'seller' ? null : formData.parties[0].address2 || null,
        buyer_address_city: type === 'seller' ? null : formData.parties[0].city || null,
        buyer_address_state: type === 'seller' ? null : formData.parties[0].state || null,
        buyer_address_zip: type === 'seller' ? null : formData.parties[0].zip || null,
        buyer_1:
            type === 'seller' || !formData.parties[0]
                ? null
                : getFullName(formData.parties[0]),
        buyer_1_signature:
            type === 'seller' || !formData.parties[0] ? null : formData.parties[0].id,
        buyer_2:
            type === 'seller' || !formData.parties[1]
                ? null
                : getFullName(formData.parties[1]),
        buyer_2_signature:
            type === 'seller' || !formData.parties[1] ? null : formData.parties[1].id,
        buyer_3:
            type === 'seller' || !formData.parties[2]
                ? null
                : getFullName(formData.parties[2]),
        buyer_3_signature:
            type === 'seller' || !formData.parties[2] ? null : formData.parties[2].id,
        buyer_4:
            type === 'seller' || !formData.parties[3]
                ? null
                : getFullName(formData.parties[3]),
        buyer_4_signature:
            type === 'seller' || !formData.parties[3] ? null : formData.parties[3].id,

        buyer_agent_1:
            type === 'seller' || userSubType !== 'sales_associate'
                ? null
                : getFullName(userData),
        buyer_agent_lic_1:
            type === 'seller' || userSubType !== 'sales_associate'
                ? null
                : userData.agent_lic,
        buyer_agent_signature_1:
            type === 'seller' || userSubType !== 'sales_associate' ? null : userData.id,

        // ASK: who is agent 2 in the moment of creating trx
        buyer_agent_2: null,
        buyer_agent_lic_2: null,
        buyer_agent_signature_2: null,

        buyer_broker_1:
            type === 'seller' || userSubType !== 'broker' ? null : getFullName(userData),
        buyer_broker_lic_1:
            type === 'seller' || userSubType !== 'broker' ? null : userData.broker_lic,
        buyer_broker_signature_1:
            type === 'seller' || userSubType !== 'broker' ? null : userData.id,
        // ASK: who is broker 2 in the moment of creating trx
        buyer_broker_2: null,
        buyer_broker_lic_2: null,
        buyer_broker_signature_2: null,

        buyer_brokerage: type === 'seller' ? null : org.company_name,
        buyer_brokerage_address:
            type === 'seller' ? null : org[userData.active_location_id]?.address || null,
        buyer_brokerage_address_1:
            type === 'seller'
                ? null
                : org[userData.active_location_id]?.address_1 || null,
        buyer_brokerage_address_2:
            type === 'seller'
                ? null
                : org[userData.active_location_id]?.address_2 || null,
        buyer_brokerage_address_city:
            type === 'seller' ? null : org[userData.active_location_id]?.city || null,
        buyer_brokerage_address_state:
            type === 'seller' ? null : org[userData.active_location_id]?.state || null,
        buyer_brokerage_address_zip:
            type === 'seller' ? null : org[userData.active_location_id]?.zip || null,
        buyer_brokerage_phone:
            type === 'seller'
                ? null
                : org[userData.active_location_id]?.phone?.find(el => el.primary)
                      ?.number || null,
        // ASK: ask Ken if it is an individual broker or brokerage mls
        buyer_brokerage_mls:
            type === 'seller' || userSubType !== 'broker'
                ? null
                : userData.mls_id[userData.active_org_id][0]?.mls_id,

        // TODO: add field for email and take a primary one
        buyer_brokerage_email: null,
        buyer_brokerage_fax:
            org[userData.active_location_id]?.phone?.find(el => el.type === 'fax')
                ?.number || null,

        buyer_closing_rep_address: null,
        buyer_closing_rep_address_1: null,
        buyer_closing_rep_address_2: null,
        buyer_closing_rep_city: null,
        buyer_closing_rep_state: null,
        buyer_closing_rep_zip: null,
        buyer_closing_rep_email: null,
        buyer_closing_rep_fax: null,
        buyer_closing_rep_name: null,
        buyer_closing_rep_phone: null
    };

    const sellerKeys = {
        all_sellers: type === 'buyer' ? null : concatParties(formData?.parties),
        seller_address:
            type === 'buyer'
                ? null
                : `${formData.parties[0].address}${
                      formData.parties[0].address2
                          ? `Unit ${formData.parties[0].address2},`
                          : ''
                  } ${formData.parties[0].city}, ${formData.parties[0].state} ${
                      formData.parties[0].zip
                  }`,
        seller_address_1: type === 'buyer' ? null : formData.parties[0].address || null,
        seller_address_2: type === 'buyer' ? null : formData.parties[0].address2 || null,
        seller_address_city: type === 'buyer' ? null : formData.parties[0].city || null,
        seller_address_state: type === 'buyer' ? null : formData.parties[0].state || null,
        seller_address_zip: type === 'buyer' ? null : formData.parties[0].zip || null,
        seller_1:
            type === 'buyer' || !formData.parties[0]
                ? null
                : getFullName(formData.parties[0]),
        seller_1_signature:
            type === 'buyer' || !formData.parties[0] ? null : formData.parties[0].id,
        seller_2:
            type === 'buyer' || !formData.parties[1]
                ? null
                : getFullName(formData.parties[1]),
        seller_2_signature:
            type === 'buyer' || !formData.parties[1] ? null : formData.parties[1].id,
        seller_3:
            type === 'buyer' || !formData.parties[2]
                ? null
                : getFullName(formData.parties[2]),
        seller_3_signature:
            type === 'buyer' || !formData.parties[2] ? null : formData.parties[2].id,
        seller_4:
            type === 'buyer' || !formData.parties[3]
                ? null
                : getFullName(formData.parties[3]),
        seller_4_signature:
            type === 'buyer' || !formData.parties[3] ? null : formData.parties[3].id,

        seller_agent_1:
            type === 'buyer' || userSubType !== 'sales_associate'
                ? null
                : getFullName(userData),
        seller_agent_lic_1:
            type === 'buyer' || userSubType !== 'sales_associate'
                ? null
                : userData.agent_lic,
        seller_agent_signature_1:
            type === 'buyer' || userSubType !== 'sales_associate' ? null : userData.id,
        seller_agent_2: null,
        seller_agent_lic_2: null,
        seller_agent_signature_2: null,

        seller_broker_1:
            type === 'buyer' || userSubType !== 'broker' ? null : getFullName(userData),
        seller_broker_lic_1:
            type === 'buyer' || userSubType !== 'broker' ? null : userData.broker_lic,
        seller_broker_signature_1:
            type === 'buyer' || userSubType !== 'broker' ? null : userData.id,
        seller_broker_2: null,
        seller_broker_lic_2: null,
        seller_broker_signature_2: null,

        seller_brokerage: type === 'buyer' ? null : org.company_name,
        seller_brokerage_address:
            type === 'buyer' ? null : org[userData.active_location_id]?.address || null,
        seller_brokerage_address_1:
            type === 'buyer' ? null : org[userData.active_location_id]?.address_1 || null,
        seller_brokerage_address_2:
            type === 'buyer' ? null : org[userData.active_location_id]?.address_2 || null,
        seller_brokerage_address_city:
            type === 'buyer' ? null : org[userData.active_location_id]?.city || null,
        seller_brokerage_address_state:
            type === 'buyer' ? null : org[userData.active_location_id]?.state || null,
        seller_brokerage_address_zip:
            type === 'buyer' ? null : org[userData.active_location_id]?.zip || null,
        seller_brokerage_phone:
            type === 'buyer'
                ? null
                : org[userData.active_location_id]?.phone?.find(el => el.primary)
                      ?.number || null,
        // ASK: ask Ken if it is an individual broker or brokerage mls
        seller_brokerage_mls:
            type === 'buyer' || userSubType !== 'broker'
                ? null
                : userData.mls_id[userData.active_org_id][0]?.mls_id,

        // TODO: add field for email and take a primary one
        seller_brokerage_email: null,
        seller_brokerage_fax:
            org[userData.active_location_id]?.phone?.find(el => el.type === 'fax')
                ?.number || null,

        seller_closing_rep_address: null,
        seller_closing_rep_address_1: null,
        seller_closing_rep_address_2: null,
        seller_closing_rep_city: null,
        seller_closing_rep_state: null,
        seller_closing_rep_zip: null,
        seller_closing_rep_email: null,
        seller_closing_rep_fax: null,
        seller_closing_rep_name: null,
        seller_closing_rep_phone: null
    };

    const mappings = Object.assign(TRANSACTION_MAPPING_SCHEMA, {
        closing_date: formData?.trxMeta?.closingDate
            ? tsFromJsDate(formData?.trxMeta?.closingDate)
            : null,
        county: null,

        legal_description: null,
        listing_start_date: formData?.trxMeta?.fromDate
            ? tsFromJsDate(formData?.trxMeta?.fromDate)
            : null,
        listing_expiration_date: formData?.trxMeta?.toDate
            ? tsFromJsDate(formData?.trxMeta?.toDate)
            : null,
        offer_date: null,
        offer_price: formData.trxMeta.offerPrice || null,
        property_address: propertyData?.location?.address.line,
        property_address_1: propertyData?.location?.address.address_1,
        property_address_2: propertyData?.location?.address.unit
            ? propertyData?.location?.address.unit
            : null,
        property_address_city: propertyData?.location?.address.city,
        property_address_state: propertyData?.location?.address.state,
        property_address_zip: propertyData?.location?.address.postal_code,
        purchase_price: null,
        listing_price: formData.trxMeta.listingPrice
    });
    return await { ...mappings, ...(type === 'buyer' ? buyerKeys : sellerKeys) };
};

const buildTransactionObjectRequest = async ({
    propertyData,
    formData,
    userData,
    org,
    tasks,
    docs
}) => {
    const docRef = transactions.doc();
    const tsNow = timeStampNow();

    const formattedTasks = await buildTaskArray({
        tasks,
        startDate: formData.trxMeta.fromDate
    });

    const formattedParties = await buildParties(formData.parties);
    const formatActivity = await buildActivity({
        tasks,
        docs,
        ts: tsNow,
        user: userData
    });

    const prefPath = userData.preferences[userData.active_org_id];
    const userSrc = prefPath.sources;
    const orgSrc = org.preferences.sources;
    const team = formData.trxMeta.team;

    const checkNewSource = () => {
        const foundUserSrc =
            userSrc.indexOf(formData.trxMeta.businessSrc.value.toUpperCase()) > -1;
        const foundOrgSrc =
            orgSrc.indexOf(formData.trxMeta.businessSrc.value.toUpperCase()) > -1;
        if (foundUserSrc || foundOrgSrc) {
            return false;
        } else {
            return true;
        }
    };

    const orgData = userData.orgs.filter(org => {
        return org.id === userData.active_org_id;
    });

    const userSubType = orgData[0].sub_type;
    const userTitle = orgData[0].title;
    const partyUids = formData.parties.map(party =>
        party.id ? party.id : party.pipeline_id
    );
    const teamUids = team && team.length ? team.map(member => member.id) : [];

    const emptyUserData = false;

    const primaryBroker = () => {
        if (userSubType === 'broker') {
            const brokerData = userData;
            return {
                email: brokerData.email,
                first_name: brokerData.first_name,
                id: brokerData.id,
                last_name: brokerData.last_name,
                phone: brokerData.phone
                    ? brokerData.phone.filter(phone => phone.primary === true)[0].number
                    : null
            };
        } else {
            return emptyUserData;
        }
    };

    const primaryAgent = () => {
        if (userSubType === 'sales_associate') {
            const agentData = userData;
            return {
                email: agentData.email,
                first_name: agentData.first_name,
                id: agentData.id,
                last_name: agentData.last_name,
                phone: agentData.phone
                    ? agentData.phone.filter(phone => phone.primary === true)[0].number
                    : null
            };
        } else {
            return emptyUserData;
        }
    };

    const primaryUserData = primaryAgent() ? primaryAgent() : primaryBroker();

    const trxIncome = {
        commission: formData.trxMeta.commission,
        commission_percent: formData.trxMeta.commissionPercent,
        split: formData.trxMeta.split,
        split_percent: formData.trxMeta.splitPercent,
        trx_fee: formData.trxMeta.fee,
        trx_fee_percent: formData.trxMeta.feePercent
    };

    const mappings = await formTransactionMappings({
        propertyData,
        formData,
        userData,
        org,
        userSubType
    });

    const trxObject = {
        address: {
            address_1: propertyData.location.address.address_1,
            address_2: propertyData.location.address.unit
                ? propertyData.location.address.unit
                : null,
            city: propertyData.location.address.city,
            latitude:
                propertyData.location.address.latitude ||
                propertyData.location.address.coordinate.lat ||
                '',
            longitude:
                propertyData.location.address.longitude ||
                propertyData.location.address.coordinate.lon ||
                '',
            state: propertyData.location.address.state,
            zip: propertyData.location.address.postal_code
        },
        access: [],
        avatars: [userData.id, ...partyUids, ...teamUids],
        closing_date: formData.trxMeta.closingDate
            ? tsFromJsDate(formData.trxMeta.closingDate)
            : null,
        created_at: tsNow,
        display: propertyData?.mlsSpec?.Media.length
            ? propertyData.mlsSpec.Media[0].MediaURL
            : 'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
        documents: {},
        id: docRef.id,
        income: trxIncome,
        invited: [],
        listing_from: formData.trxMeta.fromDate
            ? tsFromJsDate(formData.trxMeta.fromDate)
            : null,
        listing_to: formData.trxMeta.toDate
            ? tsFromJsDate(formData.trxMeta.toDate)
            : null,
        location_id: userData.active_location_id,
        mappings,
        mls: formData.trxMeta.mlsNumber,
        notes: [],
        opposing: [],
        org_id: userData.active_org_id,
        parties: formattedParties,
        party_ids: partyUids,
        photos: propertyData?.mlsSpec?.Media?.length
            ? [...propertyData?.mlsSpec?.Media]
            : [],
        price: formData.trxMeta.listingPrice,
        offer_price: formData.trxMeta.offerPrice || null,
        primary_agent: primaryAgent(),
        primary_broker: primaryBroker(),
        primary_client: {
            email: formData.parties[0].email,
            first_name: formData.parties[0].firstName,
            id: formData.parties[0].id
                ? formData.parties[0].id
                : formData.parties[0].pipeline_id,
            last_name: formData.parties[0].lastName,
            legal_name: formData.parties[0].fullLegalName,
            phone: formData.parties[0].phone
        },
        primary_opposing: emptyUserData,
        prop_sub_type: null,
        // prop_type: address.metaData.rdi.toLowerCase(), //comm
        source: formData.trxMeta.businessSrc.value,
        status: propertyData?.mlsSpec?.StandardStatus
            ? propertyData?.mlsSpec?.StandardStatus.toLowerCase()
            : 'active',
        tasks: formattedTasks,
        team: [{ ...primaryUserData, title: userTitle, type: userSubType }, ...team],
        team_ids: [userData.id, ...teamUids],
        type: formData.trxMeta.type,
        under_contract_date: formData.trxMeta.underContractDate
            ? tsFromJsDate(formData.trxMeta.underContractDate)
            : null
    };
    return { trxObject, newSource: checkNewSource(), activity: formatActivity };
};

const fsWrite = async transaction => {
    return transactions
        .doc(transaction.id)
        .set(transaction)
        .then(() => {
            return { fsWriteSuccess: true };
        })
        .catch(error => {
            return { fsError: error };
        });
};

const rtdbWrite = ({ transaction, userData, activity }) => {
    return new Promise((resolve, reject) => {
        const {
            org_id,
            team_ids,
            party_ids,
            address,
            display,
            id,
            location_id,
            mls,
            price,
            primary_agent,
            primary_broker,
            primary_client,
            status,
            type,
            parties
        } = transaction;

        const trxRef = rtdb.ref(`transactions/${org_id}/active/${id}`);
        const trxActivityRef = rtdb.ref(`trx_activity/${id}`);
        const trxMemberIds = [...team_ids, ...party_ids];

        const rtdbTransaction = async () => {
            return await new Promise((resolve, reject) => {
                let transaction = {
                    address,
                    display: display ? display : false,
                    id: id,
                    location_id,
                    mls: mls ? mls : '',
                    org_id,
                    price,
                    primary_agent: primary_agent ? primary_agent : false,
                    primary_broker: primary_broker ? primary_broker : false,
                    primary_client,
                    status,
                    updated_at: timeStampNow(),
                    type
                };
                trxMemberIds.forEach((memberId, index) => {
                    transaction = Object.assign(transaction, {
                        ...transaction,
                        [memberId]: true
                    });
                    if (index + 1 === trxMemberIds.length) {
                        trxRef.set({ ...transaction }, error => {
                            if (error) {
                                reject({ error });
                            } else {
                                resolve(true);
                            }
                        });
                    }
                });
            });
        };

        const rtdbActivity = async () => {
            return await new Promise((resolve, reject) => {
                activity.forEach((post, index) => {
                    const postRef = trxActivityRef.push();
                    postRef.set({ ...post }, error => {
                        if (error) {
                            reject({ error });
                        } else {
                            if (index + 1 === activity.length) {
                                resolve(true);
                            }
                        }
                    });
                });
            });
        };

        const rtdbPipeLine = async () => {
            return await new Promise((resolve, reject) => {
                parties.forEach((party, index) => {
                    const contactRef = party.pipeline
                        ? rtdb.ref(`pipeline/${userData.id}/${party.id}`)
                        : rtdb.ref(`connections/${userData.id}/${party.id}`);
                    contactRef.set(
                        {
                            address: address,
                            email: party.email,
                            id: party.id,
                            first_name: party.first_name,
                            last_name: party.last_name,
                            phone: party.phone,
                            legal_name: party.legal_name,
                            connection:
                                parties.length === 2
                                    ? {
                                          first_name:
                                              parties[index === 0 ? 1 : 0].first_name,
                                          last_name:
                                              parties[index === 0 ? 1 : 0].last_name,
                                          id: parties[index === 0 ? 1 : 0].id
                                      }
                                    : null
                        },
                        error => {
                            if (error) {
                                reject({ error });
                            } else {
                                if (index + 1 === parties.length) {
                                    resolve(true);
                                }
                            }
                        }
                    );
                });
            });
        };

        if (rtdbTransaction() && rtdbActivity() && rtdbPipeLine()) {
            resolve({ rtdbWriteSuccess: true });
        }
    });
};

const writeNewTransactionRequest = async ({ transaction, userData, activity }) => {
    const { fsWriteSuccess, fsError } = await fsWrite(transaction);
    const { rtdbWriteSuccess, rtdbError } = await rtdbWrite({
        transaction,
        userData,
        activity
    });
    if (fsWriteSuccess && rtdbWriteSuccess) {
        return { newTrxId: transaction.id };
    } else if (fsError || rtdbError) {
        return {
            error: {
                fsError,
                rtdbError
            }
        };
    }
};

const writeNewSrcRequest = ({ source, userData }) => {
    const ref = users.doc(userData.id);
    const org = userData.active_org_id;
    const path = `preferences.${org}.sources`;
    const existingSources = userData.preferences[org].sources;
    const newSources = [...existingSources, source.toUpperCase()];

    return new Promise((resolve, reject) => {
        ref.update({ [path]: newSources })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* formNewTransactions({ payload }) {
    const { propertyData, userData, tasks } = payload;
    let formData = { ...payload.formData };
    const org = yield select(selectors._activeOrg);
    const { processedParties, error } = yield call(() =>
        getExistingParties({ parties: formData.parties, user: userData })
    );

    if (processedParties) {
        formData = Object.assign(formData, {
            parties: [...processedParties]
        });

        const { trxObject, newSource, activity, error } = yield call(() =>
            buildTransactionObjectRequest({
                propertyData,
                formData,
                userData,
                org,
                tasks
            })
        );

        if (trxObject) {
            const { newTrxId, error } = yield call(() =>
                writeNewTransactionRequest({ transaction: trxObject, userData, activity })
            );
            if (newTrxId) {
                yield put(transactionWriteSuccess(newTrxId));
            } else {
                // Error Handling for sentry with put and maybe UI message
                log('Transactions Error: error writing new transaction (FS)', {
                    error,
                    trxObject
                });
            }
            if (newSource) {
                const { res, error } = yield call(() =>
                    writeNewSrcRequest({
                        source: formData.trxMeta.businessSrc.value,
                        userData
                    })
                );
                if (res) {
                    // Do nothing
                } else {
                    log(
                        'Transactions Error: error writing new user business sources (FS)',
                        {
                            error,
                            source: formData.trxMeta.businessSrc.value
                        }
                    );
                }
                // write new sources to user data object
            }
        } else {
            // Error Handling for sentry with put and maybe UI message
            log(
                'Transactions Error: error building trx object in forming new transaction',
                {
                    error,
                    processedParties,
                    formData
                }
            );
        }
    } else {
        // Error Handling for sentry with put and maybe UI message
        log(
            'Transactions Error: error processing transaction parties in forming new transaction',
            {
                error,
                payload
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Transaction Note /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionNoteRequest = async ({ notes, trxId }) => {
    return new Promise((resolve, reject) => {
        transactions
            .doc(trxId)
            .update({ notes })
            .then(() => {
                resolve({ writtenNote: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateTransactionNote({ payload }) {
    const { notes, trxId } = payload;
    const { writtenNote, error } = yield call(() =>
        updateTransactionNoteRequest({ notes, trxId })
    );
    if (writtenNote) {
        yield put(noteWriteSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log(`Transactions Error: updating transaction notes @ trx: ${trxId} (FS)`, {
            error,
            notes,
            trxId
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Transaction Task /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionTaskRequest = async ({
    tasks,
    trxId,
    posts,
    status,
    userData
}) => {
    const activityRef = rtdb.ref(`trx_activity/${trxId}`);
    const postRef = activityRef.push();
    return new Promise((resolve, reject) => {
        transactions
            .doc(trxId)
            .update({ tasks: tasks })
            .then(() => {
                setTransactionUpdatedTimeRequest({
                    trxId,
                    status,
                    userData
                });
            })
            .then(() => {
                postRef.set({ ...posts }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ writtenTask: true });
                    }
                });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateTransactionTask({ payload }) {
    const { tasks, trxId, posts, status } = payload;
    const userData = yield select(selectors._userData);

    const { writtenTask, error } = yield call(() =>
        updateTransactionTaskRequest({ tasks, trxId, posts, status, userData })
    );
    if (writtenTask) {
        yield put(taskWriteSuccess());
    } else {
        yield put(taskWriteFailure());
        log(`Transactions Error: updating transaction tasks @ trx: ${trxId} (FS)`, {
            error,
            tasks,
            trxId,
            posts
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Add Activity Text Post /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateActivityPostRequest = async ({ posts, trxId, userData, status }) => {
    return new Promise((resolve, reject) => {
        const activityRef = rtdb.ref(`trx_activity/${trxId}`);
        const postRef = activityRef.push();
        postRef
            .set({ ...posts })
            .then(() => {
                setTransactionUpdatedTimeRequest({
                    trxId,
                    status,
                    userData
                });
            })
            .then(() => {
                resolve({ writtenPost: true });
            })
            .catch(error => {
                reject({ error });
            });

        // transactions
        //     .doc(trxId)
        //     .update({ activity: fsFieldValue.arrayUnion() })
        //     .then(() => {
        //         resolve({ writtenPost: true });
        //     })
        //     .catch(error => {
        //         reject({ error });
        //     });
    });
};

export function* updateActivityPost({ payload }) {
    const { posts, trxId, status } = payload;
    const attachment = posts?.attachments?.length ? posts.attachments[0] : null;
    const userData = yield select(selectors._userData);

    const { writtenPost, writtenFile, error } = yield call(() =>
        attachment
            ? uploadingTransactionFileRequest({ file: attachment, trxId })
            : updateActivityPostRequest({ posts, trxId, userData, status })
    );
    if (writtenPost) {
        yield put(postWriteSuccess());
    } else if (writtenFile) {
        const data = posts;
        const attachments = [
            {
                id: writtenFile[1],
                path: writtenFile[0],
                title: attachment.title ? attachment.title : attachment.src.name,
                type: attachment.file_type
            }
        ];
        data.attachments = attachments;
        const { writtenPost, error } = yield call(() =>
            updateActivityPostRequest({ posts: data, trxId })
        );
        if (writtenPost) {
            yield put(postWriteSuccess());
        } else {
            log(
                `Transactions Error: adding activity post with attachment @ trx: ${trxId} (FS)`,
                {
                    error,
                    posts,
                    trxId
                }
            );
        }
    } else {
        log(
            `Transactions Error: adding activity post no attachment @ trx: ${trxId} (FS)`,
            {
                error,
                posts,
                trxId
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Upload Transaction Storage File /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const uploadingTransactionFileRequest = async ({ file, trxId }) => {
    const id = generateFirestoreUid();
    const storageRef = storage.ref();
    const fileRef = storageRef
        .child('transactions')
        .child(trxId)
        .child(file.storage_type)
        .child(id);
    try {
        const snapshot = await fileRef.put(file.src);
        const url = await snapshot.ref.getDownloadURL();
        return { writtenFile: [url, id] };
    } catch (error) {
        return { error };
    }
};

const generateFirestoreUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Transaction Status /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionStatusRequest = async ({
    oldStatus,
    newStatus,
    trxId,
    date,
    userData,
    transaction
}) => {
    const activity = {
        archived_at: null,
        attachments: [],
        automated: {
            name: newStatus
        },
        created_at: timeStampNow(),
        creator_id: userData.id,
        creator_type: 'user',
        edited_at: null,
        first_name: userData.first_name,
        last_name: userData.last_name,
        message: activityMessage.changeTrxStatus
    };

    return new Promise((resolve, reject) => {
        if (!Object.values(transaction).length) {
            reject({ error: 'Transaction object is empty' });
        } else {
            transactions
                .doc(trxId)
                .update({
                    status: newStatus,
                    closing_date:
                        newStatus === trxStatus.closed
                            ? timeStampJs.fromDate(new Date(date))
                            : ''
                })
                .then(() => {
                    if (
                        !(
                            newStatus === trxStatus.pending &&
                            oldStatus === trxStatus.active
                        ) &&
                        !(
                            newStatus === trxStatus.active &&
                            oldStatus === trxStatus.pending
                        )
                    ) {
                        return rtdb
                            .ref(
                                newStatus === trxStatus.pending
                                    ? `transactions/${userData.active_org_id}/active/${trxId}`
                                    : `transactions/${userData.active_org_id}/${newStatus}/${trxId}`
                            )
                            .set(
                                {
                                    ...transaction,
                                    status: newStatus,
                                    closing_date:
                                        newStatus === trxStatus.closed
                                            ? timeStampJs.fromDate(new Date(date))
                                            : ''
                                },
                                error => {
                                    if (error) {
                                        reject({ error });
                                    }
                                }
                            );
                    }
                })
                .then(() => {
                    if (
                        !(
                            newStatus === trxStatus.pending &&
                            oldStatus === trxStatus.active
                        ) &&
                        !(
                            newStatus === trxStatus.active &&
                            oldStatus === trxStatus.pending
                        )
                    ) {
                        return rtdb
                            .ref(
                                oldStatus === trxStatus.pending
                                    ? `transactions/${userData.active_org_id}/active/${trxId}`
                                    : `transactions/${userData.active_org_id}/${oldStatus}/${trxId}`
                            )
                            .remove();
                    } else {
                        return rtdb
                            .ref(`transactions/${userData.active_org_id}/active/${trxId}`)
                            .update({ status: newStatus });
                    }
                })
                .then(() => {
                    const activityRef = rtdb.ref(`trx_activity/${trxId}`);
                    const postRef = activityRef.push();
                    postRef.set({ ...activity });
                })
                .then(() => {
                    setTransactionUpdatedTimeRequest({
                        trxId,
                        status: newStatus,
                        userData
                    });
                })
                .then(() => {
                    resolve({ updatedStatus: true });
                })
                .catch(error => {
                    reject({ error });
                });
        }
    });
};

export function* updateTransactionStatus({ payload }) {
    const { oldStatus, newStatus, trxId, userData } = payload;

    const chooseModalType = () => {
        switch (newStatus) {
            case trxStatus.active:
                return oldStatus === trxStatus.closed
                    ? confirmationDialogTypes.reopenTrx
                    : confirmationDialogTypes.activateTrx;
            case trxStatus.archived:
                return confirmationDialogTypes.archiveTrx;
            case trxStatus.closed:
                return confirmationDialogTypes.closeTrx;
            default:
                return confirmationDialogTypes.reopenTrx;
        }
    };

    const { isConfirm, dataFromModal } =
        (newStatus === trxStatus.pending && oldStatus !== trxStatus.closed) ||
        (oldStatus === trxStatus.pending && newStatus === trxStatus.active)
            ? { isConfirm: true }
            : yield call(confirmSaga, {
                  modalType: chooseModalType()
              });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));

        const selector =
            oldStatus === trxStatus.pending
                ? `_activeTransactions`
                : `_${oldStatus}Transactions`;
        const transactions = yield select({ ...selectors }[selector]);
        const currentTransactionFromRtdb = transactions.find(el => el.id === trxId);

        const { updatedStatus, error } = yield call(() =>
            updateTransactionStatusRequest({
                oldStatus,
                newStatus,
                trxId,
                date: dataFromModal,
                userData,
                transaction: currentTransactionFromRtdb
            })
        );
        if (updatedStatus) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            // Error Handling for sentry with put and maybe UI message
            log(`Transactions Error: updating transaction status @ trx: ${trxId} (FS)`, {
                error,
                oldStatus,
                newStatus,
                trxId
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Transaction Details /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionDetailsRequest = async ({
    trxDetails,
    values,
    userData,
    isAddressEdited,
    isMlsEdited
}) => {
    let addresUpdates = {},
        mlsUpdates = {};
    if (isAddressEdited) {
        addresUpdates = {
            address: {
                address_1: values.address1,
                address_2: values.address2,
                city: values.city,
                latitude: values.lat,
                longitude: values.lon,
                state: values.state,
                zip: values.zip
            },
            mappings: {
                ...values.mappings,
                [mappingKeys.county]: values.county || trxDetails.mappings.county,
                [mappingKeys.legal_description]: ''
            }
        };
    }

    const { propertyData } = values;
    if (isMlsEdited) {
        const documents = Object.entries(trxDetails.documents).map(([id, document]) =>
            document.fillable
                ? [
                      id,
                      { ...document, sys_archived: true, sys_archived_id: generateUid() }
                  ]
                : [id, document]
        );
        mlsUpdates = {
            display: propertyData?.mlsSpec?.Media.length
                ? propertyData.mlsSpec.Media[0].MediaURL
                : 'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
            documents: Object.fromEntries(documents),
            mappings: {
                ...values.mappings,
                [mappingKeys.county]: values.county,
                [mappingKeys.legal_description]: '',
                [mappingKeys.offer_date]: null,
                [mappingKeys.purchase_price]: propertyData.ListPrice
            },
            mls: values.mlsNumber,
            mls_list_date: propertyData?.mlsSpec?.ListingContractDate
                ? new Date(propertyData.mlsSpec.ListingContractDate.split('-'))
                : '',
            offer_date: null,
            photos: propertyData?.mlsSpec?.Media?.length
                ? [...propertyData?.mlsSpec?.Media]
                : [],
            price: propertyData.ListPrice,
            status: trxStatus.active
        };
    }

    const fieldsForUpdate = {
        price: values.price,
        closing_date: values.closing_date,
        source: values.businessSrc.value,
        income: {
            commission: values.commission,
            commission_percent: values.commissionPercent,
            split: values.split,
            split_percent: values.splitPercent,
            trx_fee: values.fee,
            trx_fee_percent: values.feePercent
        },

        primary_client: {
            ...trxDetails.primary_client,
            first_name: values.parties[0].client_first_name,
            last_name: values.parties[0].client_last_name,
            legal_name: values.parties[0].client_full_name
        },
        parties: trxDetails.parties.map(party => {
            const currentParty = values.parties.find(el => el.id === party.id);
            return {
                ...party,
                first_name: currentParty.client_first_name,
                last_name: currentParty.client_last_name,
                legal_name: currentParty.client_full_name
            };
        })
    };

    return new Promise((resolve, reject) => {
        transactions
            .doc(trxDetails.id)
            .update({ ...fieldsForUpdate, ...addresUpdates, ...mlsUpdates })
            .then(() => {
                return rtdb
                    .ref(
                        `transactions/${userData.active_org_id}/${
                            trxDetails.status === trxStatus.pending
                                ? trxStatus.active
                                : trxDetails.status
                        }/${trxDetails.id}`
                    )
                    .update(
                        Object.entries({
                            ...fieldsForUpdate,
                            ...addresUpdates,
                            ...mlsUpdates
                        }).reduce(
                            (acc, [key, value]) =>
                                ['documents', 'offer_date', 'photos'].includes(key)
                                    ? acc
                                    : { ...acc, [key]: value },
                            {}
                        )
                    );
            })
            .then(() => {
                if (isAddressEdited || isMlsEdited) {
                    const activity = {
                        archived_at: null,
                        attachments: [],
                        automated: {
                            name: `${values.address1} ${values.address2}, ${values.city} ${values.state} ${values.zip}`
                        },
                        created_at: timeStampNow(),
                        creator_id: userData.id,
                        creator_type: 'user',
                        edited_at: null,
                        first_name: userData.first_name,
                        last_name: userData.last_name,
                        message: activityMessage.updateAddress
                    };
                    const activityRef = rtdb.ref(`trx_activity/${trxDetails.id}`);
                    const postRef = activityRef.push();
                    return postRef.set({ ...activity });
                }
            })
            .then(() => {
                setTransactionUpdatedTimeRequest({
                    trxId: trxDetails.id,
                    status: trxDetails.status,
                    userData
                });
            })
            .then(() => {
                resolve({ updatedDetails: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateTransactionDetails({ payload }) {
    const { trxDetails, values, userData, isAddressEdited, isMlsEdited } = payload;

    const { updatedDetails, error } = yield call(() =>
        updateTransactionDetailsRequest({
            trxDetails,
            values,
            userData,
            isAddressEdited,
            isMlsEdited
        })
    );
    if (updatedDetails) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        // Error Handling for sentry with put and maybe UI message
        log(
            `Transactions Error: updating transaction details @ trx: ${trxDetails.id} (FS)`,
            {
                error,
                trxId: trxDetails.id
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Party To Transaction /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removePipelineFromTheRtdbRequest = async ({ pipelineId, userId }) => {
    const pipelineRef = rtdb.ref(`pipeline/${userId}/${pipelineId}`);
    return pipelineRef
        .remove()
        .then(() => ({ res: true }))
        .catch(error => ({ err: error }));
};

const addPartyToTransactionRequest = async ({
    transactionId,
    party,
    existingContact,
    newUser,
    pipeline
}) => {
    const newPartyId = !existingContact ? newUser.id : existingContact.id;
    const newTransactionParty = (await buildParties([{ ...party, id: newPartyId }]))[0];

    const transactionRef = transactions.doc(transactionId);
    const prevValues = (await transactionRef.get()).data();

    const newParties = [
        ...prevValues.parties.filter(item => !pipeline || item.id !== party.id),
        newTransactionParty
    ];

    const partyPrefix = prevValues.type === 'seller' ? 'seller' : 'buyer';
    const reworkedMappings = {
        [`mappings.all_${prevValues.type === 'seller' ? 'sellers' : 'buyers'}`]:
            concatParties(newParties),
        [`mappings.${partyPrefix}_1`]: newParties[0] ? getFullName(newParties[0]) : null,
        [`mappings.${partyPrefix}_1_signature`]: newParties[0] ? newParties[0].id : null,
        [`mappings.${partyPrefix}_2`]: newParties[1] ? getFullName(newParties[1]) : null,
        [`mappings.${partyPrefix}_2_signature`]: newParties[1] ? newParties[1].id : null,
        [`mappings.${partyPrefix}_3`]: newParties[2] ? getFullName(newParties[2]) : null,
        [`mappings.${partyPrefix}_3_signature`]: newParties[2] ? newParties[2].id : null,
        [`mappings.${partyPrefix}_4`]: newParties[3] ? getFullName(newParties[3]) : null,
        [`mappings.${partyPrefix}_4_signature`]: newParties[3] ? newParties[3].id : null
    };

    return new Promise((resolve, reject) => {
        transactionRef
            .update({
                parties: newParties,
                party_ids: [
                    ...prevValues.party_ids.filter(
                        item => !pipeline || item !== party.id
                    ),
                    newPartyId
                ],
                avatars: prevValues.team_ids.includes(newPartyId)
                    ? prevValues.avatars
                    : [...prevValues.avatars, newPartyId],
                primary_client:
                    pipeline && party.id === prevValues.primary_client.id
                        ? {
                              id: newTransactionParty.id,
                              email: newTransactionParty.email,
                              first_name: newTransactionParty.first_name,
                              last_name: newTransactionParty.last_name,
                              legal_name: newTransactionParty.legal_name,
                              phone: newTransactionParty.phone
                          }
                        : prevValues.primary_client,
                ...reworkedMappings
            })
            .then(() => {
                resolve({ addedParty: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* addPartyToTransaction({ payload }) {
    const { transactionId, party, existingContact, pipeline } = payload;
    const user = yield select(selectors._userData);
    let newUser;
    yield put(setConfirmModalType(confirmationDialogTypes.loading));

    if (!existingContact) {
        newUser = (yield call(() =>
            addNewAuthUserConnections({
                connections: [party],
                user,
                allConnections: []
            })
        ))?.data?.processed[0];
    }

    const userConnections = yield select(selectors._userConnections);
    if (existingContact && !userConnections.some(el => el.id === existingContact.id)) {
        yield call(addExistingUserConnections, {
            connections: [{ connection: party, docData: existingContact }],
            user,
            allConnections: []
        });
    }

    if (pipeline) {
        yield call(() =>
            removePipelineFromTheRtdbRequest({
                pipelineId: party.id,
                userId: user.id
            })
        );
    }

    const { addedParty, error } = yield call(() =>
        addPartyToTransactionRequest({
            transactionId,
            party,
            existingContact,
            newUser,
            pipeline
        })
    );
    if (addedParty) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        // Error Handling for sentry with put and maybe UI message
        log(
            `Transactions Error: adding party to transaction @trx: ${transactionId} (FS)`,
            {
                error,
                trxId: transactionId,
                newParty: party,
                user
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get Member Transactions //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getMemberTransactions({ payload }) {
    const { id } = payload;

    const user = yield select(selectors._userData);
    const { active_org_id } = user;

    const activeRef = rtdb.ref(`transactions/${active_org_id}/active`);
    const closedRef = rtdb.ref(`transactions/${active_org_id}/closed`);
    const archivedRef = rtdb.ref(`transactions/${active_org_id}/archived`);

    const activeTransactionsRTDBValues = yield activeRef.once('value');
    const activeTransactions = Object.values(activeTransactionsRTDBValues.val()).filter(
        el => el[id]
    );
    yield put(storeMemberTransactions(activeTransactions));

    const closedTransactionsRTDBValues = yield closedRef.once('value');
    const closedTransaction = Object.values(closedTransactionsRTDBValues.val()).filter(
        el => el[id]
    );
    yield put(storeMemberClosedTransactions(closedTransaction));

    const archivedTransactionsRTDBValues = yield archivedRef.once('value');
    const archivedTransaction = Object.values(
        archivedTransactionsRTDBValues.val()
    ).filter(el => el[id]);
    yield put(storeMemberArchivedTransactions(archivedTransaction));
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////// Remove Party From The Transaction //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const removingPartyFromTransactionRequest = ({ transaction, userId }) => {
    const transactionRef = transactions.doc(transaction.id);

    const newParties = transaction.parties.filter(item => item.id !== userId);

    const partyPrefix = transaction.type === 'seller' ? 'seller' : 'buyer';
    const reworkedMappings = {
        [`mappings.all_${transaction.type === 'seller' ? 'sellers' : 'buyers'}`]:
            concatParties(newParties),
        [`mappings.${partyPrefix}_1`]: newParties[0] ? getFullName(newParties[0]) : null,
        [`mappings.${partyPrefix}_1_signature`]: newParties[0] ? newParties[0].id : null,
        [`mappings.${partyPrefix}_2`]: newParties[1] ? getFullName(newParties[1]) : null,
        [`mappings.${partyPrefix}_2_signature`]: newParties[1] ? newParties[1].id : null,
        [`mappings.${partyPrefix}_3`]: newParties[2] ? getFullName(newParties[2]) : null,
        [`mappings.${partyPrefix}_3_signature`]: newParties[2] ? newParties[2].id : null,
        [`mappings.${partyPrefix}_4`]: newParties[3] ? getFullName(newParties[3]) : null,
        [`mappings.${partyPrefix}_4_signature`]: newParties[3] ? newParties[3].id : null
    };

    return new Promise((resolve, reject) => {
        transactionRef
            .update({
                parties: newParties,
                party_ids: transaction.party_ids.filter(item => item !== userId),
                invited: fsFieldValue.arrayRemove(userId),
                access: fsFieldValue.arrayRemove(userId),
                avatars: transaction.team_ids.includes(userId)
                    ? transaction.avatars
                    : transaction.avatars.filter(item => item !== userId),
                ...reworkedMappings
            })
            .then(() => {
                resolve({ removedParty: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

const replacingPrimaryClientRequest = ({ newPrimary, transactionId }) => {
    const transactionRef = transactions.doc(transactionId);
    return new Promise((resolve, reject) => {
        transactionRef
            .update({
                primary_client: newPrimary
            })
            .then(() => {
                resolve({ replacedPrimary: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* removePartyFromTransaction({ payload }) {
    const { transactionId, userId } = payload;

    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.delete
    });
    if (!isConfirm) return;

    const transaction = yield getTransactionDetails(transactionId);
    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    let replacedPrimaryResult;

    if (transaction.primary_client.id === userId) {
        replacedPrimaryResult = yield call(() =>
            replacingPrimaryClientRequest({
                newPrimary: transaction.parties.find(el => el.id !== userId),
                transactionId: transaction.id
            })
        );
    }

    if (
        transaction.primary_client.id !== userId ||
        replacedPrimaryResult.replacedPrimary
    ) {
        const { removedParty, error } = yield call(() =>
            removingPartyFromTransactionRequest({
                transaction,
                userId
            })
        );
        if (removedParty) {
            yield put(setConfirmModalType(null));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            // Error Handling for sentry with put and maybe UI message
            log(
                `Transactions Error: removing party from transaction @ trx: ${transactionId} (FS)`,
                {
                    error,
                    trxId: transactionId,
                    partyId: userId
                }
            );
        }
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        // Error Handling for sentry with put and maybe UI message
        log(
            `Transactions Error: replacing primary client during removing trx party: ${transactionId} (FS)`,
            {
                error: replacedPrimaryResult.error,
                trxId: transactionId,
                partyId: userId
            }
        );
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////// Invite Party To The Transaction ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const invitingPartyToTransactionRequest = async ({
    transactionId,
    partyId,
    user,
    propertyAddress
}) => {
    const transactionRef = transactions.doc(transactionId);
    const { first_name, last_name, email, invite_code } = (
        await users.doc(partyId).get()
    ).data();
    return new Promise((resolve, reject) =>
        executeInviteClientToTransaction({
            futureParty: {
                first_name,
                last_name,
                email,
                invite_code,
                user_id: partyId
            },
            userName: `${user.first_name} ${user.last_name}`,
            property_address: propertyAddress,
            trx_id: transactionId
        })
            .then(() =>
                transactionRef.update({
                    invited: fsFieldValue.arrayUnion(partyId)
                })
            )
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                reject({
                    error,
                    party: { id: partyId, first_name, last_name, email, invite_code }
                });
            })
    );
};

export function* invitePartyToTransaction({ payload }) {
    const { transactionId, parties } = payload;

    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    const user = yield select(selectors._userData);
    const transaction = yield select(selectors._currentTransaction);
    const propertyAddress = `${transaction.address.address_1} ${
        transaction.address.address_2 || ''
    }`;

    const results = yield all(
        parties.map(party =>
            call(invitingPartyToTransactionRequest, {
                transactionId,
                partyId: party.id,
                user,
                propertyAddress
            })
        )
    );

    const success = results.every(result => result.success === true);

    if (success) {
        yield put(setConfirmModalType(confirmationDialogTypes.successWithoutHandler));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));

        results.forEach(({ error, party }) => {
            if (error) {
                log(
                    `Transactions Error: inviting party to transaction @trx: ${transactionId} (FS)`,
                    {
                        error,
                        trxId: transactionId,
                        invitee: party
                    }
                );
            }
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update Time Visiting Transaction ////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTimeVisitingTransactionRequest = ({ id, status, userData }) => {
    const pathStatus = status === trxStatus.pending ? 'active' : status;
    const transactionRef = rtdb.ref(
        `transactions/${userData.active_org_id}/${pathStatus}/${id}/visitors`
    );
    const trxUpdateTime = timeStampNow();

    return new Promise((resolve, reject) =>
        transactionRef
            .update({ [userData.id]: trxUpdateTime })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }))
    );
};

export function* updateTimeVisitingTransaction({ payload }) {
    const { userData, id, status } = payload;
    const { error } = yield call(() =>
        updateTimeVisitingTransactionRequest({ id, status, userData })
    );
    if (error) {
        log('Transaction Error: update visiting time transaction (RTDB)', {
            error,
            userData: userData.id,
            id
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Set Transaction Updated Time //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const setTransactionUpdatedTimeRequest = ({ status, userData, trxId }) => {
    const pathStatus = status === trxStatus.pending ? 'active' : status;
    const transactionRef = rtdb.ref(
        `transactions/${userData.active_org_id}/${pathStatus}/${trxId}`
    );

    return new Promise((resolve, reject) =>
        transactionRef
            .update({ updated_at: timeStampNow() })

            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            })
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Members To Transaction ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTransactionMembersRequest = async ({
    members,
    id,
    party_ids,
    primary_broker,
    active_org_id,
    status,
    team_ids
}) => {
    const transactionRef = transactions.doc(id);
    const rtdbTransactionRef = rtdb.ref(
        `transactions/${active_org_id}/${
            status === trxStatus.pending ? trxStatus.active : status
        }/${id}`
    );

    const memberIds = members.map(member => member.id);
    const removedMembersIds = team_ids.filter(
        el => !memberIds.includes(el) && !party_ids.includes(el)
    );

    const addingItems = memberIds.map(id => [id, true]);
    const removingItems = removedMembersIds.map(id => [id, null]);

    return new Promise((resolve, reject) => {
        const totalAvatars = primary_broker
            ? [...party_ids, primary_broker.id, ...memberIds]
            : [...party_ids, ...memberIds];
        transactionRef
            .update({
                team: members,
                team_ids: fsFieldValue.arrayUnion(...memberIds),
                avatars: fsFieldValue.arrayUnion(...totalAvatars)
            })
            .then(() =>
                rtdbTransactionRef.update({
                    ...Object.fromEntries(addingItems),
                    ...Object.fromEntries(removingItems)
                })
            )
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateTransactionMembers({ payload }) {
    const { active_org_id } = yield select(selectors._userData);
    const { id, party_ids, primary_broker, status, team_ids } = yield select(
        selectors._currentTransaction
    );

    const { members, isUpdatingFromList } = payload;
    let isModalConfirmed;

    if (isUpdatingFromList) {
        const { isConfirm } = yield call(confirmSaga, {
            modalType: confirmationDialogTypes.deleteMemberFromTrx
        });
        isModalConfirmed = isConfirm;
        yield put(setConfirmModalType(null));
    }

    if (isModalConfirmed || !isUpdatingFromList) {
        const { error } = yield call(() =>
            updateTransactionMembersRequest({
                members,
                id,
                party_ids,
                active_org_id,
                status,
                team_ids,
                primary_broker
            })
        );
        if (error) {
            log('Transaction Error: update transaction members  (DB/RTDB)', {
                members,
                error
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Add Opposing To Transaction //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateOpposingMembersRequest = async ({ id, member, opposing, avatars }) => {
    const ref = transactions.doc(id);

    const preparedMember = async () => {
        return await users
            .where('email', '==', member?.email?.toLowerCase() || '')
            .get()
            .then(function (querySnapshot) {
                if (querySnapshot.size > 0) {
                    var obj;
                    querySnapshot.forEach(function (doc) {
                        obj = Object.assign(member, {
                            id: opposing.some(member => member.id === doc.data().id)
                                ? `${doc.data().id}-${generateUid()}`
                                : doc.data().id
                        });
                    });
                    return obj;
                } else {
                    return Object.assign(member, {
                        id: `temp-${generateUid()}`
                    });
                }
            });
    };

    return new Promise((resolve, reject) => {
        var data;
        preparedMember().then(prepped => {
            if (prepped.primary) {
                data = Object.assign(
                    {},
                    {
                        avatars: [...avatars, prepped.id],
                        primary_opposing: { ...prepped },
                        opposing: [
                            { ...prepped },
                            ...opposing.map(opp => {
                                return { ...opp, primary: false };
                            })
                        ]
                    }
                );
                ref.update({
                    ...data
                })
                    .then(() => {
                        resolve({ res: data });
                    })
                    .catch(error => {
                        reject({ error });
                    });
            } else {
                data = Object.assign(
                    {},
                    {
                        avatars: [...avatars, prepped.id],
                        opposing: [...opposing, { ...prepped }]
                    }
                );
                ref.update({
                    ...data
                })
                    .then(() => {
                        resolve({ res: data });
                    })
                    .catch(error => {
                        reject({ error });
                    });
            }
        });
    });
};

export function* updateOpposingMembers({ payload }) {
    const { member, opposing } = payload;
    const { id, avatars } = yield select(selectors._currentTransaction);

    const { res, error } = yield call(() =>
        updateOpposingMembersRequest({ id, member, opposing, avatars })
    );
    if (res) {
        updatingOpposingMembersSuccess();
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Opposing Error: updating opposing team (FS)', {
            error,
            member,
            trx_id: id
        });
        updatingOpposingMembersFailure(error);
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Remove Opposing From Transaction ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeOpposingMemberRequest = async ({
    member,
    id,
    avatars,
    opposing,
    primary_opposing
}) => {
    const ref = transactions.doc(id);

    return new Promise((resolve, reject) => {
        var data;
        if (opposing.length === 1) {
            data = Object.assign(
                {},
                {
                    avatars: avatars.filter(id => id !== member),
                    opposing: [],
                    primary_opposing: false
                }
            );
            ref.update({
                ...data
            })
                .then(() => {
                    resolve({ res: data });
                })
                .catch(error => {
                    reject({ error });
                });
        } else {
            data = Object.assign(
                {},
                {
                    avatars: avatars.filter(id => id !== member),
                    opposing: opposing.filter(opp => opp.id !== member)
                }
            );
            ref.update({
                ...data
            })
                .then(() => {
                    resolve({ res: data });
                })
                .catch(error => {
                    reject({ error });
                });
        }
    });
};

export function* removeOpposingMember({ payload }) {
    const { member } = payload;
    const { id, avatars, opposing, primary_opposing } = yield select(
        selectors._currentTransaction
    );

    const { res, error } = yield call(() =>
        removeOpposingMemberRequest({ member, id, avatars, opposing, primary_opposing })
    );
    if (res) {
        removingOpposingMemberSuccess();
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Opposing Error: removing opposing member (FS)', {
            error,
            member,
            trx_id: id
        });
        removingOpposingMemberFailure(error);
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getUserTransactions() {
    yield takeLatest(GET_USER_TRANSACTIONS, transactionCollectionWatch);
}

export function* formingNewTransactions() {
    yield takeLatest(CREATE_TRANSACTION, formNewTransactions);
}

export function* updatingNewTransactionNote() {
    yield takeLatest(UPDATE_TRANSACTION_NOTE, updateTransactionNote);
}

export function* updatingNewTransactionTask() {
    yield takeLatest(UPDATE_TRANSACTION_TASK, updateTransactionTask);
}

export function* updatingNewTransactionPosts() {
    yield takeLatest(UPDATE_ACTIVITY_POST, updateActivityPost);
}

export function* gettingTransactionDetails() {
    yield takeLatest(
        [GET_TRANSACTION_DETAILS, SET_CLIENT_TRANSACTION_DETAILS],
        transactionDetailsWatch
    );
}

export function* updatingTransactionStatus() {
    yield takeLatest(UPDATE_TRANSACTION_STATUS, updateTransactionStatus);
}

export function* updatingTransactionDetails() {
    yield takeLatest(UPDATE_TRANSACTION_DETAILS, updateTransactionDetails);
}

export function* updatingTransactionMembers() {
    yield takeLatest(UPDATE_TRANSACTION_MEMBERS, updateTransactionMembers);
}

export function* addingPartyToTransaction() {
    yield takeLatest(ADD_PARTY_TO_TRANSACTION, addPartyToTransaction);
}

export function* gettingMemberTransaction() {
    yield takeLatest(GET_MEMBER_TRANSACTIONS, getMemberTransactions);
}

export function* removingPartyFromTransaction() {
    yield takeLatest(REMOVE_PARTY_FROM_TRANSACTION, removePartyFromTransaction);
}

export function* invitingPartyToTransaction() {
    yield takeLatest(INVITE_PARTY_TO_TRANSACTION, invitePartyToTransaction);
}

export function* updatingTimeVisitingTransaction() {
    yield takeLatest(UPDATE_TIME_VISITING_TRANSACTION, updateTimeVisitingTransaction);
}

export function* updatingOpposingMembers() {
    yield takeLatest(UPDATE_OPPOSING_MEMBERS, updateOpposingMembers);
}

export function* removingOpposingMember() {
    yield takeLatest(REMOVE_OPPOSING_MEMBER, removeOpposingMember);
}

export default function* rootSaga() {
    yield all([
        fork(getUserTransactions),
        fork(formingNewTransactions),
        fork(updatingNewTransactionNote),
        fork(updatingNewTransactionTask),
        fork(updatingNewTransactionPosts),
        fork(gettingTransactionDetails),
        fork(updatingTransactionStatus),
        fork(updatingTransactionDetails),
        fork(addingPartyToTransaction),
        fork(gettingMemberTransaction),
        fork(removingPartyFromTransaction),
        fork(invitingPartyToTransaction),
        fork(updatingTimeVisitingTransaction),
        fork(updatingTransactionMembers),
        fork(updatingOpposingMembers),
        fork(removingOpposingMember)
    ]);
}

// const test = {
//     // Buyer & Seller
//     activity: [
//         {
//             archived_at: null,
//             attachments: [],
//             created_at: 'time_stamp',
//             creator_id: null,
//             creator_type: 'system',
//             edited_at: null,
//             first_name: 'Jada',
//             last_name: null,
//             message:
//                 'Congratulations on your new transaction!  Feel free to add documents and create tasks if you have not done so already.  We look forward to seeing everyone at the finish line!'
//         }
//     ],
//     // Buyer & Seller
//     address: {
//         address_1: '',
//         address_2: '',
//         city: '',
//         latitude: '',
//         longitude: '',
//         state: '',
//         zip: ''
//     },
//     // Buyer & Seller
//     avatars: [],
//     // Buyer & Seller
//     closing_date: null,
//     // Buyer & Seller
//     created_at: 'timestamp',
//     // Buyer & Seller
//     display:
//         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
//     // Buyer & Seller
//     documents: [
//         // {
//         //     created_at: 'timestamp',
//         //     edited_at: null,
//         //     id: 'vT0tSVYy6i5TVX4leBXa',
//         //     status: 'incomplete',
//         //     title: 'Purchase and Sale Pack',
//         //     type: 'library',
//         //     url:
//         //         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fdocuments%2Ftestpdf.pdf?alt=media&token=bc1a2a5a-2198-4de8-bec3-d650a1994205'
//         // }
//     ],
//     // Buyer & Seller
//     id: 'Vhiju4PRLu9CnpPyLaFs', // trx id after creation
//     // Buyer & Seller
//     income: {
//         commission: 3,
//         commission_percent: true,
//         split: 80,
//         split_percent: true,
//         trx_fee: 195
//     },
//     // Buyer & Seller
//     location_id: 1,
//     // Buyer possible
//     mls: '2238745',
//     // Buyer & Seller
//     notes: [],
//     // Buyer & Seller
//     opposing: [],
//     // Buyer & Seller
//     org_id: 'ZHUyhQi2KRHwK6cqeG2y',
//     // Buyer & Seller
//     parties: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: ''
//         }
//     ],
//     // Buyer & Seller
//     party_ids: [],
//     // Buyer & Seller
//     photos: [
//         {
//             created_at: 'timestamp',
//             source: 'upload',
//             url: ''
//         }
//     ],
//     // Buyer & Seller
//     price: '',
//     // Buyer & Seller
//     primary_agent: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_broker: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     primary_client: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_opposing: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     prop_sub_type: '',
//     // Buyer & Seller
//     prop_type: 'resi', //comm
//     // Buyer & Seller
//     source: '',
//     // Buyer & Seller
//     status: 'active',
//     // Buyer & Seller
//     tasks: [],
//     // Buyer & Seller
//     team: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: '',
//             title: '',
//             type: ''
//         }
//     ],
//     // Buyer & Seller
//     team_ids: [],
//     // Buyer & Seller
//     type: ''
// };

// const test2 = {
//     active: true,
//     active_location_id: null,
//     active_org_id: null,

//     address: {
//         address_1: '',
//         address_2: '',
//         city: '',
//         latitude: '',
//         longitude: '',
//         state: '',
//         zip: ''
//     },
//     agent_lic: null,
//     broker_lic: null,
//     designation: null,
//     email: '',
//     facebook: null,
//     first_name: '',
//     id: '',
//     instagram: null,
//     language: 'english',
//     last_name: '',
//     linkedin: null,
//     orgs: null,
//     phone: [
//         {
//             number: '',
//             primary: null,
//             type: null
//         }
//     ],
//     role: 'user',
//     twitter: null,
//     type: 'client',
//     user_avatar: null,
//     website: null
// };

// const testjhjn = {
//     // Buyer & Seller
//     activity: [
//         {
//             archived_at: null,
//             attachments: [],
//             created_at: 'time_stamp',
//             creator_id: null,
//             creator_type: 'system',
//             edited_at: null,
//             first_name: 'Jada',
//             last_name: null,
//             message:
//                 'Congratulations on your new transaction!  Feel free to add documents and create tasks if you have not done so already.  We look forward to seeing everyone at the finish line!'
//         }
//     ],
//     // Buyer & Seller
//     address: {
//         address_1: '',
//         address_2: '',
//         city: '',
//         latitude: '',
//         longitude: '',
//         state: '',
//         zip: ''
//     },
//     // Buyer & Seller
//     avatars: [], //Microservice
//     // Buyer & Seller
//     closing_date: null,
//     // Buyer & Seller
//     created_at: 'timestamp',
//     // Buyer & Seller
//     display:
//         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fphotos%2Fimg22.jpg?alt=media&token=5dde32bd-ba64-4a83-bcb4-3d2a59bcdd0d',
//     // Buyer & Seller
//     documents: [
//         // {
//         //     created_at: 'timestamp',
//         //     edited_at: null,
//         //     id: 'vT0tSVYy6i5TVX4leBXa',
//         //     status: 'incomplete',
//         //     title: 'Purchase and Sale Pack',
//         //     type: 'library',
//         //     url:
//         //         'https://firebasestorage.googleapis.com/v0/b/jada-prototype.appspot.com/o/transactions%2FVhiju4PRLu9CnpPyLaFs%2Fdocuments%2Ftestpdf.pdf?alt=media&token=bc1a2a5a-2198-4de8-bec3-d650a1994205'
//         // }
//     ],
//     // Buyer & Seller
//     id: 'Vhiju4PRLu9CnpPyLaFs', // trx id after creation
//     // Buyer & Seller
//     income: {
//         commission: 3,
//         commission_percent: true,
//         split: 80,
//         split_percent: true,
//         trx_fee: 195
//     },
//     // Buyer & Seller
//     location_id: 1,
//     // Buyer possible
//     mls: '2238745',
//     // Buyer & Seller
//     notes: [],
//     // Buyer & Seller
//     opposing: [],
//     // Buyer & Seller
//     org_id: 'ZHUyhQi2KRHwK6cqeG2y',
//     // Buyer & Seller
//     parties: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: ''
//         }
//     ],
//     // Buyer & Seller
//     party_ids: [],
//     // Buyer & Seller
//     photos: [
//         {
//             created_at: 'timestamp',
//             source: 'upload',
//             url: ''
//         }
//     ],
//     // Buyer & Seller
//     price: '',
//     // Buyer & Seller
//     primary_agent: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_broker: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     primary_client: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     primary_opposing: {
//         email: '',
//         first_name: '',
//         id: '',
//         last_name: '',
//         phone: ''
//     },
//     // Buyer & Seller
//     prop_sub_type: '',
//     // Buyer & Seller
//     prop_type: 'resi', //comm
//     // Buyer & Seller
//     source: '',
//     // Buyer & Seller
//     status: 'active',
//     // Buyer & Seller
//     tasks: [],
//     // Buyer & Seller
//     team: [
//         {
//             email: '',
//             first_name: '',
//             id: '',
//             last_name: '',
//             phone: '',
//             title: '',
//             type: ''
//         }
//     ],
//     // Buyer & Seller
//     team_ids: [],
//     // Buyer & Seller
//     type: ''
// };
