// React
import React, { useRef } from 'react';

// Components
import { SelectField, TextField, ActionButtons } from '../../../components';

// Packages
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Label } from 'reactstrap';
import SimpleBar from 'simplebar-react';

// Utils
import { nameRegExp } from '../../../utils/Constants';

const NewMemberForm = ({
    handleClose,
    addingOrganizationMember,
    setMember,
    setFocusedState,
    lang,
    errorMessage,
    accountTypes,
    permissionTypes,
    brokered_by,
    members
}) => {
    const {
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        email_label,
        email_placeholder,
        account_type_label,
        account_type_placeholder,
        info_text,
        invite_button_label,
        cancel_invite_button_label,
        account_permissions_label,
        account_permissions_placeholder
    } = lang;

    const formRef = useRef();
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        permissions: '',
        type: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        firstName: Yup.string()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        lastName: Yup.string()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        permissions: Yup.string().required(errorMessage.permissions.required),
        type: Yup.string().required(errorMessage.accountType.required)
    });

    const validateForm = values => {
        if (members.length && members.indexOf(values['email']) > -1) {
            return { email: errorMessage.duplicateMember };
        }
        return {};
    };

    return (
        <>
            <div
                className="card-body"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '0',
                    paddingBottom: '0'
                }}
            >
                <SimpleBar style={{ overflowY: 'auto' }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validate={validateForm}
                        onSubmit={values => {
                            addingOrganizationMember({
                                members: [
                                    {
                                        ...values,
                                        email: values.email.trim().toLowerCase(),
                                        type: values.type,
                                        permissions: values.permissions,
                                        brokered_by:
                                            values.type.id === 'broker'
                                                ? null
                                                : brokered_by
                                    }
                                ]
                            });
                            setFocusedState('member_check_progress');
                            setMember({
                                ...values,
                                type: values.type,
                                permissions: values.permissions,
                                brokered_by:
                                    values.type.id === 'broker' ? null : brokered_by
                            });
                        }}
                        innerRef={formRef}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            dirty,
                            handleChange,
                            setFieldValue,
                            touched,
                            handleBlur,
                            setFieldTouched
                        }) => (
                            <Form className="w-md-50 mx-md-auto mb-4">
                                <div
                                    style={{
                                        animation: 'fadeIn .5s'
                                    }}
                                >
                                    <div className="text-center">
                                        <p>{info_text}</p>
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label
                                            className="form-label required-mark"
                                            for="firstName"
                                        >
                                            {first_name_label}
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            id="firstName"
                                            placeholder={first_name_placeholder}
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={e => {
                                                handleBlur(`firstName`);
                                                setFieldTouched(`firstName`, true);
                                                setFieldValue(
                                                    `firstName`,
                                                    e.target.value.trim()
                                                );
                                            }}
                                            error={errors.firstName}
                                            invalid={
                                                !!errors.firstName && touched.firstName
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label
                                            className="form-label required-mark"
                                            for="lastName"
                                        >
                                            {last_name_label}
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            id="lastName"
                                            placeholder={last_name_placeholder}
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={e => {
                                                handleBlur(`lastName`);
                                                setFieldTouched(`lastName`, true);
                                                setFieldValue(
                                                    `lastName`,
                                                    e.target.value.trim()
                                                );
                                            }}
                                            error={errors.lastName}
                                            invalid={
                                                !!errors.lastName && touched.lastName
                                            }
                                        />
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label
                                            className="form-label required-mark"
                                            for="email"
                                        >
                                            {email_label}
                                        </Label>
                                        <TextField
                                            required
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder={email_placeholder}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={e => {
                                                handleBlur(`email`);
                                                setFieldTouched(`email`, true);
                                                setFieldValue(
                                                    `email`,
                                                    e.target.value.trim()
                                                );
                                            }}
                                            error={errors.email}
                                            invalid={!!errors.email && touched.email}
                                        />
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label
                                            className="form-label required-mark"
                                            for="type"
                                        >
                                            {account_type_label}
                                        </Label>
                                        <SelectField
                                            formik={true}
                                            type="select"
                                            className="form-control p-0 border-0"
                                            classNamePrefix={`react-select ${
                                                !!errors.type && touched.type
                                                    ? 'danger'
                                                    : values.type !== '' && !errors.type
                                                    ? 'success'
                                                    : 'pristine'
                                            }`}
                                            placeholder={account_type_placeholder}
                                            required
                                            name="type"
                                            id="type"
                                            options={accountTypes}
                                            value={values.type}
                                            touched={touched.type}
                                            onBlur={() => {
                                                handleBlur(`type`);
                                                setFieldTouched(`type`, true);
                                            }}
                                            onChange={option => {
                                                handleChange(`type`);
                                                setFieldValue(`type`, option);
                                            }}
                                            error={errors.type}
                                            invalid={!!errors.type && touched.type}
                                        />
                                    </div>
                                    <div className="js-form-message form-group">
                                        <Label
                                            className="form-label required-mark"
                                            for="type"
                                        >
                                            {account_permissions_label}
                                        </Label>
                                        <SelectField
                                            formik={true}
                                            type="select"
                                            className="form-control p-0 border-0"
                                            classNamePrefix={`react-select ${
                                                !!errors.permissions &&
                                                touched.permissions
                                                    ? 'danger'
                                                    : values.permissions !== '' &&
                                                      !errors.permissions
                                                    ? 'success'
                                                    : 'pristine'
                                            }`}
                                            placeholder={account_permissions_placeholder}
                                            required
                                            name="permissions"
                                            id="permissions"
                                            options={permissionTypes}
                                            value={values.permissions}
                                            touched={touched.permissions}
                                            onBlur={() => {
                                                handleBlur(`permissions`);
                                                setFieldTouched(`permissions`, true);
                                            }}
                                            onChange={option => {
                                                handleChange(`permissions`);
                                                setFieldValue(`permissions`, option);
                                            }}
                                            error={errors.permissions}
                                            invalid={
                                                !!errors.permissions &&
                                                touched.permissions
                                            }
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </SimpleBar>
            </div>
            <ActionButtons
                handleSubmit={handleSubmit}
                handleClose={() => handleClose('team_list')}
                saveLabel={invite_button_label}
                cancelLabel={cancel_invite_button_label}
            />
        </>
    );
};

export default NewMemberForm;
