// React
import React, { useEffect, useState, useCallback } from 'react';

// Components
import { ContactRowSkeleton } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Firebase
import { storage } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

// Packages
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const ContactRow = ({
    contact: { first_name, last_name, address, phone, email, id },
    windowWidth
}) => {
    const [contactAvatar, setContactAvatar] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [usePlaceHolder, setUsePlaceHolder] = useState(false);
    const { address_1, address_2, city, state, zip } = address || {};
    const { button_label } = useLang()['CompanyContacts']['ContactRow'];

    const getContactAvatar = useCallback(id => {
        const ref = storage.ref().child(`users/${id}/avatar.jpg`);
        ref.getDownloadURL()
            .then(imgUrl => {
                setContactAvatar(imgUrl);
            })
            .catch(error => {
                setShowSkeleton(skeleton => !skeleton);
                setContactAvatar(null);
                log('Contact Avatar: error in getting contact avatar (FBS)', {
                    error,
                    storage_path: `users/${id}/avatar.jpg`,
                    function: 'getContactAvatar'
                });
            });
    }, []);

    const handleLoad = () => {
        setShowSkeleton(!showSkeleton);
    };

    const handleError = () => {
        setShowSkeleton(!showSkeleton);
        setUsePlaceHolder(!usePlaceHolder);
    };

    useEffect(() => {
        if (id && !contactAvatar) {
            getContactAvatar(id);
        } else if (!id) {
            setShowSkeleton(skeleton => !skeleton);
        }
    }, [id, contactAvatar, getContactAvatar]);

    return (
        <div className="card" style={{ maxHeight: '82px', height: '82px' }}>
            {showSkeleton && <ContactRowSkeleton />}
            <div className="card-body p-3" style={{ opacity: showSkeleton ? 0 : 1 }}>
                <div className="d-flex d-sm-flex align-items-center">
                    <div className="d-flex d-sm-flex align-items-center">
                        {contactAvatar ? (
                            <img
                                className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                                onLoad={handleLoad}
                                onError={handleError}
                                src={contactAvatar}
                                alt={`${first_name ? first_name : ''} ${
                                    last_name ? last_name : ''
                                }`}
                            />
                        ) : (
                            <Avatar
                                className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                                name={`${first_name ? first_name : ''} ${
                                    last_name ? last_name : ''
                                }`}
                                unstyled
                            />
                        )}
                    </div>
                    <div
                        className="d-flex d-sm-flex justify-content-start align-items-center"
                        style={{
                            flexGrow: 1,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        <div
                            className="col-3 align-items-center mr-3 mb-2 mb-sm-0 card-col-15"
                            style={{ textAlign: 'left' }}
                        >
                            <h2 className="h6 mb-0">{`${first_name ? first_name : ''} ${
                                last_name ? last_name : ''
                            }`}</h2>
                        </div>
                        {windowWidth >= 768 && (
                            <div className="col-3 mr-3 mb-3 mb-sm-0 card-col-15">
                                <span className="font-size-1">
                                    {address_1
                                        ? `${address_1}
                                            ${address_2 ? `#${address_2}` : ''}`
                                        : 'No Address'}
                                </span>
                                <small className="d-block text-muted">{`${
                                    city ? city : ''
                                }${state ? `, ${state}` : ''} ${zip ? zip : ''}`}</small>
                            </div>
                        )}
                        {windowWidth >= 992 && (
                            <div className="col-3">
                                {email && (
                                    <div className="mr-3 mb-3 mb-sm-0 card-col-15">
                                        <span className="far fa-envelope text-muted mr-2" />
                                        <a
                                            className="font-size-1"
                                            style={{ cursor: 'pointer' }}
                                            href={`mailto:${email}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {email}
                                        </a>
                                    </div>
                                )}
                                {phone && (
                                    <div className="mr-3 mb-3 mb-sm-0 card-col-15">
                                        <span className="fas fa-phone text-muted mr-2" />
                                        <a
                                            className="font-size-1"
                                            style={{ cursor: 'pointer' }}
                                            href={`tel:${phone}`}
                                        >
                                            <NumberFormat
                                                value={phone}
                                                displayType={'text'}
                                                format="###.###.####"
                                                mask="_"
                                            />
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="col-2 mr-3 card-col-5">
                            <Link
                                to={{
                                    pathname: `${routes.AUTHENTICATED}${routes.CONTACT_DETAIL}/${id}`,
                                    state: { id, imgUrl: contactAvatar }
                                }}
                                className={
                                    'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                                }
                            >
                                {button_label}
                                <span className="fas fa-angle-right ml-1" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactRow;
