export const ACCEPT_NEW_INVITATION = '/invitation-account-setup';
export const ACCEPT_INVITATION = '/accept-invitation';
export const ACCEPT_NEW_TRX_INVITATION = '/accept-new-team-invitation';
export const ACCEPT_TEAM_INVITATION = '/accept-team-invitation';
export const ADD_PARTY = '/add-party';
export const APP_INTEGRATIONS = '/app-integrations';
export const AUTHENTICATED = '/user';
export const AUTH_SERVICES = '/auth-service';
export const BILLING = '/billing';
export const BUYER = '/buyer';
export const COMPANY_CONTACTS = '/company-contacts';
export const CONTACTS = '/contacts';
export const CONTACT_DETAIL = '/contact-detail';
export const CONNECTIONS = '/connections';
export const CONNECTION_DETAIL = '/connection-detail';
export const DASHBOARD = '/dashboard';
export const DOCUMENTS = '/documents';
export const EDIT_TRANSACTION = '/edit-transaction';
export const EDIT_PACK = '/edit-pack';
export const EDIT_PACK_ID = '/edit-pack/:id';
export const EDIT_ORG_PACK = '/edit-pack/forms-library';
export const EDIT_ORG_PACK_ID = '/edit-pack/forms-library/:id';
export const EDIT_SYSTEM_PACK = '/edit-pack/system';
export const EDIT_SYSTEM_PACK_ID = '/edit-pack/system/:id';
export const FORM_FILLING = '/form-filling';
export const FORM_FILLING_ID = '/form-filling/:trxDocId';
export const FORM_SIGNING = '/form-signing';
export const FORM_SIGNING_ID = '/form-signing/:trxDocId';
export const INVITATION_CONFIRMATION = '/invitation-confirmation';
export const INVITATION_ERROR = '/invitation-error';
export const INVITATION_SERVICES = '/invitation-service';
export const LIBRARY = '/library';
export const LANDING = '/';
export const LISTING = '/listing';
export const LOGIN = '/login';
export const MESSAGES = '/messages';
export const MEMBER_TRANSACTIONS = '/member-transactions';
export const MEMBER_TRANSACTIONS_DETAILS = '/details';
export const NEW_CONNECTION = '/new-connection';
export const NEW_DOCUMENT = '/new-document';
export const NEW_PACK = '/new-pack';
export const NEW_ORG_DOCUMENT = '/new-document/forms-library';
export const NEW_ORG_PACK = '/new-pack/forms-library';
export const NEW_SYSTEM_DOCUMENT = '/new-document/forms-marketplace';
export const NEW_SYSTEM_PACK = '/new-pack/forms-marketplace';
export const NEW_PIPELINE = '/new-pipeline';
export const NEW_TRANSACTION = '/new-transaction';
export const NOT_FOUND = '/not-found';
export const PERSONAL_INFO = '/personal-info';
export const PIPELINE = '/pipeline';
export const PIPELINE_DETAIL = '/pipeline-detail';
export const PITCH = '/pitch';
export const PREFERENCES = '/preferences';
export const PROFILE = '/profile';
export const ORGANIZATION = '/organization';
export const ORGANIZATION_INFO = '/organization-info';
export const ORGANIZATION_MEMBERS = '/organization-members';
export const RESET_PASSWORD = '/reset-password';
export const SECURITY_SETTINGS = '/security-settings';
export const SETTINGS = '/settings';
export const TEAM = '/teams';
export const TRANSACTION_DETAIL = '/transaction-detail';
export const MEMBER_TRANSACTION_DETAIL = '/member-transaction-detail';
export const TRANSACTIONS = '/transactions';
export const VIEW_DOCUMENT = '/view-document';
export const VIEW_DOCUMENT_ID = '/view-document/:id';
export const VIEW_DOCUMENT_TRX_ID = '/view-document/:trxId/:id';
export const FORM_SIGNING_SERVICE = '/form-signing-service';
