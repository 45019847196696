// React
import React, { useCallback, useEffect, useState } from 'react';

// Packages
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { updatePreferenceUserLanguages } from '../../../store/actions/Preferences';

// Context
import { useLang } from '../../../context/LangContext';

// Utils
import { languages } from '../../../utils/Constants';
import { capitalizeFirstLetter, checkChanges } from '../../../utils/Helpers';

// Components
import ActionButtons from '../shared/ActionButtons';

const Languages = ({ updatePreferenceUserLanguages, userData, setHasChanges }) => {
    const { tips, default_status } = useLang()['Settings']['Preferences']['Languages'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];

    const userLanguages = userData ? userData.languages.slice() : [];

    const foundInitiallyUnchecked =
        userLanguages &&
        languages.filter(item => userLanguages.every(lang => item.id !== lang.id));

    const targetLangList = foundInitiallyUnchecked
        ? userLanguages.concat(
              foundInitiallyUnchecked.map(lang => ({ ...lang, checked: false }))
          )
        : userLanguages;

    const initialValues = targetLangList.map((lang, index) => {
        return { ...lang, default: index === 0 };
    });

    const [languagesList, setLanguagesList] = useState(initialValues || []);
    const [isDisabled, setIsDisabled] = useState(true);

    const checkDisableButtons = useCallback(() => {
        const languagesChanged = checkChanges(initialValues, languagesList);
        setIsDisabled(!languagesChanged);
    }, [initialValues, languagesList]);

    const handleReset = () => {
        setLanguagesList(initialValues);
    };

    useEffect(() => {
        languagesList[0].checked = true;
    }, [languagesList]);

    useEffect(() => {
        checkDisableButtons();
    }, [checkDisableButtons]);

    useEffect(() => {
        setHasChanges(!isDisabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled]);

    useEffect(() => {
        return handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = useCallback(
        position => {
            if (typeof position === 'number') {
                languagesList[position].checked = !languagesList[position].checked;
                setLanguagesList(languagesList.slice());
            } else {
                languagesList.forEach((lang, index) => {
                    lang.default = !index;
                });
                setLanguagesList(languagesList);
                checkDisableButtons();
            }
        },
        [checkDisableButtons, languagesList]
    );

    useEffect(() => {
        handleOnChange();
    }, [handleOnChange]);

    const handleDrop = droppedItem => {
        if (!droppedItem.destination) return;
        const updatedList = [...languagesList];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedList[0].checked = true;
        setLanguagesList(updatedList);
    };

    return (
        <>
            <div
                className="card-body"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '80%'
                }}
            >
                <div
                    className="d-flex flex-column align-items-center"
                    style={{
                        maxWidth: '600px',
                        margin: '0 auto'
                    }}
                >
                    <div className="pt-5 text-center">
                        <p className="text-wrap">{tips?.tip1}</p>
                    </div>
                    <div
                        className="pt-3 pb-lg-5 text-center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline'
                        }}
                    >
                        <i className="fa-solid fa-grip-lines lang_drag" />
                        <p className="text-wrap">{tips?.tip2}</p>
                    </div>
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container" direction="horizontal">
                            {provided => (
                                <div
                                    className="d-flex flex-wrap"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {languagesList.map((lang, index) => (
                                        <div
                                            className="col"
                                            key={lang.id}
                                            style={{ flexGrow: '0' }}
                                        >
                                            <Draggable
                                                key={lang.name}
                                                draggableId={lang.name}
                                                index={index}
                                                onChange={() => {}}
                                            >
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.dragHandleProps}
                                                        {...provided.draggableProps}
                                                    >
                                                        <form
                                                            style={{
                                                                width: 'max-content'
                                                            }}
                                                            action=""
                                                            className="d-flex align-content-start justify-content-start"
                                                        >
                                                            <i className="fa-solid fa-grip-lines lang_drag" />
                                                            <div className="form-group-check-lang">
                                                                <input
                                                                    type="checkbox"
                                                                    id={lang.id}
                                                                    name={lang.name}
                                                                    value={lang.name}
                                                                    checked={
                                                                        !!lang.checked
                                                                    }
                                                                    onChange={() =>
                                                                        handleOnChange(
                                                                            index
                                                                        )
                                                                    }
                                                                    disabled={index === 0}
                                                                />

                                                                {index === 0 ? (
                                                                    <label
                                                                        htmlFor={lang.id}
                                                                    >
                                                                        {capitalizeFirstLetter(
                                                                            lang.name
                                                                        )}
                                                                        <span
                                                                            style={{
                                                                                marginLeft:
                                                                                    '7px',
                                                                                color: '#97a4af'
                                                                            }}
                                                                        >
                                                                            {
                                                                                default_status
                                                                            }
                                                                        </span>
                                                                    </label>
                                                                ) : (
                                                                    <label
                                                                        htmlFor={lang.id}
                                                                    >
                                                                        {capitalizeFirstLetter(
                                                                            lang.name
                                                                        )}
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </form>
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            <ActionButtons
                saveLabel={save_label}
                cancelLabel={cancel_label}
                helperText={tips?.tip3}
                handleSubmit={() =>
                    updatePreferenceUserLanguages({ itemList: languagesList, userData })
                }
                handleClose={() => handleReset()}
                disabled={isDisabled}
                noCancel={isDisabled}
            />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData, type } = user;
    return { userData, type };
};
export default connect(mapStateToProps, {
    updatePreferenceUserLanguages
})(Languages);
