// React
import React, { useState } from 'react';
import { connect } from 'react-redux';

// Components
import TextField from '../common/TextField';
import { Button, FormFeedback } from 'reactstrap';

// Formik
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { legalNameRegExp, signerTypes } from '../../utils/Constants';

import { useLang } from '../../context/LangContext';
import { removeSignerFromDocument } from '../../store/actions/DocumentFilling';
import SelectField from '../common/SelectField';
import { generateUid } from '../../utils/Helpers';

const SignersStep = ({
    opposingAgent,
    setFieldValue,
    removeSignerFromDocument,
    setSuccessHandler,
    parties,
    team
}) => {
    const {
        forward_question,
        forward_label,
        addSigner_label,
        saveButton_label,
        signer_info,
        legalName_placeholder,
        email_placeholder,
        type_placeholder,
        signerTypes: signerTypesTranslations
    } = useLang()['Documents']['FillingDocument']['SetupFilling'];
    const { errorMessage } = useLang()['Constants'];
    const [isFormOpen, setIsFormOpen] = useState(false);

    const signerTypeSelectOptions = [
        { value: signerTypes.party, label: signerTypesTranslations.party },
        {
            value: signerTypes.sales_associate,
            label: signerTypesTranslations.sales_associate
        },
        { value: signerTypes.broker, label: signerTypesTranslations.broker },
        { value: signerTypes.other, label: signerTypesTranslations.other }
    ];

    const getEnabledOptions = formValues => [
        {
            value: signerTypes.party,
            label: signerTypesTranslations.party,
            isDisabled: formValues.filter(el => el.type === signerTypes.party).length >= 4
        },
        {
            value: signerTypes.sales_associate,
            label: signerTypesTranslations.sales_associate,
            isDisabled:
                formValues.filter(el => el.type === signerTypes.sales_associate).length >=
                2
        },
        {
            value: signerTypes.broker,
            label: signerTypesTranslations.broker,
            isDisabled:
                formValues.filter(el => el.type === signerTypes.broker).length >= 2
        },
        { value: signerTypes.other, label: signerTypesTranslations.other }
    ];

    const removeItemHandler = (email, id, callback) => {
        setSuccessHandler(() => callback);
        removeSignerFromDocument({ signerEmail: email, signerId: id });
    };

    const validationSchema = values =>
        Yup.object().shape({
            legalName: Yup.string()
                .matches(legalNameRegExp.format, errorMessage.fullName.valid)
                .required(errorMessage.fullName.required),
            email: Yup.string()
                .lowercase()
                .email(errorMessage.email.valid)
                .required(errorMessage.email.required),

            type: Yup.string().required(errorMessage.signerType.required)
        });

    const addItemHandler = (values, callback, existedValues) => {
        const { legalName, email, type } = values;
        const id =
            (existedValues.some(el => el.email === email)
                ? null
                : [...parties, ...team].find(el => email === el.email)?.id) ||
            generateUid();
        setIsFormOpen(false);
        callback({ legalName, email, id, type });
    };

    return (
        <div
            className="d-flex flex-column align-items-center mt-4"
            style={{ marginBottom: '100px' }}
        >
            <FieldArray
                name="confirmSigners"
                render={({ form, push, remove }) => (
                    <>
                        {form.values.confirmSigners?.map(
                            ({ legalName, email, id, type }, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={index} className="row mt-2">
                                    <div
                                        style={{ margin: '0 10px', width: '80px' }}
                                        className="align-self-center"
                                    >
                                        {
                                            signerTypeSelectOptions.find(
                                                el => el.value === type
                                            )?.label
                                        }
                                    </div>

                                    <TextField
                                        type="text"
                                        required
                                        className="form-control"
                                        parentStyle={{ margin: '0 10px' }}
                                        name={`confirmSigners.${index}.legalName`}
                                        id={`confirmSigners.${index}.legalName`}
                                        value={legalName}
                                        error={
                                            form.errors.confirmSigners &&
                                            form.errors.confirmSigners[index]?.legalName
                                        }
                                        onChange={e => {
                                            form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        invalid={
                                            form.errors.confirmSigners &&
                                            form.errors.confirmSigners[index]
                                                ?.legalName &&
                                            form.touched.confirmSigners &&
                                            form.touched.confirmSigners[index]?.legalName
                                        }
                                        hasDefaultFill
                                    />

                                    <TextField
                                        type="text"
                                        required
                                        className="form-control"
                                        parentStyle={{ margin: '0 10px' }}
                                        name={`confirmSigners.${index}.email`}
                                        id={`confirmSigners.${index}.email`}
                                        value={email}
                                        error={
                                            form.errors.confirmSigners &&
                                            form.errors.confirmSigners[index]?.email
                                        }
                                        onChange={e => {
                                            form.handleChange(e);
                                        }}
                                        onBlur={form.handleBlur}
                                        invalid={
                                            form.errors.confirmSigners &&
                                            form.errors.confirmSigners[index]?.email &&
                                            form.touched.confirmSigners &&
                                            form.touched.confirmSigners[index]?.email
                                        }
                                        hasDefaultFill
                                    />

                                    <i
                                        className="fa-regular fa-xmark pointer align-self-center"
                                        onClick={() =>
                                            removeItemHandler(email, id, () => {
                                                remove(index);
                                            })
                                        }
                                    />
                                </div>
                            )
                        )}
                        <FormFeedback
                            style={{
                                display: form.errors?.confirmSigners ? 'block' : 'none',
                                width: 'auto'
                            }}
                        >
                            {form.errors?.confirmSigners}
                        </FormFeedback>

                        {isFormOpen ? (
                            <Formik
                                initialValues={{
                                    legalName: '',
                                    email: '',
                                    type: ''
                                }}
                                validationSchema={() => validationSchema(form.values)}
                                onSubmit={values =>
                                    addItemHandler(
                                        values,
                                        push,
                                        form.values.confirmSigners
                                    )
                                }
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldTouched,
                                    setFieldValue
                                }) => (
                                    <div
                                        className="row mt-2 px-4 pt-4 pb-2 d-flex flex-column"
                                        style={{
                                            backgroundColor: '#e7eaf3',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <div className="row">
                                            <div
                                                style={{
                                                    margin: '0 10px',
                                                    width: '110px'
                                                }}
                                            >
                                                <SelectField
                                                    type="select"
                                                    name="type"
                                                    id="type"
                                                    placeholder={type_placeholder}
                                                    options={getEnabledOptions(
                                                        form.values.confirmSigners
                                                    )}
                                                    value={signerTypeSelectOptions.find(
                                                        el => el.value === values.type
                                                    )}
                                                    error={errors.type}
                                                    onChange={option =>
                                                        setFieldValue(
                                                            'type',
                                                            option.value
                                                        )
                                                    }
                                                    onBlur={() => {
                                                        handleBlur(`type`);
                                                        setFieldTouched(`type`, true);
                                                    }}
                                                    invalid={errors.type && touched.type}
                                                    touched={touched.type}
                                                    formik
                                                />
                                            </div>
                                            <TextField
                                                type="text"
                                                required
                                                className="form-control"
                                                parentStyle={{ margin: '0 10px' }}
                                                placeholder={legalName_placeholder}
                                                name="legalName"
                                                id="legalName"
                                                value={values.legalName}
                                                error={errors.legalName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={
                                                    errors.legalName && touched.legalName
                                                }
                                                hasDefaultFill
                                            />

                                            <TextField
                                                type="text"
                                                required
                                                className="form-control"
                                                parentStyle={{ margin: '0 10px' }}
                                                placeholder={email_placeholder}
                                                name="email"
                                                id="email"
                                                value={values.email}
                                                error={errors.email}
                                                onChange={handleChange}
                                                onBlur={e => {
                                                    handleBlur(`email`);
                                                    setFieldTouched(`email`, true);
                                                    setFieldValue(
                                                        `email`,
                                                        e.target.value
                                                            .toLowerCase()
                                                            .trim()
                                                    );
                                                }}
                                                invalid={errors.email && touched.email}
                                                hasDefaultFill
                                            />
                                        </div>
                                        <div className="mt-3 d-flex justify-content-between align-items-center">
                                            <p className="m-0">{signer_info}</p>
                                            <Button
                                                style={{ padding: '5px 20px' }}
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                {saveButton_label}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Formik>
                        ) : (
                            <div
                                className="d-flex align-items-center mt-2 pointer"
                                style={{
                                    width: 'fit-content'
                                }}
                                onClick={() => setIsFormOpen(true)}
                            >
                                <i
                                    className="fa-regular fa-circle-plus"
                                    style={{
                                        color: 'rgb(55, 125, 255)',
                                        marginRight: '4px'
                                    }}
                                />
                                <span className="pointer text-primary">
                                    {addSigner_label}
                                </span>
                            </div>
                        )}
                    </>
                )}
            />

            {opposingAgent && (
                <>
                    <div className="mt-4" style={{ fontWeight: 500 }}>
                        {forward_question}
                    </div>
                    <div className="custom-control custom-checkbox mb-2">
                        <input
                            type="checkbox"
                            id="forwardDocument"
                            name="forwardDocument"
                            className="custom-control-input"
                            onChange={e =>
                                setFieldValue('forwardDocument', e.target.checked)
                            }
                        />
                        <label className="custom-control-label" htmlFor="forwardDocument">
                            {forward_label.concat(opposingAgent)}
                        </label>
                    </div>
                </>
            )}
        </div>
    );
};

export default connect(null, { removeSignerFromDocument })(SignersStep);
