// React
import React, { useRef } from 'react';

// Assets
import AddFile from '../../assets/svg/AddFile.svg';

// Components
import { TextField, CreatableSelectField, DatePicker } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useMountEffect } from '../../hooks/useMountEffect';

// Packages
import { Label } from 'reactstrap';

const BuyingFlowStep2 = ({
    setStep,
    isSubmitting,
    checkError,
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    checkTouched,
    touched,
    sources
}) => {
    const closingContainerRef = useRef();
    const closingContainer = closingContainerRef.current;
    const resetTouched = () => {
        setFieldTouched(`trxMeta[closingDate]`, false);
        setFieldTouched(`trxMeta[listingPrice]`, false);
        setFieldTouched(`trxMeta[businessSrc]`, false);
    };
    const busSrc = sources.length
        ? sources.map(src => {
              return { label: src.toUpperCase(), value: src.toUpperCase() };
          })
        : [];
    const {
        closing_date_label,
        closing_date_placeholder,
        purchase_price_label,
        purchase_price_placeholder,
        offer_price_label,
        offer_price_placeholder,
        business_src_label,
        business_src_placeholder,
        upload_img_label,
        drag_drop_label,
        max_size_label,
        img_optional_label,
        back_button_label,
        next_button_label
    } = useLang()['NewTransaction']['BuyerFlowStep2'];
    const { locale } = useLang()['DateTime'];
    useMountEffect(resetTouched);

    return (
        <div
            id="contentStepForm"
            className="card-body p-5 w-lg-75 mx-lg-auto"
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <div className="row">
                <div className="col-6 mb-2">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">
                                {purchase_price_label}
                                <span className="text-danger ml-1">*</span>
                            </Label>

                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!checkError(
                                                    errors,
                                                    null,
                                                    `listingPrice`
                                                ) &&
                                                checkTouched(
                                                    touched,
                                                    null,
                                                    `listingPrice`
                                                )
                                                    ? '#de4437'
                                                    : values.trxMeta.listingPrice
                                                    ? 'rgba(0, 201, 167, 0.5)'
                                                    : ''
                                        }}
                                    >
                                        <span className="fas fa-dollar-sign" />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="trxMeta.listingPrice"
                                    id="trxMeta.listingPrice"
                                    placeholder={purchase_price_placeholder}
                                    aria-label={purchase_price_placeholder}
                                    error={checkError(errors, null, 'listingPrice')}
                                    value={values.trxMeta.listingPrice}
                                    onBlur={() => {
                                        handleBlur('listingPrice');
                                        setFieldTouched(`trxMeta[listingPrice]`, true);
                                    }}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    invalid={
                                        !!checkError(errors, null, 'listingPrice') &&
                                        checkTouched(touched, null, 'listingPrice')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-2">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">{offer_price_label}</Label>

                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!checkError(
                                                    errors,
                                                    null,
                                                    `offerPrice`
                                                ) &&
                                                checkTouched(touched, null, `offerPrice`)
                                                    ? '#de4437'
                                                    : values.trxMeta.offerPrice
                                                    ? 'rgba(0, 201, 167, 0.5)'
                                                    : ''
                                        }}
                                    >
                                        <span className="fas fa-dollar-sign" />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="trxMeta.offerPrice"
                                    id="trxMeta.offerPrice"
                                    placeholder={offer_price_placeholder}
                                    aria-label={offer_price_placeholder}
                                    error={checkError(errors, null, 'offerPrice')}
                                    value={values.trxMeta.offerPrice}
                                    onBlur={() => {
                                        handleBlur('offerPrice');
                                        setFieldTouched(`trxMeta[offerPrice]`, true);
                                    }}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    invalid={
                                        !!checkError(errors, null, 'offerPrice') &&
                                        checkTouched(touched, null, 'offerPrice')
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 mb-6">
                    <label className="form-label">{closing_date_label}</label>
                    <div
                        ref={closingContainerRef}
                        id="datepickerWrapperFrom"
                        className="js-focus-state u-datepicker input-group"
                    >
                        <div
                            className="input-group-prepend"
                            style={{
                                maxHeight: 50
                            }}
                        >
                            <span
                                id="calendarFromLabel"
                                className="input-group-text"
                                style={{
                                    borderColor:
                                        !!checkError(errors, null, `closingDate`) &&
                                        checkTouched(touched, null, `closingDate`)
                                            ? '#de4437'
                                            : values.trxMeta.closingDate
                                            ? 'rgba(0, 201, 167, 0.5)'
                                            : ''
                                }}
                            >
                                <span className="fas fa-calendar" />
                            </span>
                        </div>
                        {closingContainer && (
                            <DatePicker
                                myStyle={{
                                    flexGrow: 1
                                }}
                                required
                                placeholder={closing_date_placeholder}
                                className="js-range-datepicker form-control bg-white rounded-right input-corners"
                                name="trxMeta.closingDate"
                                id="trxMeta.closingDate"
                                aria-label="Closing Date"
                                error={checkError(errors, null, 'closingDate')}
                                value={values.trxMeta.closingDate}
                                onBlur={() => {
                                    handleBlur(`closingDate`);
                                }}
                                touched={checkTouched(touched, null, 'closingDate')}
                                invalid={
                                    !!checkError(errors, null, 'closingDate') &&
                                    checkTouched(touched, null, 'closingDate')
                                }
                                options={{
                                    appendTo: closingContainer,
                                    enableTime: true,
                                    dateFormat: 'm-d-Y h:i K',
                                    disableMobile: 'true',
                                    onChange([date]) {
                                        setFieldValue('trxMeta[closingDate]', date);
                                    },
                                    onClose() {
                                        setFieldTouched(`trxMeta[closingDate]`, true);
                                    },
                                    locale: locale
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="col-6 mb-2">
                    <Label className="form-label">
                        {business_src_label}
                        <span className="text-danger ml-1">*</span>
                    </Label>
                    <CreatableSelectField
                        type="text"
                        className="custom-edit-select p-0"
                        classNamePrefix={`react-select ${
                            !!checkError(errors, null, 'businessSrc') &&
                            checkTouched(touched, null, 'businessSrc')
                                ? 'danger'
                                : values.trxMeta.businessSrc !== '' &&
                                  !checkError(errors, null, 'businessSrc')
                                ? 'success'
                                : 'pristine'
                        }`}
                        name="trxMeta.businessSrc"
                        id="trxMeta.businessSrc"
                        placeholder={business_src_placeholder}
                        aria-label={business_src_label}
                        error={checkError(errors, null, 'businessSrc')}
                        value={values.trxMeta.businessSrc}
                        options={busSrc}
                        onBlur={() => {
                            handleBlur('businessSrc');
                        }}
                        onChange={option => {
                            handleChange('businessSrc');
                            setFieldValue('trxMeta[businessSrc]', option);
                        }}
                        onCreateOption={option => {
                            handleChange('businessSrc');
                            setFieldValue('trxMeta[businessSrc]', {
                                value: option,
                                label: option
                            });
                        }}
                        touched={checkTouched(touched, null, 'businessSrc')}
                        invalid={
                            !!checkError(errors, null, 'businessSrc') &&
                            checkTouched(touched, null, 'businessSrc')
                        }
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-7">
                    <div className="border-bottom pb-3 mb-5">
                        <h2 className="h6 text-secondary mb-0">{upload_img_label}</h2>
                    </div>
                    <div className="u-dropzone mb-3">
                        <div className="dz-message">
                            <img
                                className="js-svg-injector max-width-10 mb-3"
                                src={AddFile}
                                alt="Add File"
                                data-parent="#uploadForm"
                            />
                            <span className="d-block mb-2">{drag_drop_label}</span>
                            <small className="d-block text-muted">{max_size_label}</small>
                        </div>
                    </div>
                    <small className="form-text text-muted">{img_optional_label}</small>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button
                    className="btn btn-sm btn-soft-secondary transition-3d-hover mr-1"
                    onClick={() => setStep(1)}
                >
                    {back_button_label}
                </button>
                <button
                    type="submit"
                    className="btn btn-sm btn-primary transition-3d-hover"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                >
                    {next_button_label}
                </button>
            </div>
        </div>
    );
};

export default BuyingFlowStep2;
