// React
import React, { useEffect } from 'react';

// Constants
import { confirmationDialogTypes } from '../utils/Constants';

// Components
import { NewAddHeader, ConfirmationDialog, Loader } from '../components';

// Packages
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { storingPipelineContacts } from '../store/actions/Pipeline';

// Router
import * as routes from '../router/config/routes';
import NewPipelineForm from '../components/Pipeline/NewPipelineForm';

const NewPipeline = ({ loading }) => {
    const history = useHistory();

    useEffect(() => {
        if (!loading) return <Loader />;
    }, [loading]);

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.uploadContact}
                successHandler={() => history.goBack()}
            />
            <NewAddHeader
                returnRoute={`${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.PIPELINE}`}
            />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div className="container col d-flex">
                        <NewPipelineForm
                            storeContact={storingPipelineContacts}
                            history={history}
                        />
                    </div>
                </div>
            </main>
        </>
    );
};
const mapStateToProps = ({ pipeline }) => {
    const { loading, error } = pipeline;
    return {
        loading,
        error
    };
};

export default withRouter(connect(mapStateToProps)(NewPipeline));
