// React
import React from 'react';

// Components
import { DateTimePicker, TextField, Loader } from '../../components';
import MultiSelect, { Option, MultiValue, ValueContainer } from './MultiSelect';

// Context
import { useLang } from '../../context/LangContext';

//Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EditTaskModal = ({
    toggleEdit,
    editModal,
    taskTitle,
    taskTeam,
    taskAssignees,
    setTaskAssignees,
    setTaskTitle,
    taskContent,
    setTaskContent,
    taskDate,
    setTaskDate,
    taskStatus,
    selectedTaskHold,
    updateTask,
    deleteTask,
    loading
}) => {
    const complete = taskStatus && taskStatus === 'complete' ? true : false;
    const {
        title_placeholder,
        description_placeholder,
        date_time_placeholder,
        open_button_label,
        complete_button_label,
        delete_button_label,
        update_button_label
    } = useLang()['Common']['EditTaskModal'];
    const { locale } = useLang()['DateTime'];

    const teamMembers = taskTeam
        .filter((el, i, arr) => arr.findIndex(t => t.id === el.id) === i)
        .map(member => ({
            value: member.id,
            label: `${member.first_name} ${member.last_name}`
        }));

    const pristine = () => {
        if (selectedTaskHold && taskTitle)
            return (
                selectedTaskHold.title === taskTitle &&
                selectedTaskHold.content === taskContent &&
                selectedTaskHold.date_time?.toDate().toString().trim() ===
                    taskDate?.toString().trim() &&
                selectedTaskHold.status === taskStatus &&
                JSON.stringify(selectedTaskHold.assignees) ===
                    JSON.stringify(taskAssignees)
            );
    };

    const handleStatusChange = () => {
        const status = complete ? { data: 'open' } : { data: 'complete' };
        updateTask(status);
    };

    const onCancel = () => {
        toggleEdit({ reset: false, cancel: true });
    };

    const handleSelect = selected => {
        setTaskAssignees(selected);
    };

    return (
        <div>
            <Modal isOpen={editModal} toggle={toggleEdit} className="add-task-modal">
                <ModalHeader toggle={onCancel} className="text-break">
                    {taskTitle}
                </ModalHeader>
                <ModalBody>
                    <div className="row pl-3 pr-3 mt-3">
                        <div className="col-12 mb-0 mt-1">
                            <div className="js-form-message form-group">
                                <TextField
                                    type="text"
                                    className="form-control"
                                    placeholder={title_placeholder}
                                    aria-label={title_placeholder}
                                    value={taskTitle}
                                    onChange={e => setTaskTitle(e.target.value)}
                                    nonFormik={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row pl-3 pr-3">
                        <div className="col-12 mb-0 mt-1">
                            <div className="js-form-message form-group">
                                <TextField
                                    type="textarea"
                                    rows="4"
                                    className="form-control textarea-scroll"
                                    placeholder={description_placeholder}
                                    aria-label={description_placeholder}
                                    value={taskContent}
                                    onChange={e => setTaskContent(e.target.value)}
                                    nonFormik={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row pl-3 pr-3">
                        <div className="col-12 mb-0 mt-1 align-items-center">
                            <div className="js-form-message form-group">
                                <MultiSelect
                                    options={teamMembers}
                                    isMulti
                                    editable={true}
                                    placeholder="Assign to"
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    styles="taskModal"
                                    components={{
                                        Option,
                                        MultiValue,
                                        ValueContainer
                                    }}
                                    onChange={handleSelect}
                                    value={taskAssignees}
                                    defaultValue={taskAssignees}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row pl-3 pr-3">
                        <div className="col-8 mb-0 mt-1">
                            <div className="js-form-message form-group">
                                <DateTimePicker
                                    myStyle={{
                                        flexGrow: 1
                                    }}
                                    required
                                    placeholder={date_time_placeholder}
                                    className="js-range-datepicker form-control bg-white rounded input-corners"
                                    name="trxMeta.fromDate"
                                    id="trxMeta.fromDate"
                                    aria-label={date_time_placeholder}
                                    value={taskDate}
                                    options={{
                                        enableTime: true,
                                        dateFormat: 'm/d/Y h:i K',
                                        disableMobile: 'true',
                                        onChange([date]) {
                                            setTaskDate(date);
                                        },
                                        locale: locale
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row pl-3 pr-3">
                        <div className="col-12 mb-0 mt-1">
                            <Button
                                style={{
                                    width: '100%',
                                    backgroundColor: complete
                                        ? '#377dff'
                                        : 'rgba(65, 216, 120, 1)',
                                    borderColor: complete
                                        ? '#377dff'
                                        : 'rgba(65, 216, 120, 1)',
                                    color: 'white'
                                }}
                                onClick={handleStatusChange}
                            >
                                {complete ? (
                                    <>
                                        {open_button_label}
                                        <i className="fas fa-tasks ml-3" />
                                    </>
                                ) : (
                                    <>
                                        {complete_button_label}
                                        <i className="fas fa-check ml-3" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="col-12 mb-0 mt-1 justify-content-between">
                    <button className="btn btn-link text-danger" onClick={deleteTask}>
                        {delete_button_label}
                    </button>
                    <Button
                        className="mr-3"
                        color="primary"
                        disabled={pristine()}
                        onClick={updateTask}
                    >
                        {update_button_label}
                    </Button>
                </ModalFooter>
                {loading && <Loader />}
            </Modal>
        </div>
    );
};

export default EditTaskModal;
