import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { Loader } from '../components';

import * as routes from '../router/config/routes';

import { connect } from 'react-redux';
import {
    validateTrxInvitation,
    acceptTrxInvitation
} from '../store/actions/TransactionInvitations';
import { setConfirmModalType } from '../store/actions/Modal';

const TransactionInvitationService = ({
    authUser,
    validateTrxInvitation,
    isUserExisted,
    isInvitationChecked,
    isValid,
    userData,
    setConfirmModalType,
    trxInvitationDetails,
    acceptTrxInvitation,
    isAccepted
}) => {
    const history = useHistory();
    const location = useLocation();
    const query = useMemo(
        () => new URLSearchParams(location.search) || null,
        [location.search]
    );

    useEffect(() => {
        if (location && query && !trxInvitationDetails && !isAccepted) {
            const inviteData = {
                userId: query.get('user_id'),
                trxId: query.get('trx_id'),
                email: query.get('email'),
                inviteCode: query.get('invite_code')
            };
            validateTrxInvitation(inviteData);
        }
    }, [trxInvitationDetails, location, query, validateTrxInvitation, isAccepted]);

    useEffect(() => {
        if (isAccepted) {
            history.push({
                pathname: routes.AUTHENTICATED
            });
        }
    }, [isAccepted, history]);

    useEffect(() => {
        if (isInvitationChecked && trxInvitationDetails) {
            if (authUser) {
                if (isValid) {
                    if (authUser.email === trxInvitationDetails.email) {
                        acceptTrxInvitation(trxInvitationDetails);
                    } else {
                        history.push({
                            pathname: routes.INVITATION_ERROR,
                            state: { isTransactionInvitation: true }
                        });
                    }
                } else {
                    history.push({
                        pathname: routes.INVITATION_ERROR,
                        state: { isTransactionInvitation: true }
                    });
                }
            } else if (isUserExisted) {
                history.push({
                    pathname: routes.LOGIN
                });
            } else {
                history.push({
                    pathname: routes.ACCEPT_NEW_TRX_INVITATION
                });
            }
        }
    }, [
        setConfirmModalType,
        isInvitationChecked,
        authUser,
        history,
        isValid,
        isUserExisted,
        trxInvitationDetails,
        acceptTrxInvitation,
        userData,
        isAccepted
    ]);

    return <Loader />;
};

const mapStateToProps = ({ auth, trxInvitation, user }) => {
    const { authUser } = auth;
    const { userData } = user;
    const {
        trxInvitationDetails,
        isInvitationChecked,
        isValid,
        isUserExisted,
        isAccepted
    } = trxInvitation;
    return {
        authUser,
        isInvitationChecked,
        isUserExisted,
        isValid,
        userData,
        trxInvitationDetails,
        isAccepted
    };
};

export default withRouter(
    connect(mapStateToProps, {
        validateTrxInvitation,
        setConfirmModalType,
        acceptTrxInvitation
    })(TransactionInvitationService)
);
