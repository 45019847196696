import {
    VALIDATE_SIGNING_REQUEST,
    VALIDATE_SIGNING_REQUEST_ERROR,
    SET_SIGNING_REQUEST_VALIDITY
} from '../actions/types';

const INITIAL_STATE = {
    isRequestChecked: false,
    isUserExisted: null,
    error: null,
    isValid: '',
    emailMatch: '',
    requestDetails: null,
    isAccepted: false
};

const documentSigningReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_SIGNING_REQUEST:
            return {
                ...state,
                requestDetails: action.payload
            };
        case VALIDATE_SIGNING_REQUEST_ERROR:
            return {
                ...state,
                isValid: false,
                isRequestChecked: true,
                error: action.payload
            };
        case SET_SIGNING_REQUEST_VALIDITY:
            return {
                ...state,
                isRequestChecked: true,
                isUserExisted: action.payload?.isUserExisted,
                isValid: action.payload?.isValid,
                emailMatch: action.payload?.emailMatch
            };
        default:
            return state;
    }
};

export default documentSigningReducer;
