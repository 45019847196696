// React
import React, { useEffect, useCallback, useState, useMemo } from 'react';

// Components
import {
    ActionButtons,
    ConfirmationDialog,
    EditTrxDetailsFormContent,
    DetailsHeader,
    Loader,
    TabNavigation,
    PartiesFields,
    EditMappingsFormContent,
    EditTrxPropertyFormContent
} from '../components';
import { Formik } from 'formik';
import SimpleBar from 'simplebar-react';
import { FieldArray } from 'formik';

// Packages
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import * as routes from '../router/config/routes';

// Redux - Actions, Reducers, Sagas
import {
    gettingTransactionDetails,
    updateTransactionDetails,
    updateTransactionStatus
} from '../store/actions/Transactions';
import { setConfirmModalType } from '../store/actions/Modal';

// Constants
import {
    confirmationDialogTypes,
    headerTypes,
    legalNameRegExp,
    nameRegExp
} from '../utils/Constants';

// Context
import { useLang } from '../context/LangContext';

// Utils
import { getSources } from '../utils/Helpers';

const editTransactionTabs = {
    property: 'property',
    details: 'details',
    parties: 'parties',
    importedData: 'importedData'
};

const EditTransaction = ({
    location,
    transactionDetails,
    gettingTransactionDetails,
    userData,
    activeOrg,
    setConfirmModalType,
    updateTransactionDetails,
    modalDialogType,
    updateTransactionStatus
}) => {
    const history = useHistory();
    const { details_tab, parties_tab, importedData_tab, property_tab } =
        useLang()['TransactionDetails']['EditTransactionDetails'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { errorMessage } = useLang()['Constants'];

    const [tabContent, setTabContent] = useState(editTransactionTabs.details);
    const transaction = transactionDetails;
    const [shouldConfirmDisplayed, setShouldConfirmDisplayed] = useState(false);

    const sources = useMemo(() => {
        if (userData && activeOrg) {
            const prefPath = userData.preferences[userData.active_org_id];
            const userSrc = prefPath.sources;
            const orgSrc = activeOrg.preferences.sources;
            const sources = getSources(userSrc, orgSrc);

            return sources.length
                ? sources.map(src => ({
                      label: src.toUpperCase(),
                      value: src
                  }))
                : [];
        }
    }, [userData, activeOrg]);

    const tabs = [
        {
            name: property_tab,
            value: editTransactionTabs.property,
            id: 'pills-one-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-one-example2'
        },
        {
            name: details_tab,
            value: editTransactionTabs.details,
            id: 'pills-two-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-one-example2'
        },
        {
            name: parties_tab,
            value: editTransactionTabs.parties,
            id: 'pills-three-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-two-example2'
        },
        {
            name: importedData_tab,
            value: editTransactionTabs.importedData,
            id: 'pills-four-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-three-example2'
        }
    ];

    const getTrxId = useCallback(() => {
        const pathname = window.location.pathname;
        if (transactionDetails) return;
        if (location.state?.id) {
            if (!transactionDetails) {
                gettingTransactionDetails({
                    trxId: location.state.id,
                    detailsFlag: false
                });
            }
            return location.state.id;
        } else {
            const id = pathname.substr(pathname.lastIndexOf('/') + 1);
            gettingTransactionDetails({ trxId: id, detailsFlag: false });
            return id;
        }
    }, [transactionDetails, gettingTransactionDetails, location]);

    useEffect(() => {
        getTrxId();
    }, [getTrxId]);

    const backToTransactionDetails = () => {
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${transaction.id}`,
            state: { id: transaction.id }
        });
    };

    const cancelButtonClickHandler = () => {
        shouldConfirmDisplayed
            ? setConfirmModalType(confirmationDialogTypes.cancel)
            : backToTransactionDetails();
    };

    const initialValues = {
        closing_date: transactionDetails?.closing_date
            ? transactionDetails.closing_date.toDate()
            : '',
        price: transactionDetails?.price || '',
        offer_price: transactionDetails?.offer_price || '',
        businessSrc: {
            value: transactionDetails?.source.replace(/['"]+/g, ''),
            label: transactionDetails?.source.replace(/['"]+/g, '')
        },
        commission: transactionDetails?.income?.commission,
        commissionPercent: transactionDetails?.income?.commission_percent,
        split: transactionDetails?.income?.split,
        splitPercent: transactionDetails?.income?.split_percent,
        fee: transactionDetails?.income?.trx_fee,
        feePercent: transactionDetails?.income?.trx_fee_percent,

        mlsNumber: transactionDetails?.mls || '',
        address1: transactionDetails?.address?.address_1 || '',
        address2: transactionDetails?.address?.address_2 || '',
        city: transactionDetails?.address?.city || '',
        state: transactionDetails?.address?.state || '',
        zip: transactionDetails?.address?.zip || '',
        lat: transactionDetails?.address?.latitude || '',
        lon: transactionDetails?.address?.longitude || '',
        fullAddress: transactionDetails?.mappings?.property_address || '',

        parties: [
            {
                id: transactionDetails?.primary_client?.id,
                client_first_name: transactionDetails?.primary_client?.first_name || '',
                client_last_name: transactionDetails?.primary_client?.last_name || '',
                client_full_name: transactionDetails?.primary_client?.legal_name || ''
            },
            ...(transactionDetails?.parties
                ?.filter(item => item.id !== transactionDetails?.primary_client?.id)
                .map(item => ({
                    id: item.id,
                    client_first_name: item.first_name || '',
                    client_last_name: item.last_name || '',
                    client_full_name: item.legal_name || ''
                })) || [])
        ],
        mappings: transactionDetails?.mappings
    };

    const checkIsAddressEdited = values => {
        return (
            values.address1 !== initialValues.address1 ||
            values.address2 !== initialValues.address2 ||
            values.city !== initialValues.city ||
            values.state !== initialValues.state ||
            values.zip !== initialValues.zip ||
            values.lat !== initialValues.lat ||
            values.lon !== initialValues.lon
        );
    };

    const checkIsMlsEdited = values => values.mlsNumber !== initialValues.mlsNumber;

    const validationSchema = Yup.object().shape({
        price: Yup.string().required(errorMessage.listingPrice.required),
        closing_date: Yup.string(),
        businessSrc: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string().required(errorMessage.businessSrc.required)
        }),
        commission: Yup.number()
            .required(errorMessage.commission.required)
            .min(0, errorMessage.commission.min)
            .when('commissionPercent', {
                is: true,
                then: Yup.number().max(100, errorMessage.commission.maxPercent),
                otherwise: Yup.number().test(
                    'max',
                    errorMessage.commission.max,
                    function (value) {
                        return value < this.parent.price;
                    }
                )
            }),
        commissionPercent: Yup.bool().required(),
        split: Yup.number()
            .required(errorMessage.split.required)
            .min(0, errorMessage.split.min)
            .when('splitPercent', {
                is: true,
                then: Yup.number().max(100, errorMessage.split.maxPercent),
                otherwise: Yup.number().test(
                    'max',
                    errorMessage.split.max,
                    function (value) {
                        return value < this.parent.price;
                    }
                )
            }),
        splitPercent: Yup.bool().required(),
        fee: Yup.number()
            .required(errorMessage.fee.required)
            .min(0, errorMessage.fee.min)
            .when('feePercent', {
                is: true,
                then: Yup.number().max(100, errorMessage.fee.maxPercent),
                otherwise: Yup.number().test(
                    'max',
                    errorMessage.fee.max,
                    function (value) {
                        return value < this.parent.price;
                    }
                )
            }),
        feePercent: Yup.bool().required(),

        mlsNumber: Yup.number(),
        address1: Yup.string().trim(),
        address2: Yup.string().trim(),
        city: Yup.string().trim(),
        state: Yup.string().trim(),
        zip: Yup.string().trim(),
        lat: Yup.number(),
        lon: Yup.number(),
        address: Yup.string().trim(),

        parties: Yup.array().of(
            Yup.object().shape({
                client_first_name: Yup.string()
                    .matches(nameRegExp.format, errorMessage.firstName.valid)
                    .required(errorMessage.firstName.required),
                client_last_name: Yup.string()
                    .matches(nameRegExp.format, errorMessage.lastName.valid)
                    .required(errorMessage.firstName.required),
                client_full_name: Yup.string().matches(
                    legalNameRegExp.format,
                    errorMessage.fullName.valid
                )
            })
        )
    });

    if (!transaction) return <Loader />;
    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={modalDialogType}
                confirmHandler={backToTransactionDetails}
                successHandler={backToTransactionDetails}
            />
            <DetailsHeader
                transaction={transaction}
                userData={userData}
                headerType={headerTypes.editTransaction}
                cancelButtonClickHandler={cancelButtonClickHandler}
                updateTransactionStatus={updateTransactionStatus}
            />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div
                        id="main"
                        className="size container pt-3 pt-xl-4 mb-4"
                        style={{ overflowY: 'hidden' }}
                    >
                        <div
                            className="card"
                            style={{
                                minHeight: '80vh',
                                height: '100%'
                            }}
                        >
                            <TabNavigation
                                onClick={setTabContent}
                                selectedTab={tabContent}
                                tabs={tabs}
                            />
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                enableReinitialize
                                onSubmit={values => {
                                    updateTransactionDetails({
                                        trxDetails: transactionDetails,
                                        userData,
                                        values,
                                        isAddressEdited: checkIsAddressEdited(values),
                                        isMlsEdited: checkIsMlsEdited(values)
                                    });
                                }}
                            >
                                {props => (
                                    <>
                                        <div
                                            className="card-body"
                                            style={{
                                                position: 'relative',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '0'
                                            }}
                                        >
                                            <SimpleBar
                                                style={{
                                                    height: '100%',
                                                    overflow: 'hidden auto',
                                                    margin: '20px 0 10px'
                                                }}
                                            >
                                                {tabContent ===
                                                    editTransactionTabs.property && (
                                                    <EditTrxPropertyFormContent
                                                        {...props}
                                                        setShouldConfirmDisplayed={
                                                            setShouldConfirmDisplayed
                                                        }
                                                        transactionDetails={
                                                            transactionDetails
                                                        }
                                                    />
                                                )}
                                                {tabContent ===
                                                    editTransactionTabs.details && (
                                                    <EditTrxDetailsFormContent
                                                        {...props}
                                                        sources={sources}
                                                        setShouldConfirmDisplayed={
                                                            setShouldConfirmDisplayed
                                                        }
                                                        transactionDetails={
                                                            transactionDetails
                                                        }
                                                    />
                                                )}
                                                {tabContent ===
                                                    editTransactionTabs.parties && (
                                                    <FieldArray
                                                        name="parties"
                                                        render={({ form }) => (
                                                            <PartiesFields
                                                                form={form}
                                                                parties={
                                                                    transactionDetails?.parties
                                                                }
                                                                transactionId={
                                                                    transactionDetails?.id
                                                                }
                                                                setShouldConfirmDisplayed={
                                                                    setShouldConfirmDisplayed
                                                                }
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {tabContent ===
                                                    editTransactionTabs.importedData && (
                                                    <EditMappingsFormContent
                                                        {...props}
                                                        setShouldConfirmDisplayed={
                                                            setShouldConfirmDisplayed
                                                        }
                                                        transactionDetails={
                                                            transactionDetails
                                                        }
                                                    />
                                                )}
                                            </SimpleBar>
                                        </div>
                                        <ActionButtons
                                            handleSubmit={props.handleSubmit}
                                            handleClose={cancelButtonClickHandler}
                                            cancelLabel={cancel_label}
                                            saveLabel={save_label}
                                            disabled={!shouldConfirmDisplayed}
                                        />
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ transactions, user, modal, org }) => {
    const { userData } = user;
    const { transactionDetails } = transactions;
    const { modalDialogType } = modal;
    const { activeOrg } = org;
    return {
        transactionDetails,
        userData,
        modalDialogType,
        activeOrg
    };
};

export default withRouter(
    connect(mapStateToProps, {
        gettingTransactionDetails,
        setConfirmModalType,
        updateTransactionDetails,
        updateTransactionStatus
    })(EditTransaction)
);
