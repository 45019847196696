// React
import React from 'react';

// Components
import { TextField, ImmutableSelect } from '../../components';

// Packages
import { Label } from 'reactstrap';
import cx from 'classnames';

const AcceptStep1 = ({
    setStep,
    lang,
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    isSubmitting,
    longEnough,
    hasUppercase,
    hasNumber,
    hasSpecial
}) => {
    return (
        <div
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.first_name_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder={lang?.first_name_placeholder}
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        error={errors.firstName}
                        invalid={touched.firstName && !!errors.firstName}
                    />
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.last_name_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder={lang?.last_name_placeholder}
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        error={errors.lastName}
                        invalid={touched.lastName && !!errors.lastName}
                    />
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.email_label}
                    </Label>
                    <TextField
                        disabled
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder={lang?.email_placeholder}
                        value={values.email}
                        onChange={handleChange('email')}
                    />
                    <p className="small mt-1">{lang?.email_helper_text}</p>
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.account_type_label}
                    </Label>
                    <ImmutableSelect
                        disabled
                        type="text"
                        className="form-control"
                        name="accountType"
                        id="accountType"
                        placeholder={lang?.account_type_placeholder}
                        value={values.accountType}
                        onChange={handleChange('accountType')}
                    />
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.phone_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder={lang?.phone_placeholder}
                        value={values.phone}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                        error={errors.phone}
                        invalid={touched.phone && !!errors.phone}
                    />
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.password_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="off"
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder={lang?.password_placeholder}
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        error={errors.password}
                        invalid={touched.password && !!errors.password}
                    />
                </div>
            </div>
            <div className="offset-6 col-6 requirements mt-1">
                <div
                    className={cx(
                        'row d-flex align-items-center mb-1 pl-3 requirements__item',
                        longEnough && 'requirements__item--completed'
                    )}
                >
                    <i className="fa-solid fa-check requirements__check-icon" />
                    <p className="mb-0 pl-2 requirements__item-text">
                        {lang?.length_helper_text}
                    </p>
                </div>
                <div
                    className={cx(
                        'row d-flex align-items-center mb-1 pl-3 requirements__item',
                        hasUppercase && 'requirements__item--completed'
                    )}
                >
                    <i className="fa-solid fa-check requirements__check-icon" />
                    <p className="mb-0 pl-2 requirements__item-text">
                        {lang?.uppercase_helper_text}
                    </p>
                </div>
                <div
                    className={cx(
                        'row d-flex align-items-center mb-1 pl-3 requirements__item',
                        hasNumber && 'requirements__item--completed'
                    )}
                >
                    <i className="fa-solid fa-check requirements__check-icon" />
                    <p className="mb-0 pl-2 requirements__item-text">
                        {lang?.number_helper_text}
                    </p>
                </div>
                <div
                    className={cx(
                        'row d-flex align-items-center mb-1 pl-3 requirements__item',
                        hasSpecial && 'requirements__item--completed'
                    )}
                >
                    <i className="fa-solid fa-check requirements__check-icon" />
                    <p className="mb-0 pl-2 requirements__item-text">
                        {lang?.symbol_helper_text}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AcceptStep1;
