// import React, { useState, useCallback, useEffect } from 'react';
// import { AppHeader, AddTaskModal, RemoveTaskModal } from '../components';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';

// // Hooks
// import { useWindowSize } from '../hooks/useWindowSize';

// // Packages
// import SimpleBar from 'simplebar-react';

// // Actions Creators
// import { removePreferenceTask, addPreferenceTask } from '../store/actions/Preferences';

// // Router
// // import * as routes from '../router/config/routes';

// const Preferences = props => {
//     const [scrollHeight, setScrollHeight] = useState(0);
//     const [buyerTasks, setBuyerTasks] = useState();
//     const [sellerTasks, setSellerTasks] = useState();
//     const [addModal, setAddModal] = useState(false);
//     const [removeModal, setRemoveModal] = useState(false);
//     const [selectedTask, setSelectedTask] = useState();
//     const { windowHeight, windowWidth } = useWindowSize();
//     const { userData, removePreferenceTask, loading, addPreferenceTask } = props;

//     const measuredRef = useCallback(
//         node => {
//             if (node?.getBoundingClientRect()?.top && windowHeight) {
//                 setScrollHeight(windowHeight - node.getBoundingClientRect().top);
//             }
//         },
//         [windowHeight]
//     );

//     useEffect(() => {
//         if (userData) {
//             const prefPath = userData.preferences[userData.active_org_id];
//             setBuyerTasks(prefPathinitial_tasks.buyer);
//             setSellerTasks(prefPath.initial_tasks.listing);
//         }
//     }, [userData]);

//     useEffect(() => {
//         if (!loading) {
//             if (addModal) setAddModal(!addModal);
//             if (removeModal) setRemoveModal(!removeModal);
//         }
//     }, [loading]);

//     const toggleAdd = () => {
//         setAddModal(!addModal);
//     };

//     const toggleRemove = task => {
//         if (task) {
//             setSelectedTask(task);
//             setRemoveModal(!removeModal);
//         } else {
//             setRemoveModal(!removeModal);
//         }
//     };

//     const removeTask = () => {
//         const tasks = selectedTask.type === 'buyer' ? [...buyerTasks] : [...sellerTasks];
//         const filteredTasks = [...tasks].filter(
//             task => task.title !== selectedTask.title
//         );
//         removePreferenceTask({ tasks: filteredTasks, type: selectedTask.type });
//     };

//     const addTask = task => {
//         const expireTime = {
//             days: parseInt(task.days),
//             hours:
//                 task.ordinal === 'AM' ? parseInt(task.hours) : parseInt(task.hours) + 12,
//             mins: parseInt(task.mins),
//             ordinal: task.ordinal
//         };
//         const formattedTask = {
//             content: task.description,
//             created_at: null,
//             created_by: userData.id,
//             date_time: null,
//             edited_at: null,
//             status: 'open',
//             title: task.title,
//             expiration: { ...expireTime }
//         };

//         const bTasks = task.buyer ? [...buyerTasks, formattedTask] : [...buyerTasks];
//         const lTasks = task.listing ? [...sellerTasks, formattedTask] : [...sellerTasks];

//         addPreferenceTask({ buyerTasks: bTasks, listingTasks: lTasks });
//     };

//     if (!userData) return null;

//     return (
//         <div style={{ maxHeight: windowHeight, overflow: 'hidden' }}>
//             <AddTaskModal toggleModal={toggleAdd} addModal={addModal} addTask={addTask} />
//             <RemoveTaskModal
//                 toggleModal={toggleRemove}
//                 removeModal={removeModal}
//                 selectedTask={selectedTask}
//                 removeTask={removeTask}
//                 loading={loading}
//             />
//             <AppHeader {...props} />
//             <main id="content" role="main" className="push-main-lg bg-light">
//                 <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
//                     <div ref={measuredRef}>
//                         <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
//                             <div className="container space-bottom-1 mt-5">
//                                 <div
//                                     className="row"
//                                     style={{ justifyContent: 'space-between' }}
//                                 >
//                                     <span className="d-block h2 mb-5">Tasks</span>
//                                     <button
//                                         className="btn btn-icon transition-3d-hover"
//                                         onClick={toggleAdd}
//                                     >
//                                         <span className="fas fa-plus text-primary btn-icon__inner" />
//                                     </button>
//                                 </div>
//                                 <div className="row">
//                                     <div className="col-6 mb-6">
//                                         <div className="border-bottom pb-3 mb-2">
//                                             <h2 className="h6 text-secondary mb-0">
//                                                 Buying
//                                             </h2>
//                                         </div>
//                                         {buyerTasks &&
//                                             buyerTasks.map(task => (
//                                                 <li
//                                                     className="custom-control"
//                                                     style={{
//                                                         display: 'flex',
//                                                         flexDirection: 'row',
//                                                         justifyContent: 'space-between',
//                                                         alignItems: 'center'
//                                                     }}
//                                                     key={task.title}
//                                                 >
//                                                     <span className="d-block">
//                                                         {task.title}
//                                                     </span>
//                                                     <button
//                                                         className="btn btn-icon transition-3d-hover"
//                                                         onClick={() =>
//                                                             toggleRemove({
//                                                                 ...task,
//                                                                 type: 'buyer'
//                                                             })
//                                                         }
//                                                     >
//                                                         <span className="fas fa-trash text-primary btn-icon__inner" />
//                                                     </button>
//                                                 </li>
//                                             ))}
//                                     </div>
//                                     <div className="col-6 mb-6">
//                                         <div className="border-bottom pb-3 mb-2">
//                                             <h2 className="h6 text-secondary mb-0">
//                                                 Listing
//                                             </h2>
//                                         </div>
//                                         {sellerTasks &&
//                                             sellerTasks.map(task => (
//                                                 <li
//                                                     className="custom-control"
//                                                     style={{
//                                                         display: 'flex',
//                                                         flexDirection: 'row',
//                                                         justifyContent: 'space-between',
//                                                         alignItems: 'center'
//                                                     }}
//                                                     key={task.title}
//                                                 >
//                                                     <span className="d-block">
//                                                         {task.title}
//                                                     </span>
//                                                     <button
//                                                         className="btn btn-icon transition-3d-hover"
//                                                         onClick={() =>
//                                                             toggleRemove({
//                                                                 ...task,
//                                                                 type: 'listing'
//                                                             })
//                                                         }
//                                                     >
//                                                         <span className="fas fa-trash text-primary btn-icon__inner" />
//                                                     </button>
//                                                 </li>
//                                             ))}
//                                     </div>
//                                 </div>
//                             </div>
//                         </SimpleBar>
//                     </div>
//                 </div>
//             </main>
//         </div>
//     );
// };

// const mapStateToProps = ({ user, pref }) => {
//     const { userData } = user;
//     const { loading } = pref;
//     return { userData, loading };
// };

// export default withRouter(
//     connect(mapStateToProps, { removePreferenceTask, addPreferenceTask })(Preferences)
// );

import React, { useState, useCallback, useEffect } from 'react';
import { AppHeader, AddTaskModal, RemoveTaskModal } from '../components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';

// Actions Creators
import { removePreferenceTask, addPreferenceTask } from '../store/actions/Preferences';

// Router
// import * as routes from '../router/config/routes';

const Preferences = props => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [buyerTasks, setBuyerTasks] = useState();
    const [sellerTasks, setSellerTasks] = useState();
    const [addModal, setAddModal] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [activeUpload, setActiveUpload] = useState(false);
    const [activeRemoval, setActiveRemoval] = useState(false);
    const [addPristine, setAddPristine] = useState(true);
    const [removePristine, setRemovePristine] = useState(true);
    const { windowHeight, windowWidth } = useWindowSize();
    const { userData, removePreferenceTask, loading, addPreferenceTask, uploadError } =
        props;

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const toggleControl = useCallback(({ task, reset, cancel, action }) => {
        if (action === 'add') {
            if (reset || cancel) {
                setActiveUpload(false);
                setAddModal(modal => !modal);
            } else {
                setAddModal(modal => !modal);
            }
        } else {
            if (reset || cancel) {
                setActiveRemoval(false);
                setRemoveModal(modal => !modal);
                setSelectedTask(null);
            } else {
                setSelectedTask(task);
                setRemoveModal(modal => !modal);
            }
        }
    }, []);

    const activateTaskModal = ({ action, task }) => {
        if (action === 'add') {
            setActiveUpload(active => !active);
            toggleControl({ reset: false, cancel: false, task: task, action: action });
        } else {
            setActiveRemoval(active => !active);
            toggleControl({ reset: false, cancel: false, task: task, action: action });
        }
    };

    const addTask = task => {
        setAddPristine(false);
        const expireTime = {
            days: parseInt(task.days),
            hours:
                task.ordinal === 'AM' ? parseInt(task.hours) : parseInt(task.hours) + 12,
            mins: parseInt(task.mins),
            ordinal: task.ordinal
        };
        const formattedTask = {
            content: task.description,
            created_at: null,
            created_by: userData.id,
            date_time: null,
            edited_at: null,
            status: 'open',
            title: task.title,
            expiration: { ...expireTime }
        };

        const bTasks = task.buyer ? [...buyerTasks, formattedTask] : [...buyerTasks];
        const lTasks = task.listing ? [...sellerTasks, formattedTask] : [...sellerTasks];

        addPreferenceTask({ buyerTasks: bTasks, listingTasks: lTasks });
    };

    const removeTask = () => {
        setRemovePristine(false);
        const tasks = selectedTask.type === 'buyer' ? [...buyerTasks] : [...sellerTasks];
        const filteredTasks = [...tasks].filter(
            task => task.title !== selectedTask.title
        );
        removePreferenceTask({ tasks: filteredTasks, type: selectedTask.type });
    };

    useEffect(() => {
        if (activeUpload) {
            if (!loading && !uploadError && !addPristine) {
                toggleControl({ reset: true, cancel: false, task: null, action: 'add' });
            } else if (loading) {
                // Do nothing waiting for work to finish
            } else if (uploadError) {
                console.log('notify user of error on add');
            }
        } else {
            setAddPristine(true);
        }
        if (activeRemoval) {
            if (!loading && !uploadError && !removePristine) {
                toggleControl({
                    reset: true,
                    cancel: false,
                    task: null,
                    action: 'remove'
                });
            } else if (loading) {
                // Do nothing waiting for work to finish
            } else if (uploadError) {
                console.log('notify user of error on remove');
            }
        } else {
            setRemovePristine(true);
        }
    }, [
        loading,
        uploadError,
        activeUpload,
        activeRemoval,
        addPristine,
        removePristine,
        toggleControl
    ]);

    useEffect(() => {
        if (userData) {
            const prefPath = userData.preferences[userData.active_org_id];
            setBuyerTasks(prefPath.initial_tasks.buyer);
            setSellerTasks(prefPath.initial_tasks.seller);
        }
    }, [userData]);

    if (!userData) return null;

    return (
        <div style={{ maxHeight: windowHeight, overflow: 'hidden' }}>
            <AddTaskModal
                toggleModal={toggleControl}
                addModal={addModal}
                addTask={addTask}
                loading={loading}
            />
            <RemoveTaskModal
                toggleModal={toggleControl}
                removeModal={removeModal}
                selectedTask={selectedTask}
                removeTask={removeTask}
                loading={loading}
            />
            <AppHeader {...props} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <div ref={measuredRef}>
                        <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                            <div className="container space-bottom-1 mt-5">
                                <div
                                    className="row"
                                    style={{ justifyContent: 'space-between' }}
                                >
                                    <span className="d-block h2 mb-5">Tasks</span>
                                    <button
                                        className="btn btn-icon transition-3d-hover"
                                        onClick={() =>
                                            activateTaskModal({
                                                action: 'add',
                                                task: null
                                            })
                                        }
                                    >
                                        <span className="fas fa-plus text-primary btn-icon__inner" />
                                    </button>
                                </div>
                                <div className="row">
                                    <div className="col-6 mb-6">
                                        <div className="border-bottom pb-3 mb-2">
                                            <h2 className="h6 text-secondary mb-0">
                                                Buying
                                            </h2>
                                        </div>
                                        {buyerTasks &&
                                            buyerTasks.map(task => (
                                                <li
                                                    className="custom-control"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                    key={task.title}
                                                >
                                                    <span className="d-block">
                                                        {task.title}
                                                    </span>
                                                    <button
                                                        className="btn btn-icon transition-3d-hover"
                                                        onClick={() =>
                                                            activateTaskModal({
                                                                action: 'remove',
                                                                task: {
                                                                    ...task,
                                                                    type: 'buyer'
                                                                }
                                                            })
                                                        }
                                                    >
                                                        <span className="fas fa-trash text-primary btn-icon__inner" />
                                                    </button>
                                                </li>
                                            ))}
                                    </div>
                                    <div className="col-6 mb-6">
                                        <div className="border-bottom pb-3 mb-2">
                                            <h2 className="h6 text-secondary mb-0">
                                                Listing
                                            </h2>
                                        </div>
                                        {sellerTasks &&
                                            sellerTasks.map(task => (
                                                <li
                                                    className="custom-control"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}
                                                    key={task.title}
                                                >
                                                    <span className="d-block">
                                                        {task.title}
                                                    </span>
                                                    <button
                                                        className="btn btn-icon transition-3d-hover"
                                                        onClick={() =>
                                                            activateTaskModal({
                                                                action: 'remove',
                                                                task: {
                                                                    ...task,
                                                                    type: 'listing'
                                                                }
                                                            })
                                                        }
                                                    >
                                                        <span className="fas fa-trash text-primary btn-icon__inner" />
                                                    </button>
                                                </li>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </main>
        </div>
    );
};

const mapStateToProps = ({ user, pref }) => {
    const { userData } = user;
    const { loading, uploadError } = pref;
    return { userData, loading, uploadError };
};

export default withRouter(
    connect(mapStateToProps, { removePreferenceTask, addPreferenceTask })(Preferences)
);
