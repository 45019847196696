import {
    VALIDATE_TRX_INVITATION_ERROR,
    SET_TRX_INVITATION_VALIDITY,
    VALIDATE_TRX_INVITATION,
    ACCEPT_TRX_INVITATION_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    isInvitationChecked: false,
    isUserExisted: null,
    error: null,
    isValid: '',
    emailMatch: '',
    trxInvitationDetails: null,
    isAccepted: false
};

const trxInvitationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_TRX_INVITATION:
            return {
                ...state,
                trxInvitationDetails: action.payload
            };
        case VALIDATE_TRX_INVITATION_ERROR:
            return {
                ...state,
                isValid: false,
                isInvitationChecked: true,
                error: action.payload
            };
        case SET_TRX_INVITATION_VALIDITY:
            return {
                ...state,
                isInvitationChecked: true,
                isUserExisted: action.payload?.isUserExisted,
                isValid: action.payload?.isValid,
                emailMatch: action.payload?.emailMatch
            };
        case ACCEPT_TRX_INVITATION_SUCCESS:
            return {
                ...INITIAL_STATE,
                isAccepted: true
            };
        default:
            return state;
    }
};

export default trxInvitationsReducer;
