import {
    SET_COMPANY_CONTACTS,
    SET_USER_COLLECTION,
    SET_CONTACTS_FILTER,
    ADD_NEW_CONTACTS,
    ADD_NEW_CONTACTS_SUCCESS,
    ADD_NEW_CONTACTS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    companyContacts: [],
    contactFilterType: {
        name: 'All Contacts',
        value: 'all'
    },
    memberFilterType: {
        name: 'All Members',
        value: 'all'
    },
    uploadError: null
};

const contactFilterTypes = {
    en: {
        all: {
            name: 'All Contacts',
            value: 'all'
        },
        active: {
            name: 'Active Contacts',
            value: 'active'
        },
        inactive: {
            name: 'Inactive Contacts',
            value: 'inactive'
        }
    },
    es: {
        all: {
            name: 'Todos Contactos',
            value: 'all'
        },
        active: {
            name: 'Contactos Activos',
            value: 'active'
        },
        inactive: {
            name: 'Contactos Inactivos',
            value: 'inactive'
        }
    }
};

const memberFilterTypes = {
    en: {
        all: {
            name: 'All Members',
            value: 'all'
        },
        active: {
            name: 'Active Members',
            value: 'active'
        },
        inactive: {
            name: 'Inactive Members',
            value: 'inactive'
        }
    },
    es: {
        all: {
            name: 'Todos Miembros',
            value: 'all'
        },
        active: {
            name: 'Miembros Activos',
            value: 'active'
        },
        inactive: {
            name: 'Miembros Inactivos',
            value: 'inactive'
        }
    }
};

const contactsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_COMPANY_CONTACTS:
            return {
                ...state,
                companyContacts: action.payload
            };
        case SET_USER_COLLECTION:
            return {
                ...state,
                contactFilterType:
                    contactFilterTypes[action.payload.active_language_id][
                        state.contactFilterType.value
                    ],
                memberFilterType:
                    memberFilterTypes[action.payload.active_language_id][
                        state.memberFilterType.value
                    ]
            };
        case SET_CONTACTS_FILTER:
            return {
                ...state,
                contactFilterType: action.payload,
                memberFilterType: action.payload
            };
        case ADD_NEW_CONTACTS:
            return {
                ...state,
                loading: true,
                uploadError: false
            };
        case ADD_NEW_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadError: false
            };
        case ADD_NEW_CONTACTS_FAILURE:
            return {
                ...state,
                loading: false,
                uploadError: true
            };
        default:
            return state;
    }
};

export default contactsReducer;
