// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import { PipelineSearchFilter, PipelineList, ConfirmationDialog } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { confirmationDialogTypes } from '../utils/Constants';

const Pipeline = ({ userData, loading, pipelineContacts }) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const [searchSuggestions, settingSearchSuggestions] = useState(null);
    const [sortedPipelineContacts, setSortedPipelineContacts] = useState(null);

    const history = useHistory();

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const pipelineBySort = useCallback(() => {
        const contacts = pipelineContacts;
        contacts.sort((a, b) => {
            if (a.last_name && a.first_name && b.last_name && b.first_name) {
                const result = a.last_name.localeCompare(b.last_name);
                return result !== 0 ? result : a.first_name.localeCompare(b.first_name);
            } else if (!a.last_name || !a.first_name) {
                return 1;
            } else if (!b.last_name || !b.first_name) {
                return -1;
            }
            return true;
        });
        return contacts;
    }, [pipelineContacts]);

    useEffect(() => {
        if (pipelineContacts && pipelineContacts.length) {
            setSortedPipelineContacts(pipelineBySort());
        }
    }, [pipelineContacts, pipelineBySort]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.uploadContact}
                successHandler={() => history.goBack()}
            />
            <PipelineSearchFilter
                loading={loading}
                settingSearchSuggestions={settingSearchSuggestions}
                contacts={sortedPipelineContacts}
                pipelineContacts={pipelineContacts}
                userId={userData?.id}
            />
            <div ref={measuredRef}>
                <SimpleBar style={{ height: scrollHeight, width: windowWidth }}>
                    <div className="container space-bottom-1">
                        <PipelineList
                            searchSuggestions={searchSuggestions}
                            contacts={sortedPipelineContacts}
                        />
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user, pipeline }) => {
    const { userData } = user;
    const { loading, pipelineContacts } = pipeline;
    return { userData, loading, pipelineContacts };
};

export default withRouter(connect(mapStateToProps, {})(Pipeline));
