import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import SimpleBar from 'simplebar-react';
import cx from 'classnames';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';
import { useLang } from '../../context/LangContext';

// Helpers
import { debounce } from 'lodash';
import { formatSigningDate, getElementSize } from '../../utils/Helpers';

const FormView = ({
    documentForFilling,
    dividedSortedAnnotations,
    activeInput,
    setActiveByClick,
    isInSigningMode,
    setNextEmptyInput,
    setInputValue,
    handleChecked,
    setActiveDocumentIndex,
    documentRefs,
    userId,
    signatures
}) => {
    const { start_label, next_label } =
        useLang()['Documents']['FillingDocument']['SigningDocument'];
    const { windowHeight } = useWindowSize();
    const activeInputRef = useRef(null);
    const activeInputPointerRef = useRef(null);
    const [activeInputId, setActiveInputId] = useState(null);
    const [fontSize, setFontSize] = useState(null);
    const containerRef = useRef(null);

    const handleScroll = () => {
        const containerPosition = containerRef.current.getBoundingClientRect();
        const currentSection = documentForFilling?.documents.findLastIndex((_, index) => {
            const element = documentRefs.current[index].current;
            if (element) {
                const { top } = element.getBoundingClientRect();
                return (
                    top - containerPosition.top - documentForFilling?.doc_height / 2 <= 0
                );
            }
            return false;
        });
        if (currentSection !== -1) setActiveDocumentIndex(currentSection);
    };

    useEffect(() => {
        const containerNode = containerRef.current;
        containerNode?.addEventListener('scroll', handleScroll);
        return () => containerNode?.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef]);

    useEffect(() => {
        activeInputRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
        setFontSize(
            activeInput?.item.inputs[0].recalculated_font_size ||
                activeInput?.item.inputs[0].font_size
        );
        setActiveInputId(activeInput?.item.inputs[0].id);
    }, [activeInput]);

    useEffect(() => {
        activeInputRef?.current?.focus();
    }, [activeInputId]);

    const handleEnterPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setNextEmptyInput('next');
        }
    };

    const renderSignatureField = annotation => {
        const currentSignature = signatures?.find(
            el => el.annotation_id === annotation.id
        );
        if (currentSignature && annotation.value && userId === annotation.value) {
            return (
                <div className="d-flex flex-column h-100">
                    <img
                        className="w-100 h-85"
                        src={
                            documentForFilling.signature_values.find(
                                el =>
                                    el.type === annotation.type &&
                                    el.id === annotation.value
                            )?.value
                        }
                        alt={annotation.type}
                        style={{ objectFit: 'contain' }}
                    />
                    <div
                        className="d-flex text-white certificate-data-label px-1"
                        style={{
                            fontSize: '9px'
                        }}
                    >
                        <span className="mr-2">
                            {formatSigningDate(currentSignature.signed_at.seconds)}
                        </span>
                        <span className="ellipsis">Jada: {currentSignature.cert_id}</span>
                    </div>
                </div>
            );
        }
    };

    const getChangeInputHandler = useCallback(
        annotations => {
            const { fontFamily, fontBold } = annotations[0];
            const sumInputWidth = annotations.reduce((sum, item) => sum + item.width, 0);
            return () => {
                const textWidth =
                    getElementSize(activeInputRef.current.value, {
                        fontFamily,
                        fontSize,
                        fontBold
                    }).width + 5;
                if (textWidth > sumInputWidth) {
                    let recalculatedFontSize = fontSize;
                    do {
                        recalculatedFontSize--;
                    } while (
                        getElementSize(activeInputRef.current.value, {
                            fontFamily,
                            fontSize: recalculatedFontSize,
                            fontBold
                        }).width > sumInputWidth
                    );
                    setFontSize(recalculatedFontSize);
                }
                setInputValue(activeInputRef.current.value);
            };
        },
        [fontSize, setInputValue]
    );

    const handleInputChange = useMemo(
        () => activeInput && getChangeInputHandler(activeInput?.item.inputs),
        [activeInput, getChangeInputHandler]
    );

    const renderTextArea = useCallback(
        annotations => {
            const annotation = annotations[0];
            if (annotations.length > 1) {
                let xCoordinate = annotations[0].coords.x,
                    yCoordinate = annotations[0].coords.y,
                    maxXCoordinate = annotations[0].coords.x + annotations[0].width,
                    polygon = [];
                annotations.forEach(el => {
                    if (el.coords.x < xCoordinate) xCoordinate = el.coords.x;
                    if (el.coords.y < yCoordinate) yCoordinate = el.coords.y;
                    if (maxXCoordinate < el.coords.x + el.width)
                        maxXCoordinate = el.coords.x + el.width;
                });
                const height =
                    annotations[annotations.length - 1].coords.y +
                    annotations[annotations.length - 1].height -
                    annotations[0].coords.y;
                annotations.forEach(el => {
                    polygon = [
                        ...polygon.splice(0, polygon.length / 2),
                        `${el.coords.x + el.width - xCoordinate}px ${
                            el.coords.y - yCoordinate
                        }px`, // right top corner
                        `${el.coords.x + el.width - xCoordinate}px ${
                            el.coords.y + el.height - yCoordinate
                        }px`, // right bottom corner
                        `${el.coords.x - xCoordinate}px ${
                            el.coords.y + el.height - yCoordinate
                        }px`, // left bottom corner
                        `${el.coords.x - xCoordinate}px ${el.coords.y - yCoordinate}px`, // left top corner
                        ...polygon.splice(-polygon.length / 2 - 1)
                    ];
                });
                const width = maxXCoordinate - xCoordinate;
                return (
                    <textarea
                        className="annotation-input pointer highlighted p-0 overflow-hidden"
                        ref={activeInputRef}
                        style={{
                            width: `${width}px`,
                            height: `${height}px`,
                            top: `${yCoordinate}px`,
                            left: `${xCoordinate}px`,
                            fontFamily: annotations[0].fontFamily,
                            fontSize: `${fontSize}px`,
                            fontWeight: annotations[0].fontBold ? 'bold' : 'normal',
                            lineHeight: `${height / annotations.length}px`,
                            clipPath: `polygon(${polygon.join(', ')})`,
                            textIndent: `${annotations[0].coords.x - xCoordinate}px`,
                            resize: 'none',
                            lineBreak: 'anywhere'
                        }}
                        rows={annotations.length}
                        key={annotation.id}
                        onChange={debounce(handleInputChange, 250)}
                        defaultValue={activeInput.item.inputs.reduce(
                            (content, item) => content.concat(item.value || ''),
                            ''
                        )}
                        onKeyPress={handleEnterPress}
                    />
                );
            } else {
                return (
                    <input
                        className="annotation-input pointer highlighted p-0"
                        ref={activeInputRef}
                        type={
                            activeInput?.item.inputs[0].type === 'checkbox'
                                ? 'checkbox'
                                : 'text'
                        }
                        name="text"
                        defaultValue={annotation.value}
                        defaultChecked={annotation.value}
                        key={annotation.id}
                        autoComplete="off"
                        style={{
                            width: `${annotation.width}px`,
                            height: `${annotation.height}px`,
                            top: `${annotation.coords?.y}px`,
                            left: `${annotation.coords?.x}px`,
                            fontFamily: annotation.fontFamily,
                            fontSize: `${fontSize}px`,
                            fontWeight: annotation.fontBold ? 'bold' : 'normal'
                        }}
                        onChange={debounce(
                            e =>
                                activeInput?.item.inputs[0].type === 'checkbox'
                                    ? handleChecked(annotation.id, e.target.checked)
                                    : handleInputChange(),
                            250
                        )}
                        onKeyPress={handleEnterPress}
                    />
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeInput, activeInputId, fontSize, handleChecked, handleInputChange]
    );

    const allPages = useMemo(
        () =>
            documentForFilling?.documents?.flatMap(({ pages }, index) =>
                pages.map(page => ({ ...page, documentIndex: index }))
            ),
        [documentForFilling]
    );

    return (
        <SimpleBar
            style={{
                height: [windowHeight] - 108 + 'px',
                width: '100%',
                overflowY: 'auto'
            }}
            scrollableNodeProps={{ ref: containerRef }}
            className="col-9"
        >
            {allPages.map((page, index) => (
                <div
                    key={`${page.documentIndex}_${page.page_number}`}
                    className="page-container"
                >
                    {page.page_number === 1 && (
                        <div ref={documentRefs.current[page.documentIndex]} />
                    )}
                    <div
                        className="page zoom-level-75"
                        style={{ color: isInSigningMode ? '#377DFF' : 'black' }}
                    >
                        {isInSigningMode &&
                            (activeInput?.page === index || (!activeInput && !index)) && (
                                <div
                                    className="inputPointer"
                                    onClick={() => setNextEmptyInput('next')}
                                    ref={activeInputPointerRef}
                                    style={{
                                        top: `${
                                            activeInput?.item.inputs[0].coords?.y -
                                                activeInput?.item.inputs[0].height / 2 ||
                                            50
                                        }px`
                                    }}
                                >
                                    {!activeInput ? start_label : next_label}
                                </div>
                            )}
                        <img className="doc-image" src={page.page_url} alt="TestDoc" />
                        {dividedSortedAnnotations &&
                            dividedSortedAnnotations[index]?.map((item, i) => {
                                const isCurrentActive =
                                    activeInput?.page === index &&
                                    activeInput?.indexOnPage === i;
                                const isSignatureFieldActive = [
                                    'signature',
                                    'initials'
                                ].includes(item?.inputs[0].type);

                                return isInSigningMode &&
                                    isCurrentActive &&
                                    !isSignatureFieldActive
                                    ? renderTextArea(item.inputs)
                                    : item.inputs.map(annotation => (
                                          <div
                                              className={cx('annotation-input pointer', {
                                                  highlighted: isCurrentActive,
                                                  required:
                                                      item.isRequired && !isInSigningMode,
                                                  checkbox:
                                                      annotation.type === 'checkbox' &&
                                                      annotation.value,
                                                  'form-input-bg': !isInSigningMode,
                                                  'dashed-border':
                                                      isCurrentActive &&
                                                      isSignatureFieldActive
                                              })}
                                              ref={
                                                  activeInput?.item.inputs.findIndex(
                                                      el => el.id === annotation.id
                                                  ) === 0
                                                      ? activeInputRef
                                                      : null
                                              }
                                              key={annotation.id}
                                              style={{
                                                  width: `${annotation.width}px`,
                                                  height: `${annotation.height}px`,
                                                  top: `${annotation.coords?.y}px`,
                                                  left: `${annotation.coords?.x}px`,
                                                  fontFamily: annotation.font_family,
                                                  fontSize: `${
                                                      annotation.recalculated_font_size ||
                                                      annotation.font_size
                                                  }px`,
                                                  fontWeight: annotation.font_bold
                                                      ? 'bold'
                                                      : 'normal'
                                              }}
                                              onClick={() =>
                                                  //TODO: add check if field is editable
                                                  setActiveByClick(item)
                                              }
                                          >
                                              {annotation.type === 'checkbox' &&
                                              annotation.value ? (
                                                  <i className="fa-solid fa-square-check" />
                                              ) : ['signature', 'initials'].includes(
                                                    annotation.type
                                                ) ? (
                                                  renderSignatureField(annotation)
                                              ) : (
                                                  annotation.value
                                              )}
                                          </div>
                                      ));
                            })}
                    </div>
                </div>
            ))}
        </SimpleBar>
    );
};
export default FormView;
