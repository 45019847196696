/////////////////////////////// App //////////////////////////////
export const TOGGLE_SIDE_NAV = 'toggle_side_nav';
export const ENABLING_NAV_BUTTON = 'enabling_nav_button';

////////////////////////////// Auth //////////////////////////////
export const CHECK_USER_AUTHENTICATED = 'check_user_authenticated';
export const USER_AUTHENTICATED_SUCCESS = 'user_authenticated_success';
export const USER_AUTHENTICATED_FAILURE = 'user_authenticated_failure';
export const LOGIN_USER_EMAIL_PASSWORD = 'login_user_email_password';
export const LOGIN_USER_FAILURE = 'login_user_failure';
export const LOGOUT_USER = 'logout_user';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';
export const CLEAR_AUTH_ERROR = 'clear_auth_error';
export const RESET_USER_PASSWORD = 'reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD = 'non_auth_reset_user_password';
export const NON_AUTH_RESET_USER_PASSWORD_SUCCESS =
    'non_auth_reset_user_password_success';
export const NON_AUTH_RESET_USER_PASSWORD_FAILURE =
    'non_auth_reset_user_password_failure';
export const RESET_PASSWORD_REQUEST_STATE = 'reset_password_request_state';
export const EXECUTE_RESET_PASSWORD_FLIGHT = 'execute_reset_password_flight';
export const VERIFY_ACTION_CODE = 'verify_action_code';
export const ACTION_CODE_VALID = 'action_code_valid';
export const ACTION_CODE_INVALID = 'action_code_invalid';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILURE = 'reset_password_failure';
export const INTERNAL_RESET_PASSWORD = 'internal_reset_password';
export const INTERNAL_RESET_PASSWORD_SUCCESS = 'internal_reset_password_success';
export const INTERNAL_RESET_PASSWORD_FAILURE = 'internal_reset_password_failure';

///////////////////////// Company Contacts //////////////////////////
export const GET_CONTACTS = 'get_contacts';
export const SET_COMPANY_CONTACTS = 'set_company_contacts';
export const SET_CONTACTS_FILTER = 'set_contacts_filter';
export const ADD_NEW_CONTACTS = 'add_new_contacts';
export const ADD_NEW_CONTACTS_SUCCESS = 'add_new_contacts_success';
export const ADD_NEW_CONTACTS_FAILURE = 'add_new_contacts_failure';
export const REMOVE_CONTACT = 'remove_contact';
export const SET_MEMBER_FILTER = 'set_member_filter';

//////////////////////// Connections ////////////////////////
export const SET_USER_CONNECTIONS = 'set_user_connections';
export const CHECK_CONNECTION_EXISTENCE = 'check_connection_existence';
export const STORE_CONNECTION_MATCHES = 'store_connection_matches';
export const SET_CONNECTION_MATCH = 'set_connection_match';
export const CLEAR_CONNECTION_LINK = 'clear_connection_link';
export const CLEAR_CONNECTION_PRIMARY = 'clear_connection_primary';
export const ADD_NEW_CONNECTION = 'add_new_connection';
export const ADD_NEW_CONNECTION_SUCCESS = 'add_new_connection_success';
export const ADD_NEW_CONNECTION_FAILURE = 'add_new_connection_failure';
export const RESET_NEW_CONNECTION_DATA = 'reset_new_connection_data';

///////////////////////// Documents //////////////////////////
export const GET_DOCUMENTS = 'get_documents';
export const SET_USER_DOCUMENTS = 'set_user_documents';
export const SET_ORG_DOCUMENTS = 'set_org_documents';
export const UPLOAD_STORAGE_DOCUMENT = 'upload_storage_document';
export const UPLOAD_STORAGE_DOCUMENT_SUCCESS = 'upload_storage_document_success';
export const UPLOAD_STORAGE_DOCUMENT_FAILURE = 'upload_storage_document_failure';
export const SET_DOCUMENT_FILTER = 'set_document_filter';
export const SET_DOC_SEARCH_SUGGESTIONS = 'set_doc_search_suggestions';
export const SET_DOCUMENT_LIST = 'set_document_list';
export const SET_LIBRARY_FILTER = 'set_library_filter';
export const SET_LIBRARY_LIST = 'set_library_list';
export const SET_SYSTEM_LIST = 'set_system_list';
export const SET_SYSTEM_FILTER = 'set_system_filter';
export const SET_PACK_FILTER = 'set_pack_filter';
export const UPDATE_DOCUMENT = 'update_document';
export const REMOVE_DOCUMENT = 'remove_document';
export const SAVE_DOCUMENTS_PACK = 'save_documents_pack';
export const REMOVE_DOCUMENTS_PACK = 'remove_documents_pack';
export const ADD_DOCUMENT_TO_TRANSACTION = 'add_document_to_transaction';
export const REMOVE_DOCUMENT_FROM_TRANSACTION = 'remove_document_from_transaction';
export const REFRESH_TRANSACTION_DOCUMENTS = 'refresh_transaction_documents';
export const SET_TRANSACTION_DOCUMENTS_FILTER = 'set_transaction_documents_filter';
export const SET_DOCUMENT_ACTIVE_TAB = 'set_document_active_tab';

///////////////////////// Document Filling //////////////////////////
export const GET_DOC_FOR_FILLING = 'get_doc_for_filling';
export const SET_DOC_FOR_FILLING = 'set_doc_for_filling';
export const SAVE_SETTINGS_FOR_FILLING = 'save_settings_for_filling';
export const SET_SETTINGS_FOR_FILLING = 'set_settings_for_filling';
export const SAVE_DOC_FOR_FILLING = 'save_doc_for_filling';
export const SAVE_SIGNATURE = 'save_signature';
export const SAVE_SIGNATURE_SUCCESS = 'save_signature_success';
export const ACCEPT_SIGNING_CONCENT = 'accept_signing_consent';
export const ACCEPT_SIGNING_CONCENT_SUCCESS = 'accept_signing_consent_success';
export const REMOVE_SIGNER_FROM_DOCUMENT = 'remove_signer_from_document';
export const REMOVE_SIGNER_FROM_DOCUMENT_SUCCESS = 'remove_signer_from_document_succes';
export const SEND_SIGNATURE_REQUEST = 'send_signature_request';

///////////////////////// Document Signing //////////////////////////
export const VALIDATE_SIGNING_REQUEST = 'validate_signing_request';
export const VALIDATE_SIGNING_REQUEST_ERROR = 'validate_signing_request_error';
export const SET_SIGNING_REQUEST_VALIDITY = 'set_signing_request_validity';

///////////////////////// Invitations /////////////////////////
export const VALIDATE_ORG_INVITATION = 'validate_org_invitation';
export const VALIDATE_ORG_INVITATION_ERROR = 'validate_org_invitation_error';
export const SET_INVITATION_VALIDITY = 'set_invitation_validity';
export const CONFIRM_EXISTING_USER_INVITATION = 'confirm_existing_user_invitation';
export const CONFIRM_EXISTING_USER_INVITATION_SUCCESS =
    'confirm_existing_user_invitation_success';
export const CONFIRM_EXISTING_USER_INVITATION_FAILURE =
    'confirm_existing_user_invitation_failure';
export const CONFIRM_NEW_USER_INVITATION = 'confirm_new_user_invitation';
export const CONFIRM_NEW_USER_INVITATION_SUCCESS = 'confirm_new_user_invitation_success';
export const CONFIRM_NEW_USER_INVITATION_FAILURE = 'confirm_new_user_invitation_failure';
export const PREPARE_NEW_USER_START = 'prepare_new_user_start';
export const NEW_USER_START_SUCCESS = 'new_user_start_success';
export const NEW_USER_START_FAILURE = 'new_user_start_failure';
export const DECLINE_NEW_USER_INVITATION = 'decline_new_user_invitation';
export const DECLINE_NEW_USER_INVITATION_SUCCESS = 'decline_new_user_invitation_success';
export const DECLINE_NEW_USER_INVITATION_FAILURE = 'decline_new_user_invitation_failure';

///////////////////////// Langauge //////////////////////////
export const SET_APP_LANGUAGE_TYPE = 'set_app_language_type';
export const SET_APP_LANGUAGE_TYPE_SUCCESS = 'set_app_language_type_success';
export const SET_APP_LANGUAGE_TYPE_FAILURE = 'set_app_language_type_failure';
export const SET_LANGUAGE_BY_LOCATION = 'set_langauga_by_location';
export const SET_APP_LANGUAGE_LOCAL = 'set_app_language_local';

//////////////////////////// Messages //////////////////////////////
export const GET_USER_MESSAGES = 'get_user_messages';
export const SET_USER_MESSAGES = 'set_user_messages';
export const SEND_USER_MESSAGE = 'send_user_message';
export const SEND_USER_THREAD_MESSAGE = 'send_user_thread_message';
export const REMOVE_USER_MESSAGE = 'remove_user_message';
export const SAVE_USER_MESSAGE = 'save_user_message';
export const REMOVE_SAVED_USER_MESSAGE = 'remove_saved_user_message';
export const DRAFT_USER_MESSAGE = 'draft_user_message';
export const REMOVE_USER_DRAFT_MESSAGE = 'remove_user_draft_message';
export const SEEN_USER_MESSAGE = 'seen_user_message';

//////////////////////////  Modal  ///////////////////////////
export const SET_MODAL_TYPE = 'set_modal_type';
export const MODAL_CONFIRM = 'modal_confirm';
export const MODAL_CANCEL = 'modal_cancel';

////////////////////////////// Orgs //////////////////////////////
export const GET_USER_ORG = 'get_user_org';
export const SET_USER_ORG = 'set_user_org';
export const UPDATE_ORG_LOCATION = 'update_org_location';
export const UPDATE_ORG_LOCATION_SUCCESS = 'update_org_location_success';
export const UPDATE_ORG_LOCATION_FAILURE = 'update_org_location_failure';
export const ADD_ORG_MEMBER = 'add_org_member';
export const PREPARE_MEMBER_INVITATIONS = 'prepare_member_invitations';
export const SET_PREPARED_INVITATIONS = 'set_prepared_invitations';
export const ORG_INVITE_SUCCESS = 'org_invite_success';
export const ORG_INVITE_FAILURE = 'org_invite_failure';
export const WRITE_SEND_INVITATIONS = 'write_send_invtations';
export const GET_ORG_INVITATIONS = 'GET_ORG_INVITATIONS';
export const SET_ORG_INVITATIONS = 'set_org_invitations';
export const UPDATE_ORG_MEMBERS_STATUS = 'update_org_members_status';
export const SAVE_ORG_MEMBERS_DETAILS = 'save_org_members_details';
export const REMOVE_ORG_MEMBER = 'remove_org_member';
export const REMOVE_ORG_INVITATIONS = 'remove_org_invitations';
export const REMOVE_ORG_INVITATIONS_SUCCESS = 'remove_org_invitations_success';
export const REMOVE_ORG_INVITATIONS_FAILURE = 'remove_org_invitations_failure';

////////////////////////  Pipeline  //////////////////////////
export const GET_USER_PIPELINE = 'get_user_pipeline';
export const SET_PIPELINE_CONTACTS = 'set_pipeline_contacts';
export const ADD_NEW_PIPELINE_CONTACT = 'add_new_pipeline_contact';
export const ADD_NEW_PIPELINE_CONTACT_SUCCESS = 'add_new_pipeline_contact_success';
export const ADD_NEW_PIPELINE_CONTACT_FAILURE = 'add_new_pipeline_contact_failure';
export const EDIT_PIPELINE_CONTACT = 'edit_pipeline_contact';
export const REMOVE_PIPELINE_CONTACT = 'remove_pipeline_contact';
export const REMOVE_PIPELINE_CONTACT_SUCCESS = 'remove_pipeline_contact_success';
export const REMOVE_PIPELINE_CONTACT_FAILURE = 'remove_pipeline_contact_failure';

////////////////////////// Preferences ///////////////////////////
export const REMOVING_PREFERENCE_TASK = 'removing_preference_task';
export const ADDING_PREFERENCE_TASK = 'adding_preference_task';
export const REMOVING_PREFERENCE_TASK_SUCCESS = 'removing_preference_task_success';
export const REMOVING_PREFERENCE_TASK_FAILURE = 'removing_preference_task_failure';
export const ADDING_PREFERENCE_TASK_SUCCESS = 'adding_preference_task_success';
export const ADDING_PREFERENCE_TASK_FAILURE = 'adding_preference_task_failure';
export const UPDATE_PREFERENCE_USER_DOCUMENTS = 'update_preference_user_documents';
export const UPDATING_PREFERENCE_USER_LANGUAGES = 'updating_user_languages';
export const UPDATE_PREFERENCE_USER_NOTIFICATIONS = 'update_user_notifications';
export const UPDATING_PREFERENCE_INITIAL_TASKS = 'updating_preference_initial_tasks';
export const SET_PREFERENCE_ACTIVE_TAB = 'set_preference_active_tab';

///////////////////////// Property ///////////////////////////
export const GET_PROPERTY_MLS = 'get_property_mls';
export const GET_PROPERTY_MLS_SUCCESS = 'get_property_mls_success';
export const RESET_PROPERTY_DATA = 'reset_property_data';
export const GET_ACTIVE_BROKERAGES = 'get_active_brokerages';
export const GET_ACTIVE_BROKERAGES_SUCCESS = 'get_active_brokerages_success';

//////////////////////////// Settings /////////////////////////////
export const UPDATE_USER_AVATAR = 'update_user_avatar';
export const UPDATE_USER_AVATAR_SUCCESS = 'update_user_avatar_success';
export const UPDATE_USER_AVATAR_FAILURE = 'update_user_avatar_failure';
export const UPDATE_PERSONAL_INFO = 'update_personal_info';
export const UPDATE_PERSONAL_INFO_SUCCESS = 'update_personal_info_success';
export const UPDATE_PERSONAL_INFO_FAILURE = 'update_personal_info_failure';
export const UPDATE_USER_TEAM = 'update_user_team';
export const UPDATE_ORG_TEAM = 'update_org_team';

///////////////////////// Super Admin ///////////////////////////
export const ADD_INTERNAL_USER = 'add_internal_user';
export const ADD_INTERNAL_USER_SUCCESS = 'add_internal_user_success';

//////////////////////////// Teams //////////////////////////////
export const GET_USER_TEAM = 'get_user_team';
export const SET_USER_TEAM_SUCCESS = 'set_user_team_success';
export const SET_USER_TEAM_FAILURE = 'set_user_team_failure';
export const UPDATE_USER_TEAM_NAME = 'update_user_team_name';
export const CREATE_USER_TEAM = 'create_user_team';
export const DELETE_USER_TEAM = 'delete_user_team';
export const LEAVE_USER_TEAM = 'leave_user_team';
export const LEAVE_TEAM_SUCCESS = 'leave_team_success';
export const ADD_TEAM_MEMBER = 'add_team_member';
export const REMOVE_TEAM_MEMBER = 'remove_team_member';
export const DELETE_TEAM_SUCCESS = 'delete_team_success';
export const ACCEPT_TEAM_INVITE = 'accept_team_invite';
export const DECLINE_TEAM_INVITE = 'decline_team_invite';
export const REMOVE_TEAM_INVITE = 'remove_team_invite';

/////////////////// Transaction Activity ////////////////////
export const SET_TRANSACTION_ACTIVITY = 'set_transaction_activity';

////////////////////////// Transactions //////////////////////////
export const GET_TRANSACTION_LIST = 'get_transaction_list';
export const SET_TRANSACTION_LIST = 'set_transaction_list';
export const GET_USER_TRANSACTIONS = 'get_user_transactions';
export const SET_USER_TRANSACTIONS = 'set_user_transactions';
export const SET_USER_CLOSED_TRANSACTIONS = 'set_user_closed_transactions';
export const SET_USER_ARCHIVED_TRANSACTIONS = 'set_user_archived_transactions';
export const CREATE_TRANSACTION = 'create_transaction';
export const CREATE_TRANSACTION_SUCCESS = 'create_transaction_success';
export const CREATE_TRANSACTION_FAILURE = 'create_transaction_failure';
export const RESET_TRANSACTION_STATE = 'reset_transaction_state';
export const SET_PROPERTY_DATA = 'set_property_data';
export const UPDATE_TRANSACTION_NOTE = 'update_transaction_note';
export const UPDATE_TRANSACTION_NOTE_SUCCESS = 'update_transaction_note_success';
export const UPDATE_TRANSACTION_NOTE_FAILURE = 'update_transaction_note_failure';
export const UPDATE_TRANSACTION_TASK = 'update_transaction_task';
export const UPDATE_TRANSACTION_TASK_SUCCESS = 'update_transaction_task_success';
export const UPDATE_TRANSACTION_TASK_FAILURE = 'update_transaction_task_failure';
export const UPDATE_ACTIVITY_POST = 'update_activity_post';
export const UPDATE_ACTIVITY_POST_SUCCESS = 'update_activity_post_success';
export const UPDATE_ACTIVITY_POST_FAILURE = 'update_activity_post_failure';
export const SET_TRANSACTION_FILTER = 'set_transaction_filter';
export const SET_TRX_SEARCH_SUGGESTIONS = 'set_trx_search_suggestions';
export const GET_TRANSACTION_DETAILS = 'get_transaction_details';
export const SET_TRANSACTION_DETAILS = 'set_transaction_details';
export const SET_CLIENT_TRANSACTION_DETAILS = 'set_client_transaction_details';
export const RESET_DETAILS_FLAG = 'reset_details_flag';
export const CANCEL_DETAILS_LISTENER = 'cancel_details_listener';
export const UPDATE_TRANSACTION_STATUS = 'update_transaction_status';
export const UPDATE_TRANSACTION_DETAILS = 'update_transaction_details';
export const UPDATE_TRANSACTION_MEMBERS = 'update_transaction_members';
export const ADD_PARTY_TO_TRANSACTION = 'add_party_to_transaction';
export const REMOVE_PARTY_FROM_TRANSACTION = 'remove_party_from_transaction';
export const SET_MEMBER_TRANSACTION_LIST = 'set_member_transaction_list';
export const GET_MEMBER_TRANSACTIONS = 'get_member_transactions';
export const SET_MEMBER_TRANSACTIONS = 'set_member_transactions';
export const SET_MEMBER_CLOSED_TRANSACTIONS = 'set_member_closed_transactions';
export const SET_MEMBER_ARCHIVED_TRANSACTIONS = 'set_member_archived_transactions';
export const SET_MEMBER_TRANSACTION_FILTER = 'set_member_transaction_filter';
export const RESET_MEMBER_TRX_FILTER = 'reset_member_trx_filter';
export const INVITE_PARTY_TO_TRANSACTION = 'invite_party_to_transaction';
export const UPDATE_TIME_VISITING_TRANSACTION = 'update_time_visiting_transaction';
export const UPDATE_OPPOSING_MEMBERS = 'update_opposing_members';
export const UPDATE_OPPOSING_MEMBERS_SUCCESS = 'update_opposing_members_success';
export const UPDATE_OPPOSING_MEMBERS_FAILURE = 'update_opposing_members_failure';
export const REMOVE_OPPOSING_MEMBER = 'remove_opposing_member';
export const REMOVE_OPPOSING_MEMBER_SUCCESS = 'remove_opposing_member_success';
export const REMOVE_OPPOSING_MEMBER_FAILURE = 'remove_opposing_member_failure';

////////////////////////////// User //////////////////////////////
export const GET_USER_COLLECTION = 'get_user_collection';
export const SET_USER_COLLECTION = 'set_user_collection';

///////////////////////// Transaction Invitations /////////////////////////
export const VALIDATE_TRX_INVITATION = 'validate_trx_invitation';
export const VALIDATE_TRX_INVITATION_ERROR = 'validate_trx_invitation_error';
export const SET_TRX_INVITATION_VALIDITY = 'set_trx_invitation_validity';
export const ACCEPT_TRX_INVITATION = 'accept_trx_invitation';
export const ACCEPT_TRX_INVITATION_SUCCESS = 'accept_trx_invitation_success';
export const ACCEPT_NEW_USER_TRX_INVITATION = 'accept_new_user_trx_invitation';
