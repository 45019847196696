import React, { useRef } from 'react';
import { useLang } from '../../context/LangContext';
import cx from 'classnames';
import { Label } from 'reactstrap';

const FormInput = ({
    formTitle,
    activeInput,
    inputValue,
    handleInputChange,
    handleChecked,
    setActiveHandler,
    isPrevDisabled,
    isNextDisabled,
    requiredLeftNumber,
    openSettings,
    signers,
    assignee,
    setAssignee,
    requiredFieldsSetting
}) => {
    const {
        left_field_label,
        completed_field_label,
        prev_button,
        next_button,
        settings_label,
        assign_label,
        disabled_assigning_label
    } = useLang()['Documents']['FillingDocument'];
    const inputRef = useRef(null);

    const handleClick = option => {
        setActiveHandler(option);
        inputRef?.current?.focus();
        setAssignee(null);
    };

    return (
        <>
            <div className="card">
                <div className="m-3" style={{ fontWeight: 600 }}>
                    {formTitle}
                </div>
                <hr className="m-0" />
                <div className="m-3">
                    {activeInput?.inputs[0].map_key.name}
                    {activeInput?.isRequired && (
                        <span style={{ color: '#de4437' }}> *</span>
                    )}
                    {activeInput?.inputs[0].type === 'checkbox' ? (
                        <div className="min-height-120">
                            {activeInput?.inputs.map(input => (
                                <div
                                    key={input.id}
                                    className="custom-control custom-checkbox mt-2"
                                >
                                    <input
                                        type="checkbox"
                                        id={input.id}
                                        name={input.map_key.name}
                                        className="custom-control-input"
                                        defaultChecked={input.value}
                                        onChange={() =>
                                            handleChecked(input.id, !input.value)
                                        }
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={input.id}
                                    >
                                        {input.map_key.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    ) : ['signature', 'initials'].includes(
                          activeInput?.inputs[0].type
                      ) ? null : (
                        <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            ref={inputRef}
                            onChange={handleInputChange}
                            value={inputValue}
                        />
                    )}
                    {activeInput?.isRequired && requiredFieldsSetting === 'myself' ? (
                        <p className="my-3">{disabled_assigning_label}</p>
                    ) : (
                        <>
                            <Label className="form-label my-3">{assign_label}</Label>
                            <div className="d-block ml-1 w-80 mt-1">
                                {signers.map((signer, index) => (
                                    <div
                                        className="custom-control custom-checkbox mb-4 pointer"
                                        key={signer.id}
                                    >
                                        <input
                                            type="checkbox"
                                            id={`asignee.${index}`}
                                            name="asignee"
                                            checked={signer.id === assignee}
                                            className="custom-control-input"
                                            onChange={() => {
                                                setAssignee(prev =>
                                                    prev === signer.id ? null : signer.id
                                                );
                                            }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={`asignee.${index}`}
                                        >
                                            {signer.legal_name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="mt-3 d-flex justify-content-between">
                        <button
                            disabled={isPrevDisabled}
                            className="btn btn-sm btn-primary"
                            onClick={() => handleClick('prev')}
                        >
                            {prev_button}
                        </button>
                        <button
                            disabled={isNextDisabled}
                            className="btn btn-sm btn-primary"
                            onClick={() => handleClick('next')}
                        >
                            {next_button}
                        </button>
                    </div>
                    <p
                        className={cx('mt-3 mb-0', {
                            'text-success': !requiredLeftNumber
                        })}
                    >
                        {requiredLeftNumber
                            ? requiredLeftNumber + left_field_label
                            : completed_field_label}
                    </p>
                </div>
            </div>
            <p className="m-3 pointer" style={{ fontWeight: 600 }} onClick={openSettings}>
                <i className="fa-solid fa-sliders mr-2" />
                {settings_label}
            </p>
        </>
    );
};
export default FormInput;
