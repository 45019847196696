// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { Input, FormFeedback } from 'reactstrap';

const ImmutableSelect = ({
    required,
    type,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    onChange,
    onFocus,
    invalid,
    myStyle,
    nonFormik,
    rows,
    parentStyle,
    min,
    max,
    hasDefaultFill,
    disabled,
    autoComplete,
    resize
}) => {
    return (
        <div style={parentStyle ? parentStyle : myStyle}>
            <div
                className="justify-content-between align-items-center"
                style={{
                    display: 'flex'
                }}
            >
                <Field
                    required={required}
                    type={type}
                    className={className}
                    style={{
                        ...myStyle,
                        resize: !resize ? 'none' : resize,
                        borderColor:
                            !invalid && value && !hasDefaultFill
                                ? 'rgba(0, 201, 167, 0.5)'
                                : '',
                        borderRightWidth: 0,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    }}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    error={error}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    as={Input}
                    invalid={invalid}
                    autoComplete={autoComplete}
                    disabled={disabled}
                />
                <div
                    className="justify-content-center align-items-center"
                    style={{
                        display: 'flex',
                        border: '1px solid rgba(0, 201, 167, 0.5)',
                        borderLeftWidth: 0,
                        borderTopRightRadius: '0.3125rem',
                        borderBottomRightRadius: '0.3125rem',
                        height: 'calc(1.5em + 1.5rem + 2px)',
                        backgroundColor: '#f8fafd'
                    }}
                >
                    <span className="select-icon" />
                </div>
            </div>
            <FormFeedback>{error}</FormFeedback>
        </div>
    );
};

export default ImmutableSelect;
