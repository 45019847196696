import {
    GET_PROPERTY_MLS,
    GET_PROPERTY_MLS_SUCCESS,
    RESET_PROPERTY_DATA,
    GET_ACTIVE_BROKERAGES,
    GET_ACTIVE_BROKERAGES_SUCCESS
} from './types';

export const getMlsData = mlsNumber => {
    return {
        type: GET_PROPERTY_MLS,
        payload: mlsNumber
    };
};

export const getMlsDataSuccess = property => {
    return {
        type: GET_PROPERTY_MLS_SUCCESS,
        payload: property
    };
};

export const resetPropertyData = () => {
    return {
        type: RESET_PROPERTY_DATA
    };
};

export const gettingActiveBrokerages = () => {
    return {
        type: GET_ACTIVE_BROKERAGES
    };
};

export const gettingActiveBrokeragesSuccess = brokerages => {
    return {
        type: GET_ACTIVE_BROKERAGES_SUCCESS,
        payload: brokerages
    };
};
