import React, { useState } from 'react';

// Components
import TextField from '../../common/TextField';

// Loggers
import { log } from '../../../utils/Loggers';

// Utils
import { lookupRegExp } from '../../../utils/Constants';

// Smarty Streets
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const Address = SmartyStreetsSDK.usStreet.Lookup;

const OfficeAddress = ({
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    placeholder,
    officeAddress
}) => {
    const key = process.env.REACT_APP_SMARTY_WEBSITE_KEY;
    const credentials = new SmartyStreetsCore.SharedCredentials(key);
    const lookupClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
    const addressClient = SmartyStreetsCore.buildClient.usStreet(credentials);
    const [lookupResults, setLookupResults] = useState(null);

    const addressInputHandler = address => {
        if (lookupRegExp.valid.test(address)) {
            const lookup = new Lookup(address);
            lookup.maxSuggestions = 6;
            lookupClient
                .send(lookup)
                .then(results => {
                    const { result } = results;
                    setLookupResults(result);
                })
                .catch(error => {
                    log(
                        'Smarty Streets Error: error in address lookup results (OfficeAddress)',
                        {
                            error,
                            address,
                            function: 'addressInputHandler'
                        }
                    );
                });
        } else {
            setLookupResults(null);
        }
    };

    const getAddressFromLookup = address => {
        const batch = new SmartyStreetsCore.Batch();
        const verifiedAddress = new Address();
        verifiedAddress.street = address.streetLine;
        verifiedAddress.urbanization = '';
        verifiedAddress.city = address.city;
        verifiedAddress.state = address.state;
        verifiedAddress.maxCandidates = 3;
        verifiedAddress.match = 'invalid';
        batch.add(verifiedAddress);
        addressClient
            .send(batch)
            .then(result => {
                const { components, metadata } = result.lookups[0].result[0] || null;
                const city = components.cityName;
                const state = components.state;
                const zip = components.zipCode;
                const lat = metadata.latitude;
                const lon = metadata.longitude;

                const userAddress = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                }`;

                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                }, ${city} ${state} ${zip}`;

                const address2 = components.secondaryNumber
                    ? `${components.secondaryNumber} `
                    : '';

                setFieldTouched('address', true);
                setFieldValue('userAddress', userAddress.trimEnd());
                setFieldValue('address', address1.trimEnd());
                setFieldValue('address1', address1.trimEnd());
                setFieldValue('address2', address2.trimEnd());
                setFieldValue('city', city);
                setFieldValue('state', state);
                setFieldValue('zip', zip);
                setFieldValue('lat', lat);
                setFieldValue('lon', lon);
                setLookupResults(null);
            })
            .catch(error => {
                log(
                    'Smarty Streets Error: error in address lookup details (LISTING_TRX)',
                    {
                        error,
                        address,
                        function: 'getAddressFromLookup'
                    }
                );
            });
    };

    return (
        <>
            <TextField
                type="text"
                className="form-control office-address"
                name={officeAddress ? 'address' : 'userAddress'}
                id="address"
                myStyle={lookupResults && { borderRadius: '6px 6px 0 0' }}
                placeholder={placeholder}
                value={officeAddress ? values.address : values.userAddress}
                autoComplete="off"
                onChange={e => {
                    handleChange(e);
                    addressInputHandler(e.target.value);
                }}
                hasDefaultFill
            />
            {lookupResults && !!lookupResults.length && (
                <ul className="office-address-list">
                    {lookupResults.map(result => (
                        <li
                            key={result.text}
                            className="office-address-list__item"
                            onClick={() => {
                                getAddressFromLookup(result);
                            }}
                        >
                            {result.text}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default OfficeAddress;
