// React
import React, { useCallback, useEffect, useState } from 'react';

// Packages
import SimpleBar from 'simplebar-react';

// Redux
import { connect } from 'react-redux';
import { updatePreferenceInitialTasks } from '../../../store/actions/Preferences';

// Context
import { useLang } from '../../../context/LangContext';

// Utils
import {
    checkChanges,
    setUniqueTaskTitle,
    sortListByAlphabet
} from '../../../utils/Helpers';

// Components
import ActionButtons from '../shared/ActionButtons';
import { TaskModal } from './TaskModal';

const Tasks = ({ updateInitialTasks, userData, setHasChanges }) => {
    const { tip_note, buttons } = useLang()['Settings']['Preferences']['Tasks'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];

    const userOrg = userData.active_org_id;
    const buyer = userData.preferences[userOrg].initial_tasks.buyer;
    const seller = userData.preferences[userOrg].initial_tasks.seller;

    const buyerInitialTaskList = buyer.slice();
    const sellerInitialTaskList = seller.slice();

    const [isDisabled, setIsDisabled] = useState(true);
    const [buyerTaskModalOpen, setBuyerTaskModalOpen] = useState(false);
    const [buyerTasksList, setBuyerTasksList] = useState(buyer.slice());

    const [selectedBuyerTask, setSelectedBuyerTask] = useState(null);
    const [selectedSellerTask, setSelectedSellerTask] = useState(null);

    const [sellerTaskModalOpen, setSellerTaskModalOpen] = useState(false);
    const [sellerTasksList, setSellerTasksList] = useState(seller.slice());

    const [removeBuyerTasks, setRemoveBuyerTasks] = useState([]);
    const [removeSellerTasks, setRemoveSellerTasks] = useState([]);

    const [editing, setEditing] = useState(null);

    const handleRemoveBuyer = (task, index) => {
        const exist = removeBuyerTasks.includes(buyerTasksList[index]);
        const checkAddedTask = buyerInitialTaskList.some(item =>
            item.title.includes(task.title)
        );
        if (exist) {
            setRemoveBuyerTasks(
                removeBuyerTasks.filter(item => item !== buyerTasksList[index])
            );
        } else if (!checkAddedTask) {
            setBuyerTasksList(buyerTasksList.filter(item => item.title !== task.title));
        } else {
            setRemoveBuyerTasks([...removeBuyerTasks, buyerTasksList[index]]);
        }
        checkDisableButtons();
    };

    const handleRemoveSeller = (task, index) => {
        const exist = removeSellerTasks.includes(sellerTasksList[index]);
        const checkAddedTask = sellerInitialTaskList.some(item =>
            item.title.includes(task.title)
        );
        if (exist) {
            setRemoveSellerTasks(
                removeSellerTasks.filter(item => item !== sellerTasksList[index])
            );
        } else if (!checkAddedTask) {
            setSellerTasksList(sellerTasksList.filter(item => item.title !== task.title));
        } else {
            setRemoveSellerTasks([...removeSellerTasks, sellerTasksList[index]]);
        }
        checkDisableButtons();
    };

    const buyerTaskModalToggle = (data, index, edit) => {
        setEditing(edit);
        setBuyerTaskModalOpen(prevState => !prevState);
        setSelectedBuyerTask({ task: data, index });
    };

    const sellerTaskModalToggle = (data, index, edit) => {
        setEditing(edit);
        setSellerTaskModalOpen(prevState => !prevState);
        setSelectedSellerTask({ task: data, index });
    };

    const onBuyerSubmitCb = editedItem => {
        const { task, index } = editedItem;
        const uniqueTitle = editing
            ? task.title
            : setUniqueTaskTitle(task.title, buyerTasksList);
        task.title = uniqueTitle;
        const noIndex = buyerTasksList.length - 1;
        buyerTasksList[index || index === 0 ? index : noIndex + 1] = task;
        setBuyerTasksList(sortListByAlphabet(buyerTasksList));
        setBuyerTaskModalOpen(prevState => !prevState);
        checkDisableButtons();
    };

    const onSellerSubmitCb = editedItem => {
        const { task, index } = editedItem;
        const uniqueTitle = editing
            ? task.title
            : setUniqueTaskTitle(task.title, sellerTasksList);
        task.title = uniqueTitle;
        const noIndex = sellerTasksList.length - 1;
        sellerTasksList[index || index === 0 ? index : noIndex + 1] = task;
        setSellerTasksList(sortListByAlphabet(sellerTasksList));
        setSellerTaskModalOpen(prevState => !prevState);
        checkDisableButtons();
    };

    const handleReset = useCallback(() => {
        setBuyerTasksList(buyerInitialTaskList);
        setSellerTasksList(sellerInitialTaskList);
        setRemoveBuyerTasks([]);
        setRemoveSellerTasks([]);
    }, [buyerInitialTaskList, sellerInitialTaskList]);

    const checkDisableButtons = useCallback(() => {
        const buyersChanged = checkChanges(buyerInitialTaskList, buyerTasksList);
        const sellersChanged = checkChanges(sellerInitialTaskList, sellerTasksList);

        const dataHasChanges = buyersChanged || sellersChanged;
        const collectionsAreEmpty = !removeBuyerTasks.length && !removeSellerTasks.length;

        setIsDisabled(!(dataHasChanges || !collectionsAreEmpty));
    }, [
        buyerInitialTaskList,
        buyerTasksList,
        sellerInitialTaskList,
        sellerTasksList,
        removeBuyerTasks.length,
        removeSellerTasks.length
    ]);

    useEffect(() => {
        checkDisableButtons();
    }, [
        removeSellerTasks,
        removeBuyerTasks,
        buyerTaskModalOpen,
        setBuyerTaskModalOpen,
        checkDisableButtons
    ]);

    useEffect(() => {
        setHasChanges(!isDisabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled]);

    useEffect(() => {
        return handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className="card-body"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <SimpleBar className="container pr-5">
                    <div style={{ maxWidth: '600px', margin: 'auto' }}>
                        <div className="pt-5 text-center">
                            <p className="text-wrap">{tip_note}</p>
                        </div>
                        <div className="d-flex justify-content-between p-4">
                            <div>
                                <button
                                    className="btn mb-3 py-1"
                                    type="button"
                                    style={{
                                        backgroundColor: '#ff7744',
                                        color: '#ffffff'
                                    }}
                                    onClick={() =>
                                        buyerTaskModalToggle(null, null, false)
                                    }
                                >
                                    {buttons?.button1}
                                </button>
                                <SimpleBar
                                    style={{
                                        overflowY: 'auto',
                                        height: '200px',
                                        paddingRight: '20px',
                                        width: '200px'
                                    }}
                                >
                                    <div>
                                        {buyerTasksList.map((task, index) => (
                                            <div
                                                key={task.title}
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p
                                                    className="pointer overflow-hidden ellipsis"
                                                    style={{
                                                        maxWidth: '250px'
                                                    }}
                                                    onClick={() =>
                                                        buyerTaskModalToggle(
                                                            buyerTasksList[index],
                                                            index,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {task.title}
                                                </p>
                                                <i
                                                    className="fa-regular fa-xmark removePhoneIcon pointer pl-4"
                                                    style={{
                                                        color:
                                                            removeBuyerTasks.includes(
                                                                task
                                                            ) && '#de4437',
                                                        top: '7px'
                                                    }}
                                                    onClick={() =>
                                                        handleRemoveBuyer(task, index)
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </SimpleBar>
                            </div>
                            <div>
                                <button
                                    className="btn mb-3 py-1"
                                    type="button"
                                    style={{
                                        backgroundColor: '#ffaa22',
                                        color: '#ffffff'
                                    }}
                                    onClick={() =>
                                        sellerTaskModalToggle(null, null, false)
                                    }
                                >
                                    {buttons?.button2}
                                </button>
                                <SimpleBar
                                    style={{
                                        overflowY: 'auto',
                                        height: '200px',
                                        paddingRight: '20px',
                                        width: '200px'
                                    }}
                                >
                                    <div>
                                        {sellerTasksList.map((task, index) => (
                                            <div
                                                key={task.title}
                                                className="d-flex justify-content-between align-items-baseline"
                                                style={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p
                                                    className="pointer overflow-hidden ellipsis"
                                                    style={{
                                                        maxWidth: '250px'
                                                    }}
                                                    onClick={() =>
                                                        sellerTaskModalToggle(
                                                            sellerTasksList[index],
                                                            index,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {task.title}
                                                </p>
                                                <i
                                                    className="fa-regular fa-xmark removePhoneIcon pointer pl-4"
                                                    style={{
                                                        color:
                                                            removeSellerTasks.includes(
                                                                task
                                                            ) && '#de4437',
                                                        top: '7px'
                                                    }}
                                                    onClick={() => {
                                                        handleRemoveSeller(task, index);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </SimpleBar>
                                <TaskModal
                                    handleOpen={buyerTaskModalOpen}
                                    handleToggle={buyerTaskModalToggle}
                                    item={selectedBuyerTask}
                                    onSubmitCb={onBuyerSubmitCb}
                                    type="buyer"
                                />
                                <TaskModal
                                    handleOpen={sellerTaskModalOpen}
                                    handleToggle={sellerTaskModalToggle}
                                    item={selectedSellerTask}
                                    onSubmitCb={onSellerSubmitCb}
                                    type="seller"
                                />
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <ActionButtons
                saveLabel={save_label}
                handleSubmit={() => {
                    updateInitialTasks({
                        buyerTasks: buyerTasksList,
                        sellerTasks: sellerTasksList,
                        userData: userData,
                        removeBuyerTasks: removeBuyerTasks,
                        removeSellerTasks: removeSellerTasks
                    });
                }}
                handleClose={() => handleReset()}
                cancelLabel={cancel_label}
                noCancel={isDisabled}
                disabled={isDisabled}
            />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps, {
    updateInitialTasks: updatePreferenceInitialTasks
})(Tasks);
