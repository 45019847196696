// React
import React, { useEffect, useState } from 'react';

// Packages
import SimpleBar from 'simplebar-react';

// Context
import { useLang } from '../../../context/LangContext';

// Components
import { DocumentModal } from './DocumentModal';

export const BuyerDocuments = ({
    data = [],
    setData,
    removeBuyer,
    setRemoved,
    checkDisableButtons,
    initialDocuments
}) => {
    const { buttons, modal } = useLang()['Settings']['Preferences']['Documents'];

    const [buyerDocumentModalOpen, setBuyerDocumentModalOpen] = useState(false);

    const buyerDocumentModalToggle = () => {
        setBuyerDocumentModalOpen(prevState => !prevState);
    };

    const handleRemoveBuyerDocuments = (doc, index) => {
        const exist = removeBuyer.includes(data[index]);
        const findAddedDoc = initialDocuments.some(item => item.id.includes(doc.id));
        if (exist) {
            setRemoved(removeBuyer.filter(item => item !== data[index]));
        } else if (!findAddedDoc) {
            setData(
                data.map(item => {
                    if (item.id === doc.id) {
                        item.checked = !item.checked;
                    }
                    return item;
                })
            );
        } else {
            setRemoved([...removeBuyer, data[index]]);
        }
        checkDisableButtons();
    };

    const onBuyerSubmitCb = addedItems => {
        if (addedItems) {
            setData(addedItems.docs);
            setBuyerDocumentModalOpen(prevState => !prevState);
        }
        checkDisableButtons();
    };

    useEffect(() => {}, [data]);
    return (
        <>
            <div className="ml-5">
                <button
                    className="btn mb-3 py-1"
                    type="button"
                    style={{
                        backgroundColor: '#ff7744',
                        color: '#ffffff'
                    }}
                    onClick={buyerDocumentModalToggle}
                >
                    {buttons?.button1}
                </button>
                <SimpleBar
                    style={{
                        overflowY: 'auto',
                        height: '200px',
                        paddingRight: '20px',
                        width: '200px'
                    }}
                >
                    <div style={{ width: '100%' }}>
                        {data.map(
                            (doc, index) =>
                                doc.checked && (
                                    <div
                                        key={doc.id}
                                        style={{
                                            position: 'relative',
                                            marginRight: '15px',
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p
                                            className=" pointer overflow-hidden ellipsis"
                                            style={{
                                                maxWidth: '250px'
                                            }}
                                        >
                                            {doc.title}
                                        </p>
                                        <i
                                            className="fa-regular fa-xmark removePhoneIcon pointer pl-4"
                                            style={{
                                                color:
                                                    removeBuyer.includes(doc) &&
                                                    '#de4437',
                                                top: '7px'
                                            }}
                                            onClick={() =>
                                                handleRemoveBuyerDocuments(doc, index)
                                            }
                                        />
                                    </div>
                                )
                        )}
                    </div>
                </SimpleBar>
            </div>
            <DocumentModal
                handleOpen={buyerDocumentModalOpen}
                handleToggle={buyerDocumentModalToggle}
                title={modal.title1}
                allDocuments={data}
                onSubmitCb={onBuyerSubmitCb}
                type="buyer"
            />
        </>
    );
};
