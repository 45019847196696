import {
    GET_DOC_FOR_FILLING,
    SET_SETTINGS_FOR_FILLING,
    SET_DOC_FOR_FILLING,
    SAVE_SIGNATURE_SUCCESS,
    ACCEPT_SIGNING_CONCENT_SUCCESS,
    REMOVE_SIGNER_FROM_DOCUMENT_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    documentForFilling: null,
    loading: false
};

const documentFillingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DOC_FOR_FILLING:
            return {
                ...state,
                loading: true
            };
        case SET_DOC_FOR_FILLING:
            return {
                ...state,
                loading: false,
                documentForFilling: action.payload
            };

        case SET_SETTINGS_FOR_FILLING:
            return {
                ...state,
                documentForFilling: {
                    ...state.documentForFilling,
                    filling_settings: action.payload
                }
            };

        case SAVE_SIGNATURE_SUCCESS:
            return {
                ...state,
                documentForFilling: {
                    ...state.documentForFilling,
                    signature_values: [
                        ...(state.documentForFilling.signature_values?.filter(
                            el =>
                                !(
                                    action.payload.id === el.id &&
                                    action.payload.type === el.type
                                )
                        ) || []),
                        action.payload
                    ]
                }
            };

        case ACCEPT_SIGNING_CONCENT_SUCCESS:
            return {
                ...state,
                documentForFilling: {
                    ...state.documentForFilling,
                    filling_settings: {
                        ...state.documentForFilling.filling_settings,
                        confirm_signers:
                            state.documentForFilling.filling_settings.confirm_signers.map(
                                el =>
                                    el.id === action.payload
                                        ? {
                                              ...el,
                                              signing_consent: true
                                          }
                                        : el
                            )
                    }
                }
            };

        case REMOVE_SIGNER_FROM_DOCUMENT_SUCCESS:
            return {
                ...state,
                documentForFilling: {
                    ...state.documentForFilling,
                    filling_settings: {
                        ...state.documentForFilling.filling_settings,
                        confirm_signers:
                            state.documentForFilling.filling_settings.confirm_signers.filter(
                                el => el.email !== action.payload
                            )
                    }
                }
            };

        default:
            return state;
    }
};

export default documentFillingReducer;
