import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

// Context
import { userTypes } from '../../../utils/Constants';

import {
    addTeamMember,
    removeTeamMember,
    deleteUserTeam,
    updateUserTeamName,
    leaveUserTeam
} from '../../../store/actions/Teams';

// Components
import AddNewTeam from './AddNewTeam';
import InviteMembers from './InviteMembers';
import TeamMembers from './TeamMembers';
import ManageInvitations from './ManageInvitations';
import Loader from '../../common/Loader';
import ConfirmationDialog from '../../common/ConfirmationDialog';

const teamScreen = {
    invite: 'invite-members',
    manageInvitations: 'manageInvitations',
    manageLeadInvitations: 'manageLeadInvitations',
    teamMembers: 'teamMembers'
};

const Team = ({
    type,
    team,
    updateUserTeamName,
    userData,
    deleteUserTeam,
    addTeamMember,
    removeTeamMember,
    leaveUserTeam,
    modalDialogType
}) => {
    const [screen, setScreen] = useState(teamScreen.teamMembers);
    const [addMembers, setAddMembers] = useState(null);
    useEffect(() => {
        if (!userData.team_id && Object.values(userData.team_invites || {}).length) {
            setScreen(teamScreen.manageInvitations);
        }
    }, [userData]);
    const invitedUsers = useMemo(
        () =>
            team &&
            (team.invitations
                ? Object.values(team.invitations).map(invitation => invitation.user_id)
                : []),
        [team]
    );
    const teamMembers = useMemo(
        () => team && (team.members ? Object.values(team.members) : []),
        [team]
    );
    const isBroker = useMemo(() => {
        return type === userTypes.broker || type === userTypes.owner;
    }, [type]);

    if (!team) {
        if (!Object.values(userData.team_invites || {}).length) {
            return <AddNewTeam />;
        } else if (screen === teamScreen.teamMembers) {
            return <Loader />;
        }
    }

    const getTeamScreen = () => {
        switch (screen) {
            case teamScreen.invite:
                return (
                    <InviteMembers
                        team={team}
                        invitedUsers={invitedUsers}
                        members={teamMembers}
                        addMembers={addMembers}
                        setAddMembers={setAddMembers}
                        setScreen={setScreen}
                        teamScreen={teamScreen}
                        addTeamMember={addTeamMember}
                    />
                );

            case teamScreen.manageInvitations:
                return (
                    <ManageInvitations
                        setScreen={setScreen}
                        teamScreen={teamScreen}
                        userData={userData}
                    />
                );

            default:
                return (
                    <TeamMembers
                        team={team}
                        isBroker={isBroker}
                        updateUserTeamName={updateUserTeamName}
                        removeTeamMember={removeTeamMember}
                        teamMembers={teamMembers}
                        setScreen={setScreen}
                        teamScreen={teamScreen}
                        userData={userData}
                        deleteUserTeam={deleteUserTeam}
                        leaveUserTeam={leaveUserTeam}
                    />
                );
        }
    };

    return (
        <>
            <ConfirmationDialog initialModalDialogType={modalDialogType} />
            {getTeamScreen()}
        </>
    );
};

const mapStateToProps = ({ user, teams, modal }) => {
    const { type, userData } = user;
    const { team } = teams;
    const { modalDialogType } = modal;
    return { type, team, userData, modalDialogType };
};

export default connect(mapStateToProps, {
    updateUserTeamName,
    deleteUserTeam,
    addTeamMember,
    removeTeamMember,
    leaveUserTeam
})(Team);
