import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
    removeUserMessage,
    saveUserMessage,
    removeUserDraftMessage
} from './../store/actions/Messages';

// Packages
import cx from 'classnames';

//Components
import { AppHeader, MessagesTab } from '../components';
import { useLang } from '../context/LangContext';

const TABS = {
    newMessage: 'newMessage',
    savedMessages: 'savedMessages',
    drafts: 'drafts'
};

const Messages = ({
    removeUserDraftMessage,
    removeUserMessage,
    pinnedMessages,
    saveUserMessage,
    userMessages,
    userData,
    ...props
}) => {
    const { title, msg_tab_title, pinned_msg_tab_title, drafts_tab_title } =
        useLang()['Messages'];
    const [activeTab, setActiveTab] = useState(TABS.newMessage);

    const menuScheme = [
        { id: TABS.newMessage, name: msg_tab_title },
        { id: TABS.savedMessages, name: pinned_msg_tab_title },
        { id: TABS.drafts, name: drafts_tab_title }
    ];

    const handleClick = id => {
        setActiveTab(id);
    };

    const draftMessages = useMemo(
        () =>
            Object.values(
                (userData?.draft_messages &&
                    userData?.draft_messages[userData.active_org_id]) ||
                    {}
            ),
        [userData?.draft_messages, userData?.active_org_id]
    );

    const renderTab = () => {
        switch (activeTab) {
            case TABS.newMessage:
                return (
                    <MessagesTab
                        userMessages={userMessages}
                        removeUserMessage={removeUserMessage}
                        saveUserMessage={saveUserMessage}
                        savedMessages={pinnedMessages}
                        userId={userData.id}
                    />
                );
            case TABS.savedMessages:
                return (
                    <MessagesTab
                        removeUserMessage={removeUserMessage}
                        userId={userData.id}
                        userMessages={pinnedMessages || []}
                        isSavedTab={true}
                    />
                );
            case TABS.drafts:
                return (
                    <MessagesTab
                        removeUserMessage={removeUserDraftMessage}
                        userMessages={draftMessages}
                        isDraftTab={true}
                    />
                );
            default:
                return <MessagesTab />;
        }
    };

    return (
        <>
            <AppHeader {...props} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <div
                    className=" container mt-5"
                    style={{ overflowY: 'hidden', height: '100%' }}
                >
                    <div className="row no-gutters">
                        <div className=" col-12 no-gutters">
                            <div
                                className="card"
                                style={{
                                    height: '100%'
                                }}
                            >
                                <div className="card-header">
                                    <h1 className="h5 font-weight-semi-bold mb-0">
                                        {title}
                                    </h1>
                                </div>
                                <div className="card-body d-flex p-0">
                                    <div className="p-2 border-secondary col-2 ">
                                        <ul className="list-unstyled u-sidebar--account__list font-weight-semi-bold">
                                            {menuScheme.map(item => (
                                                <li
                                                    className="u-sidebar--account__list-item pointer list-unstyled mt-2"
                                                    key={item.name}
                                                >
                                                    <div
                                                        className={cx(
                                                            'u-sidebar--account__list-link messages-center ',
                                                            {
                                                                active:
                                                                    activeTab === item.id
                                                            }
                                                        )}
                                                        onClick={() =>
                                                            handleClick(item.id)
                                                        }
                                                    >
                                                        {item.name}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="container p-0">{renderTab()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
const mapStateToProps = ({ messages, user }) => {
    const { userMessages, pinnedMessages } = messages;
    const { userData } = user;
    return { userMessages, userData, pinnedMessages };
};
export default connect(mapStateToProps, {
    removeUserMessage,
    removeUserDraftMessage,
    saveUserMessage
})(Messages);
