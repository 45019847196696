// React
import React from 'react';

// Packages
import { Field } from 'formik';
import { FormFeedback } from 'reactstrap';
import Flatpickr from 'react-flatpickr';

const DatePicker = ({
    required,
    className,
    name,
    id,
    placeholder,
    error,
    value,
    onBlur,
    myStyle,
    options,
    touched,
    nonFormik,
    hasDefaultFill,
    invalid
}) => {
    return (
        <div style={myStyle}>
            {nonFormik ? (
                <Flatpickr
                    autoComplete="off"
                    className={className}
                    id={id}
                    placeholder={placeholder}
                    options={options}
                />
            ) : (
                <>
                    <Field
                        autoComplete="off"
                        required={required}
                        className={className}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        error={error}
                        touched={touched}
                        value={value}
                        onBlur={onBlur}
                        invalid={invalid}
                    >
                        {() => (
                            <Flatpickr
                                style={{
                                    borderColor:
                                        (error && touched) || invalid
                                            ? '#de4437'
                                            : value && !hasDefaultFill
                                            ? 'rgba(0, 201, 167, 0.5)'
                                            : ''
                                }}
                                className={className}
                                id={id}
                                placeholder={placeholder}
                                options={options}
                                value={value}
                            />
                        )}
                    </Field>
                    <FormFeedback
                        style={{
                            display: (error && touched) || invalid ? 'block' : 'none'
                        }}
                    >
                        {error}
                    </FormFeedback>
                </>
            )}
        </div>
    );
};

export default DatePicker;
