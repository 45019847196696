// React
import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import { ControllerHeader, DocumentsList, Loader, ViewController } from '../components';

// Hooks

// Packages
import { connect } from 'react-redux';
import { gettingTransactionDetails } from '../store/actions/Transactions';

const PdfView = ({
    match,
    location,
    userDocuments,
    orgDocuments,
    transactionDetails,
    gettingTransactionDetails,
    authorized,
    superAuthorized
}) => {
    const [allDocs, setAllDocs] = useState([]);
    const [docData, setDocData] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const packDocuments = useMemo(() => {
        if (docData?.pack) {
            const packDoc = allDocs.filter(el =>
                Object.keys(docData.documents).includes(el.id)
            );
            return packDoc
                .map(el => ({
                    ...el,
                    position: docData.documents[el.id].position
                }))
                .sort((a, b) => a.position - b.position);
        }
    }, [allDocs, docData]);

    useEffect(() => {
        if (userDocuments.length || orgDocuments.length) {
            const docs = [].concat(userDocuments, orgDocuments);
            setAllDocs(docs);
        }
    }, [userDocuments, orgDocuments]);

    useEffect(() => {
        if ((location.state?.trxId || match.params.trxId) && transactionDetails) {
            const transactionDocs = Object.values(transactionDetails.documents);
            setAllDocs(prev => [...prev, ...transactionDocs]);
        } else if ((location.state?.trxId || match.params.trxId) && !transactionDetails) {
            gettingTransactionDetails({
                trxId: location.state?.trxId || match.params.trxId,
                detailsFlag: false
            });
        }
    }, [location, transactionDetails, match, gettingTransactionDetails]);

    useEffect(() => {
        if (allDocs?.length && (location.state?.id || match.params.id)) {
            const doc = allDocs.find(
                doc => doc.id === (location.state?.id || match.params.id)
            );
            setDocData(doc);
            if (doc?.pack) {
                const firstDocument = Object.values(doc.documents).find(
                    el => el.position === 0
                );
                setSelectedDocument(allDocs.find(doc => doc.id === firstDocument.id));
            } else {
                setSelectedDocument(doc);
            }
        }
    }, [allDocs, location.state?.id, match.params.id]);

    const documentClickHandler = doc =>
        setSelectedDocument(allDocs.find(el => el.id === doc.id));

    if (!docData) return <Loader />;

    return (
        <>
            <ControllerHeader
                document={docData}
                authorized={authorized}
                superAuthorized={superAuthorized}
            />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div id="main" className="size container pt-3 pt-xl-4">
                        <div className="row">
                            {docData?.pack && (
                                <DocumentsList
                                    documents={packDocuments}
                                    selected={selectedDocument.id}
                                    documentClickHandler={documentClickHandler}
                                />
                            )}
                            <ViewController document={selectedDocument} />
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ documents, transactions }) => {
    const { userDocuments, orgDocuments } = documents;
    const { transactionDetails } = transactions;
    return { userDocuments, orgDocuments, transactionDetails };
};

export default withRouter(
    connect(mapStateToProps, { gettingTransactionDetails })(PdfView)
);
