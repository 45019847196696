import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';

import logo from '../assets/img/jada-logo.svg';

import { setLanguageLocal } from '../store/actions/Lang';
import { useLang } from '../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';

import { confirmExistingUserInvitation } from '../store/actions/Invitations';
import { ConfirmInterface, Loader } from '../components';

import * as routes from '../router/config/routes';

const lang = {
    en: 'english',
    es: 'español'
};

const InvitationConfirmation = ({
    userData,
    userInvitation,
    isValid,
    emailMatch,
    confirmExistingUserInvitation,
    setLanguageLocal,
    userReady,
    loading,
    error
}) => {
    const {
        confirm_heading,
        confirm_description_1,
        confirm_description_2,
        confirm_button_label
    } = useLang()['InvitationConfirmation']['ConfirmInterface'];

    const [appLanguage, setAppLanguage] = useState(lang?.en);
    const [valid, setValid] = useState('unset');
    const [validEmail, setValidEmail] = useState('unset');
    const history = useHistory();

    const setLanguage = () => {
        switch (appLanguage) {
            case lang.es:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
            case lang.en:
                setLanguageLocal({ origin: 'es' });
                setAppLanguage(lang.es);
                break;
            default:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
        }
    };

    useEffect(() => {
        if (valid === 'unset' && isValid !== '') {
            setValid(isValid);
        }
    }, [isValid, valid, setValid]);

    useEffect(() => {
        if (validEmail === 'unset' && emailMatch !== '') {
            setValidEmail(emailMatch);
        }
    }, [emailMatch, validEmail, setValidEmail]);

    useEffect(() => {
        if (userData) {
            if (userInvitation && valid !== 'unset' && validEmail !== 'unset') {
                if (valid && validEmail) {
                    confirmExistingUserInvitation({ invitation: userInvitation });
                } else {
                    history.push({
                        pathname: `${routes.INVITATION_ERROR}`,
                        state: { isTransactionInvitation: false }
                    });
                }
            }
        }
    }, [
        userData,
        userInvitation,
        valid,
        validEmail,
        confirmExistingUserInvitation,
        history
    ]);

    if (valid === 'unset' && validEmail === 'unset' && !userReady) return <Loader />;

    return (
        <div id="content" className="push-main pt-0">
            <div className="bg-light">
                <SimpleBar style={{ overflowY: 'auto', height: '100%' }}>
                    <button
                        className="btn btn-xs btn-text-secondary"
                        type="button"
                        onClick={setLanguage}
                        style={{
                            position: 'absolute',
                            margin: '15px',
                            top: 0,
                            right: '30px'
                        }}
                    >
                        <span className="position-relative">
                            <span
                                className="u-sidebar--account__toggle-text"
                                style={{
                                    textDecoration: 'underline'
                                }}
                            >
                                {appLanguage === 'english' ? lang.es : 'inglés'}
                            </span>
                        </span>
                    </button>
                    {valid && validEmail && (
                        <div className="container space-2">
                            <div className="mx-lg-auto">
                                <div className="card pt-5">
                                    <div className="jada-logo d-flex row justify-content-center mb-10">
                                        <img
                                            src={logo}
                                            className="logo"
                                            style={{ width: '190px' }}
                                            alt="Jada Real Estate Real Simple"
                                        />
                                    </div>
                                    <ConfirmInterface
                                        orgName={userInvitation?.org_name}
                                        lang={{
                                            confirm_heading,
                                            confirm_description_1,
                                            confirm_description_2,
                                            confirm_button_label
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ invitation, user }) => {
    const { userData } = user;
    const { userInvitation, isValid, emailMatch, userReady, loading, error } = invitation;
    return { userData, userInvitation, isValid, emailMatch, userReady, loading, error };
};

export default withRouter(
    connect(mapStateToProps, { confirmExistingUserInvitation, setLanguageLocal })(
        InvitationConfirmation
    )
);
