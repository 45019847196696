// React
import React from 'react';

// Assets
import JadaLogo from '../../assets/img/jada-j.png';
import PDF from '../../assets/svg/PDF.svg';

// Utils
import { formatThreadTime } from '../../utils/Helpers';
import * as routes from '../../router/config/routes';
import { useHistory } from 'react-router-dom';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import cx from 'classnames';
import Avatar from 'react-avatar';

const ActivityItem = ({
    event,
    avatarImgs,
    linkStyles,
    trxId,
    isDocumentExist,
    lastViewTime,
    currentUserId
}) => {
    const history = useHistory();
    const messages = useLang()['TransactionDetails']['ActivityMessages'];
    const titleClickHandler = activityItem => {
        if (activityItem.type === 'document' && isDocumentExist) {
            history.push({
                pathname: `${routes.AUTHENTICATED}${routes.VIEW_DOCUMENT}/${trxId}/${activityItem.id}`,
                state: {
                    id: activityItem.id,
                    trxId
                }
            });
        }
    };

    return (
        <div
            className={cx('message', {
                'message-bg-animation':
                    lastViewTime < event.created_at.seconds &&
                    currentUserId !== event.creator_id
            })}
        >
            <div className="message-avatar">
                <div className="u-avatar mr-3">
                    {avatarImgs ? (
                        <>
                            {avatarImgs.some(user => user.id === event.creator_id) ||
                            event.creator_type === 'system' ? (
                                <img
                                    className="img-fluid rounded-circle"
                                    src={
                                        event.creator_type !== 'system'
                                            ? avatarImgs[
                                                  avatarImgs.findIndex(
                                                      user => user.id === event.creator_id
                                                  )
                                              ].url
                                            : JadaLogo
                                    }
                                    alt={`${event.first_name} ${event.last_name || ''} `}
                                />
                            ) : (
                                <Avatar
                                    className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                                    name={`${event.first_name} ${event.last_name || ''}`}
                                    unstyled
                                />
                            )}
                        </>
                    ) : (
                        <Avatar
                            className="btn btn-icon btn-soft-primary rounded-circle mr-3"
                            name={`${event.first_name} ${event.last_name || ''} `}
                            unstyled
                        />
                    )}
                </div>
            </div>
            <div className="message-body">
                <span className="d-block font-weight-medium text-dark">
                    {`${event.first_name} ${event.last_name || ''} `}
                    <small className="text-muted">
                        {formatThreadTime(event.created_at.seconds)}
                    </small>
                </span>
                {event.automated ? (
                    <span>
                        {messages[event.message]}
                        <span
                            style={linkStyles}
                            onClick={() => titleClickHandler(event.automated)}
                        >
                            {event.automated?.name}
                        </span>
                    </span>
                ) : (
                    <p style={{ whiteSpace: 'pre-line' }}>{event.message}</p>
                )}
                {event.attachments &&
                    event.attachments.map(file => (
                        <div
                            style={{ width: '300px' }}
                            key={file.id}
                            onClick={() => titleClickHandler(file)}
                            className="message-attachment message-attachment-file"
                        >
                            <img
                                className="img-fluid"
                                src={file.type === 'document' ? PDF : file.path}
                                alt="File Description"
                            />
                            <span>{file.title}</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ActivityItem;
