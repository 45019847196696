// React
import React, { useCallback, useEffect, useReducer, useState } from 'react';

// Packages
import SimpleBar from 'simplebar-react';

// Redux
import { connect } from 'react-redux';
import { updatePreferenceUserDocuments } from '../../../store/actions/Preferences';

// Context
import { useLang } from '../../../context/LangContext';

// Utils
import { checkChanges, sortListByAlphabet } from '../../../utils/Helpers';

// Components
import ActionButtons from '../shared/ActionButtons';
import { BuyerDocuments } from './BuyerDocuments';
import { SellerDocuments } from './SellerDocuments';

const Documents = ({
    userData,
    filteredUserDocuments = [],
    filteredOrgDocuments = [],
    updateUserDocuments,
    setHasChanges
}) => {
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { tip_note } = useLang()['Settings']['Preferences']['Documents'];
    const allDocuments = filteredUserDocuments.concat(filteredOrgDocuments).slice();

    const [isDisabled, setIsDisabled] = useState(true);

    const documents = userData.preferences[userData.active_org_id].documents;

    const buyerDocs = documents ? documents.buyer.slice() : [];
    const sellerDocs = documents ? documents.seller.slice() : [];

    const buyerDocsIds = buyerDocs.map(doc => doc.id);
    const actualBuyerDocumentsCollection = allDocuments.map(buyerDoc => {
        return { ...buyerDoc, checked: buyerDocsIds.includes(buyerDoc.id) };
    });

    const sellerDocsIds = sellerDocs.map(doc => doc.id);
    const actualSellerDocumentsCollection = allDocuments.map(doc => {
        return { ...doc, checked: sellerDocsIds.includes(doc.id) };
    });

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_SELLER':
                return { ...state, seller: action.items };
            case 'UPDATE_BUYER':
                return { ...state, buyer: action.items };
            case 'REMOVE_BUYER':
                return { ...state, removedBuyer: action.items };
            case 'REMOVE_SELLER':
                return { ...state, removedSeller: action.items };
            case 'RESET':
                return {
                    buyer: actualBuyerDocumentsCollection,
                    seller: actualSellerDocumentsCollection,
                    removedBuyer: [],
                    removedSeller: []
                };
            default:
                return state;
        }
    };

    const [docs, dispatch] = useReducer(reducer, {
        buyer: actualBuyerDocumentsCollection,
        seller: actualSellerDocumentsCollection,
        removedBuyer: [],
        removedSeller: []
    });

    const handleUpdateSeller = items => {
        dispatch({ type: 'UPDATE_SELLER', items });
    };

    const handleUpdateBuyer = items => {
        dispatch({ type: 'UPDATE_BUYER', items });
    };

    const handleRemoveBuyer = items => {
        dispatch({ type: 'REMOVE_BUYER', items });
    };

    const handleRemoveSeller = items => {
        dispatch({ type: 'REMOVE_SELLER', items });
    };

    const handleReset = () => {
        dispatch({ type: 'RESET' });
    };

    const checkDisableButtons = useCallback(() => {
        const buyersChanged = checkChanges(
            sortListByAlphabet(actualBuyerDocumentsCollection),
            sortListByAlphabet(docs.buyer)
        );
        const sellersChanged = checkChanges(
            sortListByAlphabet(actualSellerDocumentsCollection),
            sortListByAlphabet(docs.seller)
        );

        const dataHasChanges = buyersChanged || sellersChanged;
        const collectionsAreEmpty =
            !docs.removedBuyer.length && !docs.removedSeller.length;

        setIsDisabled(!(dataHasChanges || !collectionsAreEmpty));
    }, [
        actualBuyerDocumentsCollection,
        docs.buyer,
        docs.removedBuyer.length,
        docs.removedSeller.length,
        docs.seller,
        actualSellerDocumentsCollection
    ]);

    useEffect(() => {
        checkDisableButtons();
    }, [docs.removedSeller, docs.removedBuyer, checkDisableButtons]);

    useEffect(() => {
        return handleReset();
    }, [filteredOrgDocuments, filteredUserDocuments, userData]);

    useEffect(() => {
        setHasChanges(!isDisabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled]);

    return (
        <>
            <div
                className="card-body"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <SimpleBar className="container pr-5">
                    <div style={{ maxWidth: '600px', margin: 'auto' }}>
                        <div className="pt-5 text-center">
                            <p className="text-wrap">{tip_note?.tip1}</p>
                        </div>
                        <div className="d-flex justify-content-between py-4">
                            <BuyerDocuments
                                initialDocuments={buyerDocs}
                                data={sortListByAlphabet(docs.buyer)}
                                setData={collection => {
                                    handleUpdateBuyer(collection);
                                }}
                                removeBuyer={docs.removedBuyer}
                                setRemoved={collection => {
                                    handleRemoveBuyer(collection);
                                }}
                                checkDisableButtons={checkDisableButtons}
                            />
                            <SellerDocuments
                                initialDocuments={sellerDocs}
                                data={sortListByAlphabet(docs.seller)}
                                setData={collection => {
                                    handleUpdateSeller(collection);
                                }}
                                removeSeller={docs.removedSeller}
                                setRemoved={collection => {
                                    handleRemoveSeller(collection);
                                }}
                                checkDisableButtons={checkDisableButtons}
                            />
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <ActionButtons
                cancelLabel={cancel_label}
                handleClose={() => handleReset()}
                handleSubmit={() => {
                    updateUserDocuments({
                        buyerDocs: docs.buyer.filter(doc => {
                            return doc.checked;
                        }),
                        sellerDocs: docs.seller.filter(doc => {
                            return doc.checked;
                        }),
                        userData: userData,
                        removeBuyerDocs: docs.removedBuyer,
                        removeSellerDocs: docs.removedSeller
                    });
                }}
                saveLabel={save_label}
                disabled={isDisabled}
                noCancel={isDisabled}
            />
        </>
    );
};

const mapStateToProps = ({ user, documents }) => {
    const { userData } = user;
    const { libraryList, documentList, filteredUserDocuments, filteredOrgDocuments } =
        documents;
    return {
        userData,
        libraryList,
        documentList,
        filteredUserDocuments,
        filteredOrgDocuments
    };
};

export default connect(mapStateToProps, {
    updateUserDocuments: updatePreferenceUserDocuments
})(Documents);
