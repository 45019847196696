// React
import React, { useState, useMemo } from 'react';

// Components
import {
    NewAddHeader,
    TransactionTypeSelect,
    BuyerTransactionFlow,
    ListingTransactionFlow
} from '../components';
import ConfirmationDialog from '../components/common/ConfirmationDialog';

// Router
import { Switch, Route, useHistory, withRouter, Redirect } from 'react-router-dom';
import * as routes from '../router/config/routes';

// Constants
import { confirmationDialogTypes } from '../utils/Constants';

const NewTransaction = props => {
    const history = useHistory();
    const [shouldConfirmDisplayed, setShouldConfirmDisplayed] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const prefilledParties = useMemo(() => props.location.state?.parties, []);

    const backRoute = useMemo(
        () =>
            prefilledParties
                ? `${routes.AUTHENTICATED}${routes.CONNECTION_DETAIL}/${prefilledParties[0].id}`
                : `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.TRANSACTIONS}`,
        [prefilledParties]
    );

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.cancel}
                confirmHandler={() => history.push(backRoute)}
            />
            <NewAddHeader
                returnRoute={backRoute}
                shouldConfirmDisplayed={shouldConfirmDisplayed}
            />

            <Switch>
                <Route
                    exact
                    path={`${props.match.url}`}
                    render={props => (
                        <TransactionTypeSelect
                            setShouldConfirmDisplayed={setShouldConfirmDisplayed}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={`${props.match.url}${routes.BUYER}`}
                    render={props => (
                        <BuyerTransactionFlow
                            prefilledParties={prefilledParties}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={`${props.match.url}${routes.LISTING}`}
                    render={props => (
                        <ListingTransactionFlow
                            prefilledParties={prefilledParties}
                            {...props}
                        />
                    )}
                />
                <Redirect to={routes.NOT_FOUND} />
            </Switch>
        </>
    );
};

export default withRouter(NewTransaction);
