import React, { useState } from 'react';

// Components
import TextField from './TextField';

// Loggers
import { log } from '../../utils/Loggers';

// Utils
import { lookupRegExp } from '../../utils/Constants';
import { getUsStateName } from '../../utils/Helpers';

// Smarty Streets
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usAutocomplete.Lookup;
const Address = SmartyStreetsSDK.usStreet.Lookup;

const AddressSuggestion = ({
    value,
    handleChange,
    setFieldValue,
    setFieldTouched,
    placeholder,
    fieldName,
    componentName,
    fieldNamePrefix
}) => {
    const key = process.env.REACT_APP_SMARTY_WEBSITE_KEY;
    const credentials = new SmartyStreetsCore.SharedCredentials(key);
    const lookupClient = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
    const addressClient = SmartyStreetsCore.buildClient.usStreet(credentials);
    const [lookupResults, setLookupResults] = useState(null);

    const addressInputHandler = address => {
        if (lookupRegExp.valid.test(address)) {
            const lookup = new Lookup(address);
            lookup.maxSuggestions = 6;
            lookupClient
                .send(lookup)
                .then(results => {
                    const { result } = results;
                    setLookupResults(result);
                })
                .catch(error => {
                    log(
                        'Smarty Streets Error: error in address lookup results (AddressSuggestion)',
                        {
                            error,
                            address,
                            function: 'addressInputHandler'
                        }
                    );
                });
        } else {
            setLookupResults(null);
        }
    };

    const getAddressFromLookup = address => {
        const batch = new SmartyStreetsCore.Batch();
        const verifiedAddress = new Address();
        verifiedAddress.street = address.streetLine;
        verifiedAddress.urbanization = '';
        verifiedAddress.city = address.city;
        verifiedAddress.state = address.state;
        verifiedAddress.maxCandidates = 3;
        verifiedAddress.match = 'invalid';
        batch.add(verifiedAddress);
        addressClient
            .send(batch)
            .then(result => {
                const { components, metadata } = result.lookups[0].result[0] || null;
                const city = components.cityName;
                const state = components.state;
                const zip = components.zipCode;
                const lat = metadata.latitude;
                const lon = metadata.longitude;
                const county = metadata.countyName;

                const shortAddress = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                }`;

                const address1 = `${components.primaryNumber}${
                    components.streetPredirection
                        ? ' ' + components.streetPredirection
                        : ''
                }${components.streetName ? ' ' + components.streetName : ''}${
                    components.streetSuffix ? ' ' + components.streetSuffix : ''
                }${
                    components.streetPostdirection
                        ? ' ' + components.streetPostdirection
                        : ''
                }, ${city || ''} ${state || ''} ${zip || ''}`;

                const address2 = components.secondaryNumber
                    ? `${components.secondaryNumber} `
                    : '';

                if (componentName === 'EditMappingsFormContent') {
                    setFieldValue(fieldName, address1.trimEnd());
                    setFieldValue(
                        `mappings.${fieldNamePrefix}_address_1`,
                        shortAddress.trimEnd()
                    );
                    setFieldValue(
                        `mappings.${fieldNamePrefix}_address_2`,
                        address2.trimEnd()
                    );
                    setFieldValue(`mappings.${fieldNamePrefix}_address_city`, city || '');
                    setFieldValue(
                        `mappings.${fieldNamePrefix}_address_state`,
                        getUsStateName(state) || ''
                    );
                    setFieldValue(`mappings.${fieldNamePrefix}_address_zip`, zip || '');
                } else {
                    setFieldTouched(fieldName, true);
                    setFieldValue(fieldName, shortAddress.trimEnd());
                    setFieldValue('shortAddress', shortAddress.trimEnd());
                    setFieldValue('address', address1.trimEnd());
                    setFieldValue('address2', address2.trimEnd());
                    setFieldValue('city', city || '');
                    setFieldValue('state', getUsStateName(state) || '');
                    setFieldValue('zip', zip || '');
                    setFieldValue('lat', lat || '');
                    setFieldValue('lon', lon || '');
                    if (componentName === 'EditTrxPropertyFormContent') {
                        setFieldValue('county', county || '');
                        setFieldValue('mappings.property_address', address1.trimEnd());
                        setFieldValue('mappings.property_address_1', shortAddress);
                        setFieldValue('mappings.property_address_2', address2.trimEnd());
                        setFieldValue('mappings.property_address_city', city || '');
                        setFieldValue(
                            'mappings.property_address_state',
                            getUsStateName(state) || ''
                        );
                        setFieldValue('mappings.property_address_zip', zip || '');
                    }
                }
                setLookupResults(null);
            })
            .catch(error => {
                log(
                    'Smarty Streets Error: error in address lookup details (LISTING_TRX)',
                    {
                        error,
                        address,
                        function: 'getAddressFromLookup'
                    }
                );
            });
    };

    return (
        <>
            <TextField
                type="text"
                className="form-control office-address"
                name={fieldName}
                id="address"
                placeholder={placeholder}
                value={value}
                autoComplete="off"
                onChange={e => {
                    handleChange(e);
                    addressInputHandler(e.target.value);
                }}
                hasDefaultFill
            />
            {lookupResults && !!lookupResults.length && (
                <ul className="office-address-list address-suggestions">
                    {lookupResults.map(result => (
                        <li
                            key={result.text}
                            className="office-address-list__item"
                            onClick={() => {
                                getAddressFromLookup(result);
                            }}
                        >
                            {result.text}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default AddressSuggestion;
