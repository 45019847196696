import React, { useEffect, useState } from 'react';

// Components
import { Label } from 'reactstrap';
import { AddressSuggestion, StateCountrySelect } from '..';

// Constants
import { stateProvince } from '../../utils/Constants';
import { getUsStateName } from '../../utils/Helpers';

// Context
import { useLang } from '../../context/LangContext';

// Redux - Actions, Reducers, Sagas
import { getMlsData } from '../../store/actions/Property';
import { connect } from 'react-redux';

const EditTrxPropertyFormContent = ({
    values,
    dirty,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setShouldConfirmDisplayed,
    getMlsData,
    propertyData,
    loading
}) => {
    const {
        property_info,
        address_label,
        address_placeholder,
        city_label,
        city_placeholder,
        unit_label,
        unit_placeholder,
        state_label,
        zip_label,
        zip_placeholder,
        mls_number_label,
        mls_number_placeholder,
        property_found_label,
        property_not_found_label,
        mls_button_label
    } = useLang()['TransactionDetails']['EditTransactionDetails'];
    const [mlsNumber, setMlsNumber] = useState(values.mlsNumber);
    const [propertyFound, setPropertyFound] = useState(null);

    useEffect(() => {
        if (dirty) {
            setShouldConfirmDisplayed(true);
        }
    }, [dirty, setShouldConfirmDisplayed]);

    useEffect(() => {
        if (!loading) {
            if (propertyData && Object.keys(propertyData).length && touched.mls) {
                const {
                    UnitNumber,
                    City,
                    Coordinates,
                    PostalCode,
                    StateOrProvince,
                    StreetDirPrefix,
                    StreetDirSuffix,
                    StreetName,
                    StreetNumber,
                    StreetSuffix,
                    UnparsedAddress,
                    CountyOrParish
                } = propertyData;
                const address1 = `${StreetNumber ? StreetNumber + ' ' : ''}${
                    StreetDirPrefix ? StreetDirPrefix + ' ' : ''
                }${StreetName ? StreetName + ' ' : ''}${
                    StreetSuffix ? StreetSuffix + ' ' : ''
                }${StreetDirSuffix ? StreetDirSuffix + ' ' : ''}`;

                setPropertyFound(true);
                setFieldValue('address1', address1);
                setFieldValue('address2', UnitNumber || '');
                setFieldValue('city', City);
                setFieldValue('state', getUsStateName(StateOrProvince));
                setFieldValue('zip', PostalCode);
                setFieldValue('lat', Coordinates[1]);
                setFieldValue('lon', Coordinates[0]);
                setFieldValue('mlsNumber', mlsNumber);
                setFieldValue('fullAddress', UnparsedAddress);

                setFieldValue('mappings.property_address', UnparsedAddress);
                setFieldValue('mappings.property_address_1', address1);
                setFieldValue('mappings.property_address_2', UnitNumber || null);
                setFieldValue('mappings.property_address_city', City);
                setFieldValue(
                    'mappings.property_address_state',
                    getUsStateName(StateOrProvince)
                );
                setFieldValue('mappings.property_address_zip', PostalCode);

                setFieldValue('propertyData', propertyData);
                setFieldValue('county', CountyOrParish || '');
            } else if (values.mlsNumber !== mlsNumber) {
                setPropertyFound(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, propertyData]);

    const setMappingsPropertyAddress = () => {
        const address = `${values.address1} ${values.address2}, ${values.city} ${values.state} ${values.zip}`;
        setFieldValue('mappings.property_address', address);
    };

    const propertyDataHandler = () => {
        getMlsData({ mlsNumber });
    };

    const mlsNumberHandler = number => {
        setMlsNumber(number);
        if (!touched.mls) setFieldTouched('mls', true);
        if (number !== values.mlsNumber || !propertyData) {
            setPropertyFound(null);
        } else {
            setPropertyFound(!!(propertyData && Object.keys(propertyData).length));
        }
    };

    return (
        <>
            <p className="col-8 offset-2 text-center">{property_info}</p>
            <div className="row offset-1 col-8">
                <div className="col-md-8 mb-3">
                    <div className="js-form-message form-group">
                        <Label className="form-label" htmlFor="mlsLookup">
                            {mls_number_label}
                        </Label>
                        <div className="input-group-append">
                            <input
                                type="text"
                                className="form-control"
                                style={
                                    touched.mls
                                        ? {
                                              borderBottomRightRadius: 0,
                                              borderTopRightRadius: 0
                                          }
                                        : {}
                                }
                                name="mls"
                                id="mlsLookup"
                                placeholder={mls_number_placeholder}
                                aria-label={mls_number_placeholder}
                                aria-describedby="mlsLookup"
                                autoComplete="off"
                                value={mlsNumber}
                                onChange={e => mlsNumberHandler(e.target.value)}
                            />
                            {touched.mls && (
                                <button
                                    className="btn btn-primary"
                                    style={{
                                        borderBottomLeftRadius: 0,
                                        borderTopLeftRadius: 0
                                    }}
                                    type="button"
                                    id="mlsLookupButton"
                                    onClick={propertyDataHandler}
                                    disabled={!mlsNumber.length}
                                >
                                    {loading ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : propertyFound ? (
                                        <span className="fas fa-check" />
                                    ) : (
                                        mls_button_label
                                    )}
                                </button>
                            )}
                        </div>
                        {propertyFound !== null && (
                            <div
                                id="confirmProperty"
                                style={{
                                    animation: 'fadeIn .5s'
                                }}
                            >
                                <div className="row mb-0">
                                    <div className="col-md-12">
                                        <span
                                            className={
                                                propertyFound
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            }
                                        >
                                            {propertyFound
                                                ? `${property_found_label} - ${values.fullAddress}`
                                                : property_not_found_label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row offset-1 col-8">
                <div className="col-md-8 mb-3">
                    <div className="js-form-message form-group">
                        <Label className="form-label">{address_label}</Label>
                        <AddressSuggestion
                            value={values.address1}
                            fieldName="address1"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            placeholder={address_placeholder}
                            componentName={'EditTrxPropertyFormContent'}
                        />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="js-form-message form-group">
                        <Label className="form-label" htmlFor="address2">
                            {unit_label}
                        </Label>

                        <input
                            type="text"
                            className="form-control"
                            name="address2"
                            id="address2"
                            placeholder={unit_placeholder}
                            aria-label={unit_placeholder}
                            value={values.address2}
                            onBlur={() => {
                                setMappingsPropertyAddress();
                                handleBlur('address2');
                                setFieldValue(
                                    'mappings.property_address_2',
                                    values.address2
                                );
                            }}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row offset-1 col-10">
                <div className="col-md-6 mb-3">
                    <div className="js-form-message form-group">
                        <Label className="form-label" htmlFor="city">
                            {city_label}
                        </Label>
                        <input
                            type="text"
                            className="form-control"
                            name="city"
                            id="city"
                            placeholder={city_placeholder}
                            value={values.city}
                            aria-label={city_placeholder}
                            onBlur={() => {
                                setMappingsPropertyAddress();
                                handleBlur('city');

                                setFieldValue(
                                    'mappings.property_address_city',
                                    values.city
                                );
                            }}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label" htmlFor="state">
                                {state_label}
                            </Label>
                            <StateCountrySelect
                                className="form-control custom-select"
                                name="state"
                                id="state"
                                placeholder={state_label}
                                aria-label={state_label}
                                nestedOptions={true}
                                options={stateProvince}
                                value={values.state}
                                onBlur={() => {
                                    setMappingsPropertyAddress();
                                    handleBlur('state');

                                    setFieldValue(
                                        'mappings.property_address_state',
                                        values.state
                                    );
                                }}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="js-form-message form-group">
                        <Label className="form-label" htmlFor="zip">
                            {zip_label}
                        </Label>
                        <input
                            type="text"
                            className="form-control"
                            name="zip"
                            id="zip"
                            placeholder={zip_placeholder}
                            aria-label={zip_placeholder}
                            value={values.zip}
                            onBlur={() => {
                                setMappingsPropertyAddress();
                                handleBlur('zip');

                                setFieldValue(
                                    'mappings.property_address_zip',
                                    values.zip
                                );
                            }}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ property }) => {
    const { loading, propertyData } = property;
    return { loading, propertyData };
};

export default connect(mapStateToProps, { getMlsData })(EditTrxPropertyFormContent);
