import React from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';

export const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <div className="d-flex align-items-center">
                    <input
                        className="pointer ml-2"
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />
                    {''}
                    <label style={{ margin: '0', marginLeft: '10px', cursor: 'pointer' }}>
                        {props.label}
                    </label>
                </div>
            </components.Option>
        </div>
    );
};

export const MultiValue = props => (
    <components.MultiValue {...props}>{props.data.label}</components.MultiValue>
);

/**
 * Custom Value Container is created because we need separate values by commas and
 * also trim if content overflowed
 */
export const ValueContainer = ({ selectProps, children, ...props }) => {
    const input = children[1];
    let values = children[0];

    if (Array.isArray(values)) {
        values = selectProps.value.map(value => value.label).join(', ');
    }

    return (
        <components.ValueContainer {...props}>
            <div
                style={{
                    maxWidth: '90%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}
            >
                {values}
            </div>
            {input}
        </components.ValueContainer>
    );
};

/**
 * We passing styles prop to MultiSelect component
 * which indicates what style we prefer.
 * In trx details we use plain style. In settings-team we use default style with
 * few changes
 */
const getStyles = (style, isDisabled) => {
    switch (style) {
        case 'memberDetails':
            return {
                container: base => ({
                    ...base,
                    width: '100%'
                }),
                control: () => ({
                    border: '1px solid #eee',
                    borderRadius: '5px',
                    position: 'relative'
                }),
                valueContainer: base => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    paddingLeft: '10px',
                    height: '50px'
                }),
                indicatorsContainer: base => ({
                    ...base,
                    position: 'absolute',
                    right: 35,
                    top: '50%',
                    transform: 'translateY(-50%)'
                }),
                multiValue: base => ({
                    ...base,
                    maxHeight: '20px',
                    alignItems: 'center'
                }),
                multiValueLabel: base => ({ ...base }),
                multiValueRemove: base => ({
                    ...base,
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                dropdownIndicator: base => ({
                    ...base,
                    position: 'absolute',
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                clearIndicator: () => ({
                    display: 'none'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                })
            };
        case 'taskModal':
            return {
                container: base => ({
                    ...base,
                    width: '100%'
                }),
                control: () => ({
                    border: '1px solid rgba(0, 201, 167, 0.5)',
                    borderRadius: '5px',
                    position: 'relative'
                }),
                valueContainer: base => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    paddingLeft: '10px'
                }),
                indicatorsContainer: base => ({
                    ...base,
                    position: 'absolute',
                    right: 35,
                    top: '50%',
                    transform: 'translateY(-50%)'
                }),
                multiValue: base => ({
                    ...base,
                    maxHeight: '20px',
                    alignItems: 'center'
                }),
                multiValueLabel: base => ({ ...base }),
                multiValueRemove: base => ({
                    ...base,
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                dropdownIndicator: base => ({
                    ...base,
                    position: 'absolute',
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                clearIndicator: () => ({
                    display: 'none'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                })
            };
        case 'messages':
            return {
                container: base => ({
                    ...base,
                    width: '100%'
                }),
                control: () => ({
                    border: '1px solid #eee',
                    borderRadius: '5px',
                    position: 'relative'
                }),
                valueContainer: base => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    paddingLeft: '10px'
                }),
                indicatorsContainer: base => ({
                    ...base,
                    position: 'absolute',
                    right: 35,
                    top: '50%',
                    transform: 'translateY(-50%)'
                }),
                multiValue: base => ({
                    ...base,
                    maxHeight: '20px',
                    alignItems: 'center'
                }),
                multiValueLabel: base => ({ ...base }),
                multiValueRemove: base => ({
                    ...base,
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                dropdownIndicator: base => ({
                    ...base,
                    position: 'absolute',
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                clearIndicator: () => ({
                    display: 'none'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                })
            };
        case 'plain':
            return {
                container: () => ({
                    width: '100%'
                }),
                placeholder: base => ({
                    ...base,
                    margin: '0',
                    color: '#8c98a4'
                }),
                input: base => ({
                    ...base,
                    transform: 'translateX(-10px)'
                }),
                valueContainer: base => ({
                    ...base,
                    width: '100px',
                    paddingLeft: '0',
                    gap: '10px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flexWrap: 'nowrap',
                    div: {
                        paddingLeft: '0'
                    },
                    span: {
                        paddingLeft: '0',
                        fontSize: '16px',
                        color: '#505050'
                    }
                }),
                dropdownIndicator: () => ({
                    display: 'none'
                }),
                clearIndicator: () => ({
                    display: 'none'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                }),
                menu: () => ({
                    width: '400px',
                    height: 'fit-content',
                    maxHeight: '250px',
                    backgroundColor: '#fff',
                    color: '#8c98a4',
                    marginTop: '5px',
                    position: 'absolute',
                    boxShadow: '0px 5px 9px 2px rgba(0, 0, 0, 0.11)',
                    borderRadius: '5px',
                    zIndex: '10'
                }),
                option: () => ({
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    padding: '5px',
                    '&:hover': {
                        backgroundColor: '#eee'
                    }
                }),
                multiValue: () => ({}),
                multiValueRemove: () => ({
                    display: 'none'
                }),
                control: () => ({
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                    padding: '0',
                    borderRadius: '5px',
                    outline: 'none'
                })
            };
        case 'badges':
            return {
                container: () => ({
                    width: '90%',
                    maxHeight: '32px',
                    cursor: isDisabled ? 'default' : 'pointer'
                }),
                control: () => ({
                    border: '1px solid #eee',
                    borderRadius: '5px',
                    maxHeight: '32px',
                    position: 'relative'
                }),
                valueContainer: base => ({
                    ...base,
                    width: '80%',
                    display: 'flex',
                    alignItems: 'center',
                    maxHeight: '32px',
                    padding: '0',
                    paddingLeft: '10px'
                }),
                indicatorsContainer: base => ({
                    ...base,
                    position: 'absolute',
                    right: 35,
                    top: 15
                }),
                multiValue: base => ({
                    ...base,
                    maxHeight: '20px',
                    alignItems: 'center'
                }),
                multiValueLabel: base => ({ ...base }),
                multiValueRemove: base => ({
                    ...base,
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                dropdownIndicator: () => ({
                    position: 'absolute',
                    svg: {
                        margin: '0',
                        marginTop: '5px',
                        padding: '0'
                    }
                }),
                clearIndicator: () => ({
                    display: 'none'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                })
            };
        default:
            return {
                container: base => ({
                    ...base,
                    width: '100%',
                    maxHeight: '32px'
                }),
                control: () => ({
                    border: '1px solid #eee',
                    borderRadius: '5px',
                    maxHeight: '32px',
                    position: 'relative'
                }),
                valueContainer: base => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                    maxHeight: '32px',
                    padding: '0',
                    paddingLeft: '10px'
                }),
                indicatorsContainer: base => ({
                    ...base,
                    position: 'absolute',
                    right: 35,
                    top: 15
                }),
                multiValue: base => ({
                    ...base,
                    maxHeight: '20px',
                    alignItems: 'center'
                }),
                multiValueLabel: base => ({ ...base }),
                multiValueRemove: base => ({
                    ...base,
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                dropdownIndicator: base => ({
                    ...base,
                    position: 'absolute',
                    svg: {
                        margin: '0',
                        padding: '0'
                    }
                }),
                clearIndicator: () => ({
                    display: 'none'
                }),
                indicatorSeparator: () => ({
                    display: 'none'
                })
            };
    }
};

const MultiSelect = props => {
    const customStyles = getStyles(props.styles, props.disabled);

    if (!props.editable) {
        return <span>{props.value}</span>;
    }

    return (
        <ReactSelect
            {...props}
            isDisabled={props.disabled}
            styles={customStyles}
            options={
                props.allowSelectAll ? [props.allOption, ...props.options] : props.options
            }
            onChange={(selected, event) => {
                if (selected !== null && selected.length > 0 && props.allowSelectAll) {
                    if (selected[selected.length - 1].value === props.allOption.value) {
                        return props.onChange([props.allOption, ...props.options]);
                    }
                    let result = [];
                    if (selected.length === props.options.length) {
                        if (selected.some(el => el.value === props.allOption.value)) {
                            result = selected.filter(
                                option => option.value !== props.allOption.value
                            );
                        } else if (event.action === 'select-option') {
                            result = [props.allOption, ...props.options];
                        }
                        return props.onChange(result);
                    }
                }
                return props.onChange(selected);
            }}
        />
    );
};

MultiSelect.propTypes = {
    outline: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

/**
 * In Future we may need to add option to Select All
 **/
MultiSelect.defaultProps = {
    allOption: {
        label: 'Select all',
        value: '*'
    }
};

export default MultiSelect;
