import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import connectionsSagas from './Connections';
import contactsSagas from './CompanyContacts';
import documentSagas from './Documents';
import documenFillingSagas from './DocumentFilling';
import documenSigningSagas from './DocumentSigning';
import invitationSagas from './Invitations';
import langSagas from './Lang';
import messagesSagas from './Messages';
import orgSagas from './Org';
import pipelineSagas from './Pipeline';
import preferenceSagas from './Preferences';
import propertySagas from './Property';
import settingsSagas from './Settings';
import superAdminSagas from './SuperAdmin';
import teamsSagas from './Teams';
import transactionActivitySagas from './TransactionActivity';
import transactionSagas from './Transactions';
import transactionInvitationsSagas from './TransactionInvitations';
import userSagas from './User';

export default function* rootSaga() {
    yield all([
        authSagas(),
        connectionsSagas(),
        contactsSagas(),
        documentSagas(),
        documenFillingSagas(),
        documenSigningSagas(),
        invitationSagas(),
        langSagas(),
        messagesSagas(),
        orgSagas(),
        pipelineSagas(),
        preferenceSagas(),
        propertySagas(),
        settingsSagas(),
        superAdminSagas(),
        teamsSagas(),
        transactionActivitySagas(),
        transactionSagas(),
        transactionInvitationsSagas(),
        userSagas()
    ]);
}
