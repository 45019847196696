import {
    SET_PIPELINE_CONTACTS,
    ADD_NEW_PIPELINE_CONTACT,
    ADD_NEW_PIPELINE_CONTACT_SUCCESS,
    ADD_NEW_PIPELINE_CONTACT_FAILURE,
    EDIT_PIPELINE_CONTACT,
    REMOVE_PIPELINE_CONTACT,
    REMOVE_PIPELINE_CONTACT_SUCCESS,
    REMOVE_PIPELINE_CONTACT_FAILURE
} from './types';

export const storingPipelineContacts = contacts => {
    return {
        type: SET_PIPELINE_CONTACTS,
        payload: contacts
    };
};

export const addNewPipelineContact = contact => {
    return {
        type: ADD_NEW_PIPELINE_CONTACT,
        payload: contact
    };
};

export const editPipelineContact = contact => {
    return {
        type: EDIT_PIPELINE_CONTACT,
        payload: contact
    };
};

export const addPipelineContactSuccess = () => {
    return {
        type: ADD_NEW_PIPELINE_CONTACT_SUCCESS
    };
};

export const addPipelineContactFailure = error => {
    return {
        type: ADD_NEW_PIPELINE_CONTACT_FAILURE,
        payload: error
    };
};

export const removePipelineContact = data => {
    return {
        type: REMOVE_PIPELINE_CONTACT,
        payload: data
    };
};

export const removePipelineContactSuccess = () => {
    return {
        type: REMOVE_PIPELINE_CONTACT_SUCCESS
    };
};

export const removePipelineContactFailure = error => {
    return {
        type: REMOVE_PIPELINE_CONTACT_FAILURE,
        payload: error
    };
};
