import {
    all,
    fork,
    takeLatest,
    put,
    take,
    call,
    race,
    select,
    cancelled
} from 'redux-saga/effects';

import {
    LOGOUT_USER,
    GET_USER_MESSAGES,
    SEND_USER_MESSAGE,
    SEND_USER_THREAD_MESSAGE,
    REMOVE_USER_MESSAGE,
    SAVE_USER_MESSAGE,
    REMOVE_SAVED_USER_MESSAGE,
    DRAFT_USER_MESSAGE,
    REMOVE_USER_DRAFT_MESSAGE,
    SEEN_USER_MESSAGE
} from './../actions/types';
import { setUserMessages } from './../actions/Messages';
import * as selectors from './Selectors';

import {
    db,
    fsFieldValue,
    rtdb,
    timeStampNow,
    timeStampNowSeconds
} from '../../config/Firebase';
import { buffers, eventChannel } from 'redux-saga';

// Loggers
import { log } from '../../utils/Loggers';
import { generateUid } from '../../utils/Helpers';
const users = db.collection('users');
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update User Messages //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* messagesCollectionWatch(user) {
    const messagesRef = rtdb.ref(`messages/${user.active_org_id}`);

    const AddedMessagesChannel = eventChannel(emit => {
        const unsubscribeUserMessagesData = messagesRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot) {
                    if (
                        childSnapshot.val()?.all_members ||
                        (childSnapshot.val()?.members &&
                            childSnapshot.val()?.members[user.id])
                    ) {
                        if (
                            !(
                                childSnapshot.val()?.removed &&
                                childSnapshot.val()?.removed[user.id]
                            )
                        ) {
                            emit(childSnapshot.val());
                        }
                    }
                }
            }
        );
        return unsubscribeUserMessagesData;
    });

    const ChangedMessagesChannel = eventChannel(emit => {
        const unsubscribeUserMessagesData = messagesRef.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot) {
                    if (
                        childSnapshot.val().all_members ||
                        (childSnapshot.val()?.members &&
                            childSnapshot.val()?.members[user.id]) ||
                        (childSnapshot.val()?.removed &&
                            childSnapshot.val()?.removed[user.id])
                    ) {
                        emit(childSnapshot.val());
                    }
                }
            }
        );
        return unsubscribeUserMessagesData;
    }, buffers.expanding());

    const detachSagaEmitters = () => {
        AddedMessagesChannel.close();
        ChangedMessagesChannel.close();
    };

    const detachFBListeners = () => {
        messagesRef.off('child_added');
        messagesRef.off('child_changed');
    };

    try {
        while (true) {
            const currentMessages = yield select(selectors._userMessages);
            const messages = currentMessages ? [...currentMessages] : [];

            const getMsgIndex = msg => {
                const index = messages.findIndex(arrMsg => arrMsg.id === msg.id);
                return index;
            };

            let index;

            const { userSignOut, addedUserMessagesData, changedUserMessagesData } =
                yield race({
                    userSignOut: take(LOGOUT_USER),
                    addedUserMessagesData: take(AddedMessagesChannel),
                    changedUserMessagesData: take(ChangedMessagesChannel)
                });

            if (userSignOut) {
                detachSagaEmitters(); // Detaching saga event emitters
            } else if (addedUserMessagesData) {
                index = getMsgIndex(addedUserMessagesData);
                if (index < 0) messages.push(addedUserMessagesData);
                yield put(setUserMessages({ messages, userId: user.id }));
            } else if (changedUserMessagesData) {
                index = getMsgIndex(changedUserMessagesData);
                if (index < 0) {
                    messages.push(changedUserMessagesData);
                } else {
                    if (
                        changedUserMessagesData?.removed &&
                        changedUserMessagesData?.removed[user.id]
                    ) {
                        messages.splice(index, 1);
                    } else {
                        messages[index] = changedUserMessagesData;
                    }
                }
                yield put(setUserMessages({ messages, userId: user.id }));
            }
        }
    } catch (error) {
        log('Messages Error: getting user messages (RTDB)', {
            error,
            user
        });
    } finally {
        detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            detachFBListeners(); // Detaching firebase listeners
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Send User Messages //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const sendUserMessageRequest = ({
    isReply,
    members,
    messageText,
    userData,
    messageTitle,
    isDraftTab,
    id
}) => {
    const messagesObj = rtdb.ref(`messages/${userData.active_org_id}`).push();
    const messageId = messagesObj.key;
    const messageRef = rtdb.ref(`messages/${userData.active_org_id}/${messageId}`);
    const message = {
        all_members: members.includes('allMembers'),
        updated_at: timeStampNow(),
        id: messageId,
        messageTitle,
        members: Object.fromEntries([
            ...members
                .filter(member => member !== 'allMembers')
                .map(item => [item, true]),
            [userData.id, true]
        ]),
        posts: {
            [timeStampNowSeconds()]: {
                content: messageText,
                created_at: timeStampNow(),
                creator_id: userData.id,
                creator_name: `${userData.first_name} ${userData.last_name}`
            }
        },
        removed: {},
        seen: { [userData.id]: true },
        thread: isReply
    };

    return new Promise((resolve, reject) =>
        messageRef
            .set({ ...message })
            .then(
                () =>
                    isDraftTab &&
                    removeUserDraftMessageRequest({ userData, messageIds: [id] })
            )
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            })
    );
};

export function* sendUserMessage({ payload }) {
    const { isReply, members, messageText, messageTitle, isDraftTab, id } = payload;
    const userData = yield select(selectors._userData);
    const { error } = yield call(() =>
        sendUserMessageRequest({
            isReply,
            members,
            messageText,
            userData,
            messageTitle,
            isDraftTab,
            id
        })
    );
    if (error) {
        log('Messages Error: sending user messages (RTDB)', {
            error,
            isReply,
            members,
            messageText,
            isDraftTab,
            senderId: userData.id
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Send User Thread Messages //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const sendUserThreadMessageRequest = ({ messageThreadText, userData, messageId }) => {
    const messageThreadRef = rtdb.ref(`messages/${userData.active_org_id}/${messageId}`);
    const updateMessageTime = timeStampNow();
    const post = {
        content: messageThreadText,
        created_at: updateMessageTime,
        creator_id: userData.id,
        creator_name: `${userData.first_name} ${userData.last_name}`
    };

    return new Promise((resolve, reject) =>
        messageThreadRef
            .update({
                [`posts/${timeStampNowSeconds()}`]: post,
                updated_at: updateMessageTime,
                seen: { [userData.id]: true }
            })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            })
    );
};

export function* sendUserThreadMessage({ payload }) {
    const { isReply, members, messageThreadText } = payload;
    const userData = yield select(selectors._userData);
    const { error } = yield call(() =>
        sendUserThreadMessageRequest({ ...payload, userData })
    );
    if (error) {
        log('Messages Error: sending user thread messages (RTDB)', {
            error,
            isReply,
            members,
            messageThreadText,
            senderId: userData.id
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Remove User Messages //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const removeUserMessageRequest = ({ messageIds, userData }) => {
    const messageRef = rtdb.ref(`messages/${userData.active_org_id}`);
    const items = messageIds
        .map(id => [
            [`${id}/members/${userData.id}`, null],
            [`${id}/removed/${userData.id}`, true],
            [`${id}/saved/${userData.id}`, false]
        ])
        .flat();
    return new Promise((resolve, reject) =>
        messageRef.update({ ...Object.fromEntries(items) }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        })
    );
};

export function* removeUserMessage({ payload }) {
    const { messageIds } = payload;
    const userData = yield select(selectors._userData);

    const { error } = yield call(() =>
        removeUserMessageRequest({ messageIds, userData })
    );
    if (error) {
        log('Messages Error: removing user messages (RTDB)', {
            error,
            userData: userData.id,
            messageIds
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Save User Messages //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const saveUserMessageRequest = ({ messageIds, userData }) => {
    const messageRef = rtdb.ref(`messages/${userData.active_org_id}`);
    const items = messageIds.map(id => [`${id}/saved/${userData.id}`, true]);
    return new Promise((resolve, reject) =>
        messageRef
            .update({ ...Object.fromEntries(items) })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }))
    );
};

export function* saveUserMessage({ payload }) {
    const { messageIds } = payload;
    const userData = yield select(selectors._userData);

    const { error } = yield call(() => saveUserMessageRequest({ messageIds, userData }));
    if (error) {
        log('Messages Error: save user messages (RTDB)', {
            error,
            userData: userData.id,
            messageIds
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Remove Saved User Messages //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const removeSavedUserMessageRequest = ({ userData, messageIds }) => {
    const messageRef = rtdb.ref(`messages/${userData.active_org_id}`);
    const items = messageIds.map(id => [`${id}/saved/${userData.id}`, false]);
    return new Promise((resolve, reject) =>
        messageRef
            .update({ ...Object.fromEntries(items) })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }))
    );
};

export function* removeSavedUserMessage({ payload }) {
    const { messageIds } = payload;
    const userData = yield select(selectors._userData);
    const { error } = yield call(() =>
        removeSavedUserMessageRequest({
            userData,
            messageIds
        })
    );
    if (error) {
        log('Messages Error: remove users messages from Pinn (RTDB)', {
            error,
            senderId: userData.id
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Draft User Messages /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const draftUserMessageRequest = ({
    isReply,
    members,
    messageText,
    userData,
    messageTitle,
    messageId
}) => {
    const id = messageId || generateUid();
    const messageRef = users.doc(userData.id);

    const messageDraft = {
        all_members: members.includes('allMembers'),
        updated_at: timeStampNow(),
        id,
        messageTitle,
        members: Object.fromEntries([
            ...members
                .filter(member => member !== 'allMembers')
                .map(item => [item, true]),
            [userData.id, true]
        ]),
        posts: {
            [timeStampNowSeconds()]: {
                content: messageText,
                created_at: timeStampNow(),
                creator_id: userData.id,
                creator_name: `${userData.first_name} ${userData.last_name}`
            }
        },
        removed: {},
        seen: {},
        thread: isReply
    };

    return new Promise((resolve, reject) =>
        messageRef
            .update({ [`draft_messages.${userData.active_org_id}.${id}`]: messageDraft })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }))
    );
};

export function* draftUserMessage({ payload }) {
    const { isReply, members, messageText, messageTitle, messageId } = payload;
    const userData = yield select(selectors._userData);
    const { error } = yield call(() =>
        draftUserMessageRequest({
            isReply,
            members,
            messageText,
            userData,
            messageTitle,
            messageId
        })
    );
    if (error) {
        log('Messages Error: draft user messages (DB)', {
            error,
            isReply,
            members,
            messageText,
            senderId: userData.id
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Remove Draft User Messages //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const removeUserDraftMessageRequest = ({ userData, messageIds }) => {
    const messageRef = users.doc(userData.id);
    const item = messageIds.map(id => [
        `draft_messages.${userData.active_org_id}.${id}`,
        fsFieldValue.delete()
    ]);

    return new Promise((resolve, reject) =>
        messageRef
            .update({
                ...Object.fromEntries(item)
            })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }))
    );
};

export function* removeUserDraftMessage({ payload }) {
    const { messageIds } = payload;
    const userData = yield select(selectors._userData);
    const { error } = yield call(() =>
        removeUserDraftMessageRequest({
            userData,
            messageIds
        })
    );
    if (error) {
        log('Messages Error: remove users draft messages (DB)', {
            error,
            senderId: userData.id
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Seen User Messages //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const seenUserMessageRequest = ({ messageId, userData }) => {
    const messageRef = rtdb.ref(`messages/${userData.active_org_id}`);

    return new Promise((resolve, reject) =>
        messageRef.update({ [`${messageId}/seen/${userData.id}`]: true }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        })
    );
};

export function* seenUserMessage({ payload }) {
    const { messageId } = payload;
    const userData = yield select(selectors._userData);

    const { error } = yield call(() => seenUserMessageRequest({ messageId, userData }));
    if (error) {
        log('Messages Error: seen user messages (RTDB)', {
            error,
            userData: userData.id,
            messageId
        });
    }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* updatingUserMessages() {
    yield takeLatest(GET_USER_MESSAGES, messagesCollectionWatch);
}
export function* sendingUserMessage() {
    yield takeLatest(SEND_USER_MESSAGE, sendUserMessage);
}
export function* sendingUserThreadMessage() {
    yield takeLatest(SEND_USER_THREAD_MESSAGE, sendUserThreadMessage);
}
export function* removingUserMessage() {
    yield takeLatest(REMOVE_USER_MESSAGE, removeUserMessage);
}
export function* savingUserMessage() {
    yield takeLatest(SAVE_USER_MESSAGE, saveUserMessage);
}
export function* removingSavedUserMessage() {
    yield takeLatest(REMOVE_SAVED_USER_MESSAGE, removeSavedUserMessage);
}
export function* draftingUserMessage() {
    yield takeLatest(DRAFT_USER_MESSAGE, draftUserMessage);
}
export function* removingUserDraftsMessage() {
    yield takeLatest(REMOVE_USER_DRAFT_MESSAGE, removeUserDraftMessage);
}
export function* seeUserMessage() {
    yield takeLatest(SEEN_USER_MESSAGE, seenUserMessage);
}

export default function* rootSaga() {
    yield all([
        fork(updatingUserMessages),
        fork(sendingUserMessage),
        fork(sendingUserThreadMessage),
        fork(removingUserMessage),
        fork(savingUserMessage),
        fork(removingSavedUserMessage),
        fork(draftingUserMessage),
        fork(removingUserDraftsMessage),
        fork(seeUserMessage)
    ]);
}
