import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';

import {
    UPDATE_USER_AVATAR,
    UPDATE_PERSONAL_INFO,
    UPDATE_ORG_TEAM,
    CREATE_USER_TEAM,
    UPDATE_USER_TEAM
} from '../actions/types';

import {
    uploadUserAvatarSuccess,
    uploadUserAvatarFailure,
    personalInfoUpdateSuccess,
    personalInfoUpdateFailure
} from '../actions/Settings';

import { setConfirmModalType } from '../actions/Modal';

import { storage, db } from '../../config/Firebase';

import * as selectors from './Selectors';

// Constants
import { confirmationDialogTypes } from '../../utils/Constants';

// Loggers
import { log } from '../../utils/Loggers';

// Helpers
import { generateUid } from '../../utils/Helpers';

const users = db.collection('users');
const orgs = db.collection('orgs');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update User Avatar //////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const updateUserAvatarRequest = async ({ img, userData }) => {
    const ref = storage.ref();
    const avatarRef = ref.child('users').child(userData.id).child('avatar.jpg');
    try {
        const blob = await fetch(img).then(r => r.blob());
        const snapshot = await avatarRef.put(blob);
        const url = await snapshot.ref.getDownloadURL();
        return { downloadUrl: url };
    } catch (error) {
        return { error };
    }
};

export function* updateUserAvatar({ payload }) {
    const { img } = payload;
    const userData = yield select(selectors._userData);
    const { downloadUrl, error } = yield call(() =>
        updateUserAvatarRequest({ img, userData })
    );
    if (downloadUrl) {
        yield call(updateUserUrl, { url: downloadUrl });
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Settings Error: adding/updating user avatar (FBS)', {
            error,
            img
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update User URL /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateUserUrlRequest = async ({ url, userData }) => {
    const ref = users.doc(userData.id);
    return new Promise((resolve, reject) => {
        ref.update({
            user_avatar: url
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateUserUrl({ url }) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() => updateUserUrlRequest({ url, userData }));
    if (res) {
        yield put(uploadUserAvatarSuccess());
    } else {
        yield put(uploadUserAvatarFailure(error));
        // Error Handling for sentry with put and maybe UI message
        log('Settings Error: adding/updating user avatar url (FS)', {
            error,
            url
        });
    }
}

const updateUserTeamRequest = async ({ payload, userData }) => {
    const ref = users.doc(userData.id);
    const path = `teams.${payload.id}`;
    return new Promise((resolve, reject) => {
        ref.update({
            [path]: payload
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

const updateOrgTeamRequest = async ({ payload, userData }) => {
    const ref = orgs.doc(userData.active_org_id);
    const path = `teams.${payload.id}`;
    return new Promise((resolve, reject) => {
        ref.update({
            [path]: payload
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

const createUserTeamRequest = async ({ payload, userData }) => {
    const ref = users.doc(userData.id);
    const id = generateUid();
    const path = `teams.${id}`;
    return new Promise((resolve, reject) => {
        ref.update({
            [path]: payload
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updateUserTeam({ payload }) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() => updateUserTeamRequest({ payload, userData }));
    if (res) {
        console.log('Success');
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Team Error: updating user team (FS)', {
            error
        });
    }
}

export function* updateOrgTeam({ payload }) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() => updateOrgTeamRequest({ payload, userData }));
    if (res) {
        console.log('Success');
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Team Error: updating org team (FS)', {
            error
        });
    }
}

export function* createUserTeam({ payload }) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() => createUserTeamRequest({ payload, userData }));
    if (res) {
        console.log('Success');
    } else {
        log('Team Error: creating user team (FS)', {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Update Personal Info ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updatePersonalInfoRequest = ({ data }) => {
    const ref = users.doc(data.id);
    return ref
        .update({
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            address: data.address,
            agent_lic: !data.isBroker && data.type === 'member' ? data.licNumber : null,
            broker_lic: data.isBroker ? data.licNumber : null
        })
        .then(() => ({ res: true }))
        .catch(error => ({ error }));
};

export function* updatePersonalInfo({ payload }) {
    const { data } = payload;
    const { res, error } = yield updatePersonalInfoRequest({ data });
    if (res) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
        yield put(personalInfoUpdateSuccess());
    } else {
        yield put(personalInfoUpdateFailure(error));
        log('Settings Error: updating personal info settings (FS)', {
            error,
            data
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* updatingUserAvatar() {
    yield takeLatest(UPDATE_USER_AVATAR, updateUserAvatar);
}

export function* updatingPersonalInfo() {
    yield takeLatest(UPDATE_PERSONAL_INFO, updatePersonalInfo);
}

export function* updatingUserTeam() {
    yield takeLatest(UPDATE_USER_TEAM, updateUserTeam);
}

export function* updatingOrgTeam() {
    yield takeLatest(UPDATE_ORG_TEAM, updateOrgTeam);
}

export function* creatingUserTeam() {
    yield takeLatest(CREATE_USER_TEAM, createUserTeam);
}

export default function* rootSaga() {
    yield all([
        fork(updatingUserAvatar),
        fork(updatingPersonalInfo),
        fork(updatingUserTeam),
        fork(updatingOrgTeam),
        fork(creatingUserTeam)
    ]);
}
