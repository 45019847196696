import React, { useState, useRef } from 'react';
import { useLang } from '../../context/LangContext';
import pen from '../../assets/img/Pen-icon.png';
import SignaturePad from 'react-signature-canvas';

const SigningTab = ({ setSigningData, type }) => {
    const { sign_label, initial_label, next_buttonLabel, clean_buttonLabel } =
        useLang()['Documents']['FillingDocument']['SigningDocument'];
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const signatureRef = useRef(null);

    const nextClickHandler = () => {
        setSigningData(signatureRef.current.toDataURL());
    };

    const cleanClickHandler = () => {
        signatureRef.current.clear();
        setIsNextDisabled(true);
    };

    const onPaintingStart = () => {
        if (isNextDisabled) setIsNextDisabled(false);
    };

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: '90%' }}
        >
            <span style={{ fontSize: '12px', fontWeight: 500 }}>
                {type === 'signature' ? sign_label : initial_label}
            </span>
            <SignaturePad
                canvasProps={{
                    style: {
                        width: '100%',
                        height: '100%',
                        cursor: `url(${pen}) 2 30, pointer`
                    }
                }}
                onBegin={onPaintingStart}
                ref={signatureRef}
            />
            <button
                className="btn btn-sm bottom-buttons clean-button"
                onClick={cleanClickHandler}
            >
                {clean_buttonLabel}
            </button>
            <button
                disabled={isNextDisabled}
                className="btn btn-sm bottom-buttons next-button"
                onClick={nextClickHandler}
            >
                {next_buttonLabel}
            </button>
        </div>
    );
};

export default SigningTab;
