import {
    all,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    call,
    select
} from 'redux-saga/effects';

import {
    GET_USER_ORG,
    LOGOUT_USER,
    UPDATE_ORG_LOCATION,
    PREPARE_MEMBER_INVITATIONS,
    WRITE_SEND_INVITATIONS,
    GET_ORG_INVITATIONS,
    UPDATE_ORG_MEMBERS_STATUS,
    SAVE_ORG_MEMBERS_DETAILS,
    REMOVE_ORG_MEMBER,
    REMOVE_ORG_INVITATIONS
} from '../actions/types';

import { eventChannel } from 'redux-saga';

import {
    storeOrgData,
    updateOrgLocationSuccess,
    updateOrgLocationFailure,
    setPreparedInvitations,
    orgInvitationSuccess,
    orgInvitationFailure,
    setOrgInvitations
} from '../actions/Org';

import { setConfirmModalType } from '../actions/Modal';

import { db, rtdb, docId, timeStampNow, func } from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';
import { generateUid } from '../../utils/Helpers';
import { confirmationDialogTypes } from '../../utils/Constants';

import * as selectors from './Selectors';
import { confirmSaga } from './Modal';

const orgs = db.collection('orgs');
const users = db.collection('users');
const inviteNewMembers = func.httpsCallable('inviteNewMemberRequest');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Organization ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* orgCollectionWatch(user) {
    /************************************ IMPORTANT ************************************/
    /*** We must be cognizant of this limitation in the future if any User will have ***
     *** more than 10 Orgs they are connected to due to Firebase only allowing up to ***
     *** 10 comparisons in an "in" query ***/
    /***********************************************************************************/

    let unsubscribeUserOrgData;
    const userOrgs = orgs => {
        const orgIds = [];
        if (orgs) {
            orgs.forEach(org => orgIds.push(org.id));
            if (orgIds.length === orgs.length) return orgIds;
        }
    };

    const orgCollectionChannel = eventChannel(emit => {
        unsubscribeUserOrgData = orgs
            .where(docId, 'in', userOrgs(user?.orgs))
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot) {
                    var userOrgs = [];
                    querySnapshot.forEach(function (doc) {
                        userOrgs.push(doc.data());
                    });
                    emit({
                        orgs: userOrgs,
                        active_org_id: user.active_org_id,
                        user_id: user.id
                    });
                } else {
                    const doc = { exists: false };
                    emit({ doc });
                }
            });
        return unsubscribeUserOrgData;
    });
    try {
        while (true) {
            const { userSignOut, userOrgData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userOrgData: take(orgCollectionChannel)
            });

            if (userSignOut) {
                orgCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(storeOrgData(userOrgData));
            }
        }
    } catch (error) {
        log('Org Error: getting user org collection data (FS)', {
            error,
            user
        });
    } finally {
        unsubscribeUserOrgData(); // Detach firebase listener
        if (yield cancelled()) {
            orgCollectionChannel.close(); // Detach saga event emitter
            unsubscribeUserOrgData(); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get Organization Invitations ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* orgInvitationsCollectionWatch(user) {
    const ref = rtdb.ref(`orgs/${user.active_org_id}/invitations`);

    const orgInvitationsCollectionChannel = eventChannel(emit => {
        const unsubscribeOrgInvitationData = ref
            .orderByChild('invited_at/seconds')
            .on('value', querySnapshot => {
                if (querySnapshot && querySnapshot.val()) {
                    if (querySnapshot && querySnapshot.val()) {
                        var orgInvites = [];
                        orgInvites = Object.values(querySnapshot.val());
                        emit(orgInvites);
                    } else {
                        const doc = { exists: false };
                        emit({ doc });
                    }
                }
            });
        return unsubscribeOrgInvitationData;
    });

    try {
        while (true) {
            const { cancelledDetails, userSignOut, orgInvitationsData } = yield race({
                userSignOut: take(LOGOUT_USER),
                orgInvitationsData: take(orgInvitationsCollectionChannel)
            });

            if (cancelledDetails || userSignOut) {
                orgInvitationsCollectionChannel.close(); // Detach saga event emitter
            } else {
                yield put(setOrgInvitations(orgInvitationsData));
            }
        }
    } catch (error) {
        log('Org Invitations Error: getting org invitations data (RTDB)', {
            error,
            user
        });
    } finally {
        ref.off('value'); // Detach firebase listener
        if (yield cancelled()) {
            orgInvitationsCollectionChannel.close(); // Detach saga event emitter
            ref.off('value'); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Updating Organization Location (Office) //////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateOrgLocationRequest = ({ data, locationId, orgId }) => {
    const ref = orgs.doc(orgId);
    const path = `locations.${locationId}`;

    return new Promise((resolve, reject) => {
        ref.update({
            [path]: data
        })
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* updateOrgLocation({ payload }) {
    const { data, locationId, orgId } = payload;
    const { res, error } = yield call(() =>
        updateOrgLocationRequest({ data, locationId, orgId })
    );
    if (res) {
        updateOrgLocationSuccess();
    } else {
        updateOrgLocationFailure(error);
        log('Org Error: updating org location data (FS)', {
            error,
            data,
            locationId,
            orgId
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Adding Organization Member ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const prepareInvitationsRequest = async ({ members, user }) => {
    return new Promise((resolve, reject) => {
        const { active_location_id, active_org_id, orgs } = user;
        const locations = orgs.filter(org => org.id === active_org_id)[0].locations;
        const existing = [];
        const nonExisting = [];
        members.forEach(member => {
            const inviteType = { ...member.type, permission: member.permissions.id };
            const inviteId = generateUid();
            users
                .where('email', '==', member.email)
                .get()
                .then(querySnapshot => {
                    if (!querySnapshot.size) {
                        const nonExistingData = {
                            accepted: false,
                            accepted_at: null,
                            active_location_id: active_location_id,
                            brokered_by: member.brokered_by,
                            declined_at: null,
                            email: member.email,
                            existing: false,
                            first_name: member.firstName,
                            invite_id: inviteId,
                            last_name: member.lastName,
                            org_id: active_org_id,
                            org_locations: locations,
                            status: 'pending',
                            type: inviteType,
                            user_id: null
                        };
                        nonExisting.push(nonExistingData);
                    } else {
                        querySnapshot.forEach(doc => {
                            const data = doc.data();
                            const { first_name, last_name, email, id } = data;
                            const existingData = {
                                accepted: false,
                                accepted_at: null,
                                active_location_id: active_location_id,
                                brokered_by: member.brokered_by,
                                declined_at: null,
                                email,
                                existing: true,
                                first_name,
                                invite_id: inviteId,
                                last_name,
                                org_id: active_org_id,
                                org_locations: locations,
                                status: 'pending',
                                type: inviteType,
                                user_id: id
                            };
                            existing.push(existingData);
                        });
                    }
                    if (existing.length + nonExisting.length === members.length) {
                        resolve({ invites: { existing, nonExisting } });
                    }
                })
                .catch(error => {
                    reject(error);
                    log('Error building invites: ', error);
                });
        });
    });
};

export function* prepareInvitations({ payload }) {
    const { members } = payload;
    const user = yield select(selectors._userData);
    const { invites, error } = yield call(() =>
        prepareInvitationsRequest({ members, user })
    );
    if (invites) {
        const { existing, nonExisting } = invites;
        yield put(setPreparedInvitations({ existing, nonExisting }));
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Org Error: building invitations for new Org Members (FS)', {
            error,
            members
        });
    }
}

const writeSendInvitationsRequest = ({ invites, org }) => {
    return new Promise((resolve, reject) => {
        const newInvites = [];
        var count = 0;
        const processInvites = () => {
            invites.forEach((invitee, index) => {
                const ref = rtdb.ref(`orgs/${org.id}/invitations/${invitee.invite_id}`);
                const formattedInvite = {
                    ...invitee,
                    invited_at: timeStampNow()
                };
                newInvites.push(formattedInvite);

                ref.set({ ...formattedInvite }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        if (count + 1 === newInvites.length) {
                            inviteNewMembers({ invitations: newInvites, org }).then(
                                data => resolve({ sentInvites: true })
                            );
                        } else {
                            count++;
                        }
                    }
                });
            });
        };
        processInvites();
    });
};

// curl --location --request POST 'https://api-staging.jada.app/api/organizations/ZHUyhQi2KRHwK6cqeG2y/validate-invitation' \
// --header 'accept: */*' \
// --header 'Content-Type: application/json' \
// --data-raw '{
//  "invite_code": "GXsklZspSq3pmffZHjWr"
// }'
// Document Signed and Completed
// curl --location --request POST 'https://api-staging.jada.app/api/annotated-documents/8NeQyKOhVNwQfc4CdRug/complete-document' \
// --header 'accept: */*'
// Decline Organization Invitation
// curl --location --request PATCH 'https://api-staging.jada.app/api/organizations/ZHUyhQi2KRHwK6cqeG2y/decline-invitation' \
// --header 'Content-Type: application/json' \
// --data-raw '{
//  "invite_code": "GXsklZspSq3pmffZHjWr",
//  "invitation_id": "lDPySCrFsUHmCH1xn21p",
//  "status": "declined"
// }'

export function* writeSendInvitations() {
    const org = yield select(selectors._activeOrg);
    const existingInvites = yield select(selectors._existingInvites);
    const nonExistingInvites = yield select(selectors._nonExistingInvites);
    const invites = [...existingInvites, ...nonExistingInvites];
    const { sentInvites, error } = yield call(() =>
        writeSendInvitationsRequest({ invites, org })
    );
    if (sentInvites) {
        yield put(orgInvitationSuccess());
        yield put(setConfirmModalType(confirmationDialogTypes.inviteMemberSuccess));
    } else {
        yield put(orgInvitationFailure(error));
        log('Org Error: writing and sending invitations for new Org Members (FS)', {
            error,
            org,
            invites
        });
        yield put(setConfirmModalType(confirmationDialogTypes.inviteMemberFailure));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Update Org Members Status  //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateOrgMembersStatusRequest = ({ userData, changedStatuses }) => {
    const orgsMemberRef = rtdb.ref(`orgs/${userData.active_org_id}/contacts`);

    const items = Object.entries(changedStatuses).map(([id, status]) => [
        `${id}/active`,
        status
    ]);

    return new Promise((resolve, reject) =>
        orgsMemberRef.update({ ...Object.fromEntries(items) }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        })
    );
};

export function* updateOrgMembersStatus({ payload }) {
    const { changedStatuses } = payload;
    const userData = yield select(selectors._userData);

    const { error } = yield call(() =>
        updateOrgMembersStatusRequest({ userData, changedStatuses })
    );
    if (error) {
        log('Orgs Error: Update orgs member status (RTDB)', {
            error,
            userData: userData.id,
            changedStatuses
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Save Org Members Details ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const saveOrgMembersDetailsRequest = ({
    userData,
    selectedAccountType,
    memberId,
    memberPermissions
}) => {
    const orgsMemberRef = rtdb.ref(`orgs/${userData.active_org_id}/contacts/${memberId}`);
    const items = memberPermissions.map(el => [el, true]);
    return new Promise((resolve, reject) =>
        orgsMemberRef.update(
            { role: selectedAccountType, permissions: Object.fromEntries(items) },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            }
        )
    );
};

export function* saveOrgMembersDetails({ payload }) {
    const { selectedAccountType, memberId, memberPermissions } = payload;
    const userData = yield select(selectors._userData);

    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    const { error, res } = yield call(() =>
        saveOrgMembersDetailsRequest({
            userData,
            selectedAccountType,
            memberId,
            memberPermissions
        })
    );
    if (res) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        log('Orgs Error: Save orgs member details (RTDB)', {
            error,
            userData: userData.id,
            selectedAccountType
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Removing Org Member  ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeOrgMemberRequest = async ({ userData, memberId }) => {
    const orgsMemberRef = rtdb.ref(`orgs/${userData.active_org_id}/contacts/${memberId}`);

    return orgsMemberRef
        .remove()
        .then(() => ({ res: true }))
        .catch(error => ({ err: error }));
};

export function* removeOrgMember({ payload }) {
    const { memberId } = payload;
    const userData = yield select(selectors._userData);

    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.deleteMemberFromOrg
    });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { res, err } = yield call(() =>
            removeOrgMemberRequest({
                userData,
                memberId
            })
        );
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log('Orgs Error: Remove orgs member (RTDB)', {
                err,
                userData: userData.id
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Removing Org Member  ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeOrgInvitationsRequest = async ({ removing, userData }) => {
    const orgsMemberRef = rtdb.ref(`orgs/${userData.active_org_id}/invitations`);
    var removed = {};

    removing.forEach(item => {
        Object.assign(removed, {
            [`/${item}`]: null
        });
    });

    return orgsMemberRef
        .update(removed)
        .then(() => ({ res: true }))
        .catch(error => ({ err: error }));
};

export function* removeOrgInvitations({ payload }) {
    const { removing } = payload;
    const userData = yield select(selectors._userData);
    const { res, err } = yield call(() =>
        removeOrgInvitationsRequest({
            removing,
            userData
        })
    );
    if (res) {
        // Does there need to be a success modal?
    } else {
        log('Orgs Error: Remove orgs invitations (RTDB)', {
            err,
            removing
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getOrgCollection() {
    yield takeLatest(GET_USER_ORG, orgCollectionWatch);
}

export function* updatingOrgLocation() {
    yield takeLatest(UPDATE_ORG_LOCATION, updateOrgLocation);
}

export function* preparingInvitations() {
    yield takeLatest(PREPARE_MEMBER_INVITATIONS, prepareInvitations);
}

export function* writingSendingInvitations() {
    yield takeLatest(WRITE_SEND_INVITATIONS, writeSendInvitations);
}

export function* getOrgInvitations() {
    yield takeLatest(GET_ORG_INVITATIONS, orgInvitationsCollectionWatch);
}

export function* updatingOrgMembersStatus() {
    yield takeLatest(UPDATE_ORG_MEMBERS_STATUS, updateOrgMembersStatus);
}

export function* savingOrgMembersDetails() {
    yield takeLatest(SAVE_ORG_MEMBERS_DETAILS, saveOrgMembersDetails);
}

export function* removingOrgMember() {
    yield takeLatest(REMOVE_ORG_MEMBER, removeOrgMember);
}

export function* removingOrgInvitations() {
    yield takeLatest(REMOVE_ORG_INVITATIONS, removeOrgInvitations);
}

export default function* rootSaga() {
    yield all([
        fork(getOrgCollection),
        fork(updatingOrgLocation),
        fork(preparingInvitations),
        fork(writingSendingInvitations),
        fork(getOrgInvitations),
        fork(updatingOrgMembersStatus),
        fork(savingOrgMembersDetails),
        fork(removingOrgMember),
        fork(removingOrgInvitations)
    ]);
}
