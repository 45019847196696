// React
import React, { useState, useEffect, useMemo } from 'react';

// Assets
import NewConnectionCard from '../assets/svg/NewConnectionCard.svg';

// Constants
import { confirmationDialogTypes } from '../utils/Constants';

// Components
import {
    NewAddHeader,
    ExistingConnectionCheck,
    ConnectionProgress,
    ConnectionMatches,
    AddNewConnection,
    ConfirmationDialog
} from '../components';

// Context
import { useLang } from '../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import {
    setConnectionMatch,
    clearConnectionPrimary,
    clearConnectionLink,
    resetNewConnection
} from '../store/actions/Connections';

// Router
import { useHistory } from 'react-router-dom';
import * as routes from '../router/config/routes';

const NewConnection = ({
    location,
    loading,
    matches,
    primaryConnection,
    linkConnection,
    setConnectionMatch,
    primaryEmail,
    primaryPhone,
    linkEmail,
    linkPhone,
    clearConnectionPrimary,
    clearConnectionLink,
    resetNewConnection,
    addingParty
}) => {
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [focusedState, setFocusedState] = useState('primary_existing_check');
    const [connectionType, setConnectionType] = useState('primary');
    const [cancelLink, setCancelLink] = useState(null);
    const { title, sub_text, party_title, party_sub_text } = useLang()['NewConnection'];
    const history = useHistory();

    useEffect(() => {
        return () => {
            resetNewConnection();
        };
    }, [resetNewConnection]);

    useEffect(() => {
        if (!loading) {
            // console.log(loading, 'from new contact');
        }
    }, [loading]);

    const trxId = useMemo(() => {
        const pathname = window.location.pathname;
        return (
            location.state?.transactionId ||
            pathname.substr(pathname.lastIndexOf('/') + 1)
        );
    }, [location]);

    const getPrimaryPhone = phones => {
        if (!phones || !phones.length) return;
        const primaryPhone = phones.filter(phone => phone.primary === true);
        return primaryPhone[0].number;
    };

    const renderComponentByFocusState = focus => {
        switch (focus) {
            case 'primary_existing_check':
                return (
                    <ExistingConnectionCheck
                        focusedState={focusedState}
                        setFocusedState={setFocusedState}
                        connectionType={connectionType}
                        setCancelLink={setCancelLink}
                        primaryEmail={primaryEmail}
                        initialContactValues={{
                            email: location.state?.pipelineContactInfo?.email,
                            phone: location.state?.pipelineContactInfo?.phone
                        }}
                        addingParty={addingParty}
                    />
                );
            case 'link_existing_check':
                return (
                    <ExistingConnectionCheck
                        focusedState={focusedState}
                        setFocusedState={setFocusedState}
                        connectionType={connectionType}
                        setCancelLink={setCancelLink}
                        primaryEmail={primaryEmail}
                    />
                );
            case 'connection_progress':
                return (
                    <ConnectionProgress
                        loading={loading}
                        setFocusedState={setFocusedState}
                    />
                );
            case 'connection_matches':
                return (
                    <ConnectionMatches
                        setFocusedIndex={setFocusedIndex}
                        setFocusedState={setFocusedState}
                        matches={matches}
                        connectionType={connectionType}
                        selectConnectionMatch={setConnectionMatch}
                        getPrimaryPhone={getPrimaryPhone}
                        clearConnectionPrimary={clearConnectionPrimary}
                        clearConnectionLink={clearConnectionLink}
                    />
                );
            case 'connection_form':
                return null;
            default:
                return null;
        }
    };

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.uploadConnection}
                successHandler={() => history.goBack()}
            />
            <NewAddHeader
                returnRoute={
                    addingParty
                        ? `${routes.AUTHENTICATED}${routes.EDIT_TRANSACTION}/${trxId}`
                        : `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.CONNECTIONS}`
                }
            />
            <main id="content" role="main" className="push-main">
                <div className="bg-light">
                    <div className="container col d-flex justify-content-center">
                        <div className="col">
                            {focusedState !== 'connection_form' && (
                                <>
                                    <div className="row justify-content-center">
                                        <img
                                            className="new-connection-logo mt-5"
                                            src={NewConnectionCard}
                                            alt="SVG"
                                        />
                                    </div>
                                    <div className="row justify-content-center mt-5">
                                        <h1 className="h2 font-weight-medium">
                                            {addingParty ? party_title : title}
                                        </h1>
                                    </div>
                                    <div
                                        className="row justify-content-center align-items-center mt-5"
                                        style={{
                                            paddingLeft: '21%',
                                            paddingRight: '21%'
                                        }}
                                    >
                                        <h6
                                            style={{
                                                fontWeight: '200',
                                                fontSize: 16,
                                                color: '#8C98A4',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {addingParty ? party_sub_text : sub_text}
                                        </h6>
                                    </div>
                                </>
                            )}
                            <div className="row mt-6">
                                {renderComponentByFocusState(focusedState)}
                                <AddNewConnection
                                    focusedState={focusedState}
                                    focusedIndex={focusedIndex}
                                    setFocusedIndex={setFocusedIndex}
                                    setFocusedState={setFocusedState}
                                    setConnectionType={setConnectionType}
                                    primaryConnection={primaryConnection}
                                    pipelineContactInfo={
                                        location.state?.pipelineContactInfo
                                    }
                                    linkConnection={linkConnection}
                                    getPrimaryPhone={getPrimaryPhone}
                                    primaryEmail={primaryEmail}
                                    primaryPhone={primaryPhone}
                                    linkEmail={linkEmail}
                                    linkPhone={linkPhone}
                                    cancelLink={cancelLink}
                                    setCancelLink={setCancelLink}
                                    addingParty={addingParty}
                                    trxId={trxId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
const mapStateToProps = ({ connections }) => {
    const {
        loading,
        matches,
        primaryConnection,
        linkConnection,
        primaryEmail,
        primaryPhone,
        linkEmail,
        linkPhone
    } = connections;
    return {
        loading,
        matches,
        primaryConnection,
        linkConnection,
        primaryEmail,
        primaryPhone,
        linkEmail,
        linkPhone
    };
};

export default withRouter(
    connect(mapStateToProps, {
        setConnectionMatch,
        clearConnectionPrimary,
        clearConnectionLink,
        resetNewConnection
    })(NewConnection)
);
