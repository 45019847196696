import {
    SET_USER_TEAM_SUCCESS,
    SET_USER_TEAM_FAILURE,
    UPDATE_USER_TEAM_NAME,
    CREATE_USER_TEAM,
    DELETE_USER_TEAM,
    LEAVE_USER_TEAM,
    LEAVE_TEAM_SUCCESS,
    ADD_TEAM_MEMBER,
    DELETE_TEAM_SUCCESS,
    REMOVE_TEAM_MEMBER,
    ACCEPT_TEAM_INVITE,
    DECLINE_TEAM_INVITE,
    REMOVE_TEAM_INVITE
} from '../actions/types';

export const settingUserTeamSuccess = team => {
    return {
        type: SET_USER_TEAM_SUCCESS,
        payload: team
    };
};

export const settingUserTeamFailure = error => {
    return {
        type: SET_USER_TEAM_FAILURE,
        payload: error
    };
};

export const updateUserTeamName = team => {
    return {
        type: UPDATE_USER_TEAM_NAME,
        payload: team
    };
};

export const createUserTeam = team => {
    return {
        type: CREATE_USER_TEAM,
        payload: team
    };
};

export const deleteUserTeam = team => {
    return {
        type: DELETE_USER_TEAM,
        payload: team
    };
};

export const leaveUserTeam = team => {
    return {
        type: LEAVE_USER_TEAM,
        payload: team
    };
};

export const addTeamMember = team => {
    return {
        type: ADD_TEAM_MEMBER,
        payload: team
    };
};

export const removeTeamMember = team => {
    return {
        type: REMOVE_TEAM_MEMBER,
        payload: team
    };
};

export const deleteTeamSuccess = () => {
    return {
        type: DELETE_TEAM_SUCCESS
    };
};

export const leaveTeamSuccess = () => {
    return {
        type: LEAVE_TEAM_SUCCESS
    };
};

export const acceptInvite = payload => {
    return {
        type: ACCEPT_TEAM_INVITE,
        payload
    };
};

export const declineInvite = payload => {
    return {
        type: DECLINE_TEAM_INVITE,
        payload
    };
};

export const removeInvite = payload => {
    return {
        type: REMOVE_TEAM_INVITE,
        payload
    };
};
