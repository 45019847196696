// React
import React, { useState, useRef, useEffect, useMemo } from 'react';

// Components
import { TabNavigation, Activity, Documents, Notes } from '../index';

// Hookes
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { isTablet } from 'react-device-detect';
import { trxStatus } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

const TransactionInterface = ({
    match,
    transaction,
    avatars,
    allDocs,
    userData,
    addDocumentToTrx,
    removeDocumentFromTrx,
    setDocumentView,
    documentFilter,
    settingTrxDocumentsFilter,
    filteredDocs,
    activity,
    isMember,
    lastViewTime
}) => {
    const { activity_label, documents_label, notes_label } =
        useLang()['TransactionDetails']['TabNavigation'];
    const [tabContent, setTabContent] = useState('Activity');
    const [maxHeight, setMaxHeight] = useState(0);
    const [tabletDevice, setTabletDevice] = useState(isTablet);
    const interfaceRef = useRef();
    const { windowHeight, windowWidth } = useWindowSize();
    const isTransactionArchived = useMemo(
        () => transaction?.status === trxStatus.archived,
        [transaction]
    );

    const tabs = [
        {
            name: activity_label,
            value: 'Activity',
            id: 'pills-one-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-one-example2'
        },
        {
            name: documents_label,
            value: 'Documents',
            id: 'pills-two-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-two-example2'
        },
        {
            name: notes_label,
            value: 'Notes',
            id: 'pills-three-example2-tab',
            dataToggle: 'pill',
            role: 'tab',
            ariaControls: 'pills-three-example2'
        }
    ];

    useEffect(() => {
        setMaxHeight(windowHeight - interfaceRef.current.offsetTop);
        setTabletDevice(isTablet);
    }, [windowHeight, windowWidth]);

    return (
        <div
            ref={interfaceRef}
            className="col-md-9 col-lg-9 col-xl-6 no-gutters"
            style={{
                display: 'flex',
                flexDirection: tabletDevice ? 'column' : 'row',
                flexGrow: 1
            }}
        >
            <div
                className="col-md-12"
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column'
                }}
            >
                <div
                    className="card bg-transparent border-0"
                    style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column'
                    }}
                >
                    <TabNavigation
                        onClick={setTabContent}
                        selectedTab={tabContent}
                        tabs={tabs}
                    />
                    {tabContent === 'Activity' && (
                        <Activity
                            activity={activity}
                            avatars={avatars}
                            transaction={transaction}
                            lang={userData?.active_language_id}
                            isTransactionArchived={isTransactionArchived}
                            isMember={isMember}
                            currentUserId={userData?.id}
                            lastViewTime={lastViewTime}
                        />
                    )}
                    {tabContent === 'Documents' && (
                        <Documents
                            documents={Object.values(transaction?.documents || {})}
                            allDocs={allDocs}
                            match={match}
                            containerHeight={maxHeight}
                            userData={userData}
                            trxId={transaction?.id}
                            trxType={transaction?.type}
                            addDocumentToTrx={addDocumentToTrx}
                            status={transaction?.status}
                            removeDocumentFromTrx={removeDocumentFromTrx}
                            setDocumentView={setDocumentView}
                            documentFilter={documentFilter}
                            settingTrxDocumentsFilter={settingTrxDocumentsFilter}
                            filteredDocs={filteredDocs}
                            tabContent={tabContent}
                            lang={userData?.active_language_id}
                            isTransactionArchived={isTransactionArchived}
                            isMember={isMember}
                        />
                    )}
                    {tabContent === 'Notes' && (
                        <Notes
                            userId={userData?.id}
                            notes={transaction?.notes}
                            containerHeight={maxHeight}
                            trxId={transaction?.id}
                            isTransactionArchived={isTransactionArchived}
                            isMember={isMember}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransactionInterface;
