import {
    SET_USER_MESSAGES,
    SEND_USER_MESSAGE,
    SEND_USER_THREAD_MESSAGE,
    REMOVE_USER_MESSAGE,
    SAVE_USER_MESSAGE,
    REMOVE_SAVED_USER_MESSAGE,
    DRAFT_USER_MESSAGE,
    REMOVE_USER_DRAFT_MESSAGE,
    SEEN_USER_MESSAGE
} from '../actions/types';

export const setUserMessages = payload => {
    return {
        type: SET_USER_MESSAGES,
        payload
    };
};
export const sendUserMessage = payload => {
    return {
        type: SEND_USER_MESSAGE,
        payload
    };
};
export const sendUserThreadMessage = payload => {
    return {
        type: SEND_USER_THREAD_MESSAGE,
        payload
    };
};
export const removeUserMessage = payload => {
    return {
        type: REMOVE_USER_MESSAGE,
        payload
    };
};
export const saveUserMessage = payload => {
    return {
        type: SAVE_USER_MESSAGE,
        payload
    };
};
export const removeSavedUserMessage = payload => {
    return {
        type: REMOVE_SAVED_USER_MESSAGE,
        payload
    };
};
export const draftUserMessage = payload => {
    return {
        type: DRAFT_USER_MESSAGE,
        payload
    };
};
export const removeUserDraftMessage = payload => {
    return {
        type: REMOVE_USER_DRAFT_MESSAGE,
        payload
    };
};
export const seenUserMessage = payload => {
    return {
        type: SEEN_USER_MESSAGE,
        payload
    };
};
