// React
import React from 'react';
import { connect } from 'react-redux';

// Context
import { useLang } from '../../context/LangContext';

// Router
import * as routes from '../../router/config/routes';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import TransactionBadge from '../common/TransactionBadge';

const TransactionRow = ({
    match,
    id,
    address: { address_1, address_2, city },
    primary_client: { first_name, last_name },
    status,
    type,
    mls,
    member,
    memberId,
    memberName,
    updated_at,
    userId,
    visitors
}) => {
    const history = useHistory();
    const location = useLocation();

    const { button_label } = useLang()['Transactions']['TransactionRow'];

    const lastVisitedTime = visitors && visitors[userId]?.seconds;

    const handleDetailsRequest = id => {
        member
            ? history.push({
                  pathname: `${match.url}${routes.MEMBER_TRANSACTIONS_DETAILS}/${memberId}-${id}`,
                  state: { id, member, prevLocation: location.pathname, memberName }
              })
            : history.push({
                  pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${id}`,
                  state: { id, member }
              });
    };

    return (
        <div className="card">
            <div className="card-body p-3">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <TransactionBadge
                        status={status}
                        type={type}
                        isPill={false}
                        isVertical
                    />
                    <div className="mr-3 mb-2 mb-sm-0 card-col-10">
                        <h2 className="h6 mb-0">{`${first_name} ${last_name}`}</h2>
                    </div>
                    <div
                        className="mr-3 mb-3 mb-sm-0 card-col-20 text-primary"
                        style={{ cursor: 'pointer' }}
                    >
                        <div
                            className="font-size-1"
                            onClick={() => handleDetailsRequest(id)}
                        >
                            {`${address_1}
                            ${address_2 ? `#${address_2}` : ''}, ${city}`}
                        </div>
                        <small className="d-block text-muted">mls# {mls}</small>
                    </div>
                    <div className="mr-3 card-col-2">
                        <div
                            className={
                                'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDetailsRequest(id)}
                        >
                            {button_label}
                            <span className="fas fa-angle-right ml-1" />
                        </div>
                    </div>
                    {updated_at?.seconds > lastVisitedTime && (
                        <span
                            className="badge badge-sm badge-danger badge-pos rounded-circle"
                            style={{
                                top: '8px',
                                right: '8px',
                                width: '.6rem',
                                height: '.6rem'
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userId: userData.id };
};

export default connect(mapStateToProps)(TransactionRow);
