import React from 'react';
import SimpleBar from 'simplebar-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const MembersListModal = ({
    membersList,
    imgByIds,
    checked,
    handleItemClick,
    searchSuggestions
}) => {
    const connectionsList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : membersList;

    return (
        <div style={{ width: '100%' }}>
            <SimpleBar
                style={{
                    height: '400px',
                    width: 'auto'
                }}
            >
                <div className="col mt-3 mb-2">
                    {connectionsList &&
                        connectionsList.map(member => {
                            const isAdded = checked?.some(el => el.id === member.id);
                            return (
                                <div
                                    key={member.id}
                                    className="col-12 py-2 border-bottom d-flex justify-content-between align-items-center"
                                    onClick={() => handleItemClick(member)}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <div className="row d-flex align-items-center">
                                        {imgByIds.map(avatar => (
                                            <React.Fragment key={avatar.id}>
                                                {avatar.id === member.id && (
                                                    <img
                                                        src={avatar.url}
                                                        alt="Avatar"
                                                        className="rounded-circle contact-frame__avatar--small mr-3"
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                        <div>
                                            <h6 className="h6 font-weight-medium mb-0 pointer">
                                                {`${member?.first_name} ${member?.last_name}`}
                                            </h6>
                                            <p className="small mb-0">{member?.role}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon
                                            id={member.id}
                                            icon={faCheckCircle}
                                            style={{
                                                cursor: 'pointer',
                                                color: isAdded ? '#329c01' : '#b3b9be',
                                                fontSize: '1.2rem'
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </SimpleBar>
        </div>
    );
};

export default MembersListModal;
