import {
    all,
    call,
    fork,
    take,
    cancelled,
    takeLatest,
    race,
    put,
    select
} from 'redux-saga/effects';

import {
    GET_DOCUMENTS,
    LOGOUT_USER,
    UPLOAD_STORAGE_DOCUMENT,
    UPDATE_DOCUMENT,
    ADD_DOCUMENT_TO_TRANSACTION,
    // REFRESH_TRANSACTION_DOCUMENTS,
    REMOVE_DOCUMENT_FROM_TRANSACTION,
    REMOVE_DOCUMENT,
    SAVE_DOCUMENTS_PACK,
    REMOVE_DOCUMENTS_PACK
} from '../actions/types';

import { confirmSaga } from './Modal';
import { eventChannel } from 'redux-saga';

import * as selectors from './Selectors';

import axios from 'axios';

import {
    storingUserDocuments,
    storingOrgDocuments,
    uploadDocumentSuccess,
    uploadDocumentFailure
} from '../actions/Documents';
import { setConfirmModalType } from '../actions/Modal';
import { setTransactionUpdatedTimeRequest } from './Transactions';

import {
    db,
    rtdb,
    storage,
    timeStampNow,
    timeStampJs,
    fsFieldValue
} from '../../config/Firebase';

// Loggers
import { log } from '../../utils/Loggers';

// Consts
import {
    confirmationDialogTypes,
    documentStatus,
    activityMessage,
    documentSections,
    signerTypes
} from '../../utils/Constants';
import { getFullName } from '../../utils/Helpers';

const trxs = db.collection('transactions');
const annotatedDocs = db.collection('annotated_docs');
const buildSheet = db.collection('build_sheet');
const transactions = db.collection('transactions');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Get User Organization ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* docCollectionWatch(user) {
    const documentsRef = rtdb.ref(`documents/${user.id}`);
    const orgDocumentsRef = rtdb.ref(`orgs/${user.active_org_id}/documents`);

    const userDocCollectionChannel = eventChannel(emit => {
        const unsubscribeUserDocData = documentsRef.on('value', querySnapshot => {
            if (querySnapshot && querySnapshot.val()) {
                var documents = [];
                documents = Object.values(querySnapshot.val());
                emit(documents);
            } else {
                const doc = { empty: true };
                emit({ doc });
            }
        });
        return unsubscribeUserDocData;
    });

    const orgDocCollectionChannel = eventChannel(emit => {
        const unsubscribeOrgDocData = orgDocumentsRef.on('value', querySnapshot => {
            if (querySnapshot && querySnapshot.val()) {
                var documents = [];
                documents = Object.values(querySnapshot.val());
                emit(documents);
            } else {
                const doc = { empty: true };
                emit({ doc });
            }
        });
        return unsubscribeOrgDocData;
    });

    try {
        while (true) {
            const { userSignOut, userDocData, orgDocData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userDocData: take(userDocCollectionChannel),
                orgDocData: take(orgDocCollectionChannel)
            });

            if (userSignOut) {
                userDocCollectionChannel.close(); // Detach saga event emitter
                orgDocCollectionChannel.close(); // Detach saga event emitter
            }
            if (userDocData) yield put(storingUserDocuments(userDocData));
            if (orgDocData) yield put(storingOrgDocuments(orgDocData));
        }
    } catch (error) {
        log('Documents Error: getting documents collection data on User/Org (RTDB)', {
            error,
            user,
            function: 'docCollectionWatch'
        });
    } finally {
        documentsRef.off('value'); // Detach firebase listener
        orgDocumentsRef.off('value'); // Detach firebase listener
        if (yield cancelled()) {
            documentsRef.off('value'); // Detach firebase listener
            orgDocumentsRef.off('value'); // Detach firebase listener
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Upload User Storage Document /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const uploadingUserDocumentRequest = async ({ src, uploader_id, section }) => {
    const docId = buildSheet.doc().id;
    const storageRef = storage.ref();
    const directory = section === documentSections.system ? 'system' : 'users';
    const fileRef = storageRef.child(directory).child(uploader_id).child(docId);
    try {
        const snapshot = await fileRef.put(src);
        const url = await snapshot.ref.getDownloadURL();
        return { downloadUrl: [url, docId] };
    } catch (error) {
        return { error };
    }
};

export function* uploadingUserDocument({ payload }) {
    const { src, uploader_id, org_id, upload_type, type, title, fillable, section } =
        payload;

    yield put(setConfirmModalType(confirmationDialogTypes.loading));

    const { downloadUrl, error } = yield call(() =>
        uploadingUserDocumentRequest({ src, uploader_id, section })
    );
    if (downloadUrl) {
        yield call(writingDocumentUrl, {
            url: downloadUrl[0],
            id: downloadUrl[1],
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            section
        });

        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        log('Documents Error: storing user document (RTDB)', {
            error,
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            section,
            function: 'uploadingUserDocument'
        });
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Remove User Storage (RTDB) Document //////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingDocumentFromRtdb = async ({ document, userData }) => {
    const { id, section, uploader_id } = document;
    let rtdbDocumentsRef;

    if (section === documentSections.library) {
        rtdbDocumentsRef = rtdb.ref(`orgs/${userData.active_org_id}/documents/${id}`);
    } else if (section === documentSections.system) {
        rtdbDocumentsRef = rtdb.ref(`system/${uploader_id}/documents/${id}`);
    } else {
        rtdbDocumentsRef = rtdb.ref(`documents/${uploader_id}/${id}`);
    }

    return rtdbDocumentsRef
        .remove()
        .then(() => ({ rtdbRes: true }))
        .catch(error => ({ rtdbError: error }));
};

const removingDocumentFromStorage = async ({ document, userData }) => {
    const { id, uploader_id, section } = document;
    const directory = section === documentSections.system ? 'system' : 'users';

    const documentRef = storage.ref().child(`${directory}/${uploader_id}/${id}`);

    return documentRef
        .delete()
        .then(() => ({ storageRes: true }))
        .catch(error => ({ storageError: error }));
};

export function* removeUserDocument({ payload }) {
    const userData = yield select(selectors._userData);
    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.delete
    });

    if (isConfirm) {
        const { document, type } = payload;
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { rtdbRes, rtdbError } = yield call(() =>
            removingDocumentFromRtdb({
                document,
                userData
            })
        );

        if (rtdbRes) {
            const { storageRes, storageError } = yield call(() =>
                removingDocumentFromStorage({
                    document
                })
            );

            if (storageRes) {
                yield put(setConfirmModalType(confirmationDialogTypes.success));
            } else {
                yield put(setConfirmModalType(confirmationDialogTypes.failed));
                log(`Documents Error: removing document from ${type} (RTDB/Storage)`, {
                    error: storageError,
                    document,
                    function: 'removeUserDocument'
                });
            }
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Documents Error: removing document from ${type} (RTDB/Storage)`, {
                error: rtdbError,
                document,
                function: 'removeUserDocument'
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Firestore Doc /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const writingDocumentUrlRequest = ({
    url,
    id,
    src,
    uploader_id,
    org_id,
    upload_type,
    type,
    title,
    fillable,
    section,
    userData
}) => {
    return new Promise((resolve, reject) => {
        const getRef = () => {
            if (section === documentSections.library) {
                return rtdb.ref(`orgs/${userData.active_org_id}/documents/${id}`);
            } else if (section === documentSections.system) {
                return rtdb.ref(`system/${uploader_id}/documents/${id}`);
            } else {
                return rtdb.ref(`documents/${uploader_id}/${id}`);
            }
        };

        const docRef = getRef();
        const timeStamp = timeStampNow();

        docRef.set(
            {
                created_at: src?.lastModifiedDate
                    ? timeStampJs.fromDate(src.lastModifiedDate)
                    : timeStamp,
                edited_at: null,
                id,
                mime_type: type,
                name: src.name,
                org_id,
                title: title || src.name,
                fillable,
                section,
                upload_type,
                uploaded_at: timeStamp,
                uploader_id,
                url
            },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    // Creating Buildsheet!!!
                    axios({
                        method: 'POST',
                        url: `https://buildsheet-api-staging.jada.app/blueprints`,
                        data: JSON.stringify({
                            user_id: uploader_id,
                            document_id: id,
                            title: title || src.name
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(res => {
                            resolve({ res: true });
                        })
                        .catch(error => {
                            reject({ error });
                        });
                }
            }
        );
    });
};

export function* writingDocumentUrl({
    url,
    id,
    src,
    uploader_id,
    org_id,
    upload_type,
    type,
    title,
    fillable,
    section
}) {
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        writingDocumentUrlRequest({
            url,
            id,
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            section,
            userData
        })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        log('Documents Error: uploading user document (RTDB)', {
            error,
            src,
            uploader_id,
            org_id,
            upload_type,
            type,
            title,
            fillable,
            section,
            function: 'writingDocumentUrl'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Update Doc Meta Data /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updatingDocMetaRequest = ({ id, upload_type, title, section, userData }) => {
    return new Promise((resolve, reject) => {
        const getRef = () => {
            if (section === documentSections.library) {
                return rtdb.ref(`orgs/${userData.active_org_id}/documents/${id}`);
            } else if (section === documentSections.system) {
                return rtdb.ref(`system/${userData.id}/documents/${id}`);
            } else {
                return rtdb.ref(`documents/${userData.id}/${id}`);
            }
        };

        const docRef = getRef();
        const timeStamp = timeStampNow();

        docRef.update(
            {
                id,
                title,
                upload_type,
                edited_at: timeStamp
            },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            }
        );
    });
};

export function* updatingDocMeta({ payload }) {
    const { id, upload_type, title, section } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        updatingDocMetaRequest({
            id,
            section,
            upload_type,
            title,
            userData
        })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: updating document meta data (RTDB)', {
            error,
            id,
            upload_type,
            title,
            section,
            userData,
            function: 'updatingDocMeta'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////// Add Doc To Transaction(s) ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getFillableDocuments = async documents => {
    const payload = await Promise.all(
        documents.map(({ id }) => buildSheet.doc(id).get())
    );
    return payload.map(document => ({
        ...document.data(),
        position: documents.find(el => el.id === document.id)?.position
    }));
};

export const getTransactionDetails = async trxId => {
    const transaction = await transactions.doc(trxId).get();
    return transaction.data();
};

const prefillAnnotations = (annotations, trxDetails) => {
    let processedAnnotationGroups = [];
    return annotations.map(item => {
        if (item.type === 'multi_text') {
            if (processedAnnotationGroups.includes(item.map_key.key)) {
                return {
                    ...item,
                    value: ''
                };
            } else {
                processedAnnotationGroups = [
                    ...processedAnnotationGroups,
                    item.map_key.key
                ];
            }
        }
        if (
            trxDetails.parties.length === 2 &&
            ['buyer_2', 'buyer_3', 'buyer_2_signature', 'buyer_3_signature'].includes(
                item.map_key.key
            ) &&
            trxDetails.type === 'buyer'
        ) {
            return {
                ...item,
                assigned: ['buyer_2_signature', 'buyer_3_signature'].includes(
                    item.map_key.key
                ),
                assignment:
                    item.map_key.key === 'buyer_3_signature'
                        ? (trxDetails.mappings &&
                              trxDetails.mappings['buyer_2_signature']) ||
                          null
                        : null,
                value:
                    item.map_key.key === 'buyer_3'
                        ? (trxDetails.mappings && trxDetails.mappings['buyer_2']) || null
                        : null
            };
        } else if (
            trxDetails.parties.length === 2 &&
            ['seller_2', 'seller_3', 'seller_2_signature', 'seller_3_signature'].includes(
                item.map_key.key
            ) &&
            trxDetails.type === 'seller'
        ) {
            return {
                ...item,
                assigned: ['seller_2_signature', 'seller_3_signature'].includes(
                    item.map_key.key
                ),
                assignment:
                    item.map_key.key === 'seller_3_signature'
                        ? (trxDetails.mappings &&
                              trxDetails.mappings['seller_2_signature']) ||
                          null
                        : null,
                value:
                    item.map_key.key === 'seller_3'
                        ? (trxDetails.mappings && trxDetails.mappings['seller_2']) || null
                        : null
            };
        } else if (
            ([
                'seller_1_signature',
                'seller_2_signature',
                'seller_3_signature',
                'seller_4_signature',
                'seller_agent_signature_1',
                'seller_agent_signature_2',
                'seller_broker_signature_1',
                'seller_broker_signature_2'
            ].includes(item.map_key.key) &&
                trxDetails.type === 'seller') ||
            ([
                'buyer_1_signature',
                'buyer_2_signature',
                'buyer_3_signature',
                'buyer_4_signature',
                'buyer_agent_signature_1',
                'buyer_agent_signature_2',
                'buyer_broker_signature_1',
                'buyer_broker_signature_2'
            ].includes(item.map_key.key) &&
                trxDetails.type === 'buyer')
        ) {
            return {
                ...item,
                assigned: !!trxDetails.mappings[item.map_key.key],
                assignment: trxDetails.mappings[item.map_key.key]
            };
        }
        return {
            ...item,
            value: (trxDetails.mappings && trxDetails.mappings[item.map_key.key]) || null
        };
    });
};

const addDocumentToAnnotatedDocs = async ({
    trxId,
    fillableDocs,
    trxDocId,
    docTitle
}) => {
    const trxDetails = await getTransactionDetails(trxId);

    const signatures = fillableDocs
        .flatMap(({ annotations }) => annotations)
        .filter(el => el.type === 'signature' || el.type === 'initials')
        .map(({ id, type, map_key }) => ({
            annotation_id: id,
            complete: false,
            // TODO: check is we really need signature_id
            signature_id: null,
            user_id:
                (trxDetails.mappings &&
                    trxDetails.mappings[map_key.key] &&
                    trxDetails.mappings[map_key.key][0]?.id) ||
                null,
            type
        }));

    const documents = fillableDocs
        .map(({ annotations, original_document_path, pages, title, position }) => ({
            original_pdf_path: original_document_path,
            pages,
            annotations: prefillAnnotations(annotations, trxDetails),
            title,
            position: position || 0
        }))
        .sort((a, b) => a.position - b.position);

    const annotatedDoc = {
        annotated_doc_id: trxDocId,
        created_at: timeStampNow(),

        doc_height: 1030,
        doc_width: 850,

        documents,
        form_title: docTitle,
        signatures,
        status: documentStatus.notStarted,
        transaction_storage_path: `transactions/${trxId}/documents/${trxDocId}`,
        trx_id: trxId,
        header_transaction_info: {
            address: trxDetails.address,
            mls: trxDetails.mls
        },
        filling_settings: {
            is_setup: false,
            required_fields_select: null,
            signers_select: null,
            confirm_signers: [
                ...trxDetails.parties.map(el => ({
                    id: el.id,
                    legal_name: getFullName(el),
                    email: el.email,
                    type: signerTypes.party
                })),
                ...trxDetails.team.map(el => ({
                    id: el.id,
                    legal_name: getFullName(el),
                    email: el.email,
                    type: el.type
                }))
            ],
            opposing_agent:
                trxDetails.primary_opposing && getFullName(trxDetails.primary_opposing),
            forward_document: null
        },
        persist_mappings: true
    };
    return annotatedDocs.doc(trxDocId).set({ ...annotatedDoc });
};

const addingSingleDocumentToTransaction = async ({
    transactions,
    userData,
    document
}) => {
    let fillableDocs;
    if (document.fillable) {
        fillableDocs = await getFillableDocuments(
            document.pack ? Object.values(document.documents) : [document]
        );
    }

    return new Promise((resolve, reject) => {
        const trxId = transactions[0].id;
        const ref = trxs.doc(trxId);

        const newTrxDoc = annotatedDocs.doc();

        const doc = {
            ...document,
            status: document.fillable ? documentStatus.notStarted : null,
            locked: document.fillable ? false : null,
            added_at: timeStampNow(),
            trx_doc_id: newTrxDoc.id
        };

        const activity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'document',
                name: document.title,
                id: document.id,
                trxDocId: newTrxDoc.id
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: activityMessage.addDocument
        };

        ref.update({
            [`documents.${doc.trx_doc_id}`]: doc
        })
            .then(() => {
                if (fillableDocs)
                    return addDocumentToAnnotatedDocs({
                        trxId,
                        fillableDocs,
                        trxDocId: doc.trx_doc_id,
                        docTitle: doc.title
                    });
            })
            .then(() => {
                setTransactionUpdatedTimeRequest({
                    trxId,
                    status: transactions[0].status,
                    userData
                });
            })
            .then(() => {
                const activityRef = rtdb.ref(`trx_activity/${trxId}`);
                const postRef = activityRef.push();
                postRef.set({ ...activity }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ res: true });
                    }
                });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

const addingMultipleDocumentsToTransaction = async ({
    transactions,
    userData,
    document
}) => {
    /*eslint-disable */
    var batch = db.batch();
    var count = 0;

    let fillableDocs;
    if (document.fillable) {
        fillableDocs = await getFillableDocuments(
            document.pack ? Object.values(document.documents) : [document]
        );
    }

    const transactionsArrayCopy = [...transactions];
    while (transactionsArrayCopy.length) {
        const trxStatus = transactionsArrayCopy[0].status;
        const newTrxDoc = annotatedDocs.doc();
        const doc = {
            ...document,
            status: document.fillable ? documentStatus.notStarted : null,
            locked: document.fillable ? false : null,
            added_at: timeStampNow(),
            trx_doc_id: newTrxDoc.id
        };
        const trxId = transactionsArrayCopy[0].id;
        const activityRef = rtdb.ref(`trx_activity/${trxId}`);
        const postRef = activityRef.push();
        const activity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'document',
                name: document.title,
                id: document.id,
                trxDocId: newTrxDoc.id
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: activityMessage.addDocument
        };

        const writePostBatch = async (activity, trxId) => {
            await postRef
                .set({ ...activity }, error => {
                    if (error) {
                        return { error };
                    }
                    batch.update(trxs.doc(trxId), {
                        [`documents.${doc.trx_doc_id}`]: doc
                    });
                    transactionsArrayCopy.shift();
                    count++;
                })
                .then(() => {
                    setTransactionUpdatedTimeRequest({
                        trxId,
                        status: trxStatus,
                        userData
                    });
                });
        };

        await writePostBatch(activity, trxId);
        if (fillableDocs)
            await addDocumentToAnnotatedDocs({
                trxId,
                fillableDocs,
                trxDocId: doc.trx_doc_id,
                docTitle: doc.title
            });

        if (count === 500 || !transactionsArrayCopy.length) {
            return await batch
                .commit()
                .then(() => {
                    count = 0;
                    batch = db.batch();
                    if (!transactionsArrayCopy.length) return { res: true };
                })
                .catch(error => {
                    return { error };
                });
        }
    }
};

export function* addingDocumentToTransaction({ payload }) {
    const { transactions, userData, document, isUploadingNeeded } = payload;
    let documentToAdd = document;
    if (isUploadingNeeded) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { downloadUrl, error } = yield call(() =>
            uploadingUserDocumentRequest({
                src: document.src,
                uploader_id: document.uploader_id
            })
        );
        if (downloadUrl) {
            const timeStamp = timeStampNow();
            documentToAdd = {
                ...document,
                url: downloadUrl[0],
                id: downloadUrl[1],
                created_at: document.src?.lastModifiedDate
                    ? timeStampJs.fromDate(document.src.lastModifiedDate)
                    : timeStamp,
                edited_at: null,
                name: document?.src?.name,
                status: null,
                locked: null,
                uploaded_at: timeStamp
            };
            delete documentToAdd.src;
        } else {
            log('Documents Error: uploading and adding document to transaction(s) (FS)', {
                error,
                transactions,
                userData,
                document,
                function: 'uploadingUserDocumentRequest'
            });
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            return;
        }
    }
    const { res, error } = yield call(() =>
        transactions.length > 1
            ? addingMultipleDocumentsToTransaction({
                  transactions,
                  userData,
                  document
              })
            : addingSingleDocumentToTransaction({
                  transactions,
                  userData,
                  document: documentToAdd
              })
    );
    if (res) {
        yield put(uploadDocumentSuccess());
        if (isUploadingNeeded)
            yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: adding document to transaction(s) (FS)', {
            error,
            transactions,
            userData,
            document,
            function: 'addingDocumentToTransaction'
        });
        yield put(uploadDocumentFailure());
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////// Remove Doc From Transaction(s) /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingDocumentFromTransactionRequest = async ({
    deletedDocId,
    userData,
    id,
    trxDocId,
    title,
    status
}) => {
    return new Promise((resolve, reject) => {
        const trxId = id;
        const ref = trxs.doc(trxId);
        const activity = {
            archived_at: null,
            attachments: [],
            automated: {
                type: 'document',
                name: title,
                id: deletedDocId,
                trxDocId
            },
            created_at: timeStampNow(),
            creator_id: userData.id,
            creator_type: 'user',
            edited_at: null,
            first_name: userData.first_name,
            last_name: userData.last_name,
            message: activityMessage.removeDocument
        };
        ref.update({
            [`documents.${trxDocId}`]: fsFieldValue.delete()
        })
            .then(async () => annotatedDocs.doc(trxDocId)?.delete())
            .then(() => {
                setTransactionUpdatedTimeRequest({
                    trxId,
                    status,
                    userData
                });
            })
            .then(async () => {
                const activityRef = rtdb.ref(`trx_activity/${trxId}`);
                const postRef = activityRef.push();
                await postRef.set({ ...activity }, error => {
                    if (error) {
                        reject({ error });
                    } else {
                        resolve({ res: true });
                    }
                });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* removingDocumentFromTransaction({ payload }) {
    const { userData, id, title, trxDocId } = payload;
    const { res, error } = yield call(() =>
        removingDocumentFromTransactionRequest(payload)
    );
    if (res) {
        yield put(uploadDocumentSuccess());
    } else {
        // Error Handling for sentry with put and maybe UI message
        log('Documents Error: removing document from transaction (FS)', {
            error,
            userData,
            trxDocId,
            id,
            title,
            function: 'removingDocumentFromTransaction'
        });
        yield put(uploadDocumentFailure());
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Create Documents Pack /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const generateUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};

const savingDocumentsPackRequest = async ({
    documents,
    title,
    userData,
    packId,
    section,
    upload_type
}) => {
    const dbPackId = packId || generateUid();

    const getRef = () => {
        if (section === documentSections.library) {
            return rtdb.ref(`orgs/${userData.active_org_id}/documents/${dbPackId}`);
        } else if (section === documentSections.system) {
            return rtdb.ref(`system/${userData.id}/documents/${dbPackId}`);
        } else {
            return rtdb.ref(`documents/${userData.id}/${dbPackId}`);
        }
    };
    const documentsRef = getRef();

    const packDocuments = Object.fromEntries(
        documents.map(({ id, uploader_id, section, position }) => [
            id,
            { id, uploader_id, section, position }
        ])
    );

    return new Promise((resolve, reject) =>
        documentsRef
            .update({
                ...{
                    id: dbPackId,
                    upload_type,
                    pack: true,
                    documents: packDocuments,
                    title,
                    edited_at: timeStampNow(),
                    section,
                    fillable: documents.some(el => el.fillable)
                },
                ...(packId
                    ? {}
                    : {
                          uploaded_at: timeStampNow(),
                          created_at: timeStampNow()
                      })
            })
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                reject({ error });
            })
    );
};

export function* savingDocumentsPack({ payload }) {
    const { documents, title, packId, upload_type, section } = payload;

    yield put(setConfirmModalType(confirmationDialogTypes.loading));
    const userData = yield select(selectors._userData);
    const { success, error } = yield call(() =>
        savingDocumentsPackRequest({ ...payload, userData })
    );
    if (success) {
        yield put(setConfirmModalType(confirmationDialogTypes.success));
    } else {
        // Error Handling for sentry with put and maybe UI message
        yield put(setConfirmModalType(confirmationDialogTypes.error));
        log('Documents Error: creating documents pack (RTDB)', {
            error,
            userId: userData.id,
            documentsIds: documents.map(({ id }) => id),
            title,
            section,
            upload_type,
            packId,
            function: 'savingDocumentsPack'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Remove Documents Pack /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingDocumentsPackRequest = async ({ document, userData }) => {
    const { id, section } = document;
    let documentsRef;

    if (section === documentSections.library) {
        documentsRef = rtdb.ref(`orgs/${userData.active_org_id}/documents/${id}`);
    } else if (section === documentSections.system) {
        documentsRef = rtdb.ref(`system/${userData.id}/documents/${id}`);
    } else {
        documentsRef = rtdb.ref(`documents/${userData.id}/${id}`);
    }

    return new Promise((resolve, reject) =>
        documentsRef
            .remove()
            .then(() => {
                resolve({ success: true });
            })
            .catch(error => {
                reject({ error });
            })
    );
};

export function* removingDocumentsPack({ payload }) {
    const { document } = payload;
    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.delete
    });
    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const userData = yield select(selectors._userData);
        const { error } = yield call(() =>
            removingDocumentsPackRequest({ document, userData })
        );
        if (error) {
            // Error Handling for sentry with put and maybe UI message
            log('Documents Error: removing documents pack (RTDB)', {
                error,
                userId: userData.id,
                documentsIds: documents.map(({ id }) => id),
                title,
                function: 'removingDocumentsPack'
            });
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Refresh Transaction Docs ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// const checkForDocChanges = (doc, trxDoc) => {
//     if (
//         doc.title !== trxDoc.title ||
//         doc.upload_type !== trxDoc.upload_type ||
//         (doc.edited_at && doc.edited_at?.seconds !== trxDoc.edited_at?.seconds) ||
//         doc.fillable !== trxDoc.fillable
//     ) {
//         return true;
//     }

//     return false;
// };

// const buildUpdatedArray = documents => {
//     const dirtyDocs = [];
//     const cleanDocs = [];
//     const docCount = documents.length;

//     return new Promise((resolve, reject) => {
//         documents.forEach((doc, index) => {
//             const ref = rtdb.ref(`documents/${doc.uploader_id}/${doc.id}`);
//             ref.once('value')
//                 .then(snapshot => {
//                     if (checkForDocChanges(snapshot.val(), doc)) {
//                         const newDoc = {
//                             ...doc,
//                             title: snapshot.val().title ? snapshot.val().title : null,
//                             upload_type: snapshot.val().upload_type
//                                 ? snapshot.val().upload_type
//                                 : null,
//                             edited_at: snapshot.val().edited_at
//                                 ? snapshot.val().edited_at
//                                 : null,
//                             fillable: snapshot.val().fillable
//                                 ? snapshot.val().fillable
//                                 : null
//                         };
//                         dirtyDocs.push(newDoc);
//                         if (index === docCount - 1) {
//                             resolve({ clean: cleanDocs, dirty: dirtyDocs });
//                         }
//                     } else {
//                         cleanDocs.push(doc);
//                         if (index === docCount - 1) {
//                             resolve({ clean: cleanDocs, dirty: dirtyDocs });
//                         }
//                     }
//                 })
//                 .catch(error => {
//                     reject({ error });
//                 });
//         });
//     });
// };

// const refreshingTransactionDocumentsRequest = ({ documents, id }) => {
//     const ref = trxs.doc(id);
//     let updatedDocs;
//     return new Promise((resolve, reject) => {
//         buildUpdatedArray(documents).then(docs => {
//             if (!docs.error) {
//                 if (docs.dirty?.length) {
//                     updatedDocs = [].concat(docs.dirty, docs.clean);
//                     ref.update({
//                         documents: updatedDocs
//                     })
//                         .then(() => {
//                             resolve({ refreshedDocs: true });
//                         })
//                         .catch(error => {
//                             reject({ error });
//                         });
//                 } else {
//                     resolve({ refreshedDocs: true });
//                 }
//             } else {
//                 reject({ error: docs.error });
//             }
//         });
//     });
// };

// export function* refreshingTransactionDocuments(documents, id) {
//     if (documents && documents.length) {
//         const { refreshedDocs, error } = yield call(() =>
//             refreshingTransactionDocumentsRequest({
//                 documents,
//                 id
//             })
//         );
//         if (refreshedDocs) {
//             console.log('refreshed docs complete!!!');
//         } else {
//             log('Documents Error: refreshing transaction documents (FS)', {
//                 error,
//                 documents,
//                 id,
//                 function: 'refreshingTransactionDocuments'
//             });
//             yield put(uploadDocumentFailure());
//         }
//     } else {
//         console.log('no update needed');
//     }
// }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getDocCollection() {
    yield takeLatest(GET_DOCUMENTS, docCollectionWatch);
}

export function* uploadDocument() {
    yield takeLatest(UPLOAD_STORAGE_DOCUMENT, uploadingUserDocument);
}

export function* updateDocument() {
    yield takeLatest(UPDATE_DOCUMENT, updatingDocMeta);
}

export function* removeDocument() {
    yield takeLatest(REMOVE_DOCUMENT, removeUserDocument);
}

export function* addDocumentToTransaction() {
    yield takeLatest(ADD_DOCUMENT_TO_TRANSACTION, addingDocumentToTransaction);
}

export function* removeDocumentFromTransaction() {
    yield takeLatest(REMOVE_DOCUMENT_FROM_TRANSACTION, removingDocumentFromTransaction);
}

export function* saveDocumentsPack() {
    yield takeLatest(SAVE_DOCUMENTS_PACK, savingDocumentsPack);
}

export function* removeDocumentsPack() {
    yield takeLatest(REMOVE_DOCUMENTS_PACK, removingDocumentsPack);
}

// export function* refreshTransactionDocuments() {
//     yield takeLatest(REFRESH_TRANSACTION_DOCUMENTS, refreshingTransactionDocuments);
// }

export default function* rootSaga() {
    yield all([
        fork(getDocCollection),
        fork(uploadDocument),
        fork(updateDocument),
        fork(removeDocument),
        fork(addDocumentToTransaction),
        fork(removeDocumentFromTransaction),
        fork(saveDocumentsPack),
        fork(removeDocumentsPack)
    ]);
}
