import { Interval, DateTime } from 'luxon';
import { storage, timeStampJs } from '../config/Firebase';
import { FS_USER_SCHEMA, FS_USER_PREFERENCES, unitedStates } from './Constants';

///////////////// Activity Thread /////////////////

const dateOrdinal = day => {
    return 31 === day || 21 === day || 1 === day
        ? 'st'
        : 22 === day || 2 === day
        ? 'nd'
        : 23 === day || 3 === day
        ? 'rd'
        : 'th';
};

const formatThreadDate = (timeStamp, lang) => {
    if (lang === 'en') {
        return `${DateTime.fromSeconds(timeStamp).toFormat('cccc, LLL d')}${dateOrdinal(
            DateTime.fromSeconds(timeStamp).day
        )}`;
    } else if (lang === 'es') {
        return `${DateTime.fromSeconds(timeStamp)
            .setLocale('es-ES')
            .toFormat('cccc, LLL d')}`;
    } else {
        return;
    }
};

export const formatThreadTime = timeStamp => {
    return DateTime.fromSeconds(timeStamp).toFormat('t');
};

export const formatActivity = (data, lang) => {
    const formattedEvents = [];
    const activity = [...data];
    const sortedEvents = activity.sort((x, y) => {
        return y.created_at.seconds - x.created_at.seconds;
    });
    sortedEvents.forEach(activity => {
        const activityDay = formatThreadDate(activity.created_at.seconds, lang);

        const found = formattedEvents.findIndex(event => event.day === activityDay);

        if (found >= 0) {
            formattedEvents[found].events.push(activity);
        } else {
            formattedEvents.push({ day: activityDay, events: [{ ...activity }] });
        }
    });
    return formattedEvents;
};

export const formatInviteDate = seconds => DateTime.fromSeconds(seconds).toFormat('f');

export const formatSigningDate = seconds =>
    DateTime.fromSeconds(seconds).setLocale('en-US').toFormat('D');

///////////////////////////////////////////////////

/////////////////// Documents /////////////////////

export const formatDocumentDate = (timeStamp, lang) => {
    if (lang === 'en') {
        return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
            DateTime.fromSeconds(timeStamp).day
        )}, ${DateTime.fromSeconds(timeStamp).toFormat('yyyy')}`;
    } else if (lang === 'es') {
        return `${DateTime.fromSeconds(timeStamp)
            .setLocale('es-ES')
            .toFormat('LLL d')}, ${DateTime.fromSeconds(timeStamp).toFormat('yyyy')}`;
    } else {
        return;
    }
};

///////////////////////////////////////////////////

////////////////////// Messages ///////////////////
export const formatMessagesDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat(
        'LLL. d, yyyy'
    )}, ${DateTime.fromSeconds(timeStamp).toFormat('h:mm')}${DateTime.fromSeconds(
        timeStamp
    )
        .toFormat('a')
        .toLocaleLowerCase()}
        `;
};
///////////////////////////////////////////////////

////////////////////// Tasks //////////////////////

export const formatTaskDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).setLocale('es-ES').day
    )}, ${DateTime.fromSeconds(timeStamp).toFormat('h:mm')}${DateTime.fromSeconds(
        timeStamp
    )
        .toFormat('a')
        .toLocaleLowerCase()}
        `;
};

export const setTaskStatus = task => {
    const date = DateTime.fromSeconds(task.date_time.seconds);
    const diff = date.diffNow().as('hours');
    if (task.status === 'complete') return 'complete';
    if (diff < 0) {
        return 'danger';
    } else if (diff > 24) {
        return 'success';
    } else {
        return 'warning';
    }
};

export const tsFromJsDate = date => {
    return timeStampJs.fromDate(date);
};

///////////////////////////////////////////////////

////////////// Transaction List/Grid //////////////

export const formatCardDate = timeStamp => {
    return `${DateTime.fromSeconds(timeStamp).toFormat('LLL d')}${dateOrdinal(
        DateTime.fromSeconds(timeStamp).day
    )}`;
};

///////////////////////////////////////////////////

/////////////// Load FB Avatar Image //////////////

// Will revisit this to make sure we are doing the right thing for efficeincy
// export const setUserAvatar = (id) => {
//     const avatarRef = storage.ref().child(`users/${id}/avatar.jpg`);
//     avatarRef.getDownloadURL();
// };

///////////////////////////////////////////////////

//////////////////// Transactions //////////////////

export const getSources = (userSources, orgSources) => {
    if (orgSources && userSources) {
        return [...orgSources, ...userSources];
    } else if (orgSources) {
        return [...orgSources];
    } else if (userSources) {
        return [...userSources];
    } else {
        return [];
    }
};

export const daysOnMls = seconds => {
    const date = DateTime.fromSeconds(seconds);
    const days = Math.floor(Math.abs(date.diffNow().as('days')));
    return days;
};

export const daysTillClosing = seconds => {
    const date = DateTime.fromSeconds(seconds);
    const days = Math.ceil(Math.abs(date.diffNow().as('days')));
    return days;
};

export const getFullName = person => {
    return person.legal_name || person.fullLegalName
        ? person.legal_name || person.legalName
        : `${person.first_name || person.firstName} ${
              person.last_name || person.lastName
          }`;
};

//////////////////// Preferences //////////////////

export const fieldsNotEqual = (object1, object2) => {
    if (!object1 || !object2) {
        return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return true;
    }

    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return true;
        }
    }
    return false;
};

export const checkChanges = (initialArr, newArr) => {
    if (initialArr.length !== newArr.length) {
        return true;
    }

    for (let i = 0; i < initialArr.length; i++) {
        if (fieldsNotEqual(initialArr[i], newArr[i])) {
            return true;
        }
    }

    return false;
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
};

///////////////////////////////////////////////////

// let parties = formData.parties.reduce((partyObjects, party) => {
//     if (party.email !== '')
//         partyObjects.push({ email: party.email.toLowerCase(), id: null });
//     return partyObjects;
// }, []);

///////////////////////////////////////////////////

// }, []);export const getUserAvatars = ({ ids, setImgByIds }) => {
//     const imgObjArr = [];
//     ids.forEach(id => {
//         if (id) {
//             const ref = storage.ref().child(`users/${id}/avatar.jpg`);
//             ref.getDownloadURL()
//                 .then(imgUrl => {
//                     imgObjArr.push({ id, url: imgUrl });
//                     if (imgObjArr.length === ids.length) {
//                         setImgByIds(imgObjArr);
//                     }
//                 })
//                 .catch(error => {
//                     imgObjArr.push({ id, url: null, err: error.code_ });
//                     if (imgObjArr.length === ids.length) {
//                         setImgByIds(imgObjArr);
//                     }
//                 });
//         } else {
//             imgObjArr.push({ id: null, url: null });
//         }
//     });
// };

///////////////////////////////////////////////////

/////////////////// Divide input text /////////////////////

export const getElementSize = (text, { fontFamily, fontSize, fontBold }) => {
    const element = document.createElement('div');
    const textNode = document.createTextNode(text);
    element.appendChild(textNode);
    element.style.fontFamily = fontFamily;
    element.style.fontSize = fontSize + 'px';
    element.style.fontWeight = fontBold ? 'bold' : 'normal';
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.left = '0px';
    element.style.top = '0px';
    element.style.height = 'auto';
    element.style.wordBreak = 'normal';
    document.body.appendChild(element);
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    element.parentNode.removeChild(element);
    return { width, height };
};

const getSpaceWidth = fontSize => {
    switch (true) {
        case fontSize > 16:
            return 5;
        case fontSize <= 16 && fontSize > 12:
            return 4;
        case fontSize <= 12 && fontSize > 7:
            return 3;
        default:
            return 2;
    }
};

export const splitInputText = (text, annotations) => {
    const splitText = text.toString().trim().split(/(\s+)/);
    let isSeparatingFinished,
        separatedLines = [];

    // calculating font size
    let fontSize = +annotations[0].font_size;
    let sumInputWidth = 0;
    annotations.forEach(item => {
        if (+item.font_size < +fontSize) fontSize = +item.font_size;
        sumInputWidth += item.width;
    });
    while (
        sumInputWidth <
        getElementSize(text, {
            fontSize,
            fontFamily: annotations[0].font_family,
            fontBold: annotations[0].font_bold
        }).width +
            5
    ) {
        fontSize--;
    }

    const spaceWidth = getSpaceWidth(+fontSize);

    while (!isSeparatingFinished) {
        let maxString = 0,
            lineText = '',
            currentAnnotationIndex = 0;
        separatedLines = [];
        for (let index = 0; index < splitText.length; index++) {
            const word = splitText[index];
            const currentAnnotation = annotations[currentAnnotationIndex];
            const { width: wordWidth } = getElementSize(word, {
                fontSize,
                fontFamily: currentAnnotation.font_family,
                fontBold: currentAnnotation.font_bold
            });
            const wordSize = wordWidth !== 0 ? wordWidth : spaceWidth;
            if (wordSize + maxString <= currentAnnotation.width) {
                lineText = lineText + word;
                maxString = maxString + wordSize;
            } else {
                if (currentAnnotationIndex + 1 === annotations.length) {
                    fontSize--;
                    break;
                }
                separatedLines.push(lineText);
                currentAnnotationIndex++;
                lineText = word; // Reset until all words are processed
                maxString = wordSize; // Reset until all words are processed
            }
            if (index + 1 === splitText.length) {
                separatedLines.push(lineText);
                isSeparatingFinished = true;
            }
        }
    }
    return { separatedLines, fontSize: +fontSize };
};

export const getUserAvatars = async ({ ids, setImgByIds }) => {
    let imgsObj = {};
    await Promise.all(
        ids.map(id => {
            const ref = storage.ref().child(`users/${id}/avatar.jpg`);
            return ref
                .getDownloadURL()
                .then(imgUrl => (imgsObj = { ...imgsObj, [id]: imgUrl }));
        })
    );
    setImgByIds(imgsObj);
};

export const setUniqueTaskTitle = (title, taskArr) => {
    const formattedTitle = title.trim();
    var unique = false;
    var count = 0;
    var testedTitle = formattedTitle;
    var uniqueTitle;
    while (!unique) {
        if (count !== 0) {
            testedTitle = `${formattedTitle} (${count})`;
        }
        const matches = taskArr.filter(
            // eslint-disable-next-line no-loop-func
            task => task.title.toLowerCase() === testedTitle.toLowerCase()
        );
        if (matches.length) {
            count++;
        } else {
            uniqueTitle = testedTitle;
            unique = true;
        }
    }
    return uniqueTitle;
};

export const sortListByAlphabet = arr => {
    return arr.sort((a, b) => a.title.localeCompare(b.title));
};

export const getUsStateName = unitedState => {
    const state = unitedStates.find(state => state.value === unitedState);
    return state.name;
};

export const makePossessiveCase = (name, activeLang) => {
    if (activeLang === 'en' && name.charAt(name.length - 1) !== 's') {
        return name + "'s";
    } else if (activeLang === 'en' && name.charAt(name.length - 1) === 's') {
        return name + "'";
    } else {
        return name;
    }
};
// }, []);

///////////////////////////////////////////////////

/////////////////////// Orgs //////////////////////

// const orgInvitationLink = () => {};

export const pluralizeAndStringify = (value, word, suffix = 's') => {
    if (value === 1) {
        return value + ' ' + word;
    } else {
        return value + ' ' + word + suffix;
    }
};

///////////////////////////////////////////////////

/////////////////// Invitations ///////////////////

export const checkExpiration = seconds => {
    const invited_at = DateTime.fromSeconds(seconds);
    const currentTime = DateTime.now();
    const interval = Interval.fromDateTimes(invited_at, currentTime);
    // console.log(interval.length('days'));
    if (interval.length('days') > 5) return true;
    return false;
};

///////////////////////////////////////////////////

// const datastuff = {
// draft_messages: {
//     draft_message_id_2: {
//         test: 'test'
//     },
//     draft_message_id_1: {
//         test: 'test'
//     }
// },
// email: 'marc@jadaapp.com',
// designation: null,
// team_id: null,
// broker_lic: null,
// address: null,
// agent_lic: 'SL12355444',
// role: 'user',
// persist: {
//     transactions: [
//         {
//             address: '1325 Oakes Blvd',
//             id: 'WYwu1UA783f1kJhtQYRf'
//         },
//         {
//             address: '2946 N Summit Ave',
//             id: 'KL1mYjC1cA5p8ala0EDQ'
//         },
//         {
//             id: 'P8PJ4556Qt78asJRlfIF',
//             address: '2941 N Hackett Ave'
//         }
//     ]
// },
// active: true,
// linkedin: null,
// active_location_id: 'DZ5d4BAJKYz9F8LYKGMA',
// twitter: null,
// about: 'This is for the <strong>about</strong> section for the agent profile.',
// active_provider_id: 'hUOHXjNqJ6GZLckFvbSw',
// team_invites: {
//     '5m1e8fjSFvhKOfI8z5Hs': {
//         email: 'marc@jadaapp.com',
//         expired_at: {
//             seconds: 1667406257,
//             nanoseconds: 656000000
//         },
//         type: 'Sales Associate',
//         accepted: false,
//         invited_at: {
//             seconds: 1667406255,
//             nanoseconds: 512000000
//         },
//         org_id: 'ZHUyhQi2KRHwK6cqeG2y',
//         team_id: 'lxOPwynkYVRkjrFpmH8n',
//         first_name: 'Marc',
//         last_name: 'Vivonetto',
//         invite_id: '5m1e8fjSFvhKOfI8z5Hs',
//         team_name: 'Test Team',
//         team_lead: {
//             email: 'ken@jadaapp.com',
//             first_name: 'Ken',
//             last_name: 'Delaney',
//             id: 'jADofRUXpjfA5jcP8vpdQckJafF3'
//         },
//         user_id: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//         status: 'expired'
//     }
// },
// active_org_id: 'ZHUyhQi2KRHwK6cqeG2y',
// orgs: [
//     {
//         id: 'ZHUyhQi2KRHwK6cqeG2y',
//         title: 'Sales Associate',
//         locations: ['DZ5d4BAJKYz9F8LYKGMA', 'ZDFGRc8tFBu8euqTvKOd'],
//         sub_type: 'sales_associate',
//         owner: false
//     },
//     {
//         sub_type: 'agent',
//         locations: ['kXe9hlSejKkL4svlfxKN'],
//         title: 'Sales Associate',
//         id: 'nw37tjm92ZekG0jHghDU',
//         owner: false
//     }
// ],
// facebook: null,
// user_avatar:
//     'https://firebasestorage.googleapis.com/v0/b/jada-development.appspot.com/o/users%2FxebANMW5jPfyrdoZFOtS12X9erC2%2Favatar.jpg?alt=media&token=d850ab87-dfd7-4b2f-b8e9-847a3ebb555b',
// saved_messages: {
//     message_id_1: {
//         test: 'test'
//     },
//     message_id_2: {
//         test: 'test'
//     }
// },
// website: null,
// instagram: null,
// preferences: {
//     ZHUyhQi2KRHwK6cqeG2y: {
//         sources: [
//             'REALTOR.COM',
//             'TRULIA',
//             'REDFIN',
//             'RELATIVE',
//             'VETERANS UNITED',
//             'FAMILY FRIEND'
//         ],
//         income: {
//             commission_percent: true,
//             split_percent: true,
//             commission: 3,
//             trx_fee: 195,
//             split: 80,
//             trx_fee_percent: false
//         },
//         notifications: [
//             {
//                 title: 'Client invite is accepted',
//                 id: 'client_invite',
//                 checked: true
//             },
//             {
//                 title: 'A new task has been created',
//                 checked: true,
//                 id: 'new_task'
//             },
//             {
//                 id: 'docs_signed',
//                 title: 'Documents have been signed',
//                 checked: true
//             },
//             {
//                 title: 'An Offer is made',
//                 checked: true,
//                 id: 'offer_made'
//             }
//         ],
//         initial_tasks: {
//             seller: [
//                 {
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1640444400
//                     },
//                     status: 'open',
//                     created_at: {
//                         seconds: 1640018365,
//                         nanoseconds: 407000000
//                     },
//                     title: 'Execute Listing',
//                     content: 'Execute the listing on behalf of clients',
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     edited_at: null,
//                     expiration: {
//                         mins: 0,
//                         days: 1,
//                         ordinal: 'AM',
//                         hours: 9
//                     }
//                 },
//                 {
//                     edited_at: null,
//                     title: 'Property Photos',
//                     created_at: {
//                         seconds: 1640018364,
//                         nanoseconds: 407000000
//                     },
//                     date_time: {
//                         seconds: 1640806200,
//                         nanoseconds: 0
//                     },
//                     status: 'open',
//                     content: 'Upload and gather best photos of the property',
//                     expiration: {
//                         days: 5,
//                         ordinal: 'PM',
//                         mins: 30,
//                         hours: 13
//                     },
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2'
//                 },
//                 {
//                     expiration: {
//                         mins: 0,
//                         hours: 9,
//                         days: 1,
//                         ordinal: 'AM'
//                     },
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1608127200
//                     },
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     edited_at: null,
//                     status: 'open',
//                     title: 'Upload to MLS',
//                     created_at: {
//                         nanoseconds: 36000000,
//                         seconds: 1608072395
//                     },
//                     content: 'Upload property and listing data to MLS'
//                 },
//                 {
//                     created_at: {
//                         seconds: 1640018366,
//                         nanoseconds: 407000000
//                     },
//                     status: 'open',
//                     edited_at: null,
//                     title: 'Install Lockbox',
//                     content: 'Installing lockbox at the property',
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1640622600
//                     },
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     expiration: {
//                         days: 3,
//                         ordinal: 'AM',
//                         mins: 30,
//                         hours: 10
//                     }
//                 },
//                 {
//                     edited_at: null,
//                     created_at: {
//                         seconds: 1635372983,
//                         nanoseconds: 287000000
//                     },
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1637942400
//                     },
//                     title: 'Install Sign',
//                     status: 'open',
//                     expiration: {
//                         ordinal: 'AM',
//                         mins: 0,
//                         days: 3,
//                         hours: 10
//                     },
//                     content: 'Place sign near street of the property',
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2'
//                 },
//                 {
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     content: 'Schedule and stage listing with AAA Staging Company',
//                     status: 'open',
//                     expiration: {
//                         mins: 30,
//                         ordinal: 'PM',
//                         days: 4,
//                         hours: 14
//                     },
//                     created_at: {
//                         seconds: 1640018367,
//                         nanoseconds: 407000000
//                     },
//                     date_time: {
//                         seconds: 1640723400,
//                         nanoseconds: 0
//                     },
//                     title: 'Staging',
//                     edited_at: null
//                 },
//                 {
//                     expiration: {
//                         ordinal: '',
//                         hours: 22,
//                         days: 7,
//                         mins: 30
//                     },
//                     edited_at: null,
//                     date_time: null,
//                     title: 'Follow Up',
//                     content: 'Call owners to check in',
//                     created_at: null,
//                     status: 'open',
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2'
//                 }
//             ],
//             buyer: [
//                 {
//                     title: 'Deliver Escrow Deposit',
//                     date_time: null,
//                     created_at: null,
//                     content: 'Delivering the escrow deposit ',
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     expiration: {
//                         days: 5,
//                         ordinal: 'AM',
//                         mins: 30,
//                         hours: 10
//                     },
//                     edited_at: null,
//                     status: 'open'
//                 },
//                 {
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     content: 'Requesting HOA docs',
//                     status: 'open',
//                     expiration: {
//                         ordinal: 'AM',
//                         hours: 9,
//                         mins: 30,
//                         days: 5
//                     },
//                     title: 'Request HOA/Condo Docs',
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1609601400
//                     },
//                     edited_at: null,
//                     created_at: {
//                         seconds: 1609179202,
//                         nanoseconds: 670000000
//                     }
//                 },
//                 {
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     content: 'Scheduling home inspection',
//                     status: 'open',
//                     title: 'Home Inspection',
//                     created_at: {
//                         seconds: 1609179201,
//                         nanoseconds: 670000000
//                     },
//                     expiration: {
//                         days: 5,
//                         ordinal: 'AM',
//                         hours: 11,
//                         mins: 30
//                     },
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1609608600
//                     },
//                     edited_at: null
//                 },
//                 {
//                     expiration: {
//                         days: 5,
//                         ordinal: 'PM',
//                         mins: 30,
//                         hours: 12
//                     },
//                     edited_at: null,
//                     created_at: null,
//                     content: 'Submitting mortgage application for buyers',
//                     status: 'open',
//                     date_time: null,
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     title: 'Mortgage Application'
//                 },
//                 {
//                     created_at: {
//                         seconds: 1609179203,
//                         nanoseconds: 670000000
//                     },
//                     status: 'open',
//                     date_time: {
//                         nanoseconds: 0,
//                         seconds: 1609603200
//                     },
//                     title: 'Appraisal',
//                     expiration: {
//                         mins: 0,
//                         hours: 10,
//                         days: 5,
//                         ordinal: 'AM'
//                     },
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     edited_at: null,
//                     content: 'Schedule appraiser to appraise the property'
//                 },
//                 {
//                     created_at: {
//                         nanoseconds: 670000000,
//                         seconds: 1609179204
//                     },
//                     created_by: 'xebANMW5jPfyrdoZFOtS12X9erC2',
//                     date_time: {
//                         seconds: 1609518600,
//                         nanoseconds: 0
//                     },
//                     title: 'Send Welcome Letter',
//                     status: 'open',
//                     expiration: {
//                         mins: 30,
//                         hours: 10,
//                         ordinal: 'AM',
//                         days: 4
//                     },
//                     content: 'Send welcome letter to clients',
//                     edited_at: null
//                 }
//             ]
//         }
//     },
// nw37tjm92ZekG0jHghDU: {
//     income: {
//         split_percent: true,
//         trx_fee: 0,
//         split: 0,
//         commission_percent: true,
//         commission: 0,
//         trx_fee_percent: false
//     },
//     sources: [],
//     initial_tasks: {
//         seller: [],
//         buyer: []
//     },
//     documents: {
//         buyer: [],
//         seller: []
//     }
// }
// },
// languages: [
//     {
//         name: 'English',
//         checked: true,
//         default: true,
//         id: 'en'
//     },
//     {
//         name: 'Spanish',
//         id: 'es',
//         default: false,
//         checked: true
//     }
// ],
// active_language_id: 'en',
// id: 'xebANMW5jPfyrdoZFOtS12X9erC2',
// first_name: 'Marc',
// type: 'member',
// active_team_id: 'rIUZve6cngv77fIQVmeZ',
// phone: [
//     {
//         primary: true,
//         number: '2395372615',
//         type: 'office'
//     }
// ],
// last_name: 'Vivonetto'
// };

export const formatNewMemberData = ({ user }) => {
    const agentLicense =
        user.type.sub_type === 'sales_associate'
            ? user.licNumber === ''
                ? null
                : user.licNumber
            : null;
    const brokerLicense =
        user.type.sub_type === 'broker'
            ? user.licNumber === ''
                ? null
                : user.licNumber
            : null;
    const userMls = user.skip
        ? {
              [user.org_id]: []
          }
        : user.mlsId
        ? {
              [user.org_id]: [
                  {
                      mls_id: user.mlsId,
                      provider_id: user.mls.id,
                      provider_name: user.mls.name
                  }
              ]
          }
        : { [user.org_id]: [] };
    const isOwner = user.type.sub_type === 'owner' || user.type.permission === 'owner';
    const userType = user.type.sub_type === 'owner' ? 'owner' : 'member';
    const providerId = user.mls ? user.mls.id : null;
    const userLanguages = [
        {
            checked: true,
            default: user.lang ? (user.lang.trim() === 'english' ? true : false) : true,
            id: 'en',
            name: 'English'
        },
        {
            checked: true,
            default: user.lang ? (user.lang.trim() !== 'english' ? true : false) : false,
            id: 'es',
            name: 'Spanish'
        }
    ];
    const activeLanguage = user.lang
        ? user.lang.trim() === 'english'
            ? 'en'
            : 'es'
        : 'en';

    return {
        ...FS_USER_SCHEMA,
        about: user.skip ? '' : user.about,
        active_org_id: user.org_id,
        active_language_id: activeLanguage,
        active_location_id: user.active_location_id,
        active_provider_id: user.skip ? null : providerId,
        agent_lic: user.skip ? null : agentLicense,
        broker_lic: user.skip ? null : brokerLicense,
        email: user.email,
        first_name: user.firstName,
        id: user.user_id,
        last_name: user.lastName,
        language: activeLanguage,
        languages: userLanguages,
        mls: userMls,
        orgs: [
            {
                brokered_by: user.brokered_by,
                id: user.org_id,
                locations: user.org_locations,
                owner: isOwner,
                sub_type: user.type.sub_type,
                title: user.type.langAgnosticLabel
            }
        ],
        phone: [
            {
                primary: true,
                number: user.phone,
                type: 'mobile'
            }
        ],
        preferences: {
            [user.org_id]: {
                ...FS_USER_PREFERENCES
            }
        },
        role: 'user',
        type: userType,
        user_avatar: user.user_avatar
    };
};

export const getPackPageIndex = (pagesLength, pageNumber, documentIndex) => {
    let packPageIndex = 0;
    for (let i = 0; i < documentIndex; i++) {
        packPageIndex += pagesLength[i];
    }
    return packPageIndex + pageNumber;
};
