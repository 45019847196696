import {
    REMOVING_PREFERENCE_TASK,
    ADDING_PREFERENCE_TASK,
    REMOVING_PREFERENCE_TASK_SUCCESS,
    REMOVING_PREFERENCE_TASK_FAILURE,
    ADDING_PREFERENCE_TASK_SUCCESS,
    ADDING_PREFERENCE_TASK_FAILURE,
    UPDATE_PREFERENCE_USER_DOCUMENTS,
    UPDATING_PREFERENCE_USER_LANGUAGES,
    UPDATE_PREFERENCE_USER_NOTIFICATIONS,
    UPDATING_PREFERENCE_INITIAL_TASKS,
    SET_PREFERENCE_ACTIVE_TAB
} from './types';

export const removePreferenceTask = task => {
    return {
        type: REMOVING_PREFERENCE_TASK,
        payload: task
    };
};

export const addPreferenceTask = task => {
    return {
        type: ADDING_PREFERENCE_TASK,
        payload: task
    };
};

export const taskRemovalSuccess = () => {
    return {
        type: REMOVING_PREFERENCE_TASK_SUCCESS
    };
};

export const taskAdditionSuccess = () => {
    return {
        type: ADDING_PREFERENCE_TASK_SUCCESS
    };
};

export const taskRemovalFailure = () => {
    return {
        type: REMOVING_PREFERENCE_TASK_FAILURE
    };
};

export const taskAdditionFailure = () => {
    return {
        type: ADDING_PREFERENCE_TASK_FAILURE
    };
};

export const updatePreferenceUserNotifications = notifications => {
    return {
        type: UPDATE_PREFERENCE_USER_NOTIFICATIONS,
        payload: notifications
    };
};

export const updatePreferenceUserDocuments = docs => {
    return {
        type: UPDATE_PREFERENCE_USER_DOCUMENTS,
        payload: docs
    };
};

export const updatePreferenceUserLanguages = languages => {
    return {
        type: UPDATING_PREFERENCE_USER_LANGUAGES,
        payload: languages
    };
};

export const updatePreferenceInitialTasks = tasks => {
    return {
        type: UPDATING_PREFERENCE_INITIAL_TASKS,
        payload: tasks
    };
};

export const setPreferenceActiveTab = tab => {
    return {
        type: SET_PREFERENCE_ACTIVE_TAB,
        payload: tab
    };
};
