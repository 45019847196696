import React from 'react';

export const MemberCard = ({ imgByIds, member }) => {
    return (
        <div className="row d-flex align-items-center ">
            <img
                src={imgByIds[member.id || member.user_id]}
                alt=""
                className="rounded-circle contact-frame__avatar--small mr-3 skeleton-loader"
            />
            <div>
                <h6 className="h6 font-weight-medium mb-0 pointer">
                    {member?.first_name} {member?.last_name}
                </h6>
                <p className="small mb-0">{member?.email}</p>
            </div>
        </div>
    );
};
