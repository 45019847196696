// React
import React, { useEffect, useMemo, useState } from 'react';

import SimpleBar from 'simplebar-react';

// Context
import { useLang } from '../../../context/LangContext';

import { MembersForm } from './MembersForm';
import { MemberCard } from './MemberCard';
import { Form, Formik } from 'formik';
import { invitationStatuses } from '../../../utils/Constants';
import ActionButtons from '../shared/ActionButtons';
import { getUserAvatars } from '../../../utils/Helpers';

const TeamMembers = ({
    team,
    isBroker,
    updateUserTeamName,
    teamMembers,
    setScreen,
    teamScreen,
    userData,
    deleteUserTeam,
    leaveUserTeam,
    removeTeamMember
}) => {
    const [removeMembers, setRemoveMembers] = useState([]);
    const { teamList, header, pending_invites_label } = useLang()['Settings']['Team'];
    const { save_label, cancel_label, delete_label, leave_label } = useLang()['Settings'];

    const allTeamMembers = useMemo(
        () =>
            teamMembers &&
            team &&
            [
                ...teamMembers,
                ...Object.values(
                    team.lead_id === userData.id ? team.invitations || {} : {}
                )
            ]?.sort((a, b) => a?.first_name?.localeCompare(b?.first_name)),
        [teamMembers, team, userData]
    );

    // Getting users avatars
    const [imgByIds, setImgByIds] = useState([]);
    const ids = useMemo(
        () => allTeamMembers?.map(member => member.id || member.user_id),
        [allTeamMembers]
    );

    useEffect(() => {
        getUserAvatars({ ids, setImgByIds });
    }, [ids]);

    const invitationsNumber = useMemo(
        () =>
            Object.values(userData.team_invites || {}).filter(
                el => el.status === invitationStatuses.pending
            ).length,
        [userData]
    );

    const handleRemove = member => {
        const exist = removeMembers.some(
            el => el.userId === (member.id || member.user_id)
        );
        if (exist) {
            setRemoveMembers(
                removeMembers.filter(
                    item => item.userId !== (member.id || member.user_id)
                )
            );
        } else {
            setRemoveMembers([
                ...removeMembers,
                { userId: member.id || member.user_id, inviteId: member.invite_id }
            ]);
        }
    };

    return (
        <>
            <Formik
                initialValues={{ name: team?.name || '' }}
                onSubmit={values => {
                    if (removeMembers.length) {
                        removeTeamMember({
                            teamId: team.id,
                            removeMembers
                        });
                    }
                    if (team.name !== values.name) {
                        updateUserTeamName({
                            teamId: team.id,
                            name: values.name
                        });
                    }
                    setRemoveMembers([]);
                }}
                enableReinitialize
            >
                {({ values, dirty, handleChange, handleSubmit, handleReset }) => (
                    <>
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <Form>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {userData.id === team.lead_id && (
                                        <i className="fa-regular fa-pen-to-square edit-icon" />
                                    )}
                                    <input
                                        className="team-name h5 mb-0"
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        disabled={userData.id !== team.lead_id}
                                        style={{
                                            cursor:
                                                userData.id !== team.lead_id
                                                    ? 'default'
                                                    : 'pointer'
                                        }}
                                    />
                                </div>
                            </Form>
                            <div className="col-6 d-flex align-items-center justify-content-end card-header__actions">
                                <button
                                    className="btn btn-outline-secondary d-flex align-items-center mr-2"
                                    style={{ maxHeight: '36px' }}
                                    onClick={() =>
                                        setScreen(teamScreen.manageInvitations)
                                    }
                                >
                                    {pending_invites_label}
                                    {!!invitationsNumber && (
                                        <span className="badge badge-md badge-outline-secondary rounded-circle ml-1">
                                            {invitationsNumber}
                                        </span>
                                    )}
                                </button>
                                {userData.id === team.lead_id && (
                                    <button
                                        className="btn btn-outline-secondary d-flex align-items-center mr-2"
                                        style={{ maxHeight: '36px' }}
                                        onClick={() => {
                                            if (teamMembers) {
                                                setScreen(teamScreen.invite);
                                            } else {
                                                setScreen(teamScreen.new);
                                            }
                                        }}
                                    >
                                        {teamMembers
                                            ? header.invite_label
                                            : header.add_team_label}
                                    </button>
                                )}
                            </div>
                        </div>
                        <div
                            className="card-body"
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '0'
                            }}
                        >
                            <SimpleBar
                                className="container"
                                style={{ overflowY: 'auto', height: '100%' }}
                            >
                                <>
                                    {!!allTeamMembers.length && (
                                        <div className="row d-flex mb-2">
                                            <div className="col-5">
                                                <h3 className="h5 font-weight-medium">
                                                    {teamList?.name_label}
                                                </h3>
                                            </div>
                                            <div className="col-7 pl-2">
                                                <h3 className="h5 font-weight-medium">
                                                    {teamList?.type_label}
                                                </h3>
                                            </div>
                                        </div>
                                    )}
                                    <div className="container pr-0">
                                        {allTeamMembers?.map(member => {
                                            const isRemoved = removeMembers.some(
                                                el =>
                                                    el.userId ===
                                                    (member.id || member.user_id)
                                            );
                                            return (
                                                <div
                                                    key={member.id || member.user_id}
                                                    style={{
                                                        opacity: isRemoved && '0.7'
                                                    }}
                                                    className="row d-flex align-items-center w-100 mb-4 pr-0"
                                                >
                                                    <div className="col-5">
                                                        <MemberCard
                                                            member={member}
                                                            imgByIds={imgByIds}
                                                        />
                                                    </div>
                                                    <div className="col-7 d-flex align-items-center pl-5">
                                                        <MembersForm
                                                            team={team}
                                                            member={member}
                                                            userData={userData}
                                                            isRemoved={isRemoved}
                                                            removeMembers={removeMembers}
                                                            handleRemove={handleRemove}
                                                            isBroker={isBroker}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            </SimpleBar>
                        </div>
                        <ActionButtons
                            deleteLabel={delete_label}
                            noDelete={userData.id !== team.lead_id}
                            handleDelete={() => {
                                deleteUserTeam(team);
                            }}
                            leaveLabel={leave_label}
                            handleLeave={() => leaveUserTeam(team)}
                            disabled={!removeMembers.length && !dirty}
                            noCancel={!removeMembers.length && !dirty}
                            saveLabel={save_label}
                            cancelLabel={cancel_label}
                            handleSubmit={handleSubmit}
                            handleClose={() => {
                                handleReset();
                                setRemoveMembers([]);
                            }}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};

export default TeamMembers;
