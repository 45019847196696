import {
    all,
    fork,
    takeLatest,
    put,
    call,
    take,
    race,
    select,
    cancelled
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    ADD_NEW_PIPELINE_CONTACT,
    EDIT_PIPELINE_CONTACT,
    GET_USER_PIPELINE,
    LOGOUT_USER,
    REMOVE_PIPELINE_CONTACT
} from '../actions/types';

import { storingPipelineContacts } from '../actions/Pipeline';

import { rtdb } from '../../config/Firebase';

import * as selectors from './Selectors';

import { confirmSaga } from './Modal';
import { confirmationDialogTypes } from '../../utils/Constants';
import { setConfirmModalType } from '../actions/Modal';

// Loggers
import { log } from '../../utils/Loggers';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Pipeline Collection Watch //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* pipelineCollectionWatch(user) {
    const pipelineRef = rtdb.ref(`pipeline/${user.id}`);

    const AddPipelineContactChannel = eventChannel(emit => {
        const unsubscribeUserPipelineData = pipelineRef.on(
            'child_added',
            childSnapshot => {
                if (childSnapshot && childSnapshot.val()) {
                    emit(childSnapshot.val());
                }
            }
        );
        return unsubscribeUserPipelineData;
    });

    const ChangePipelineContactChannel = eventChannel(emit => {
        const unsubscribeUserPipelineData = pipelineRef.on(
            'child_changed',
            childSnapshot => {
                if (childSnapshot && childSnapshot.val()) {
                    emit(childSnapshot.val());
                }
            }
        );
        return unsubscribeUserPipelineData;
    });

    const RemovePipelineContactChannel = eventChannel(emit => {
        const unsubscribeUserPipelineData = pipelineRef.on(
            'child_removed',
            childSnapshot => {
                if (childSnapshot && childSnapshot.val()) {
                    emit(childSnapshot.val());
                }
            }
        );
        return unsubscribeUserPipelineData;
    });

    const detachSagaEmitters = () => {
        AddPipelineContactChannel.close();
        ChangePipelineContactChannel.close();
        RemovePipelineContactChannel.close();
    };

    const detachFBListeners = () => {
        pipelineRef.off('child_added');
        pipelineRef.off('child_changed');
        pipelineRef.off('child_removed');
    };

    try {
        while (true) {
            const currentPipeline = yield select(selectors._pipelineContacts);
            const pipelineContacts = currentPipeline ? [...currentPipeline] : [];

            const getContactIndex = contact => {
                const data = contact;
                const contactIndex = pipelineContacts.findIndex(
                    contact => contact.id === data.id
                );
                return contactIndex;
            };

            let index;
            const {
                userSignOut,
                addPipelineContactData,
                changePipelineContactData,
                removePipelineContactData
            } = yield race({
                userSignOut: take(LOGOUT_USER),
                addPipelineContactData: take(AddPipelineContactChannel),
                changePipelineContactData: take(ChangePipelineContactChannel),
                removePipelineContactData: take(RemovePipelineContactChannel)
            });

            if (userSignOut) {
                detachSagaEmitters(); // Detaching saga event emitters
            } else if (addPipelineContactData) {
                index = getContactIndex(addPipelineContactData);
                if (index < 0) pipelineContacts.push(addPipelineContactData);
                yield put(storingPipelineContacts(pipelineContacts));
            } else if (changePipelineContactData) {
                index = getContactIndex(changePipelineContactData);
                if (index < 0) {
                    pipelineContacts.push(changePipelineContactData);
                } else {
                    pipelineContacts[index] = changePipelineContactData;
                }
                yield put(storingPipelineContacts(pipelineContacts));
            } else if (removePipelineContactData) {
                index = getContactIndex(removePipelineContactData);
                if (index >= 0) pipelineContacts.splice(index, 1);
                yield put(storingPipelineContacts(pipelineContacts));
            }
        }
    } catch (error) {
        //TODO: Error Handling
        log('Pipeline Contacts Error: getting user pipeline contacts (RTDB)', {
            error,
            user
        });
    } finally {
        detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            detachFBListeners(); // Detaching firebase listeners
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Adding Pipeline Contact ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addingPipelineContactRequest = ({ contact, userData }) => {
    const userPipelineObj = rtdb.ref(`pipeline/${userData.id}`).push();
    const pipelineId = userPipelineObj.key;
    const pipelineRef = rtdb.ref(`pipeline/${userData.id}/${pipelineId}`);

    return new Promise((resolve, reject) => {
        const {
            address,
            address2,
            city,
            state,
            zip,
            lat,
            lon,
            firstName,
            lastName,
            fullLegalName,
            email,
            phone,
            notes
        } = contact;

        const contactData = {
            address: {
                address_1: address !== '' ? address.trim() : null,
                address_2: address2 !== '' ? address2.trim() : null,
                city: city !== '' ? city.trim() : null,
                state: state !== '' ? state.trim() : null,
                zip: zip !== '' ? zip.trim() : null,
                lat: lat !== '' ? lat : null,
                lon: lon !== '' ? lon : null
            },
            email: email.trim(),
            id: pipelineId,
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            legal_name: fullLegalName.trim(),
            phone: phone.trim(),
            pipeline_id: pipelineId,
            notes: notes !== '' ? notes.trim() : null
        };

        pipelineRef.set({ ...contactData }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        });
    });
};

export function* addingPipelineContact({ payload }) {
    const { contact } = payload;
    const userData = yield select(selectors._userData);

    if (contact) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { res, error } = yield call(() =>
            addingPipelineContactRequest({ contact, userData })
        );

        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            // Error Handling for sentry with put and maybe UI message
            log('Pipeline Error: adding new contact(s) (FS)', {
                error,
                contact
            });
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
        }
    }
}

const editingPipelineContactRequest = ({ contact, userData }) => {
    const pipelineContactRef = rtdb.ref(`pipeline/${userData.id}/${contact.id}`);

    return new Promise((resolve, reject) => {
        const {
            address,
            address2,
            city,
            state,
            zip,
            lat,
            lon,
            firstName,
            lastName,
            fullLegalName,
            email,
            phone,
            notes,
            id
        } = contact;

        const contactData = {
            address: {
                address_1: address !== '' ? address.trim() : null,
                address_2: address2 !== '' ? address2.trim() : null,
                city: city !== '' ? city.trim() : null,
                state: state !== '' ? state.trim() : null,
                zip: zip !== '' ? zip.trim() : null,
                lat: lat !== '' ? lat : null,
                lon: lon !== '' ? lon : null
            },
            email: email !== '' ? email.trim() : null,
            id: id,
            first_name: firstName !== '' ? firstName.trim() : null,
            last_name: lastName !== '' ? lastName.trim() : null,
            legal_name: fullLegalName !== '' ? fullLegalName.trim() : null,
            phone: phone !== '' ? phone.trim() : null,
            pipeline_id: id,
            notes: notes !== '' ? notes.trim() : null
        };

        return pipelineContactRef.update({ ...contactData }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        });
    });
};

export function* editingPipelineContact({ payload }) {
    const { contact, userData } = payload;

    if (contact) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { res, error } = yield call(() =>
            editingPipelineContactRequest({ contact, userData })
        );

        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            // Error Handling for sentry with put and maybe UI message
            log('Pipeline Error: editing existing contact (FS)', {
                error,
                contact
            });
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Removing Pipeline Contact //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removingPipelineContactRequest = async ({ contact, userData }) => {
    const pipelineContactRef = rtdb.ref(`pipeline/${userData.id}/${contact.id}`);

    return pipelineContactRef
        .remove()
        .then(() => ({ res: true }))
        .catch(error => ({ err: error }));
};

export function* removingPipelineContact({ payload }) {
    const { userData, contact } = payload;
    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.delete
    });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { res, error } = yield call(() =>
            removingPipelineContactRequest({
                userData,
                contact
            })
        );
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Pipeline Contacts Error: removing pipeline contact (RTDB)`, {
                error,
                contact,
                userData
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getPipelineCollection() {
    yield takeLatest(GET_USER_PIPELINE, pipelineCollectionWatch);
}

export function* addPipelineContact() {
    yield takeLatest(ADD_NEW_PIPELINE_CONTACT, addingPipelineContact);
}

export function* editPipelineContact() {
    yield takeLatest(EDIT_PIPELINE_CONTACT, editingPipelineContact);
}

export function* removePipelineContact() {
    yield takeLatest(REMOVE_PIPELINE_CONTACT, removingPipelineContact);
}

export default function* rootSaga() {
    yield all([
        fork(getPipelineCollection),
        fork(addPipelineContact),
        fork(editPipelineContact),
        fork(removePipelineContact)
    ]);
}
