// React
import React from 'react';
import cx from 'classnames';

// Components
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const DropdownMenuComponent = ({ dropItems, angle }) => (
    <UncontrolledButtonDropdown>
        <DropdownToggle
            style={{
                background: angle ? 'rgba(255, 255, 255, 0.1)' : 'none',
                border: 'none'
            }}
            className={cx('contact-frame__toggle', {
                'angle-button': angle
            })}
        >
            {angle ? (
                <i className="fa-solid fa-angle-down" />
            ) : (
                <FontAwesomeIcon
                    className="pointer"
                    style={{ color: '#77838f', fontSize: '1.2rem' }}
                    icon={faEllipsisV}
                />
            )}
        </DropdownToggle>
        <DropdownMenu
            right
            className={cx('animate', {
                slideInStayRight: !angle
            })}
        >
            {dropItems.map(item => (
                <span key={item.name} className="dropdown-link">
                    <DropdownItem
                        className="dropdown-item"
                        color="#000"
                        onClick={item.onClickHandler}
                    >
                        {item.icon && <small className={item.icon} />} {item.name}
                    </DropdownItem>
                </span>
            ))}
        </DropdownMenu>
    </UncontrolledButtonDropdown>
);

export default DropdownMenuComponent;
