// React
import React from 'react';

// Assets
import PDF from '../../assets/svg/PDF.svg';

// Components
import { Loader, TextField } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';

const TransactionUploadModal = ({
    setSource,
    source,
    toggleModal,
    loading,
    uploadModal,
    mimeCat,
    setMimeCat,
    imageSrc,
    setImageSrc,
    setPostAttachment,
    uploadSend,
    fileTitle,
    setFileTitle
}) => {
    const kb = 1024;
    const mb = 1048576;
    const {
        image_label,
        file_label,
        attach_file_label,
        add_title_label,
        add_title_placeholder,
        name_label,
        size_label,
        attach_button_label,
        attach_send_button_label,
        cancel_button_label
    } = useLang()['Common']['TransactionUploadModal'];

    const type = mimeCat === 'photo' ? image_label : file_label;

    const onCancel = () => {
        toggleModal({ reset: null, auto: null, cancel: true });
        clearData();
    };

    const clearData = () => {
        setSource(null);
        setMimeCat(null);
        setImageSrc(null);
        setFileTitle('');
    };

    const attachFile = () => {
        setPostAttachment({
            src: source,
            file_type: mimeCat === 'photo' ? 'photo' : 'document',
            storage_type: mimeCat === 'photo' ? 'photos' : 'documents',
            title: fileTitle
        });
        toggleModal({ reset: null, auto: null, cancel: null });
        clearData();
    };

    return (
        <div>
            <Modal
                isOpen={uploadModal}
                toggle={toggleModal}
                onExit={clearData}
                contentClassName="document-upload-modal"
                className="document-upload-modal"
            >
                <ModalHeader
                    toggle={onCancel}
                >{`${type} ${attach_file_label}`}</ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            maxWidth: '450px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                className={`mt-3 ${type === 'Image' ? 'mb-3' : 'mb-1'}`}
                                style={{
                                    maxHeight: '125px'
                                }}
                                src={type === 'Image' ? imageSrc : PDF}
                                alt={type === 'Image' ? 'IMAGE' : 'PDF'}
                            />
                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-12 mt-3 mb-2">
                                    <div className="js-form-message form-group">
                                        <Label className="form-label">
                                            {add_title_label}
                                            <span style={{ color: '#de4437' }}> *</span>
                                        </Label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder={add_title_placeholder}
                                            aria-label={add_title_placeholder}
                                            value={fileTitle}
                                            onChange={e => setFileTitle(e.target.value)}
                                            nonFormik={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ width: '100%' }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    className="col-12"
                                >
                                    <span>
                                        {name_label}
                                        <span className="text-primary">
                                            {source?.name}
                                        </span>
                                    </span>
                                    <span className="mb-3">
                                        {size_label}
                                        <span className="text-primary">
                                            {source
                                                ? Math.ceil(source.size / kb) > 1000
                                                    ? `${
                                                          Math.round(
                                                              (source.size / mb) * 100
                                                          ) / 100
                                                      } MB`
                                                    : `${Math.ceil(source.size / kb)} KB`
                                                : null}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    {loading && <Loader />}
                    <button className="btn btn-link text-danger" onClick={onCancel}>
                        {cancel_button_label}
                    </button>
                    <div>
                        <Button
                            className="ml-1 mr-3"
                            color="primary"
                            disabled={!fileTitle}
                            onClick={attachFile}
                        >
                            {attach_button_label}
                        </Button>

                        <Button
                            color="primary"
                            disabled={!fileTitle}
                            onClick={() =>
                                uploadSend({
                                    src: source,
                                    file_type: mimeCat === 'photo' ? 'photo' : 'document',
                                    storage_type:
                                        mimeCat === 'photo' ? 'photos' : 'documents',
                                    title: fileTitle
                                })
                            }
                        >
                            {attach_send_button_label}{' '}
                            <i className="fas fa-paper-plane ml-1" />
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default TransactionUploadModal;
