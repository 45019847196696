// React
import React from 'react';

// Components
import { SideNavigation } from '../components';

// Packages
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

// Pages
import {
    InvitationAcceptance,
    InvitationError,
    InvitationServices,
    Landing,
    Login,
    ResetPassword,
    AuthenticationServices,
    Pitch,
    Page404,
    TransactionInvitationService,
    TrxInvitationAcceptance,
    FormSigningService
} from '../pages';

//Router
import * as routes from '../router/config/routes';
import AuthenticatedRouter from '../router/AuthenticatedRouter';

// PrivateRoute Components makes sure a user who is not signed in/authenticated cannot visit the authenticated area like Dashboard Page...if they try and access Dashboard route they will be redirected to the Login Page
const PrivateRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser ? (
                Component ? (
                    <Component {...props} />
                ) : (
                    rest.render(props)
                )
            ) : (
                <Redirect to={routes.LOGIN} />
            )
        }
    />
);

// PrevAuthedRoute Components makes sure a user who is signed in/authenticated cannot login again or sign up again...if they try and access the login route or sign up route they will be redirected to the Dashboard Page
const PrevAuthedRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !authUser ? <Component {...props} /> : <Redirect to={routes.AUTHENTICATED} />
        }
    />
);

const AppRouter = props => {
    const { match, history, authUser, userType } = props;
    // console.log(history);
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={routes.LANDING}
                    match={match}
                    history={history}
                    component={() => <Landing authUser={authUser} />}
                />
                <Route
                    exact
                    path={routes.PITCH}
                    match={match}
                    history={history}
                    component={() => <Pitch authUser={authUser} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.LOGIN}
                    component={props => <Login authUser={authUser} {...props} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.RESET_PASSWORD}
                    component={props => <ResetPassword authUser={authUser} {...props} />}
                />
                <PrevAuthedRoute
                    exact
                    authUser={authUser}
                    path={routes.AUTH_SERVICES}
                    component={props => (
                        <AuthenticationServices authUser={authUser} {...props} />
                    )}
                />
                <PrivateRoute
                    authUser={authUser}
                    path={routes.AUTHENTICATED}
                    render={props => (
                        <>
                            <SideNavigation />
                            <AuthenticatedRouter
                                authUser={authUser}
                                userType={userType}
                                {...props}
                            />
                        </>
                    )}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.ACCEPT_TEAM_INVITATION}
                    match={match}
                    history={history}
                    component={() => <TransactionInvitationService authUser={authUser} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.ACCEPT_NEW_TRX_INVITATION}
                    match={match}
                    history={history}
                    component={() => <TrxInvitationAcceptance authUser={authUser} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.ACCEPT_INVITATION}
                    match={match}
                    history={history}
                    component={() => <InvitationServices authUser={authUser} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.ACCEPT_NEW_INVITATION}
                    match={match}
                    history={history}
                    component={() => <InvitationAcceptance authUser={authUser} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.INVITATION_ERROR}
                    match={match}
                    history={history}
                    component={() => <InvitationError authUser={authUser} />}
                />
                <Route
                    exact
                    authUser={authUser}
                    path={routes.FORM_SIGNING_SERVICE}
                    match={match}
                    history={history}
                    component={() => <FormSigningService authUser={authUser} />}
                />

                <Route component={Page404} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
