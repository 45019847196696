import {
    SET_USER_ORG,
    UPDATE_ORG_LOCATION,
    UPDATE_ORG_LOCATION_SUCCESS,
    UPDATE_ORG_LOCATION_FAILURE,
    PREPARE_MEMBER_INVITATIONS,
    SET_PREPARED_INVITATIONS,
    SET_ORG_INVITATIONS
} from '../actions/types';

const INITIAL_STATE = {
    orgs: null,
    activeOrg: null,
    loading: false,
    error: null,
    matches: [],
    nonMatches: [],
    orgInvitations: []
};

const filteredUserOrg = ({ orgs, active_org_id }) => {
    return orgs.filter(org => org.id === active_org_id)[0];
};

const filteredOrgInvites = invites => {
    return invites.filter(invite => invite.status !== 'accepted');
};

const orgReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_ORG:
            return {
                ...state,
                activeOrg: filteredUserOrg(action.payload),
                orgs: action.payload.orgs
            };
        case UPDATE_ORG_LOCATION:
            return {
                ...state,
                loading: true,
                error: INITIAL_STATE.error
            };
        case UPDATE_ORG_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: INITIAL_STATE.error
            };
        case UPDATE_ORG_LOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case PREPARE_MEMBER_INVITATIONS:
            return {
                ...state,
                loading: true
            };
        case SET_PREPARED_INVITATIONS:
            return {
                ...state,
                matches: action.payload.existing,
                nonMatches: action.payload.nonExisting,
                loading: false
            };
        case SET_ORG_INVITATIONS:
            return {
                ...state,
                orgInvitations: filteredOrgInvites(action.payload)
            };
        default:
            return state;
    }
};

export default orgReducer;
