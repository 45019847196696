import {
    SET_TRANSACTION_LIST,
    SET_USER_TRANSACTIONS,
    SET_USER_CLOSED_TRANSACTIONS,
    SET_USER_ARCHIVED_TRANSACTIONS,
    SET_PROPERTY_DATA,
    CREATE_TRANSACTION,
    CREATE_TRANSACTION_SUCCESS,
    // CREATE_TRANSACTION_FAILURE,
    RESET_TRANSACTION_STATE,
    UPDATE_TRANSACTION_NOTE,
    UPDATE_TRANSACTION_NOTE_SUCCESS,
    // UPDATE_TRANSACTION_NOTE_FAILURE,
    UPDATE_TRANSACTION_TASK,
    UPDATE_TRANSACTION_TASK_SUCCESS,
    UPDATE_TRANSACTION_TASK_FAILURE,
    UPDATE_ACTIVITY_POST,
    UPDATE_ACTIVITY_POST_SUCCESS,
    // UPDATE_ACTIVITY_POST_FAILURE,
    SET_TRANSACTION_FILTER,
    SET_TRX_SEARCH_SUGGESTIONS,
    GET_TRANSACTION_DETAILS,
    SET_TRANSACTION_DETAILS,
    SET_CLIENT_TRANSACTION_DETAILS,
    RESET_DETAILS_FLAG,
    CANCEL_DETAILS_LISTENER,
    UPDATE_TRANSACTION_STATUS,
    UPDATE_TRANSACTION_DETAILS,
    UPDATE_TRANSACTION_MEMBERS,
    ADD_PARTY_TO_TRANSACTION,
    REMOVE_PARTY_FROM_TRANSACTION,
    SET_MEMBER_TRANSACTION_LIST,
    SET_MEMBER_TRANSACTION_FILTER,
    GET_MEMBER_TRANSACTIONS,
    SET_MEMBER_TRANSACTIONS,
    SET_MEMBER_CLOSED_TRANSACTIONS,
    SET_MEMBER_ARCHIVED_TRANSACTIONS,
    RESET_MEMBER_TRX_FILTER,
    INVITE_PARTY_TO_TRANSACTION,
    UPDATE_TIME_VISITING_TRANSACTION,
    UPDATE_OPPOSING_MEMBERS,
    UPDATE_OPPOSING_MEMBERS_SUCCESS,
    UPDATE_OPPOSING_MEMBERS_FAILURE,
    REMOVE_OPPOSING_MEMBER,
    REMOVE_OPPOSING_MEMBER_SUCCESS,
    REMOVE_OPPOSING_MEMBER_FAILURE
} from './types';

export const setTransactionList = type => {
    return {
        type: SET_TRANSACTION_LIST,
        payload: type
    };
};

export const storeUserTransactions = transactions => {
    return {
        type: SET_USER_TRANSACTIONS,
        payload: transactions
    };
};

export const storeClosedTransactions = transactions => {
    return {
        type: SET_USER_CLOSED_TRANSACTIONS,
        payload: transactions
    };
};

export const storeArchivedTransactions = transactions => {
    return {
        type: SET_USER_ARCHIVED_TRANSACTIONS,
        payload: transactions
    };
};

export const setMemberTransactionList = type => {
    return {
        type: SET_MEMBER_TRANSACTION_LIST,
        payload: type
    };
};

export const storeMemberTransactions = transactions => {
    return {
        type: SET_MEMBER_TRANSACTIONS,
        payload: transactions
    };
};

export const storeMemberClosedTransactions = transactions => {
    return {
        type: SET_MEMBER_CLOSED_TRANSACTIONS,
        payload: transactions
    };
};

export const storeMemberArchivedTransactions = transactions => {
    return {
        type: SET_MEMBER_ARCHIVED_TRANSACTIONS,
        payload: transactions
    };
};

export const createNewTransaction = transaction => {
    return {
        type: CREATE_TRANSACTION,
        payload: transaction
    };
};

export const transactionWriteSuccess = id => {
    return {
        type: CREATE_TRANSACTION_SUCCESS,
        payload: id
    };
};

export const resettingTransactionState = () => {
    return {
        type: RESET_TRANSACTION_STATE
    };
};

export const setPropertyData = data => {
    return {
        type: SET_PROPERTY_DATA,
        payload: data
    };
};

export const updateTransactionNotes = notes => {
    return {
        type: UPDATE_TRANSACTION_NOTE,
        payload: notes
    };
};

export const noteWriteSuccess = () => {
    return {
        type: UPDATE_TRANSACTION_NOTE_SUCCESS
    };
};

export const updateTransactionTasks = tasks => {
    return {
        type: UPDATE_TRANSACTION_TASK,
        payload: tasks
    };
};

export const taskWriteSuccess = () => {
    return {
        type: UPDATE_TRANSACTION_TASK_SUCCESS
    };
};

export const taskWriteFailure = () => {
    return {
        type: UPDATE_TRANSACTION_TASK_FAILURE
    };
};

export const updateActivityPosts = posts => {
    return {
        type: UPDATE_ACTIVITY_POST,
        payload: posts
    };
};

export const postWriteSuccess = () => {
    return {
        type: UPDATE_ACTIVITY_POST_SUCCESS
    };
};

export const settingTransactionFilter = data => {
    return {
        type: SET_TRANSACTION_FILTER,
        payload: data
    };
};

export const settingMemberTransactionFilter = data => {
    return {
        type: SET_MEMBER_TRANSACTION_FILTER,
        payload: data
    };
};

export const settingSearchSuggestions = data => {
    return {
        type: SET_TRX_SEARCH_SUGGESTIONS,
        payload: data
    };
};

export const gettingTransactionDetails = data => {
    return {
        type: GET_TRANSACTION_DETAILS,
        payload: data
    };
};

export const settingClientTransactionDetails = data => {
    return {
        type: SET_CLIENT_TRANSACTION_DETAILS,
        payload: data
    };
};

export const settingTransactionDetails = data => {
    return {
        type: SET_TRANSACTION_DETAILS,
        payload: data
    };
};

export const resetDetailsFlag = () => {
    return {
        type: RESET_DETAILS_FLAG
    };
};

export const cancelDetailsListener = () => {
    return {
        type: CANCEL_DETAILS_LISTENER
    };
};

export const updateTransactionStatus = payload => {
    return {
        type: UPDATE_TRANSACTION_STATUS,
        payload
    };
};

export const updateTransactionMembers = payload => {
    return {
        type: UPDATE_TRANSACTION_MEMBERS,
        payload
    };
};

export const updateTransactionDetails = payload => {
    return {
        type: UPDATE_TRANSACTION_DETAILS,
        payload
    };
};

export const addPartyToTransaction = payload => {
    return {
        type: ADD_PARTY_TO_TRANSACTION,
        payload
    };
};

export const getMemberTransactions = id => {
    return {
        type: GET_MEMBER_TRANSACTIONS,
        payload: id
    };
};

export const resetMemberTransactionFilter = () => {
    return {
        type: RESET_MEMBER_TRX_FILTER
    };
};

export const removePartyFromTransaction = payload => {
    return {
        type: REMOVE_PARTY_FROM_TRANSACTION,
        payload
    };
};

export const invitePartyToTransaction = payload => {
    return {
        type: INVITE_PARTY_TO_TRANSACTION,
        payload
    };
};

export const updateTimeVisitingTransaction = payload => {
    return {
        type: UPDATE_TIME_VISITING_TRANSACTION,
        payload
    };
};

export const updatingOpposingMembers = members => {
    return {
        type: UPDATE_OPPOSING_MEMBERS,
        payload: members
    };
};

export const updatingOpposingMembersSuccess = () => {
    return {
        type: UPDATE_OPPOSING_MEMBERS_SUCCESS
    };
};

export const updatingOpposingMembersFailure = error => {
    return {
        type: UPDATE_OPPOSING_MEMBERS_FAILURE,
        payload: error
    };
};

export const removingOpposingMember = member => {
    return {
        type: REMOVE_OPPOSING_MEMBER,
        payload: member
    };
};

export const removingOpposingMemberSuccess = () => {
    return {
        type: REMOVE_OPPOSING_MEMBER_SUCCESS
    };
};

export const removingOpposingMemberFailure = error => {
    return {
        type: REMOVE_OPPOSING_MEMBER_FAILURE,
        payload: error
    };
};
