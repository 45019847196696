import { all, fork, takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import { GET_PROPERTY_MLS, GET_ACTIVE_BROKERAGES } from '../actions/types';
import { getMlsDataSuccess, gettingActiveBrokeragesSuccess } from '../actions/Property';

// Loggers
import { log } from '../../utils/Loggers';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Get Property Data //////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getPropertyDataRequest = async ({ mlsNumber }) => {
    return await axios({
        method: 'GET',
        url: `https://api.bridgedataoutput.com/api/v2/nabor/listings/${mlsNumber}`,

        headers: {
            Authorization: `Bearer 67bd5e93223b6e35219ee4941002cc2e`,
            'Content-Type': 'application/json'
        }
    })
        .then(property => {
            const data = property?.data?.bundle ? property.data.bundle : {};
            return { data: data };
        })
        .catch(err => {
            return { error: err };
        });
};

export function* getPropertyData({ payload }) {
    const { mlsNumber } = payload;
    const { data, error } = yield call(() => getPropertyDataRequest({ mlsNumber }));
    if (data) {
        // console.log(data);
        // const size = new TextEncoder().encode(JSON.stringify(data)).length;
        // const kiloBytes = size / 1024;
        // const megaBytes = kiloBytes / 1024;
        // console.log('KB: ', kiloBytes);
        // console.log('MB: ', megaBytes);
        yield put(getMlsDataSuccess(data));
    } else {
        yield put(getMlsDataSuccess(null));
        // Error Handling for sentry with put and maybe UI message
        log('Property Error: getting property data (API)', {
            error,
            mlsNumber
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// Get Property Data //////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getActiveBrokeragesRequest = async () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.bridgedataoutput.com/api/v2/nabor/offices?limit=200&OfficeStatus=Active`,

            headers: {
                Authorization: `Bearer 67bd5e93223b6e35219ee4941002cc2e`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                var brokerages = [...res.data.bundle];
                var total = res.data?.total;
                var remainingRequests;
                if (brokerages.length === total) {
                    resolve({ brokerages });
                } else {
                    const offset = 200;
                    const count = Math.ceil((total - offset) / offset);
                    remainingRequests = Array.apply(null, Array(count)).map(() => null);
                    remainingRequests.forEach((request, index) => {
                        axios({
                            method: 'GET',
                            url: `https://api.bridgedataoutput.com/api/v2/nabor/offices?offset=${
                                offset * (index + 1)
                            }&limit=200&OfficeStatus=Active`,
                            headers: {
                                Authorization: `Bearer 67bd5e93223b6e35219ee4941002cc2e`,
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(res => {
                                brokerages.push(...res.data.bundle);
                                if (index + 1 === remainingRequests.length) {
                                    resolve({ brokerages });
                                }
                            })
                            .catch(err => {
                                reject({ error: err });
                            });
                    });
                }
            })
            .catch(err => {
                reject({ error: err });
            });
    });
};

export function* getActiveBrokerages() {
    const { brokerages, error } = yield call(() => getActiveBrokeragesRequest());
    if (brokerages) {
        yield put(gettingActiveBrokeragesSuccess(brokerages));
    } else {
        yield put(gettingActiveBrokeragesSuccess([]));
        // Error Handling for sentry with put and maybe UI message
        log('Property Error: getting active brokerages data (API)', {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* gettingPropertyData() {
    yield takeLatest(GET_PROPERTY_MLS, getPropertyData);
}

export function* gettingActiveBrokerages() {
    yield takeLatest(GET_ACTIVE_BROKERAGES, getActiveBrokerages);
}

export default function* rootSaga() {
    yield all([fork(gettingPropertyData), fork(gettingActiveBrokerages)]);
}
