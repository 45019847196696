import {
    all,
    fork,
    takeLatest,
    put,
    take,
    race,
    select,
    cancelled,
    call
} from 'redux-saga/effects';

import { eventChannel } from 'redux-saga';

import {
    CREATE_USER_TEAM,
    GET_USER_TEAM,
    LOGOUT_USER,
    UPDATE_USER_TEAM_NAME,
    DELETE_USER_TEAM,
    LEAVE_USER_TEAM,
    ADD_TEAM_MEMBER,
    REMOVE_TEAM_MEMBER,
    ACCEPT_TEAM_INVITE,
    REMOVE_TEAM_INVITE,
    DECLINE_TEAM_INVITE
} from '../actions/types';
import {
    settingUserTeamSuccess,
    settingUserTeamFailure,
    deleteTeamSuccess,
    leaveTeamSuccess
} from '../actions/Teams';
import { setConfirmModalType } from '../actions/Modal';
import { db, fsFieldValue, rtdb, timeStampNow } from '../../config/Firebase';

// import { confirmSaga } from './Modal';
// import { confirmationDialogTypes } from '../../utils/Constants';
// import { setConfirmModalType } from '../actions/Modal';

// Loggers
import { log } from '../../utils/Loggers';
import { confirmationDialogTypes, invitationStatuses } from '../../utils/Constants';
import * as selectors from './Selectors';
import { confirmSaga } from './Modal';
import { generateUid } from '../../utils/Helpers';

/////////////////////////////////////////////////////////Auth///////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Pipeline Collection Watch //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const users = db.collection('users');

export function* teamsCollectionWatch(user) {
    const teamsRef = rtdb.ref(`teams/${user.team_id}`);

    const UserTeamChannel = eventChannel(emit => {
        const unsubscribeUserTeamData = teamsRef.on('value', childSnapshot => {
            if (childSnapshot && childSnapshot.val()) {
                emit(childSnapshot.val());
            }
        });
        return unsubscribeUserTeamData;
    });

    const detachSagaEmitters = () => {
        UserTeamChannel.close();
    };

    const detachFBListeners = () => {
        teamsRef.off('value');
    };

    try {
        while (true) {
            const { userSignOut, userTeamData } = yield race({
                userSignOut: take(LOGOUT_USER),
                userTeamData: take(UserTeamChannel)
            });

            if (userSignOut) {
                detachSagaEmitters(); // Detaching saga event emitters
            } else {
                yield put(settingUserTeamSuccess(userTeamData));
            }
        }
    } catch (error) {
        //TODO: Error Handling
        log('User Teams Error: getting user team data (RTDB)', {
            error,
            user
        });
        yield put(settingUserTeamFailure(error));
    } finally {
        detachFBListeners(); // Detaching firebase listeners
        if (yield cancelled()) {
            detachSagaEmitters(); // Detaching saga event emitter
            detachFBListeners(); // Detaching firebase listeners
        }
    }
}

const updateUserRequest = data => {
    const ref = users.doc(data.userId);

    return new Promise((resolve, reject) =>
        ref
            .update({
                team_id: data.teamId
            })
            .then(() => resolve({ success: true }))
            .catch(err => reject({ err }))
    );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////// Update Team Name  /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateTeamNameRequest = ({ teamId, name }) => {
    const teamRef = rtdb.ref(`teams/${teamId}`);

    return new Promise((resolve, reject) =>
        teamRef.update({ name }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        })
    );
};

export function* updateTeamName({ payload }) {
    if (payload) {
        const { res, error } = yield call(updateTeamNameRequest, payload);
        if (res) {
            log('Success');
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log('Teams Error: editing existing team', {
                error
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////// Create Team  ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const createTeamRequest = team => {
    const teamRef = rtdb.ref(`teams/${team.id}`);

    return new Promise((resolve, reject) => {
        return teamRef
            .update({ ...team })
            .then(() =>
                updateUserRequest({
                    userId: team.lead_id,
                    teamId: team.id
                })
            )
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }));
    });
};

export function* createTeam({ payload }) {
    if (payload) {
        const id = generateUid();
        const userData = yield select(selectors._userData);
        const defaultUser = {
            active: true,
            email: userData.email,
            first_name: userData.first_name,
            id: userData.id,
            last_name: userData.last_name,
            phone: userData.phone,
            role: userData.type
        };
        const team = {
            id,
            name: payload.name,
            lead_id: userData.id,
            members: {
                [userData.id]: defaultUser
            }
        };
        const { error } = yield call(createTeamRequest, team);
        if (error) {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log('Teams Error: editing existing team', {
                error
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////// Delete Team  ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const deleteTeamRequest = async team => {
    const teamRef = rtdb.ref(`teams/${team.id}`);
    return new Promise((resolve, reject) =>
        teamRef
            .remove()
            .then(() => resolve({ res: true }))
            .catch(error => reject({ err: error }))
    );
};

export function* deleteTeam({ payload }) {
    const team = payload;
    const members = team.members;
    const teamMembersIds = Object.keys(members).map(member => members[member].id);

    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.deleteTeam
    });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        const { res, error } = yield call(deleteTeamRequest, team);

        if (res) {
            const results = yield all(
                teamMembersIds.map(id =>
                    call(updateUserRequest, {
                        userId: id,
                        teamId: null
                    })
                )
            );

            const success = results.every(result => result.success === true);
            if (success) {
                yield put(setConfirmModalType(confirmationDialogTypes.success));
                yield put(deleteTeamSuccess());
            }
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Can't delete`, {
                error,
                team
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////// Leave Team  /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const leaveUserTeamRequest = async team => {
    const teamRef = rtdb.ref(`teams/${team.id}`);

    return new Promise((resolve, reject) => {
        return teamRef.update({ ...team }, error => {
            if (error) {
                reject({ error });
            } else {
                resolve({ res: true });
            }
        });
    });
};

export function* leaveTeam({ payload }) {
    const team = payload;
    const userData = yield select(selectors._userData);

    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.leaveCurrentTeam
    });

    if (isConfirm) {
        yield put(setConfirmModalType(confirmationDialogTypes.loading));
        delete team.members[userData.id];
        const { res, error } = yield call(leaveUserTeamRequest, team);

        if (res) {
            const { success } = yield call(updateUserRequest, {
                userId: userData.id,
                teamId: null
            });
            if (success) {
                yield put(setConfirmModalType(confirmationDialogTypes.success));
                yield put(leaveTeamSuccess());
            }
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log(`Can't leave`, {
                error,
                team
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Invite User to the Team  ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateUserInvitationsRequest = ({ userId, invitation }) => {
    const ref = users.doc(userId);

    return ref
        .update({ [`team_invites.${invitation.invite_id}`]: invitation })
        .then(() => ({ success: true }))
        .catch(err => ({ err }));
};

const addTeamMemberRequest = async ({ teamId, existedInvitations, newInvitations }) => {
    const teamRef = rtdb.ref(`teams/${teamId}`);

    return new Promise((resolve, reject) =>
        teamRef.update(
            { invitations: { ...existedInvitations, ...newInvitations } },
            error => {
                if (error) {
                    reject({ error });
                } else {
                    resolve({ res: true });
                }
            }
        )
    );
};

export function* addTeamMember({ payload }) {
    const { newMembers, team } = payload;
    const userData = yield select(selectors._userData);

    const newInvitations = Object.fromEntries(
        Object.values(newMembers).map(member => {
            const inviteId = generateUid();
            return [
                inviteId,
                {
                    accepted: false,
                    email: member.email,
                    expired_at: null,
                    first_name: member.first_name,
                    invite_id: inviteId,
                    invited_at: timeStampNow(),
                    last_name: member.last_name,
                    status: invitationStatuses.pending,
                    team_name: team.name,
                    team_id: team.id,
                    team_lead: {
                        email: userData.email,
                        id: userData.id,
                        first_name: userData.first_name,
                        last_name: userData.last_name
                    },
                    type: member.role,
                    user_id: member.id,
                    org_id: userData.active_org_id
                }
            ];
        })
    );

    const { res, error } = yield call(() =>
        addTeamMemberRequest({
            teamId: team.id,
            existedInvitations: team.invitations || [],
            newInvitations
        })
    );
    if (res) {
        const results = yield all(
            Object.values(newInvitations).map(invitation =>
                call(updateUserInvitationsRequest, {
                    userId: invitation.user_id,
                    invitation
                })
            )
        );
        const success = results.every(result => result.success === true);
        if (success) {
            log('Success');
        }
    } else {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));
        log('Teams Error: editing existing team', {
            error
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Remove Team Member ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const setInvitationExpiredDate = ({ userId, inviteId }) => {
    const ref = users.doc(userId);
    const path = `team_invites.${inviteId}`;

    return ref
        .update({
            [`${path}.expired_at`]: timeStampNow(),
            [`${path}.status`]: invitationStatuses.expired
        })
        .then(() => ({ success: true }))
        .catch(err => ({ err }));
};

const updateInviteesAndMembersRequest = ({ removeMembers }) => {
    return Promise.all(
        removeMembers.map(
            ({ inviteId, userId }) =>
                new Promise((resolve, reject) =>
                    (inviteId
                        ? setInvitationExpiredDate({ userId, inviteId })
                        : updateUserRequest({ userId, teamId: null })
                    )
                        .then(() => resolve({ res: true }))
                        .catch(error => reject({ error }))
                )
        )
    );
};

const updateTeamInvitationsAndMembers = ({ teamId, removeMembers }) => {
    const teamInvitationRef = rtdb.ref(`teams/${teamId}/`);

    return new Promise((resolve, reject) =>
        teamInvitationRef
            .update(
                Object.fromEntries(
                    removeMembers.map(({ inviteId, userId }) => [
                        `${inviteId ? 'invitations' : 'members'}/${inviteId || userId}`,
                        null
                    ])
                )
            )
            .then(() => resolve({ res: true }))
            .catch(error => reject({ error }))
    );
};

export function* removeTeamMember({ payload }) {
    const { teamId, removeMembers } = payload;
    const results = yield call(updateInviteesAndMembersRequest, {
        removeMembers
    });

    if (results.some(result => result.error)) {
        yield put(setConfirmModalType(confirmationDialogTypes.failed));

        results.forEach(res => {
            if (res.error) {
                log('Teams Error: update team invitee or member of the team (RTDB)', {
                    error: res.error,
                    function: 'updateInviteesAndMembersRequest'
                });
            }
        });
    } else {
        const { error } = yield call(updateTeamInvitationsAndMembers, {
            teamId,
            removeMembers
        });

        if (error) {
            log('Teams Error: update team object (remove invitations or members)(FS)', {
                error: error,
                function: 'updateTeamInvitationsAndMembers'
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Remove Team Invitation  ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeTeamInvitationRequest = ({ userId, invitationId }) => {
    const ref = users.doc(userId);
    const path = `team_invites.${invitationId}`;

    return new Promise((resolve, reject) =>
        ref
            .update({ [path]: fsFieldValue.delete() })
            .then(() => resolve({ success: true }))
            .catch(error => reject({ error }))
    );
};

export function* removeTeamInvitation({ payload }) {
    const { userId, invitationId } = payload;
    const { error } = yield call(removeTeamInvitationRequest, payload);

    if (error) {
        log('Settings Error: remove team invitation as an invitee', {
            error,
            userId,
            invitationId,
            function: 'removeTeamInvitationRequest'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Accept Team Invitation  ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addUserAsMember = ({ user, teamId, invitationId }) => {
    const teamInvitationRef = rtdb.ref(`teams/${teamId}/invitations/${invitationId}`);
    const teamRef = rtdb.ref(`teams/${teamId}/members/${user.id}`);

    const member = {
        active: true,
        email: user.email,
        first_name: user.first_name,
        id: user.id,
        last_name: user.last_name,
        phone: user.phone,
        role: user.team_invites[invitationId].type
    };

    return new Promise((resolve, reject) =>
        teamRef
            .set(member)
            .then(() => teamInvitationRef.remove())
            .then(() => resolve({ success: true }))
            .catch(err => reject({ err }))
    );
};

const acceptTeamInvitationRequest = ({ user, teamId, invitationId, team }) => {
    const ref = users.doc(user.id);

    return new Promise((resolve, reject) =>
        ref
            .update({
                team_id: teamId
            })
            .then(() => removeTeamInvitationRequest({ userId: user.id, invitationId }))
            .then(() => addUserAsMember({ user, teamId, invitationId }))
            .then(() => team?.lead_id === user.id && deleteTeamRequest(team))
            .then(
                () =>
                    team?.lead_id === user.id &&
                    Promise.all(
                        Object.keys(team.members)
                            .filter(el => el !== user.id)
                            .map(id =>
                                updateUserRequest({
                                    userId: id,
                                    teamId: null
                                })
                            )
                    )
            )
            .then(
                () =>
                    team?.lead_id === user.id &&
                    Promise.all(
                        Object.entries(team.invitations).map(([inviteId, invitation]) =>
                            setInvitationExpiredDate({
                                userId: invitation.user_id,
                                inviteId
                            })
                        )
                    )
            )
            .then(() => resolve({ success: true }))
            .catch(error => reject({ error }))
    );
};

export function* acceptTeamInvitation({ payload }) {
    const { user, teamId, invitationId } = payload;
    let shouldContinue;
    let team;

    if (user.team_id) {
        team = yield select(selectors._userTeam);

        const { isConfirm } = yield call(confirmSaga, {
            modalType:
                team.lead_id === user.id
                    ? confirmationDialogTypes.leaveAsLeaderAndJoinTeam
                    : confirmationDialogTypes.leaveAndJoinTeam
        });
        shouldContinue = isConfirm;
    } else {
        shouldContinue = true;
    }
    yield put(setConfirmModalType(null));

    if (shouldContinue) {
        const { error } = yield call(acceptTeamInvitationRequest, {
            user,
            teamId,
            invitationId,
            team
        });

        if (error) {
            log('Settings Error: accept team invitation', {
                error,
                userId: user.id,
                teamId,
                invitationId,
                function: 'acceptTeamInvitationRequest'
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////// Decline Team Invitation  ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const declineTeamInvitationRequest = ({ teamId, invitationId, userId }) => {
    const teamInvitationRef = rtdb.ref(`teams/${teamId}/invitations/${invitationId}`);

    return new Promise((resolve, reject) =>
        removeTeamInvitationRequest({ userId, invitationId })
            .then(() => teamInvitationRef.remove())
            .then(() => resolve({ success: true }))
            .catch(error => reject({ error }))
    );
};

export function* declineTeamInvitation({ payload }) {
    const { teamId, invitationId, userId } = payload;

    const { isConfirm } = yield call(confirmSaga, {
        modalType: confirmationDialogTypes.declineTeamInvite
    });
    yield put(setConfirmModalType(null));

    if (isConfirm) {
        const { error } = yield call(declineTeamInvitationRequest, payload);

        if (error) {
            log('Settings Error: decline team invitation', {
                error,
                userId,
                teamId,
                invitationId,
                function: 'declineTeamInvitationRequest'
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* getUserTeam() {
    yield takeLatest(GET_USER_TEAM, teamsCollectionWatch);
}

export function* updatingTeam() {
    yield takeLatest(UPDATE_USER_TEAM_NAME, updateTeamName);
}

export function* creatingTeam() {
    yield takeLatest(CREATE_USER_TEAM, createTeam);
}

export function* deletingTeam() {
    yield takeLatest(DELETE_USER_TEAM, deleteTeam);
}

export function* leavingTeam() {
    yield takeLatest(LEAVE_USER_TEAM, leaveTeam);
}

export function* addingTeamMember() {
    yield takeLatest(ADD_TEAM_MEMBER, addTeamMember);
}

export function* removingTeamMember() {
    yield takeLatest(REMOVE_TEAM_MEMBER, removeTeamMember);
}

export function* acceptingTeamInvitation() {
    yield takeLatest(ACCEPT_TEAM_INVITE, acceptTeamInvitation);
}

export function* decliningTeamInvitation() {
    yield takeLatest(DECLINE_TEAM_INVITE, declineTeamInvitation);
}

export function* removingTeamInvitation() {
    yield takeLatest(REMOVE_TEAM_INVITE, removeTeamInvitation);
}

export default function* rootSaga() {
    yield all([
        fork(getUserTeam),
        fork(updatingTeam),
        fork(creatingTeam),
        fork(deletingTeam),
        fork(leavingTeam),
        fork(addingTeamMember),
        fork(removingTeamMember),
        fork(acceptingTeamInvitation),
        fork(removingTeamInvitation),
        fork(decliningTeamInvitation)
    ]);
}
