// React
import React, { useState } from 'react';

// Packages
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import { Form, Formik } from 'formik';

// Context
import { useLang } from '../../../context/LangContext';

// Utils
import { capitalizeFirstLetter } from '../../../utils/Helpers';

export const TaskModal = ({ handleOpen, handleToggle, item, onSubmitCb, type }) => {
    const { modal } = useLang()['Settings']['Preferences']['Tasks'];

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const modalTitle = () => {
        if (type === 'buyer' && !item.task) {
            return modal.newCaptionBuyer;
        } else if (type === 'buyer' && item.task) {
            return modal.editCaptionBuyer;
        } else if (type === 'seller' && !item.task) {
            return modal.newCaptionSeller;
        } else if (type === 'seller' && item.task) {
            return modal.editCaptionSeller;
        }
    };
    const validateMinutesField = value => {
        if (value > 59) {
            return 0;
        } else if (value < 10) {
            return '0' + value.slice(-1);
        } else if (value.length > 2) {
            return value.slice(1, 3);
        } else {
            return value.slice(0, 2);
        }
    };

    return (
        <Modal
            centered
            isOpen={handleOpen}
            toggle={handleToggle}
            className="personalInfoModal"
        >
            <Formik
                initialValues={{
                    title: item?.task?.title || '',
                    description: item?.task?.description || '',
                    expiration: {
                        days: item?.task?.expiration?.days || '0',
                        hours: item?.task?.expiration?.hours || '12',
                        minutes:
                            item?.task?.expiration?.minutes < 10
                                ? '0' + item?.task?.expiration?.minutes
                                : item?.task?.expiration?.minutes || '00',
                        ordinals: item?.task?.expiration?.ordinals || 'PM'
                    }
                }}
                onSubmit={values => {
                    onSubmitCb({
                        task: {
                            title: capitalizeFirstLetter(values.title),
                            description: values.description,
                            expiration: {
                                days: parseInt(values.expiration.days),
                                hours: parseInt(values.expiration.hours),
                                minutes: parseInt(values.expiration.minutes),
                                ordinals: values.expiration.ordinals
                            }
                        },
                        index: item.index
                    });
                }}
            >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <>
                        <Form>
                            <ModalHeader toggle={handleToggle}>
                                {modalTitle()}
                            </ModalHeader>
                            <ModalBody>
                                <input
                                    type="text"
                                    className="fs-5 borderColor rounded form-control p-3"
                                    placeholder={modal.placeholders.title}
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                />
                                <textarea
                                    className="fs-5 borderColor rounded form-control my-3 "
                                    placeholder={modal.placeholders.description}
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    style={{
                                        height: '105px',
                                        maxHeight: '120px',
                                        resize: 'none'
                                    }}
                                />
                                <div className="d-flex align-items-center">
                                    <label
                                        className="col-4 p-0"
                                        htmlFor="days"
                                        style={{ color: '#8C98A4' }}
                                    >
                                        {modal.labels.days}
                                    </label>
                                    <input
                                        style={{ color: '#8C98A4' }}
                                        type="number"
                                        id="days"
                                        className="fs-5 col-2 borderColor rounded form-control my-3"
                                        placeholder="0"
                                        name="expiration.days"
                                        value={values.expiration.days}
                                        onChange={handleChange}
                                        min={0}
                                    />
                                </div>
                                <div className="d-flex align-items-center">
                                    <label
                                        className="col-4 p-0"
                                        htmlFor="time"
                                        style={{ color: '#8C98A4' }}
                                    >
                                        {modal.labels.time}
                                    </label>
                                    <div className="input-group flex-nowrap">
                                        <input
                                            style={{ color: '#8C98A4' }}
                                            type="number"
                                            id="days"
                                            className="col-3 borderColor rounded-left form-control"
                                            placeholder="12"
                                            name="expiration.hours"
                                            value={values.expiration.hours}
                                            onChange={event => {
                                                setFieldValue(
                                                    'expiration.hours',
                                                    event.target.value > 12
                                                        ? (event.target.value = 12)
                                                        : event.target.value.slice(0, 2)
                                                );
                                            }}
                                            min={1}
                                            max={12}
                                            maxLength={2}
                                            pattern="(1[012]|[1-9])"
                                        />
                                        <input
                                            style={{ color: '#8C98A4' }}
                                            type="number"
                                            id="days"
                                            className="fs-5 col-3 borderColor form-control "
                                            placeholder="00"
                                            name="expiration.minutes"
                                            value={values.expiration.minutes}
                                            onChange={event => {
                                                setFieldValue(
                                                    'expiration.minutes',
                                                    validateMinutesField(
                                                        event.target.value
                                                    )
                                                );
                                            }}
                                            min={0}
                                            max={59}
                                        />
                                        <div className="input-group-append">
                                            <Dropdown
                                                isOpen={dropdownOpen}
                                                toggle={toggle}
                                            >
                                                <DropdownToggle
                                                    className="btn border time-border dropdown-toggle rounded-right d-flex align-items-center"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                    onChange={toggle}
                                                    style={{
                                                        backgroundColor: ' #fff',
                                                        color: '#8C98A4',
                                                        height: '45px'
                                                    }}
                                                >
                                                    {values.expiration.ordinals}
                                                </DropdownToggle>
                                                <DropdownMenu style={{ width: '20px' }}>
                                                    <DropdownItem
                                                        className="dropdown-item"
                                                        color="#000"
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'expiration.ordinals',
                                                                'PM'
                                                            )
                                                        }
                                                    >
                                                        PM
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        className="dropdown-item"
                                                        color="#000"
                                                        onClick={() =>
                                                            setFieldValue(
                                                                'expiration.ordinals',
                                                                'AM'
                                                            )
                                                        }
                                                    >
                                                        AM
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Form>
                        <ModalFooter>
                            <button
                                className="btn btn-link text-danger mr-4"
                                onClick={handleToggle}
                                type="button"
                            >
                                {modal.buttons.cancel_button}
                            </button>
                            <Button color="primary" onClick={handleSubmit}>
                                {!item.task
                                    ? modal.buttons.add_button
                                    : modal.buttons.update_button}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
