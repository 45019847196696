import { SET_USER_MESSAGES } from '../actions/types';

const INITIAL_STATE = {
    appInitialized: false,
    userMessages: [],
    pinnedMessages: [],
    newMessagesCounter: null
};

const separateMessages = (messages, userId) => {
    return messages.filter(el => el.saved && el.saved[userId] === true);
};

const countNewMessages = (messages, userId) => {
    let count = 0;
    messages.forEach(element => {
        if (!(element.seen && element.seen[userId])) count++;
    });
    return count;
};

const messagesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_MESSAGES:
            return {
                ...state,
                appInitialized: true,
                userMessages: action.payload.messages,
                pinnedMessages: separateMessages(
                    action.payload.messages,
                    action.payload.userId
                ),
                newMessagesCounter: countNewMessages(
                    action.payload.messages,
                    action.payload.userId
                )
            };
        default:
            return state;
    }
};

export default messagesReducer;
