import {
    SET_USER_TEAM_SUCCESS,
    SET_USER_TEAM_FAILURE,
    UPDATE_USER_TEAM_NAME,
    CREATE_USER_TEAM,
    DELETE_USER_TEAM,
    LEAVE_USER_TEAM,
    ADD_TEAM_MEMBER,
    REMOVE_TEAM_MEMBER,
    DELETE_TEAM_SUCCESS,
    LEAVE_TEAM_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    team: null,
    error: null,
    loading: false,
    member: null
};

const teamsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_TEAM_SUCCESS:
            return {
                ...state,
                loading: INITIAL_STATE.loading,
                error: INITIAL_STATE.error,
                team: action.payload
            };
        case SET_USER_TEAM_FAILURE:
            return {
                ...state,
                team: INITIAL_STATE.team,
                loading: INITIAL_STATE.loading,
                error: action.payload
            };
        case UPDATE_USER_TEAM_NAME:
            return {
                ...state,
                team: action.payload
            };
        case CREATE_USER_TEAM:
            return {
                ...state,
                team: action.payload
            };
        case DELETE_USER_TEAM:
            return {
                ...state,
                team: action.payload
            };
        case DELETE_TEAM_SUCCESS:
            return {
                ...state,
                team: null
            };
        case LEAVE_USER_TEAM:
            return {
                ...state,
                team: action.payload
            };
        case LEAVE_TEAM_SUCCESS:
            return {
                ...state,
                team: null
            };
        case ADD_TEAM_MEMBER:
            return {
                ...state,
                team: action.payload
            };
        case REMOVE_TEAM_MEMBER:
            return {
                ...state,
                team: action.payload
            };
        default:
            return state;
    }
};

export default teamsReducer;
