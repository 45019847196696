// React
import React, { useState, useEffect } from 'react';

// Components
import { Loader } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ViewController = ({ document }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [url, setUrl] = useState(null);
    const { windowHeight } = useWindowSize();
    const [viewHeight, setViewHeight] = useState(null);
    const { of_label } = useLang()['PdfViewer']['ViewController'];

    useEffect(() => {
        if (windowHeight) {
            setViewHeight(windowHeight * 0.85);
        }
    }, [windowHeight, setViewHeight]);

    useEffect(() => {
        if (document?.url) {
            setUrl(document.url);
        }
        setPageNumber(1);
    }, [document]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePrevious = () => {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNext = () => {
        if (pageNumber !== numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    if (!viewHeight && !url) return null;
    return (
        <div className="col" style={{ height: viewHeight }}>
            <Document
                className="react-pdf__Document"
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
                loading={<Loader />}
            >
                <Page
                    className="react-pdf__Page"
                    height={viewHeight}
                    pageNumber={pageNumber}
                    loading={<Loader />}
                />
                <div className="viewer-button-panel">
                    <button
                        className="btn btn-icon ml-auto panel-button"
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={handlePrevious}
                    >
                        <span className="fas fa-chevron-left" />
                    </button>
                    <span className="panel-text">{`${pageNumber} ${of_label} ${numPages}`}</span>
                    <button
                        className="btn btn-icon ml-auto panel-button"
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={handleNext}
                    >
                        <span className="fas fa-chevron-right" />
                    </button>
                </div>
            </Document>
        </div>
    );
};

export default ViewController;
