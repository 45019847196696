import React, { useState, useEffect } from 'react';
import { Loader } from '../components';
import { useLocation, useHistory } from 'react-router-dom';
import * as routes from '../router/config/routes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validateOrgInvitation } from '../store/actions/Invitations';

const InvitationServices = ({
    authUser,
    validateOrgInvitation,
    userInvitation,
    isValid,
    error
}) => {
    const [inviteData, setInviteData] = useState(null);
    const history = useHistory();
    const location = useLocation();

    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery() || null;

    useEffect(() => {
        if (location && query && !inviteData) {
            setInviteData({
                inviteCode: query.get('inviteCode'),
                inviteId: query.get('inviteId'),
                email: query.get('email'),
                firstName: query.get('firstName'),
                lastName: query.get('lastName'),
                orgId: query.get('orgId'),
                orgName: query.get('orgName'),
                brokeredBy: query.get('brokeredBy')
            });
        }
    }, [inviteData, location, query]);

    useEffect(() => {
        if (inviteData) {
            const { inviteCode, inviteId, orgId, orgName, brokeredBy } = inviteData;
            validateOrgInvitation({ inviteCode, inviteId, orgId, orgName, brokeredBy });
        }
    }, [inviteData, validateOrgInvitation]);

    useEffect(() => {
        if (userInvitation) {
            if (userInvitation.existing) {
                if (authUser) {
                    history.push({
                        pathname: `${routes.AUTHENTICATED}${routes.INVITATION_CONFIRMATION}`
                    });
                } else {
                    history.push({
                        pathname: `${routes.LOGIN}`
                    });
                }
            } else {
                if (authUser || !isValid) {
                    history.push({
                        pathname: `${routes.INVITATION_ERROR}`,
                        state: { isTransactionInvitation: false }
                    });
                } else {
                    if (isValid) {
                        history.push({
                            pathname: `${routes.ACCEPT_NEW_INVITATION}`
                        });
                    } else {
                        history.push({
                            pathname: `${routes.INVITATION_ERROR}`,
                            state: { isTransactionInvitation: false }
                        });
                    }
                }
            }
        }
    }, [userInvitation, authUser, history, isValid]);

    return <Loader />;
};

const mapStateToProps = ({ auth, invitation }) => {
    const { authUser } = auth;
    const { userInvitation, isValid, error } = invitation;
    return {
        authUser,
        userInvitation,
        isValid,
        error
    };
};

export default withRouter(
    connect(mapStateToProps, { validateOrgInvitation })(InvitationServices)
);
