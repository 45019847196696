import {
    GET_DOC_FOR_FILLING,
    SET_DOC_FOR_FILLING,
    SAVE_SETTINGS_FOR_FILLING,
    SET_SETTINGS_FOR_FILLING,
    SAVE_DOC_FOR_FILLING,
    SAVE_SIGNATURE,
    SAVE_SIGNATURE_SUCCESS,
    ACCEPT_SIGNING_CONCENT,
    ACCEPT_SIGNING_CONCENT_SUCCESS,
    REMOVE_SIGNER_FROM_DOCUMENT,
    REMOVE_SIGNER_FROM_DOCUMENT_SUCCESS,
    SEND_SIGNATURE_REQUEST
} from './types';

export const getDocumentForFilling = payload => {
    return {
        type: GET_DOC_FOR_FILLING,
        payload
    };
};

export const setDocumentForFilling = payload => {
    return {
        type: SET_DOC_FOR_FILLING,
        payload
    };
};

export const saveSettingsForFilling = payload => {
    return {
        type: SAVE_SETTINGS_FOR_FILLING,
        payload
    };
};

export const setSettingsForFilling = payload => {
    return {
        type: SET_SETTINGS_FOR_FILLING,
        payload
    };
};

export const saveDocumentForFilling = payload => {
    return {
        type: SAVE_DOC_FOR_FILLING,
        payload
    };
};

export const saveSignature = payload => {
    return {
        type: SAVE_SIGNATURE,
        payload
    };
};

export const saveSignatureSuccess = payload => {
    return {
        type: SAVE_SIGNATURE_SUCCESS,
        payload
    };
};

export const acceptSigningConsent = () => ({
    type: ACCEPT_SIGNING_CONCENT
});

export const acceptConsentSuccess = payload => ({
    type: ACCEPT_SIGNING_CONCENT_SUCCESS,
    payload
});

export const removeSignerFromDocument = payload => ({
    type: REMOVE_SIGNER_FROM_DOCUMENT,
    payload
});

export const removeSignerFromDocumentSuccess = payload => ({
    type: REMOVE_SIGNER_FROM_DOCUMENT_SUCCESS,
    payload
});

export const sendSignatureRequest = payload => ({
    type: SEND_SIGNATURE_REQUEST,
    payload
});
