// React
import React, { useState } from 'react';

// Components
import PasswordModal from './PasswordModal';
import SettingsAddress from '../OrganizationInfo/OfficeAddress';
import ActionButtons from '../shared/ActionButtons';
import PhonesField from '../shared/PhonesField';
import { ConfirmationDialog, TextField } from '../../../components';

// Context
import { useLang } from '../../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import SimpleBar from 'simplebar-react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Redux - Actions, Reducers, Sagas
import { updatePersonalInfo } from '../../../store/actions/Settings';

// Utils
import {
    nameRegExp,
    phoneRegExp,
    confirmationDialogTypes
} from '../../../utils/Constants';

const PersonalInfo = ({ userData, type, updatePersonalInfo }) => {
    const {
        main_title,
        lic_number,
        name_label,
        email_label,
        phone_label,
        about_label,
        placeholder_licence,
        placeholder_first_name,
        placeholder_last_name,
        placeholder_email,
        placeholder_editor,
        modal_title,
        modal_password_placeholder,
        modal_button_cancel,
        modal_button_save
    } = useLang()['Settings']['PersonalInfo'];

    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { errorMessage } = useLang()['Constants'];

    const history = useHistory();
    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const modalToggle = () => setModalOpen(prevState => !prevState);

    const isClient = type === 'client';
    const isBroker = type === 'broker';
    const checkPassword = () => setIsValidPassword(true);
    const handleSetPassword = event => setPassword(event.target.value);
    const submitPassword = event => {
        event.preventDefault();
        // TODO: check valid password with API request
        console.log('password:', password);
        setPassword('');
        checkPassword();
        modalToggle();
    };

    const onEditorStateChange = editorState => setEditorState(editorState);

    const initialValues = {
        id: userData?.id || '',
        firstName: userData?.first_name || '',
        lastName: userData?.last_name || '',
        email: userData?.email || '',
        about: '',
        licNumber: userData?.agent_lic || userData?.broker_lic || '',
        phones: userData?.phone || [],
        shortAddress: userData?.address?.address_1 || '',
        address2: userData?.address?.address_2 || '',
        city: userData?.address?.city || '',
        state: userData?.address?.state || '',
        zip: userData?.address?.zip || ''
    };

    const validationSchema = Yup.object().shape({
        licNumber: !isClient
            ? Yup.string().required(errorMessage.licNumber.required)
            : null,
        firstName: Yup.string()
            .matches(nameRegExp.format, errorMessage.firstName.valid)
            .required(errorMessage.firstName.required),
        lastName: Yup.string()
            .matches(nameRegExp.format, errorMessage.lastName.valid)
            .required(errorMessage.lastName.required),
        email: Yup.string()
            .lowercase()
            .email(errorMessage.email.valid)
            .required(errorMessage.email.required),
        phones: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                    .matches(phoneRegExp.format, errorMessage.phone.valid)
                    .required(errorMessage.phone.required)
            })
        ),
        about: Yup.string()
    });

    return (
        <>
            <ConfirmationDialog modalDialogType={confirmationDialogTypes.success} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    updatePersonalInfo({
                        data: {
                            first_name: values.firstName,
                            last_name: values.lastName,
                            email: values.email,
                            phone: values.phones,
                            licNumber: values.licNumber,
                            address: isClient
                                ? {
                                      address_1: values.shortAddress,
                                      address_2: values.address2,
                                      city: values.city,
                                      state: values.state,
                                      zip: values.zip
                                  }
                                : null,
                            id: values.id,
                            type: userData.type,
                            isBroker: isBroker
                        }
                    });
                    setSubmitting(false);
                }}
                enableReinitialize
            >
                {({
                    errors,
                    values,
                    dirty,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <>
                        <div className="card-header">
                            <h1 className="h5 font-weight-medium mb-0 ml-2">
                                {main_title}
                            </h1>
                            {/* {loading && <p className="ml-2">Updating...</p>} */}
                        </div>
                        <div
                            className="card-body"
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '0'
                            }}
                        >
                            <SimpleBar
                                className="container pr-5"
                                style={{ overflowY: 'auto', height: '100%' }}
                            >
                                <Form
                                    className="d-flex flex-column"
                                    style={{ gap: '25px' }}
                                >
                                    {!isClient && (
                                        <div className="row d-flex align-items-center">
                                            <label className="col-4 fs-5">
                                                {lic_number}
                                            </label>
                                            <div className="col-8">
                                                <TextField
                                                    className="w-100 borderColor rounded form-control position-relative"
                                                    myStyle={{ width: '520px' }}
                                                    required
                                                    name="licNumber"
                                                    type="text"
                                                    placeholder={placeholder_licence}
                                                    value={values.licNumber}
                                                    error={errors.licNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    invalid={!!errors.licNumber && dirty}
                                                    hasDefaultFill
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row d-flex align-items-center">
                                        <label className="col-4 fs-5">{name_label}</label>
                                        <div className="col-8 d-flex">
                                            <TextField
                                                className="borderColor rounded-left form-control"
                                                myStyle={{
                                                    width: '260px',
                                                    borderRadius: 'inherit'
                                                }}
                                                required
                                                name="firstName"
                                                type="text"
                                                placeholder={placeholder_first_name}
                                                value={values.firstName}
                                                error={errors.firstName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                invalid={!!errors.firstName && dirty}
                                                hasDefaultFill
                                            />
                                            <TextField
                                                className="borderColor rounded-right form-control"
                                                myStyle={{
                                                    width: '260px',
                                                    borderRadius: 'inherit'
                                                }}
                                                required
                                                name="lastName"
                                                type="text"
                                                placeholder={placeholder_last_name}
                                                value={values.lastName}
                                                error={errors.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={!!errors.lastName && dirty}
                                                hasDefaultFill
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center">
                                        <label className="col-4">{email_label}</label>
                                        <div className="col-8">
                                            <TextField
                                                className="w-100 borderColor rounded form-control position-relative"
                                                myStyle={{
                                                    width: '520px',
                                                    color: isValidPassword
                                                        ? '#505050'
                                                        : '#77838f'
                                                }}
                                                required
                                                name="email"
                                                type="email"
                                                placeholder={placeholder_email}
                                                value={values.email}
                                                error={errors.email}
                                                onChange={handleChange}
                                                onBlur={e => {
                                                    handleBlur('email');
                                                    setFieldTouched('email', true);
                                                    setFieldValue(
                                                        'email',
                                                        e.target.value
                                                            .toLowerCase()
                                                            .trim()
                                                    );
                                                }}
                                                invalid={!!errors.email && dirty}
                                                disabled={!isValidPassword}
                                                hasDefaultFill
                                            />
                                            <i
                                                className="fa-solid fa-pen-to-square"
                                                style={{
                                                    cursor: 'pointer',
                                                    position: 'absolute',
                                                    top: '16px',
                                                    right: '20px',
                                                    color: 'rgb(140, 152, 164)'
                                                }}
                                                onClick={modalToggle}
                                            />
                                        </div>
                                    </div>

                                    {/* Modal */}
                                    <PasswordModal
                                        handleOpen={modalOpen}
                                        handleToggle={modalToggle}
                                        handleSubmit={submitPassword}
                                        title={modal_title}
                                        password={password}
                                        handleChangePassword={handleSetPassword}
                                        cancelTitle={modal_button_cancel}
                                        saveTitle={modal_button_save}
                                        placeholder={modal_password_placeholder}
                                    />
                                    <div className="row d-flex align-items-center">
                                        <div className="col-4 align-self-start">
                                            {phone_label}
                                        </div>
                                        <div className="col-8">
                                            <FieldArray
                                                name="phones"
                                                render={arrayHelpers => (
                                                    <PhonesField
                                                        form={arrayHelpers.form}
                                                        push={arrayHelpers.push}
                                                        remove={arrayHelpers.remove}
                                                        isClient={isClient}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {isClient ? (
                                        <>
                                            <div className="row d-flex align-items-center">
                                                <label className="col-4">Address</label>
                                                <div className="col-8">
                                                    <SettingsAddress
                                                        values={values}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                        placeholder="Enter address"
                                                        officeAddress={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row d-flex align-items-center">
                                                <label className="col-4">
                                                    Unit, Suite, Apt
                                                </label>
                                                <div className="col-8">
                                                    <TextField
                                                        className="w-100 borderColor rounded form-control"
                                                        required
                                                        name="address2"
                                                        type="text"
                                                        placeholder="Enter unit or suite"
                                                        value={values.address2}
                                                        error={errors.address2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={
                                                            !!errors.address2 && dirty
                                                        }
                                                        hasDefaultFill
                                                    />
                                                </div>
                                            </div>
                                            <div className="row d-flex align-items-center">
                                                <label className="col-4">
                                                    City, State, Zip
                                                </label>
                                                <div
                                                    className="col-8 d-flex"
                                                    style={{ gap: '15px' }}
                                                >
                                                    <TextField
                                                        className="borderColor rounded form-control"
                                                        myStyle={{ flexGrow: '1' }}
                                                        required
                                                        name="city"
                                                        type="text"
                                                        placeholder="Enter city"
                                                        value={values.city}
                                                        error={errors.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors.city && dirty}
                                                        hasDefaultFill
                                                    />
                                                    <TextField
                                                        className="borderColor rounded form-control"
                                                        myStyle={{
                                                            flexBasis: '60px',
                                                            flexGrow: '0'
                                                        }}
                                                        required
                                                        name="state"
                                                        type="text"
                                                        placeholder="Enter state"
                                                        value={values.state}
                                                        error={errors.state}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors.state && dirty}
                                                        hasDefaultFill
                                                    />
                                                    <TextField
                                                        className="borderColor rounded form-control"
                                                        myStyle={{ flexGrow: '1' }}
                                                        required
                                                        name="zip"
                                                        type="text"
                                                        placeholder="Enter unit or suite"
                                                        value={values.zip}
                                                        error={errors.zip}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors.zip && dirty}
                                                        hasDefaultFill
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="row d-flex align-items-start">
                                            <label className="col-4">{about_label}</label>
                                            <div className="col-8">
                                                <Editor
                                                    editorState={editorState}
                                                    onEditorStateChange={
                                                        onEditorStateChange
                                                    }
                                                    placeholder={placeholder_editor}
                                                    toolbarClassName="m-0"
                                                    editorClassName="borderColor editorSize"
                                                    onChange={() =>
                                                        setFieldValue(
                                                            'about',
                                                            draftToHtml(
                                                                convertToRaw(
                                                                    editorState.getCurrentContent()
                                                                )
                                                            )
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        setFieldTouched('about', true)
                                                    }
                                                    toolbar={{
                                                        options: [
                                                            'inline',
                                                            'list',
                                                            'link'
                                                        ],
                                                        inline: {
                                                            options: [
                                                                'bold',
                                                                'italic',
                                                                'underline'
                                                            ]
                                                        },
                                                        list: {
                                                            options: ['unordered']
                                                        },
                                                        link: {
                                                            options: ['link']
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </SimpleBar>
                        </div>
                        <ActionButtons
                            handleClose={() => history.goBack()}
                            handleSubmit={handleSubmit}
                            saveLabel={save_label}
                            cancelLabel={cancel_label}
                            disabled={isSubmitting}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};

const mapStateToProps = ({ user, settings }) => {
    const { userData, type } = user;
    const { loading } = settings;
    return { userData, type, loading };
};

export default connect(mapStateToProps, { updatePersonalInfo })(PersonalInfo);
