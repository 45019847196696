// React
import React from 'react';

// Components
import { AppHeader } from '../components';

// Pages
import {
    Connections,
    Transactions,
    Pipeline,
    Organization,
    AllInclusiveDocument
} from '../pages';

// Packages
import { withRouter } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';

// Router
import * as routes from '../router/config/routes';

const Dashboard = props => {
    return (
        <>
            <AppHeader {...props} />
            <main id="content" role="main" className="push-main-lg bg-light">
                <Switch>
                    <Redirect
                        exact
                        from={`${props.match.url}`}
                        to={`${props.match.url}${routes.TRANSACTIONS}`}
                    />
                    <Route
                        path={`${props.match.url}${routes.CONNECTIONS}`}
                        render={props => <Connections {...props} />}
                    />
                    <Route
                        path={`${props.match.url}${routes.PIPELINE}`}
                        render={props => <Pipeline {...props} />}
                    />
                    <Route
                        path={`${props.match.url}${routes.DOCUMENTS}`}
                        render={props => <AllInclusiveDocument {...props} />}
                    />
                    {props?.authorized && (
                        <Route
                            exact
                            path={`${props.match.url}${routes.ORGANIZATION}`}
                            render={props => <Organization {...props} />}
                        />
                    )}
                    <Route
                        exact
                        path={`${props.match.url}${routes.TRANSACTIONS}`}
                        render={props => <Transactions {...props} />}
                    />
                    <Redirect to={routes.NOT_FOUND} />
                </Switch>
            </main>
        </>
    );
};

export default withRouter(Dashboard);
