// React
import React, { useState, useEffect } from 'react';

// Components
import { Collapse, Button } from 'reactstrap';
import { Loader } from '../../components';

// Config
import { timeStampNow } from '../../config/Firebase';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import { updateTransactionNotes } from '../../store/actions/Transactions';

// Router
import { withRouter } from 'react-router-dom';

const NoteDrop = ({
    setDropState,
    dropState,
    notes,
    trxId,
    updateTransactionNotes,
    userData,
    loading
}) => {
    const [noteTitle, setNoteTitle] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [notePrivacy, setNotePrivacy] = useState(false);
    const [noteSubmission, setNoteSubmission] = useState(false);
    const {
        note_title_placeholder,
        note_content_placeholder,
        private_label,
        add_note_button_label
    } = useLang()['TransactionDetails']['NoteDrop'];

    const handleNoteAddition = () => {
        const noteArr = notes && notes.length ? [...notes] : [];
        const title = noteTitle.trim();
        const content = noteContent.trim();
        noteArr.push({
            content: content,
            created_at: timeStampNow(),
            creator_id: userData.id,
            edited_at: null,
            private: notePrivacy,
            title: title
        });
        const noteData = {
            notes: noteArr,
            trxId,
            loading: true
        };
        updateTransactionNotes(noteData);
    };

    const validateAddition = () => {
        const title = noteTitle.trim();
        const content = noteContent.trim();
        const valid = title.length !== 0 && content.length !== 0;
        return !valid;
    };

    useEffect(() => {
        if (loading) {
            setNoteSubmission(true);
        } else {
            if (noteSubmission) {
                setNoteSubmission(false);
                setDropState(false);
                setNoteTitle('');
                setNoteContent('');
                setNotePrivacy(false);
            }
        }
    }, [loading, noteSubmission, setDropState]);

    return (
        <Collapse className="bg-white" isOpen={dropState}>
            <form
                id="newNote"
                className="input-group-borderless"
                style={{
                    borderLeft: '1px solid #e7eaf3',
                    borderRight: '1px solid #e7eaf3',
                    borderBottom: '1px solid #e7eaf3'
                }}
            >
                <input
                    type="text"
                    className="form-control"
                    placeholder={note_title_placeholder}
                    aria-label={note_title_placeholder}
                    value={noteTitle}
                    onChange={e => setNoteTitle(e.target.value)}
                />
                <textarea
                    rows="2"
                    className="form-control"
                    placeholder={note_content_placeholder}
                    aria-label={note_content_placeholder}
                    style={{ resize: 'none' }}
                    value={noteContent}
                    onChange={e => setNoteContent(e.target.value)}
                />
                {loading && <Loader />}
                <div
                    className="row  ml-3 mt-3 pb-3"
                    style={{ justifyContent: 'space-between', width: '95%' }}
                >
                    <div className="custom-control custom-switch">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            checked={notePrivacy}
                            onChange={e => setNotePrivacy(e.target.checked)}
                        />
                        <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                            style={{ lineHeight: '30px' }}
                        >
                            {private_label}
                        </label>
                    </div>
                    <Button
                        color="primary"
                        className="addDoc btn btn-xs btn-primary transition-3d-hover"
                        disabled={validateAddition()}
                        onClick={handleNoteAddition}
                    >
                        <span className="fas fa-pen mr-1" />
                        {add_note_button_label}
                    </Button>
                </div>
            </form>
        </Collapse>
    );
};

const mapStateToProps = ({ user, transactions }) => {
    const { userData } = user;
    const { loading } = transactions;
    return { userData, loading };
};

export default withRouter(connect(mapStateToProps, { updateTransactionNotes })(NoteDrop));
