// React
import React, { useMemo } from 'react';

// Packages
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { setDocumentActiveTab } from '../store/actions/Documents';

// Context
import { useLang } from '../context/LangContext';

// Utils
import { documentsTabs, userTypes } from '../utils/Constants';

// Components
import Documents from './Documents';

const AllInclusiveDocument = ({
    activeTab,
    docRoute,
    packRoute,
    setDocumentActiveTab,
    type,
    ...props
}) => {
    const { tabName, document_button_label } = useLang()['Documents']['Tabs'];

    const isBroker = useMemo(
        () => [userTypes.broker, userTypes.owner, userTypes.admin].includes(type),

        [type]
    );

    const menuScheme = [
        { id: documentsTabs.formsLibrary, name: tabName?.library },
        { id: documentsTabs.myDocuments, name: tabName?.my_doc },
        { id: documentsTabs.formsMarketplace, name: tabName?.forms_marketplace }
    ];

    const handleClick = e => {
        setDocumentActiveTab(e.target.id);
    };

    return (
        <>
            <div className="col-sm-10 mx-lg-auto mt-4 ">
                <div
                    className="card "
                    style={{
                        height: '100%'
                    }}
                >
                    <Nav tabs className="nav nav-tabs nav-fill nav-classic">
                        {menuScheme.map(tab => (
                            <NavItem
                                className="pointer mx-6"
                                key={tab.id}
                                style={{ textAlign: 'left' }}
                            >
                                <NavLink
                                    id={tab.id}
                                    className={activeTab === tab.id ? 'active' : ''}
                                    onClick={handleClick}
                                    style={{
                                        borderLeftColor: 'transparent',
                                        borderRightColor: 'transparent',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <Documents {...props} activeTab={activeTab} packRoute={packRoute} />
                    {(activeTab === documentsTabs.myDocuments || isBroker) && (
                        <div className="card-footer d-flex justify-content-end">
                            <div className="d-flex justify-content-end">
                                <div className="actionsContainer">
                                    <Link className="btn btn-primary mr-2" to={docRoute}>
                                        {document_button_label}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ documents, user }) => {
    const { activeTab, docRoute, packRoute } = documents;
    const { type } = user;
    return { activeTab, docRoute, packRoute, type };
};

export default connect(mapStateToProps, {
    setDocumentActiveTab
})(AllInclusiveDocument);
