// React
import React, { useState, useRef } from 'react';

// Components
import { TextField, AddressSuggestion } from '../../../components';
import PhonesField from '../shared/PhonesField';
import ActionButtons from '../shared/ActionButtons';

// Context
import { useLang } from '../../../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { UncontrolledTooltip } from 'reactstrap';
import SimpleBar from 'simplebar-react';

import { updateOrgLocation } from '../../../store/actions/Org';

// Router
import * as routes from '../../../router/config/routes';

import { phoneRegExp } from '../../../utils/Constants';

const Office = ({
    updateOrgLocation,
    handleOfficeChange,
    organization,
    location,
    officeLang,
    orgs
}) => {
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const { errorMessage } = useLang()['Constants'];
    const { labels, placeholders } = officeLang || {};
    const [logo, setLogo] = useState('');
    const history = useHistory();
    const initialValues = {
        officeLogo: organization?.logo_url || '',
        officeName: location?.office_name || '',
        officeType: location?.office_type || '',
        phones: location?.phone || [],
        address: `${location?.address_1}` || '',
        address1: location?.address_1 || '',
        address2: location?.address_2 || '',
        city: location?.city || '',
        state: location?.state || '',
        zip: location?.zip || '',
        lat: location?.lat || '',
        lon: location?.lon || ''
    };

    const validationSchema = Yup.object().shape({
        officeLogo: Yup.string(),
        officeName: Yup.string(),
        officeMLS: Yup.string(),
        phones: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                    .matches(phoneRegExp.format, errorMessage.phone.valid)
                    .required(errorMessage.phone.required)
            })
        ),
        lat: Yup.string(),
        lon: Yup.string(),
        address: Yup.string().required()
    });

    const formRef = useRef();

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={values => {
                    updateOrgLocation({
                        data: {
                            address_1: values.address1,
                            address_2: values.address2,
                            city: values.city,
                            state: values.state,
                            zip: values.zip,
                            lat: values.lat,
                            lon: values.lon,
                            office_name: values.officeName,
                            phone: values.phones,
                            office_type: values.officeType,
                            id: location.id
                        },
                        orgId: organization.id.trim(),
                        locationId: location.id
                    });
                }}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    dirty,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched
                }) => (
                    <>
                        <div
                            className="card-body"
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '0'
                            }}
                        >
                            <SimpleBar
                                className="container pr-5"
                                style={{ overflowY: 'auto', height: '100%' }}
                            >
                                <Form
                                    className="d-flex flex-column org-info"
                                    style={{ gap: '25px' }}
                                >
                                    <div className="row d-flex align-items-center">
                                        <div className="col-4 d-flex align-items-center">
                                            {officeLang?.labels?.officeLogo}
                                            <i
                                                style={{
                                                    color: 'rgb(140, 152, 164)',
                                                    marginLeft: '10px'
                                                }}
                                                className="far fa-info-circle"
                                                id="infoIcon"
                                            />
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="infoIcon"
                                            >
                                                {officeLang?.labels?.officeLogo_tooltip}
                                            </UncontrolledTooltip>
                                        </div>
                                        {values.officeLogo ? (
                                            <div className="col-3">
                                                <img
                                                    className="settings-upload__logo"
                                                    src={values.officeLogo}
                                                    alt="Office logo"
                                                />
                                            </div>
                                        ) : (
                                            logo && (
                                                <div className="col-3">
                                                    <img
                                                        className="settings-upload__logo"
                                                        src={logo}
                                                        alt="Office logo"
                                                    />
                                                </div>
                                            )
                                        )}
                                        <div className="col-5 d-flex align-items-center settings-upload">
                                            <label className="mb-0 settings-upload__label">
                                                {labels?.uploadPhoto}
                                                <TextField
                                                    name="officeLogo"
                                                    className="form-control w-100"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={e => {
                                                        if (e.target.files) {
                                                            if (values.officeLogo)
                                                                setFieldValue(
                                                                    'officeLogo',
                                                                    ''
                                                                );

                                                            setLogo(
                                                                URL.createObjectURL(
                                                                    e.target.files[0]
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            </label>
                                            <button
                                                className="ml-2"
                                                type="button"
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    color: '#de4437'
                                                }}
                                                onClick={() => {
                                                    if (values.officeLogo)
                                                        setFieldValue('officeLogo', '');
                                                    setLogo('');
                                                }}
                                            >
                                                {labels?.remove}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-4">{labels?.officeName}</div>
                                        <div className="col-8">
                                            <TextField
                                                name="officeName"
                                                className="form-control w-100"
                                                type="text"
                                                placeholder={placeholders?.enterName}
                                                error={errors.officeName}
                                                value={values.officeName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                invalid={!!errors.officeName && dirty}
                                                hasDefaultFill
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-4 align-self-start">
                                            {labels?.officePhone}
                                        </div>
                                        <div className="col-8">
                                            <FieldArray
                                                name="phones"
                                                component={PhonesField}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center">
                                        <div className="col-4 align-self-start">
                                            {labels?.officeAddress}
                                        </div>
                                        <div className="col-8">
                                            <AddressSuggestion
                                                value={values.address}
                                                fieldName="address"
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                handleChange={handleChange}
                                                placeholder={placeholders?.enterAddress}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </SimpleBar>
                        </div>
                        <ActionButtons
                            handleClose={() =>
                                orgs.length > 1
                                    ? handleOfficeChange(null, null)
                                    : history.push(
                                          `${routes.AUTHENTICATED}${routes.DASHBOARD}`
                                      )
                            }
                            handleSubmit={handleSubmit}
                            saveLabel={save_label}
                            cancelLabel={cancel_label}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};

export default connect(null, { updateOrgLocation })(Office);
