// React
import React, { useCallback, useEffect } from 'react';

// Components
import { TextField, SelectField, AvatarEditModal } from '../../components';

// Packages
import { Label } from 'reactstrap';

import { useDropzone } from 'react-dropzone';

const AcceptStep2 = ({
    setStep,
    lang,
    skip,
    setSkip,
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    isSubmitting,
    source,
    setSource,
    avatarModal,
    setAvatarModal,
    avatar,
    setAvatar,
    activeUpload,
    setActiveUpload,
    pristine,
    setPristine,
    uploadError,
    setUploadError,
    maxSize
}) => {
    const toggleModal = useCallback(
        ({ reset, cancel }) => {
            if (reset || cancel) {
                setActiveUpload(false);
                setAvatarModal(upload => !upload);
            } else {
                setAvatarModal(upload => !upload);
            }
        },
        [setActiveUpload, setAvatarModal]
    );

    useEffect(() => {
        if (activeUpload) {
            if (!uploadError && !pristine) {
                toggleModal({ reset: true, cancel: false });
            }
        } else {
            setPristine(true);
        }
    }, [activeUpload, pristine, setPristine, toggleModal, uploadError]);

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length) {
                const url = URL.createObjectURL(acceptedFiles[0]);
                setSource(url);
                setActiveUpload(true);
                toggleModal({ reset: false, cancel: false });
            }
        },
        [setSource, setActiveUpload, toggleModal]
    );

    const uploadAvatar = (canvas, crop, setFieldValue) => {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            blob => {
                const url = URL.createObjectURL(blob);
                setFieldValue('avatar', url);
                setAvatar(url);
                setPristine(false);
            },
            'image/jpeg',
            0.25
        );
    };

    const { isDragActive, getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg',
        minSize: 0,
        maxSize
    });

    const isFileTooLarge =
        fileRejections?.length > 0 && fileRejections[0].file.size > maxSize;

    return (
        <div
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <AvatarEditModal
                source={source}
                setSource={setSource}
                avatarModal={avatarModal}
                toggleModal={toggleModal}
                uploadAvatar={uploadAvatar}
                setFieldValue={setFieldValue}
                newUser={true}
                isDragActive={isDragActive}
            />
            <div className="row d-flex justify-content-center">
                <div
                    className="col-2 js-form-message form-group d-flex flex-column align-items-center"
                    {...getRootProps()}
                >
                    <Label className="form-label">{lang?.profile_picture_label}</Label>
                    <input {...getInputProps()} disabled={skip} />

                    <label
                        htmlFor="upload"
                        className="upload-picture__container d-flex justify-content-center align-items-center"
                        style={{ opacity: isDragActive ? 0.7 : 1 }}
                    >
                        {avatar ? (
                            <img
                                src={avatar}
                                alt="Uploaded Profile"
                                className="upload-picture"
                            />
                        ) : (
                            <span className="upload-picture__icon">
                                <i className="fa-solid fa-user-astronaut fa-4x pb-3" />
                            </span>
                        )}
                    </label>
                    <div className="upload-picture__label d-flex position-relative justify-content-center align-items-center">
                        <span>{lang?.upload_label}</span>
                    </div>
                    {isFileTooLarge && (
                        <div className="text-center text-danger text-xsmall">
                            {lang?.file_size_warning}
                        </div>
                    )}
                </div>
                <div className="col-6 js-form-message form-group">
                    <Label className="form-label">{lang?.about_label}</Label>
                    <TextField
                        autoComplete="off"
                        type="textarea"
                        myStyle={{
                            height: '100px'
                        }}
                        className="form-control"
                        name="about"
                        id="about"
                        placeholder={lang?.about_placeholder}
                        value={values.about}
                        onChange={handleChange('about')}
                        onBlur={handleBlur('about')}
                        error={errors.about}
                        disabled={skip || isSubmitting}
                        invalid={touched.about && !!errors.about}
                    />
                    <p className="small mt-1">{lang?.about_helper_text}</p>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label" for="type">
                        {lang?.mls_label}
                    </Label>
                    <SelectField
                        required={values.mlsId !== ''}
                        formik={true}
                        type="select"
                        className="form-control p-0 border-0"
                        classNamePrefix={`react-select ${
                            !!errors.mls && touched.mls
                                ? 'danger'
                                : values.mls !== '' && !errors.mls
                                ? 'success'
                                : 'pristine'
                        }`}
                        placeholder={lang?.mls_placeholder}
                        name="mls"
                        id="mls"
                        options={[
                            {
                                label: 'NABOR - Naples Area Board of REALTORS',
                                value: 'NABOR - Naples Area Board of REALTORS',
                                name: 'Naples Area Board of REALTORS',
                                alias: 'NABOR',
                                id: 'hUOHXjNqJ6GZLckFvbSw'
                            }
                        ]}
                        value={values.mls}
                        touched={touched.mls}
                        isClearable={true}
                        disabled={skip || isSubmitting}
                        onBlur={() => {
                            handleBlur(`mls`);
                            setFieldTouched(`mls`, true);
                        }}
                        onChange={option => {
                            handleChange(`mls`);
                            setFieldValue(`mls`, option);
                        }}
                        error={errors.mls}
                        invalid={!!errors.mls && touched.mls}
                    />
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label">{lang?.lic_number_label}</Label>
                    <TextField
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="licNumber"
                        id="licNumber"
                        placeholder={lang?.lic_number_placeholder}
                        value={values.licNumber}
                        disabled={skip || isSubmitting}
                        onChange={handleChange('licNumber')}
                        onBlur={handleBlur('licNumber')}
                        error={errors.licNumber}
                        invalid={touched.licNumber && !!errors.licNumber}
                    />
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-8 js-form-message form-group">
                    <div className="col-6 pl-0">
                        {values.mls && values.mls !== '' && !skip && (
                            <>
                                <Label className="form-label">{lang?.mls_id_label}</Label>
                                <TextField
                                    autoComplete="off"
                                    type="text"
                                    className="form-control"
                                    name="mlsId"
                                    id="mlsId"
                                    placeholder={lang?.mls_id_placeholder}
                                    value={values.mlsId}
                                    disabled={skip || isSubmitting}
                                    onChange={handleChange('mlsId')}
                                    onBlur={handleBlur('mlsId')}
                                    error={errors.mlsId}
                                    invalid={touched.mlsId && !!errors.mlsId}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcceptStep2;
