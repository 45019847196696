// React
import React from 'react';

const TabNavigation = ({ onClick, selectedTab, tabs, tabClassName, navClassName }) => {
    return (
        <ul
            className={
                `nav nav-classic rounded-top border-bottom nav-justified bg-white ` +
                navClassName
            }
            role="tablist"
        >
            {tabs.map(tab => (
                <li
                    className="nav-item"
                    key={tab.id}
                    onClick={() => onClick(tab.value)}
                    style={{ cursor: 'pointer' }}
                >
                    <div
                        className={
                            selectedTab === tab.value
                                ? 'nav-link font-weight-medium active'
                                : 'nav-link font-weight-medium'
                        }
                        id={tab.id}
                        data-toggle={tab.dataToggle}
                        role={tab.role}
                        aria-controls={tab.ariaControls}
                        aria-selected={selectedTab === tab.value ? 'true' : 'false'}
                    >
                        <div
                            className={
                                `d-md-flex justify-content-md-center align-items-md-center ` +
                                tabClassName
                            }
                        >
                            {tab.name}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default TabNavigation;
