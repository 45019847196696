import React from 'react';

const AcceptFooter = ({
    handleSkip,
    handleSubmit,
    handleBack,
    handleDecline,
    submitLabel,
    nextLabel,
    skipLabel,
    declineLabel,
    backLabel,
    disabled,
    step,
    loading
}) => {
    return (
        <div className="card-footer d-flex justify-content-between align-items-center">
            <div className="small offset-2 ml-2">
                {step === 'second' ? (
                    <button
                        className="btn btn-primary mr-2"
                        onClick={handleBack}
                        type="button"
                        disabled={disabled}
                    >
                        {backLabel}
                    </button>
                ) : (
                    <button
                        className="btn btn-link text-danger mr-2"
                        onClick={handleDecline}
                        type="button"
                        disabled={disabled}
                    >
                        {declineLabel}
                    </button>
                )}
            </div>
            <div className="actionsContainer">
                {step === 'second' && (
                    <button
                        className="btn btn-link text-danger mr-4"
                        onClick={handleSkip}
                        type="button"
                        disabled={disabled}
                    >
                        {skipLabel}
                    </button>
                )}
                <button
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={disabled}
                >
                    {loading ? (
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : step === 'first' ? (
                        nextLabel
                    ) : (
                        submitLabel
                    )}
                </button>
            </div>
        </div>
    );
};

export default AcceptFooter;
