// React
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import cx from 'classnames';

import {
    sendUserMessage,
    sendUserThreadMessage,
    draftUserMessage,
    saveUserMessage,
    removeUserMessage,
    seenUserMessage
} from '../../store/actions/Messages';

import MultiSelect, { Option, MultiValue } from '../common/MultiSelect';
import { useLang } from '../../context/LangContext';
import { formatMessagesDate } from '../../utils/Helpers';
import { userTypes } from './../../utils/Constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';

const MessageModal = ({
    draftUserMessage,
    sendUserThreadMessage,
    sendUserMessage,
    saveUserMessage,
    removeUserMessage,
    seenUserMessage,
    content,
    isMessageOpen,
    toggleIsMessageOpen,
    companyContacts,
    userData,
    type,
    isDraftTab,
    isSavedTab,
    setActiveMessageId
}) => {
    const {
        placeholder_to,
        placeholder_msg,
        placeholder_new_msg,
        btn_draft,
        btn_send,
        btn_save,
        btn_delete,
        new_msg_title,
        new_draft_msg_title,
        btn_reply,
        no_reply_label,
        placeholder_reply,
        select_all_members
    } = useLang()['Messages']['MessageModal'];

    const { members, thread, id, messageTitle: dbMessageTitle } = content || {};
    const [messageField, setMessageField] = useState('');
    const [replyMessageField, setReplyMessageField] = useState('');
    const [reply, setReply] = useState(true);
    const [contactSelect, setContactSelect] = useState([]);
    const messagesEndRef = useRef(null);
    const textInput = useRef(null);
    const [hiddenBadges, setHiddenBadges] = useState(true);
    const userType = [userTypes.broker, userTypes.owner].includes(type);
    const [messageTitle, setMessageTitle] = useState('');

    const posts = useMemo(
        () => content?.posts && Object.entries(content.posts),
        [content]
    );

    useEffect(() => {
        if (isMessageOpen) {
            if (isDraftTab) {
                const memberIds = Object.keys(content.members);
                setContactSelect([
                    ...companyContacts
                        .filter(el => el.id !== userData.id && memberIds.includes(el.id))
                        .map(item => ({
                            value: item.id,
                            label: `${item.first_name} ${item.last_name}`
                        }))
                ]);
                setMessageField(Object.values(content.posts)[0].content);
                setReply(content.thread);
            } else if (content) {
                seenUserMessage({ messageId: id });
            }

            setMessageTitle(content ? dbMessageTitle : '');
        }
    }, [
        isMessageOpen,
        companyContacts,
        content,
        isDraftTab,
        userData.id,
        id,
        dbMessageTitle,
        seenUserMessage
    ]);

    const handleModalClose = () => {
        if (!isDraftTab && content) {
            seenUserMessage({ messageId: id });
        }
        setMessageField('');
        setContactSelect([]);
        setReply(true);
        setMessageTitle('');
        setActiveMessageId(null);
        setReplyMessageField('');
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [posts]);

    const membersList = useMemo(
        () => [
            ...companyContacts
                .filter(el => el.id !== userData.id)
                .map(item => ({
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`
                }))
        ],
        [companyContacts, userData.id]
    );

    const handleHiddenBadges = () => setHiddenBadges(prevState => !prevState);

    const handleSelectMember = option => {
        setContactSelect(option);
    };

    const handleCheckReply = () => {
        setReply(prevState => !prevState);
    };

    const handleReply = () => {
        sendUserThreadMessage({
            messageId: id,
            messageThreadText: replyMessageField
        });
        setReplyMessageField('');
    };

    const handleDelete = () => {
        removeUserMessage({ messageIds: [id] });
        toggleIsMessageOpen();
    };

    const handleSave = () => {
        saveUserMessage({ messageIds: [id] });
        toggleIsMessageOpen();
    };

    const handleDraft = () => {
        draftUserMessage({
            messageId: isDraftTab ? id : undefined,
            messageTitle: messageTitle === '' ? new_draft_msg_title : messageTitle,
            isReply: reply,
            members: contactSelect.map(item => item.value),
            messageText: messageField
        });
        toggleIsMessageOpen();
    };

    const handleSend = () => {
        sendUserMessage({
            isDraftTab,
            id: isDraftTab ? id : undefined,
            messageTitle: messageTitle === new_msg_title ? '' : messageTitle,
            isReply: reply,
            members: contactSelect.map(item => item.value),
            messageText: messageField
        });
        toggleIsMessageOpen();
    };

    const closeBtn = (
        <span onClick={toggleIsMessageOpen} type="button" className="fas fa-times" />
    );

    const deleteBtn = (
        <span
            onClick={toggleIsMessageOpen}
            type="button"
            className="fas fa-trash-alt"
            style={{ color: '#e70000' }}
        />
    );

    return (
        <Modal
            onClosed={handleModalClose}
            onOpened={() => textInput.current?.focus()}
            isOpen={isMessageOpen}
            toggle={toggleIsMessageOpen}
            className="add-task-modal"
            backdrop="static"
        >
            <ModalHeader close={content ? closeBtn : deleteBtn}>
                <div className="d-flex align-items-center ml-3">
                    {!(content && posts && !isDraftTab) && (
                        <i className="fa-regular fa-pen-to-square edit-icon" />
                    )}

                    <input
                        className="team-name h5 mb-0"
                        type="text"
                        name="name"
                        placeholder={placeholder_new_msg}
                        ref={textInput}
                        value={messageTitle}
                        onChange={e => setMessageTitle(e.target.value)}
                        autoComplete="off"
                        disabled={content && posts && !isDraftTab}
                        style={{
                            cursor: content && posts ? 'default' : 'pointer'
                        }}
                    />
                </div>
            </ModalHeader>
            <ModalBody>
                {!content || isDraftTab ? (
                    <>
                        <div className="mt-3 col-12 align-items-center">
                            <MultiSelect
                                options={membersList}
                                isMulti
                                editable={true}
                                disabled={false}
                                styles="messages"
                                placeholder={placeholder_to}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                    MultiValue
                                }}
                                onChange={option => handleSelectMember(option || [])}
                                allowSelectAll={true}
                                allOption={
                                    type !== userTypes.member
                                        ? {
                                              value: 'allMembers',
                                              label: select_all_members
                                          }
                                        : undefined
                                }
                                defaultValue={contactSelect}
                                value={contactSelect}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <textarea
                                rows="11"
                                className="form-control"
                                placeholder={placeholder_msg}
                                value={messageField}
                                onChange={e => setMessageField(e.target.value)}
                            />
                        </div>
                    </>
                ) : !thread && posts ? (
                    <div className="container p-3" style={{ minHeight: '330px' }}>
                        {posts[0][1].content}
                    </div>
                ) : (
                    <>
                        {Object.keys(members).length > 1 && (
                            <div
                                onClick={() => handleHiddenBadges()}
                                className={cx(
                                    'mt-3 col-12 border rounded align-items-center pt-1',
                                    { ellipsis: hiddenBadges }
                                )}
                            >
                                <h5>
                                    {Object.keys(members).map(item => {
                                        const member = membersList.find(
                                            el => el.value === item
                                        );
                                        return (
                                            member && (
                                                <span
                                                    key={item}
                                                    className="badge badge-secondary mr-2"
                                                >
                                                    {member.label}
                                                </span>
                                            )
                                        );
                                    })}
                                </h5>
                            </div>
                        )}

                        <Card className="col-12 pt-3 mt-3 border rounded">
                            <SimpleBar style={{ height: '230px' }}>
                                {posts &&
                                    posts.map(([id, item]) => {
                                        const creatorName = item.creator_name.split(' ');
                                        return (
                                            <div key={id} className="col px-3">
                                                <div className="row align-items-center">
                                                    <h2 className="h6 mb-0">
                                                        {creatorName}
                                                    </h2>
                                                    <small className="d-block text-muted ml-2">
                                                        {formatMessagesDate(
                                                            item.created_at.seconds
                                                        )}
                                                    </small>
                                                </div>
                                                <div className="text-lh-sm">
                                                    {item.content}
                                                </div>
                                                <hr />
                                            </div>
                                        );
                                    })}
                                <div ref={messagesEndRef} />
                            </SimpleBar>
                        </Card>
                        {Object.keys(members).length > 1 && (
                            <div className="mt-3">
                                <textarea
                                    rows="2"
                                    autoFocus={true}
                                    className="form-control "
                                    placeholder={placeholder_reply}
                                    value={replyMessageField}
                                    onChange={e => setReplyMessageField(e.target.value)}
                                />
                            </div>
                        )}
                    </>
                )}
            </ModalBody>
            {!content || isDraftTab ? (
                <ModalFooter
                    className={
                        userType
                            ? 'col-12 mb-0 mt-1 justify-content-between'
                            : 'col-12 mb-0 mt-1'
                    }
                >
                    {userType && (
                        <div className="ml-3 justify-content-between">
                            <input
                                type="checkbox"
                                id="allow-reply"
                                checked={!reply}
                                onChange={handleCheckReply}
                            />
                            <label htmlFor="allow-reply" className="ml-2">
                                {no_reply_label}
                            </label>
                        </div>
                    )}
                    <div className="justify-content-between">
                        <Button
                            className="mr-3 px-2 py-2"
                            color="secondary"
                            onClick={handleDraft}
                            disabled={!messageField}
                        >
                            {btn_draft}
                        </Button>
                        <Button
                            className="mr-3 px-4 py-2"
                            disabled={
                                !(messageField && contactSelect.length && messageTitle)
                            }
                            color="primary"
                            onClick={handleSend}
                        >
                            {btn_send}
                        </Button>
                    </div>
                </ModalFooter>
            ) : !thread && !isSavedTab ? (
                <ModalFooter className="col-12 mb-0 mt-1 justify-content-end">
                    <div>
                        <Button className="mr-3 px-4 py-2" onClick={handleDelete}>
                            {btn_delete}
                        </Button>
                        <Button
                            className="mr-3 px-4 py-2"
                            color="primary"
                            onClick={handleSave}
                        >
                            {btn_save}
                        </Button>
                    </div>
                </ModalFooter>
            ) : (
                <ModalFooter className="col-12 mb-0 mt-1 justify-content-between">
                    <Button
                        className="mr-3 px-4 py-2 text-danger"
                        color="none"
                        onClick={handleDelete}
                    >
                        {btn_delete}
                    </Button>
                    <div>
                        {isSavedTab && (
                            <FontAwesomeIcon
                                icon={faThumbtack}
                                style={{
                                    color: '#0d7fdb',
                                    fontSize: '1.3rem',
                                    marginRight: '15px'
                                }}
                            />
                        )}
                        {Object.keys(members).length > 1 && (
                            <Button
                                disabled={!replyMessageField}
                                className="mr-2 px-4 py-1"
                                color="primary"
                                onClick={handleReply}
                            >
                                {btn_reply}
                            </Button>
                        )}
                    </div>
                </ModalFooter>
            )}
        </Modal>
    );
};
const mapStateToProps = ({ contacts, user }) => {
    const { companyContacts } = contacts;
    const { userData, type } = user;

    return { companyContacts, userData, type };
};
export default connect(mapStateToProps, {
    sendUserMessage,
    sendUserThreadMessage,
    draftUserMessage,
    saveUserMessage,
    removeUserMessage,
    seenUserMessage
})(MessageModal);
