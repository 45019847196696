// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import AgentCardSkeleton from './AgentCardSkeleton';

// Firebase
import { storage } from '../../config/Firebase';

// Lang
import { useLang } from '../../context/LangContext';

// Packages
import NumberFormat from 'react-number-format';

// Router
import { useHistory } from 'react-router-dom';
import * as routes from '../../router/config/routes';

const AgentCard = ({ agent, user }) => {
    const { transactions_button_label } = useLang()['Organization'];
    const [avatar, setAvatar] = useState();
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [usePlaceHolder, setUsePlaceHolder] = useState(false);
    const history = useHistory();

    const getAvatar = useCallback(id => {
        const ref = storage.ref().child(`users/${id}/avatar.jpg`);
        ref.getDownloadURL()
            .then(imgUrl => {
                setAvatar(imgUrl);
            })
            .catch(error => {
                setShowSkeleton(skeleton => !skeleton);
                setAvatar(null);
            });
    }, []);

    const handleTransactionsRequest = data => {
        const { id, first_name, last_name } = data;
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.MEMBER_TRANSACTIONS}/${id}`,
            state: { id, first_name, last_name }
        });
    };

    const handleLoad = () => {
        setShowSkeleton(!showSkeleton);
    };

    const handleError = () => {
        setShowSkeleton(!showSkeleton);
        setUsePlaceHolder(!usePlaceHolder);
    };

    useEffect(() => {
        if (agent.id && !avatar) {
            getAvatar(agent.id);
        } else if (!agent.id) {
            setShowSkeleton(skeleton => !skeleton);
        }
    }, [agent, avatar, getAvatar]);

    return (
        <>
            <div className="agent-card">
                {showSkeleton && <AgentCardSkeleton />}
                <div
                    className="agent-card__image"
                    style={{ opacity: showSkeleton ? 0 : 1 }}
                >
                    <img
                        src={avatar}
                        alt="Agent avatar"
                        onLoad={handleLoad}
                        onError={handleError}
                        style={{
                            width: '180px',
                            height: '180px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)'
                        }}
                    />
                </div>
                <div
                    className="agent-card__body"
                    style={{ opacity: showSkeleton ? 0 : 1 }}
                >
                    <p className="agent-card__body-name">
                        {agent?.first_name} {agent?.last_name}
                    </p>
                    <div className="d-flex flex-column mb-3">
                        <a
                            className="font-size-1 mb-1"
                            style={{ cursor: 'pointer' }}
                            href={`mailto:${agent?.email}`}
                        >
                            {agent?.email}
                        </a>
                        <a
                            className="font-size-1"
                            style={{ cursor: 'pointer' }}
                            href={`tel:${agent?.phone}`}
                        >
                            <NumberFormat
                                value={agent?.phone}
                                displayType={'text'}
                                format="###.###.####"
                                mask="_"
                            />
                        </a>
                    </div>
                    <div
                        className="btn btn-sm btn-soft-primary transition-3d-hover w-100"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleTransactionsRequest(agent)}
                    >
                        {transactions_button_label}
                        <span className="fas fa-angle-right ml-1" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentCard;
