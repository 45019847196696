import React from 'react';
import { useLang } from '../../context/LangContext';

const ConfirmStep = ({ signingData, applySignature, type }) => {
    const { apply_button, signature_label, initials_label } =
        useLang()['Documents']['FillingDocument']['SigningDocument'];

    return (
        <div className="d-flex flex-column justify-content-center align-items-center mx-auto">
            <div
                className="d-flex flex-column justify-content-between align-items-center mx-2 pb-2"
                style={{ width: '100%' }}
            >
                <span style={{ fontSize: '12px' }}>
                    {type === 'signature' ? signature_label : initials_label}
                </span>
                <img className="img-contain" src={signingData} alt="Sign" />
            </div>
            <button
                type="button"
                className="btn btn-sm btn-wide btn-primary btn-pill transition-3d-hover"
                style={{ cursor: 'pointer' }}
                onClick={applySignature}
            >
                {apply_button}
            </button>
        </div>
    );
};

export default ConfirmStep;
