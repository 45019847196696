import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';

// Components
import {
    ConfirmationDialog,
    DocumentProgress,
    SetupSteper,
    FillingDocumentStep
} from '../components';
import FormsHeader from '../components/common/FormsHeader';

// Redux
import { connect } from 'react-redux';
import { setConfirmModalType } from '../store/actions/Modal';
import {
    setDocumentForFilling,
    saveDocumentForFilling,
    getDocumentForFilling,
    sendSignatureRequest
} from '../store/actions/DocumentFilling';
import { getTransactionDetails } from '../store/sagas/Documents';

// Routing
import { useHistory, withRouter } from 'react-router-dom';
import * as routes from '../router/config/routes';

// Constants
import { confirmationDialogTypes, signerTypes } from '../utils/Constants';
import { splitInputText } from '../utils/Helpers';
import { useLang } from '../context/LangContext';

const FormFilling = ({
    match,
    loading,
    documentForFilling,
    setDocumentForFilling,
    setConfirmModalType,
    getDocumentForFilling,
    saveDocumentForFilling,
    modalDialogType,
    userData,
    sendSignatureRequest
}) => {
    const { replaceFieldValue, reassignField, reassignFieldFrom } =
        useLang()['Documents']['FillingDocument'];
    const fieldLabels = useLang()['TransactionDetails']['EditMappingData'];
    const { import_button, clean_button, saveAndSend_button, saveAndClose_button } =
        useLang()['Documents']['FillingDocument'];

    const history = useHistory();
    const [isProgressVisible, setProgressVisible] = useState(true);
    const [isSettingsStepVisible, setSettingsStepVisible] = useState(true);
    const [dividedSortedAnnotations, setDividedSortedAnnotations] = useState([]);
    const [activeInput, setActiveInput] = useState(null);
    const [modalContent, setModalContent] = useState({});
    const [successHandler, setSuccessHandler] = useState(null);
    const generatorRef = useRef(null);
    const shouldApplyAll = useRef(null);

    const requiredLeftNumber = useMemo(() => {
        let inputsNumber = 0;
        dividedSortedAnnotations.forEach(page =>
            page.forEach(item => {
                if (
                    !item.isCompleted &&
                    item.isRequired &&
                    (!item.assignee || item.assignee === userData.id)
                ) {
                    inputsNumber++;
                }
            })
        );
        return inputsNumber || 0;
    }, [dividedSortedAnnotations, userData]);

    useEffect(() => {
        const trxDocId = match.params.trxDocId;
        getDocumentForFilling(trxDocId);
    }, [getDocumentForFilling, match.params.trxDocId]);

    useEffect(() => {
        if (!documentForFilling) return;
        setSettingsStepVisible(!documentForFilling.filling_settings?.is_setup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentForFilling?.filling_settings?.is_setup]);

    const backToTransactionDetails = useCallback(() => {
        setDocumentForFilling(null);
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${documentForFilling.trx_id}`,
            state: { id: documentForFilling.trx_id }
        });
    }, [documentForFilling, setDocumentForFilling, history]);

    const prepareDocumentAnnotations = () => {
        const documentsAnnotations = [];
        dividedSortedAnnotations.forEach(page =>
            page.forEach(annotation =>
                annotation.inputs.forEach(input => {
                    const inputCopy = { ...input };
                    delete inputCopy.documentIndex;
                    delete inputCopy.packPageNumber;
                    documentsAnnotations[input.documentIndex] = [
                        ...(documentsAnnotations[input.documentIndex] || []),
                        inputCopy
                    ];
                })
            )
        );
        const documents = documentForFilling.documents.map((el, index) => ({
            ...el,
            annotations: documentsAnnotations[index] || []
        }));
        return documents;
    };

    const saveDocument = () => {
        setSuccessHandler(() => backToTransactionDetails);
        saveDocumentForFilling({
            trxDocId: match.params.trxDocId,
            trxId: documentForFilling.trx_id,
            documents: prepareDocumentAnnotations()
        });
    };

    const sendForSignature = () => {
        const preparedAnnotations = dividedSortedAnnotations.map(page =>
            page.map(annotation => annotation.inputs)
        );
        const address = documentForFilling?.header_transaction_info?.address;
        setSuccessHandler(() => backToTransactionDetails);
        sendSignatureRequest({
            signers: documentForFilling.filling_settings?.confirm_signers,
            docId: documentForFilling.annotated_doc_id,
            formName: documentForFilling.form_title,
            propertyAddress: `${address.address_1} ${address.address_2 || ''}`,
            annotations: preparedAnnotations.flat(2),
            documents: prepareDocumentAnnotations(),
            trxId: documentForFilling.trx_id
        });
    };

    const cleanFormFields = () => {
        const cleanedDividedSortedAnnotations = dividedSortedAnnotations.map(page =>
            page.map(field => ({
                ...field,
                isCompleted: false,
                inputs: field.inputs.map(line => ({ ...line, value: '' }))
            }))
        );
        setDividedSortedAnnotations(cleanedDividedSortedAnnotations);
        if (activeInput) {
            setActiveInput(prev => ({
                ...prev,
                item: cleanedDividedSortedAnnotations[prev.page][prev.indexOnPage]
            }));
        }
    };

    function* iterateAnnotations(mappings, trxType) {
        const parties = documentForFilling.filling_settings.confirm_signers.filter(
            signer => signer.type === signerTypes.party
        );
        const partiesNumber = parties.length;
        let updatedValue = [];
        for (let i = 0; i < dividedSortedAnnotations.length; i++) {
            let newPage = [];
            const page = dividedSortedAnnotations[i];
            for (let j = 0; j < page.length; j++) {
                const el = page[j];
                if (!el.mappingKey) {
                    newPage = [...newPage, el];
                    continue;
                }

                let data, assignee;
                if (
                    partiesNumber === 2 &&
                    [
                        'buyer_2',
                        'buyer_3',
                        'buyer_2_signature',
                        'buyer_3_signature'
                    ].includes(el.mappingKey) &&
                    trxType === 'buyer'
                ) {
                    const party = parties[1];
                    data =
                        el.mappingKey === 'buyer_3'
                            ? party?.legal_name || null
                            : el.mappingKey === 'buyer_2'
                            ? ' '
                            : null;
                    assignee =
                        el.mappingKey === 'buyer_3_signature' ? party?.id || null : null;
                } else if (
                    partiesNumber === 2 &&
                    [
                        'seller_2',
                        'seller_3',
                        'seller_2_signature',
                        'seller_3_signature'
                    ].includes(el.mappingKey) &&
                    trxType === 'seller'
                ) {
                    const party = parties[1];
                    data =
                        el.mappingKey === 'seller_3'
                            ? party?.legal_name || null
                            : el.mappingKey === 'seller_2'
                            ? ' '
                            : null;
                    assignee =
                        el.mappingKey === 'seller_3_signature' ? party?.id || null : null;
                } else if (
                    ([
                        'seller_1_signature',
                        'seller_2_signature',
                        'seller_3_signature',
                        'seller_4_signature'
                    ].includes(el.mappingKey) &&
                        trxType === 'seller') ||
                    ([
                        'buyer_1_signature',
                        'buyer_2_signature',
                        'buyer_3_signature',
                        'buyer_4_signature'
                    ].includes(el.mappingKey) &&
                        trxType === 'buyer')
                ) {
                    const index = el.mappingKey.split('_')[1];
                    assignee = parties[index - 1]?.id || null;
                } else if (
                    (['seller_1', 'seller_2', 'seller_3', 'seller_4'].includes(
                        el.mappingKey
                    ) &&
                        trxType === 'seller') ||
                    (['buyer_1', 'buyer_2', 'buyer_3', 'buyer_4'].includes(
                        el.mappingKey
                    ) &&
                        trxType === 'buyer')
                ) {
                    const index = el.mappingKey.split('_')[1];
                    data = parties[index - 1]?.legal_name || ' ';
                } else if (
                    ([
                        'seller_agent_1',
                        'seller_agent_2',
                        'seller_broker_1',
                        'seller_broker_2'
                    ].includes(el.mappingKey) &&
                        trxType === 'seller') ||
                    ([
                        'buyer_agent_1',
                        'buyer_agent_2',
                        'buyer_broker_1',
                        'buyer_broker_2'
                    ].includes(el.mappingKey) &&
                        trxType === 'buyer')
                ) {
                    let signerType = el.mappingKey.split('_')[1];
                    if (signerType === 'agent') signerType = 'sales_associate';
                    const signerIndex = el.mappingKey.split('_')[2];
                    const signer =
                        documentForFilling.filling_settings.confirm_signers.filter(
                            signer => signer.type === signerTypes[signerType]
                        )[signerIndex - 1];
                    data =
                        signer?.legal_name || mappings[el.mappingKey]?.toString() || ' ';
                } else if (
                    ([
                        'seller_agent_signature_1',
                        'seller_agent_signature_2',
                        'seller_broker_signature_1',
                        'seller_broker_signature_2'
                    ].includes(el.mappingKey) &&
                        trxType === 'seller') ||
                    ([
                        'buyer_agent_signature_1',
                        'buyer_agent_signature_2',
                        'buyer_broker_signature_1',
                        'buyer_broker_signature_2'
                    ].includes(el.mappingKey) &&
                        trxType === 'buyer')
                ) {
                    let signerType = el.mappingKey.split('_')[1];
                    if (signerType === 'agent') signerType = 'sales_associate';
                    const signerIndex = el.mappingKey.split('_')[3];
                    const signer =
                        documentForFilling.filling_settings.confirm_signers.filter(
                            signer => signer.type === signerTypes[signerType]
                        )[signerIndex - 1];
                    assignee = signer?.id || null;
                } else {
                    data = mappings[el.mappingKey]?.toString();
                }

                if (!data && !assignee) {
                    newPage = [...newPage, el];
                    continue;
                }
                if (el.isCompleted && data !== ' ') {
                    const prevValue = el.inputs.map(el => el.value).join('');
                    if (prevValue === data) {
                        newPage = [...newPage, el];
                        continue;
                    }
                    const content = replaceFieldValue
                        .replace('prevValue', prevValue)
                        .replace('nextValue', data)
                        .replace('fieldName', fieldLabels[el.mappingKey]);
                    if (shouldApplyAll.current === null)
                        setConfirmModalType(confirmationDialogTypes.replaceValue);
                    const shouldApply =
                        shouldApplyAll.current === null
                            ? yield content
                            : shouldApplyAll.current;
                    if (!shouldApply) {
                        newPage = [...newPage, el];
                        continue;
                    }
                }
                if (el.assignee && el.assignee !== assignee) {
                    const prevAssignee =
                        documentForFilling.filling_settings.confirm_signers.find(
                            ({ id }) => id === el.assignee
                        )?.legal_name;
                    const content = (prevAssignee ? reassignFieldFrom : reassignField)
                        .replace('prevValue', prevAssignee)
                        .replace(
                            'nextValue',
                            documentForFilling.filling_settings.confirm_signers.find(
                                ({ id }) => id === assignee
                            )?.legal_name
                        )
                        .replace('fieldName', fieldLabels[el.mappingKey]);
                    if (shouldApplyAll.current === null)
                        setConfirmModalType(confirmationDialogTypes.replaceValue);
                    const shouldApply =
                        shouldApplyAll.current === null
                            ? yield content
                            : shouldApplyAll.current;
                    if (!shouldApply) {
                        newPage = [...newPage, el];
                        continue;
                    }
                }
                let newEl = {
                    ...el,
                    assignee
                };
                if (data) {
                    const { separatedLines, fontSize } = splitInputText(data, el.inputs);
                    newEl = {
                        ...el,
                        isCompleted: true,
                        inputs: el.inputs.map((input, inputIndex) => ({
                            ...input,
                            value: separatedLines[inputIndex] || '',
                            recalculated_font_size: fontSize
                        }))
                    };
                }
                newPage = [...newPage, newEl];
            }
            updatedValue = [...updatedValue, newPage];
        }

        setDividedSortedAnnotations(updatedValue);
        if (activeInput) {
            setActiveInput(prev => ({
                ...prev,
                item: updatedValue[prev.page][prev.indexOnPage]
            }));
        }
        shouldApplyAll.current = null;
    }

    const importMappingData = async () => {
        const { mappings, type: trxType } = await getTransactionDetails(
            documentForFilling.trx_id
        );

        generatorRef.current = iterateAnnotations(mappings, trxType);
        setModalContent(generatorRef.current.next().value);
    };

    const dropItems = useMemo(
        () =>
            !isSettingsStepVisible &&
            [
                {
                    name: saveAndSend_button,
                    onClickHandler: sendForSignature
                },
                {
                    name: saveAndClose_button,
                    onClickHandler: saveDocument
                },
                {
                    name: import_button,
                    onClickHandler: importMappingData
                },
                {
                    name: clean_button,
                    onClickHandler: () =>
                        setConfirmModalType(confirmationDialogTypes.cleanForm)
                }
            ].filter(item => {
                if (!requiredLeftNumber) {
                    return [saveAndClose_button, clean_button, import_button].includes(
                        item.name
                    );
                } else {
                    return [clean_button, import_button].includes(item.name);
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isSettingsStepVisible,
            documentForFilling?.filling_settings,
            setConfirmModalType,
            requiredLeftNumber,
            dividedSortedAnnotations
        ]
    );

    const applyValue = applyAll => {
        if (applyAll) shouldApplyAll.current = true;
        setModalContent(generatorRef.current.next(true).value);
    };

    const skipValue = applyAll => {
        if (applyAll) shouldApplyAll.current = false;
        setModalContent(generatorRef.current.next(false).value);
    };

    const confirmationDialogConfirmHandler = useMemo(() => {
        switch (modalDialogType) {
            case confirmationDialogTypes.cleanForm:
                return cleanFormFields;

            case confirmationDialogTypes.replaceValue:
                return applyValue;

            case confirmationDialogTypes.cancel:
                return documentForFilling.filling_settings?.is_setup
                    ? () => setSettingsStepVisible(false)
                    : backToTransactionDetails;

            case confirmationDialogTypes.updateFieldsWithNewSigners:
                return importMappingData;

            default:
                return backToTransactionDetails;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalDialogType, documentForFilling]);

    return isProgressVisible ? (
        <DocumentProgress loading={loading} setProgressVisible={setProgressVisible} />
    ) : (
        <>
            <ConfirmationDialog
                initialModalDialogType={modalDialogType}
                successHandler={successHandler}
                confirmHandler={confirmationDialogConfirmHandler}
                closeHandler={skipValue}
                modalContent={modalContent}
            />
            <FormsHeader
                documentForFilling={documentForFilling}
                requiredLeftNumber={requiredLeftNumber}
                dropItems={dropItems}
                saveDocument={saveDocument}
                isSettingsStepVisible={isSettingsStepVisible}
                sendForSignature={sendForSignature}
            />
            {documentForFilling &&
                (isSettingsStepVisible ? (
                    <SetupSteper
                        trxDocId={match.params.trxDocId}
                        trxId={documentForFilling.trx_id}
                        documentTitle={documentForFilling.form_title}
                        fillingSettings={documentForFilling.filling_settings}
                        setSuccessHandler={setSuccessHandler}
                        setSettingsStepVisible={setSettingsStepVisible}
                    />
                ) : (
                    <FillingDocumentStep
                        openSettings={() => setSettingsStepVisible(true)}
                        dividedSortedAnnotations={dividedSortedAnnotations}
                        setDividedSortedAnnotations={setDividedSortedAnnotations}
                        activeInput={activeInput}
                        setActiveInput={setActiveInput}
                        requiredLeftNumber={requiredLeftNumber}
                    />
                ))}
        </>
    );
};

const mapStateToProps = ({ documentFilling, modal, user }) => {
    const { userData } = user;
    const { loading, documentForFilling } = documentFilling;
    const { modalDialogType } = modal;
    return {
        loading,
        documentForFilling,
        modalDialogType,
        userData
    };
};

export default withRouter(
    connect(mapStateToProps, {
        getDocumentForFilling,
        setConfirmModalType,
        setDocumentForFilling,
        saveDocumentForFilling,
        sendSignatureRequest
    })(FormFilling)
);
