// React
import React, { useMemo, useState } from 'react';

// Router
import { useHistory } from 'react-router-dom';
import * as routes from '../../router/config/routes';

// Components
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

// Context
import { useLang } from '../../context/LangContext';

// Constants
import { trxStatus, userTypes, headerTypes } from '../../utils/Constants';

// Packages
import cx from 'classnames';

const DetailsHeader = ({
    transaction,
    contact,
    updateTransactionStatus,
    userData,
    headerType,
    cancelButtonClickHandler,
    numberOfUserTransactions
}) => {
    const { TransactionStatus } = useLang()['TransactionDetails']['DetailsHeader'];
    const { active, pending, closed, archived } = TransactionStatus;
    const { address, mls, first_name, last_name } = transaction
        ? transaction || {}
        : contact || {};
    const { address_1, address_2, city, state, zip } = address || {};
    const history = useHistory();
    const member = history?.location?.state?.member;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const statuses = useMemo(
        () => [
            { name: active, value: trxStatus.active },
            { name: pending, value: trxStatus.pending },
            { name: closed, value: trxStatus.closed },
            { name: archived, value: trxStatus.archived }
        ],
        [active, pending, closed, archived]
    );

    // //Need to handle this in the best way possible so if user navs directly to trx from url, using goback will take them to the last webpage they were on...prob change to nav directly to dashboard/transactions
    // const handleClick = () => {
    //     history.push(`${routes.AUTHENTICATED}${routes.DASHBOARD}`);
    // };

    const filteringDropdownItems = status => {
        // Displaying conditions from the ticket JWB#4
        const isMeetsStatusCondition =
            transaction.status === trxStatus.archived
                ? status.value === trxStatus.active
                : transaction.status === trxStatus.closed
                ? status.value !== trxStatus.archived
                : true;
        const isItCurrentState = status.value === transaction.status;
        return !isItCurrentState && isMeetsStatusCondition;
    };

    const itemClickHandler = status => {
        updateTransactionStatus({
            oldStatus: transaction.status,
            newStatus: status,
            trxId: transaction.id,
            userData
        });
    };

    const editTrxButtonClickHandler = () => {
        history.push({
            pathname: `${routes.AUTHENTICATED}${routes.EDIT_TRANSACTION}/${transaction.id}`,
            state: { id: transaction.id }
        });
    };

    const crossButtonClickHandler = () => {
        if (member && headerType === headerTypes.transactionDetails) history.goBack();

        switch (headerType) {
            case headerTypes.transactionDetails:
                history.push(`${routes.AUTHENTICATED}${routes.DASHBOARD}`);
                break;
            case headerTypes.editTransaction:
                cancelButtonClickHandler();
                break;
            case headerTypes.addConnection:
                history.push(
                    `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.CONNECTIONS}`
                );
                break;
            case headerTypes.connectionDetails:
                history.push(
                    `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.CONNECTIONS}`
                );

                break;
            default:
                history.goBack();
                break;
        }
    };

    return (
        <header
            id="header"
            className={cx('u-header bg-primary d-flex u-header--sticky-top', {
                'top-80': headerType === headerTypes.transactionDetails,
                'top-140': headerType === headerTypes.memberTransactionDetails
            })}
            style={{ zIndex: '1' }}
        >
            <div id="h-main" className="size container pt-md-2 pb-md-2">
                <div className="d-flex justify-content-between align-items-center h-100">
                    <div>
                        {transaction?.address && (
                            <div className="text-white">
                                <h1 className="h5 font-weight-medium mb-0">
                                    {address_1} {address_2}
                                </h1>
                                <p className="d-block text-white mb-0">
                                    {city}, {state} {zip} {!!mls && `| mls# ${mls}`}
                                </p>
                            </div>
                        )}
                        {contact && (
                            <div className="text-white">
                                <h1 className="h5 font-weight-medium mb-0">
                                    {first_name} {last_name}
                                </h1>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        {headerType === headerTypes.editTransaction &&
                            transaction &&
                            userData?.type !== userTypes.client && (
                                <>
                                    <div className="d-flex justify-content-end align-items-center px-3">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle
                                                className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover"
                                                color="btn-soft-white"
                                            >
                                                {
                                                    statuses.find(
                                                        status =>
                                                            status.value ===
                                                            transaction.status
                                                    ).name
                                                }
                                                <span className="fas fa-angle-down ml-2" />
                                            </DropdownToggle>
                                            <DropdownMenu
                                                className={'animate slideIn'}
                                                style={{ width: 'inherit' }}
                                            >
                                                {statuses
                                                    .filter(filteringDropdownItems)
                                                    .map(item => (
                                                        <DropdownItem
                                                            key={item.value}
                                                            className="dropdown-item"
                                                            onClick={() =>
                                                                itemClickHandler(
                                                                    item.value
                                                                )
                                                            }
                                                            style={{
                                                                color:
                                                                    item.value ===
                                                                    trxStatus.active
                                                                        ? '#377dff'
                                                                        : item.value ===
                                                                          trxStatus.archived
                                                                        ? '#de4437'
                                                                        : ''
                                                            }}
                                                        >
                                                            {item.name}
                                                        </DropdownItem>
                                                    ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </>
                            )}
                        {headerType === headerTypes.transactionDetails &&
                            transaction &&
                            userData?.type !== userTypes.client && (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-soft-white text-nowrap transition-3d-hover mx-3"
                                    onClick={editTrxButtonClickHandler}
                                >
                                    <span className="fa-solid fa-gear" />
                                </button>
                            )}
                        {!(
                            numberOfUserTransactions === 1 &&
                            userData?.type === userTypes.client
                        ) && (
                            <div id="close-trx">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-icon btn-primary rounded-circle transition-3d-hover ml-auto"
                                    onClick={crossButtonClickHandler}
                                >
                                    <span className="fas fa-times" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default DetailsHeader;
