import {
    VALIDATE_SIGNING_REQUEST,
    VALIDATE_SIGNING_REQUEST_ERROR,
    SET_SIGNING_REQUEST_VALIDITY
} from './types';

export const validateSigningRequest = data => {
    return {
        type: VALIDATE_SIGNING_REQUEST,
        payload: data
    };
};

export const signingRequestValidationFailure = error => {
    return {
        type: VALIDATE_SIGNING_REQUEST_ERROR,
        payload: error
    };
};

export const setSigningRequestValidity = data => {
    return {
        type: SET_SIGNING_REQUEST_VALIDITY,
        payload: data
    };
};
