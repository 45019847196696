// React
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import { ActivityInput, ActivityThread } from '../index';

// Actions
import { updateTimeVisitingTransaction } from './../../store/actions/Transactions';

const Activity = ({
    activity,
    transaction,
    avatars,
    lang,
    isTransactionArchived,
    isMember,
    lastViewTime,
    currentUserId,
    userData,
    updateTimeVisitingTransaction
}) => {
    useEffect(() => {
        return () => {
            updateTimeVisitingTransaction({
                userData,
                id: transaction?.id,
                status: transaction?.status
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                animation: 'fadeIn 1s',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            {!isTransactionArchived && !isMember && (
                <ActivityInput
                    tasks={transaction?.tasks}
                    status={transaction?.status}
                    trxId={transaction?.id}
                    activity={activity}
                    team={[...transaction?.team, ...transaction?.parties]}
                />
            )}
            <ActivityThread
                activity={activity}
                avatars={avatars}
                lang={lang}
                isTransactionArchived={isTransactionArchived}
                transaction={transaction}
                lastViewTime={lastViewTime}
                currentUserId={currentUserId}
            />
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return {
        userData
    };
};

export default connect(mapStateToProps, { updateTimeVisitingTransaction })(Activity);
