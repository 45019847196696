// React
import React, { useEffect, useState } from 'react';

// Components
import { ConfirmationDialog } from '../../../components';

// Constants
import { confirmationDialogTypes } from '../../../utils/Constants';

const AddMemberProgress = ({
    setFocusedState,
    loading,
    matches,
    executeInvitatonSend
}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!loading) {
            if (!matches?.length) {
                executeInvitatonSend();
            }
            setProgress(100);
        }
    }, [loading, setProgress, matches, executeInvitatonSend]);

    useEffect(() => {
        if (progress === 100) {
            if (matches && matches.length) {
                setTimeout(() => {
                    setFocusedState('member_matches');
                }, 1500);
            }
        }
    }, [progress, setFocusedState, matches]);

    return (
        <div
            className="container d-flex justify-content-center align-items-center"
            style={{
                animation: 'fadeIn 1s',
                height: '50%'
            }}
        >
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.inviteMemberSuccess}
                successHandler={() => setFocusedState('team_list')}
            />
            <div className="col">
                <div className="row justify-content-center align-items-center">
                    <div
                        className="progress"
                        style={{ width: '300px', height: '10px', borderRadius: 5 }}
                    >
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                                width: `${progress}%`,
                                transition: 'width 1500ms ease-in-out'
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddMemberProgress;
