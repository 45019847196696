// React
import React from 'react';
import { useHistory } from 'react-router-dom';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { saveAs } from 'file-saver';
import cx from 'classnames';

// Utils
import { formatDocumentDate } from '../../utils/Helpers';
import * as routes from '../../router/config/routes';
import { documentStatus } from '../../utils/Constants';

const DocumentList = ({
    documents,
    removeDocumentFromTrx,
    userData,
    trxId,
    setDocumentView,
    lang,
    isTransactionArchived,
    isMember,
    status
}) => {
    const { view_label, download_label, archive_label, fill_label, document_status } =
        useLang()['TransactionDetails']['DocumentList'];
    const history = useHistory();
    const dropItems = [
        {
            name: view_label,
            icon: 'fas fa-eye dropdown-item-icon',
            type: 'view'
        },
        {
            name: download_label,
            icon: 'fas fa-cloud-download-alt dropdown-item-icon',
            type: 'download'
        },
        {
            name: archive_label,
            icon: 'fas fa-archive dropdown-item-icon',
            type: 'remove'
        },
        {
            name: fill_label,
            icon: 'fas fa-pen dropdown-item-icon',
            type: 'fill'
        }
    ];

    const handleDownload = ({ url, title }) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
            var blob = xhr.response;
            saveAs(blob, title);
        };
        xhr.open('GET', url);
        xhr.send();
    };

    const handleRemove = document => {
        removeDocumentFromTrx({
            deletedDocId: document.id,
            trxDocId: document.trx_doc_id,
            userData,
            id: trxId,
            title: document.title,
            status
        });
    };

    const handleClick = (type, doc) => {
        if (type === 'view') {
            setDocumentView(doc);
        } else if (type === 'download') {
            handleDownload({
                url: doc.url,
                title: doc.title
            });
        } else if (type === 'remove') {
            handleRemove(doc);
        } else if (type === 'fill') {
            history.push({
                pathname: `${routes.AUTHENTICATED}${routes.FORM_FILLING}/${doc.trx_doc_id}`
            });
        }
    };

    if (!documents || !documents.length) return null;

    return (
        <>
            <div id="documentsCard" className="pt-3 pb-3 px-4">
                {documents &&
                    documents.length &&
                    documents.map(doc => (
                        <div
                            className="d-flex justify-content-between align-items-center mb-3 pb-3 document-item"
                            key={doc.trx_doc_id}
                        >
                            <div className="media">
                                <div className="u-sm-avatar mt-1 mr-3">
                                    <img
                                        className="img-fluid"
                                        src="/images/pdf.svg"
                                        alt="Document"
                                    />
                                </div>
                                <div className="media-body">
                                    <span className="d-block text-dark">{doc.title}</span>
                                    <small className="d-block text-secondary">
                                        <span className="trxtimeSub2-2">
                                            {formatDocumentDate(
                                                doc.added_at
                                                    ? doc.added_at?.seconds
                                                    : doc.edited_at?.seconds
                                                    ? doc.edited_at?.seconds
                                                    : doc.created_at?.seconds,
                                                lang
                                            )}
                                        </span>
                                        {doc.fillable && (
                                            <span
                                                className={cx('text-primary ml-1', {
                                                    'text-success':
                                                        doc.status ===
                                                        documentStatus.completed,
                                                    'text-orange':
                                                        doc.status ===
                                                        documentStatus.pendingSignatures
                                                })}
                                            >
                                                {document_status[doc.status]}
                                            </span>
                                        )}
                                        {/*  We will use it in the future to mark different statuses of document
                                        <i className="text-success ml-1 fas fa-check-circle" />
                                        <i className="text-secondary ml-1 fas fa-lock" /> */}
                                    </small>
                                </div>
                            </div>
                            <div className="position-relative">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                        type="button"
                                        className="drop btn btn-sm btn-icon btn-secondary"
                                    >
                                        <span className="fas fa-ellipsis-h btn-icon__inner" />
                                    </DropdownToggle>
                                    <DropdownMenu className={'animate slideInStayRight'}>
                                        {dropItems
                                            .filter(
                                                item =>
                                                    doc.fillable || item.type !== 'fill'
                                            )
                                            .map(item =>
                                                (isTransactionArchived || isMember) &&
                                                ['remove', 'fill'].includes(
                                                    item.type
                                                ) ? null : (
                                                    <React.Fragment key={item.name}>
                                                        <span
                                                            key={item.name}
                                                            className="dropdown-link"
                                                            onClick={() =>
                                                                handleClick(
                                                                    item.type,
                                                                    doc
                                                                )
                                                            }
                                                        >
                                                            <DropdownItem className="dropdown-item">
                                                                <small
                                                                    className={item.icon}
                                                                />
                                                                {item.name}
                                                            </DropdownItem>
                                                        </span>
                                                    </React.Fragment>
                                                )
                                            )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default DocumentList;
