// React
import React, { useState, useEffect } from 'react';

// Components
import {
    FormHeader,
    ListingFlowStep1,
    ListingFlowStep2,
    ListingFlowStep3,
    Loader
} from '../../components';

// Constants
import { phoneRegExp, nameRegExp, streetAddressRegExp } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import * as Yup from 'yup';
import { FieldArray, Form, Formik } from 'formik';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';

// Redux - Actions, Reducers, Sagas
import {
    createNewTransaction,
    resettingTransactionState
} from '../../store/actions/Transactions';

// Router
import { withRouter, useHistory } from 'react-router-dom';
import * as routes from '../../router/config/routes';

// Utils
import { getSources } from '../../utils/Helpers';

const ListingTransactionFlow = props => {
    const [step, setStep] = useState(1);
    const [income, setIncome] = useState();
    const [sources, setSources] = useState();
    const [idCount, setIdCount] = useState(1);
    const [initialTasks, setInitialTasks] = useState();
    // const [initialDocs, setInitialDocs] = useState();
    const [selectedTasks, setSelectedTasks] = useState([]);
    // const [selectedDocs, setSelectedDocs] = useState();
    const history = useHistory();
    const {
        userData,
        createNewTransaction,
        writingTrx,
        writingTrxSuccess,
        newTrxId,
        resettingTransactionState,
        activeOrg,
        orgs,
        propertyData,
        prefilledParties,
        userConnections,
        pipelineContacts
    } = props;

    const { errorMessage } = useLang()['Constants'];

    useEffect(() => {
        if (!propertyData) {
            history.replace({
                pathname: `${routes.AUTHENTICATED}${routes.NEW_TRANSACTION}`
            });
        }
    }, [propertyData, history]);

    useEffect(() => {
        return () => {
            resettingTransactionState();
        };
    }, [resettingTransactionState]);

    useEffect(() => {
        if (userData && activeOrg) {
            const prefPath = userData.preferences[userData.active_org_id];
            const income = prefPath.income;
            const userSrc = prefPath.sources;
            const orgSrc = activeOrg.preferences.sources;
            const tasks = prefPath.initial_tasks.seller;
            // const docs = prefPath.initial_docs.listing;
            setInitialTasks(tasks);
            // setSelectedTasks(tasks);
            // setInitialDocs(docs);
            setSources(getSources(userSrc, orgSrc));
            setIncome(income);
        }
    }, [userData, activeOrg, orgs]);

    useEffect(() => {
        if (writingTrxSuccess) {
            history.replace({
                pathname: `${routes.AUTHENTICATED}${routes.TRANSACTION_DETAIL}/${newTrxId}`,
                state: { id: newTrxId }
            });
        }
    }, [writingTrxSuccess, newTrxId, history]);

    const initialValues = {
        parties: prefilledParties
            ? prefilledParties.map((el, index) => ({
                  id: index,
                  firstName: el.first_name || '',
                  lastName: el.last_name || '',
                  fullLegalName: el.legal_name || '',
                  email: el.email || '',
                  address: el.address?.address_1 || '',
                  address2: el.address?.address_2 || '',
                  phone: el.phone || '',
                  city: el.address?.city || '',
                  state: el.address?.state || '',
                  zip: el.address?.zip || '',
                  sameAddress: false,
                  pipelineId: ''
              }))
            : [
                  {
                      id: 0,
                      firstName: '',
                      lastName: '',
                      fullLegalName: '',
                      email: '',
                      address: propertyData?.location.address.address_1 || '',
                      address2: propertyData?.location.address.unit_number || '',
                      phone: '',
                      city: propertyData?.location.address.city || '',
                      state: propertyData?.location.address.state,
                      zip: propertyData?.location.address.postal_code || '',
                      sameAddress: true,
                      pipelineId: ''
                  }
              ],
        trxMeta: {
            fromDate: propertyData?.mlsSpec?.ListingContractDate
                ? new Date(propertyData.mlsSpec.ListingContractDate.split('-'))
                : '',
            toDate: '',
            closingDate: propertyData?.mlsSpec?.CloseDate
                ? new Date(propertyData?.mlsSpec?.CloseDate.split('-'))
                : '',
            listingPrice: propertyData?.mlsSpec?.ListPrice || '',
            businessSrc: '',
            mlsNumber: propertyData?.mlsSpec?.ListingId || '',
            commission: income?.commission ? income.commission : '',
            commissionPercent: income?.commission_percent
                ? income.commission_percent
                : true,
            split: income?.split ? income.split : '',
            splitPercent: income?.split_percent ? income.split_percent : true,
            fee: income?.trx_fee ? income.trx_fee : '',
            feePercent: income?.trx_fee_percent ? income.trx_fee_percent : false,
            type: 'seller',
            underContractDate: propertyData?.mlsSpec?.PurchaseContractDate
                ? new Date(propertyData?.mlsSpec?.PurchaseContractDate.split('-'))
                : '',
            team: []
        }
    };

    const addedParty = {
        id: idCount,
        firstName: '',
        lastName: '',
        fullLegalName: '',
        email: '',
        address: propertyData?.location.address.address_1 || '',
        address2: propertyData?.location.address.unit_number || '',
        phone: '',
        city: propertyData?.location.address.city || '',
        state: propertyData?.location.address.state,
        zip: propertyData?.location.address.postal_code || '',
        sameAddress: true,
        pipelineId: ''
    };

    const validationSchema = Yup.object({
        parties: Yup.array().of(
            Yup.object({
                firstName: Yup.string()
                    .matches(nameRegExp.format, errorMessage.firstName.valid)
                    .required(errorMessage.firstName.required),
                lastName: Yup.string()
                    .matches(nameRegExp.format, errorMessage.lastName.valid)
                    .required(errorMessage.lastName.required),
                fullLegalName: Yup.string(),
                email: Yup.string().lowercase().email(errorMessage.email.valid),
                phone: Yup.string().matches(phoneRegExp.format, errorMessage.phone.valid),
                address: Yup.string()
                    .matches(streetAddressRegExp, errorMessage.address.valid)
                    .test(
                        'primary-party-test',
                        errorMessage.address.required,
                        function (value) {
                            return this.path !== 'parties[0].address' || value;
                        }
                    ),
                address2: Yup.string(),
                city: Yup.string()
                    .matches(nameRegExp.format, errorMessage.city.valid)
                    .test(
                        'primary-party-test',
                        errorMessage.city.required,
                        function (value) {
                            return this.path !== 'parties[0].city' || value;
                        }
                    ),
                state: Yup.string().test(
                    'primary-party-test',
                    errorMessage.state.required,
                    function (value) {
                        return this.path !== 'parties[0].state' || value;
                    }
                ),
                zip: Yup.string().test(
                    'primary-party-test',
                    errorMessage.zip.required,
                    function (value) {
                        return this.path !== 'parties[0].zip' || value;
                    }
                ),
                sameAddress: Yup.bool()
            })
        ),
        trxMeta: Yup.object().shape({
            fromDate:
                step === 2
                    ? Yup.string().required(errorMessage.fromDate.required)
                    : Yup.string(),
            toDate:
                step === 2
                    ? Yup.string().required(errorMessage.toDate.required)
                    : Yup.string(),
            listingPrice:
                step === 2
                    ? Yup.number().required(errorMessage.listingPrice.required)
                    : Yup.number(),
            businessSrc:
                step === 2
                    ? Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string().required(errorMessage.businessSrc.required)
                      })
                    : Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string()
                      }),
            commission:
                step === 3
                    ? Yup.number()
                          .required(errorMessage.commission.required)
                          .min(0, errorMessage.commission.min)
                          .when('commissionPercent', {
                              is: true,
                              then: Yup.number().max(
                                  100,
                                  errorMessage.commission.maxPercent
                              ),
                              otherwise: Yup.number().test(
                                  'max',
                                  errorMessage.commission.max,
                                  function (value) {
                                      return value < this.parent.listingPrice;
                                  }
                              )
                          })
                    : Yup.number(),
            commissionPercent: step === 3 ? Yup.bool().required() : Yup.bool(),
            split:
                step === 3
                    ? Yup.number()
                          .required(errorMessage.split.required)
                          .min(0, errorMessage.split.min)
                          .when('splitPercent', {
                              is: true,
                              then: Yup.number().max(100, errorMessage.split.maxPercent),
                              otherwise: Yup.number().test(
                                  'max',
                                  errorMessage.split.max,
                                  function (value) {
                                      return value < this.parent.listingPrice;
                                  }
                              )
                          })
                    : Yup.number(),
            splitPercent: step === 3 ? Yup.bool().required() : Yup.bool(),
            fee:
                step === 3
                    ? Yup.number()
                          .required(errorMessage.fee.required)
                          .min(0, errorMessage.fee.min)
                          .when('feePercent', {
                              is: true,
                              then: Yup.number().max(100, errorMessage.fee.maxPercent),
                              otherwise: Yup.number().test(
                                  'max',
                                  errorMessage.fee.max,
                                  function (value) {
                                      return value < this.parent.listingPrice;
                                  }
                              )
                          })
                    : Yup.number(),
            feePercent: step === 3 ? Yup.bool().required() : Yup.bool(),
            team: Yup.array()
        })
    });

    const checkError = (errors, index, type) => {
        if (index !== null) {
            if (
                errors &&
                errors.parties &&
                errors.parties[index] &&
                errors.parties[index][type]
            ) {
                return errors.parties[index][type];
            }
        } else {
            if (type === 'businessSrc') {
                if (errors && errors.trxMeta && errors.trxMeta[type]?.value) {
                    return errors.trxMeta[type]?.value;
                }
            } else {
                if (errors && errors.trxMeta && errors.trxMeta[type]) {
                    return errors.trxMeta[type];
                }
            }
            return null;
        }
    };

    const checkTouched = (touched, index, type) => {
        if (index !== null) {
            if (
                touched &&
                touched.parties &&
                touched.parties[index] &&
                touched.parties[index][type]
            ) {
                return true;
            } else {
                return null;
            }
        } else {
            if (type === 'businessSrc') {
                if (touched && touched.trxMeta && touched.trxMeta[type]) {
                    return true;
                } else {
                    return null;
                }
            } else {
                if (touched && touched.trxMeta && touched.trxMeta[type]) {
                    return true;
                } else {
                    return null;
                }
            }
        }
    };

    const renderContent = Boolean(propertyData && props.userData?.preferences);

    if (!renderContent) return null;

    return (
        <main id="content" role="main" className="push-main">
            <div className="bg-light">
                <div
                    style={{
                        alignSelf: 'center',
                        display: writingTrx ? 'block' : 'none',
                        width: '100%'
                    }}
                >
                    <Loader />
                </div>
                <SimpleBar
                    style={{
                        height: '100%',
                        width: '100%',
                        display: writingTrx ? 'none' : ''
                    }}
                >
                    <div className="container space-2">
                        <div className="mx-lg-auto">
                            <div className="card">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    enableReinitialize
                                    onSubmit={(values, { setSubmitting }) => {
                                        if (step === 3) {
                                            createNewTransaction({
                                                formData: { ...values },
                                                propertyData: { ...propertyData },
                                                userData,
                                                tasks: selectedTasks
                                            });
                                        }
                                        setStep(step === 3 ? null : step + 1);
                                        setSubmitting(false);
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldTouched,
                                        setFieldValue,
                                        touched,
                                        values,
                                        isSubmitting
                                    }) => (
                                        <>
                                            <FormHeader
                                                step={step}
                                                setStep={setStep}
                                                handleSubmit={handleSubmit}
                                            />
                                            <Form>
                                                <div
                                                    style={{
                                                        animation: 'fadeIn .5s'
                                                    }}
                                                >
                                                    {step === 1 && (
                                                        <FieldArray
                                                            name="parties"
                                                            render={arrayHelpers => {
                                                                const { parties } =
                                                                    values;

                                                                return (
                                                                    <ListingFlowStep1
                                                                        parties={parties}
                                                                        pipeline={
                                                                            pipelineContacts
                                                                        }
                                                                        contacts={
                                                                            userConnections
                                                                        }
                                                                        checkError={
                                                                            checkError
                                                                        }
                                                                        errors={errors}
                                                                        values={values}
                                                                        handleBlur={
                                                                            handleBlur
                                                                        }
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        handleSubmit={
                                                                            handleSubmit
                                                                        }
                                                                        setFieldTouched={
                                                                            setFieldTouched
                                                                        }
                                                                        setFieldValue={
                                                                            setFieldValue
                                                                        }
                                                                        checkTouched={
                                                                            checkTouched
                                                                        }
                                                                        touched={touched}
                                                                        isSubmitting={
                                                                            isSubmitting
                                                                        }
                                                                        arrayHelpers={
                                                                            arrayHelpers
                                                                        }
                                                                        addedParty={
                                                                            addedParty
                                                                        }
                                                                        setIdCount={
                                                                            setIdCount
                                                                        }
                                                                        idCount={idCount}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    {step === 2 && (
                                                        <ListingFlowStep2
                                                            setStep={setStep}
                                                            isSubmitting={isSubmitting}
                                                            checkError={checkError}
                                                            errors={errors}
                                                            values={values}
                                                            handleBlur={handleBlur}
                                                            handleChange={handleChange}
                                                            handleSubmit={handleSubmit}
                                                            setFieldTouched={
                                                                setFieldTouched
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            checkTouched={checkTouched}
                                                            touched={touched}
                                                            sources={sources}
                                                        />
                                                    )}
                                                    {step === 3 && (
                                                        <ListingFlowStep3
                                                            setStep={setStep}
                                                            isSubmitting={isSubmitting}
                                                            checkError={checkError}
                                                            errors={errors}
                                                            values={values}
                                                            handleBlur={handleBlur}
                                                            handleChange={handleChange}
                                                            handleSubmit={handleSubmit}
                                                            setFieldTouched={
                                                                setFieldTouched
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            checkTouched={checkTouched}
                                                            touched={touched}
                                                            initialTasks={initialTasks}
                                                            selectedTasks={selectedTasks}
                                                            setSelectedTasks={
                                                                setSelectedTasks
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Form>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </main>
    );
};

const mapStateToProps = ({ user, transactions, org, connections, pipeline }) => {
    const { userData } = user;
    const { activeOrg, orgs } = org;
    const { userConnections } = connections;
    const { pipelineContacts } = pipeline;
    const { writingTrx, writingTrxSuccess, newTrxId, propertyData } = transactions;
    return {
        userData,
        activeOrg,
        orgs,
        writingTrx,
        writingTrxSuccess,
        newTrxId,
        propertyData,
        userConnections,
        pipelineContacts
    };
};

export default withRouter(
    connect(mapStateToProps, {
        createNewTransaction,
        resettingTransactionState
    })(ListingTransactionFlow)
);
