// React
import React, { useState } from 'react';

// Assets
import logo from '../../assets/img/jada-logo.svg';

// Components
import { MainHeader } from '../index';
import AdminControlsBanner from '../MemberTransactions/AdminControlsBanner';

// Packages
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

// Redux - Actions, Reducers, Sagas
import { togglingSideNav } from '../../store/actions/App';
import { setLanguageType } from '../../store/actions/Lang';

// // Router
import * as routes from '../../router/config/routes';

// Utils
import { capitalizeFirstLetter, makePossessiveCase } from '../../utils/Helpers';

const AppHeader = ({
    match,
    history,
    togglingSideNav,
    userData,
    type,
    disabledButton,
    setLanguageType,
    shouldHideTabs,
    newMessagesCounter,
    adminBanner = false,
    memberName
}) => {
    const homePath = `${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.TRANSACTIONS}`;

    const userLanguages = userData?.languages;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const formattedName = memberName
        ? makePossessiveCase(memberName, userData.active_language_id)
        : null;

    if (!userData) return null;

    return (
        <header id="header" className="u-header u-header--sticky-top shadow-soft ">
            {adminBanner && formattedName && (
                <AdminControlsBanner memberName={formattedName} />
            )}
            <div className="u-header__section p-15">
                <div
                    id="logoAndNav"
                    className="container d-flex align-items-center justify-content-between"
                >
                    <nav className="navbar navbar-expand-md u-header__navbar w-100">
                        <Link
                            className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                            style={{
                                pointerEvents:
                                    homePath === history?.location.pathname
                                        ? 'none'
                                        : 'auto'
                            }}
                            to={`${routes.AUTHENTICATED}${routes.DASHBOARD}`}
                        >
                            <img
                                src={logo}
                                className="logo"
                                style={{ height: '42px' }}
                                alt="jada"
                            />
                        </Link>

                        <div id="navBar" className="ml-auto">
                            <ul className="navbar-nav u-header__navbar-nav">
                                {userLanguages?.length > 1 && (
                                    <li className="nav-item u-header__nav-item">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle
                                                id="sidebarNavToggler"
                                                className="btn btn-xs btn-text-secondary u-sidebar--account__toggle-bg target-of-invoker-has-unfolds"
                                                type="button"
                                                style={{
                                                    padding:
                                                        '0.43rem 1.95rem .43rem 0.5rem',
                                                    marginRight: '-32px',
                                                    backgroundColor:
                                                        'rgba(224, 224, 224, 1)',
                                                    borderColor: 'rgba(224, 224, 224, 1)'
                                                }}
                                            >
                                                <span className="position-relative">
                                                    <span className="u-sidebar--account__toggle-text">
                                                        {userData.active_language_id}
                                                    </span>
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="languages_dropdown_menu">
                                                {userLanguages.map(lang => (
                                                    <span
                                                        key={lang.id}
                                                        className="dropdown-link"
                                                    >
                                                        <DropdownItem
                                                            className="dropdown-item"
                                                            color="#000"
                                                            style={{ paddingY: '0' }}
                                                        >
                                                            <p
                                                                className="languages_dropdown_item-name"
                                                                onClick={() => {
                                                                    setLanguageType({
                                                                        origin: lang.id
                                                                    });
                                                                }}
                                                                style={{
                                                                    color:
                                                                        userData.active_language_id ===
                                                                        lang.id
                                                                            ? '#377dff'
                                                                            : '#77838f'
                                                                }}
                                                            >
                                                                {capitalizeFirstLetter(
                                                                    lang.name
                                                                )}
                                                            </p>
                                                        </DropdownItem>
                                                    </span>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                )}
                                <li className="nav-item u-header__nav-item">
                                    <button
                                        id="sidebarNavToggler"
                                        onClick={!disabledButton ? togglingSideNav : null}
                                        className="btn btn-xs btn-text-secondary u-sidebar--account__toggle-bg ml-1 target-of-invoker-has-unfolds"
                                        type="button"
                                        style={{
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                            border: '1px solid #e0e0e0'
                                        }}
                                    >
                                        <span className="position-relative">
                                            <span className="u-sidebar--account__toggle-text">
                                                {`${userData?.first_name} ${userData?.last_name}`}
                                            </span>
                                            {userData?.user_avatar ? (
                                                <img
                                                    src={userData.user_avatar}
                                                    className="u-sidebar--account__toggle-img"
                                                    alt={`${userData?.first_name} ${userData?.last_name}`}
                                                />
                                            ) : (
                                                <Avatar
                                                    className="btn btn-rel btn-icon btn-soft-primary contact-frame__avatar--tiny rounded-circle"
                                                    name={`${userData.first_name} ${userData.last_name}`}
                                                    unstyled
                                                />
                                            )}
                                            {!!newMessagesCounter && (
                                                <span className="badge badge-sm badge-danger badge-pos rounded-circle">
                                                    {newMessagesCounter}
                                                </span>
                                            )}
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            {!shouldHideTabs && (
                <MainHeader
                    match={match}
                    history={history}
                    userData={userData}
                    type={type}
                />
            )}
        </header>
    );
};

const mapStateToProps = ({ app, user, transactions, messages }) => {
    const { memberData } = transactions;
    const { newMessagesCounter } = messages;
    const { userData, type } = user;
    const { sideNav, disabledButton } = app;
    return { userData, type, sideNav, disabledButton, memberData, newMessagesCounter };
};

export default connect(mapStateToProps, {
    togglingSideNav,
    setLanguageType
})(AppHeader);
