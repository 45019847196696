/******************************************************************************************************************/
/******************************************* Multilingual Term Library ********************************************/
/******************************************************************************************************************/

import { english } from 'flatpickr/dist/l10n/default.js';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

const library = {
    en: {
        lang_name: `English`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {
                new_connections_label: `Add New Connection`,
                create_button_label: `Save Connection`,
                cancel_button_label: `Cancel`
            },

            AddDocToTrxModal: {
                add_label: `Add to transaction`,
                search_placeholder: `Search transactions`,
                select_all_label: `Select all`,
                mls_label: `MLS#`,
                add_button_label: `Add document`,
                cancel_button_label: `Cancel`,
                already_exists_message: `This Document already exists in all of your active Transactions.`,
                no_active_message: `You do not have any active Transactions.  Documents can only be added to active Transactions`,
                transaction_button_label: `New Transaction`,
                close_button_label: `Close`
            },
            AddDocumentModal: {
                drag_click_label: 'Drag files here or click to upload',
                upload_label: `Upload document`,
                name_label: `Name: `,
                size_label: `Size: `,
                add_title_label: `Add title`,
                title_placeholder: `Title`,
                doc_type_label: `Document type`,
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                add_button_label: `Add document`,
                cancel_button_label: `Cancel`,
                create_pack_label: `Create pack`,
                save_changes_label: `Save changes`
            },
            AddTaskModal: {
                add_task_label: `Add a Task`,
                add_task_tip: `Creating a new task allows the task to be optionally added to any new transaction during initial creation.`,
                trx_type_label: `Transaction Type`,
                buying_label: `Buying`,
                listing_label: `Listing`,
                task_title_label: `Task Title`,
                task_title_placeholder: `Title`,
                task_desc_label: `Task Description`,
                task_desc_placeholder: `Description`,
                default_expire_label: `Default Epiration Time`,
                days_label: `Days`,
                hour_label: `Hour`,
                min_label: `Minute`,
                ordinal_label: `AM/PM`,
                create_button_label: `Create Task`,
                cancel_button_label: `Cancel`
            },
            AppHeader: {},
            AvatarEditModal: {
                change_avatar_label: `Change your avatar`,
                update_button_label: `Update avatar`,
                cancel_button_label: `Cancel`
            },
            ClickWrapper: {},
            CreatableSelectField: {
                add_custom_label: `Add custom source`
            },
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {
                title_placeholder: `Title`,
                description_placeholder: `Description`,
                date_time_placeholder: `Date and time`,
                open_button_label: `Open Task`,
                complete_button_label: `Task Complete`,
                delete_button_label: `Delete Task`,
                update_button_label: `Update Task`
            },
            Loader: {},
            MainHeader: {
                transactions_link: `Transactions`,
                connections_link: `Connections`,
                pipeline_link: `Pipeline`,
                forms_link: `Forms Library`,
                documents_link: `Documents`,
                profile_link: `Profile`,
                preferences_link: `Preferences`,
                messages_link: `Messages`,
                alerts_link: `Alerts`,
                organization_button_label: `New Member`,
                transaction_button_label: `New Transaction`,
                connection_button_label: `New Connection`,
                pipeline_button_label: `New Pipeline`,
                library_button_label: `New Form`
            },
            RemoveTaskModal: {
                remove_task_label: `Remove a Task`,
                assurance_label: `Are you sure you want to remove this task?`,
                delete_button_label: `Delete Task`,
                cancel_button_label: `Cancel`
            },
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {
                home_link: `Home`,
                settings_link: `Settings`,
                messages_link: `Messages`,
                contacts_link: `Company Contacts`,
                logout_link: `Logout`
            },
            StateCountrySelect: {
                select_label: `Select`
            },
            TextField: {},
            TransactionUploadModal: {
                image_label: `Image`,
                file_label: `File`,
                attach_file_label: `attachment`,
                add_title_label: `Add Title`,
                add_title_placeholder: `Title`,
                name_label: `Name: `,
                size_label: `Size: `,
                attach_button_label: `Attach`,
                attach_send_button_label: `Attach and `,
                cancel_button_label: `Cancel`
            },
            ConfirmationDialog: {
                /**
                 * Instruction:
                 * To add a new type of Confirmation Dialog you should add translations for all states in which
                 * dialog can be for this type or it will use common translation. To create specific one follow
                 * next naming rule: `{confirmationDialogType}_[title/text/confirmButtonText/cancelButtonText]`.
                 */
                Confirmation: {
                    // Common
                    title: `Are you sure?`,

                    // Specific
                    cancel_text: `All changes will be lost.`,
                    delete_text: `This action cannot be undone!`,
                    activateTrx_text: `All parties will now have access to this transaction.`,
                    reopenTrx_text: `This transaction has been closed, please confirm you want to reopen it.`,
                    closeTrx_text: `Please enter a closing date`,
                    archiveTrx_text: `This action will disable all editing and remove access to all parties. You can reset to Active if needed in the future.`,
                    cleanForm_text: `It will clean up all fields`,
                    useConnection_text: `Should linked parties be added to the new transaction?`,
                    removeSigner_text: `This action cannot be undone!`,
                    removePrimarySigner_text: `This action cannot be undone!`,
                    leaveAndJoinTeam_text: `This action will remove you from your current team and add you to the new team, all team leaders will be notified.`,
                    leaveAsLeaderAndJoinTeam_text: `This action will remove your current team and all members of the team in which you are a team leader.`,
                    leaveCurrentTeam_text: `You are leaving the team, the team leader will be notified. This action cannot be undone.`,
                    declineTeamInvite_text: `Please confirm you do not want to accept this invitation. The team leader will be notified.`,
                    deleteTeam_text: `All team members will be released and the team will be deleted. This action cannot be undone.`,
                    deleteMemberFromTrx_text: `Removing a team member will block them from accessing the transaction. The member's past activity will remain as part of the transaction.`,
                    deleteMemberFromOrg_text: `You want to remove this member completely!`,
                    updateFieldsWithNewSigners_text: `Do you want to update document fields with new settings?`,

                    // Member Invites
                    inviteMemberSuccess_title: `On it's way!`,
                    inviteMemberFailure_title: `Ooops...`,
                    inviteMemberSuccess_text: `Your invitation was successfully sent.`,
                    inviteMemberFailure_text: `There was a problem sending your invitation, please try again later.`,

                    // Accept/Decline Member Invites
                    acceptInviteSuccess_title: `Congratulations!`,
                    acceptInviteSuccess_text: `You're all set up. Now let's start closing some deals!`,
                    acceptInviteFailure_title: `Ooops...`,
                    acceptInviteFailure_text: `Something went wrong.  You may need to contact the Organization who invited you to resend the invitation.  Sorry for the inconvenience.`,
                    declineInvite_title: `Decline invitation?`,
                    declineInvite_text: `Please confirm you do not want to accept this invitation.`,
                    declineInviteSuccess_title: `Success!`,
                    declineInviteSuccess_text: `You have declined this invitation.`,
                    declineInviteFailure_title: `Ooops...`,
                    declineInviteFailure_text: `Something went wrong.  No need to take any action but there was a problem declining this invitation.  This invitation will eventually expire in case this problem persists.  Sorry for the inconvenience.`,

                    // Buttons
                    // Common
                    confirmButtonText: `Confirm!`,
                    cancelButtonText: `Cancel`,
                    // Specific
                    delete_confirmButtonText: `Yes, delete it!`,
                    cancel_confirmButtonText: `Yes, cancel!`,
                    cancel_cancelButtonText: `No, stay!`,
                    inviteMemberSuccess_confirmButtonText: `Continue`,
                    inviteMemberFailure_confirmButtonText: `Continue`,
                    acceptInviteSuccess_confirmButtonText: `Let's go`,
                    acceptInviteFailure_confirmButtonText: `Continue`,
                    replaceValue_confirmButtonText: `Replace`,
                    replaceValue_cancelButtonText: `Skip`,
                    useConnection_confirmButtonText: `Add linked parties`,
                    useConnection_cancelButtonText: `Skip`,
                    leaveAndJoinTeam_confirmButtonText: `I'm Sure`,
                    leaveAsLeaderAndJoinTeam_confirmButtonText: `I'm Sure`,
                    leaveCurrentTeam_confirmButtonText: `Yes, leave team!`,
                    updateFieldsWithNewSigners_confirmButtonText: `Update`,

                    // Additional items
                    closeTrx_inputPlaceholder: `Select a date`,
                    closeTrx_validationError: `Please pick a closing date.`,
                    replaceValue_inputPlaceholder: `Apply for all fields`,
                    removeSigner_inputPlaceholder: `Remove party from the entire transaction.`
                },
                Loading: {
                    // Common
                    title: `Processing...`,
                    text: `Please, wait.`
                },
                Success: {
                    // Common
                    title: `Success!`,
                    text: `Your changes have been updated.`,
                    confirmButtonText: `OK`,

                    // Specific
                    delete_title: `Deleted!`,
                    delete_text: `Selected item has been deleted.`,
                    uploadContact_text: `Your contact has been added.`,
                    uploadDocument_title: `Uploaded!`,
                    uploadDocument_text: `Your document has been uploaded.`,
                    uploadConnection_text: `Your connection(s) have been uploaded.`,
                    createFormPack_text: `A new form pack has been created.`
                },
                SuccessWithoutHandler: {
                    // Common
                    title: `Success!`,
                    confirmButtonText: `OK`
                },
                Failed: {
                    // Common
                    title: `Ooops...`,
                    text: `Something went wrong!`,
                    confirmButtonText: `OK`
                },
                ErrorCurrentPassword: {
                    // Common
                    title: `Ooops...`,
                    text: `Current password is wrong!`,
                    confirmButtonText: `OK`
                }
            },
            Dropdown: {
                delete_label: 'Delete',
                edit_label: 'Edit'
            },
            ActionButtons: {
                cancel_label: 'Cancel',
                save_label: 'Save changes'
            }
        },
        CompanyContacts: {
            ContactDetailsHistory: {},
            ContactList: { no_contacts_label: `You have no contacts!` },
            ContactRow: { button_label: `Details` },
            ContactRowSkeleton: {},
            ContactsSearchFilter: {
                all_label: `All Contacts`,
                active_label: `Active Contacts`,
                inactive_label: `Inactive Contacts`,
                input_label: `Search contacts`
            }
        },
        ContactDetails: { button_label: `Send message` },
        Constants: {
            errorMessage: {
                emailPasswordCombo: `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser: `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                emailPrimaryLinkMatch: `Email address cannot be the same as Primary connection.`,
                emailIsAlreadyParty: `This user is already a party of the transaction.`,
                password: {
                    lowerCase: `Must contain at least one lowercase letter`,
                    upperCase: `Must contain at least one uppercase letter`,
                    numeric: `Must contain at least one number`,
                    length: `Must be at least 8 characters long`,
                    required: `Please enter a password`
                },
                email: {
                    valid: `Please enter a valid email address`,
                    required: `Email address is required`,
                    uniqSigner: `The user with this email is already a signer of this document`,
                    duplicateEmail: `Duplicate email address`
                },
                phone: {
                    valid: `Please enter a valid phone number`,
                    required: `Phone number is required`
                },
                phoneExt: {
                    valid: `Enter a valid ext.`
                },
                address: {
                    required: `Address is required`,
                    valid: `Please enter a valid address`
                },
                firstName: {
                    valid: `Please enter a valid first name`,
                    required: `First name is required`
                },
                middleInitial: {
                    valid: `Please enter a valid middle initial`
                },
                lastName: {
                    valid: `Please enter a valid last name`,
                    required: `Last name is required`
                },
                fullName: {
                    valid: `Please enter a valid full name`,
                    required: `Full name is required`
                },
                city: {
                    valid: `Please enter a valid city`,
                    required: `City is required`
                },
                state: {
                    required: `State required`
                },
                zip: {
                    required: `Zip/Postal code is required`
                },
                country: {
                    required: `Country is required`
                },
                fromDate: {
                    required: `Start date is required`
                },
                toDate: {
                    required: `End date is required`
                },
                listingPrice: {
                    required: `Listing price is required`
                },
                commission: {
                    required: `Commission is required`,
                    valid: `Commission must be a number`,
                    min: `Commission must be a positive number`,
                    max: `Commission cannot be greater than the price`,
                    maxPercent: `Commission percent cannot be greater than 100`
                },
                split: {
                    required: `Agent split is required`,
                    valid: `Agent split must be a number`,
                    min: `Agent split must be a positive number`,
                    max: `Agent split cannot be greater than the price`,
                    maxPercent: `Agent split percent cannot be greater than 100`
                },
                fee: {
                    required: `Transaction fee is required`,
                    valid: `Transaction fee must be a number`,
                    min: `Transaction fee must be a positive number`,
                    max: `Transaction fee cannot be greater than the price`,
                    maxPercent: `Transaction fee percent cannot be greater than 100`
                },
                businessSrc: {
                    required: `Source is required`
                },
                emailOrPhone: {
                    required: `Email or phone is required`
                },
                firstOrLastName: {
                    required: `First or last name is required`
                },
                licNumber: {
                    required: `Lic number is required`
                },
                mlsId: {
                    required: `MLS id is required`
                },
                mlsIdIfMls: {
                    required: `An MLS ID is required if an MLS is selected`
                },
                mlsIfMlsId: {
                    required: `An MLS is required if an MLS ID is entered`
                },
                permissions: {
                    required: `Permissions selection is required`
                },
                accountType: {
                    required: `Account type is required`
                },
                signerType: {
                    required: `Signer type is required`
                },
                confirmSigners: {
                    maxBrokers: `You can add at most two brokers to sign the document`,
                    maxSalesAssociates: `You can add at most two sales associates to sign the document`,
                    maxParties: `You can add at most four parties to sign the document`
                },
                duplicateMember: `Organization member email already exists`
            },
            accountTypes: [
                {
                    id: 'administrator',
                    label: 'Administrator',
                    langAgnosticLabel: 'Administrator',
                    role: 'member',
                    sub_type: 'administrator',
                    value: 'Administrator'
                },
                {
                    id: 'assistant',
                    label: 'Assistant',
                    langAgnosticLabel: 'Assistant',
                    role: 'member',
                    sub_type: 'assistant',
                    value: 'Assistant'
                },
                {
                    id: 'broker',
                    label: 'Broker',
                    langAgnosticLabel: 'Broker',
                    role: 'member',
                    sub_type: 'broker',
                    value: 'Broker'
                },
                {
                    id: 'coordinator',
                    label: 'Coordinator',
                    langAgnosticLabel: 'Coordinator',
                    role: 'member',
                    sub_type: 'coordinator',
                    value: 'Coordinator'
                },
                {
                    id: 'owner',
                    label: 'Owner',
                    langAgnosticLabel: 'Owner',
                    role: 'owner',
                    sub_type: 'owner',
                    value: 'Owner'
                },
                {
                    id: 'sales_associate',
                    label: 'Sales Associate',
                    langAgnosticLabel: 'Sales Associate',
                    role: 'member',
                    sub_type: 'sales_associate',
                    value: 'Sales Associate'
                }
            ],
            permissionTypes: [
                {
                    id: 'default',
                    label: 'Default',
                    langAgnosticLabel: 'Default',
                    value: 'default'
                },
                {
                    id: 'owner',
                    label: 'Owner',
                    langAgnosticLabel: 'Owner',
                    value: 'owner'
                }
            ]
        },
        Connections: {
            AddNewConnection: {},
            ConnectionList: { no_connections_label: `You have no connections!` },
            ConnectionMatches: {
                match_label: `Potential Match`,
                incorrect_match_single: `This is not the correct match.`,
                incorrect_match_multiple: `These are not the correct matches.`,
                select_match: `Select`
            },
            ConnectionProgress: {},
            ConnectionRow: { button_label: `Details` },
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {
                input_label: `Search connections`
            },
            ExistingConnectionCheck: {
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`
            },
            NewConnectionForm: {
                first_name_label: `First Name`,
                first_name_placeholder: `First name`,
                last_name_label: `Last Name`,
                last_name_placeholder: `Last name`,
                legal_name_label: `Legal Name`,
                legal_name_placeholder: `Legal Name`,
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`,
                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,
                mailing_address_same_label: `Mailing address same as primary connection?`,
                connection_notes_label: `Connection Notes`,
                connection_notes_placeholder: `Connection notes`,
                remove_connection_label: `Remove link`,
                add_another_connection_label: `Add another connection`,
                link_button: `Link connection`,
                primary_button: `Primary connection`,
                add_party_text: `Add a linked party`,
                add_party_subtext: `spouse, partner, parent, etc..`,
                save_button: `Save`
            }
        },
        ConnectionDetails: {
            notes_heading: 'Notes',
            history_heading: 'History',
            status: {
                buyer: 'Buyer',
                seller: 'Seller'
            },
            button_label: 'New Transaction'
        },
        Dashboard: {},
        DateTime: { locale: english },
        Documents: {
            DocumentCard: {
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                pack_label: ` Pack`
            },
            DocumentsGrid: {
                no_documents_label: `No documents found!`,
                create_pack_label: `Create a pack`
            },
            DocumentRow: {
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                pack_label: ` Pack`,
                button_label: `View Document`,
                pack_button_label: `View Pack`,
                file_name_label: `File name`,
                upload_date_label: `Upload date`,
                documents_pack_label: `Documents pack`
            },
            DocumentsSearchFilter: {
                input_label: `Search documents`,
                all_label: `All Documents`,
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                dropdown_label: `Document Title`
            },
            FillingDocument: {
                importing_text: `Importing transaction data...`,
                importing_subtext: `Let's see how much data we can pull in for you... saving you time is our goal!`,
                left_field_label: ` Required Fields Left`,
                completed_field_label: `All Required Fields Complete!`,
                assign_label: `Assign field`,
                disabled_assigning_label: `Assigning required fields is not allowed with the current filling settings`,
                prev_button: `Prev`,
                next_button: `Next`,
                settings_label: `Settings`,
                import_button: `Import Data`,
                clean_button: `Clear All Data`,
                saveAndSend_button: `Save and Send`,
                saveAndClose_button: `Save Draft and Close`,

                replaceFieldValue: `Replace <b>prevValue</b> by <b>nextValue</b> in <ins>fieldName</ins> field`,
                reassignFieldFrom: `Reassign <ins>fieldName</ins> field from <b>prevValue</b> to <b>nextValue</b>`,
                reassignField: `Reassign <ins>fieldName</ins> field to <b>nextValue</b>`,

                SetupFilling: {
                    first_question: `Who will complete all required fields for this document?`,
                    second_question: `Who will need to sign this document?`,
                    third_question: `Confirm signers:`,
                    myself_label: `Myself`,
                    myselfAndOthers_label: `Myself + Others`,
                    others_label: `Only Others`,
                    noSignatures_label: `No signatures needed`,
                    forward_question: `When signatures are complete:`,
                    forward_label: `Forward document to listing agent - `,
                    addSigner_label: `Add signer`,
                    current_step: `Step stepNumber of 3`,
                    go_back: `Go Back`,
                    save_settings: `Save Settings`,
                    saveButton_label: `Save`,
                    signer_info: `Signer for this document only.`,
                    legalName_placeholder: `Full legal name`,
                    email_placeholder: `Email address`,
                    type_placeholder: `Type`,
                    signerTypes: {
                        party: 'Party',
                        sales_associate: 'Sales Associate',
                        broker: 'Broker',
                        other: 'Other'
                    }
                },
                SigningDocument: {
                    consent_modalTitle: `Consent to Electronic Signature`,
                    consent_modalContent: `By agreeing you are consenting to electronically sign this document and to the terms of service of Jada Technologies, LLC. A timestamp and your device IP address will be recorded to authenticate this action.`,
                    agree_buttonLabel: `Agree`,
                    disagree_buttonLabel: `Disagree`,
                    finish_buttonLabel: `Finished`,
                    start_label: `Start`,
                    next_label: `Next`,
                    type_tabLabel: `Type`,
                    sign_tabLabel: `Sign`,
                    upload_tabLabel: `Upload`,
                    apply_button: `Apply`,
                    sign_label: `Sign below`,
                    initial_label: `Initial below`,
                    uploadSignature_label: `Upload signature as a .png`,
                    uploadInitials_label: `Upload initials as a .png`,
                    dragClick_label: `Drag or click to upload image.`,
                    next_buttonLabel: `Next`,
                    clean_buttonLabel: `Clean`,
                    signature_label: `Signature`,
                    initials_label: `Initials`
                }
            },
            Tabs: {
                tabName: {
                    library: `Forms Library`,
                    my_doc: `My Documents`,
                    forms_marketplace: `Forms Marketplace`
                },
                packTabName: {
                    avaliable: `Available `,
                    my_doc: `Selected`
                },

                document_button_label: `New Document`
            }
        },
        Landing: {
            title_1: `Welcome. This could be the start of something `,
            title_2: `big!`,
            button_label: `Let's Get Started`
        },
        Library: {},
        InvitationAcceptance: {
            next_label: 'Save and continue',
            submit_label: 'Create account',
            cancel_label: 'Cancel',
            skip_label: 'Skip for now',
            back_label: 'Back',
            decline_label: 'Decline invitation',
            step_one: {
                heading: 'Welcome to Jada!',
                secondary_text:
                    'To set up your account, we just need a little information first.',
                first_name_label: 'First Name',
                first_name_placeholder: 'First Name',
                last_name_label: 'Last Name',
                last_name_placeholder: 'Last Name',
                email_label: 'Email Address',
                email_placeholder: 'example@mail.com',
                email_helper_text: 'This can be changed later in your profile settings.',
                account_type_label: 'Account Type',
                account_type_placeholder: 'Select Type',
                phone_label: 'Mobile Phone Number',
                phone_placeholder: 'Phone',
                password_label: 'Create Password',
                password_placeholder: '********',
                length_helper_text: 'At least 8 characters please',
                uppercase_helper_text: 'At least one uppercase letter',
                number_helper_text: 'Add a number for more security',
                symbol_helper_text: 'Use a # & ! % $ or * to make it super secure'
            },
            step_two: {
                heading: 'Almost there!',
                secondary_text:
                    'Fill out your profile information now or you can do it later in settings. ',
                profile_picture_label: 'Profile Picture',
                upload_label: 'upload',
                about_label: 'A little about yourself',
                about_placeholder: 'Type here...',
                about_helper_text: 'This info will be seen in your public profile.',
                mls_id_label: 'MLS ID/Member number',
                mls_id_placeholder: 'MLS ID',
                lic_number_label: 'State License Number',
                lic_number_placeholder: 'License',
                mls_label: 'MLS/Board Name',
                mls_placeholder: 'Select One',
                helper_text: 'Everything here can be updated in your profile settings',
                file_size_warning: `File is too large. Please choose a file 2MB or smaller.`
            }
        },
        InvitationConfirmation: {
            ConfirmInterface: {
                confirm_heading: `Confirmed!`,
                confirm_description_1: `You have added `,
                confirm_description_2: ` as a new organization to your account. You can now switch between organizations in your settings.`,
                confirm_button_label: `Go to dashboard`
            }
        },
        InvitationError: {
            ErrorInterface: {
                exit_button_label: `Exit`,
                confirm_button_label: `Go to dashboard`,
                expired_heading: `Invitation Expired!`,
                expired_description: `Unfortunately this invitation is no longer valid or has expired.  Please reach out to the organization who invited you to resend the invitation.`,
                invalid_email_heading: `Invalid User Account!`,
                invalid_email_description: `In order to properly accept this invitation, you must be logged in to your Jada Account using the email address that this invitation was delivered to.`
            }
        },
        TrxInvitationAcceptance: {
            submit_label: 'Create account',
            heading: 'Welcome to Jada!',
            secondary_text: 'Complete your account setup to access your new transaction.',
            TrxInvRegistrarionForm: {
                first_name_label: 'First Name',
                first_name_placeholder: 'First Name',
                last_name_label: 'Last Name',
                last_name_placeholder: 'Last Name',
                full_name_label: 'Full Legal Name',
                full_name_placeholder: 'Full Legal Name',
                email_label: 'Email Address',
                email_placeholder: 'example@mail.com',
                email_helper_text: 'This can be changed later in your profile settings.',
                phone_label: 'Mobile Phone Number',
                phone_placeholder: 'Phone',
                password_label: 'Create Password',
                password_placeholder: '********',
                length_helper_text: 'At least 8 characters please',
                uppercase_helper_text: 'At least one uppercase letter',
                number_helper_text: 'Add a number for more security',
                symbol_helper_text: 'Use a # & ! % $ or * to make it super secure',
                profile_picture_label: 'Profile Picture',
                upload_label: 'upload',
                file_size_warning: `File is too large. Please choose a file 2MB or smaller.`
            }
        },
        Login: {
            title_1: `Welcome `,
            title_2: `back`,
            sub_title: `Login to access your account.`,
            email_label: `EMAIL ADDRESS`,
            email_placeholder: `Email address`,
            password_label: `PASSWORD`,
            forgot_password: `Forgot Password?`,
            no_account: `Don't have an account? `,
            no_account_link: `Learn more`,
            button_label: `Let's Go`
        },
        Messages: {
            title: `Message Center`,
            msg_tab_title: `Messages`,
            pinned_msg_tab_title: `Pinned Messages`,
            drafts_tab_title: `Drafts`,
            MessagesTab: {
                new_msg_title: `New Message`,
                bulk_action_btn_label: `Bulk actions`,
                pinn_btn_label: `Pinn`,
                delete_btn_label: `Delete`,
                compose_btn_label: `Compose`,
                no_msg_found: `No messages found!`
            },
            MessageModal: {
                new_msg_title: `New message`,
                new_draft_msg_title: `New draft`,
                placeholder_to: `To`,
                placeholder_new_msg: `New message`,
                placeholder_msg: `Message...`,
                placeholder_reply: `Reply...`,
                btn_cancel: `Cancel`,
                btn_draft: `Save as draft`,
                btn_send: `Send`,
                btn_save: `Save`,
                btn_delete: `Delete`,
                btn_reply: `Reply`,
                no_reply_label: `No reply`,
                select_all_members: `All members`
            }
        },
        NewConnection: {
            title: `Yes! Making connections.`,
            sub_text: `Let's start with the basics to check if your new contact may already have an account.  If not you'll be able to invite them or enter their information manually.`,
            party_title: `Adding a new person to the deal.`,
            party_sub_text: `Let's check to see if the new party already has an account. If not we'll invite them to the transaction automatically.`
        },
        NewDocument: {
            back_button_label: 'Go back'
        },
        NewTransaction: {
            BuyerFlowStep1: {
                first_name_label: `First Name`,
                first_name_placeholder: `First name`,
                middle_initial_label: `MI`,
                last_name_label: `Last Name`,
                last_name_placeholder: `Last name`,
                legal_name_label: `Legal Name`,
                legal_name_placeholder: `Full legal name`,
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `Mailing address same as above?`,
                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,
                add_party_label: `Add another party`,
                remove_party_label: `Remove party`,
                next_button_label: `Next`,
                modal_text: `Should linked parties be added to the new transaction?`
            },
            BuyerFlowStep2: {
                closing_date_label: `Closing Date`,
                closing_date_placeholder: `Closing date`,
                purchase_price_label: `Purchase Price`,
                purchase_price_placeholder: `Price`,
                offer_price_label: `Offer Price`,
                offer_price_placeholder: `Price`,
                business_src_label: `Business Source`,
                business_src_placeholder: `Select`,
                upload_img_label: `Upload images`,
                drag_drop_label: `Browse your device and upload images`,
                max_size_label: `Maximum file size is 2MB`,
                img_optional_label: `This is optional. We'll grab more images from MLS later.`,
                back_button_label: `Back`,
                next_button_label: `Next`
            },
            BuyerFlowStep3: {
                commission_label: `Buying Commission`,
                commission_placeholder: `0`,
                split_label: `Agent Split`,
                split_placeholder: `0`,
                fee_label: `Transaction Fee`,
                fee_placeholder: `0`,
                forms_label: `Initial forms`,
                tasks_label: `Initial tasks`,
                back_button_label: `Back`,
                done_button_label: `Done`
            },
            BuyerSelectForm: {
                select_label: `Select`,
                property_info_label: `Property information`,
                mls_option_label: `Enter MLS number.`,
                address_option_label: `Not in MLS? Enter the address.`,
                property_address_label: `Property Address`,
                address_placeholder: `Start typing address`,
                city_label: `City`,
                city_placeholder: `City`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                state_label: `State`,
                zip_label: `Zip Code`,
                zip_placeholder: `Zip code`,
                mls_number_label: `MLS Number`,
                mls_number_placeholder: `Enter MLS ID`,
                property_found_label: `Property found!`,
                property_not_found_label: `Property not found.`,
                button_label: `Let's go`,
                mls_button_label: `Find`
            },
            BuyerTransactionFlow: {},
            FormHeader: {
                step_1_label: `Parties`,
                step_2_label: `Details`,
                step_3_label: `Set Up`
            },
            ListingFlowStep1: {
                first_name_label: `First Name`,
                first_name_placeholder: `First name`,
                middle_initial_label: `MI`,
                last_name_label: `Last Name`,
                last_name_placeholder: `Last name`,
                legal_name_label: `Legal Name`,
                legal_name_placeholder: `Full legal name`,
                email_address_label: `Email Address`,
                email_address_placeholder: `Email address`,
                phone_number_label: `Phone Number`,
                phone_number_placeholder: `Phone number`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `Mailing address same as listing address?`,
                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,
                add_party_label: `Add another party`,
                remove_party_label: `Remove party`,
                next_button_label: `Next`,
                modal_text: `Should linked parties be added to the new transaction?`
            },
            ListingFlowStep2: {
                listing_start_label: `Listing Start Date`,
                listing_start_placeholder: `From`,
                listing_expiration_label: `Expiration Date`,
                listing_expiration_placeholder: `To`,
                listing_price_label: `Listing Price`,
                listing_price_placeholder: `Price`,
                business_src_label: `Business Source`,
                business_src_placeholder: `Select`,
                upload_img_label: `Upload images`,
                drag_drop_label: `Browse your device and upload images`,
                max_size_label: `Maximum file size is 2MB`,
                img_optional_label: `This is optional. We'll grab more images from MLS later.`,
                back_button_label: `Back`,
                next_button_label: `Next`
            },
            ListingFlowStep3: {
                commission_label: `Selling Commission`,
                commission_placeholder: `0`,
                split_label: `Agent Split`,
                split_placeholder: `0`,
                fee_label: `Transaction Fee`,
                fee_placeholder: `0`,
                forms_label: `Initial forms`,
                tasks_label: `Initial tasks`,
                back_button_label: `Back`,
                done_button_label: `Done`
            },
            ListingSelectForm: {
                select_label: `Select`,
                property_info_label: `Property information`,
                manual_option_label: `Address not found? Enter it manually.`,
                mls_option_label: `Have an MLS number?`,
                address_option_label: `Have an address?`,
                address_label: `Address Lookup`,
                address_placeholder: `Start typing address`,
                property_address_label: `Property Address`,
                city_label: `City`,
                city_placeholder: `City`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                state_label: `State`,
                zip_label: `Zip Code`,
                zip_placeholder: `Zip code`,
                description_label: `Lot/Land Description`,
                description_placeholder: `Description`,
                mls_number_label: `Mls Number`,
                mls_number_placeholder: `Enter MLS ID`,
                property_found_label: `Property found!`,
                property_not_found_label: `Property not found.`,
                or_label: `Or`,
                cancel_link: `Cancel`,
                button_label: `Let's go`,
                mls_button_label: `Find`
            },
            ListingTransactionFlow: {},
            NewTransactionHeader: { back_button_label: `Go back` },
            TransactionTypeSelect: {
                title_label: `Woohoo! Let's do this.`,
                listing_button_label: `Listing`,
                buyer_button_label: `Buyer`
            }
        },
        PdfViewer: {
            ControllerHeader: {
                fillable_button_label: `Make Fillable`,
                edit_button_label: `Edit fields`,
                add_trx_button_label: `Add to Transaction`,
                edit_pack_button_label: `Edit Pack`
            },
            ViewController: {
                of_label: `of`
            }
        },
        Pipeline: {
            PipelineList: { no_pipeline_label: `Your pipeline is empty!` },
            PipelineRow: { button_label: `Details` },
            PipelineRowSkeleton: {},
            PipelineSearchFilter: { input_label: `Search pipeline` },
            NewPipelineForm: {
                first_name_label: 'First Name',
                first_name_placeholder: 'First Name',
                last_name_label: 'Last Name',
                last_name_placeholder: 'Last Name',
                legal_name_label: 'Legal Name',
                legal_name_placeholder: 'Full Legal Name',
                address_label: 'Current Address',
                address_placeholder: '123 Main Street',
                unit_label: 'Unit',
                unit_placeholder: 'unit, apartment, suite',
                city_label: 'City',
                city_placeholder: 'City',
                state_label: 'State',
                state_placeholder: 'State',
                zip_label: 'Zip/Postal Code',
                zip_placeholder: 'Zip',
                email_label: 'Email',
                email_placeholder: 'Email',
                phone_label: 'Phone',
                phone_placeholder: 'Phone',
                save_label: 'Save',
                cancel_label: 'Cancel'
            }
        },
        Profile: {},
        Settings: {
            save_label: 'Save changes',
            cancel_label: 'Cancel',
            create_label: 'Create Team',
            delete_label: 'Delete Team',
            leave_label: 'Leave Team',
            accept_label: 'Accept',
            decline_label: 'Decline',
            remove_label: 'Remove',
            SettingsMenu: {
                app_integratons_link: `App Integrations`,
                billing_link: `Billing`,
                organization_info_link: `Organization Info`,
                organization_members_link: `My Organization`,
                personal_info_link: `Personal Info`,
                preferences_link: `Preferences`,
                security_settings_link: `Security Settings`,
                team_link: `My Team`,
                edit_label: `edit`,
                file_size_warning: `File is too large. Please choose a file 2MB or smaller.`
            },
            OrganizationInfo: {
                heading: 'Organization info',
                name_label: 'Office Name',
                status_label: 'Status',
                type_label: 'Office Type',
                addOffice_label: 'Add office',
                addOrg_label: 'Add org',
                addPhone_label: 'Add phone',
                officeStatuses: {
                    active_label: 'Active',
                    inactive_label: 'Inactive'
                },
                officeTypes: {
                    main_label: 'Main',
                    branch_label: 'Branch',
                    satellite_label: 'Satellite'
                },
                office: {
                    labels: {
                        licNumber: 'Lic number',
                        mlsId: 'MLS id',
                        officeLogo: 'Office logo',
                        officeLogo_tooltip:
                            'For best results, the logo file should be either a .png or .svg with a minimum  height of 98px.',
                        officeName: 'Office name',
                        officeAddress: 'Office address',
                        officeMLS: 'Office MLS id',
                        officePhone: 'Phone',
                        uploadPhoto: 'Upload photo',
                        remove: 'Remove'
                    },
                    placeholders: {
                        enterNumber: 'Enter number',
                        enterId: 'Enter id',
                        enterName: 'Enter office name',
                        enterPhone: 'Enter phone number',
                        enterAddress: 'Enter address'
                    },
                    phoneTypes: {
                        office: 'office',
                        mobile: 'mobile',
                        phone: 'phone',
                        work: 'work',
                        fax: 'fax'
                    }
                }
            },
            OrganizationMembers: {
                main_title: 'My Organization',
                header: {
                    add_member_label: 'Add member'
                },
                info_text:
                    'Please complete the form below to invite a new member to your organization.',
                name_label: 'Name',
                first_name_label: 'First Name',
                first_name_placeholder: 'First Name',
                last_name_label: 'Last Name',
                last_name_placeholder: 'Last Name',
                email_label: 'Email',
                email_placeholder: 'Email',
                account_type_label: 'Account Type',
                account_type_placeholder: 'Select Type',
                account_permissions_label: 'Account Permissions',
                account_permissions_placeholder: 'Select Permissions',
                invite_button_label: 'Invite member',
                match_label: `Potential Match`,
                incorrect_match_single: `This is not the correct match.`,
                incorrect_match_multiple: `These are not the correct matches.`,
                select_match: `Select`,
                send_invite_button_label: `Send invite`,
                cancel_invite_button_label: `Cancel`,
                pending_invites_label: `Pending Invites`,
                my_invites_title: `My Invites`,
                remove_org_member: `Remove member`,
                back_button_label: `Back to My Organization`,
                invitationStatuses: {
                    pending: 'Pending',
                    accepted: 'Accepted',
                    declined: 'Declined',
                    expired: 'Expired'
                },
                none_found_label: `No inviations found!`
            },
            PersonalInfo: {
                main_title: 'Personal Info',
                lic_number: 'License number',
                mls_id: 'MLS id',
                tooltip_mls_id:
                    'Your MLS ID will only be used for keeping your MLS listings in sync with your transactions in Jada',
                name_label: 'Name',
                email_label: 'Email',
                phone_label: 'Phone',
                about_label: 'About',
                add_phone_label: 'Add phone',
                placeholder_licence: 'Enter your licence number',
                placeholder_mls_id: 'Enter your id',
                placeholder_first_name: 'Enter your first name',
                placeholder_last_name: 'Enter your last name',
                placeholder_email: 'Enter your email',
                placeholder_phone: 'Enter your number',
                placeholder_editor: 'Enter text',
                dropdown_title: 'Mobile',
                dropdown_item_office: 'Office',
                dropdown_item_mobile: 'Mobile',
                dropdown_item_fax: 'Fax',
                modal_title: 'Please confirm your password',
                modal_password_placeholder: 'Enter your password',
                modal_button_cancel: 'Cancel',
                modal_button_save: 'Save changes'
            },
            SecuritySettings: {
                main_heading: 'Security Settings',
                info_text: 'Update your password for account access.',
                newPass_label: 'New password',
                repeat_label: 'Re-type New Password',
                save_label: 'Save changes',
                cancel_label: 'Cancel',
                current_password_label: 'Current password'
            },
            Team: {
                main_title: 'My Team',
                invite_title: 'Invite Member',
                no_available_invite: "You don't have any available organization members.",
                no_team:
                    'You don`t have a team yet. Create one and invite members to join!',
                header: {
                    add_member_label: 'Add member',
                    add_team_label: 'Create team',
                    invite_label: 'Invite member'
                },
                newTeam: {
                    add_team_label: 'Add New Team'
                },
                teamList: {
                    name_label: 'Name',
                    status_label: 'Status',
                    type_label: 'Account type',
                    pending_member: `pending member`,
                    statuses: {
                        active_label: 'Active',
                        inactive_label: 'Inactive'
                    }
                },
                myInvites_title: `My Invites`,
                back_button_label: `Back to My Team`,
                no_invitations_label: `You don't have any invitation yet`,
                pending_invites_label: `Pending Invites`,
                invitationStatuses: {
                    pending: 'Pending',
                    accepted: 'Accepted',
                    declined: 'Declined',
                    abandoned: 'Abandoned',
                    expired: 'Expired'
                }
            },
            AppIntegrations: {},
            Billing: {},
            Preferences: {
                title: 'Preferences',
                Tabs: {
                    names: {
                        name1: 'Notifications',
                        name2: 'Documents',
                        name3: 'Tasks',
                        name4: 'Languages'
                    }
                },
                Notifications: {
                    options: {
                        client_invite: 'Client invite is accepted',
                        new_task: 'A new task has been created',
                        docs_signed: 'Documents have been signed',
                        offer_made: 'An Offer is made'
                    },
                    tips: 'Get notified by email when new activity occurs on your transactions. These options can also be set for individual transactions.'
                },
                Documents: {
                    tip_note: {
                        tip1: 'Adding default documents here gives you the option to quickly add them when creating a new transaction, saving you time!'
                    },
                    buttons: {
                        button1: '+ Buyer Docs',
                        button2: '+ Seller Docs'
                    },
                    modal: {
                        search: 'Search',
                        title1: 'Add Buyer Documents',
                        title2: 'Add Seller Documents',
                        buttons: {
                            cancel: 'Cancel',
                            update: 'Update'
                        }
                    }
                },
                Tasks: {
                    tip_note:
                        'Adding default tasks here gives you the option to quickly add them when creating a new transaction, saving you time!',
                    buttons: {
                        button1: '+ Buyer Tasks',
                        button2: '+ Seller Tasks'
                    },
                    modal: {
                        newCaptionBuyer: 'New Buyer Task',
                        editCaptionBuyer: 'Edit Buyer Task',
                        newCaptionSeller: 'New Seller Task',
                        editCaptionSeller: 'Edit Seller Task',
                        placeholders: {
                            title: 'Task title',
                            description: 'Task description'
                        },
                        labels: {
                            days: 'Days from new',
                            time: 'Time due'
                        },
                        buttons: {
                            cancel_button: 'Cancel',
                            save_button: 'Save changes',
                            add_button: 'Add',
                            update_button: 'Update'
                        }
                    }
                },
                Languages: {
                    tips: {
                        tip1: 'Adding languages here will allow you to quickly toggle between your default language and others you are fluent in.',
                        tip2: 'Move the language in the order of priority.',
                        tip3: 'More languages coming soon!'
                    },
                    default_status: 'default'
                }
            }
        },
        TransactionDetails: {
            ActivityMessages: {
                add_document: `Added a new document: `,
                remove_document: `Removed a document: `,
                change_trx_status: `Changed transaction status to `,
                new_transaction: `Congratulations on your new transaction! Feel free to add documents and create tasks if you have not done so already. We look forward to seeing everyone at the finish line!`,
                add_task: `Added a new task: `,
                complete_task: `Updated task status to Complete: `,
                open_task: `Updated a task status to Open: `,
                updated_task: `Updated a task: `,
                deleted_task: `Deleted a task: `,
                update_address: `Updated property address to `
            },
            ActivityInput: {
                added_task_label: `Added a new task: `,
                upload_a_img_label: `Uploaded an image: `,
                upload_a_file_label: `Uploaded a file: `,
                image_label: `Image`,
                file_label: `File`,
                whats_up_placeholder: `What's happening...`,
                task_title_placeholder: `Task Title`,
                task_note_placeholder: `Task Note`,
                date_time_placeholder: `Date and time`,
                attachment_button_label: `Attachment`,
                task_button_label: `Task`,
                post_button_label: `Post`,
                add_task_label: `Add Task`,
                uploaded_label: `uploaded:`
            },
            ActivityThread: {
                no_messages_label: `No Messages yet.`
            },
            AddSearch: {
                all_label: `All`,
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                other_label: `Other`,
                add_label: `Add`,
                search_placeholder: `Search`
            },
            DetailsHeader: {
                TransactionStatus: {
                    active: 'Active',
                    pending: 'Pending',
                    closed: 'Closed',
                    archived: 'Archived'
                },
                edit_transaction: 'Edit Transaction'
            },
            DocumentDrop: {
                drag_drop_label: `Drag files here or click to upload`,
                add_label: `Add`,
                last_update_label: `Last updated`
            },
            DocumentList: {
                view_label: `View`,
                download_label: `Download`,
                archive_label: `Archive`,
                fill_label: `Fill Fields`,
                document_status: {
                    notStarted: `Not started`,
                    draft: `Draft`,
                    pendingSignatures: `Pending Signatures`,
                    completed: `Completed`
                }
            },
            Documents: {},
            DocumentView: {
                of_label: `of`
            },
            NoteDrop: {
                note_title_placeholder: `Note title`,
                note_content_placeholder: `Note content`,
                private_label: `Private`,
                add_note_button_label: `Add Note`
            },
            NoteList: {
                assurance_label: `Are you sure you want to remove this note?`,
                remove_label: `Remove`,
                cancel_label: `Cancel`
            },
            Notes: {},
            TabNavigation: {
                activity_label: `Activity`,
                documents_label: `Documents`,
                notes_label: `Notes`
            },
            Tasks: {
                all_tasks_button_label: `All Tasks`,
                less_tasks_button_label: `Less Tasks`,
                hide_history_button_label: `Hide Task History`,
                show_history_button_label: `Show Task History`,
                add_task_button_label: `Add a Task`,
                no_tasks_label: `No scheduled tasks`
            },
            TransactionInterface: {},
            TransactionMeta: {
                seller_agent_label: `Seller's Agent`,
                buyer_agent_label: `Buyer's Agent`,
                days_market_label: `Days on market`,
                closing_in_label: `Closing in`,
                days_label: `days`,
                no_close_date_label: `No closing date`,
                opposing_label: `Opposing members:`,
                team_label: `Team members:`,
                seller_label: `Seller`,
                buyer_label: `Buyer`,
                primary_agent_label: `Buyer's agent`,
                primary_broker_label: `Buyer's broker`,
                btn_save_label: `Save`,
                tab_team_label: `My Team`,
                tab_office_label: `Office`,
                search_placeholder: `Search member`,
                first_name_label: 'First Name',
                first_name_placeholder: 'First Name',
                last_name_label: 'Last Name',
                last_name_placeholder: 'Last Name',
                legal_name_label: 'Legal Name',
                legal_name_placeholder: 'Full Legal Name',
                email_label: 'Email',
                email_placeholder: 'Email',
                type_placeholder: `Type`,
                signerTypes: {
                    party: 'Party',
                    sales_associate: 'Sales Associate',
                    broker: 'Broker',
                    other: 'Other'
                },
                primary_label: 'Primary',
                new_opposing_label: 'New opposing member',
                add_member_label: 'Add member',
                phone_label: 'Phone',
                phone_placeholder: 'Phone',
                brokerage_label: 'Brokerage',
                brokerage_placeholder: 'Start typing to find brokerage...',
                add_opposing_button: 'Add opposing member'
            },
            EditTransactionDetails: {
                details_tab: `Details`,
                parties_tab: `Parties`,
                importedData_tab: `Imported Data`,
                property_tab: `Property`,
                property_info: `Changing the MLS number or property address is a global change and will update all transaction data previously stored. All documents with the current property data will be archived.`,
                price_label: `Price`,
                offer_price_label: `Offer Price`,
                closingDate_label: `Closing Date`,
                first_name_label: `client first name`,
                last_name_label: `client last name`,
                full_name_label: `client legal name`,
                view_connection_label: `View connection`,
                view_connection_description: `To update other details`,

                business_src_label: `Business Source`,
                business_src_placeholder: `Select`,
                commission_label: `Selling Commission`,
                commission_placeholder: `0`,
                split_label: `Agent Split`,
                split_placeholder: `0`,
                fee_label: `Transaction Fee`,
                fee_placeholder: `0`,

                add_contact: `Add as a contact`,
                add_party: `Add a new party`,
                remove_label: `Remove`,
                invite_label: `Invite`,
                reinvite_label: `Reinvite`,

                address_label: `Current Address`,
                address_placeholder: `Address`,
                unit_label: `Unit`,
                unit_placeholder: `Apt, Suite, Unit`,
                city_label: `City`,
                city_placeholder: `City`,
                state_label: `State`,
                zip_label: `Zip/Postal Code`,
                zip_placeholder: `Zip`,

                mls_number_label: `MLS Number`,
                mls_number_placeholder: `Enter MLS ID`,
                property_found_label: `Property found!`,
                property_not_found_label: `Property not found.`,
                mls_button_label: `Find`
            },
            EditMappingData: {
                all_buyers: 'All Buyers',
                all_sellers: 'All Sellers',
                buyer_address: 'Buyer Address',
                buyer_address_1: 'Buyer Address Line 1',
                buyer_address_2: 'Buyer Address Line 2 (Unit #)',
                buyer_address_city: 'Buyer Address City',
                buyer_address_state: 'Buyer Address State',
                buyer_address_zip: 'Buyer Address Zip',
                buyer_1: 'Buyer 1',
                buyer_2: 'Buyer 2',
                buyer_3: 'Buyer 3',
                buyer_4: 'Buyer 4',
                buyer_1_signature: 'Buyer 1 Signature',
                buyer_2_signature: 'Buyer 2 Signature',
                buyer_3_signature: 'Buyer 3 Signature',
                buyer_4_signature: 'Buyer 4 Signature',
                buyer_agent_1: 'Buyer Agent 1',
                buyer_agent_lic_1: 'Buyer Agent 1 License',
                buyer_agent_signature_1: 'Buyer Agent 1 Signature',
                buyer_agent_2: 'Buyer Agent 2',
                buyer_agent_lic_2: 'Buyer Agent 2 License',
                buyer_agent_signature_2: 'Buyer Agent 2 Signature',
                buyer_broker_1: 'Buyer Broker 1',
                buyer_broker_lic_1: 'Buyer Broker 1 License',
                buyer_broker_signature_1: 'Buyer Broker 1 Signature',
                buyer_broker_2: 'Buyer Broker 2',
                buyer_broker_lic_2: 'Buyer Broker 2 License',
                buyer_broker_signature_2: 'Buyer Broker 2 Signature',
                buyer_brokerage: 'Buying Brokerage',
                buyer_brokerage_address: 'Buying Brokerage Address',
                buyer_brokerage_address_1: 'Buying Brokerage Address Line 1',
                buyer_brokerage_address_2: 'Buying Brokerage Address Line 2 (Unit #)',
                buyer_brokerage_address_city: 'Buying Brokerage Address City',
                buyer_brokerage_address_state: 'Buying Brokerage Address State',
                buyer_brokerage_address_zip: 'Buying Brokerage Address Zip',
                buyer_brokerage_email: 'Buying Brokerage Email',
                buyer_brokerage_fax: 'Buying Brokerage Fax',
                buyer_brokerage_mls: 'Buyer Brokerage MLS ID',
                buyer_brokerage_phone: 'Buying Brokerage Phone',
                buyer_closing_rep_address: 'Buyer Closing Representative Address',
                buyer_closing_rep_address_1: 'Buyer Closing Rep Address Line 1',
                buyer_closing_rep_address_2: 'Buyer Closing Rep Address Line 2',
                buyer_closing_rep_city: 'Buyer Closing Rep Address City',
                buyer_closing_rep_state: 'Buyer Closing Rep Address State',
                buyer_closing_rep_zip: 'Buyer Closing Rep Address Zip',
                buyer_closing_rep_email: 'Buyer Closing Representative Email',
                buyer_closing_rep_fax: 'Buyer Closing Representative Fax',
                buyer_closing_rep_name: 'Buyer Closing Representative Name',
                buyer_closing_rep_phone: 'Buyer Closing Representative Phone',
                closing_date: 'Closing Date',
                county: 'County',
                legal_description: 'Legal Description',
                listing_start_date: 'Listing Start Date',
                listing_expiration_date: 'Listing End Date',
                offer_date: 'Offer Date',
                property_address: 'Property Address',
                property_address_1: 'Property Address Line 1',
                property_address_2: 'Property Address Line 2 (Unit #)',
                property_address_city: 'Property Address City',
                property_address_state: 'Property Address State',
                property_address_zip: 'Property Address Zip',
                purchase_price: 'Purchase Price',
                offer_price: 'Offer Price',
                listing_price: 'Listing Price',
                seller_address: 'Seller Address',
                seller_address_1: 'Seller Address Line 1',
                seller_address_2: 'Seller Address Line 2 (Unit #)',
                seller_address_city: 'Seller Address City',
                seller_address_state: 'Seller Address State',
                seller_address_zip: 'Seller Address Zip',
                seller_1: 'Seller 1',
                seller_2: 'Seller 2',
                seller_3: 'Seller 3',
                seller_4: 'Seller 4',
                seller_1_signature: 'Seller 1 Signature',
                seller_2_signature: 'Seller 2 Signature',
                seller_3_signature: 'Seller 3 Signature',
                seller_4_signature: 'Seller 4 Signature',
                seller_agent_1: 'Seller Agent 1',
                seller_agent_lic_1: 'Seller Agent 1 License',
                seller_agent_signature_1: 'Seller Agent 1 Signature',
                seller_agent_2: 'Seller Agent 2',
                seller_agent_lic_2: 'Seller Agent 2 License',
                seller_agent_signature_2: 'Seller Agent 2 Signature',
                seller_broker_1: 'Seller Broker 1',
                seller_broker_lic_1: 'Seller Broker 1 License',
                seller_broker_signature_1: 'Seller Broker 1 Signature',
                seller_broker_2: 'Seller Broker 2',
                seller_broker_lic_2: 'Seller Broker 2 License',
                seller_broker_signature_2: 'Seller Broker 2 Signature',
                seller_brokerage: 'Selling Brokerage',
                seller_brokerage_address: 'Selling Brokerage Address',
                seller_brokerage_address_1: 'Selling Brokerage Address Line 1',
                seller_brokerage_address_2: 'Selling Brokerage Address Line 2 (Unit #)',
                seller_brokerage_address_city: 'Selling Brokerage Address City',
                seller_brokerage_address_state: 'Selling Brokerage Address State',
                seller_brokerage_address_zip: 'Selling Brokerage Address Zip',
                seller_brokerage_email: 'Selling Brokerage Email',
                seller_brokerage_fax: 'Selling Brokerage Fax',
                seller_brokerage_phone: 'Selling Brokerage Phone',
                seller_brokerage_mls: 'Seller Brokerage MLS ID',
                seller_closing_rep_address: 'Selling Closing Representative Address',
                seller_closing_rep_address_1: 'Seller Closing Rep Address Line 1',
                seller_closing_rep_address_2: 'Seller Closing Rep Address Line 2',
                seller_closing_rep_city: 'Seller Closing Rep Address City',
                seller_closing_rep_state: 'Seller Closing Rep Address State',
                seller_closing_rep_zip: 'Seller Closing Rep Address Zip',
                seller_closing_rep_email: 'Selling Closing Representative Email',
                seller_closing_rep_fax: 'Selling Closing Representative Fax',
                seller_closing_rep_name: 'Selling Closing Representative Name',
                seller_closing_rep_phone: 'Selling Closing Representative Phone'
            }
        },
        Transactions: {
            active_label: `Active`,
            pending_label: `Pending`,
            closed_label: `Closed`,
            archived_label: `Archived`,
            buyer_label: `Buyer`,
            seller_label: `Seller`,
            CardSkeleton: {},
            TransactionCard: {
                button_label: `Details`
            },

            TransactionList: {
                none_found_label: `No transactions found!`
            },

            TransactionRow: {
                buyer_label: `Buyer`,
                seller_label: `Seller`,
                button_label: `Details`
            },

            TransactionsSearchFilter: {
                input_label: `Search transactions`,
                all_label: `All Active`,
                buyers_label: `Buyers`,
                sellers_label: `Sellers`,
                closed_label: `Closed`,
                archived_label: 'Archived',
                name_label: `Name`,
                address_label: `Address`
            },
            AdminControlBanner: {
                button_label: 'Admin controls',
                text1: 'Viewing ',
                text2: ' transactions'
            }
        },
        Organization: {
            none_found_label: `No members found!`,
            transactions_button_label: 'Transactions',
            OrganizationSearchFilter: {
                input_label: `Search organization`,
                all_label: `All Members`,
                active_label: `Active Members`,
                inactive_label: `Inactive Members`
            }
        },
        Page404: {
            page_title: `Oops 404!`,
            page_subtitle: `We can't seem to find the page you are looking for.`,
            startOver_button: `Start Over`
        }
    },

    es: {
        lang_name: `Spanish`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {
                new_connections_label: `Nuevos conexiones`,
                create_button_label: `Crear conexiones`,
                cancel_button_label: `Cancelar`
            },
            AddDocToTrxModal: {
                add_label: `Agregar a la transacción`,
                search_placeholder: `Transacciones de búsqueda`,
                select_all_label: `Seleccionar todo`,
                mls_label: `MLS#`,
                add_button_label: `Agregar documento`,
                cancel_button_label: `Cancelar`,
                already_exists_message: `Este documento ya existe en todas sus transacciones activas.`,
                no_active_message: `No tiene transacciones activas. Los documentos solo se pueden agregar a transacciones activas.`,
                transaction_button_label: `Nueva Transacción`,
                close_button_label: `Cerrar`
            },
            AddDocumentModal: {
                drag_click_label: 'Arrastre los archivos aquí o haga clic para cargar',
                upload_label: `Subir documento`,
                name_label: `Nombre: `,
                size_label: `Tamaño: `,
                add_title_label: `Agregar título `,
                title_placeholder: `Título`,
                doc_type_label: `Tipo de documento`,
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                add_button_label: `Agregar documentot`,
                cancel_button_label: `Cancelar`,
                create_pack_label: `Crear un paquete`,
                save_changes_label: `Guardar cambios`
            },
            AddTaskModal: {
                add_task_label: `Agregar una tarea`,
                add_task_tip: `La creación de una nueva tarea permite que la tarea se agregue opcionalmente a cualquier nueva transacción durante la creación inicial.`,
                trx_type_label: `Tipo De Transacción`,
                buying_label: `Comprar`,
                listing_label: `Listado`,
                task_title_label: `Título De La Tarea`,
                task_title_placeholder: `Título`,
                task_desc_label: `Descripción De La Tarea`,
                task_desc_placeholder: `Descripción`,
                default_expire_label: `Tiempo De Epiración Predeterminado`,
                days_label: `Dias`,
                hour_label: `Horas`,
                min_label: `Minutos`,
                ordinal_label: `AM/PM`,
                create_button_label: `Crear Tarea`,
                cancel_button_label: `Cancelar`
            },
            AppHeader: {},
            AvatarEditModal: {
                change_avatar_label: `Cambia tu avatar`,
                update_button_label: `Actualizar avatar`,
                cancel_button_label: `Cancelar`
            },
            ClickWrapper: {},
            CreatableSelectField: {
                add_custom_label: `Agregar fuente personalizada`
            },
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {
                title_placeholder: `Título`,
                description_placeholder: `Descripción`,
                date_time_placeholder: `Fecha y hora`,
                open_button_label: `Tarea Abierta`,
                complete_button_label: `Tarea Completada`,
                delete_button_label: `Eliminar Tarea`,
                update_button_label: `Tarea De Actualización`
            },
            Loader: {},
            MainHeader: {
                transactions_link: `Transacciones`,
                connections_link: `Conexiones`,
                pipeline_link: `Tubería`,
                forms_link: `Formularios`,
                documents_link: `Documentos`,
                profile_link: `Perfil`,
                preferences_link: `Preferencias`,
                messages_link: `Mensaje`,
                alerts_link: `Alertas`,
                organization_button_label: `Nueva Miembro`,
                transaction_button_label: `Nueva Transacción`,
                connection_button_label: `Nueva Conexión`,
                pipeline_button_label: `Nueva Tubería`,
                library_button_label: `Nueva Formo`
            },
            RemoveTaskModal: {
                remove_task_label: `Eliminar una Tarea`,
                assurance_label: `¿Estás seguro de que quieres eliminar esta tarea?`,
                delete_button_label: `Eliminar Tarea`,
                cancel_button_label: `Cancelar`
            },
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {
                home_link: `Inicio`,
                settings_link: `Ajustes`,
                messages_link: `Mensaje`,
                contacts_link: `Contactos de la empresa`,
                logout_link: `Cerrar sesión`
            },
            StateCountrySelect: {
                select_label: `Selección`
            },
            TextField: {},
            TransactionUploadModal: {
                image_label: `Imagen`,
                file_label: `Archivo`,
                attach_file_label: `adjunto`,
                add_title_label: `Añadir Título`,
                add_title_placeholder: `Título`,
                name_label: `Nombre: `,
                size_label: `Tamaño: `,
                attach_button_label: `Adjuntar`,
                attach_send_button_label: `Adjuntar y `,
                cancel_button_label: `Cancelar`
            },
            ConfirmationDialog: {
                /**
                 * Instruction:
                 * To add a new type of Confirmation Dialog you should add translations for all states in which
                 * dialog can be for this type or it will use common translation. To create specific one follow
                 * next naming rule: `{confirmationDialogType}_[title/text/confirmButtonText/cancelButtonText]`.
                 */
                Confirmation: {
                    // Common
                    title: `Estas seguro?`,

                    // Specific
                    cancel_text: `Todos los cambios se perderán.`,
                    delete_text: `Esta acción no se puede deshacer!`,
                    activateTrx_text: `Todas las partes ahora tendrán acceso a esta transacción.`,
                    reopenTrx_text: `Esta transacción ha sido cerrada, por favor confirme que desea volver a abrirla.`,
                    closeTrx_text: `Por favor, introduzca una fecha de cierre`,
                    archiveTrx_text: `Esta acción deshabilitará todas las ediciones y eliminará el acceso a todas las partes. Puede restablecer a Activo si es necesario en el futuro.`,
                    cleanForm_text: `Limpiará todos los campos`,
                    useConnection_text: `¿Deberían agregarse partes vinculadas a la nueva transacción?`,
                    removeSigner_text: `Esta acción no se puede deshacer!`,
                    removePrimarySigner_text: `Esta acción no se puede deshacer!`,
                    leaveAndJoinTeam_text: `Esta acción lo eliminará de su equipo actual y lo agregará al nuevo equipo; se notificará a todos los líderes del equipo.`,
                    leaveAsLeaderAndJoinTeam_text: `Esta acción eliminará a su equipo actual y a todos los miembros del equipo en el que es líder de equipo.`,
                    leaveCurrentTeam_text: `Estás dejando el equipo, el líder del equipo será notificado. Esta acción no se puede deshacer.`,
                    declineTeamInvite_text: `Confirme que no desea aceptar esta invitación. El líder del equipo será notificado.`,
                    deleteTeam_text: `Todos los miembros del equipo serán liberados y el equipo será eliminado. Esta acción no se puede deshacer.`,
                    deleteMemberFromTrx_text: `Eliminar a un miembro del equipo impedirá que acceda a la transacción. La actividad pasada del miembro permanecerá como parte de la transacción.`,
                    deleteMemberFromOrg_text: `¡Quieres eliminar completamente a esta miembro!`,
                    updateFieldsWithNewSigners_text: `¿Desea actualizar los campos del documento con nuevas configuraciones?`,

                    // Member Invites
                    inviteMemberSuccess_title: `¡En su camino!`,
                    inviteMemberFailure_title: `Uuups...`,
                    inviteMemberSuccess_text: `Su invitación fue enviada con éxito.`,
                    inviteMemberFailure_text: `Hubo un problema al enviar su invitación, inténtelo de nuevo más tarde.`,

                    // Accept/Decline Member Invites
                    acceptInviteSuccess_title: `¡Felicidades!`,
                    acceptInviteSuccess_text: `Estás listo. ¡Ahora comencemos a cerrar algunos tratos!`,
                    acceptInviteFailure_title: `Uuups...`,
                    acceptInviteFailure_text: `Algo salió mal. Es posible que deba ponerse en contacto con la Organización que lo invitó a reenviar la invitación. Lo siento por los inconvenientes ocasionados.`,
                    declineInvite_title: `¿Rechazar la invitación?`,
                    declineInvite_text: `Confirme que no desea aceptar esta invitación.`,
                    declineInviteSuccess_title: `¡Éxito!`,
                    declineInviteSuccess_text: `Ha rechazado esta invitación.`,
                    declineInviteFailure_title: `Uuups...`,
                    declineInviteFailure_text: `Algo salió mal. No es necesario realizar ninguna acción, pero hubo un problema al rechazar esta invitación. Esta invitación caducará eventualmente en caso de que el problema persista. Lo siento por los inconvenientes ocasionados.`,

                    // Buttons
                    // Common
                    confirmButtonText: `Confirmar!`,
                    cancelButtonText: `Cancelar`,
                    // Specific
                    delete_confirmButtonText: `Sí, bórralo!`,
                    cancel_confirmButtonText: `Sí, cancelar!`,
                    cancel_cancelButtonText: `No quedarse!`,
                    inviteMemberSuccess_confirmButtonText: `Continuar`,
                    inviteMemberFailure_confirmButtonText: `Continuar`,
                    acceptInviteSuccess_confirmButtonText: `Vamos`,
                    acceptInviteFailure_confirmButtonText: `Continuar`,
                    replaceValue_confirmButtonText: `Reemplazar`,
                    replaceValue_cancelButtonText: `Saltar`,
                    useConnection_confirmButtonText: `Agregar partes vinculadas`,
                    useConnection_cancelButtonText: `Saltar`,
                    leaveAndJoinTeam_confirmButtonText: `Estoy seguro`,
                    leaveAsLeaderAndJoinTeam_confirmButtonText: `Estoy seguro`,
                    leaveCurrentTeam_confirmButtonText: `¡Sí, deja el equipo!`,
                    updateFieldsWithNewSigners_confirmButtonText: `Actualizar`,

                    // Additional items
                    closeTrx_inputPlaceholder: `Seleccione una fecha`,
                    closeTrx_validationError: `Elija una fecha de cierre.`,
                    replaceValue_inputPlaceholder: `Aplicar para todos los campos`,
                    removeSigner_inputPlaceholder: `Elimina a la parte de toda la transacción.`
                },
                Loading: {
                    // Common
                    title: `Procesando...`,
                    text: `Espere por favor.`
                },
                Success: {
                    // Common
                    title: `Éxito!`,
                    text: `Tus cambios han sido actualizados.`,
                    confirmButtonText: `OK`,

                    // Specific
                    delete_title: `Eliminado!`,
                    delete_text: `El elemento seleccionado ha sido eliminado.`,
                    uploadDocument_title: `Subido!`,
                    uploadContact_text: `Tu contacto ha sido agregado.`,
                    uploadDocument_text: `Su documento ha sido subido.`,
                    uploadConnection_text: `Tus conexiones han sido subidos`,
                    createFormPack_text: `Se ha creado un nuevo paquete de formularios.`
                },
                Failed: {
                    // Common
                    title: `Uuups...`,
                    text: `Algo salió mal!`,
                    confirmButtonText: `OK`
                },
                ErrorCurrentPassword: {
                    // Common
                    title: `Uuups...`,
                    text: `¡La contraseña actual es incorrecta!`,
                    confirmButtonText: `OK`
                }
            },
            Dropdown: {
                delete_label: 'Eliminar',
                edit_label: 'Editar'
            },
            ActionButtons: {
                cancel_label: 'Cancelar',
                save_label: 'Guardar cambios'
            }
        },
        CompanyContacts: {
            ContactDetailsHistory: {},
            ContactList: { no_contacts_label: `¡No tienes contactos!` },
            ContactRow: { button_label: `Detalles` },
            ContactRowSkeleton: {},
            ContactsSearchFilter: {
                all_label: `Todos Contactos`,
                active_label: `Contactos Activos`,
                inactive_label: `Contactos Inactivos`,
                input_label: `Buscar contactos`
            }
        },
        ContactDetails: { button_label: `Enviar mensaje` },
        Constants: {
            errorMessage: {
                emailPasswordCombo: `La combinación de correo electrónico / contraseña es incorrecta. Puede optar por restablecer su contraseña haciendo clic en el enlace a continuación.`,
                emailNoUser: `Esta dirección de correo electrónico no pertenece a una cuenta de Jada. Cree una cuenta haciendo clic en el enlace a continuación.`,
                emailPrimaryLinkMatch: `La dirección de correo electrónico no puede ser la misma que la conexión Principal.`,
                emailIsAlreadyParty: `Este usuario ya es parte de la transacción.`,
                password: {
                    lowerCase: `Debe contener al menos una letra minúscula`,
                    upperCase: `Debe contener al menos una letra mayúscula`,
                    numeric: `Debe contener al menos un número`,
                    length: `Debe tener al menos 8 caracteres de longitud`,
                    required: `Porfavor ingrese una contraseña`
                },
                email: {
                    valid: `Por favor, introduce una dirección de correo electrónico válida`,
                    required: `Se requiere Dirección de correo electrónico`,
                    uniqSigner: `El usuario con este correo electrónico ya es un firmante de este documento`,
                    duplicateEmail: `Dirección de correo electrónico duplicada`
                },
                phone: {
                    valid: `Por favor ingrese un número de teléfono válido`,
                    required: `Se requiere el número de teléfono`
                },
                phoneExt: {
                    valid: `Ingrese una extensión válida`
                },
                address: {
                    required: `La dirección es necesaria`,
                    valid: `Por favor introduce una dirección válida`
                },
                firstName: {
                    valid: `Por favor, ingrese un nombre válido`,
                    required: `Se requiere el primer nombre`
                },
                middleInitial: {
                    valid: `Ingrese una inicial del segundo nombre válida`
                },
                lastName: {
                    valid: `Por favor ingrese un apellido válido`,
                    required: `Se requiere apellido`
                },
                fullName: {
                    valid: `Por favor ingrese un nombre completo válido`,
                    required: `Se requiere el nombre completo`
                },
                city: {
                    valid: `
                    Por favor introduzca una ciudad válida`,
                    required: `Ciudad es requerida`
                },
                state: {
                    required: `Estado requerido`
                },
                zip: {
                    required: `Se requiere el código postal`
                },
                country: {
                    required: `El país es obligatorio`
                },
                fromDate: {
                    required: `Se requiere la fecha de inicio`
                },
                toDate: {
                    required: `Se requiere la fecha de finalización`
                },
                listingPrice: {
                    required: `Se requiere el precio de lista`
                },
                commission: {
                    required: `Se requiere comisión`,
                    valid: `La comisión debe ser un número`,
                    min: `La comisión debe ser un número positivo`,
                    max: `La comisión no puede ser mayor que el precio`,
                    maxPercent: `El porcentaje de comisión no puede ser superior a 100`
                },
                split: {
                    required: `Se requiere la división del agente`,
                    valid: `La división del agente debe ser un número`,
                    min: `La división del agente debe ser un número positivo`,
                    max: `La división del agente no puede ser mayor que el precio`,
                    maxPercent: `El porcentaje de división del agente no puede ser superior a 100`
                },
                fee: {
                    required: `Se requiere tarifa de transacción`,
                    valid: `La tarifa de transacción debe ser un número`,
                    min: `La tarifa de transacción debe ser un número positivo`,
                    max: `La tarifa de transacción no puede ser mayor que el precio`,
                    maxPercent: `El porcentaje de la tarifa de transacción no puede ser superior a 100`
                },
                businessSrc: {
                    required: `Se requiere la fuente`
                },
                emailOrPhone: {
                    required: `Se requiere correo electrónico o teléfono`
                },
                firstOrLastName: {
                    required: `Se requiere nombre o apellido`
                },
                licNumber: {
                    required: `Se requiere número de licencia`
                },
                mlsId: {
                    required: `Se requiere identificación MLS`
                },
                mlsIdIfMls: {
                    required: `Se requiere una ID de MLS si se selecciona un MLS`
                },
                mlsIfMlsId: {
                    required: `Se requiere un MLS si se ingresa una ID de MLS`
                },
                permissions: {
                    required: `Se requiere selección de permisos`
                },
                accountType: {
                    required: `Se requiere tipo de cuenta`
                },
                signerType: {
                    required: `El tipo de firmante es obligatorio`
                },
                confirmSigners: {
                    maxBrokers: `Puede agregar como máximo dos corredores para firmar el documento`,
                    maxSalesAssociates: `Puede agregar como máximo dos asociados de ventas para firmar el documento`,
                    maxParties: `Puede agregar como máximo cuatro partes para firmar el documento`
                },
                duplicateMember: `El correo electrónico del miembro de la organización ya existe`
            },
            accountTypes: [
                {
                    id: 'administrator',
                    label: 'Administrador',
                    langAgnosticLabel: 'Administrator',
                    role: 'member',
                    sub_type: 'administrator',
                    value: 'Administrator'
                },
                {
                    id: 'assistant',
                    label: 'Asistente',
                    langAgnosticLabel: 'Assistant',
                    role: 'member',
                    sub_type: 'assistant',
                    value: 'Assistant'
                },
                {
                    id: 'sales_associate',
                    label: 'Asociado De Ventas',
                    langAgnosticLabel: 'Sales Associate',
                    role: 'member',
                    sub_type: 'sales_associate',
                    value: 'Sales Associate'
                },
                {
                    id: 'coordinator',
                    label: 'Coordinador',
                    langAgnosticLabel: 'Coordinator',
                    role: 'member',
                    sub_type: 'coordinator',
                    value: 'Coordinator'
                },
                {
                    id: 'broker',
                    label: 'Corredor',
                    langAgnosticLabel: 'Broker',
                    role: 'member',
                    sub_type: 'broker',
                    value: 'Broker'
                },
                {
                    id: 'owner',
                    label: 'Dueño',
                    langAgnosticLabel: 'Owner',
                    role: 'owner',
                    sub_type: 'owner',
                    value: 'Owner'
                }
            ],
            permissionTypes: [
                {
                    id: 'owner',
                    label: 'Dueño',
                    langAgnosticLabel: 'Owner',
                    value: 'owner'
                },
                {
                    id: 'default',
                    label: 'Predeterminado',
                    langAgnosticLabel: 'Default',
                    value: 'default'
                }
            ]
        },
        Connections: {
            AddNewConnection: {},
            ConnectionList: { no_connections_label: `¡No tienes conexiones!` },
            ConnectionMatches: {
                match_label: `Coincidencia Potencial`,
                incorrect_match_single: `Esta no es la coincidencia correcta.`,
                incorrect_match_multiple: `Estas no son las coincidencias correctas.`,
                select_match: `Seleccione`
            },
            ConnectionRow: { button_label: `Detalles` },
            ConnectionRowSkeleton: {},
            ConnectionProgress: {},
            ConnectionsSearchFilter: {
                input_label: `Buscar conexiones`
            },
            ExistingConnectionCheck: {
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`
            },
            NewConnectionForm: {
                first_name_label: `Nombre`,
                first_name_placeholder: `Nombre`,
                last_name_label: `Apellido`,
                last_name_placeholder: `Apellido`,
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre Legal`,
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`,
                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                mailing_address_same_label: `¿La misma dirección postal que la conexión principal?`,
                connection_notes_label: `Notas De Conexión`,
                connection_notes_placeholder: `Notas de conexión`,
                remove_connection_label: `Remover enlace`,
                add_another_connection_label: `Agregar otro conexión`,
                link_button: `Conexión de enlace`,
                primary_button: `Conexión principal`,
                add_party_text: `Agregar una parte vinculada`,
                add_party_subtext: `cónyuge, pareja, padre, etc..`,
                save_button: `Guardar`
            }
        },
        ConnectionDetails: {
            notes_heading: 'Notas',
            history_heading: 'Historia',
            status: {
                buyer: 'Comprador',
                seller: 'Vendedor'
            },
            button_label: 'Nueva transacción'
        },
        Dashboard: {},
        DateTime: { locale: Spanish },
        Documents: {
            DocumentCard: {
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                pack_label: ` Paquete`
            },
            DocumentsGrid: {
                no_documents_label: `¡No se encontraron documentos!`,
                create_pack_label: `Crear un paquete`
            },
            DocumentRow: {
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                pack_label: ` Paquete`,
                button_label: `Ver Documento`,
                pack_button_label: `Ver Paquete`,
                file_name_label: `Nombre del fichero`,
                upload_date_label: `Fecha de carga`,
                documents_pack_label: `Paquete de documentos`
            },
            DocumentsSearchFilter: {
                input_label: `Buscar documentos`,
                all_label: `Todos Documentos`,
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                dropdown_label: `Título del documento`
            },
            FillingDocument: {
                importing_text: `Importación de datos de transacciones...`,
                importing_subtext: `Veamos cuántos datos podemos recopilar para usted... ¡ahorrarle tiempo es nuestro objetivo!`,
                left_field_label: ` Campos obligatorios a la izquierda`,
                completed_field_label: `¡Todos los campos obligatorios completos!`,
                assign_label: `Asignar campo`,
                disabled_assigning_label: `No se permite asignar campos obligatorios con la configuración de llenado actual`,
                prev_button: `Anterior`,
                next_button: `Próximo`,
                settings_label: `Ajustes`,
                import_button: `Datos de importacion`,
                clean_button: `Borrar todos los datos`,
                saveAndSend_button: `Guardar y enviar`,
                saveAndClose_button: `Guardar borrador y cerrar`,

                replaceFieldValue: `Reemplace <b>prevValue</b> por <b>nextValue</b> en el campo <ins>fieldName</ins>`,
                reassignFieldFrom: `Reasignar <ins>fieldName</ins> campo de <b>prevValue</b> a <b>nextValue</b>`,
                reassignField: `Reasignar <ins>fieldName</ins> campo a <b>nextValue</b>`,

                SetupFilling: {
                    first_question: `¿Quién completará todos los campos requeridos para este documento?`,
                    second_question: `¿Quién tendrá que firmar este documento?`,
                    third_question: `Confirmar firmantes:`,
                    myself_label: `Mí mismo`,
                    myselfAndOthers_label: `Yo + Otras`,
                    others_label: `Solo otras`,
                    noSignatures_label: `No se necesitan firmas`,
                    forward_question: `Cuando las firmas estén completas:`,
                    forward_label: `Reenviar documento al agente de listado - `,
                    addSigner_label: `Agregar firmante`,
                    current_step: `Paso stepNumber de 3`,
                    go_back: `Regresa`,
                    save_settings: `Guardar ajustes`,
                    saveButton_label: `Guardar`,
                    signer_info: `Firmante solo para este documento.`,
                    legalName_placeholder: `Nombre legal completo`,
                    email_placeholder: `Dirección de correo electrónico`,
                    type_placeholder: `Tipo`,
                    signerTypes: {
                        party: 'Parte',
                        sales_associate: 'Asociado de ventas',
                        broker: 'Corredor',
                        other: 'Otro'
                    }
                },
                SigningDocument: {
                    consent_modalTitle: `Consentimiento a la Firma Electrónica`,
                    consent_modalContent: `Al aceptar, acepta firmar electrónicamente este documento y los términos de servicio de Jada Technologies, LLC. Se registrará una marca de tiempo y la dirección IP de su dispositivo para autenticar esta acción.`,
                    agree_buttonLabel: `Aceptar`,
                    disagree_buttonLabel: `Discrepar`,
                    finish_buttonLabel: `Acabado`,
                    start_label: `Comienzo`,
                    next_label: `Próximo`,
                    type_tabLabel: `Escribe`,
                    sign_tabLabel: `Señal`,
                    upload_tabLabel: `Subir`,
                    apply_button: `Aplicar`,
                    sign_label: `Firme abajo`,
                    initial_label: `Inicial abajo`,
                    uploadSignature_label: `Subir firma como .png`,
                    uploadInitials_label: `Subir iniciales como .png`,
                    dragClick_label: `Arrastre o haga clic para cargar una imagen.`,
                    next_buttonLabel: `Próximo`,
                    clean_buttonLabel: `Limpio`,
                    signature_label: `Firma`,
                    initials_label: `Iniciales`
                }
            },
            Tabs: {
                tabName: {
                    library: `Biblioteca de formularios`,
                    my_doc: `Mis documentos`,
                    forms_marketplace: `Mercado de formularios`
                },
                document_button_label: `Nuevo Documento`
            }
        },
        Landing: {
            title_1: `Bienvenido. Este podría ser el comienzo de algo `,
            title_2: `grande!`,
            button_label: `Empecemos`
        },
        Library: {},
        InvitationAcceptance: {
            next_label: 'Guardar y continuar',
            submit_label: 'Crear cuenta',
            cancel_label: 'Cancelar',
            skip_label: 'Saltar por ahora',
            back_label: 'Atras',
            decline_label: 'Rechazar invitación',
            step_one: {
                heading: 'Bienvenido a Jada!',
                secondary_text:
                    'Para configurar su cuenta, solo necesitamos un poco de información primero.',
                first_name_label: 'Primer nombre',
                first_name_placeholder: 'Primer nombre',
                last_name_label: 'Apellido',
                last_name_placeholder: 'Apellido',
                email_label: 'Dirección de correo electrónico',
                email_placeholder: 'ejemplo@mail.com',
                email_helper_text:
                    'Esto se puede cambiar más tarde en la configuración de su perfil.',
                account_type_label: 'Tipo de cuenta',
                account_type_placeholder: 'Seleccione tipo',
                phone_label: 'Número de teléfono móvil',
                phone_placeholder: 'Teléfono',
                password_label: 'Crear contraseña',
                password_placeholder: '********',
                length_helper_text: 'Al menos 8 caracteres por favor',
                uppercase_helper_text: 'Agregar nuevos tipos al proyecto',
                number_helper_text: 'Añade un número para más seguridad',
                symbol_helper_text: 'Utilizar una # & ! % $ o * para hacerlo súper seguro'
            },
            step_two: {
                heading: '¡Casi ahí!',
                secondary_text:
                    'Complete la información de su perfil ahora o puede hacerlo más tarde en la configuración.',
                profile_picture_label: 'Foto de perfil',
                upload_label: 'subir',
                about_label: 'Un poco sobre ti',
                about_placeholder: 'Escriba aquí...',
                about_helper_text: 'Esta información se verá en tu perfil público.',
                mls_id_label: 'ID de MLS/Número de miembro',
                mls_id_placeholder: 'MLS ID',
                lic_number_label: 'Número de licencia estatal',
                lic_number_placeholder: 'Licencia',
                mls_label: 'MLS/Nombre de la Junta',
                mls_placeholder: 'Seleccione uno',
                helper_text:
                    'Todo aquí se puede actualizar en la configuración de su perfil',
                file_size_warning: `El archivo es demasiado grande. Elija un archivo de 2 MB o menos.`
            }
        },
        InvitationConfirmation: {
            ConfirmInterface: {
                confirm_heading: `¡Confirmado!`,
                confirm_description_1: `Ha agregado `,
                confirm_description_2: ` como una nueva organización a su cuenta. Ahora puede cambiar entre organizaciones en su configuración.`,
                confirm_button_label: `Ir al panel de control`
            }
        },
        InvitationError: {
            ErrorInterface: {
                exit_button_label: `Salida`,
                confirm_button_label: `Ir al panel de control`,
                expired_heading: `¡Invitación Caducada!`,
                expired_description: `Lamentablemente, esta invitación ya no es válida o ha caducado. Comuníquese con la organización que lo invitó a reenviar la invitación.`,
                invalid_email_heading: `Inválido Usuario ¡Cuenta!`,
                invalid_email_description: `Para aceptar correctamente esta invitación, debe iniciar sesión en su cuenta de Jada con la dirección de correo electrónico a la que se entregó esta invitación.`
            }
        },
        TrxInvitationAcceptance: {
            submit_label: 'Crear una cuenta',
            heading: '¡Bienvenido a Jada!',
            secondary_text:
                'Complete la configuración de su cuenta para acceder a su nueva transacción.',
            TrxInvRegistrarionForm: {
                first_name_label: 'Primer nombre',
                first_name_placeholder: 'Primer nombre',
                last_name_label: 'Apellido',
                last_name_placeholder: 'Apellido',
                full_name_label: 'Nombre legal completo',
                full_name_placeholder: 'Nombre legal completo',
                email_label: 'Dirección de correo electrónico',
                email_placeholder: 'example@mail.com',
                email_helper_text:
                    'Esto se puede cambiar más tarde en la configuración de su perfil.',
                phone_label: 'Número de teléfono móvil',
                phone_placeholder: 'Teléfono',
                password_label: 'Crear contraseña',
                password_placeholder: '********',
                length_helper_text: 'Al menos 8 caracteres por favor',
                uppercase_helper_text: 'Al menos una letra mayuscula',
                number_helper_text: 'Añade un número para más seguridad',
                symbol_helper_text:
                    'Utilizar una # & ! % $ o * para que sea súper seguro',
                profile_picture_label: 'Foto de perfil',
                upload_label: 'subir',
                file_size_warning: `El archivo es demasiado grande. Elija un archivo de 2 MB o menos.`
            }
        },
        Login: {
            title_1: `Bienvenido `,
            title_2: `de nuevo`,
            sub_title: `Entre para acceder a su cuenta.`,
            email_label: `CORREO ELECTRÓNICO`,
            email_placeholder: `Correo electrónico`,
            password_label: `CONTRASEÑA`,
            forgot_password: `¿Olvidó su contraseña?`,
            no_account: `¿No tienes una cuenta? `,
            no_account_link: `Aprenda más`,
            button_label: `Vamos`
        },
        Messages: {
            title: 'Centro de mensajes',
            msg_tab_title: 'Mensaje',
            pinned_msg_tab_title: 'Mensajes anclados',
            drafts_tab_title: 'Reclutar',
            MessagesTab: {
                new_msg_title: `Nuevo mensaje`,
                bulk_action_btn_label: `Acciones masivas`,
                pinn_btn_label: `Alfiler`,
                delete_btn_label: `Borrar`,
                compose_btn_label: `Componer`,
                no_msg_found: `No se han encontrado mensajes!`
            },
            MessageModal: {
                new_draft_msg_title: `Nuevo borrador`,
                new_msg_title: `Nuevo mensaje`,
                placeholder_to: `a`,
                placeholder_msg: `Mensaje...`,
                placeholder_new_msg: `Nuevo mensaje`,
                placeholder_reply: `Respuesta...`,
                btn_cancel: `Cancelar`,
                btn_draft: `Reclutar`,
                btn_send: `Enviar`,
                btn_save: `Ahorrar`,
                btn_delete: `Borrar`,
                btn_reply: `Respuesta`,
                no_reply_label: `Ninguna respuesta`,
                select_all_members: `Todos los miembros`
            }
        },
        NewConnection: {
            title: `¡Sí! Hacer conexiones.`,
            sub_text: `Comencemos con lo básico para verificar si su nuevo contacto ya tiene una cuenta. De lo contrario, podrá invitarlos o ingresar su información manualmente.`,
            party_title: `Agregar una nueva persona al trato.`,
            party_sub_text: `Verifiquemos si el nuevo partido ya tiene una cuenta. Si no, los invitaremos a la transacción automáticamente.`
        },
        NewDocument: {
            back_button_label: 'Regresa'
        },
        NewTransaction: {
            BuyerFlowStep1: {
                first_name_label: `Nombre`,
                first_name_placeholder: `Nombre`,
                middle_initial_label: `MI`,
                last_name_label: `Apellido`,
                last_name_placeholder: `Apellido`,
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre legal completo`,
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `¿La dirección postal es la misma que la anterior?`,
                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                add_party_label: `Agregar otra parte a la transacción`,
                remove_party_label: `Eliminar a la parte de la transacción`,
                next_button_label: `Próximo`,
                modal_text: `¿Deberían agregarse partes vinculadas a la nueva transacción?`
            },
            BuyerFlowStep2: {
                closing_date_label: `Fecha De Cierre`,
                closing_date_placeholder: `Fecha de cierre`,
                purchase_price_label: `Precio De Compra`,
                purchase_price_placeholder: `Precio`,
                offer_price_label: `Precio De Oferta`,
                offer_price_placeholder: `Precio`,
                business_src_label: `Fuente De Negocio`,
                business_src_placeholder: `Selección`,
                upload_img_label: `Subir imagenes`,
                drag_drop_label: `Explore su dispositivo y cargue imágenes`,
                max_size_label: `El tamaño máximo del archivo es 2 MB`,
                img_optional_label: `Esto es opcional. Tomaremos más imágenes de MLS más tarde.`,
                back_button_label: `Atrás`,
                next_button_label: `Próximo`
            },
            BuyerFlowStep3: {
                commission_label: `Comisión De Compra`,
                commission_placeholder: `0`,
                split_label: `División De Agentes`,
                split_placeholder: `0`,
                fee_label: `Tarifa De Transacción`,
                fee_placeholder: `0`,
                forms_label: `Formularios iniciales`,
                tasks_label: `Tareas iniciales`,
                back_button_label: `Atrás`,
                done_button_label: `Hecho`
            },
            BuyerSelectForm: {
                select_label: `Selección`,
                property_info_label: `Información de la propiedad`,
                mls_option_label: `Ingrese el número MLS.`,
                address_option_label: `¿No estás en la MLS? Ingrese la dirección.`,
                property_address_label: `Dirección de Propiedad`,
                address_placeholder: `Empezar a escribir dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                mls_number_label: `Número MLS`,
                mls_number_placeholder: `Engrese un MLS ID`,
                property_found_label: `Propiedad encontrada!`,
                property_not_found_label: `Propiedad no encontrada.`,
                button_label: `Vamos`,
                mls_button_label: `Encontrar`
            },
            BuyerTransactionFlow: {},
            FormHeader: {
                step_1_label: `Partes`,
                step_2_label: `Detalles`,
                step_3_label: `Configurar`
            },
            ListingFlowStep1: {
                first_name_label: `Nombre`,
                first_name_placeholder: `Nombre`,
                middle_initial_label: `MI`,
                last_name_label: `Apellido`,
                last_name_placeholder: `Apellido`,
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre legal completo`,
                email_address_label: `Correo Electrónico`,
                email_address_placeholder: `Correo electrónico`,
                phone_number_label: `Número De Teléfono`,
                phone_number_placeholder: `Número de teléfono`,
                extension_label: `EXT.`,
                extension_placeholder: `Ext.`,
                mailing_address_same_label: `¿La dirección postal es la misma que la dirección de la lista?`,
                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                add_party_label: `Agregar otra parte a la transacción`,
                remove_party_label: `Eliminar a la parte de la transacción`,
                next_button_label: `Próximo`,
                modal_text: `¿Deberían agregarse partes vinculadas a la nueva transacción?`
            },
            ListingFlowStep2: {
                listing_start_label: `Fecha De Inicio De La Lista`,
                listing_start_placeholder: `De`,
                listing_expiration_label: `Fecha De Caducidad`,
                listing_expiration_placeholder: `Para`,
                listing_price_label: `Precio De Lista`,
                listing_price_placeholder: `Precio`,
                business_src_label: `Fuente De Negocio`,
                business_src_placeholder: `Selección`,
                upload_img_label: `Subir imagenes`,
                drag_drop_label: `Explore su dispositivo y cargue imágenes`,
                max_size_label: `El tamaño máximo del archivo es 2 MB`,
                img_optional_label: `Esto es opcional. Tomaremos más imágenes de MLS más tarde.`,
                back_button_label: `Atrás`,
                next_button_label: `Próximo`
            },
            ListingFlowStep3: {
                commission_label: `Comisión De Venta`,
                commission_placeholder: `0`,
                split_label: `División De Agentes`,
                split_placeholder: `0`,
                fee_label: `Tarifa De Transacción`,
                fee_placeholder: `0`,
                forms_label: `Formularios iniciales`,
                tasks_label: `Tareas iniciales`,
                back_button_label: `Atrás`,
                done_button_label: `Hecho`
            },
            ListingSelectForm: {
                select_label: `Selección`,
                property_info_label: `Información de la propiedad`,
                manual_option_label: `¿Dirección no encontrada? Introdúzcalo manualmente.`,
                mls_option_label: `¿Tienes un número MLS?`,
                address_option_label: `¿Tienes una dirección?`,
                address_label: `Búsqueda de direcciones`,
                property_address_label: `Dirección de Propiedad`,
                address_placeholder: `Empezar a escribir dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,
                description_label: `Descripción de Lote/Tierra`,
                description_placeholder: `Descripción`,
                mls_number_label: `Número MLS`,
                mls_number_placeholder: `Engrese un MLS ID`,
                property_found_label: `Propiedad encontrada!`,
                property_not_found_label: `Propiedad no encontrada.`,
                or_label: `O`,
                cancel_link: `Cancelar`,
                button_label: `Vamos`,
                mls_button_label: `Encontrar`
            },
            ListingTransactionFlow: {},
            NewTransactionHeader: { back_button_label: `Regresa` },
            TransactionTypeSelect: {
                title_label: `¡Woohoo! Hagámoslo.`,
                listing_button_label: `Listado`,
                buyer_button_label: `Comprador`
            }
        },
        PdfViewer: {
            ControllerHeader: {
                fillable_button_label: `Hacer Rellenable`,
                edit_button_label: `Editar campos`,
                add_trx_button_label: `Agregar a la Transacción`,
                edit_pack_button_label: `Editar Paquete`
            },
            ViewController: {
                of_label: `de`
            }
        },
        Pipeline: {
            PipelineList: { no_pipeline_label: `¡Tu tubería está vacía!` },
            PipelineRow: { button_label: `Detalles` },
            PipelineRowSkeleton: {},
            PipelineSearchFilter: { input_label: `Tubería de búsqueda` },
            NewPipelineForm: {
                first_name_label: 'Primer Nombre',
                first_name_placeholder: 'Primer Nombre',
                last_name_label: 'Apellido',
                last_name_placeholder: 'Apellido',
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre Legal`,
                address_label: 'Dirección Actual',
                address_placeholder: 'Calle Principal 123',
                unit_label: 'unidad, apartamento, suite',
                city_label: 'Ciudad',
                city_placeholder: 'Ciudad',
                state_label: 'Estado',
                state_placeholder: 'Estado',
                zip_label: 'Código postal',
                zip_placeholder: 'Postal',
                email_label: 'Correo electrónico',
                email_placeholder: 'Correo electrónico',
                phone_label: 'Teléfono',
                phone_placeholder: 'Teléfono',
                save_label: 'Ahorrar',
                cancel_label: 'Cancelar'
            }
        },
        Profile: {},
        Settings: {
            save_label: 'Guardar cambios',
            cancel_label: 'Cancelar',
            create_label: 'Crear Equipo',
            delete_label: 'Eliminar Equipo',
            leave_label: 'Abandonar Equipo',
            accept_label: 'Aceptar',
            decline_label: 'Rechazar',
            remove_label: 'Remover',
            SettingsMenu: {
                app_integratons_link: `App Integrations`,
                billing_link: `Billing`,
                organization_info_link: `Organization Info`,
                organization_members_link: `Mi Organización`,
                personal_info_link: `Personal Info`,
                preferences_link: `Preferences`,
                security_settings_link: `Security Settings`,
                team_link: `Mi Equipo`,
                edit_label: `editar`,
                file_size_warning: `El archivo es demasiado grande. Elija un archivo de 2 MB o menos.`
            },
            OrganizationInfo: {
                heading: 'Información de la organización',
                name_label: 'Nombre de la oficina',
                status_label: 'Estado',
                type_label: 'Tipo de oficina',
                addOffice_label: 'Agregar oficina',
                addOrg_label: 'Agregar organización',
                addPhone_label: 'Añadir teléfono',
                officeStatuses: {
                    active_label: 'Activo',
                    inactive_label: 'Inactivo'
                },
                officeTypes: {
                    main_label: 'Principal',
                    branch_label: 'Rama',
                    satellite_label: 'Satélite'
                },
                office: {
                    labels: {
                        officeLogo: 'Logotipo de la oficina',
                        officeLogo_tooltip:
                            'Para mejores resultados, el archivo del logotipo debe ser ya sea un .png o .svg con un minimo altura de 98px.',
                        officeName: 'Nombre de la oficina',
                        officeAddress: 'Dirección de la oficina',
                        officeMLS: 'ID de MLS de la oficina',
                        officePhone: 'Teléfono',
                        uploadPhoto: 'Subir foto',
                        remove: 'Eliminar'
                    },
                    placeholders: {
                        enterNumber: 'Ingresar número',
                        enterId: 'Ingrese identificación',
                        enterName: 'Ingrese el nombre de la oficina',
                        enterPhone: 'Introduzca el número de teléfono',
                        enterAddress: 'Ingresa la direccion'
                    },
                    phoneTypes: {
                        office: 'oficina',
                        mobile: 'móvil',
                        phone: 'teléfono',
                        work: 'trabajo',
                        fax: 'fax'
                    }
                }
            },
            OrganizationMembers: {
                main_title: 'Mi Organización',
                header: {
                    add_member_label: 'Añadir miembro'
                },
                info_text:
                    'Complete el siguiente formulario para invitar a un nuevo miembro a su organización.',
                name_label: 'Nombre',
                first_name_label: 'Primer Nombre',
                first_name_placeholder: 'Primer Nombre',
                last_name_label: 'Apellido',
                last_name_placeholder: 'Apellido',
                email_label: 'Correo Electrónico',
                email_placeholder: 'Correo Electrónico',
                account_type_label: 'Tipo De Cuenta',
                account_type_placeholder: 'Seleccione Tipo',
                account_permissions_label: 'Permisos De Cuenta',
                account_permissions_placeholder: 'Seleccionar Permisos',
                invite_button_label: 'Invitar miembro',
                match_label: `Coincidencia Potencial`,
                incorrect_match_single: `Esta no es la coincidencia correcta.`,
                incorrect_match_multiple: `Estas no son las coincidencias correctas.`,
                select_match: `Seleccione`,
                send_invite_button_label: 'Enviar invitación',
                cancel_invite_button_label: `Cancelar`,
                pending_invites_label: `Invitaciones pendientes`,
                my_invites_title: `Mis invitaciones`,
                remove_org_member: `Quitar miembro`,
                back_button_label: `Volver a Mi Organización`,
                invitationStatuses: {
                    pending: 'Pendiente',
                    accepted: 'Aceptado',
                    declined: 'Rechazado',
                    expired: 'Caducado'
                },
                none_found_label: `¡No se han encontrado invitaciones!`
            },
            PersonalInfo: {
                main_title: 'Información personal',
                lic_number: 'Número de licencia',
                mls_id: 'MLS id',
                tooltip_mls_id:
                    'Su ID de MLS solo se utilizará para mantener sus listados de MLS sincronizados con sus transacciones en Jada',
                name_label: 'Nombre',
                email_label: 'Correo electrónico',
                phone_label: 'Teléfono',
                about_label: 'Sobre',
                add_phone_label: 'Añadir teléfono',
                placeholder_licence: 'Introduzca su número de licencia',
                placeholder_mls_id: 'Introduzca su identificación',
                placeholder_first_name: 'Introduzca su nombre de pila',
                placeholder_last_name: 'Introduzca su apellido',
                placeholder_email: 'Introduzca su correo electrónico',
                placeholder_phone: 'Introduzca su número',
                placeholder_editor: 'Introduzca el texto',
                dropdown_title: 'Móviles',
                dropdown_item_office: 'Oficina',
                dropdown_item_mobile: 'Móviles',
                dropdown_item_fax: 'Fax',
                modal_title: 'Confirme su contraseña',
                modal_password_placeholder: 'Introduzca su contraseña',
                modal_button_cancel: 'Cancelar',
                modal_button_save: 'Guardar cambios'
            },
            SecuritySettings: {
                main_heading: 'Configuraciones de seguridad',
                info_text: 'Actualice su contraseña para acceder a la cuenta.',
                newPass_label: 'Nueva contraseña',
                repeat_label: 'Reescriba nueva contraseña',
                save_label: 'Guardar cambios',
                cancel_label: 'Cancelar',
                current_password_label: 'Contraseña actual'
            },
            AppIntegrations: {},
            Billing: {},
            Preferences: {
                title: 'Preferencias',
                Tabs: {
                    names: {
                        name1: 'Notificaciones',
                        name2: 'Documentos',
                        name3: 'Tareas',
                        name4: 'Idiomas'
                    }
                },
                Notifications: {
                    options: {
                        client_invite: 'Se acepta la invitación del cliente',
                        new_task: 'Se ha creado una nueva tarea.',
                        docs_signed: 'Los documentos han sido firmados',
                        offer_made: 'Se hace una oferta'
                    },
                    tips: 'Reciba una notificación por correo electrónico cuando se produzca una nueva actividad en sus transacciones. Estas opciones también se pueden configurar para transacciones individuales.'
                },
                Documents: {
                    tip_note: {
                        tip1: 'Agregar documentos predeterminados aquí le brinda la opción de agregarlos rápidamente al crear una nueva transacción, ¡lo que le permite ahorrar tiempo!'
                    },
                    buttons: {
                        button1: '+ Docs del comprador',
                        button2: '+ Docs del vendedor'
                    },
                    modal: {
                        search: 'Buscar',
                        title1: 'Agregar documentos del comprador',
                        title2: 'Agregar documentos del vendedor',
                        buttons: {
                            cancel: 'Cancelar',
                            update: 'Actualizar'
                        }
                    }
                },
                Tasks: {
                    tip_note:
                        'Agregar tareas predeterminadas aquí le brinda la opción de agregarlas rápidamente al crear una nueva transacción, ¡lo que le permite ahorrar tiempo!',
                    buttons: {
                        button1: '+ Tareas del comprador',
                        button2: '+ Tareas del vendedor'
                    },
                    modal: {
                        newCaptionBuyer: 'Nuevo tarea de comprador',
                        editCaptionBuyer: 'Editar tarea del comprador',
                        newCaptionSeller: 'Nuevo tarea de vendedor',
                        editCaptionSeller: 'Editar tarea del vendedor',
                        placeholders: {
                            title: 'Título de la tarea',
                            description: 'Descripción de la tarea'
                        },
                        labels: {
                            days: 'Días desde nuevo',
                            time: 'Tiempo de expiración'
                        },
                        buttons: {
                            cancel_button: 'Cancelar',
                            save_button: 'Guardar cambios',
                            add_button: 'Agregar',
                            update_button: 'Actualizar'
                        }
                    }
                },
                Languages: {
                    tips: {
                        tip1: 'Agregar idiomas aquí le permitirá alternar rápidamente entre su idioma predeterminado y otros que domina.',
                        tip2: 'Mover el idioma en el orden de prioridad.',
                        tip3: '¡Más idiomas próximamente!'
                    },
                    default_status: 'defecto'
                }
            },
            Team: {
                main_title: 'Mi Equipo',
                invite_title: 'Miembro Invitado',
                no_available_invite:
                    'No tienes ningún miembro de la organización disponible.',
                no_team:
                    'Aún no tienes un equipo. ¡Cree uno e invite a los miembros a unirse!',
                header: {
                    add_member_label: 'Añadir miembro',
                    add_team_label: 'Crear equipo',
                    invite_label: 'Miembro invitado'
                },
                newTeam: {
                    add_team_label: 'Agregar nuevo equipo'
                },
                teamList: {
                    name_label: 'Nombre',
                    status_label: 'Estado',
                    type_label: 'Tipo de cuenta',
                    pending_member: `miembros pendiente`,
                    statuses: {
                        active_label: 'Activo',
                        inactive_label: 'Inactivo'
                    }
                },
                myInvites_title: `Mis invitaciones`,
                back_button_label: `Volver a Mi Equipo`,
                no_invitations_label: `You don't have any invitation yet`,
                pending_invites_label: `Invitaciones pendientes`,
                invitationStatuses: {
                    pending: 'Pendiente',
                    accepted: 'Aceptado',
                    declined: 'Rechazado',
                    abandoned: 'Abandonado',
                    expired: 'Caducado'
                }
            }
        },
        TransactionDetails: {
            ActivityMessages: {
                add_document: `Añadido un nuevo documento: `,
                remove_document: `Eliminado un documento: `,
                change_trx_status: `Cambió el estado de la transacción a `,
                new_transaction: `¡Felicitaciones por su nueva transacción! Siéntase libre de agregar documentos y crear tareas si aún no lo ha hecho. ¡Esperamos verlos a todos en la línea de meta!`,
                add_task: `Se agregó una nueva tarea: `,
                complete_task: `Estado de la tarea actualizado a Completado: `,
                open_task: `Se actualizó el estado de una tarea a Abierto: `,
                updated_task: `Actualización de una tarea: `,
                deleted_task: `Eliminado una tarea: `,
                update_address: `Dirección de propiedad actualizada a `
            },
            ActivityInput: {
                added_task_label: `Añadida una nueva tarea: `,
                upload_a_img_label: `Subió una imagen: `,
                upload_a_file_label: `Subió un archivo: `,
                image_label: `Imagen`,
                file_label: `Archivo`,
                whats_up_placeholder: `Qué esta pasando...`,
                task_title_placeholder: `Título De La Tarea`,
                task_note_placeholder: `Nota De Tarea`,
                date_time_placeholder: `Fecha y hora`,
                attachment_button_label: `Adjunto`,
                task_button_label: `Tarea`,
                post_button_label: `Publicar`,
                add_task_label: `Agregar tarea`,
                uploaded_label: `subido:`
            },
            ActivityThread: {
                no_messages_label: `Aún no hay mensajes.`
            },
            AddSearch: {
                all_label: `Todos`,
                buyer_label: `Comprador`,
                seller_label: `Vendedor`,
                other_label: `Otro`,
                add_label: `Agregar`,
                search_placeholder: `Buscar`
            },
            DetailsHeader: {
                TransactionStatus: {
                    active: 'Activo',
                    pending: 'Pendiente',
                    closed: 'Cerrado',
                    archived: 'Archivado'
                },
                edit_transaction: 'Editar transacción'
            },
            DocumentDrop: {
                drag_drop_label: `Arrastre los archivos aquí o haga clic para cargar`,
                add_label: `Agregar`,
                last_update_label: `Última actualización`
            },
            DocumentList: {
                view_label: `Ver`,
                download_label: `Descargar`,
                archive_label: `Archivo`,
                fill_label: `Rellenar Campos`,
                document_status: {
                    notStarted: `No empezado`,
                    draft: `Reclutar`,
                    pendingSignatures: `Firmas pendientes`,
                    completed: `Terminado`
                }
            },
            Documents: {},
            DocumentView: {
                of_label: `de`
            },
            NoteDrop: {
                note_title_placeholder: `Título de la nota`,
                note_content_placeholder: `Contenido de la nota`,
                private_label: `Privado`,
                add_note_button_label: `Añadir Nota`
            },
            NoteList: {
                assurance_label: `¿Estás seguro de que quieres eliminar esta nota?`,
                remove_label: `Eliminar`,
                cancel_label: `Cancelar`
            },
            Notes: {},
            TabNavigation: {
                activity_label: `Actividad`,
                documents_label: `Documentos`,
                notes_label: `Notas`
            },
            Tasks: {
                all_tasks_button_label: `Todas Tareas`,
                less_tasks_button_label: `Menos Tareas`,
                hide_history_button_label: `Ocultar Historial Tareas`,
                show_history_button_label: `Mostrar Historial Tareas`,
                add_task_button_label: `Agregar una Tarea`,
                no_tasks_label: `Sin tareas programadas`
            },
            TransactionInterface: {},
            TransactionMeta: {
                seller_agent_label: `Agente del Vendedor`,
                buyer_agent_label: `Agente del Comprador`,
                days_market_label: `Días en el mercado`,
                closing_in_label: `Cerrando en`,
                days_label: `días`,
                no_close_date_label: `Sin fecha de cierre`,
                opposing_label: `Miembros opuestos`,
                team_label: `Miembros del equipo:`,
                seller_label: `Vendedor`,
                buyer_label: `Comprador`,
                primary_agent_label: `Agente del comprador`,
                primary_broker_label: `Corredor del comprador`,
                btn_save_label: `Guardar cambios`,
                tab_team_label: `Mi equipo`,
                tab_office_label: `Oficina`,
                search_placeholder: `Buscar miembro`,
                first_name_label: 'Primer Nombre',
                first_name_placeholder: 'Primer Nombre',
                last_name_label: 'Apellido',
                last_name_placeholder: 'Apellido',
                legal_name_label: `Nombre Legal`,
                legal_name_placeholder: `Nombre Legal Completo`,
                email_label: 'Correo electrónico',
                email_placeholder: 'Correo electrónico',
                type_placeholder: `Tipo`,
                signerTypes: {
                    party: 'Parte',
                    sales_associate: 'Asociado de ventas',
                    broker: 'Corredor',
                    other: 'Otro'
                },
                primary_label: 'Primario',
                new_opposing_label: 'Nuevo miembro opositor',
                add_member_label: 'Añadir miembro',
                phone_label: 'Teléfono',
                phone_placeholder: 'Teléfono',
                brokerage_label: 'Corretaje',
                brokerage_placeholder: 'Comience a escribir para encontrar corretaje...',
                add_opposing_button: 'Agregar miembro opuesto'
            },
            EditTransactionDetails: {
                details_tab: `Detalles`,
                parties_tab: `Fiestas`,
                importedData_tab: `Datos importados`,
                property_tab: `Propiedad`,
                property_info: `Cambiar el número de MLS o la dirección de la propiedad es un cambio global y actualizará todos los datos de transacciones previamente almacenados. Todos los documentos con los datos de propiedad actuales serán archivados.`,
                price_label: `Precio`,
                offer_price_label: `Precio de oferta`,
                closingDate_label: `Fecha de cierre`,
                first_name_label: `cliente nombre`,
                last_name_label: `cliente apellido`,
                full_name_label: `cliente nombre completo`,
                view_connection_label: `Ver conexiónn`,
                view_connection_description: `Para actualizar otros detalles`,

                add_contact: `Agregar como contacto`,
                add_party: `Agregar una nueva fiesta`,
                remove_label: `Remover`,
                invite_label: `Invitar`,
                reinvite_label: `Re invitar`,

                business_src_label: `Fuente comercial`,
                business_src_placeholder: `Seleccione`,
                commission_label: `Comisión de venta`,
                commission_placeholder: `0`,
                split_label: `División de agente`,
                split_placeholder: `0`,
                fee_label: `Tarifa de transacción`,
                fee_placeholder: `0`,

                address_label: `Direccion Actual`,
                address_placeholder: `Dirección`,
                city_label: `Ciudad`,
                city_placeholder: `Ciudad`,
                unit_label: `Unidad`,
                unit_placeholder: `Apto, Suite, Unidad`,
                state_label: `Estado`,
                zip_label: `Código Postal`,
                zip_placeholder: `Código postal`,

                mls_number_label: `Número MLS`,
                mls_number_placeholder: `Engrese un MLS ID`,
                property_found_label: `Propiedad encontrada!`,
                property_not_found_label: `Propiedad no encontrada.`,
                mls_button_label: `Encontrar`
            },
            EditMappingData: {
                all_buyers: 'Todos los compradores',
                all_sellers: 'Todos los vendedores',
                buyer_address: 'Dirección del comprador',
                buyer_address_1: 'Línea de dirección del comprador 1',
                buyer_address_2: 'Línea de dirección del comprador 2 (n.° de unidad)',
                buyer_address_city: 'Comprador Dirección Ciudad',
                buyer_address_state: 'Estado de la dirección del comprador',
                buyer_address_zip: 'Código postal de la dirección del comprador',
                buyer_1: 'Comprador 1',
                buyer_2: 'Comprador 2',
                buyer_3: 'Comprador 3',
                buyer_4: 'Comprador 4',
                buyer_1_signature: 'Comprador 1 Firma',
                buyer_2_signature: 'Comprador 2 Firma',
                buyer_3_signature: 'Comprador 3 Firma',
                buyer_4_signature: 'Comprador 4 Firma',
                buyer_agent_1: 'Agente comprador 1',
                buyer_agent_lic_1: 'Licencia de agente comprador 1',
                buyer_agent_signature_1: 'Firma del agente comprador 1',
                buyer_agent_2: 'Agente comprador 2',
                buyer_agent_lic_2: 'Licencia de agente comprador 2',
                buyer_agent_signature_2: 'Firma del agente comprador 2',
                buyer_broker_1: 'Corredor comprador 1',
                buyer_broker_lic_1: 'Comprador Broker 1 Licencia',
                buyer_broker_signature_1: 'Comprador Corredor 1 Firma',
                buyer_broker_2: 'Corredor comprador 2',
                buyer_broker_lic_2: 'Licencia de agente comprador 2',
                buyer_broker_signature_2: 'Comprador Corredor 2 Firma',
                buyer_brokerage: 'Corretaje de compras',
                buyer_brokerage_address: 'Dirección de corretaje de compra',
                buyer_brokerage_address_1: 'Línea de dirección de corretaje de compra 1',
                buyer_brokerage_address_2:
                    'Línea de dirección de corretaje de compra 2 (n.° de unidad)',
                buyer_brokerage_address_city: 'Dirección de corretaje de compra Ciudad',
                buyer_brokerage_address_state: 'Dirección de corretaje de compra Estado',
                buyer_brokerage_address_zip:
                    'Dirección de corretaje de compra Código postal',
                buyer_brokerage_email: 'Correo electrónico de corretaje de compras',
                buyer_brokerage_fax: 'Compra de fax de corretaje',
                buyer_brokerage_mls: 'Comprador Corretaje MLS ID',
                buyer_brokerage_phone: 'Compra de teléfono de corretaje',
                buyer_closing_rep_address:
                    'Dirección del representante de cierre del comprador',
                buyer_closing_rep_address_1:
                    'Línea 1 de la dirección del representante de cierre del comprador',
                buyer_closing_rep_address_2:
                    'Línea 2 de la dirección del representante de cierre del comprador',
                buyer_closing_rep_city:
                    'Dirección del representante de cierre del comprador Ciudad',
                buyer_closing_rep_state:
                    'Estado de la dirección del representante de cierre del comprador',
                buyer_closing_rep_zip:
                    'Dirección del representante de cierre del comprador Código postal',
                buyer_closing_rep_email:
                    'Correo electrónico del representante de cierre del comprador',
                buyer_closing_rep_fax: 'Fax del representante de cierre del comprador',
                buyer_closing_rep_name:
                    'Nombre del representante de cierre del comprador',
                buyer_closing_rep_phone:
                    'Teléfono del representante de cierre del comprador',
                closing_date: 'Fecha de cierre',
                county: 'Condado',
                legal_description: 'Descripción legal',
                listing_start_date: 'Fecha de inicio del listado',
                listing_expiration_date: 'Fecha de finalización del listado',
                offer_date: 'Fecha de oferta',
                property_address: 'Dirección de Propiedad',
                property_address_1: 'Línea 1 de la dirección de la propiedad',
                property_address_2: 'Dirección de la propiedad Línea 2 (Unidad #)',
                property_address_city: 'Propiedad Dirección Ciudad',
                property_address_state: 'Propiedad Dirección Estado',
                property_address_zip: 'Dirección de la propiedad Código postal',
                purchase_price: 'Precio de compra',
                offer_price: 'Precio de oferta',
                listing_price: 'Precio de cotización',
                seller_address: 'Dirección del vendedor',
                seller_address_1: 'Línea de dirección del vendedor 1',
                seller_address_2: 'Línea de dirección del vendedor 2 (n.º de unidad)',
                seller_address_city: 'Ciudad de la dirección del vendedor',
                seller_address_state: 'Estado de la dirección del vendedor',
                seller_address_zip: 'Dirección del vendedor Código postal',
                seller_1: 'Vendedor 1',
                seller_2: 'Vendedor 2',
                seller_3: 'Vendedor 3',
                seller_4: 'Vendedor 4',
                seller_1_signature: 'Vendedor 1 Firma',
                seller_2_signature: 'Vendedor 2 Firma',
                seller_3_signature: 'Vendedor 3 Firma',
                seller_4_signature: 'Vendedor 4 Firma',
                seller_agent_1: 'Agente vendedor 1',
                seller_agent_lic_1: 'Licencia de agente vendedor 1',
                seller_agent_signature_1: 'Vendedor Agente 1 Firma',
                seller_agent_2: 'Agente vendedor 2',
                seller_agent_lic_2: 'Licencia de agente vendedor 2',
                seller_agent_signature_2: 'Firma del agente vendedor 2',
                seller_broker_1: 'Corredor vendedor 1',
                seller_broker_lic_1: 'Vendedor Corredor 1 Licencia',
                seller_broker_signature_1: 'Vendedor Corredor 1 Firma',
                seller_broker_2: 'Corredor vendedor 2',
                seller_broker_lic_2: 'Corredor vendedor 2',
                seller_broker_signature_2: 'Vendedor Corredor 2 Firma',
                seller_brokerage: 'Corretaje de venta',
                seller_brokerage_address: 'Dirección de corretaje de venta',
                seller_brokerage_address_1: 'Línea de dirección de corretaje de venta 1',
                seller_brokerage_address_2:
                    'Línea de dirección de corretaje de venta 2 (n.º de unidad)',
                seller_brokerage_address_city: 'Dirección de corretaje de venta Ciudad',
                seller_brokerage_address_state: 'Venta Corretaje Dirección Estado',
                seller_brokerage_address_zip:
                    'Dirección de corretaje de venta Código postal',
                seller_brokerage_email: 'Correo electrónico de corretaje de ventas',
                seller_brokerage_fax: 'Fax de corretaje de venta',
                seller_brokerage_phone: 'Teléfono de corretaje de venta',
                seller_brokerage_mls: 'Vendedor Corretaje MLS ID',
                seller_closing_rep_address:
                    'Dirección del representante de cierre de ventas',
                seller_closing_rep_address_1:
                    'Línea 1 de la dirección del representante de cierre del vendedor',
                seller_closing_rep_address_2:
                    'Línea 2 de la dirección del representante de cierre del vendedor',
                seller_closing_rep_city:
                    'Dirección del representante de cierre del vendedor Ciudad',
                seller_closing_rep_state:
                    'Estado de la dirección del representante de cierre del vendedor',
                seller_closing_rep_zip:
                    'Dirección del representante de cierre del vendedor Código postal',
                seller_closing_rep_email:
                    'Correo electrónico del representante de cierre de ventas',
                seller_closing_rep_fax: 'Fax del representante de cierre de ventas',
                seller_closing_rep_name: 'Nombre del representante de cierre de ventas',
                seller_closing_rep_phone: 'Teléfono del representante de cierre de ventas'
            }
        },
        Transactions: {
            active_label: `Activo`,
            pending_label: `Pendiente`,
            closed_label: `Cerrado`,
            archived_label: `Archivado`,
            buyer_label: `Compradore`,
            seller_label: `Vendedore`,
            CardSkeleton: {},
            TransactionCard: {
                button_label: `Detalles`
            },

            TransactionList: {
                none_found_label: `¡No se encontraron transacciones!`
            },

            TransactionRow: {
                buyer_label: `Compradore`,
                seller_label: `Vendedore`,
                button_label: `Detalles`
            },

            TransactionsSearchFilter: {
                input_label: `Buscar transacciones`,
                all_label: `Todo Activo`,
                buyers_label: `Compradores`,
                sellers_label: `Vendedores`,
                closed_label: `Cerrado`,
                archived_label: 'Archivado',
                name_label: `Nombre`,
                address_label: `Dirección`
            },
            AdminControlBanner: {
                button_label: 'Controles de administración',
                text1: 'Ver transacciones de ',
                text2: ''
            }
        },
        Organization: {
            none_found_label: `¡No se encontraron miembros!`,
            transactions_button_label: 'Transacciones',
            OrganizationSearchFilter: {
                input_label: `Organización de búsqueda`,
                all_label: `Todos Miembros`,
                active_label: `Miembros Activos`,
                inactive_label: `Miembros Inactivos`
            }
        },
        Page404: {
            page_title: `¡Vaya 404!`,
            page_subtitle: `Parece que no podemos encontrar la página que está buscando.`,
            startOver_button: `Comenzar de nuevo`
        }
    }
};
export default library;

/**
  de: {
        lang_name:`German`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    fr: {
        lang_name:`French`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    it: {
        lang_name:`Italian`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    nl: {
        lang_name:`Dutch`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    },

    uk: {
        lang_name:`Ukrainian`,
        Alerts: {},
        AuthenticationServices: {
            ResetPassword: {}
        },
        Common: {
            AddConnectionsModal: {},
            AddDocumentModal: {},
            AddDocToTrxModal: {},
            AddTaskModal: {},
            AppHeader: {},
            AvatarEditModal: {},
            ClickWrapper: {},
            CreatableSelectField: {},
            DatePicker: {},
            DateTimePicker: {},
            EditTaskModal: {},
            Loader: {},
            MainHeader: {},
            RemoveTaskModal: {},
            SelectField: {},
            SideNavClickWrapper: {},
            SideNavigation: {},
            StateCountrySelect: {},
            TextField: {},
            TransactionUploadModal: {}
        },
        Constants: {
            errorMessage: {
                emailPasswordCombo:
                   `The email/password combination is incorrect.  You may choose to reset your password by clicking the link below.`,
                emailNoUser:
                   `This email address does not belong to a Jada account.  Please create an account by clicking the link below.`,
                password: {
                    lowerCase:`Must contain at least one lowercase letter`,
                    upperCase:`Must contain at least one uppercase letter`,
                    numeric:`Must contain at least one number`,
                    length:`Must be at least 8 characters long`,
                    required:`Please enter a password'
                },
                email: {
                    valid:`Please enter a valid email address`,
                    required:`Email address is required'
                },
                phone: {
                    valid:`Please enter a valid phone number`,
                    required:`Phone number is required'
                },
                phoneExt: {
                    valid:`Enter a valid ext.'
                },
                address: {
                    required:`Address is required`,
                    valid:`Please enter a valid address'
                },
                firstName: {
                    valid:`Please enter a valid first name`,
                    required:`First name is required'
                },
                middleInitial: {
                    valid:`Please enter a valid middle initial'
                },
                lastName: {
                    valid:`Please enter a valid last name`,
                    required:`Last name is required'
                },
                city: {
                    valid:`Please enter a valid city`,
                    required:`City is required'
                },
                state: {
                    required:`State required'
                },
                zip: {
                    required:`Zip/Postal code is required'
                },
                country: {
                    required:`Country is required'
                },
                fromDate: {
                    required:`Start date is required'
                },
                toDate: {
                    required:`End date is required'
                },
                listingPrice: {
                    required:`Listing price is required'
                },
                commission: {
                    required:`Commission is required`,
                    valid:`Commission must be a number'
                },
                split: {
                    required:`Agent split is required`,
                    valid:`Agent split must be a number'
                },
                fee: {
                    required:`Transaction fee is required`,
                    valid:`Transaction fee must be a number'
                },
                businessSrc: {
                    required:`Source is required'
                },
                emailOrPhone: {
                    required:`Email or phone is required'
                },
                firstOrLastName: {
                    required:`First or last name is required'
                }
            }
        },
        Connections: {
            NewConnectionForm: {},
            ConnectionList: {},
            ConnectionRow: {},
            ConnectionRowSkeleton: {},
            ConnectionsSearchFilter: {}
        },
        ConnectionDetails: {},
        Dashboard: {},
        Documents: {
            DocumentCard: {},
            DocumentsGrid: {},
            DocumentRow: {},
            DocumentsSearchFilter: {}
        },
        Landing: {},
        Library: {},
        Login: {},
        NewTransaction: {
            BuyerFlowStep1: {},
            BuyerFlowStep2: {},
            BuyerFlowStep3: {},
            BuyerSelectForm: {},
            BuyerTransactionFlow: {},
            FormHeader: {},
            ListingFlowStep1: {},
            ListingFlowStep2: {},
            ListingFlowStep3: {},
            ListingSelectForm: {},
            ListingTransactionFlow: {},
            NewTransactionHeader: {},
            TransactionTypeSelect: {}
        },
        PdfView: {
            ControllerHeader: {},
            ViewController: {}
        },
        Preferences: {},
        Profile: {},

        Settings: {},
        TransactionDetails: {
            Activity: {},
            ActivityInput: {},
            ActivityThread: {},
            AddSearch: {},
            DetailsHeader: {},
            DocumentDrop: {},
            DocumentList: {},
            Documents: {},
            DocumentView: {},
            NoteDrop: {},
            NoteList: {},
            Notes: {},
            TabNavigation: {},
            Tasks: {},
            TransactionInterface: {},
            TransactionMeta: {}
        },
        Transactions: {
            CardSkeleton: {},
            TransactionCard: {},

            TransactionList: {},

            TransactionRow: {},

            TransactionsSearchFilter: {}
        }
    }
 
 */
