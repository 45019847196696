import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';
import { db, fsFieldValue } from '../../config/Firebase';

import {
    ACCEPT_NEW_USER_TRX_INVITATION,
    ACCEPT_TRX_INVITATION,
    VALIDATE_TRX_INVITATION
} from '../actions/types';

import { log } from '../../utils/Loggers';
import * as selectors from './Selectors';

import {
    acceptTrxInvitationSuccess,
    setTrxInvitationValidity
} from '../actions/TransactionInvitations';
import { updateUserAvatarRequest } from './Settings';
import { internalResetUserPasswordRequest, loginUserEmailPassword } from './Auth';

const users = db.collection('users');
const transactions = db.collection('transactions');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////// Validating Trx Invitation ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const validateTrxInvitationRequest = async ({ userId, trxId }) => {
    const transaction = (await transactions.doc(trxId).get()).data();
    return transaction.invited.includes(userId);
};

const getUser = async ({ userId }) => {
    const user = (await users.doc(userId).get()).data();
    return user;
};

export function* validateTrxInvitation({ payload }) {
    const { userId, trxId, email } = payload;
    const authUser = yield select(selectors._authUser);
    const userData = yield call(() => getUser({ userId }));
    const isValid = yield call(() => validateTrxInvitationRequest({ userId, trxId }));
    const isUserExisted = !userData?.invite_code;

    yield put(
        setTrxInvitationValidity({
            isValid,
            isUserExisted,
            emailMatch: authUser?.email === email
        })
    );
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////// Accept Invite //////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const acceptTrxInvitationRequest = ({ userId, trxId }) => {
    const transactionRef = transactions.doc(trxId);
    return new Promise((resolve, reject) =>
        transactionRef
            .update({
                invited: fsFieldValue.arrayRemove(userId),
                access: fsFieldValue.arrayUnion(userId)
            })
            .then(() => {
                resolve({ accepted: true });
            })
            .catch(error => {
                reject({ error });
            })
    );
};

export function* acceptTrxInvitation({ payload }) {
    const { userId, trxId } = payload;
    const { accepted, error } = yield call(() =>
        acceptTrxInvitationRequest({ userId, trxId })
    );
    if (accepted) {
        yield put(acceptTrxInvitationSuccess());
    } else {
        log('TrxInvitation Error: Confirming Invitation (FS)', {
            error,
            userId,
            trxId,
            function: 'acceptTrxInvitationRequest'
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Confirm New User Invite //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const updateNewUserDataRequest = async ({ invitation, formData, avatarUrl }) => {
    const userRef = users.doc(invitation.userId);
    return new Promise((resolve, reject) => {
        userRef
            .update({
                invite_code: null,
                first_name: formData.firstName,
                last_name: formData.lastName,
                legal_name: formData.fullName,
                phone: fsFieldValue.arrayUnion({
                    number: formData.phone,
                    primary: true,
                    type: 'mobile'
                }),
                ...(avatarUrl ? { user_avatar: avatarUrl } : {})
            })
            .then(() => {
                resolve({ updated: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* acceptNewUserTrxInvitation({ payload }) {
    const { invitation, formData } = payload;
    yield call(loginUserEmailPassword, {
        payload: {
            email: invitation.email,
            password: invitation.inviteCode
        }
    });

    let avatarUrl;
    if (formData.avatar) {
        const { downloadUrl } = yield call(() =>
            updateUserAvatarRequest({
                img: formData.avatar,
                userData: { id: invitation.userId }
            })
        );
        avatarUrl = downloadUrl;
    }

    const { error: updateUserError } = yield call(() =>
        updateNewUserDataRequest({ invitation, formData, avatarUrl })
    );
    const authUser = yield select(selectors._authUser);
    const { error: resetPasswordError } = yield call(
        internalResetUserPasswordRequest,
        formData.password,
        authUser
    );
    if (updateUserError) {
        log(
            'TrxInvitation Error: Updating new user object in confirm TrxInvitation (FS)',
            {
                error: updateUserError,
                invitation,
                formData
            }
        );
    } else if (resetPasswordError) {
        log(
            'TrxInvitation Error: Updating password for new user in confirm TrxInvitation (FS)',
            {
                error: resetPasswordError,
                invitation,
                formData
            }
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* validatingTrxInvitation() {
    yield takeLatest(VALIDATE_TRX_INVITATION, validateTrxInvitation);
}

export function* acceptingTrxInvitation() {
    yield takeLatest(ACCEPT_TRX_INVITATION, acceptTrxInvitation);
}

export function* acceptingNewUserTrxInvitation() {
    yield takeLatest(ACCEPT_NEW_USER_TRX_INVITATION, acceptNewUserTrxInvitation);
}

export default function* rootSaga() {
    yield all([
        fork(validatingTrxInvitation),
        fork(acceptingTrxInvitation),
        fork(acceptingNewUserTrxInvitation)
    ]);
}
