// React
import React, { useState, useEffect } from 'react';

// Assets
import logo from '../assets/img/jada-logo.svg';

// Components
import {
    AcceptStep1,
    AcceptStep2,
    AcceptFooter,
    Loader,
    ConfirmationDialog
} from '../components';

// Context
import { useLang } from '../context/LangContext';

// Packages
import SimpleBar from 'simplebar-react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { setLanguageLocal } from '../store/actions/Lang';
import {
    confirmNewUserInvitation,
    prepareNewUserStart,
    decliningNewUserInvitation
} from '../store/actions/Invitations';
import { setConfirmModalType } from '../store/actions/Modal';

// Router
import * as routes from '../router/config/routes';

// Utils
import {
    nameRegExp,
    phoneRegExp,
    passwordRegExp,
    confirmationDialogTypes
} from '../utils/Constants';

const InvitationAcceptance = ({
    setLanguageLocal,
    userInvitation,
    confirmNewUserInvitation,
    loading,
    prepareNewUserStart,
    userReady,
    decliningNewUserInvitation,
    declined,
    error,
    modalDialogType,
    setConfirmModalType
}) => {
    const steps = {
        first: 'first',
        second: 'second'
    };

    const lang = {
        en: `english`,
        es: `español`
    };

    const {
        submit_label,
        next_label,
        decline_label,
        skip_label,
        step_one,
        step_two,
        back_label
    } = useLang()['InvitationAcceptance'];
    const { accountTypes, errorMessage } = useLang()['Constants'];
    const [appLanguage, setAppLanguage] = useState(`english`);
    const [step, setStep] = useState(steps.first);
    const [skipped, setSkipped] = useState(false);
    const [declining, setDeclining] = useState(false);
    const [longEnough, setLongEnough] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecial, setHasSpecial] = useState(false);
    const [source, setSource] = useState(null);
    const [avatarModal, setAvatarModal] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [activeUpload, setActiveUpload] = useState(false);
    const [pristine, setPristine] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const maxSize = 2097152;
    const history = useHistory();

    const setLanguage = () => {
        switch (appLanguage) {
            case lang.es:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
            case lang.en:
                setLanguageLocal({ origin: 'es' });
                setAppLanguage(lang.es);
                break;
            default:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
        }
    };

    const initialValues = {
        about: '',
        accountType: accountTypes.filter(type => type.id === userInvitation?.type.id)[0]
            .value,
        avatar: '',
        email: userInvitation?.email,
        firstName: userInvitation?.first_name,
        lastName: userInvitation?.last_name,
        licNumber: '',
        mlsId: '',
        mls: null,
        password: '',
        phone: '',
        skip: false,
        decline: false
    };

    const validationSchema = Yup.object().shape(
        {
            accountType: Yup.string().required(),
            avatar: Yup.string(),
            about: Yup.string(),
            email: Yup.string()
                .lowercase()
                .email(errorMessage.email.valid)
                .required(errorMessage.email.required),
            firstName: Yup.string()
                .matches(nameRegExp.format, errorMessage.firstName.valid)
                .required(errorMessage.firstName.required),
            lastName: Yup.string()
                .matches(nameRegExp.format, errorMessage.lastName.valid)
                .required(errorMessage.lastName.required),
            mlsId: Yup.string().when(['skip', 'mls', 'decline'], {
                is: (skip, mls, decline) =>
                    !skip && mls && mls?.trim().length !== 0 && !decline,
                then: Yup.string().required(errorMessage.mlsIdIfMls.required),
                otherwise: Yup.string()
            }),
            mls: Yup.string().when(['skip', 'mlsId', 'decline'], {
                is: (skip, mlsId, decline) =>
                    !skip && mlsId && mlsId?.trim().length !== 0 && !decline,
                then: Yup.string().required(errorMessage.mlsIfMlsId.required),
                otherwise: Yup.string().nullable()
            }),
            password: Yup.string().when(['decline'], {
                is: decline => !decline,
                then: Yup.string()
                    .matches(passwordRegExp.lowerCase, errorMessage.password.lowerCase)
                    .matches(passwordRegExp.upperCase, errorMessage.password.upperCase)
                    .matches(passwordRegExp.numeric, errorMessage.password.numeric)
                    .matches(passwordRegExp.special, errorMessage.password.special)
                    .min(8, errorMessage.password.length)
                    .required(errorMessage.password.required),
                otherwise: Yup.string()
            }),
            phone: Yup.string().when(['decline'], {
                is: decline => !decline,
                then: Yup.string()
                    .required(errorMessage.phone.required)
                    .matches(phoneRegExp.format, errorMessage.phone.valid),
                otherwise: Yup.string()
            }),
            skip: Yup.bool(),
            decline: Yup.bool()
        },
        [
            ['mls', 'mlsId', 'skip', 'decline'],
            ['skip', 'mlsId', 'decline'],
            ['skip', 'mls', 'decline'],
            ['decline']
        ]
    );

    const SkippedSubmission = () => {
        const { values, submitForm } = useFormikContext();
        useEffect(() => {
            if (values.skip) {
                submitForm();
            }
        }, [values, submitForm]);
        return null;
    };

    const DeclinedSubmission = ({ declined }) => {
        const { values, submitForm } = useFormikContext();
        useEffect(() => {
            if (values.decline && !declined) {
                submitForm();
            }
        }, [values, declined, submitForm]);
        return null;
    };

    const handleSuccess = () => {
        if (modalDialogType === confirmationDialogTypes.acceptInviteSuccess) {
            prepareNewUserStart();
        }
        if (declined || error) {
            history.push(`${routes.LOGIN}`);
        }
    };

    useEffect(() => {
        if (userReady) {
            history.push(`${routes.AUTHENTICATED}${routes.DASHBOARD}`);
        }
    }, [history, userReady]);

    if (!userInvitation) return <Loader />;

    return (
        <div id="content" className="push-main pt-0">
            <ConfirmationDialog
                initialModalDialogType={
                    modalDialogType || confirmationDialogTypes.acceptInviteSuccess
                }
                modalDialogType={modalDialogType}
                successHandler={() => handleSuccess()}
            />
            <div className="bg-light">
                <SimpleBar style={{ overflowY: 'auto', height: '100%' }}>
                    <button
                        className="btn btn-xs btn-text-secondary"
                        type="button"
                        onClick={setLanguage}
                        style={{
                            position: 'absolute',
                            margin: '15px',
                            top: 0,
                            right: '30px'
                        }}
                        disabled={skipped}
                    >
                        <span className="position-relative">
                            <span
                                className="u-sidebar--account__toggle-text"
                                style={{
                                    textDecoration: 'underline'
                                }}
                            >
                                {appLanguage === 'english' ? lang.es : 'inglés'}
                            </span>
                        </span>
                    </button>
                    <div className="container space-2">
                        <div className="mx-lg-auto">
                            <div className="card pt-5">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    validate={values => {
                                        values.password.length < 8
                                            ? setLongEnough(false)
                                            : setLongEnough(true);
                                        values.password.match(passwordRegExp.upperCase)
                                            ? setHasUppercase(true)
                                            : setHasUppercase(false);
                                        values.password.match(passwordRegExp.numeric)
                                            ? setHasNumber(true)
                                            : setHasNumber(false);
                                        values.password.match(passwordRegExp.special)
                                            ? setHasSpecial(true)
                                            : setHasSpecial(false);
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        if (values.decline) {
                                            setSubmitting(true);
                                            decliningNewUserInvitation();
                                        } else {
                                            if (step === 'second') {
                                                setSubmitting(true);
                                                confirmNewUserInvitation({
                                                    formData: { ...values },
                                                    invitation: userInvitation,
                                                    skip: skipped,
                                                    language: appLanguage
                                                });
                                            } else {
                                                setStep('second');
                                                setSubmitting(false);
                                            }
                                        }
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldTouched,
                                        setFieldValue,
                                        setFieldError,
                                        resetForm,
                                        touched,
                                        values,
                                        isValid,
                                        dirty,
                                        isSubmitting
                                    }) => (
                                        <>
                                            <Form className="mb-5">
                                                <div className="jada-logo d-flex row justify-content-center mb-4">
                                                    <img
                                                        src={logo}
                                                        className="logo"
                                                        style={{ width: '190px' }}
                                                        alt="Jada Real Estate Real Simple"
                                                    />
                                                </div>

                                                {step === 'first' ? (
                                                    <AcceptStep1
                                                        setStep={setStep}
                                                        lang={{
                                                            ...step_one
                                                        }}
                                                        errors={errors}
                                                        values={values}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        handleSubmit={handleSubmit}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        touched={touched}
                                                        isSubmitting={isSubmitting}
                                                        longEnough={longEnough}
                                                        hasUppercase={hasUppercase}
                                                        hasNumber={hasNumber}
                                                        hasSpecial={hasSpecial}
                                                    />
                                                ) : (
                                                    <AcceptStep2
                                                        setStep={setStep}
                                                        lang={{
                                                            ...step_two
                                                        }}
                                                        skip={skipped}
                                                        setSkip={setSkipped}
                                                        errors={errors}
                                                        values={values}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        handleSubmit={handleSubmit}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        touched={touched}
                                                        isSubmitting={isSubmitting}
                                                        source={source}
                                                        setSource={setSource}
                                                        avatarModal={avatarModal}
                                                        setAvatarModal={setAvatarModal}
                                                        avatar={avatar}
                                                        setAvatar={setAvatar}
                                                        activeUpload={activeUpload}
                                                        setActiveUpload={setActiveUpload}
                                                        pristine={pristine}
                                                        setPristine={setPristine}
                                                        uploadError={uploadError}
                                                        setUploadError={setUploadError}
                                                        maxSize={maxSize}
                                                    />
                                                )}
                                                <SkippedSubmission />
                                                <DeclinedSubmission declined={declined} />
                                            </Form>
                                            <AcceptFooter
                                                loading={loading}
                                                disabled={isSubmitting}
                                                backLabel={back_label}
                                                skipLabel={skip_label}
                                                nextLabel={next_label}
                                                submitLabel={submit_label}
                                                declineLabel={decline_label}
                                                handleBack={() => {
                                                    setStep('first');
                                                }}
                                                handleSkip={() => {
                                                    setFieldTouched('skip', true);
                                                    setFieldValue('skip', true);
                                                    setSkipped(true);
                                                }}
                                                handleSubmit={() => {
                                                    handleSubmit();
                                                }}
                                                handleDecline={e => {
                                                    e.preventDefault();
                                                    setFieldTouched('decline', true);
                                                    setFieldValue('decline', true);
                                                    setDeclining(true);
                                                }}
                                                step={step}
                                                newMember={true}
                                                skip={skipped}
                                                decline={declining}
                                            />
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ invitation, modal }) => {
    const { userInvitation, loading, userReady, declined, error } = invitation;
    const { modalDialogType } = modal;
    return { userInvitation, loading, userReady, declined, error, modalDialogType };
};

export default connect(mapStateToProps, {
    setLanguageLocal,
    confirmNewUserInvitation,
    prepareNewUserStart,
    decliningNewUserInvitation,
    setConfirmModalType
})(InvitationAcceptance);
