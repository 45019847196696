// React
import React, { useState } from 'react';

// Packages
import { Nav, NavItem, NavLink } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { setConfirmModalType } from '../../../store/actions/Modal';
import { setPreferenceActiveTab } from '../../../store/actions/Preferences';

// Context
import { useLang } from '../../../context/LangContext';

// Utils
import { confirmationDialogTypes } from '../../../utils/Constants';

// Components
import ConfirmationDialog from '../../common/ConfirmationDialog';
import Documents from './Documents';
import Languages from './Languages';
import Tasks from './Tasks';
import Notifications from './Notifications';

const TABS = {
    DOCUMENTS: 'documents',
    NOTIFICATIONS: 'notifications',
    LANGUAGES: 'languages',
    MESSAGING: 'messaging',
    TASKS: 'tasks'
};

const Preferences = ({ setConfirmModalType, setPreferenceActiveTab, activeTab }) => {
    const { title } = useLang()['Settings']['Preferences'];
    const { names } = useLang()['Settings']['Preferences']['Tabs'];

    const [hasChanges, setHasChanges] = useState(false);
    const [potentialTab, setPotentialTab] = useState(null);

    const menuScheme = [
        { id: TABS.NOTIFICATIONS, name: names?.name1 },
        { id: TABS.DOCUMENTS, name: names?.name2 },
        { id: TABS.TASKS, name: names?.name3 },
        { id: TABS.LANGUAGES, name: names?.name4 }
    ];

    const handleClick = e => {
        if (!hasChanges) {
            setPreferenceActiveTab(e.target.id);
        } else {
            setConfirmModalType(confirmationDialogTypes.cancel);
            setPotentialTab(e.target.id);
        }
    };

    const handleCancel = () => {
        setConfirmModalType(null);
        setPotentialTab(null);
    };

    const handleConfirm = () => {
        setPreferenceActiveTab(potentialTab);
        setHasChanges(false);
    };

    const renderTab = () => {
        switch (activeTab) {
            case TABS.NOTIFICATIONS:
                return <Notifications setHasChanges={setHasChanges} />;
            case TABS.DOCUMENTS:
                return <Documents setHasChanges={setHasChanges} />;
            case TABS.TASKS:
                return <Tasks setHasChanges={setHasChanges} />;
            case TABS.LANGUAGES:
                return <Languages setHasChanges={setHasChanges} />;
            default:
                return <Notifications setHasChanges={setHasChanges} />;
        }
    };

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.cancel}
                modalDialogType={confirmationDialogTypes.cancel}
                confirmHandler={handleConfirm}
                cancelHandler={handleCancel}
            />
            <div className="card-header">
                <h1 className="h5 font-weight-medium mb-0 ml-2">{title}</h1>
            </div>
            <Nav tabs className="nav nav-tabs nav-fill nav-classic">
                {menuScheme.map(tab => (
                    <NavItem
                        className="pointer"
                        key={tab.id}
                        style={{ textAlign: 'left' }}
                    >
                        <NavLink
                            id={tab.id}
                            className={activeTab === tab.id ? 'active' : ''}
                            onClick={handleClick}
                            style={{
                                borderLeftColor: 'transparent',
                                borderRightColor: 'transparent',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {tab.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {renderTab()}
        </>
    );
};

const mapStateToProps = state => {
    const {
        pref: { activeTab }
    } = state;
    return { activeTab };
};

export default connect(mapStateToProps, {
    setConfirmModalType,
    setPreferenceActiveTab
})(Preferences);
