// React
import React, { useEffect, useState } from 'react';

// Components
import { NewAddHeader, ConfirmationDialog, Loader } from '../components';
import Documents from './Documents';

// Context
import { useLang } from '../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Redux - Actions, Reducers, Sagas
import { saveDocumentsPack } from '../store/actions/Documents';

// Router
import * as routes from '../router/config/routes';

// Constants
import { confirmationDialogTypes, documentSections } from '../utils/Constants';

// Hooks
import { useBasePath } from '../hooks/useBasePath';

const NewPack = ({
    match,
    location,
    saveDocumentsPack,
    modalDialogType,
    userDocuments,
    orgDocuments
}) => {
    const history = useHistory();
    const basePath = useBasePath();
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [currentPack, setCurrentPack] = useState(null);

    const library =
        `${routes.AUTHENTICATED}${routes.NEW_ORG_PACK}` === history.location.pathname || 
        `${routes.AUTHENTICATED}${routes.EDIT_ORG_PACK}` === basePath;
    const system =
        `${routes.AUTHENTICATED}${routes.NEW_SYSTEM_PACK}` === history.location.pathname;

    const packId = location.state?.id || match.params.id;

    const initialValues = {
        title: currentPack?.title || '',
        type: currentPack?.upload_type || '',
        selectedDocs: selectedDocuments
    };

    useEffect(() => {
        if (packId && userDocuments && orgDocuments) {
            const allDocs = [...userDocuments, ...orgDocuments];
            const currentPack = allDocs.find(el => el.id === packId);
            if (currentPack) {
                setCurrentPack(currentPack);
                const filteredDocs = allDocs.filter(el =>
                    Object.keys(currentPack.documents || {}).includes(el.id)
                );

                setSelectedDocuments(
                    filteredDocs.map(el => ({
                        ...el,
                        position: currentPack.documents[el.id].position
                    }))
                );
            }
        }
    }, [packId, setSelectedDocuments, userDocuments, orgDocuments]);

    const handleAddDocToPack = (document, setFieldValue, values) => {
        const docId = document.id;
        if (!values.selectedDocs.some(el => el.id === docId)) {
            const position = values.selectedDocs.length;
            setFieldValue('selectedDocs', [
                ...values.selectedDocs,
                { ...document, position }
            ]);
        } else {
            setFieldValue(
                'selectedDocs',
                values.selectedDocs.filter(el => el.id !== docId)
            );
        }
    };

    const {
        add_title_label,
        title_placeholder,
        buyer_label,
        seller_label,
        other_label,
        doc_type_label,
        cancel_button_label,
        create_pack_label,
        save_changes_label
    } = useLang()['Common']['AddDocumentModal'];

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required!'),
        type: Yup.string().required('Type is required. Choose one')
    });

    const cancelClickHandler = () =>
        history.push(`${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.DOCUMENTS}`);

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={
                    !currentPack
                        ? confirmationDialogTypes.createFormPack
                        : modalDialogType
                }
                successHandler={() => history.goBack()}
            />
            <div className="row">
                <NewAddHeader
                    returnRoute={`${routes.AUTHENTICATED}${routes.DASHBOARD}${routes.DOCUMENTS}`}
                />
            </div>
            <main id="content" role="main" className="push-main bg-light">
                <div className="container p-5 card">
                    {currentPack || !packId ? (
                        <Formik
                            validateOnMount
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={({ title, type, selectedDocs }) => {
                                saveDocumentsPack({
                                    documents: selectedDocs,
                                    title,
                                    upload_type: type,
                                    packId,
                                    section: library
                                        ? documentSections.library
                                        : system
                                        ? documentSections.system
                                        : documentSections.personal
                                });
                            }}
                        >
                            {({
                                values,
                                handleChange,
                                handleSubmit,
                                dirty,
                                setFieldValue
                            }) => (
                                <Form>
                                    <div className="row d-flex flex-column mt-8 pl-4 pr-4">
                                        <label
                                            htmlFor="title"
                                            className="new-document--heading"
                                        >
                                            {add_title_label}{' '}
                                            <span style={{ color: '#f00' }}>*</span>
                                        </label>
                                        <input
                                            id="title"
                                            name="title"
                                            type="text"
                                            value={values.title}
                                            onChange={handleChange}
                                            placeholder={title_placeholder}
                                            className="form-control w-50"
                                        />
                                    </div>
                                    <div className="row d-flex flex-column mt-5 px-4">
                                        <p className="new-document--heading">
                                            {doc_type_label}{' '}
                                            <span style={{ color: '#f00' }}>*</span>
                                        </p>
                                        <div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id="buyer"
                                                    type="radio"
                                                    name="type"
                                                    className="form-check-input"
                                                    value="buyer"
                                                    checked={values.type === 'buyer'}
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    htmlFor="buyer"
                                                    className="form-check-label"
                                                >
                                                    {buyer_label}
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id="seller"
                                                    type="radio"
                                                    name="type"
                                                    className="form-check-input"
                                                    value="seller"
                                                    checked={values.type === 'seller'}
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    htmlFor="seller"
                                                    className="form-check-label"
                                                >
                                                    {seller_label}
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id="other"
                                                    type="radio"
                                                    name="type"
                                                    className="form-check-input"
                                                    value="other"
                                                    checked={values.type === 'other'}
                                                    onChange={handleChange}
                                                />
                                                <label
                                                    htmlFor="other"
                                                    className="form-check-label"
                                                >
                                                    {other_label}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <Documents
                                        setFieldValue={setFieldValue}
                                        selectedDocuments={values.selectedDocs}
                                        handleAddDocToPack={document =>
                                            handleAddDocToPack(
                                                document,
                                                setFieldValue,
                                                values
                                            )
                                        }
                                    />
                                    <div className="d-flex justify-content-end p-4">
                                        <div className="actionsContainer">
                                            <button
                                                className="btn btn-link text-danger mr-4"
                                                onClick={cancelClickHandler}
                                                type="button"
                                            >
                                                {cancel_button_label}
                                            </button>
                                            <button
                                                className="btn btn-primary mr-2"
                                                onClick={handleSubmit}
                                                type="submit"
                                                disabled={
                                                    values.selectedDocs.length < 2 ||
                                                    !values.type ||
                                                    !values.title ||
                                                    !dirty
                                                }
                                            >
                                                {currentPack
                                                    ? save_changes_label
                                                    : create_pack_label}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <Loader />
                    )}
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ user, modal, documents }) => {
    const { modalDialogType } = modal;
    const { userData } = user;
    const { userDocuments, orgDocuments } = documents;
    return { userData, modalDialogType, userDocuments, orgDocuments };
};

export default withRouter(connect(mapStateToProps, { saveDocumentsPack })(NewPack));
