import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useLang } from '../../../context/LangContext';

// Components
import { InviteMembersHeader } from './InviteMembersHeader';
import SimpleBar from 'simplebar-react';
import ActionButtons from '../shared/ActionButtons';

// Constants & Helpers
import { invitationStatuses } from '../../../utils/Constants';
import { formatInviteDate, getUserAvatars } from '../../../utils/Helpers';

// Actions
import { connect } from 'react-redux';
import {
    acceptInvite,
    removeInvite,
    declineInvite,
    createUserTeam
} from '../../../store/actions/Teams';

const ManageInvitations = ({
    acceptInvite,
    setScreen,
    teamScreen,
    userData,
    removeInvite,
    declineInvite,
    createUserTeam
}) => {
    const {
        teamList,
        myInvites_title,
        back_button_label,
        no_invitations_label,
        invitationStatuses: invitationStatusesContent
    } = useLang()['Settings']['Team'];
    const { accept_label, decline_label, remove_label, create_label } =
        useLang()['Settings'];
    const invitations = useMemo(
        () =>
            Object.values(userData.team_invites || {}).sort((a, b) => {
                if (
                    a.status === invitationStatuses.pending &&
                    b.status !== invitationStatuses.pending
                )
                    return -1;
                else if (
                    b.status === invitationStatuses.pending &&
                    a.status !== invitationStatuses.pending
                )
                    return 1;
                else if (
                    a.status === invitationStatuses.pending &&
                    b.status === invitationStatuses.pending
                ) {
                    return b.invited_at.seconds - a.invited_at.seconds;
                } else {
                    return b.expired_at.seconds - a.expired_at.seconds;
                }
            }),
        [userData]
    );

    // Getting leaders avatars
    const [imgByIds, setImgByIds] = useState([]);
    const ids = useMemo(
        () => invitations.map(invitation => invitation.team_lead.id),
        [invitations]
    );

    useEffect(() => {
        getUserAvatars({ ids, setImgByIds });
    }, [ids]);

    const getInvitationDate = useCallback(
        invitation =>
            formatInviteDate(
                (invitation.status === invitationStatuses.expired
                    ? invitation.expired_at
                    : invitation.invited_at
                ).seconds
            ),
        []
    );

    return (
        <>
            <div className="card-header d-flex align-items-center">
                <h2 className="h5 mb-0">{myInvites_title}</h2>
            </div>
            {invitations.length ? (
                <div
                    className="card-body"
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '0'
                    }}
                >
                    <SimpleBar
                        className="container"
                        style={{ overflowY: 'auto', height: '100%' }}
                    >
                        <>
                            <InviteMembersHeader
                                name={teamList.name_label}
                                status={teamList.status_label}
                            />
                            {invitations?.map(invitation => (
                                <div
                                    key={invitation.invite_id}
                                    className="row d-flex align-items-center w-100 mb-4 pr-0"
                                >
                                    <div className="d-flex col-6">
                                        <img
                                            src={imgByIds[invitation.team_lead.id]}
                                            alt=""
                                            className="rounded-circle contact-frame__avatar--small mr-3 skeleton-loader"
                                        />
                                        <div>
                                            <div>{invitation.team_name}</div>
                                            <p className="small mb-0">
                                                {invitation.team_lead.email}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div
                                            className={cx({
                                                'text-success':
                                                    invitation.status ===
                                                    invitationStatuses.pending,
                                                'text-danger':
                                                    invitation.status ===
                                                    invitationStatuses.expired
                                            })}
                                        >
                                            {invitationStatusesContent[invitation.status]}
                                        </div>
                                        <p>{getInvitationDate(invitation)}</p>
                                    </div>
                                    <div className="d-flex col-3 justify-content-end">
                                        {invitation.status ===
                                        invitationStatuses.pending ? (
                                            <>
                                                <button
                                                    className="btn btn-success transition-3d-hover mr-3"
                                                    style={{ padding: '0.3rem 1rem' }}
                                                    onClick={() =>
                                                        acceptInvite({
                                                            teamId: invitation.team_id,
                                                            invitationId:
                                                                invitation.invite_id,
                                                            user: userData
                                                        })
                                                    }
                                                >
                                                    {accept_label}
                                                </button>
                                                <button
                                                    className="btn btn-danger transition-3d-hover"
                                                    style={{ padding: '0.3rem 1rem' }}
                                                    onClick={() =>
                                                        declineInvite({
                                                            teamId: invitation.team_id,
                                                            invitationId:
                                                                invitation.invite_id,
                                                            userId: userData.id
                                                        })
                                                    }
                                                >
                                                    {decline_label}
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                className="btn btn-danger transition-3d-hover"
                                                style={{ padding: '0.3rem 1rem' }}
                                                onClick={() =>
                                                    removeInvite({
                                                        invitationId:
                                                            invitation.invite_id,
                                                        userId: userData.id
                                                    })
                                                }
                                            >
                                                {remove_label}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </>
                    </SimpleBar>
                </div>
            ) : (
                <div className="card-body text-wrap text-center d-flex align-items-center justify-content-center team-text">
                    {no_invitations_label}
                </div>
            )}
            {userData.team_id ? (
                <div className="card-footer d-flex justify-content-between">
                    <div
                        className="text-primary m-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setScreen(teamScreen.teamMembers)}
                    >
                        <span className="fas fa-angle-left mr-2" />
                        {back_button_label}
                    </div>
                </div>
            ) : (
                <ActionButtons
                    saveLabel={create_label}
                    handleSubmit={() =>
                        createUserTeam({
                            name: 'My Team'
                        })
                    }
                />
            )}
        </>
    );
};

const mapStateToProps = ({ contacts }) => {
    const { companyContacts } = contacts;
    return { companyContacts };
};

export default connect(mapStateToProps, {
    acceptInvite,
    removeInvite,
    declineInvite,
    createUserTeam
})(ManageInvitations);
