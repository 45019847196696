// React
import React, { useState, useEffect, useMemo } from 'react';

// Components
import { DocumentsList, Loader } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DocumentView = ({ documentView, setDocumentView, allDocs }) => {
    const [activeDocument, setActiveDocument] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [url, setUrl] = useState(null);
    const { windowHeight } = useWindowSize();
    const [viewHeight, setViewHeight] = useState(null);
    const { of_label } = useLang()['TransactionDetails']['DocumentView'];

    const packDocuments = useMemo(() => {
        if (documentView?.pack) {
            const packDocs = allDocs
                .filter(el => Object.keys(documentView.documents).includes(el.id))
                .map(el => ({
                    ...el,
                    position: documentView.documents[el.id].position
                }));
            setActiveDocument(packDocs.find(el => el.position === 0));
            return packDocs.sort((a, b) => a.position - b.position);
        }
    }, [allDocs, documentView]);

    useEffect(() => {
        if (windowHeight) {
            setViewHeight(windowHeight * 0.85);
        }
    }, [windowHeight, setViewHeight]);

    useEffect(() => {
        if (documentView?.url) {
            setUrl(documentView.url);
        }
    }, [documentView]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePrevious = () => {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNext = () => {
        if (pageNumber !== numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const documentClickHandler = document => {
        setActiveDocument(document);
        setPageNumber(1);
    };

    if (!viewHeight && !url) return null;
    return (
        <div
            className="justify-content-center"
            style={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                left: 0,
                zIndex: 20000,
                backgroundColor: 'rgba(0,0,0,0.7)',
                alignContent: 'center'
            }}
        >
            <div style={{ marginTop: '7rem' }}>
                <button
                    type="button"
                    className="btn btn-lg btn-icon rounded-circle transition-3d-hover ml-auto"
                    style={{
                        position: 'absolute',
                        right: '10%',
                        color: 'rgba(255,255,255,1)',
                        fontSize: '50px'
                    }}
                    onClick={() => setDocumentView(null)}
                >
                    <span className="far fa-times-circle" />
                </button>
                {documentView.pack && (
                    <div
                        style={{
                            width: '15vw',
                            position: 'absolute',
                            left: '25px',
                            alignContent: 'center'
                        }}
                    >
                        <DocumentsList
                            documents={packDocuments}
                            selected={activeDocument?.id}
                            documentClickHandler={documentClickHandler}
                        />
                    </div>
                )}
                <Document
                    className="react-pdf__Document"
                    file={url || activeDocument?.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Loader />}
                >
                    <Page
                        className="react-pdf__Page"
                        height={viewHeight}
                        pageNumber={pageNumber}
                        loading={<Loader />}
                    />
                    <div className="viewer-button-panel">
                        <button
                            className="btn btn-icon ml-auto panel-button"
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={handlePrevious}
                        >
                            <span className="fas fa-chevron-left" />
                        </button>
                        <span className="panel-text">{`${pageNumber} ${of_label} ${numPages}`}</span>
                        <button
                            className="btn btn-icon ml-auto panel-button"
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={handleNext}
                        >
                            <span className="fas fa-chevron-right" />
                        </button>
                    </div>
                </Document>
            </div>
        </div>
    );
};
export default DocumentView;
