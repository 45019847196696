import React, { useState, useEffect } from 'react';

// Components
import {
    DocumentProgress,
    FillingDocumentStep,
    ConsentModal,
    ConfirmationDialog
} from '../components';
import FormsHeader from '../components/common/FormsHeader';

// Redux
import { connect } from 'react-redux';
import {
    saveDocumentForFilling,
    getDocumentForFilling
} from '../store/actions/DocumentFilling';

// Routing
import { withRouter } from 'react-router-dom';

const FormSigning = ({
    match,
    loading,
    documentForFilling,
    getDocumentForFilling,
    saveDocumentForFilling,
    isConsentAccepted
}) => {
    const [isProgressVisible, setProgressVisible] = useState(true);
    const [dividedSortedAnnotations, setDividedSortedAnnotations] = useState([]);
    const [signatures, setSignatures] = useState(null);
    const [activeInput, setActiveInput] = useState(null);

    useEffect(() => {
        const trxDocId = match.params.trxDocId;
        getDocumentForFilling(trxDocId);
    }, [getDocumentForFilling, match.params.trxDocId]);

    useEffect(() => {
        if (documentForFilling && !signatures)
            setSignatures(documentForFilling.signatures);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentForFilling, setSignatures]);

    const saveDocument = () => {
        const documentsAnnotations = [];
        dividedSortedAnnotations.forEach(page =>
            page.forEach(annotation =>
                annotation.inputs.forEach(input => {
                    const inputCopy = { ...input };
                    delete inputCopy.documentIndex;
                    delete inputCopy.packPageNumber;
                    documentsAnnotations[input.documentIndex] = [
                        ...(documentsAnnotations[input.documentIndex] || []),
                        inputCopy
                    ];
                })
            )
        );
        const documents = documentForFilling.documents.map((el, index) => ({
            ...el,
            annotations: documentsAnnotations[index] || []
        }));
        saveDocumentForFilling({
            trxDocId: match.params.trxDocId,
            trxId: documentForFilling.trx_id,
            documents,
            signatures
        });
    };

    return isProgressVisible ? (
        <DocumentProgress loading={loading} setProgressVisible={setProgressVisible} />
    ) : (
        <>
            <ConfirmationDialog />
            <FormsHeader
                documentForFilling={documentForFilling}
                saveDocument={saveDocument}
                isInSigningMode
            />
            <FillingDocumentStep
                dividedSortedAnnotations={dividedSortedAnnotations}
                setDividedSortedAnnotations={setDividedSortedAnnotations}
                activeInput={activeInput}
                setActiveInput={setActiveInput}
                isInSigningMode
                signatures={signatures}
                setSignatures={setSignatures}
            />
            <ConsentModal isConsentAccepted={isConsentAccepted} />
        </>
    );
};

const mapStateToProps = ({ documentFilling, user }) => {
    const { loading, documentForFilling } = documentFilling;
    const id = user?.userData?.id;
    const isConsentAccepted = documentForFilling?.filling_settings.confirm_signers.some(
        el => el.id === id && el.signing_consent
    );
    return {
        loading,
        documentForFilling,
        isConsentAccepted
    };
};

export default withRouter(
    connect(mapStateToProps, {
        getDocumentForFilling,
        saveDocumentForFilling
    })(FormSigning)
);
