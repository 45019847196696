// React
import React, { useState, useCallback, useEffect } from 'react';

// Components
import {
    AppHeader,
    TransactionsSearchFilter,
    TransactionList,
    Loader
} from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';

//Router
import { useLocation } from 'react-router-dom';

// Redux - Actions, Reducers, Sagas
import { getMemberTransactions } from '../store/actions/Transactions';

const MemberTransactions = ({
    match,
    memberTransactions,
    history,
    companyContacts,
    getMemberTransactions,
    cancelDetailsListener
}) => {
    const location = useLocation();
    const [scrollHeight, setScrollHeight] = useState(0);
    const [memberData, setMemberData] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const { windowHeight, windowWidth } = useWindowSize();
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && !memberData && companyContacts) {
            if (
                location.state?.id &&
                location.state?.first_name &&
                location.state?.last_name
            ) {
                setMemberData({
                    id: location.state.id,
                    first_name: location.state.first_name,
                    last_name: location.state.last_name
                });
            } else {
                const id = location.pathname.substr(
                    location.pathname.lastIndexOf('/') + 1
                );
                const member = companyContacts.filter(member => member.id === id)[0];
                if (member)
                    setMemberData({
                        id: member.id,
                        first_name: member.first_name,
                        last_name: member.last_name
                    });
            }
        }
    }, [location, isMounted, memberData, companyContacts]);

    useEffect(() => {
        if (memberData) {
            getMemberTransactions({ id: memberData.id });
        }
    }, [memberData, getMemberTransactions]);

    if (!companyContacts && !location) return <Loader />;

    return (
        <>
            <AppHeader
                match={match}
                history={history}
                memberName={
                    memberData?.first_name && memberData?.last_name
                        ? `${memberData?.first_name} ${memberData?.last_name}`
                        : null
                }
                adminBanner
            />
            <main
                id="content"
                role="main"
                className="push-main-lg bg-light"
                style={{ paddingTop: '212px' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <TransactionsSearchFilter member={true} />
                    <div ref={measuredRef}>
                        <SimpleBar
                            style={{
                                height: scrollHeight,
                                width: windowWidth,
                                paddingBottom: '4rem'
                            }}
                        >
                            <div className="container space-bottom-1">
                                <TransactionList
                                    match={match}
                                    member={true}
                                    memberId={memberData?.id}
                                    memberName={
                                        memberData?.first_name && memberData?.last_name
                                            ? `${memberData?.first_name} ${memberData?.last_name}`
                                            : null
                                    }
                                />
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ transactions, contacts }) => {
    const { memberTransactions } = transactions;
    const { companyContacts } = contacts;
    return { memberTransactions, companyContacts };
};

export default connect(mapStateToProps, { getMemberTransactions })(MemberTransactions);
