import { all, fork, takeLatest, put, call, select } from 'redux-saga/effects';
import { db } from '../../config/Firebase';

import { VALIDATE_SIGNING_REQUEST } from '../actions/types';

import * as selectors from './Selectors';

import { setSigningRequestValidity } from '../actions/DocumentSigning';

const users = db.collection('users');
const annotatedDocs = db.collection('annotated_docs');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////// Validating Signature Request /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const executeValidatingSignatureRequest = async ({ userId, docId }) => {
    const document = (await annotatedDocs.doc(docId).get()).data();
    return document?.filling_settings?.confirm_signers.some(el => el.id === userId);
};

const getUser = async ({ userId }) => {
    const user = (await users.doc(userId).get()).data();
    return user;
};

export function* validateSignatureRequest({ payload }) {
    const { userId, docId, email } = payload;
    const authUser = yield select(selectors._authUser);
    const userData = yield call(() => getUser({ userId }));
    const isValid = yield call(() =>
        executeValidatingSignatureRequest({ userId, docId })
    );
    const isUserExisted = !userData?.invite_code;

    yield put(
        setSigningRequestValidity({
            isValid,
            isUserExisted,
            emailMatch: authUser?.email === email
        })
    );
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* validatingSigningRequest() {
    yield takeLatest(VALIDATE_SIGNING_REQUEST, validateSignatureRequest);
}

export default function* rootSaga() {
    yield all([fork(validatingSigningRequest)]);
}
