// React
import React, { useState, useCallback } from 'react';

// Components
import { DocumentsGrid, DocumentsSearchFilter } from '../components';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { Nav, NavItem, NavLink } from 'reactstrap';

// Utils
import { documentsPackTabs } from '../utils/Constants';
import { useLang } from '../context/LangContext';

const Documents = ({
    match,
    selectedDocuments,
    handleAddDocToPack,
    activeTab,
    packRoute,
    setFieldValue
}) => {
    const { packTabName } = useLang()['Documents']['Tabs'];
    const [scrollHeight, setScrollHeight] = useState(0);
    const { windowHeight } = useWindowSize();
    const [availablePackTab, setAvailablePackList] = useState(
        documentsPackTabs.avaliableDocs
    );

    const menuScheme = [
        { id: documentsPackTabs.avaliableDocs, name: packTabName?.avaliable },
        { id: documentsPackTabs.selectedDocuments, name: packTabName?.my_doc }
    ];

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const handleClick = e => {
        setAvailablePackList(e.target.id);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <DocumentsSearchFilter
                activeTab={!handleAddDocToPack && activeTab}
                isPackPage={!!handleAddDocToPack}
                packRoute={packRoute}
                selectedDocuments={selectedDocuments}
                availablePackTab={availablePackTab}
            />

            {handleAddDocToPack && (
                <div className="px-3">
                    <Nav tabs className="nav nav-fill nav-classic mb-2">
                        {menuScheme.map(tab => (
                            <NavItem
                                className="pointer"
                                key={tab.id}
                                style={{ textAlign: 'left' }}
                            >
                                <NavLink
                                    id={tab.id}
                                    className={
                                        availablePackTab === tab.id ? 'active' : ''
                                    }
                                    onClick={handleClick}
                                    style={{
                                        borderLeftColor: 'transparent',
                                        borderRightColor: 'transparent',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </div>
            )}
            <div ref={measuredRef}>
                <SimpleBar
                    style={{
                        height: scrollHeight - 100
                    }}
                >
                    <div className="container space-bottom-1">
                        <DocumentsGrid
                            selectedDocuments={selectedDocuments}
                            handleAddDocToPack={handleAddDocToPack}
                            activeTab={activeTab}
                            match={match}
                            packRoute={packRoute}
                            availablePackTab={availablePackTab}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default Documents;
