// React
import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { updateOrgMembersStatus } from './../../../store/actions/Org';

// Components
import StatusDropdown from '../OrganizationInfo/StatusDropdown';
import ActionButtons from '../shared/ActionButtons';

// Context
import { useLang } from '../../../context/LangContext';

// Hooks
import { useUserAvatars } from '../../../hooks/useUserAvatars';
import { useWindowSize } from '../../../hooks/useWindowSize';

// Helpers
import { pluralizeAndStringify, checkExpiration } from '../../../utils/Helpers';

// Packages
import SimpleBar from 'simplebar-react';
import cx from 'classnames';

const OrgMembers = ({
    activeTeam,
    lang,
    team,
    updateOrgMembersStatus,
    setFocusedState,
    setMemberDetails,
    orgScreen,
    orgInvitations
}) => {
    const { accountTypes } = useLang()['Constants'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const [changedStatuses, setChangedStatuses] = useState({});
    const [scrollHeight, setScrollHeight] = useState(0);
    const teamMembers = team ? team : activeTeam?.team?.members;
    teamMembers.sort((a, b) => a.last_name.localeCompare(b.last_name));

    const [imgByIds, setImgByIds] = useState([]);

    const ids = useMemo(() => teamMembers.map(member => member.id), [teamMembers]);
    useUserAvatars(ids, setImgByIds);

    const { windowHeight } = useWindowSize();
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const statusesItems = [
        {
            value: true,
            name: lang?.statuses?.active_label
        },
        {
            value: false,
            name: lang?.statuses?.inactive_label
        }
    ];

    const isHaveChanges = Object.values(changedStatuses).length;

    const handleSelect = (status, member) => {
        const id = member?.id;
        if (
            changedStatuses[id] !== status &&
            (changedStatuses[id] !== undefined || status !== member?.active)
        ) {
            if (changedStatuses[id] === undefined) {
                setChangedStatuses(prev => ({ ...prev, [id]: status }));
            } else {
                const copyObj = { ...changedStatuses };
                delete copyObj[id];
                setChangedStatuses(copyObj);
            }
        }
    };

    const handleOpenDetails = member => {
        setMemberDetails(member);
        setFocusedState(orgScreen.memberDetails);
    };

    const handleSubmit = () => {
        updateOrgMembersStatus({ changedStatuses });
        setChangedStatuses({});
    };

    const pendingStatuses = useMemo(
        () =>
            orgInvitations.filter(
                el => el.status === 'pending' && !checkExpiration(el.invited_at.seconds)
            ),
        [orgInvitations]
    );

    const pendingLabel = () => {
        return pluralizeAndStringify(pendingStatuses.length, lang?.pending_member);
    };

    return (
        <>
            <div
                style={{
                    animation: 'fadeIn .5s'
                }}
            >
                <div className="container pl-5">
                    <div className="row d-flex justify-content-center mb-2 mt-3">
                        <div className="col-5 pl-3">
                            <h3 className="h5 font-weight-medium">{lang?.name_label}</h3>
                        </div>
                        <div className="col-3 pl-0">
                            <h3 className="h5 font-weight-medium">{lang?.type_label}</h3>
                        </div>
                        <div className="col-4 pl-4">
                            <h3 className="h5 font-weight-medium">
                                {lang?.status_label}
                            </h3>
                        </div>
                    </div>
                    <div ref={measuredRef}>
                        <SimpleBar
                            className="container "
                            style={{
                                height: [scrollHeight] - 83 + 'px'
                            }}
                        >
                            {teamMembers?.map(member => {
                                const isChanged =
                                    changedStatuses[member.id] !== undefined;
                                return (
                                    <div
                                        key={member.id}
                                        className={cx(
                                            'row d-flex align-items-center w-100 mb-4',
                                            {
                                                'bg-light': isChanged
                                            }
                                        )}
                                    >
                                        <div
                                            className="col-5 pointer"
                                            onClick={() => handleOpenDetails(member)}
                                        >
                                            <div className="row d-flex align-items-center">
                                                <img
                                                    src={
                                                        imgByIds.find(
                                                            avatar =>
                                                                avatar.id === member.id
                                                        )?.url
                                                    }
                                                    alt=""
                                                    className="rounded-circle contact-frame__avatar--small mr-3 skeleton-loader"
                                                />
                                                <div>
                                                    <h6 className="h6 font-weight-medium mb-0 pointer">
                                                        {member?.first_name}{' '}
                                                        {member?.last_name}
                                                    </h6>
                                                    <p className="small mb-0">
                                                        {member?.email}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex row justify-content-between align-items-center pl-4">
                                            <span>
                                                {
                                                    accountTypes.find(
                                                        type =>
                                                            type.value === member?.role
                                                    ).label
                                                }
                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <StatusDropdown
                                                statuses
                                                options={statusesItems}
                                                form={{
                                                    values: {
                                                        status: (
                                                            changedStatuses[member.id] ===
                                                            undefined
                                                                ? member?.active
                                                                : changedStatuses[
                                                                      member.id
                                                                  ]
                                                        )
                                                            ? statusesItems[0].name
                                                            : statusesItems[1].name
                                                    }
                                                }}
                                                onChange={status =>
                                                    handleSelect(status.value, member)
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <ActionButtons
                cancelLabel={cancel_label}
                saveLabel={save_label}
                handleSubmit={handleSubmit}
                disabled={!isHaveChanges}
                noCancel={!isHaveChanges}
                linkLabel={pendingLabel()}
                handleLink={() => setFocusedState(orgScreen.memberInvitations)}
                handleClose={() => {
                    setChangedStatuses({});
                }}
            />
        </>
    );
};

export default connect(null, {
    updateOrgMembersStatus
})(OrgMembers);
