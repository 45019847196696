// React
import React, { useState } from 'react';

//Components
import ActionButtons from '../shared/ActionButtons';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import MultiSelect, { MultiValue, Option } from '../../common/MultiSelect';

//Redux - Action
import { saveOrgMembersDetails, removeOrgMember } from './../../../store/actions/Org';

//Packages
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { isEqual } from 'lodash';

//Hooks
import { useUserAvatars } from '../../../hooks/useUserAvatars';
import { useLang } from '../../../context/LangContext';

//Constants
import { confirmationDialogTypes } from '../../../utils/Constants';

const MemberDetails = ({
    member,
    orgScreen,
    setFocusedState,
    saveOrgMembersDetails,
    removeOrgMember
}) => {
    const { account_type_label, account_permissions_label, remove_org_member } =
        useLang()['Settings']['OrganizationMembers'];

    const { accountTypes, permissionTypes } = useLang()['Constants'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];
    const [selectedAccountType, setAccountType] = useState(member?.role);
    const defaultPermissions = Object.keys(member?.permissions || {});

    const [memberPermissions, setMemberPermissions] = useState(defaultPermissions);

    const isChanged =
        selectedAccountType !== member?.role ||
        !isEqual(defaultPermissions, memberPermissions);

    const [imgByIds, setImgByIds] = useState([]);
    useUserAvatars([member.id], setImgByIds);

    const handleRemove = memberId => {
        removeOrgMember({ memberId });
    };

    const handleSubmit = () => {
        saveOrgMembersDetails({
            selectedAccountType,
            memberId: member.id,
            memberPermissions
        });
    };

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.deleteMemberFromOrg}
                successHandler={() => setFocusedState(orgScreen.teamList)}
            />
            <div className="mb-4" style={{ height: '100%' }}>
                <div className="row d-flex mb-4 mt-6 justify-content-center">
                    <img
                        src={imgByIds[0]?.url}
                        alt=""
                        className="rounded-circle contact-frame__avatar mr-3 skeleton-loader"
                    />
                    <div className="col-5 ml-5 mt-4">
                        <h5 className="font-weight-medium mb-0 pointer">
                            {member?.first_name} {member?.last_name}
                        </h5>
                        <p>{member?.email}</p>
                        <div className=" mt-7">
                            <div className="mb-5">
                                <Label className="form-label">{account_type_label}</Label>
                                <MultiSelect
                                    options={accountTypes}
                                    editable={true}
                                    styles="memberDetails"
                                    defaultValue={accountTypes.find(
                                        el => el.value === member?.role
                                    )}
                                    onChange={option => setAccountType(option.value)}
                                />
                            </div>
                            <div>
                                <Label className="form-label">
                                    {account_permissions_label}
                                </Label>
                                <MultiSelect
                                    className="form-control p-0 border-0"
                                    options={permissionTypes}
                                    isMulti
                                    editable={true}
                                    styles="memberDetails"
                                    defaultValue={permissionTypes.filter(el =>
                                        memberPermissions.includes(el.value)
                                    )}
                                    components={{
                                        Option,
                                        MultiValue
                                    }}
                                    onChange={option => {
                                        setMemberPermissions(
                                            (option || []).map(el => el.value)
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ActionButtons
                cancelLabel={cancel_label}
                saveLabel={save_label}
                handleSubmit={handleSubmit}
                deleteLabel={remove_org_member}
                handleDelete={() => handleRemove(member?.id)}
                disabled={!isChanged || !memberPermissions.length}
                handleClose={() => {
                    setFocusedState(orgScreen.teamList);
                }}
            />
        </>
    );
};

export default connect(null, {
    saveOrgMembersDetails,
    removeOrgMember
})(MemberDetails);
