// React
import React, { useState, useCallback, useEffect } from 'react';

// Components
import { AddSearch, DocumentDrop, DocumentList } from '../index';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import SimpleBar from 'simplebar-react';
import { userTypes } from '../../utils/Constants';

const Documents = ({
    match,
    documents,
    allDocs,
    userData,
    trxId,
    trxType,
    addDocumentToTrx,
    removeDocumentFromTrx,
    setDocumentView,
    documentFilter,
    settingTrxDocumentsFilter,
    filteredDocs,
    tabContent,
    lang,
    isTransactionArchived,
    isMember,
    status
}) => {
    const [dropState, setDropState] = useState(false);
    const [dropAction, setDropAction] = useState(null);
    const [docsHeight, setDocsHeight] = useState(0);
    const [overFlow, setOverFlow] = useState('visible');
    const [parentHeight, setParentHeight] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchSuggestions, setSearchSuggestions] = useState(null);
    const { windowHeight } = useWindowSize();

    const settingSearchSuggestions = useCallback(
        suggestions => {
            setSearchSuggestions(suggestions);
        },
        [setSearchSuggestions]
    );

    const parentRef = useCallback(
        node => {
            if (node?.getBoundingClientRect().top && windowHeight) {
                setParentHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect().top && windowHeight) {
                setDocsHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        // eslint-disable-next-line
        [windowHeight, isTransactionArchived]
    );

    useEffect(() => {
        if (docsHeight > parentHeight) {
            setOverFlow('scroll');
        } else {
            setOverFlow('auto');
        }
    }, [parentHeight, docsHeight, dropAction]);

    return (
        <div
            ref={parentRef}
            style={{
                animation: 'fadeIn 1s',
                width: '100%',
                maxHeight: parentHeight,
                overflowY: overFlow
            }}
        >
            {!isTransactionArchived &&
                !isMember &&
                userData.type !== userTypes.client && (
                    <AddSearch
                        onClick={setDropState}
                        dropState={dropState}
                        setDropState={setDropState}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        documentFilter={documentFilter}
                        settingTrxDocumentsFilter={settingTrxDocumentsFilter}
                        searchSuggestions={searchSuggestions}
                        settingSearchSuggestions={settingSearchSuggestions}
                        allDocs={allDocs}
                        tabContent={tabContent}
                        lang={lang}
                    />
                )}
            <div ref={measuredRef}>
                <SimpleBar style={{ height: docsHeight, overflowY: overFlow }}>
                    {!isTransactionArchived &&
                        !isMember &&
                        userData.type !== userTypes.client && (
                            <DocumentDrop
                                dropState={dropState}
                                documents={filteredDocs}
                                addedDocuments={documents}
                                setDropAction={setDropAction}
                                userData={userData}
                                trxId={trxId}
                                trxType={trxType}
                                addDocumentToTrx={addDocumentToTrx}
                                searchText={searchText}
                                searchSuggestions={searchSuggestions}
                                settingSearchSuggestions={settingSearchSuggestions}
                                documentFilter={documentFilter}
                                lang={lang}
                            />
                        )}
                    <DocumentList
                        match={match}
                        documents={documents || []}
                        dropState={dropState}
                        removeDocumentFromTrx={removeDocumentFromTrx}
                        trxId={trxId}
                        userData={userData}
                        setDocumentView={setDocumentView}
                        lang={lang}
                        isTransactionArchived={isTransactionArchived}
                        isMember={isMember}
                        status={status}
                    />
                </SimpleBar>
            </div>
        </div>
    );
};

export default Documents;
