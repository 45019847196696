// React
import React from 'react';

// Packages
import Flatpickr from 'react-flatpickr';

const DateTimePicker = ({
    required,
    className,
    name,
    id,
    placeholder,
    value,
    myStyle,
    options
}) => {
    return (
        <div style={myStyle}>
            <Flatpickr
                autoComplete="off"
                required={required}
                className={className}
                style={{
                    borderColor: value ? 'rgba(0, 201, 167, 0.5)' : ''
                }}
                name={name}
                id={id}
                placeholder={placeholder}
                value={value}
                options={options}
            />
        </div>
    );
};

export default DateTimePicker;
