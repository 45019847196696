import React from 'react';
import cx from 'classnames';

const DocumentsList = ({ documents, selected, documentClickHandler }) => (
    <ul className="list-unstyled u-sidebar--account__list font-weight-semi-bold">
        {documents?.map((item, index) => (
            <li
                className="u-sidebar--account__list-item documents-pack pointer list-unstyled"
                key={item.id || item.position}
            >
                <div
                    className={cx('u-sidebar--account__list-link documents-pack p-3', {
                        active: selected === item.id || selected === item.position
                    })}
                    onClick={() => documentClickHandler(item, index)}
                >
                    {item.title}
                </div>
            </li>
        ))}
    </ul>
);

export default DocumentsList;
