import {
    SET_MODAL_TYPE,
    MODAL_CANCEL,
    DECLINE_NEW_USER_INVITATION_SUCCESS
} from '../actions/types';
import { confirmationDialogTypes } from '../../utils/Constants';

const INITIAL_STATE = {
    modalDialogType: null
};

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MODAL_TYPE:
            return {
                ...state,
                modalDialogType: action.payload
            };

        case MODAL_CANCEL:
            return {
                ...state,
                modalDialogType: null
            };
        case DECLINE_NEW_USER_INVITATION_SUCCESS:
            return {
                ...state,
                modalDialogType: confirmationDialogTypes.declineInviteSuccess
            };
        default:
            return state;
    }
};

export default modalReducer;
