// React
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import MessageModal from './MessageModal';
import MessageItem from './MessageItem';

import { useWindowSize } from '../../hooks/useWindowSize';
import { useLang } from '../../context/LangContext';

const MessagesTab = ({
    savedMessages,
    userMessages,
    removeUserMessage,
    saveUserMessage,
    isDraftTab,
    isSavedTab,
    userId
}) => {
    const {
        bulk_action_btn_label,
        pinn_btn_label,
        delete_btn_label,
        compose_btn_label,
        no_msg_found
    } = useLang()['Messages']['MessagesTab'];

    const [checkedItems, setCheckedItems] = useState([]);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isMessageOpen, setMessageOpen] = useState(false);
    const [activeMessageId, setActiveMessageId] = useState(null);
    const checkboxRef = useRef();

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const { windowHeight } = useWindowSize();
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect()?.top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    useEffect(() => {
        setCheckedItems([]);
    }, [isDraftTab, isSavedTab]);

    const sortedUserMessages = useMemo(
        () =>
            userMessages.sort((a, b) =>
                a.updated_at.seconds < b.updated_at.seconds ? 1 : -1
            ),
        [userMessages]
    );

    const modalContent = useMemo(
        () => userMessages.find(el => el.id === activeMessageId),
        [userMessages, activeMessageId]
    );

    const handleSaveMessage = () => {
        saveUserMessage({ messageIds: checkedItems });
        checkboxRef.current.checked = false;
        setCheckedItems([]);
    };

    const handleRemoveMessage = () => {
        removeUserMessage({ messageIds: checkedItems });
        checkboxRef.current.checked = false;
        setCheckedItems([]);
    };

    const handleClick = id => {
        const newCheckedItems = checkedItems.includes(id)
            ? checkedItems.filter(el => el !== id)
            : [...checkedItems, id];
        setCheckedItems(newCheckedItems);
    };

    const handleSelectAll = e => {
        setCheckedItems(e.target.checked ? userMessages.map(el => el.id) : []);
    };

    const handleItemClick = id => {
        setActiveMessageId(id);
        setMessageOpen(true);
    };

    const handleMessagesTab = () => {
        setMessageOpen(true);
    };

    const toggleIsMessageOpen = () => {
        setMessageOpen(prev => !prev);
    };

    return (
        <>
            <MessageModal
                isMessageOpen={isMessageOpen}
                toggleIsMessageOpen={toggleIsMessageOpen}
                content={modalContent}
                isDraftTab={isDraftTab}
                isSavedTab={isSavedTab}
                setActiveMessageId={setActiveMessageId}
            />
            <div
                className="card border-top-0 border-right-0 border-bottom-0"
                style={{ width: '100%', height: '100%', borderRadius: '0px' }}
            >
                <div className="card-header d-flex align-items-center justify-content-between ">
                    <div className="d-flex align-items-center">
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            ref={checkboxRef}
                            className="notifications__checkbox mr-4 ml-3"
                            style={{
                                float: 'left',
                                color: '#fff',
                                width: '18px',
                                height: '18px'
                            }}
                        />
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle
                                className="btn-sm text-dark py-2"
                                style={{ backgroundColor: '#e7eaf3', border: 'none' }}
                            >
                                {bulk_action_btn_label}
                                <span className="fas fa-angle-down ml-2" />
                            </DropdownToggle>
                            <DropdownMenu
                                className={'animate slideIn'}
                                style={{ width: 'inherit', padding: 0 }}
                            >
                                <DropdownItem
                                    className="dropdown-item"
                                    value="save"
                                    onClick={handleSaveMessage}
                                    style={{
                                        color: '#377dff'
                                    }}
                                >
                                    {pinn_btn_label}
                                </DropdownItem>
                                <DropdownItem
                                    className="dropdown-item"
                                    value="delete"
                                    onClick={handleRemoveMessage}
                                    style={{
                                        color: '#de4437'
                                    }}
                                >
                                    {delete_btn_label}
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="d-flex align-items-center justify-content-end card-header__actions">
                        {!isDraftTab && !isSavedTab && (
                            <button
                                className="btn btn-primary d-flex align-items-center mr-2 px-5"
                                style={{ maxHeight: '36px' }}
                                onClick={handleMessagesTab}
                            >
                                {compose_btn_label}
                            </button>
                        )}
                    </div>
                </div>

                <div className="card-body p-0">
                    <div ref={measuredRef}>
                        <SimpleBar style={{ height: [scrollHeight] - 50 + 'px' }}>
                            <div className="container px-0">
                                {sortedUserMessages.length ? (
                                    sortedUserMessages.map(item => {
                                        const latestMessage = Object.values(
                                            item.posts
                                        ).slice(-1)[0];
                                        return (
                                            <MessageItem
                                                key={item.id}
                                                id={item.id}
                                                isPinned={
                                                    savedMessages?.some(
                                                        el => el.id === item.id
                                                    ) || isSavedTab
                                                }
                                                isNew={
                                                    !isDraftTab &&
                                                    !(item.seen && item.seen[userId])
                                                }
                                                isDraftTab={isDraftTab}
                                                isChecked={checkedItems.includes(item.id)}
                                                handleClick={() => handleClick(item.id)}
                                                handleItemClick={() =>
                                                    handleItemClick(item.id)
                                                }
                                                post={latestMessage}
                                                msgTitle={item.messageTitle}
                                            />
                                        );
                                    })
                                ) : (
                                    <Alert color="info" className="mb-0 mt-2 mx-3">
                                        {no_msg_found}
                                    </Alert>
                                )}
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MessagesTab;
