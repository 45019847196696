import React from 'react';

import { useLang } from '../../../context/LangContext';
import ActionButtons from '../shared/ActionButtons';
import { connect } from 'react-redux';
import { createUserTeam } from '../../../store/actions/Teams';

const AddNewTeam = ({ createUserTeam }) => {
    const { create_label } = useLang()['Settings'];
    const { main_title, no_team } = useLang()['Settings']['Team'];

    return (
        <>
            <div className="card-header team-text__caption">{main_title}</div>
            <div className="card-body text-wrap text-center d-flex align-items-center justify-content-center team-text">
                {no_team}
            </div>
            <ActionButtons
                saveLabel={create_label}
                handleSubmit={() =>
                    createUserTeam({
                        name: 'My Team'
                    })
                }
            />
        </>
    );
};

export default connect(null, { createUserTeam })(AddNewTeam);
