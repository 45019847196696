import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLang } from '../../context/LangContext';

const UploadSignatureTab = ({ setSigningData, type }) => {
    const {
        uploadSignature_label,
        uploadInitials_label,
        dragClick_label,
        clean_buttonLabel,
        next_buttonLabel
    } = useLang()['Documents']['FillingDocument']['SigningDocument'];
    const [doc, setDoc] = useState(null);

    const onDrop = acceptedFiles => {
        if (acceptedFiles.length) {
            const reader = new FileReader();
            reader.onload = event => {
                const base64String = event.target.result;
                setDoc(base64String);
            };
            reader.readAsDataURL(acceptedFiles[0]);
        }
    };

    const nextClickHandler = () => {
        setSigningData(doc);
        setDoc(null);
    };

    const cleanClickHandler = () => {
        setDoc(null);
    };

    const { isDragActive, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.png',
        minSize: 0,
        maxSize: 2097152
    });

    return (
        <div
            className="d-flex flex-column align-items-center"
            style={{ height: '90%', width: '100%' }}
        >
            {!doc ? (
                <>
                    <span style={{ fontSize: '12px', fontWeight: 500 }}>
                        {type === 'signature'
                            ? uploadSignature_label
                            : uploadInitials_label}
                    </span>

                    <div
                        {...getRootProps()}
                        style={{
                            height: '180px',
                            width: '100%',
                            borderRadius: '12px',
                            borderStyle: isDragActive ? 'solid' : 'dashed',
                            borderColor: isDragActive ? '#377dff' : '#e7eaf3'
                        }}
                        className="mt-2 d-flex flex-column justify-content-center align-items-center pointer"
                    >
                        <input {...getInputProps()} name="path" />
                        <span style={{ fontSize: '12px' }}>{dragClick_label}</span>
                    </div>
                </>
            ) : (
                <>
                    <img src={doc} className="img-contain" alt="Uploaded document" />
                    <button
                        className="btn btn-sm bottom-buttons clean-button"
                        onClick={cleanClickHandler}
                    >
                        {clean_buttonLabel}
                    </button>
                </>
            )}

            <button
                disabled={!doc}
                className="btn btn-sm bottom-buttons next-button"
                onClick={nextClickHandler}
            >
                {next_buttonLabel}
            </button>
        </div>
    );
};

export default UploadSignatureTab;
