import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { useLang } from '../../context/LangContext';
import { acceptSigningConsent } from '../../store/actions/DocumentFilling';

const ConsentModal = ({ isConsentAccepted, acceptSigningConsent }) => {
    const {
        consent_modalTitle,
        consent_modalContent,
        agree_buttonLabel,
        disagree_buttonLabel
    } = useLang()['Documents']['FillingDocument']['SigningDocument'];

    return (
        <Modal
            centered
            isOpen={!isConsentAccepted}
            className="signingConsentModal"
            modalTransition={{ timeout: 200 }}
        >
            <ModalHeader>{consent_modalTitle}</ModalHeader>
            <ModalBody>{consent_modalContent}</ModalBody>
            <ModalFooter>
                {/* TODO: add back handler */}
                <Button onClick={() => {}}>{disagree_buttonLabel}</Button>
                <Button color="primary" onClick={acceptSigningConsent}>
                    {agree_buttonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default connect(null, { acceptSigningConsent })(ConsentModal);
