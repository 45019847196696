import React, { useState, useEffect } from 'react';
import { useLang } from '../../context/LangContext';

// Components
import { Button } from 'reactstrap';
import SelectionStep from './SelectionStep';
import SignersStep from './SignersStep';

// Packages
import { Formik } from 'formik';
import * as Yup from 'yup';

// Constants
import {
    confirmationDialogTypes,
    legalNameRegExp,
    signerTypes
} from '../../utils/Constants';

// Redux
import { connect } from 'react-redux';
import { saveSettingsForFilling } from '../../store/actions/DocumentFilling';
import {
    cancelDetailsListener,
    gettingTransactionDetails
} from '../../store/actions/Transactions';
import { setConfirmModalType } from '../../store/actions/Modal';

const setupFillingSteps = ['requiredFieldsSelect', 'signersSelect', 'confirmSigners'];

const SetupSteper = ({
    trxDocId,
    trxId,
    fillingSettings,
    documentTitle,
    saveSettingsForFilling,
    setSuccessHandler,
    setSettingsStepVisible,
    cancelDetailsListener,
    gettingTransactionDetails,
    transactionParties,
    transactionTeam,
    setConfirmModalType
}) => {
    const {
        first_question,
        second_question,
        third_question,
        myself_label,
        myselfAndOthers_label,
        others_label,
        current_step,
        go_back,
        save_settings
    } = useLang()['Documents']['FillingDocument']['SetupFilling'];
    const { errorMessage } = useLang()['Constants'];
    const [step, setStep] = useState(0);

    useEffect(() => {
        if (!transactionParties) {
            gettingTransactionDetails({
                trxId: trxId,
                detailsFlag: false
            });
        }
    }, [transactionParties, gettingTransactionDetails, trxId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => cancelDetailsListener, []);

    const stepContent = [
        {
            question: first_question,
            options: [
                { value: 'myself', label: myself_label, icon: 'fa-user-large' },
                {
                    value: 'myselfAndOthers',
                    label: myselfAndOthers_label,
                    icon: 'fa-users'
                }
            ]
        },
        {
            question: second_question,
            options: [
                { value: 'myself', label: myself_label, icon: 'fa-user-large' },
                {
                    value: 'myselfAndOthers',
                    label: myselfAndOthers_label,
                    icon: 'fa-users'
                },
                { value: 'others', label: others_label, icon: 'fa-user-large' }
            ]
        },
        {
            question: third_question
        }
    ];

    const backHandler = () => {
        setStep(prev => prev - 1);
    };

    const initialValues = {
        requiredFieldsSelect: fillingSettings?.required_fields_select,
        signersSelect: fillingSettings?.signers_select,
        confirmSigners: fillingSettings?.confirm_signers.map(el => ({
            ...el,
            legalName: el.legal_name
        })),
        forwardDocument: fillingSettings?.forward_document
    };

    const validationSchema = Yup.object({
        confirmSigners: Yup.array()
            .test('maxBrokers', errorMessage.confirmSigners.maxBrokers, arr => {
                const brokerCount = arr.filter(
                    obj => obj.type === signerTypes.broker
                ).length;
                return brokerCount <= 2;
            })
            .test(
                'maxSalesAssociates',
                errorMessage.confirmSigners.maxSalesAssociates,
                arr => {
                    const agentsCount = arr.filter(
                        obj => obj.type === signerTypes.sales_associate
                    ).length;
                    return agentsCount <= 2;
                }
            )
            .test('maxParties', errorMessage.confirmSigners.maxParties, arr => {
                const partiesCount = arr.filter(
                    obj => obj.type === signerTypes.party
                ).length;
                return partiesCount <= 4;
            })
            .of(
                Yup.object().shape({
                    legalName: Yup.string()
                        .matches(legalNameRegExp.format, errorMessage.fullName.valid)
                        .required(errorMessage.fullName.required),
                    email: Yup.string()
                        .lowercase()
                        .email(errorMessage.email.valid)
                        .required(errorMessage.email.required),
                    type: Yup.string().required(errorMessage.signerType.required)
                })
            )
    });

    const handleSubmit = values => {
        setSuccessHandler(() => () => {
            setSettingsStepVisible(false);
            setConfirmModalType(confirmationDialogTypes.updateFieldsWithNewSigners);
        });
        saveSettingsForFilling({
            trxDocId,
            fillingSettings: { ...values, opposingAgent: fillingSettings?.opposing_agent }
        });
    };

    return (
        <div
            id="main"
            className="size container push-main mb-4"
            style={{ minHeight: '70vh', height: '100%' }}
        >
            <div className="col-12 no-gutters flex">
                <div className="card" style={{ height: '100%' }}>
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h1 className="h5 font-weight-medium mb-0 ml-2">
                            {documentTitle}
                        </h1>
                    </div>
                    <div
                        className="d-flex justify-content-center flex-column align-items-center"
                        style={{ height: '100%' }}
                    >
                        <div className="mt-4" style={{ fontWeight: 500 }}>
                            {stepContent[step].question}
                        </div>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnMount
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleSubmit, setFieldValue, isValid }) => (
                                <form
                                    className="d-flex justify-content-center flex-column"
                                    style={{ width: '100%' }}
                                    onSubmit={handleSubmit}
                                >
                                    {step === 2 ? (
                                        <SignersStep
                                            opposingAgent={
                                                fillingSettings?.opposing_agent
                                            }
                                            setFieldValue={setFieldValue}
                                            setSuccessHandler={setSuccessHandler}
                                            parties={transactionParties}
                                            team={transactionTeam}
                                        />
                                    ) : (
                                        <SelectionStep
                                            stepContent={stepContent[step].options}
                                            selectedItem={values[setupFillingSteps[step]]}
                                            step={step}
                                            fillingSettings={fillingSettings}
                                            setStep={setStep}
                                            name={setupFillingSteps[step]}
                                            setFieldValue={setFieldValue}
                                            transactionTeam={transactionTeam}
                                        />
                                    )}
                                    {!(
                                        step === 1 &&
                                        values.signersSelect === 'noSignatures'
                                    ) && (
                                        <div className="current-step">
                                            {current_step.replace('stepNumber', step + 1)}
                                        </div>
                                    )}
                                    <div className="position-absolute right-bottom-corner d-flex align-items-center">
                                        {!!step && (
                                            <div
                                                onClick={backHandler}
                                                className="pointer text-primary"
                                            >
                                                {go_back}
                                            </div>
                                        )}
                                        {(step === 2 ||
                                            (step === 1 &&
                                                values.signersSelect ===
                                                    'noSignatures')) && (
                                            <Button
                                                className="ml-4"
                                                color="primary"
                                                type="submit"
                                                onClick={handleSubmit}
                                                disabled={!isValid}
                                            >
                                                {save_settings}
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ transactions }) => {
    const { transactionDetails } = transactions;
    return {
        transactionParties: transactionDetails?.parties,
        transactionTeam: transactionDetails?.team
    };
};

export default connect(mapStateToProps, {
    saveSettingsForFilling,
    gettingTransactionDetails,
    cancelDetailsListener,
    setConfirmModalType
})(SetupSteper);
