import {
    all,
    fork,
    // take,
    // cancelled,
    takeLatest,
    // race,
    put,
    call,
    select
} from 'redux-saga/effects';

import {
    REMOVING_PREFERENCE_TASK,
    ADDING_PREFERENCE_TASK,
    UPDATE_PREFERENCE_USER_DOCUMENTS,
    UPDATING_PREFERENCE_USER_LANGUAGES,
    UPDATE_PREFERENCE_USER_NOTIFICATIONS,
    UPDATING_PREFERENCE_INITIAL_TASKS
} from '../actions/types';

import {
    taskRemovalSuccess,
    taskAdditionSuccess,
    taskRemovalFailure,
    taskAdditionFailure
} from '../actions/Preferences';

import {
    db
    //  timeStampNow,
    //  timeStampJs
} from '../../config/Firebase';

import * as selectors from './Selectors';

// Loggers
import { log } from '../../utils/Loggers';

import { setConfirmModalType } from '../actions/Modal';
import { confirmationDialogTypes } from '../../utils/Constants';
import { setLanguageType } from '../actions/Lang';

// const transactions = db.collection('transactions');
const users = db.collection('users');

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////// Removing Preference Task ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const removeUserTaskRequest = ({ tasks, type, userData }) => {
    const ref = users.doc(userData.id);
    const preferences = userData.preferences;
    const org = userData.active_org_id;
    const newPref = {
        ...preferences,
        [`${org}`]: [...preferences[`${org}`]]
    };
    newPref[`${org}`].initial_tasks[type] = tasks;
    return new Promise((resolve, reject) => {
        ref.update({
            preferences: { ...newPref }
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* removeUserTask({ payload }) {
    const { tasks, type } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        removeUserTaskRequest({ tasks, type, userData })
    );
    if (res) {
        yield put(taskRemovalSuccess());
    } else {
        yield put(taskRemovalFailure());
        log('Preferences Error: removing task from initial tasks in preferences (FS)', {
            error,
            tasks,
            type
        });
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////// Adding Preference Task ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const addPreferenceTaskRequest = ({ buyerTasks, listingTasks, userData }) => {
    const ref = users.doc(userData.id);
    const preferences = userData.preferences;
    const org = userData.active_org_id;
    const newPref = {
        ...preferences,
        [`${org}`]: [...preferences[`${org}`]]
    };
    newPref[`${org}`].initial_tasks.buyer = buyerTasks;
    newPref[`${org}`].initial_tasks.seller = listingTasks;
    return new Promise((resolve, reject) => {
        ref.update({
            preferences: { ...newPref }
        })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* addPreferenceTask({ payload }) {
    const { buyerTasks, listingTasks } = payload;
    const userData = yield select(selectors._userData);
    const { res, error } = yield call(() =>
        addPreferenceTaskRequest({ buyerTasks, listingTasks, userData })
    );
    if (res) {
        yield put(taskAdditionSuccess());
    } else {
        yield put(taskAdditionFailure());
        log('Preferences Error: adding task to initial tasks in preferences (FS)', {
            error,
            buyerTasks,
            listingTasks
        });
    }
}

const updatePreferenceUserNotificationsRequest = ({ notificationsList, userData }) => {
    const ref = users.doc(userData.id);
    const org = userData.active_org_id;
    const path = `preferences.${org}.notifications`;
    return new Promise((resolve, reject) => {
        return ref
            .update({ [path]: notificationsList })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updatePreferenceUserNotifications({ payload }) {
    const { notificationsList, userData } = payload;
    if (payload) {
        const { res, error } = yield call(updatePreferenceUserNotificationsRequest, {
            notificationsList,
            userData
        });
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
            log('Success');
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log("Can't update", {
                error
            });
        }
    }
}

const updatePreferenceUserDocumentsRequest = ({ buyerDocs, sellerDocs, userData }) => {
    const ref = users.doc(userData.id);
    const org = userData.active_org_id;
    const path = `preferences.${org}.documents`;

    return new Promise((resolve, reject) => {
        return ref
            .update({ [path]: { buyer: buyerDocs, seller: sellerDocs } })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updatePreferenceUserDocuments({ payload }) {
    const { buyerDocs, sellerDocs, userData, removeBuyerDocs, removeSellerDocs } =
        payload;
    const newBuyerDocs =
        buyerDocs &&
        buyerDocs.filter(i => !removeBuyerDocs.find(f => f.title === i.title));
    const newSellerDocs =
        sellerDocs &&
        sellerDocs.filter(i => !removeSellerDocs.find(f => f.title === i.title));
    if (payload) {
        const { res, error } = yield call(() =>
            updatePreferenceUserDocumentsRequest({
                buyerDocs: newBuyerDocs,
                sellerDocs: newSellerDocs,
                userData: userData
            })
        );
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
            log('Success');
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log("Can't update", {
                error
            });
        }
    }
}

const updatePreferenceUserLanguagesRequest = ({ checkedLanguages, userData }) => {
    const ref = users.doc(userData.id);
    return new Promise((resolve, reject) => {
        return ref
            .update({ languages: checkedLanguages })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updatePreferenceUserLanguages({ payload }) {
    const { itemList, userData } = payload;
    const checkedLanguages = itemList && itemList.filter(lang => lang.checked);
    const defaultLanguage = checkedLanguages.filter(lang => lang.default);

    yield put(setLanguageType({ origin: defaultLanguage[0].id }));

    if (payload) {
        const { res, error } = yield call(updatePreferenceUserLanguagesRequest, {
            checkedLanguages,
            userData
        });
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
            log('Success');
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log("Can't update", {
                error
            });
        }
    }
}

const updatePreferenceInitialTasksRequest = ({ buyerTasks, sellerTasks, userData }) => {
    const ref = users.doc(userData.id);
    const org = userData.active_org_id;
    const path = `preferences.${org}.initial_tasks`;

    return new Promise((resolve, reject) => {
        return ref
            .update({ [path]: { buyer: buyerTasks, seller: sellerTasks } })
            .then(() => {
                resolve({ res: true });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export function* updatePreferenceInitialTasks({ payload }) {
    const { buyerTasks, sellerTasks, userData, removeBuyerTasks, removeSellerTasks } =
        payload;
    const newBuyerTasks =
        buyerTasks &&
        buyerTasks.filter(i => !removeBuyerTasks.find(f => f.title === i.title));
    const newSellerTask =
        sellerTasks &&
        sellerTasks.filter(i => !removeSellerTasks.find(f => f.title === i.title));
    if (payload) {
        const { res, error } = yield call(() =>
            updatePreferenceInitialTasksRequest({
                buyerTasks: newBuyerTasks,
                sellerTasks: newSellerTask,
                userData: userData
            })
        );
        if (res) {
            yield put(setConfirmModalType(confirmationDialogTypes.success));
            log('Success');
        } else {
            yield put(setConfirmModalType(confirmationDialogTypes.failed));
            log('Teams Error: editing existing team', {
                error
            });
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// Action Creators For Root Saga ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* removingUserTask() {
    yield takeLatest(REMOVING_PREFERENCE_TASK, removeUserTask);
}

export function* addingPreferenceTask() {
    yield takeLatest(ADDING_PREFERENCE_TASK, addPreferenceTask);
}

export function* updatingPreferenceUserDocuments() {
    yield takeLatest(UPDATE_PREFERENCE_USER_DOCUMENTS, updatePreferenceUserDocuments);
}

export function* updatingPreferenceInitialTasks() {
    yield takeLatest(UPDATING_PREFERENCE_INITIAL_TASKS, updatePreferenceInitialTasks);
}

export function* updatingPreferenceUserNotifications() {
    yield takeLatest(
        UPDATE_PREFERENCE_USER_NOTIFICATIONS,
        updatePreferenceUserNotifications
    );
}

export function* updatingPreferenceUserLanguages() {
    yield takeLatest(UPDATING_PREFERENCE_USER_LANGUAGES, updatePreferenceUserLanguages);
}

export default function* rootSaga() {
    yield all([
        fork(removingUserTask),
        fork(addingPreferenceTask),
        fork(updatingPreferenceUserDocuments),
        fork(updatingPreferenceUserLanguages),
        fork(updatingPreferenceUserNotifications),
        fork(updatingPreferenceInitialTasks)
    ]);
}
