// Invitations
export const _newAuthUser = state => state.invitation.newAuthUser;
export const _userInvitation = state => state.invitation.userInvitation;

// Org
export const _activeOrg = state => state.org.activeOrg;
export const _existingInvites = state => state.org.matches;
export const _nonExistingInvites = state => state.org.nonMatches;

// Pipeline
export const _pipelineContacts = state => state.pipeline.pipelineContacts;

// Transactions
export const _activeTransactions = state => state.transactions.userTransactions;
export const _closedTransactions = state => state.transactions.closedTransactions;
export const _archivedTransactions = state => state.transactions.archivedTransactions;
export const _currentTransaction = state => state.transactions.transactionDetails;

// Messages
export const _userMessages = state => state.messages.userMessages;

// User
export const _userData = state => state.user.userData;
export const _activeTrxList = state => state.user.userData.transactions;
export const _authUser = state => state.auth.authUser;
export const _userTeam = state => state.teams.team;

// Preferences
export const _activeTab = state => state.preferences.activeTab;
// DocumentFilling
export const _documentForFilling = state => state.documentFilling.documentForFilling;

// Connections
export const _userConnections = state => state.connections.userConnections;
