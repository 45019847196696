// React
import React, { useCallback, useEffect, useState } from 'react';

// Packages
import SimpleBar from 'simplebar-react';

// Redux
import { connect } from 'react-redux';
import { updatePreferenceUserNotifications } from '../../../store/actions/Preferences';

// Utils
import { checkChanges } from '../../../utils/Helpers';

// Context
import { useLang } from '../../../context/LangContext';

// Components
import ActionButtons from '../shared/ActionButtons';

const Notifications = ({ updateUserNotifications, userData, setHasChanges }) => {
    const { options, tips } = useLang()['Settings']['Preferences']['Notifications'];
    const { save_label, cancel_label } = useLang()['Common']['ActionButtons'];

    const notificationsText = {
        client_invite: options['client_invite'],
        new_task: options['new_task'],
        docs_signed: options['docs_signed'],
        offer_made: options['offer_made']
    };

    const userOrg = userData.active_org_id;
    const userNotifications =
        userData && userData.preferences[userOrg].notifications
            ? userData.preferences[userOrg].notifications.slice()
            : [];

    const addedCheckedItems = userNotifications
        ? userNotifications.map(item => {
              return { ...item, title: notificationsText[item.id] };
          })
        : [];

    const initialList = addedCheckedItems.map(lang => {
        return { ...lang };
    });

    const [notificationsList, setNotificationsList] = useState(initialList);
    const [isDisabled, setIsDisabled] = useState(true);

    const checkDisableButtons = useCallback(() => {
        const languagesChanged = checkChanges(initialList, notificationsList);
        setIsDisabled(!languagesChanged);
    }, [notificationsList, initialList]);

    const handleReset = () => {
        setNotificationsList(initialList);
    };

    useEffect(() => {
        checkDisableButtons();
    }, [checkDisableButtons]);

    useEffect(() => {
        setHasChanges(!isDisabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisabled]);

    useEffect(() => {
        return handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = id => {
        if (id) {
            const modifiedList = [...notificationsList];
            const itemPos = modifiedList.map(item => item.id).indexOf(id);
            const prevValue = modifiedList[itemPos].checked;
            modifiedList[itemPos].checked = !prevValue;
            setNotificationsList(modifiedList);
            checkDisableButtons();
        }
    };

    return (
        <>
            <div
                className="card-body"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <SimpleBar
                    className="container pr-5"
                    style={{ overflowY: 'clip', height: '400px' }}
                >
                    <div style={{ maxWidth: '600px', margin: 'auto' }}>
                        <div className="pt-3 text-center">
                            <p className="text-wrap">{tips}</p>
                        </div>
                        <div style={{ padding: '58px' }}>
                            {notificationsList.map((item, index) => (
                                <form action="" key={`${item.name}-${item.id}`}>
                                    <div className="form-group-check">
                                        <input
                                            type="checkbox"
                                            id={item.id}
                                            name={item.name}
                                            value={item.checked}
                                            checked={item.checked}
                                            onChange={() => handleOnChange(item.id)}
                                            className="notifications__checkbox"
                                            style={{ float: 'right', color: '#fff' }}
                                        />
                                        <label
                                            htmlFor={item.id}
                                            className="notifications__text"
                                        >
                                            {item.title}
                                        </label>
                                    </div>
                                </form>
                            ))}
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <ActionButtons
                cancelLabel={cancel_label}
                saveLabel={save_label}
                handleSubmit={() => {
                    updateUserNotifications({ notificationsList, userData });
                }}
                disabled={isDisabled}
                noCancel={isDisabled}
                handleClose={() => handleReset()}
            />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData, type } = user;
    return { userData, type };
};
export default connect(mapStateToProps, {
    updateUserNotifications: updatePreferenceUserNotifications
})(Notifications);
