import {
    SET_USER_ORG,
    UPDATE_ORG_LOCATION,
    UPDATE_ORG_LOCATION_SUCCESS,
    UPDATE_ORG_LOCATION_FAILURE,
    PREPARE_MEMBER_INVITATIONS,
    SET_PREPARED_INVITATIONS,
    WRITE_SEND_INVITATIONS,
    ORG_INVITE_SUCCESS,
    ORG_INVITE_FAILURE,
    SET_ORG_INVITATIONS,
    UPDATE_ORG_MEMBERS_STATUS,
    SAVE_ORG_MEMBERS_DETAILS,
    REMOVE_ORG_MEMBER,
    REMOVE_ORG_INVITATIONS
} from './types';

export const storeOrgData = org => {
    return {
        type: SET_USER_ORG,
        payload: org
    };
};

export const updateOrgLocation = location => {
    return {
        type: UPDATE_ORG_LOCATION,
        payload: location
    };
};

export const updateOrgLocationSuccess = () => {
    return {
        type: UPDATE_ORG_LOCATION_SUCCESS
    };
};

export const updateOrgLocationFailure = error => {
    return {
        type: UPDATE_ORG_LOCATION_FAILURE,
        payload: error
    };
};

export const addingOrganizationMember = members => {
    return {
        type: PREPARE_MEMBER_INVITATIONS,
        payload: members
    };
};

export const setPreparedInvitations = invitations => {
    return {
        type: SET_PREPARED_INVITATIONS,
        payload: invitations
    };
};

export const orgInvitationSuccess = () => {
    return {
        type: ORG_INVITE_SUCCESS
    };
};

export const orgInvitationFailure = error => {
    return {
        type: ORG_INVITE_FAILURE,
        payload: error
    };
};

export const executeInvitatonSend = () => {
    return {
        type: WRITE_SEND_INVITATIONS
    };
};

export const setOrgInvitations = invites => {
    return {
        type: SET_ORG_INVITATIONS,
        payload: invites
    };
};

export const updateOrgMembersStatus = payload => {
    return {
        type: UPDATE_ORG_MEMBERS_STATUS,
        payload
    };
};

export const saveOrgMembersDetails = payload => {
    return {
        type: SAVE_ORG_MEMBERS_DETAILS,
        payload
    };
};

export const removeOrgMember = payload => {
    return {
        type: REMOVE_ORG_MEMBER,
        payload
    };
};

export const removeOrgInvitations = payload => {
    return {
        type: REMOVE_ORG_INVITATIONS,
        payload
    };
};
