// React
import React, { useEffect, useMemo, useState, useCallback } from 'react';

// Assets
import defaultTransactionPhoto from '../../assets/img/default_transaction_photo.png';

// Components
import SimpleBar from 'simplebar-react';
import TransactionBadge from '../common/TransactionBadge';
import AddMembersToTrxModal from '../common/AddMembersToTrxModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import AddOpposingToTrxModal from '../common/AddOpposingToTrxModal';

// Context
import { useLang } from '../../context/LangContext';

// Firebase
import { timeStampNowSeconds } from '../../config/Firebase';

// Hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// Packages
import { connect } from 'react-redux';
import { Progress } from 'reactstrap';
import Avatar from 'react-avatar';
import numeral from 'numeral';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { isTablet } from 'react-device-detect';
import NumberFormat from 'react-number-format';
import FsLightbox from 'fslightbox-react';

// Redux - Actions, Reducers, Sagas
import {
    updateTransactionMembers,
    updatingOpposingMembers,
    removingOpposingMember
} from '../../store/actions/Transactions';

// Utils
import { daysOnMls, daysTillClosing } from '../../utils/Helpers';
import { confirmationDialogTypes } from '../../utils/Constants';

const TransactionMeta = ({
    transaction,
    avatars,
    isClient,
    companyContacts,
    teamMembers,
    currentUserId,
    updateTransactionMembers,
    updatingOpposingMembers,
    removingOpposingMember,
    brokerages
}) => {
    const [avatarImgs, setAvatarImgs] = useState(null);
    const [teamExpand, setTeamExpand] = useState(false);
    const [isIpadRegular, setIsIpadRegular] = useState();
    const [expandHeight, setExpandHeight] = useState(0);
    const { windowHeight, windowWidth } = useWindowSize();
    const [userExpandIndex, setUserExpandIndex] = useState([]);
    const [isGalleryOpened, setIsGalleryOpened] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isOpposingModalOpen, setOpposingModalOpen] = useState(false);
    const iPadProPixels = 2390;
    const totalWindowPixels = windowHeight + windowWidth;

    const {
        display,
        type,
        closing_date,
        price,
        parties,
        team,
        opposing,
        primary_client,
        primary_agent,
        primary_broker,
        mls_list_date,
        under_contract_date,
        photos
    } = transaction || {};
    const {
        seller_agent_label,
        buyer_agent_label,
        days_market_label,
        closing_in_label,
        days_label,
        no_close_date_label,
        opposing_label,
        team_label,
        seller_label,
        buyer_label,
        primary_agent_label,
        primary_broker_label,
        add_opposing_button
    } = useLang()['TransactionDetails']['TransactionMeta'];

    const opposingTitle = () => {
        if (type === 'buyer') return seller_agent_label;
        if (type === 'seller') return buyer_agent_label;
    };

    const trxOwnerId = primary_agent ? primary_agent.id : primary_broker.id;

    const isUserTrxOwner = currentUserId === trxOwnerId;
    const daysOnMarketHeader = type === 'seller' && !closing_date && mls_list_date;
    const measuredRef = useCallback(
        node => {
            if (node?.getBoundingClientRect().top && windowHeight) {
                setScrollHeight(windowHeight - node.getBoundingClientRect().top);
            }
        },
        [windowHeight]
    );

    // const shortTitle = title => {
    //     const cleanString = title.replace("'", '');
    //     const shortString = cleanString.match(/\b(\w)/g).join('');
    //     return shortString;
    // };

    const progress = closing_date?.seconds
        ? 100 -
          Math.round(
              ((closing_date?.seconds - timeStampNowSeconds()) /
                  (closing_date?.seconds - under_contract_date?.seconds)) *
                  100
          )
        : 0;

    const clients = transaction
        ? [
              primary_client,
              ...parties.filter(party => {
                  return party.id !== primary_client.id;
              })
          ]
        : [];

    const members = transaction
        ? primary_agent
            ? (isClient ? [] : [primary_agent]).concat(
                  team.filter(member => member.id !== primary_agent.id)
              )
            : (isClient ? [] : [primary_broker]).concat(
                  team.filter(member => member.id !== primary_broker.id)
              )
        : [];

    const initialHeight =
        (closing_date ? 570 : 596) + clients.length * 58 + opposing.length * 58;

    const teamExpansion = teamExpand ? (team?.length > 1 ? 32 * team?.length : 0) : 0;

    const clientExpansion = userExpandIndex.length * 42;

    const handleUserExpand = id => {
        const newArr = [...userExpandIndex];
        const inArray = userExpandIndex.includes(id);
        if (inArray) {
            setUserExpandIndex(newArr.filter(item => item !== id));
            setExpandHeight(expandHeight - 42);
        } else {
            const user = isClient
                ? team.filter(item => item.id === id)[0]
                : parties.filter(item => item.id === id)[0];
            if (user && user.email.trim() === '' && user.phone.trim() === '') return;
            setUserExpandIndex([...userExpandIndex, id]);
            setExpandHeight(expandHeight + 42);
        }
    };

    const handleTeamExpand = () => {
        setTeamExpand(!teamExpand);
        if (team.length > 1)
            setExpandHeight(
                expandHeight +
                    (teamExpand
                        ? expandHeight - team.length * 32
                        : expandHeight + team.length * 32)
            );
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const toggleIsModalOpen = () => {
        setModalOpen(prev => !prev);
    };

    const isPrimaryAgentExist = useMemo(
        () => Boolean(Object.values(primary_agent).length),
        [primary_agent]
    );

    const handleRemoveMember = id => {
        updateTransactionMembers({
            members: team.filter(member => member.id !== id),
            isUpdatingFromList: true
        });
    };

    useEffect(() => {
        if (avatars && transaction) {
            setAvatarImgs(avatars);
        }
    }, [avatars, transaction]);

    useEffect(() => {
        setIsIpadRegular(isTablet && totalWindowPixels < iPadProPixels);
    }, [totalWindowPixels, iPadProPixels]);

    useEffect(() => {
        setExpandHeight(teamExpansion + clientExpansion);
    }, [teamExpansion, clientExpansion, setExpandHeight]);

    if (!transaction) return null;

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.deleteMemberFromTrx}
            />
            <AddMembersToTrxModal
                isModalOpen={isModalOpen}
                toggleIsModaOpen={toggleIsModalOpen}
                teamMembers={teamMembers}
                companyContacts={companyContacts}
                trxTeam={team}
                trxOwnerId={trxOwnerId}
                updateTransactionMembers={updateTransactionMembers}
            />
            <AddOpposingToTrxModal
                isOpposingModalOpen={isOpposingModalOpen}
                setOpposingModalOpen={setOpposingModalOpen}
                opposing={transaction?.opposing}
                trxOwnerId={trxOwnerId}
                updatingOpposingMembers={updatingOpposingMembers}
                brokerages={brokerages}
            />
            <div
                className="row no-gutters d-xl-none mb-3"
                style={{
                    maxHeight: windowWidth < 1024 ? '280px' : '135px'
                }}
            >
                <div className="row">
                    <div
                        className="col-6 pr-3 col-lg-5"
                        style={{
                            maxHeight: !isIpadRegular ? '135px' : '',
                            height: !isIpadRegular ? '135px' : ''
                        }}
                    >
                        <div className={`card ${!isIpadRegular ? 'h-100' : ''}`}>
                            <div className="row no-gutters align-items-center justify-content-between h-100">
                                <div className="col-12 col-lg-6 pr-lg-3 h-100">
                                    <div className="position-relative h-100">
                                        <img
                                            className="rounded-top rounded-lg-left w-100 h-100"
                                            src={display}
                                            alt="property main"
                                        />
                                        <span
                                            className={`position-absolute badge badge-pill badge-${type} top-0 left-0 m-1`}
                                        >
                                            {type === 'seller'
                                                ? seller_label
                                                : buyer_label}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 pr-lg-3">
                                    <div className="text-center">
                                        <div className="row pb-2 pb-lg-3">
                                            <div className="col-12">
                                                {daysOnMarketHeader ? (
                                                    <>
                                                        <span className="d-block col-12 font-size-2 font-weight-semi-bold ">
                                                            {daysOnMls(
                                                                mls_list_date.seconds
                                                            )}
                                                        </span>
                                                        {isIpadRegular ? (
                                                            <small className="d-block col-12 text-muted">
                                                                {days_market_label}
                                                            </small>
                                                        ) : (
                                                            <span className="d-block col-12 text-muted">
                                                                {days_market_label}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    <div className="d-block">
                                                        <div className="progress mb-2">
                                                            <Progress
                                                                bar
                                                                value={`${progress}`}
                                                                max="100"
                                                                color="success"
                                                            />
                                                        </div>
                                                        {closing_date?.seconds ? (
                                                            <span
                                                                className="d-block font-weight-medium"
                                                                style={{
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                {`${closing_in_label} ${daysTillClosing(
                                                                    closing_date.seconds
                                                                )} ${days_label}`}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className="d-block font-weight-medium"
                                                                style={{
                                                                    textAlign: 'center',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                {no_close_date_label}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* {transaction && transaction.closing_date && (
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <span className="font-size-1">
                                                        Closing:
                                                    </span>
                                                    <span className="font-weight-medium font-size-1">
                                                        {closing_date
                                                            ? `${formatCardDate(
                                                                  closing_date.seconds
                                                              )}`
                                                            : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        )} */}
                                        <div className="row mb-2 mb-lg-0">
                                            <div className="col-12">
                                                <div className="mb-0">
                                                    <span className="align-top">$</span>
                                                    <span className="font-size-2 font-weight-medium text-lh-sm">
                                                        {numeral(price).format('0,0')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-6 col-lg-7 pr-0 mr-0"
                        style={{
                            height: !isIpadRegular ? '135px' : '280px',
                            maxHeight: !isIpadRegular ? '135px' : '280px'
                        }}
                    >
                        <div
                            className="row card w-100 h-100 p-0"
                            style={{ overflowY: 'scroll' }}
                        >
                            <div className="row d-xl-none justify-flex-start mr-0 ml-0">
                                {(!isClient
                                    ? clients
                                    : isPrimaryAgentExist
                                    ? [primary_agent]
                                    : [primary_broker]
                                ).map(({ first_name, last_name, id, email, phone }) => (
                                    <div
                                        className="pt-2 pb-0 ml-3 align-items-center"
                                        style={{
                                            zIndex: 1000
                                        }}
                                        key={id}
                                    >
                                        <div
                                            className="row mr-0 ml-0 align-items-center"
                                            onClick={() => handleUserExpand(id)}
                                        >
                                            <div className="d-inline-block u-sm-avatar mr-3">
                                                {avatarImgs ? (
                                                    <>
                                                        {avatarImgs.findIndex(
                                                            user => user.id === id
                                                        ) >= 0 &&
                                                        avatarImgs[
                                                            avatarImgs.findIndex(
                                                                user => user.id === id
                                                            )
                                                        ].url ? (
                                                            <img
                                                                className="img-fluid rounded-circle"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                src={
                                                                    avatarImgs[
                                                                        avatarImgs.findIndex(
                                                                            user =>
                                                                                user.id ===
                                                                                id
                                                                        )
                                                                    ].url
                                                                }
                                                                alt={`${first_name} ${last_name}`}
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                className={`btn btn-sm btn-icon btn-soft-primary rounded-circle mr-1 ${
                                                                    phone
                                                                        ? 'cursor-pointer'
                                                                        : email
                                                                        ? 'cursor-pointer'
                                                                        : ''
                                                                }`}
                                                                name={`${first_name} ${last_name}`}
                                                                unstyled
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <Avatar
                                                        className={`btn btn-sm btn-icon btn-soft-primary rounded-circle mr-1 ${
                                                            phone
                                                                ? 'cursor-pointer'
                                                                : email
                                                                ? 'cursor-pointer'
                                                                : ''
                                                        }`}
                                                        name={`${first_name} ${last_name}`}
                                                        unstyled
                                                    />
                                                )}
                                            </div>
                                            <div className="d-inine-block media-body">
                                                <span>{`${first_name} ${last_name} `}</span>
                                                {isClient && (
                                                    <span className="badge badge-buyer">
                                                        {isPrimaryAgentExist
                                                            ? primary_agent_label
                                                            : primary_broker_label}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <motion.div
                                            className="d-block"
                                            style={{
                                                width: '165px',
                                                pointerEvents: 'none'
                                            }}
                                            animate={{
                                                opacity: userExpandIndex.includes(id)
                                                    ? 1
                                                    : 0,

                                                height: userExpandIndex.includes(id)
                                                    ? 'auto'
                                                    : '0px'
                                            }}
                                            transition={{
                                                type: 'spring',
                                                delay: 0,
                                                stiffness: 500,
                                                damping: 60,
                                                mass: 1
                                            }}
                                        >
                                            {phone && (
                                                <div className="d-flex column align-items-center ml-2">
                                                    <span className="fas fa-phone text-muted mr-2" />
                                                    <span
                                                        className="d-inline-block font-size-1 text-muted"
                                                        style={{
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={phone}
                                                            displayType={'text'}
                                                            format="###.###.####"
                                                            mask="_"
                                                        />
                                                    </span>
                                                </div>
                                            )}
                                            {email && (
                                                <div className="d-flex column align-items-center ml-2">
                                                    <span className="far fa-envelope text-muted mr-2" />
                                                    <span
                                                        className="d-inline-block font-size-1 text-muted"
                                                        style={{
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            width: '155px'
                                                        }}
                                                    >
                                                        {email}
                                                    </span>
                                                </div>
                                            )}
                                        </motion.div>
                                    </div>
                                ))}
                                <ul
                                    className={`list-inline pt-2 mb-0  ${
                                        teamExpand && !isIpadRegular
                                            ? 'ml-3'
                                            : 'py-2 px-3'
                                    }`}
                                >
                                    {members.map(
                                        ({ first_name, last_name, id, email }, index) => (
                                            <motion.li
                                                className="list-inline-item mr-1 mt-1"
                                                style={{ cursor: 'pointer' }}
                                                key={id}
                                                initial={true}
                                                animate={{
                                                    x: 0,
                                                    y: teamExpand ? index * 0 : 0
                                                }}
                                                transition={{
                                                    type: 'spring',
                                                    delay: 0,
                                                    stiffness: 262,
                                                    damping: 26,
                                                    mass: 1
                                                }}
                                            >
                                                <div className="d-flex align-items-center list-inline-item">
                                                    <div className="u-sm-avatar u-sm-avatar--bordered rounded-circle p-0">
                                                        {avatarImgs ? (
                                                            <div
                                                                onClick={handleTeamExpand}
                                                            >
                                                                {avatarImgs.findIndex(
                                                                    user => user.id === id
                                                                ) >= 0 ? (
                                                                    <img
                                                                        className="img-fluid rounded-circle"
                                                                        src={
                                                                            avatarImgs[
                                                                                avatarImgs.findIndex(
                                                                                    user =>
                                                                                        user.id ===
                                                                                        id
                                                                                )
                                                                            ].url
                                                                        }
                                                                        alt={`${first_name} ${last_name}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        className="btn btn-sm btn-icon btn-soft-primary rounded-circle mr-2 cursor-pointer"
                                                                        name={`${first_name} ${last_name}`}
                                                                        unstyled
                                                                    />
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <Avatar
                                                                className="btn btn-sm btn-icon btn-soft-primary rounded-circle mr-2 cursor-pointer"
                                                                name={`${first_name} ${last_name}`}
                                                                unstyled
                                                            />
                                                        )}
                                                    </div>
                                                    <motion.small
                                                        className="text-muted ml-2 mr-2 team-member-email pointer"
                                                        initial={false}
                                                        animate={{
                                                            opacity: teamExpand ? 1 : 0,
                                                            display: teamExpand
                                                                ? 'inline-block'
                                                                : 'none'
                                                        }}
                                                        transition={{
                                                            type: 'spring',
                                                            delay: 0,
                                                            stiffness: 262,
                                                            damping: 26,
                                                            mass: 1
                                                        }}
                                                    >
                                                        <span
                                                            className="d-inline-block"
                                                            style={{
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                width: '165px'
                                                            }}
                                                        >
                                                            {email}
                                                        </span>
                                                        {isUserTrxOwner &&
                                                            currentUserId !== id && (
                                                                <span
                                                                    onClick={() =>
                                                                        handleRemoveMember(
                                                                            id
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    className="fas fa-trash-alt pl-2 align-text-top"
                                                                    style={{
                                                                        color: '#e70000'
                                                                    }}
                                                                />
                                                            )}
                                                    </motion.small>
                                                </div>
                                            </motion.li>
                                        )
                                    )}
                                    {!isClient && (
                                        <motion.li
                                            className="list-inline-item mr-0"
                                            initial={true}
                                            animate={{
                                                opacity: !teamExpand ? 1 : 0,
                                                display: !teamExpand
                                                    ? 'inline-block'
                                                    : 'none'
                                            }}
                                            transition={{
                                                type: 'spring',
                                                delay: 0,
                                                stiffness: 262,
                                                damping: 26,
                                                mass: 1
                                            }}
                                        >
                                            {isUserTrxOwner && (
                                                <div
                                                    className="btn btn-sm btn-icon btn-soft-secondary rounded-circle add-team-member-button"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleModalOpen}
                                                >
                                                    <span className="btn-icon__inner">
                                                        +
                                                    </span>
                                                </div>
                                            )}
                                        </motion.li>
                                    )}
                                </ul>
                                <div className="d-block">
                                    {opposing.map(
                                        ({
                                            first_name,
                                            last_name,
                                            id,
                                            email,
                                            phone,
                                            primary,
                                            brokerage
                                        }) => (
                                            <div
                                                className="pt-2 pb-0 ml-3 align-items-center"
                                                style={{
                                                    zIndex: 1000
                                                }}
                                                key={id}
                                            >
                                                <div
                                                    className="row mr-0 ml-0 align-items-center"
                                                    onClick={() => handleUserExpand(id)}
                                                >
                                                    <div className="d-inline-block u-sm-avatar mr-3">
                                                        {avatarImgs ? (
                                                            <>
                                                                {avatarImgs.findIndex(
                                                                    user => user.id === id
                                                                ) >= 0 &&
                                                                avatarImgs[
                                                                    avatarImgs.findIndex(
                                                                        user =>
                                                                            user.id === id
                                                                    )
                                                                ].url ? (
                                                                    <img
                                                                        className="img-fluid rounded-circle"
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        src={
                                                                            avatarImgs[
                                                                                avatarImgs.findIndex(
                                                                                    user =>
                                                                                        user.id ===
                                                                                        id
                                                                                )
                                                                            ].url
                                                                        }
                                                                        alt={`${first_name} ${last_name}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        className={`btn btn-sm btn-icon btn-soft-primary rounded-circle mr-1 ${
                                                                            phone
                                                                                ? 'cursor-pointer'
                                                                                : email
                                                                                ? 'cursor-pointer'
                                                                                : ''
                                                                        }`}
                                                                        name={`${first_name} ${last_name}`}
                                                                        unstyled
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Avatar
                                                                className={`btn btn-sm btn-icon btn-soft-primary rounded-circle mr-1 ${
                                                                    phone
                                                                        ? 'cursor-pointer'
                                                                        : email
                                                                        ? 'cursor-pointer'
                                                                        : ''
                                                                }`}
                                                                name={`${first_name} ${last_name}`}
                                                                unstyled
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className="media-body pointer"
                                                        style={{
                                                            width: '10rem',
                                                            maxWidth: '10rem'
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span>{`${first_name} ${last_name}`}</span>
                                                            {isUserTrxOwner &&
                                                                userExpandIndex.includes(
                                                                    id
                                                                ) && (
                                                                    <span
                                                                        onClick={() => {
                                                                            if (
                                                                                !primary ||
                                                                                (primary &&
                                                                                    opposing.length ===
                                                                                        1)
                                                                            ) {
                                                                                removingOpposingMember(
                                                                                    {
                                                                                        member: id
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                        type="button"
                                                                        className="fas fa-trash-alt pl-2 align-text-top"
                                                                        style={{
                                                                            color:
                                                                                !primary ||
                                                                                (primary &&
                                                                                    opposing.length ===
                                                                                        1)
                                                                                    ? '#e70000'
                                                                                    : '#8c98a4',
                                                                            opacity:
                                                                                !primary ||
                                                                                (primary &&
                                                                                    opposing.length ===
                                                                                        1)
                                                                                    ? 1
                                                                                    : 0.7
                                                                        }}
                                                                    />
                                                                )}
                                                        </div>
                                                        <span
                                                            className={`badge badge-pill badge-${
                                                                primary
                                                                    ? 'primary'
                                                                    : 'secondary'
                                                            } w-80`}
                                                            style={{
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {brokerage.name ||
                                                                opposingTitle()}
                                                        </span>
                                                    </div>
                                                </div>
                                                <motion.div
                                                    className="d-block"
                                                    style={{
                                                        width: '165px',
                                                        pointerEvents: 'none'
                                                    }}
                                                    animate={{
                                                        opacity: userExpandIndex.includes(
                                                            id
                                                        )
                                                            ? 1
                                                            : 0,

                                                        height: userExpandIndex.includes(
                                                            id
                                                        )
                                                            ? 'auto'
                                                            : '0px'
                                                    }}
                                                    transition={{
                                                        type: 'spring',
                                                        delay: 0,
                                                        stiffness: 500,
                                                        damping: 60,
                                                        mass: 1
                                                    }}
                                                >
                                                    {phone && (
                                                        <div className="d-flex column align-items-center ml-2">
                                                            <span className="fas fa-phone text-muted mr-2" />
                                                            <span
                                                                className="d-inline-block font-size-1 text-muted"
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow:
                                                                        'ellipsis'
                                                                }}
                                                            >
                                                                <NumberFormat
                                                                    value={phone}
                                                                    displayType={'text'}
                                                                    format="###.###.####"
                                                                    mask="_"
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                    {email && (
                                                        <div className="d-flex column align-items-center ml-2">
                                                            <span className="far fa-envelope text-muted mr-2" />
                                                            <span
                                                                className="d-inline-block font-size-1 text-muted"
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    width: '155px'
                                                                }}
                                                            >
                                                                {email}
                                                            </span>
                                                        </div>
                                                    )}
                                                </motion.div>
                                            </div>
                                        )
                                    )}

                                    <motion.div
                                        className="ml-3 mb-3 mt-3"
                                        initial={true}
                                        animate={{
                                            x: 0,
                                            y: !teamExpand
                                                ? 0
                                                : members.length > 1
                                                ? 16
                                                : 0
                                        }}
                                        transition={{
                                            type: 'spring',
                                            delay: 0,
                                            stiffness: 262,
                                            damping: 26,
                                            mass: 1
                                        }}
                                    >
                                        <button
                                            className="btn btn-link d-flex align-items-center p-0"
                                            style={{ maxHeight: '36px' }}
                                            onClick={() =>
                                                setOpposingModalOpen(state => !state)
                                            }
                                        >
                                            {`+ ${add_opposing_button}`}
                                        </button>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-none d-xl-block col-3 pr-3">
                <motion.div
                    className="card mb-3"
                    animate={{
                        height: `calc(${initialHeight + expandHeight}px + 2rem)`
                    }}
                    transition={{
                        type: 'spring',
                        delay: 0,
                        stiffness: 500,
                        damping: 50,
                        mass: 1
                    }}
                >
                    <div className="mb-3 position-relative">
                        <div>
                            <img
                                onClick={() => setIsGalleryOpened(!isGalleryOpened)}
                                src={display || defaultTransactionPhoto}
                                className={cn('card-img-top w-100', {
                                    pointer: !!display
                                })}
                                style={{ height: '183px', objectFit: 'cover' }}
                                alt="property main"
                            />
                            {!!display && (
                                <FsLightbox
                                    toggler={isGalleryOpened}
                                    sources={
                                        photos.length
                                            ? photos.map(({ MediaURL }) => MediaURL)
                                            : [display]
                                    }
                                    types={['image']}
                                />
                            )}
                            <TransactionBadge
                                status={transaction.status}
                                type={type}
                                isPill
                                isVertical={false}
                            />
                            {!!photos.length && (
                                <div className="photos-quantity">
                                    1<span>/</span>
                                    {photos.length}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="px-4 py-2">
                        <div className="row">
                            <div className="col-12 mb-3">
                                {daysOnMarketHeader ? (
                                    <div className="row">
                                        <span
                                            className="d-block col-12 font-size-3 font-weight-semi-bold"
                                            style={{ textAlign: 'center' }}
                                        >
                                            {daysOnMls(mls_list_date.seconds)}
                                        </span>

                                        <span
                                            className="d-block col-12 text-muted"
                                            style={{ textAlign: 'center' }}
                                        >
                                            {days_market_label}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="d-block">
                                        <div className="progress mb-2">
                                            <Progress
                                                bar
                                                value={`${progress}`}
                                                max="100"
                                                color="success"
                                            />
                                        </div>
                                        {closing_date?.seconds ? (
                                            <span
                                                className="d-block font-weight-medium"
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >{`${closing_in_label} ${daysTillClosing(
                                                closing_date.seconds
                                            )} ${days_label}`}</span>
                                        ) : (
                                            <span
                                                className="d-block font-weight-medium"
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {no_close_date_label}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* {transaction && transaction.closing_date && (
                            <div className="row">
                                <div className="col-12 pt-1">
                                    <span className="font-size-1">Closing:</span>
                                    <span className="font-weight-medium font-size-1 trxtime">
                                        {closing_date
                                            ? `${formatCardDate(closing_date.seconds)}`
                                            : ''}
                                    </span>
                                </div>
                            </div>
                        )} */}
                        <hr className="mt-3 mb-3" />
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-0">
                                    <span className="align-top">$ </span>
                                    <span className="font-size-2 font-weight-medium text-lh-sm">
                                        {numeral(price).format('0,0')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={measuredRef}>
                        <SimpleBar style={{ height: scrollHeight }}>
                            <div className="px-4">
                                <hr className="mb-3 mt-1" />
                                <ul className="list-unstyled mb-1">
                                    {(!isClient
                                        ? clients
                                        : isPrimaryAgentExist
                                        ? [primary_agent]
                                        : [primary_broker]
                                    ).map(
                                        ({ first_name, last_name, id, email, phone }) => (
                                            <li
                                                className="media align-items-start py-1"
                                                key={id}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    onClick={() => handleUserExpand(id)}
                                                >
                                                    <div className="u-avatar mr-3">
                                                        {avatarImgs ? (
                                                            <>
                                                                {avatarImgs.findIndex(
                                                                    user => user.id === id
                                                                ) >= 0 &&
                                                                avatarImgs[
                                                                    avatarImgs.findIndex(
                                                                        user =>
                                                                            user.id === id
                                                                    )
                                                                ].url ? (
                                                                    <img
                                                                        className="img-fluid rounded-circle"
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        src={
                                                                            avatarImgs[
                                                                                avatarImgs.findIndex(
                                                                                    user =>
                                                                                        user.id ===
                                                                                        id
                                                                                )
                                                                            ].url
                                                                        }
                                                                        alt={`${first_name} ${last_name}`}
                                                                    />
                                                                ) : (
                                                                    <Avatar
                                                                        className={`btn btn-icon btn-soft-primary rounded-circle mr-3 ${
                                                                            phone
                                                                                ? 'cursor-pointer'
                                                                                : email
                                                                                ? 'cursor-pointer'
                                                                                : ''
                                                                        }`}
                                                                        name={`${first_name} ${last_name}`}
                                                                        unstyled
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Avatar
                                                                className={`btn btn-icon btn-soft-primary rounded-circle mr-3 ${
                                                                    phone
                                                                        ? 'cursor-pointer'
                                                                        : email
                                                                        ? 'cursor-pointer'
                                                                        : ''
                                                                }`}
                                                                name={`${first_name} ${last_name}`}
                                                                unstyled
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="media-body">
                                                        <span className="d-block">{`${first_name} ${last_name} `}</span>
                                                        {isClient && (
                                                            <span className="badge badge-buyer">
                                                                {isPrimaryAgentExist
                                                                    ? primary_agent_label
                                                                    : primary_broker_label}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <motion.div
                                                    className="d-block ml-9 w-80 mt-1"
                                                    animate={{
                                                        opacity: userExpandIndex.includes(
                                                            id
                                                        )
                                                            ? 1
                                                            : 0,

                                                        height: userExpandIndex.includes(
                                                            id
                                                        )
                                                            ? 'auto'
                                                            : '0px'
                                                    }}
                                                    transition={{
                                                        type: 'spring',
                                                        delay: 0,
                                                        stiffness: 500,
                                                        damping: 60,
                                                        mass: 1
                                                    }}
                                                >
                                                    {phone && (
                                                        <div className="d-flex column align-items-center mb-2">
                                                            <span className="fas fa-phone text-muted mr-2" />
                                                            <span
                                                                className="d-inline-block font-size-1 text-muted"
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    width: '155px'
                                                                }}
                                                            >
                                                                <NumberFormat
                                                                    value={phone}
                                                                    displayType={'text'}
                                                                    format="###.###.####"
                                                                    mask="_"
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                    {email && (
                                                        <div className="d-flex column align-items-center">
                                                            <span className="far fa-envelope text-muted mr-2" />
                                                            <span
                                                                className="d-inline-block font-size-1 text-muted"
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    width: '155px'
                                                                }}
                                                            >
                                                                {email}
                                                            </span>
                                                        </div>
                                                    )}
                                                </motion.div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                            {team.length && (
                                <div className="px-4 mb-1 mt-2">
                                    <div className="mb-1">
                                        <small>{team_label}</small>
                                    </div>
                                    <ul className="list-inline mb-0">
                                        {members.map(
                                            (
                                                { first_name, last_name, id, email },
                                                index
                                            ) => (
                                                <motion.li
                                                    className={`list-inline-item mr-0 ${
                                                        index + 1 === members.length
                                                            ? 'mb-3'
                                                            : ''
                                                    }`}
                                                    key={id}
                                                    initial={true}
                                                    animate={{
                                                        x: 0,
                                                        y: teamExpand ? index * 16 : 0
                                                    }}
                                                    transition={{
                                                        type: 'spring',
                                                        delay: 0,
                                                        stiffness: 262,
                                                        damping: 26,
                                                        mass: 1
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center list-inline-item">
                                                        <div className="u-sm-avatar u-sm-avatar--bordered rounded-circle p-0">
                                                            {avatarImgs ? (
                                                                <div>
                                                                    {avatarImgs.findIndex(
                                                                        user =>
                                                                            user.id === id
                                                                    ) >= 0 ? (
                                                                        <img
                                                                            className="img-fluid rounded-circle"
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            src={
                                                                                avatarImgs[
                                                                                    avatarImgs.findIndex(
                                                                                        user =>
                                                                                            user.id ===
                                                                                            id
                                                                                    )
                                                                                ].url
                                                                            }
                                                                            alt={`${first_name} ${last_name}`}
                                                                            onClick={
                                                                                handleTeamExpand
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            className="btn btn-icon btn-soft-primary rounded-circle mr-3 pointer"
                                                                            name={`${first_name} ${last_name}`}
                                                                            unstyled
                                                                            onClick={
                                                                                handleTeamExpand
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <Avatar
                                                                    className="btn btn-icon btn-soft-primary rounded-circle mr-3 pointer"
                                                                    name={`${first_name} ${last_name}`}
                                                                    unstyled
                                                                />
                                                            )}
                                                        </div>
                                                        <motion.small
                                                            className="text-muted ml-2 team-member-email pointer"
                                                            initial={false}
                                                            animate={{
                                                                opacity: teamExpand
                                                                    ? 1
                                                                    : 0,
                                                                display: teamExpand
                                                                    ? 'inline-block'
                                                                    : 'none'
                                                            }}
                                                            transition={{
                                                                type: 'spring',
                                                                delay: 0,
                                                                stiffness: 262,
                                                                damping: 26,
                                                                mass: 1
                                                            }}
                                                        >
                                                            <span
                                                                className="d-inline-block"
                                                                style={{
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    width: '165px'
                                                                }}
                                                            >
                                                                {email}
                                                            </span>
                                                            {isUserTrxOwner &&
                                                                currentUserId !== id && (
                                                                    <span
                                                                        onClick={() =>
                                                                            handleRemoveMember(
                                                                                id
                                                                            )
                                                                        }
                                                                        type="button"
                                                                        className="fas fa-trash-alt pl-2 align-text-top"
                                                                        style={{
                                                                            color: '#e70000'
                                                                        }}
                                                                    />
                                                                )}
                                                        </motion.small>
                                                    </div>
                                                </motion.li>
                                            )
                                        )}
                                        {!isClient && (
                                            <motion.li
                                                className="list-inline-item mr-0"
                                                initial={true}
                                                animate={{
                                                    opacity: !teamExpand ? 1 : 0,
                                                    display: !teamExpand
                                                        ? 'inline-block'
                                                        : 'none'
                                                }}
                                                transition={{
                                                    type: 'spring',
                                                    delay: 0,
                                                    stiffness: 262,
                                                    damping: 26,
                                                    mass: 1
                                                }}
                                            >
                                                {isUserTrxOwner && (
                                                    <div
                                                        className="btn btn-sm btn-icon btn-soft-secondary rounded-circle add-team-member-button"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleModalOpen}
                                                    >
                                                        <span className="btn-icon__inner">
                                                            +
                                                        </span>
                                                    </div>
                                                )}
                                            </motion.li>
                                        )}
                                    </ul>
                                    <motion.hr
                                        className="mb-5"
                                        initial={true}
                                        animate={{
                                            x: 0,
                                            y: !teamExpand
                                                ? 0
                                                : members.length > 1
                                                ? members.length * 16
                                                : 0
                                        }}
                                        transition={{
                                            type: 'spring',
                                            delay: 0,
                                            stiffness: 262,
                                            damping: 26,
                                            mass: 1
                                        }}
                                    />
                                </div>
                            )}

                            {!isClient && (
                                <>
                                    {opposing && opposing.length ? (
                                        <div className="px-4 mb-1 mt-2">
                                            <div className="mb-1">
                                                <small>{opposing_label}</small>
                                            </div>
                                            <ul className="list-inline mb-3">
                                                {opposing.map(
                                                    ({
                                                        first_name,
                                                        last_name,
                                                        id,
                                                        email,
                                                        phone,
                                                        brokerage,
                                                        primary
                                                    }) => (
                                                        <React.Fragment key={id}>
                                                            <li
                                                                className="media align-items-start py-1"
                                                                key={id}
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection:
                                                                        'column'
                                                                }}
                                                            >
                                                                <div
                                                                    className="d-flex align-items-center"
                                                                    onClick={() =>
                                                                        handleUserExpand(
                                                                            id
                                                                        )
                                                                    }
                                                                >
                                                                    <div className="u-avatar mr-3">
                                                                        {avatarImgs ? (
                                                                            <>
                                                                                {avatarImgs.findIndex(
                                                                                    user =>
                                                                                        user.id ===
                                                                                        id
                                                                                ) >= 0 &&
                                                                                avatarImgs[
                                                                                    avatarImgs.findIndex(
                                                                                        user =>
                                                                                            user.id ===
                                                                                            id
                                                                                    )
                                                                                ].url ? (
                                                                                    <img
                                                                                        className="img-fluid rounded-circle"
                                                                                        style={{
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        src={
                                                                                            avatarImgs[
                                                                                                avatarImgs.findIndex(
                                                                                                    user =>
                                                                                                        user.id ===
                                                                                                        id
                                                                                                )
                                                                                            ]
                                                                                                .url
                                                                                        }
                                                                                        alt={`${first_name} ${last_name}`}
                                                                                    />
                                                                                ) : (
                                                                                    <Avatar
                                                                                        className={`btn btn-icon btn-soft-primary rounded-circle mr-3 ${
                                                                                            phone
                                                                                                ? 'cursor-pointer'
                                                                                                : email
                                                                                                ? 'cursor-pointer'
                                                                                                : ''
                                                                                        }`}
                                                                                        name={`${first_name} ${last_name}`}
                                                                                        unstyled
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <Avatar
                                                                                className={`btn btn-icon btn-soft-primary rounded-circle mr-3 ${
                                                                                    phone
                                                                                        ? 'cursor-pointer'
                                                                                        : email
                                                                                        ? 'cursor-pointer'
                                                                                        : ''
                                                                                }`}
                                                                                name={`${first_name} ${last_name}`}
                                                                                unstyled
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className="media-body pointer"
                                                                        style={{
                                                                            width: '10rem',
                                                                            maxWidth:
                                                                                '10rem'
                                                                        }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <span>{`${first_name} ${last_name}`}</span>
                                                                            {isUserTrxOwner &&
                                                                                userExpandIndex.includes(
                                                                                    id
                                                                                ) && (
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                !primary ||
                                                                                                (primary &&
                                                                                                    opposing.length ===
                                                                                                        1)
                                                                                            ) {
                                                                                                removingOpposingMember(
                                                                                                    {
                                                                                                        member: id
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        type="button"
                                                                                        className="fas fa-trash-alt pl-2 align-text-top"
                                                                                        style={{
                                                                                            color:
                                                                                                !primary ||
                                                                                                (primary &&
                                                                                                    opposing.length ===
                                                                                                        1)
                                                                                                    ? '#e70000'
                                                                                                    : '#8c98a4',
                                                                                            opacity:
                                                                                                !primary ||
                                                                                                (primary &&
                                                                                                    opposing.length ===
                                                                                                        1)
                                                                                                    ? 1
                                                                                                    : 0.7
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                        </div>
                                                                        <span
                                                                            className={`badge badge-pill badge-${
                                                                                primary
                                                                                    ? 'primary'
                                                                                    : 'secondary'
                                                                            } w-80`}
                                                                            style={{
                                                                                overflow:
                                                                                    'hidden',
                                                                                whiteSpace:
                                                                                    'nowrap',
                                                                                textOverflow:
                                                                                    'ellipsis'
                                                                            }}
                                                                        >
                                                                            {brokerage.name ||
                                                                                opposingTitle()}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <motion.div
                                                                    className="d-block ml-9 w-80 mt-1"
                                                                    animate={{
                                                                        opacity:
                                                                            userExpandIndex.includes(
                                                                                id
                                                                            )
                                                                                ? 1
                                                                                : 0,

                                                                        height: userExpandIndex.includes(
                                                                            id
                                                                        )
                                                                            ? 'auto'
                                                                            : '0px'
                                                                    }}
                                                                    transition={{
                                                                        type: 'spring',
                                                                        delay: 0,
                                                                        stiffness: 500,
                                                                        damping: 60,
                                                                        mass: 1
                                                                    }}
                                                                >
                                                                    {phone && (
                                                                        <div className="d-flex column align-items-center mb-2">
                                                                            <span className="fas fa-phone text-muted mr-2" />
                                                                            <span
                                                                                className="d-inline-block font-size-1 text-muted"
                                                                                style={{
                                                                                    overflow:
                                                                                        'hidden',
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                    textOverflow:
                                                                                        'ellipsis',
                                                                                    width: '155px'
                                                                                }}
                                                                            >
                                                                                <NumberFormat
                                                                                    value={
                                                                                        phone
                                                                                    }
                                                                                    displayType={
                                                                                        'text'
                                                                                    }
                                                                                    format="###.###.####"
                                                                                    mask="_"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {email && (
                                                                        <div className="d-flex column align-items-center">
                                                                            <span className="far fa-envelope text-muted mr-2" />
                                                                            <span
                                                                                className="d-inline-block font-size-1 text-muted"
                                                                                style={{
                                                                                    overflow:
                                                                                        'hidden',
                                                                                    whiteSpace:
                                                                                        'nowrap',
                                                                                    textOverflow:
                                                                                        'ellipsis',
                                                                                    width: '155px'
                                                                                }}
                                                                            >
                                                                                {email}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </motion.div>
                                                            </li>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </ul>
                                            <motion.div
                                                initial={true}
                                                animate={{
                                                    x: 0,
                                                    y: !teamExpand
                                                        ? 0
                                                        : members.length > 1
                                                        ? members.length * 16
                                                        : 0
                                                }}
                                                transition={{
                                                    type: 'spring',
                                                    delay: 0,
                                                    stiffness: 262,
                                                    damping: 26,
                                                    mass: 1
                                                }}
                                            >
                                                <button
                                                    className="btn btn-link d-flex align-items-center p-0 ml-3"
                                                    style={{ maxHeight: '36px' }}
                                                    onClick={() =>
                                                        setOpposingModalOpen(
                                                            state => !state
                                                        )
                                                    }
                                                >
                                                    {`+ ${add_opposing_button}`}
                                                </button>
                                                <div
                                                    style={{
                                                        height: '2rem',
                                                        minHeight: '2rem'
                                                    }}
                                                />
                                            </motion.div>
                                        </div>
                                    ) : (
                                        <motion.div
                                            className="d-flex justify-content-center align-items-center"
                                            initial={true}
                                            animate={{
                                                x: 0,
                                                y: !teamExpand
                                                    ? 0
                                                    : members.length > 1
                                                    ? members.length * 16
                                                    : 0
                                            }}
                                            transition={{
                                                type: 'spring',
                                                delay: 0,
                                                stiffness: 262,
                                                damping: 26,
                                                mass: 1
                                            }}
                                        >
                                            <button
                                                className="btn btn-link d-flex align-items-center p-0"
                                                style={{ maxHeight: '36px' }}
                                                onClick={() =>
                                                    setOpposingModalOpen(state => !state)
                                                }
                                            >
                                                {`+ ${add_opposing_button}`}
                                            </button>
                                            <div
                                                style={{
                                                    height: '2rem',
                                                    minHeight: '2rem'
                                                }}
                                            />
                                        </motion.div>
                                    )}
                                </>
                            )}
                        </SimpleBar>
                    </div>
                </motion.div>
            </div>
        </>
    );
};

export default connect(null, {
    updateTransactionMembers,
    updatingOpposingMembers,
    removingOpposingMember
})(TransactionMeta);
