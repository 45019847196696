// React
import React from 'react';

// Components
import { ActionButtons } from '../../components';

// Packages
import { useHistory } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const ErrorInterface = ({ lang, valid, validEmail, authUser }) => {
    const {
        exit_button_label,
        expired_heading,
        expired_description,
        confirm_button_label,
        invalid_email_heading,
        invalid_email_description
    } = lang;

    const history = useHistory();

    const handleSubmit = () => {
        history.replace({
            pathname: `${routes.AUTHENTICATED}${routes.DASHBOARD}`
        });
    };
    const confirmHeading = () => {
        if (!valid && validEmail) {
            return expired_heading;
        } else {
            return invalid_email_heading;
        }
    };

    const confirmDescription = () => {
        if (!valid && validEmail) {
            return (
                <span
                    style={{
                        fontSize: 16,
                        color: '#8C98A4',
                        width: '70%',
                        textAlign: 'center'
                    }}
                >
                    {expired_description}
                </span>
            );
        } else {
            return (
                <span
                    style={{
                        fontSize: 16,
                        color: '#8C98A4',
                        width: '70%',
                        textAlign: 'center'
                    }}
                >
                    {invalid_email_description}
                </span>
            );
        }
    };

    return (
        <div
            className="mt-5"
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <div className="card-body mb-10">
                <div className="col d-flex flex-column align-items-center">
                    <div className="row">
                        <h1 className="h2 font-weight-medium">{confirmHeading()}</h1>
                    </div>
                    <div className="row justify-content-center mb-10">
                        {confirmDescription()}
                    </div>
                </div>
            </div>
            <ActionButtons
                cancelLabel={null}
                saveLabel={authUser ? confirm_button_label : exit_button_label}
                handleSubmit={handleSubmit}
                disabled={false}
            />
        </div>
    );
};

export default ErrorInterface;
