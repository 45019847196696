// React
import React, { useState } from 'react';

// Assets
import logo from '../assets/img/jada-logo.svg';

// Components
import { ErrorInterface, Loader } from '../components';

// Context
import { useLang } from '../context/LangContext';

// Packages
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

// Redux - Actions, Reducers, Sagas
import { setLanguageLocal } from '../store/actions/Lang';

const lang = {
    en: 'english',
    es: 'español'
};

const InvitationError = ({
    setLanguageLocal,
    isValidOrgInvitation,
    emailMatchOrgInvitation,
    isValidTrxInvitation,
    emailMatchTrxInvitation,
    isValidSigningInvitation,
    emailMatchSigningInvitation,
    location,
    ...props
}) => {
    const {
        exit_button_label,
        confirm_button_label,
        expired_heading,
        expired_description,
        invalid_email_heading,
        invalid_email_description
    } = useLang()['InvitationError']['ErrorInterface'];
    const { isTransactionInvitation, isSigningInvitation } = location?.state;
    const [isValid, emailMatch] = isTransactionInvitation
        ? [isValidTrxInvitation, emailMatchTrxInvitation]
        : isSigningInvitation
        ? [isValidSigningInvitation, emailMatchSigningInvitation]
        : [isValidOrgInvitation, emailMatchOrgInvitation];
    const [appLanguage, setAppLanguage] = useState(lang?.en);

    const setLanguage = () => {
        switch (appLanguage) {
            case lang.es:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
            case lang.en:
                setLanguageLocal({ origin: 'es' });
                setAppLanguage(lang.es);
                break;
            default:
                setLanguageLocal({ origin: 'en' });
                setAppLanguage(lang.en);
                break;
        }
    };

    if (isValid === '' || emailMatch === '') return <Loader />;

    return (
        <div id="content" className="push-main pt-0">
            <div className="bg-light">
                <SimpleBar style={{ overflowY: 'auto', height: '100%' }}>
                    <button
                        className="btn btn-xs btn-text-secondary"
                        type="button"
                        onClick={setLanguage}
                        style={{
                            position: 'absolute',
                            margin: '15px',
                            top: 0,
                            right: '30px'
                        }}
                    >
                        <span className="position-relative">
                            <span
                                className="u-sidebar--account__toggle-text"
                                style={{
                                    textDecoration: 'underline'
                                }}
                            >
                                {appLanguage === 'english' ? lang.es : 'inglés'}
                            </span>
                        </span>
                    </button>
                    <div className="container space-2">
                        <div className="mx-lg-auto">
                            <div className="card pt-5">
                                <div className="jada-logo d-flex row justify-content-center mb-10">
                                    <img
                                        src={logo}
                                        className="logo"
                                        style={{ width: '190px' }}
                                        alt="Jada Real Estate Real Simple"
                                    />
                                </div>
                                <ErrorInterface
                                    authUser={props.authUser}
                                    lang={{
                                        exit_button_label,
                                        confirm_button_label,
                                        expired_heading,
                                        expired_description,
                                        invalid_email_heading,
                                        invalid_email_description
                                    }}
                                    valid={isValid ? isValid : false}
                                    validEmail={emailMatch === null ? true : emailMatch}
                                />
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

const mapStateToProps = ({ invitation, trxInvitation, documentSigning }) => {
    const { isValid: isValidOrgInvitation, emailMatch: emailMatchOrgInvitation } =
        invitation;
    const { isValid: isValidTrxInvitation, emailMatch: emailMatchTrxInvitation } =
        trxInvitation;
    const { isValid: isValidSigningInvitation, emailMatch: emailMatchSigningInvitation } =
        documentSigning;
    return {
        isValidOrgInvitation,
        emailMatchOrgInvitation,
        isValidTrxInvitation,
        emailMatchTrxInvitation,
        isValidSigningInvitation,
        emailMatchSigningInvitation
    };
};

export default withRouter(
    connect(mapStateToProps, { setLanguageLocal })(InvitationError)
);
