import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
    saveUserMessage,
    removeSavedUserMessage,
    removeUserMessage,
    removeUserDraftMessage
} from './../../store/actions/Messages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';

import { formatMessagesDate } from '../../utils/Helpers';
import DropdownMenuComponent from '../common/DropdownMenu';

const MessageItem = ({
    id,
    isChecked,
    handleClick,
    handleItemClick,
    post,
    msgTitle,
    saveUserMessage,
    removeUserMessage,
    removeSavedUserMessage,
    removeUserDraftMessage,
    isPinned,
    isDraftTab,
    isNew
}) => {
    const dropItems = [
        {
            name: 'Delete',
            icon: 'fas fa-archive dropdown-item-icon',
            onClickHandler: () => {
                if (isDraftTab) {
                    removeUserDraftMessage({ messageIds: [id] });
                } else {
                    removeUserMessage({ messageIds: [id] });
                }
            }
        }
    ];

    const handlePinn = () => {
        if (!isPinned) {
            saveUserMessage({ messageIds: [id] });
        } else {
            removeSavedUserMessage({ messageIds: [id] });
        }
    };

    return (
        <>
            <div
                className={cx(
                    'd-flex space-between align-items-center border-bottom py-2 pl-5 ',
                    {
                        'bg-blue': isNew
                    }
                )}
            >
                <div>
                    <input
                        id={id}
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleClick}
                        className="notifications__checkbox"
                        style={{
                            marginRight: '10px',
                            color: '#fff',
                            width: '18px',
                            height: '18px'
                        }}
                    />
                </div>
                <div
                    onClick={handleItemClick}
                    className="col-10 d-flex align-items-center text-nowrap pointer ellipsis "
                >
                    <div>
                        <h2 className="h6 mb-0  text-truncate" title={msgTitle}>
                            {msgTitle}
                        </h2>

                        <small className="d-block text-muted ">
                            {formatMessagesDate(post.created_at.seconds)}
                        </small>
                    </div>
                    <div className="ml-4 align-items-center text-nowrap ellipsis">
                        {post.content}
                    </div>
                </div>
                <div className="col d-flex justify-content-end align-items-center">
                    {!isDraftTab && (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            onClick={handlePinn}
                            style={{
                                cursor: 'pointer',
                                marginRight: '10px',
                                color: isPinned ? '#0d7fdb' : '#b3b9be',
                                fontSize: '1.2rem'
                            }}
                        />
                    )}
                    <DropdownMenuComponent dropItems={dropItems} />
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    saveUserMessage,
    removeUserMessage,
    removeSavedUserMessage,
    removeUserDraftMessage
})(MessageItem);
