// React
import React, { useEffect, useCallback } from 'react';

// Context
import { useLang } from '../../context/LangContext';

// Components
import { ConfirmationDialog } from '../../components';

// Assets
import PDF from '../../assets/svg/pdf-file.svg';
import PDF_PACK from '../../assets/svg/pdf-pack.svg';

// Packages
import { Collapse } from 'reactstrap';
import { useDropzone } from 'react-dropzone';

// Utils
import { formatDocumentDate } from '../../utils/Helpers';
import cx from 'classnames';

// Constants
import { confirmationDialogTypes, documentSections } from '../../utils/Constants';
const maxDocumentSize = 2097152;

const DocumentDrop = ({
    dropState,
    documents,
    addedDocuments,
    setDropAction,
    userData,
    trxId,
    trxType,
    addDocumentToTrx,
    searchText,
    searchSuggestions,
    settingSearchSuggestions,
    lang
}) => {
    const { drag_drop_label, add_label, last_update_label } =
        useLang()['TransactionDetails']['DocumentDrop'];
    const handleDocumentAdd = (doc, isUploadingNeeded) => {
        const transactions = [{ id: trxId }];
        addDocumentToTrx({
            transactions,
            userData,
            document: doc,
            isUploadingNeeded
        });
    };

    const onDrop = acceptedFiles => {
        if (acceptedFiles.length) {
            const document = {
                src: acceptedFiles[0],
                uploader_id: userData.id,
                org_id: userData.active_org_id,
                upload_type: trxType,
                mime_type: 'application/pdf',
                title: acceptedFiles[0].name.split('.').slice(0, -1).join('.'),
                fillable: false,
                section: documentSections.personal
            };
            handleDocumentAdd(document, true);
        }
    };

    const { isDragActive, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.pdf',
        minSize: 0,
        maxSize: maxDocumentSize
    });

    const documentInputSearch = useCallback(
        input => {
            if (input.trim() !== '') {
                const filteredTitle = () => {
                    const titleSuggestions = [];
                    documents.filter(doc => {
                        if (
                            doc.title.toLowerCase() &&
                            doc.title.toLowerCase().includes(input.toLowerCase())
                        ) {
                            titleSuggestions.push({
                                ...doc
                            });
                        }
                        return true;
                    });
                    return titleSuggestions;
                };
                const finalSuggestions = [...filteredTitle()];
                settingSearchSuggestions(finalSuggestions);
            } else {
                settingSearchSuggestions([]);
            }
        },
        [documents, settingSearchSuggestions]
    );

    useEffect(() => {
        documentInputSearch(searchText);
    }, [searchText, documentInputSearch]);

    const documentList =
        searchSuggestions && searchSuggestions.length ? searchSuggestions : documents;

    if (!documents) return null;

    return (
        <>
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.uploadDocument}
            />
            <Collapse
                isOpen={dropState}
                onEntered={() => setDropAction('opened')}
                onExited={() => setDropAction('closed')}
            >
                <div
                    id="newDoc"
                    className="pt-3 pb-3 px-4"
                    style={{
                        backgroundColor: 'rgba(55, 125, 255, 0.08)'
                    }}
                >
                    <div
                        {...getRootProps()}
                        className="u-dropzone gradient-overlay-half-primary-v4 mb-5"
                        style={{
                            height: '135px',
                            padding: 0,
                            borderStyle: isDragActive ? 'solid' : 'dashed',
                            borderColor: isDragActive ? '#377dff' : '#e7eaf3'
                        }}
                    >
                        <div className="dz-message py-3">
                            <figure className="max-width-10 mx-auto mb-3">
                                <img
                                    src="/images/drag-n-drop.svg"
                                    alt="Drag N Drop"
                                    data-parent="#SVGIcon"
                                />
                            </figure>
                            <span className="d-block">{drag_drop_label}</span>
                        </div>
                        <input {...getInputProps()} name="path" />
                    </div>

                    <div
                        style={{
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        {documentList.map(doc => (
                            <div
                                className="d-flex justify-content-between align-items-center mb-3 pb-3 document-item"
                                key={doc.id}
                            >
                                <div className="media">
                                    <div className="u-sm-avatar mt-1 mr-3">
                                        <img
                                            src={doc.pack ? PDF_PACK : PDF}
                                            className="img-fluid"
                                            alt="document"
                                        />
                                    </div>
                                    <div className="media-body">
                                        <span className="d-block text-dark">
                                            {doc.pack ? `Pack: ${doc.title}` : doc.title}
                                        </span>
                                        <small className="d-block text-secondary">
                                            {`${last_update_label}: ${
                                                doc?.edited_at?.seconds
                                                    ? formatDocumentDate(
                                                          doc.edited_at.seconds,
                                                          lang
                                                      )
                                                    : formatDocumentDate(
                                                          doc?.created_at?.seconds,
                                                          lang
                                                      )
                                            }`}
                                        </small>
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <div
                                        className={cx(
                                            'btn btn-xs btn-outline-primary transition-3d-hover',
                                            {
                                                'bg-lilac': addedDocuments.some(
                                                    item => doc.id === item.id
                                                )
                                            }
                                        )}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleDocumentAdd(doc, false)}
                                    >
                                        <span className="fas fa-plus mr-1" />
                                        {add_label}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Collapse>
        </>
    );
};

export default DocumentDrop;
