import React from 'react';
import cx from 'classnames';
import { useLang } from '../../context/LangContext';
import { connect } from 'react-redux';
import { signerTypes } from '../../utils/Constants';

const SelectionStep = ({
    stepContent,
    setStep,
    selectedItem,
    name,
    setFieldValue,
    fillingSettings,
    userData,
    step,
    transactionTeam
}) => {
    const { noSignatures_label } =
        useLang()['Documents']['FillingDocument']['SetupFilling'];

    const getUserType = () => {
        const member = transactionTeam.find(el => el.id === userData.id);
        return member?.type || signerTypes.other;
    };

    const clickHandler = value => {
        if (step === 1) {
            const signers =
                value === 'myself'
                    ? [
                          {
                              id: userData.id,
                              legalName:
                                  userData.legalName ||
                                  `${userData.first_name} ${userData.last_name}`,
                              email: userData.email,
                              type: getUserType()
                          }
                      ]
                    : value === 'others'
                    ? fillingSettings.confirm_signers
                          .filter(el => el.email !== userData.email)
                          .map(el => ({
                              ...el,
                              legalName: el.legal_name
                          }))
                    : [
                          ...fillingSettings.confirm_signers.map(el => ({
                              ...el,
                              legalName: el.legal_name
                          })),
                          {
                              id: userData.id,
                              legalName:
                                  userData.legalName ||
                                  `${userData.first_name} ${userData.last_name}`,
                              email: userData.email,
                              type: getUserType()
                          }
                      ].filter(
                          (el, index, array) =>
                              array.findIndex(item => item.id === el.id) === index
                      );
            setFieldValue('confirmSigners', signers);
        }
        setStep(prev => prev + 1);
        setFieldValue(name, value, false);
    };

    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                {stepContent.map((item, index) => (
                    <div
                        key={item.value}
                        className="card m-3"
                        style={{ maxWidth: '148px', width: '100%' }}
                    >
                        <input
                            type="checkbox"
                            name={name}
                            id={item.value}
                            className="custom-control-input checkbox-outline__input"
                            key={item.label}
                            onClick={() => clickHandler(item.value)}
                        />
                        <label
                            className={cx(
                                'd-flex justify-content-center flex-column mb-0 pointer',
                                {
                                    selected: selectedItem === item.value
                                }
                            )}
                            htmlFor={item.value}
                        >
                            {index === 2 ? (
                                <div className="py-4 text-center">
                                    <i
                                        className={`fa-solid text-primary ${item.icon}`}
                                        style={{ fontSize: '52px' }}
                                    />
                                    <i
                                        className={`fa-solid text-primary ${item.icon}`}
                                        style={{ fontSize: '52px' }}
                                    />
                                </div>
                            ) : (
                                <i
                                    className={`fa-solid text-primary text-center py-4 ${item.icon}`}
                                    style={{ fontSize: '52px' }}
                                />
                            )}
                            <p className="text-center">{item.label}</p>
                        </label>
                    </div>
                ))}
            </div>
            {name === 'signersSelect' && (
                <>
                    <input
                        type="checkbox"
                        name={name}
                        id="noSignatures"
                        className="custom-control-input checkbox-outline__input"
                        onChange={e =>
                            setFieldValue(name, e.target.checked ? 'noSignatures' : null)
                        }
                    />
                    <label
                        className={cx('align-self-center pointer', {
                            'text-primary': selectedItem === 'noSignatures'
                        })}
                        htmlFor="noSignatures"
                    >
                        {noSignatures_label}
                    </label>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps)(SelectionStep);
