// React
import React, { useState, useEffect } from 'react';

// Components
import { ConfirmationDialog } from '../../../components';

// Constants
import { confirmationDialogTypes } from '../../../utils/Constants';

// Packages
import { Button } from 'reactstrap';

const MemberMatches = ({
    matches,
    setFocusedState,
    member,
    executeInvitatonSend,
    lang
}) => {
    const [memberInvite, setMemberInvite] = useState(null);
    const { send_invite_button_label, cancel_invite_button_label } = lang;

    useEffect(() => {
        if (matches) {
            if (matches.length) {
                setMemberInvite({
                    email: matches[0].email,
                    first_name: matches[0].first_name,
                    last_name: matches[0].last_name,
                    type: lang.accountTypes.filter(type => type.id === member.type.id)[0]
                        .label
                });
            }
        }
    }, [lang.accountTypes, matches, member]);

    if (!memberInvite) return null;

    return (
        <div
            className="container d-flex justify-content-center align-items-center"
            style={{
                animation: 'fadeIn 1s',
                height: '50%'
            }}
        >
            <ConfirmationDialog
                initialModalDialogType={confirmationDialogTypes.inviteMemberSuccess}
                successHandler={() => setFocusedState('team_list')}
            />
            <div className="col" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <div
                    className="row align-items-center justify-content-between"
                    style={{
                        height: '73px',
                        backgroundColor: '#ffffff',
                        width: '100%',
                        margin: 0,
                        border: '1px solid rgba(231, 234, 243, 1)',
                        borderRadius: 5
                    }}
                >
                    <div className="col-3">
                        <h2 className="h6 mb-0">{`${memberInvite.first_name} ${memberInvite.last_name}`}</h2>
                    </div>
                    <div className="col-3 ellipsis">
                        <span
                            className="font-size-1"
                            style={{
                                color: 'rgba(55, 125, 255, 1)'
                            }}
                        >
                            {`${memberInvite.email}`}
                        </span>
                    </div>
                    <div className="col-3">
                        <span
                            className="font-size-1"
                            style={{ color: 'rgba(55, 125, 255, 1)' }}
                        >
                            {memberInvite.type}
                        </span>
                    </div>
                    <div className="col-3">
                        <button
                            onClick={executeInvitatonSend}
                            className={
                                'btn btn-block btn-soft-primary transition-3d-hover ml-auto'
                            }
                        >
                            {send_invite_button_label}
                            <span className="fas fa-angle-right ml-1" />
                        </button>
                    </div>
                </div>

                <div className="row justify-content-center m-5">
                    <Button
                        color="link"
                        style={{ color: 'rgba(222, 68, 55, 1)' }}
                        onClick={() => {
                            setFocusedState('team_list');
                        }}
                    >
                        {cancel_invite_button_label}
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default MemberMatches;
