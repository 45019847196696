// React
import React, { useState, useCallback, useEffect } from 'react';

// Components
import { OrganizationSearchFilter, AgentList } from '../components';

// Hooks
import { useMountEffect } from '../hooks/useMountEffect';

// Packages
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

// Redux - Actions, Reducers, Sagas
import { resetMemberTransactionFilter } from '../store/actions/Transactions';

const Organization = ({
    userData,
    companyContacts,
    memberFilterType,
    resetMemberTransactionFilter
}) => {
    const [searchSuggestions, setSearchSuggestions] = useState(null);
    const [filteredContacts, setFilteredContacts] = useState(null);

    useMountEffect(resetMemberTransactionFilter);

    const getContactsByType = useCallback(() => {
        if (memberFilterType.value === 'active') {
            return companyContacts.filter(
                contact => contact.active && contact.id !== userData.id
            );
        } else if (memberFilterType.value === 'inactive') {
            return companyContacts.filter(
                contact => !contact.active && contact.id !== userData.id
            );
        } else {
            return companyContacts.filter(contact => contact.id !== userData.id);
        }
    }, [memberFilterType.value, companyContacts, userData]);

    const contactsByFilter = useCallback(() => {
        const contacts = getContactsByType();
        contacts.sort((a, b) => {
            if (a.last_name && a.first_name && b.last_name && b.first_name) {
                const result = a.last_name.localeCompare(b.last_name);
                return result !== 0 ? result : a.first_name.localeCompare(b.first_name);
            } else if (!a.last_name || !a.first_name) {
                return 1;
            } else if (!b.last_name || !b.first_name) {
                return -1;
            }
            return true;
        });
        return contacts;
    }, [getContactsByType]);

    useEffect(() => {
        if (companyContacts && companyContacts.length) {
            setFilteredContacts(contactsByFilter());
        }
    }, [companyContacts, memberFilterType, contactsByFilter]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            <OrganizationSearchFilter
                contacts={filteredContacts}
                companyContacts={companyContacts}
                memberFilterType={memberFilterType}
                userId={userData?.id}
                userData={userData}
                agents={filteredContacts}
                setSearchSuggestions={setSearchSuggestions}
            />
            <SimpleBar style={{ overflowY: 'auto', height: '100%' }}>
                <div className="container space-bottom-1">
                    <AgentList
                        agentList={filteredContacts}
                        searchSuggestions={searchSuggestions}
                    />
                </div>
            </SimpleBar>
        </div>
    );
};

const mapStateToProps = ({ contacts, user }) => {
    const { userData } = user;
    const { companyContacts, memberFilterType } = contacts;
    return { companyContacts, memberFilterType, userData };
};

export default connect(mapStateToProps, { resetMemberTransactionFilter })(Organization);
