import React, { useEffect } from 'react';

// Components
import { Label } from 'reactstrap';
import { DatePicker, TextField, CreatableSelectField } from '..';

// Context
import { useLang } from '../../context/LangContext';

const EditTrxDetailsFormContent = ({
    errors,
    values,
    dirty,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setShouldConfirmDisplayed,
    sources
}) => {
    const {
        price_label,
        offer_price_label,
        closingDate_label,
        business_src_label,
        business_src_placeholder,
        commission_label,
        commission_placeholder,
        split_label,
        split_placeholder,
        fee_label,
        fee_placeholder
    } = useLang()['TransactionDetails']['EditTransactionDetails'];
    const { locale } = useLang()['DateTime'];

    useEffect(() => {
        setShouldConfirmDisplayed(dirty);
    }, [dirty, setShouldConfirmDisplayed]);

    return (
        <>
            <div className="row">
                <div className="col-4 mb-2 offset-2 js-focus-state">
                    <Label className="form-label">{price_label}</Label>
                    <TextField
                        type="number"
                        myStyle={{
                            flexGrow: 1
                        }}
                        required
                        className="form-control"
                        name="price"
                        id="price"
                        value={values.price}
                        error={errors.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.price}
                        hasDefaultFill
                    />
                </div>

                <div className="col-4 mb-2">
                    <Label className="form-label">{offer_price_label}</Label>
                    <TextField
                        type="number"
                        myStyle={{
                            flexGrow: 1
                        }}
                        required
                        className="form-control"
                        name="offer_price"
                        id="offer_price"
                        value={values.offer_price}
                        error={errors.offer_price}
                        onChange={e => {
                            handleChange(e);
                            setFieldValue(`mappings.offer_price`, e.target.value);
                        }}
                        onBlur={handleBlur}
                        invalid={!!errors.offer_price}
                        hasDefaultFill
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4 offset-2 mb-2">
                    <Label className="form-label">{closingDate_label}</Label>

                    <DatePicker
                        myStyle={{
                            flexGrow: 1
                        }}
                        className="js-range-datepicker form-control bg-white rounded-right"
                        name="closing_date"
                        id="closing_date"
                        required
                        value={values.closing_date}
                        error={errors.closing_date}
                        onBlur={handleBlur}
                        invalid={!!errors.closing_date && dirty}
                        hasDefaultFill
                        options={{
                            enableTime: true,
                            dateFormat: 'm-d-Y h:i K',
                            disableMobile: true,
                            locale: locale,
                            onChange([date]) {
                                setFieldValue(`mappings.closing_date`, date);
                                setFieldValue(`closing_date`, date);
                            }
                        }}
                    />
                </div>

                <div className="col-4 mb-2">
                    <Label className="form-label">{business_src_label}</Label>
                    <CreatableSelectField
                        type="text"
                        className="custom-edit-select p-0"
                        classNamePrefix={`react-select ${
                            !!errors.businessSrc && touched.businessSrc
                                ? 'danger'
                                : 'pristine'
                        }`}
                        name="businessSrc"
                        id="businessSrc"
                        placeholder={business_src_placeholder}
                        aria-label={business_src_label}
                        error={errors.businessSrc}
                        value={values.businessSrc}
                        options={sources}
                        onBlur={() => {
                            handleBlur('businessSrc');
                            setFieldTouched(`businessSrc`, true);
                        }}
                        onChange={option => {
                            handleChange('businessSrc');
                            setFieldValue('businessSrc', option);
                        }}
                        onCreateOption={option => {
                            handleChange('businessSrc');
                            setFieldValue('businessSrc', {
                                value: option,
                                label: option
                            });
                        }}
                        touched={touched.businessSrc}
                        invalid={!!errors.businessSrc && touched.businessSrc}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 offset-2 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">{commission_label}</Label>
                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50,
                                        cursor: 'pointer'
                                    }}
                                    name="commissionPercent"
                                    id="commissionPercent"
                                    aria-label={commission_label}
                                    value={values.commissionPercent}
                                    onBlur={() => {
                                        handleBlur('commissionPercent');
                                        setFieldTouched(`commissionPercent`, true);
                                    }}
                                    onClick={() => {
                                        handleChange(`commissionPercent`);
                                        setFieldValue(
                                            `commissionPercent`,
                                            !values.commissionPercent
                                        );
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!errors.commission && touched.commission
                                                    ? '#de4437'
                                                    : ''
                                        }}
                                    >
                                        <span
                                            className={`commissionTypeIcon fas ${
                                                values.commissionPercent
                                                    ? 'fa-percentage'
                                                    : 'fa-dollar-sign'
                                            }`}
                                        />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="commission"
                                    id="commission"
                                    placeholder={commission_placeholder}
                                    aria-label={commission_label}
                                    value={values.commission}
                                    error={errors.commission}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    invalid={!!errors.commission}
                                    hasDefaultFill
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">{split_label}</Label>
                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50,
                                        cursor: 'pointer'
                                    }}
                                    name="splitPercent"
                                    id="splitPercent"
                                    aria-label={split_label}
                                    value={values.splitPercent}
                                    onBlur={handleBlur}
                                    onClick={() => {
                                        handleChange('splitPercent');
                                        setFieldValue(
                                            `splitPercent`,
                                            !values.splitPercent
                                        );
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!errors.split && touched.split
                                                    ? '#de4437'
                                                    : ''
                                        }}
                                    >
                                        <span
                                            className={`commissionTypeIcon fas ${
                                                values.splitPercent
                                                    ? 'fa-percentage'
                                                    : 'fa-dollar-sign'
                                            }`}
                                        />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="split"
                                    id="split"
                                    placeholder={split_placeholder}
                                    aria-label={split_label}
                                    error={errors.split}
                                    value={values.split}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    invalid={!!errors.split}
                                    hasDefaultFill
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mb-3">
                    <div className="form-group">
                        <div className="js-focus-state">
                            <Label className="form-label">{fee_label}</Label>
                            <div
                                className="input-group"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap'
                                }}
                            >
                                <div
                                    className="input-group-prepend"
                                    style={{
                                        maxHeight: 50,
                                        cursor: 'pointer'
                                    }}
                                    name="feePercent"
                                    id="feePercent"
                                    aria-label={fee_label}
                                    value={values.feePercent}
                                    onBlur={handleBlur}
                                    onClick={() => {
                                        handleChange('feePercent');
                                        setFieldValue(`feePercent`, !values.feePercent);
                                    }}
                                >
                                    <span
                                        className="input-group-text"
                                        id="listingAddressLabel"
                                        style={{
                                            borderColor:
                                                !!errors.fee && touched.fee
                                                    ? '#de4437'
                                                    : ''
                                        }}
                                    >
                                        <span
                                            className={`commissionTypeIcon fas ${
                                                values.feePercent
                                                    ? 'fa-percentage'
                                                    : 'fa-dollar-sign'
                                            }`}
                                        />
                                    </span>
                                </div>
                                <TextField
                                    type="number"
                                    myStyle={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        flexGrow: 1
                                    }}
                                    className="form-control"
                                    name="fee"
                                    id="fee"
                                    placeholder={fee_placeholder}
                                    aria-label={fee_label}
                                    error={errors.fee}
                                    value={values.fee}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    invalid={!!errors.fee}
                                    hasDefaultFill
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditTrxDetailsFormContent;
