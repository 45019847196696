// React
import React from 'react';

// Components
import { Container, Col } from 'reactstrap';
import WarningIcon from '../assets/svg/Warning.svg';

//Router
import * as routes from '../router/config/routes';
import { Link } from 'react-router-dom';

// Hooks
import { useLang } from '../context/LangContext';

const Page404 = () => {
    const { page_title, page_subtitle, startOver_button } = useLang()['Page404'];

    return (
        <Container className="space-4">
            <Col className="d-flex flex-column justify-items-center align-items-center">
                <img
                    className="row js-svg-injector max-width-35 mb-3"
                    src={WarningIcon}
                    alt="Warning"
                />
                <h1
                    className="display-3"
                    style={{
                        fontWeight: '600'
                    }}
                >
                    {page_title}
                </h1>
                <p style={{ fontSize: '1.2rem' }}>{page_subtitle}</p>
                <Link
                    to={routes.AUTHENTICATED}
                    className="btn btn-primary transition-3d-hover"
                >
                    {startOver_button}
                </Link>
            </Col>
        </Container>
    );
};

export default Page404;
