import {
    SET_PIPELINE_CONTACTS,
    ADD_NEW_PIPELINE_CONTACT,
    ADD_NEW_PIPELINE_CONTACT_SUCCESS,
    ADD_NEW_PIPELINE_CONTACT_FAILURE,
    EDIT_PIPELINE_CONTACT,
    REMOVE_PIPELINE_CONTACT,
    REMOVE_PIPELINE_CONTACT_SUCCESS,
    REMOVE_PIPELINE_CONTACT_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    loading: null,
    pipelineContacts: [],
    error: null
};

const pipelineReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PIPELINE_CONTACTS:
            return {
                ...state,
                pipelineContacts: action.payload
            };
        case ADD_NEW_PIPELINE_CONTACT:
            return {
                ...state,
                loading: true,
                error: INITIAL_STATE.error
            };
        case ADD_NEW_PIPELINE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: INITIAL_STATE.error
            };
        case ADD_NEW_PIPELINE_CONTACT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case EDIT_PIPELINE_CONTACT:
            return {
                ...state,
                loading: true,
                error: INITIAL_STATE.error
            };
        case REMOVE_PIPELINE_CONTACT:
            return {
                ...state,
                loading: true,
                error: INITIAL_STATE.error
            };
        case REMOVE_PIPELINE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: INITIAL_STATE.error
            };
        case REMOVE_PIPELINE_CONTACT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default pipelineReducer;
