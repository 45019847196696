// React
import React, { useState, useCallback, useRef, useEffect } from 'react';

// Components
import { Loader } from '../../components';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const AvatarEditModal = ({
    setSource,
    source,
    toggleModal,
    loading,
    avatarModal,
    uploadAvatar,
    newUser,
    setFieldValue
}) => {
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [crop, setCrop] = useState({ unit: 'px', width: 150, aspect: 1, x: 75, y: 75 });
    const [maxCrop, setMaxCrop] = useState(null);
    const { change_avatar_label, update_button_label, cancel_button_label } =
        useLang()['Common']['AvatarEditModal'];

    const onLoad = useCallback(
        img => {
            imgRef.current = img;
            setMaxCrop({
                width: img.height < 450 ? img.height : 450,
                height: img.width < 450 ? img.width : 450
            });
        },
        [setMaxCrop]
    );

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    const onCancel = () => {
        toggleModal({ reset: false, cancel: true });
    };
    const onClosed = () => {
        setSource(null);
        setMaxCrop(null);
        setCompletedCrop(null);
        setCrop({ unit: 'px', width: 150, aspect: 1, x: 75, y: 75 });
    };

    const handleUploadAvatar = (canvas, crop, func) => {
        if (newUser) {
            uploadAvatar(canvas, crop, func);
        } else {
            uploadAvatar(canvas, crop);
        }
    };

    return (
        <div>
            <Modal
                isOpen={avatarModal}
                toggle={toggleModal}
                onClosed={onClosed}
                contentClassName="avatar-edit-modal"
                className="avatar-edit-modal"
            >
                <ModalHeader toggle={onCancel}>{change_avatar_label}</ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            width: '500px',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div className="mt-3">
                            <ReactCrop
                                src={source}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={c => setCrop(c)}
                                onComplete={c => setCompletedCrop(c)}
                                circularCrop={true}
                                maxWidth={maxCrop?.width}
                                maxHeight={maxCrop?.height}
                                minWidth={150}
                                minHeight={150}
                                keepSelection={maxCrop ? true : false}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                backgroundColor: 'red',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '2vh',
                                width: '125px',
                                height: '125px',
                                borderRadius: '50%'
                            }}
                        >
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    width: '160px',
                                    borderRadius: '50%'
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {loading && <Loader />}
                    <button className="btn btn-link text-danger" onClick={onCancel}>
                        {cancel_button_label}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => {
                            handleUploadAvatar(
                                previewCanvasRef.current,
                                completedCrop,
                                setFieldValue
                            );
                        }}
                    >
                        {update_button_label}
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AvatarEditModal;
