// React
import React, { useState, useEffect, useCallback } from 'react';

// Components
import {
    DetailsHeader,
    TransactionMeta,
    TransactionInterface,
    Tasks,
    Loader,
    DocumentView,
    AppHeader
} from '../components';

// Firebase
import { storage } from '../config/Firebase';

// Hooks
import { useWindowSize } from '../hooks/useWindowSize';

// Loggers
import { log } from '../utils/Loggers';

// Packages
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { isTablet } from 'react-device-detect';

// Redux - Actions, Reducers, Sagas
import {
    gettingTransactionDetails,
    resetDetailsFlag,
    cancelDetailsListener
} from '../store/actions/Transactions';

// Utils
import { headerTypes } from '../utils/Constants';
import { makePossessiveCase } from '../utils/Helpers';

const MemberTransactionsDetails = ({
    match,
    history,
    transactionDetails,
    gettingTransactionDetails,
    detailsFlag,
    resetDetailsFlag,
    cancelDetailsListener,
    transactionActivity,
    userData,
    companyContacts
}) => {
    const [tabletDevice, setTabletDevice] = useState(isTablet);
    const [imgByIds, setImgByIds] = useState([]);
    const [documentView, setDocumentView] = useState(null);
    const { windowHeight, windowWidth } = useWindowSize();
    const [memberName, setMemberName] = useState(null);
    const transaction = transactionDetails;
    const activity = transactionActivity;
    const location = useLocation();

    const getMemberTransactionDetails = useCallback(
        ({ contacts, location }) => {
            const pathname = location.pathname;
            const urlTrxId = pathname.substr(pathname.lastIndexOf('/') + 1).split('-')[1];
            const urlMemberId = pathname
                .substr(pathname.lastIndexOf('/') + 1)
                .split('-')[0];
            const locStateTrxId = location.state?.id;
            const locStateMemberName = location.state?.memberName;
            const trxId = locStateTrxId || urlTrxId;

            const getMemberName = id => {
                const member = contacts.filter(member => member.id === id)[0];
                return makePossessiveCase(`${member.first_name} ${member.last_name}`);
            };

            const formattedName = locStateMemberName || getMemberName(urlMemberId);

            gettingTransactionDetails({
                trxId,
                detailsFlag: false
            });

            setMemberName(formattedName);
        },
        [gettingTransactionDetails]
    );

    useEffect(() => {
        if (location && companyContacts?.length && !transactionDetails)
            getMemberTransactionDetails({
                contacts: companyContacts,
                location: location
            });
    }, [getMemberTransactionDetails, location, companyContacts, transactionDetails]);

    useEffect(() => {
        if (detailsFlag) {
            resetDetailsFlag();
        }
    }, [detailsFlag, resetDetailsFlag]);

    useEffect(() => {
        setTabletDevice(isTablet);
    }, [windowHeight, windowWidth]);

    const getUserAvatars = ids => {
        const imgObjArr = [];
        ids.forEach(id => {
            if (id) {
                const ref = storage.ref().child(`users/${id}/avatar.jpg`);
                ref.getDownloadURL()
                    .then(imgUrl => {
                        imgObjArr.push({ id, url: imgUrl });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                    })
                    .catch(error => {
                        imgObjArr.push({ id, url: null, err: error.code_ });
                        if (imgObjArr.length === ids.length) {
                            setImgByIds(imgObjArr);
                        }
                        log('Transaction Details Avatar: error in getting avatar (FBS)', {
                            error,
                            storage_path: `users/${id}/avatar.jpg`,
                            function: 'getUserAvatars'
                        });
                    });
            } else {
                imgObjArr.push({ id: null, url: null });
            }
        });
    };

    useEffect(() => {
        if (transaction?.avatars) {
            getUserAvatars([...transaction.avatars]);
        }
    }, [transaction]);

    useEffect(() => {
        return () => {
            cancelDetailsListener();
        };
    }, [cancelDetailsListener]);

    if (!transaction || !transactionActivity) return <Loader />;
    return (
        <>
            {documentView && (
                <DocumentView
                    setDocumentView={setDocumentView}
                    documentView={documentView}
                />
            )}
            <AppHeader
                match={match}
                history={history}
                shouldHideTabs={true}
                adminBanner
                memberName={memberName}
            />
            <DetailsHeader
                headerType={headerTypes.memberTransactionDetails}
                transaction={transaction}
                updateTransactionStatus={null}
                userData={userData}
                numberOfUserTransactions={0}
            />
            <main
                id="content"
                role="main"
                className="push-main-lg"
                style={{ paddingTop: '212px' }}
            >
                <div className="bg-light">
                    <div id="main" className="size container pt-3 pt-xl-4">
                        {tabletDevice ? (
                            <>
                                {windowWidth >= 1366 ? (
                                    <>
                                        <div className="row no-gutters">
                                            <TransactionMeta
                                                transaction={transaction}
                                                avatars={imgByIds}
                                                isClient={false}
                                                companyContacts={companyContacts}
                                                isMember={true}
                                            />
                                            <TransactionInterface
                                                transaction={transaction}
                                                activity={activity}
                                                match={match}
                                                avatars={imgByIds}
                                                allDocs={[]}
                                                userData={userData}
                                                addDocumentToTrx={null}
                                                removeDocumentFromTrx={null}
                                                setDocumentView={setDocumentView}
                                                documentFilter={null}
                                                settingTrxDocumentsFilter={null}
                                                filteredDocs={[]}
                                                isMember={true}
                                            />
                                            <Tasks
                                                transaction={transaction}
                                                avatars={imgByIds}
                                                isMember={true}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className="no-gutters"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: 1
                                        }}
                                    >
                                        <TransactionMeta
                                            transaction={transaction}
                                            avatars={imgByIds}
                                            isClient={false}
                                            companyContacts={companyContacts}
                                            isMember={true}
                                        />
                                        <div className="row no-gutters">
                                            <TransactionInterface
                                                transaction={transaction}
                                                activity={activity}
                                                match={match}
                                                avatars={imgByIds}
                                                allDocs={[]}
                                                userData={userData}
                                                addDocumentToTrx={null}
                                                removeDocumentFromTrx={null}
                                                setDocumentView={setDocumentView}
                                                documentFilter={null}
                                                settingTrxDocumentsFilter={null}
                                                filteredDocs={[]}
                                                isMember={true}
                                            />
                                            <Tasks
                                                transaction={transaction}
                                                avatars={imgByIds}
                                                isMember={true}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="row no-gutters">
                                <TransactionMeta
                                    transaction={transaction}
                                    avatars={imgByIds}
                                    isClient={false}
                                    companyContacts={companyContacts}
                                    isMember={true}
                                />
                                <TransactionInterface
                                    transaction={transaction}
                                    activity={activity}
                                    match={match}
                                    avatars={imgByIds}
                                    allDocs={[]}
                                    userData={userData}
                                    addDocumentToTrx={null}
                                    removeDocumentFromTrx={null}
                                    setDocumentView={setDocumentView}
                                    documentFilter={null}
                                    settingTrxDocumentsFilter={null}
                                    filteredDocs={[]}
                                    isMember={true}
                                />
                                <Tasks
                                    transaction={transaction}
                                    avatars={imgByIds}
                                    isMember={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = ({ transactions, activity, user, contacts }) => {
    const { userData } = user;
    const { companyContacts } = contacts;
    const { transactionDetails, detailsFlag } = transactions;
    const { transactionActivity } = activity;
    return {
        userData,
        transactionDetails,
        detailsFlag,
        transactionActivity,
        companyContacts
    };
};

export default withRouter(
    connect(mapStateToProps, {
        gettingTransactionDetails,
        resetDetailsFlag,
        cancelDetailsListener
    })(MemberTransactionsDetails)
);
