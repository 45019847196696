// React
import React, { useMemo } from 'react';

// Components
import { DocumentCard, DocumentRow } from '../../components';
import ConfirmationDialog from '../common/ConfirmationDialog';

// Packages
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Assets
import TAB from '../../assets/svg/tab.svg';

// Redux
import {
    removeUserDocument,
    removeUserDocumentsPack
} from '../../store/actions/Documents';

// Constants
import {
    confirmationDialogTypes,
    documentsTabs,
    documentsPackTabs,
    userTypes
} from '../../utils/Constants';
import { sortListByAlphabet } from '../../utils/Helpers';

// Context
import { useLang } from '../../context/LangContext';

// Router
import * as routes from '../../router/config/routes';
import { useBasePath } from '../../hooks/useBasePath';

const DocumentsGrid = ({
    match,
    filteredUserDocuments,
    filteredSystemDocuments,
    filteredPackDocuments,
    filteredOrgDocuments,
    packDocFilterType,
    documentList,
    libraryList,
    systemList,
    searchSuggestions,
    userData,
    removeUserDocument,
    removeUserDocumentsPack,
    selectedDocuments,
    handleAddDocToPack,
    activeTab,
    packRoute,
    userType,
    availablePackTab,
    setFieldValue
}) => {
    const { no_documents_label, create_pack_label } =
        useLang()['Documents']['DocumentsGrid'];
    const basePath = useBasePath();
    const libraryPage =
        `${routes.AUTHENTICATED}${routes.NEW_ORG_PACK}` === basePath ||
        `${routes.AUTHENTICATED}${routes.EDIT_ORG_PACK}` === basePath;

    const library = activeTab === documentsTabs.formsLibrary;
    const myDocuments = activeTab === documentsTabs.myDocuments;
    const sysDocuments = activeTab === documentsTabs.formsMarketplace;
    const avaliableList = availablePackTab === documentsPackTabs.avaliableDocs;
    const dragDisable =
        !!searchSuggestions.length || packDocFilterType.value !== 'all' || avaliableList;

    const documents = useMemo(() => {
        let allDocuments;
        if (searchSuggestions.length) {
            const searchSuggestionsDocuments = searchSuggestions.map(
                suggestion => suggestion.document
            );
            if (handleAddDocToPack) {
                if (avaliableList) {
                    allDocuments = searchSuggestionsDocuments
                        .filter(el => !libraryPage || el.section === 'library')
                        .filter(
                            el =>
                                !selectedDocuments.find(selected => selected.id === el.id)
                        );
                } else {
                    allDocuments = searchSuggestionsDocuments
                        .filter(el =>
                            selectedDocuments.find(selected => selected.id === el.id)
                        )
                        .filter(
                            doc =>
                                doc.upload_type === packDocFilterType.value ||
                                packDocFilterType.value === 'all'
                        );
                }
            } else {
                allDocuments = searchSuggestionsDocuments;
            }
        } else if (handleAddDocToPack) {
            if (availablePackTab === documentsPackTabs.avaliableDocs) {
                allDocuments = filteredPackDocuments
                    .filter(el => !libraryPage || el.section === 'library')
                    .filter(
                        el => !selectedDocuments.find(selected => selected.id === el.id)
                    );
            } else {
                return selectedDocuments
                    .filter(
                        doc =>
                            doc.upload_type === packDocFilterType.value ||
                            packDocFilterType.value === 'all'
                    )
                    .sort((a, b) => a.position - b.position);
            }
        } else {
            switch (activeTab) {
                case documentsTabs.formsLibrary:
                    allDocuments = filteredOrgDocuments;
                    break;
                case documentsTabs.formsMarketplace:
                    allDocuments = filteredSystemDocuments;
                    break;
                default:
                    allDocuments = filteredUserDocuments;
                    break;
            }
        }
        return sortListByAlphabet(allDocuments);
    }, [
        searchSuggestions,
        activeTab,
        filteredUserDocuments,
        filteredSystemDocuments,
        filteredOrgDocuments,
        filteredPackDocuments,
        handleAddDocToPack,
        selectedDocuments,
        avaliableList,
        packDocFilterType,
        availablePackTab,
        libraryPage
    ]);

    const handleOnDragEnd = dropedItem => {
        if (!dropedItem.destination) return;
        const items = Array.from(documents);
        const [reorderedItem] = items.splice(dropedItem.source.index, 1);
        items.splice(dropedItem.destination.index, 0, reorderedItem);

        const updatedItems = items.map((item, index) => ({ ...item, position: index }));
        setFieldValue('selectedDocs', updatedItems);
    };

    return (
        <>
            {!handleAddDocToPack && (
                <ConfirmationDialog
                    initialModalDialogType={confirmationDialogTypes.delete}
                />
            )}
            <div className="mb-4" style={{ width: '100%' }}>
                {documents && documents.length ? (
                    <>
                        {(libraryList === 'grid' && library) ||
                        (systemList === 'grid' && sysDocuments) ||
                        (documentList === 'grid' && myDocuments) ? (
                            <div
                                className={
                                    documents.length > 3
                                        ? 'card-grid'
                                        : 'card-grid-single'
                                }
                            >
                                {!handleAddDocToPack &&
                                    (myDocuments || userType === userTypes.broker) && (
                                        <Link
                                            className="card mb-5 d-flex align-items-center justify-content-center"
                                            to={packRoute}
                                        >
                                            <img
                                                src={TAB}
                                                className="card-img-top mb-3 w-100"
                                                style={{
                                                    width: '75%',
                                                    height: '15%'
                                                }}
                                                alt="create pack"
                                            />
                                            {create_pack_label}
                                        </Link>
                                    )}
                                {documents.map(doc => (
                                    <div className="card mb-5" key={doc.id}>
                                        <DocumentCard
                                            handleAddDocToPack={handleAddDocToPack}
                                            match={match}
                                            document={doc}
                                            removeUserDocument={removeUserDocument}
                                            removeUserDocumentsPack={
                                                removeUserDocumentsPack
                                            }
                                            availablePackTab={availablePackTab}
                                            library={library}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable
                                        droppableId="document-list"
                                        direction="vertical"
                                    >
                                        {provided => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {documents.map((doc, index) => (
                                                    <Draggable
                                                        key={doc.id}
                                                        draggableId={doc.id}
                                                        index={index}
                                                        isDragDisabled={dragDisable}
                                                    >
                                                        {provided => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <DocumentRow
                                                                    handleAddDocToPack={
                                                                        handleAddDocToPack
                                                                    }
                                                                    match={match}
                                                                    key={doc.id}
                                                                    document={doc}
                                                                    lang={
                                                                        userData?.active_language_id
                                                                    }
                                                                    removeUserDocument={
                                                                        removeUserDocument
                                                                    }
                                                                    removeUserDocumentsPack={
                                                                        removeUserDocumentsPack
                                                                    }
                                                                    availablePackTab={
                                                                        availablePackTab
                                                                    }
                                                                    dragDisable={
                                                                        dragDisable
                                                                    }
                                                                    library={library}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </>
                        )}
                    </>
                ) : (
                    <Alert color="info" className="mb-0">
                        {no_documents_label}
                    </Alert>
                )}
            </div>
        </>
    );
};

const mapStateToProps = ({ documents, user }) => {
    const {
        filteredUserDocuments,
        filteredOrgDocuments,
        filteredSystemDocuments,
        filteredPackDocuments,
        packDocFilterType,
        libraryList,
        documentList,
        systemList,
        searchSuggestions
    } = documents;
    const { userData, type } = user;
    return {
        filteredUserDocuments,
        filteredOrgDocuments,
        filteredSystemDocuments,
        filteredPackDocuments,
        packDocFilterType,
        libraryList,
        documentList,
        systemList,
        searchSuggestions,
        userData,
        userType: type
    };
};

export default withRouter(
    connect(mapStateToProps, {
        removeUserDocument,
        removeUserDocumentsPack
    })(DocumentsGrid)
);
