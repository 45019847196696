// React
import React from 'react';

// Components
import { ActionButtons } from '../../components';

// Packages
import { useHistory } from 'react-router-dom';

// Router
import * as routes from '../../router/config/routes';

const ConfirmInterface = ({ lang, orgName }) => {
    const {
        confirm_heading,
        confirm_description_1,
        confirm_description_2,
        confirm_button_label
    } = lang;

    const history = useHistory();

    const handleSubmit = () => {
        history.replace({
            pathname: `${routes.AUTHENTICATED}${routes.DASHBOARD}`
        });
    };

    return (
        <div
            className="mt-5"
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <div className="card-body mb-10">
                <div className="col d-flex flex-column align-items-center">
                    <div className="row">
                        <h1 className="h2 font-weight-medium">{confirm_heading}</h1>
                    </div>
                    <div className="row justify-content-center mb-10">
                        <span
                            style={{
                                fontSize: 16,
                                color: '#8C98A4',
                                width: '70%',
                                textAlign: 'center'
                            }}
                        >
                            {confirm_description_1}
                            <span style={{ fontWeight: 'bold' }}>
                                {orgName}
                                <span style={{ fontWeight: 'normal' }}>
                                    {confirm_description_2}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <ActionButtons
                cancelLabel={null}
                saveLabel={confirm_button_label}
                handleSubmit={handleSubmit}
                disabled={false}
            />
        </div>
    );
};

export default ConfirmInterface;
