import {
    UPDATE_USER_AVATAR,
    UPDATE_USER_AVATAR_SUCCESS,
    UPDATE_USER_TEAM,
    UPDATE_ORG_TEAM,
    CREATE_USER_TEAM,
    UPDATE_USER_AVATAR_FAILURE,
    UPDATE_PERSONAL_INFO,
    UPDATE_PERSONAL_INFO_SUCCESS,
    UPDATE_PERSONAL_INFO_FAILURE
} from './types';

export const uploadUserAvatar = img => {
    return {
        type: UPDATE_USER_AVATAR,
        payload: img
    };
};

export const uploadUserAvatarSuccess = () => {
    return {
        type: UPDATE_USER_AVATAR_SUCCESS
    };
};

export const updateUserTeam = team => {
    return {
        payload: team,
        type: UPDATE_USER_TEAM
    };
};

export const updateOrgTeam = team => {
    return {
        payload: team,
        type: UPDATE_ORG_TEAM
    };
};

export const uploadUserAvatarFailure = error => {
    return {
        type: UPDATE_USER_AVATAR_FAILURE,
        payload: error
    };
};

export const updatePersonalInfo = info => {
    return {
        type: UPDATE_PERSONAL_INFO,
        payload: info
    };
};

export const personalInfoUpdateSuccess = () => {
    return {
        type: UPDATE_PERSONAL_INFO_SUCCESS
    };
};

export const personalInfoUpdateFailure = error => {
    return {
        type: UPDATE_PERSONAL_INFO_FAILURE,
        payload: error
    };
};

export const createUserTeam = team => {
    return {
        payload: team,
        type: CREATE_USER_TEAM
    };
};
