// React
import React, { useCallback, useEffect } from 'react';

// Components
import { TextField, AvatarEditModal } from '../../components';
import { Label } from 'reactstrap';

// Context
import { useLang } from '../../context/LangContext';

// Packages
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';

const TrxInvRegistrarionForm = ({
    errors,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    longEnough,
    hasUppercase,
    hasNumber,
    hasSpecial,
    source,
    setSource,
    avatarModal,
    setAvatarModal,
    avatar,
    setAvatar,
    activeUpload,
    setActiveUpload,
    pristine,
    setPristine,
    maxSize
}) => {
    const lang = useLang()['TrxInvitationAcceptance']['TrxInvRegistrarionForm'];
    const toggleModal = useCallback(
        ({ reset, cancel }) => {
            if (reset || cancel) {
                setActiveUpload(false);
                setAvatarModal(upload => !upload);
            } else {
                setAvatarModal(upload => !upload);
            }
        },
        [setActiveUpload, setAvatarModal]
    );

    useEffect(() => {
        if (activeUpload) {
            if (!pristine) {
                toggleModal({ reset: true, cancel: false });
            }
        } else {
            setPristine(true);
        }
    }, [activeUpload, pristine, setPristine, toggleModal]);

    const onDrop = useCallback(
        acceptedFiles => {
            if (acceptedFiles.length) {
                const url = URL.createObjectURL(acceptedFiles[0]);
                setSource(url);
                setActiveUpload(true);
                toggleModal({ reset: false, cancel: false });
            }
        },
        [setSource, setActiveUpload, toggleModal]
    );

    const uploadAvatar = (canvas, crop, setFieldValue) => {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            blob => {
                const url = URL.createObjectURL(blob);
                setFieldValue('avatar', url);
                setAvatar(url);
                setPristine(false);
            },
            'image/jpeg',
            0.25
        );
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg',
        minSize: 0,
        maxSize
    });

    const isFileTooLarge =
        fileRejections?.length > 0 && fileRejections[0].file.size > maxSize;
    return (
        <div
            style={{
                animation: 'fadeIn .5s'
            }}
        >
            <AvatarEditModal
                source={source}
                setSource={setSource}
                avatarModal={avatarModal}
                toggleModal={toggleModal}
                uploadAvatar={uploadAvatar}
                setFieldValue={setFieldValue}
                newUser={true}
            />
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.first_name_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder={lang?.first_name_placeholder}
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        error={errors.firstName}
                        invalid={touched.firstName && !!errors.firstName}
                    />
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.last_name_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder={lang?.last_name_placeholder}
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        error={errors.lastName}
                        invalid={touched.lastName && !!errors.lastName}
                    />
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label">{lang?.full_name_label}</Label>
                    <TextField
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="fullName"
                        id="fullName"
                        placeholder={lang?.full_name_placeholder}
                        value={values.fullName}
                        onChange={handleChange('fullName')}
                        onBlur={handleBlur('fullName')}
                        error={errors.fullName}
                        invalid={touched.fullName && !!errors.fullName}
                    />
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label text-secondary">
                        {lang?.email_label}
                    </Label>
                    <TextField
                        disabled
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder={lang?.email_placeholder}
                        value={values.email}
                        onChange={handleChange('email')}
                    />
                    <p className="small mt-1">{lang?.email_helper_text}</p>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label">{lang?.phone_label}</Label>
                    <TextField
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder={lang?.phone_placeholder}
                        value={values.phone}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                        error={errors.phone}
                        invalid={touched.phone && !!errors.phone}
                    />
                </div>
                <div className="col-4 js-form-message form-group">
                    <Label className="form-label required-mark">
                        {lang?.password_label}
                    </Label>
                    <TextField
                        required
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder={lang?.password_placeholder}
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        error={errors.password}
                        invalid={touched.password && !!errors.password}
                    />
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div
                    className="col-2 js-form-message form-group d-flex flex-column align-items-center"
                    {...getRootProps()}
                >
                    <Label className="form-label align-self-start">
                        {lang?.profile_picture_label}
                    </Label>
                    <label
                        htmlFor="upload"
                        className="upload-picture__container d-flex justify-content-center align-items-center"
                    >
                        <input {...getInputProps()} />
                        {avatar ? (
                            <img
                                src={avatar}
                                alt="Uploaded Profile"
                                className="upload-picture"
                            />
                        ) : (
                            <span className="upload-picture__icon">
                                <i className="fa-solid fa-user-astronaut fa-4x pb-3" />
                            </span>
                        )}
                    </label>
                    <div className="upload-picture__label d-flex position-relative justify-content-center align-items-center">
                        <span>{lang?.upload_label}</span>
                    </div>
                    {isFileTooLarge && (
                        <div className="text-center text-danger text-xsmall">
                            {lang?.file_size_warning}
                        </div>
                    )}
                </div>

                <div className="offset-2 col-4 requirements mt-1">
                    <div
                        className={cx(
                            'row d-flex align-items-center mb-1 pl-3 requirements__item',
                            longEnough && 'requirements__item--completed'
                        )}
                    >
                        <i className="fa-solid fa-check requirements__check-icon" />
                        <p className="mb-0 pl-2 requirements__item-text">
                            {lang?.length_helper_text}
                        </p>
                    </div>
                    <div
                        className={cx(
                            'row d-flex align-items-center mb-1 pl-3 requirements__item',
                            hasUppercase && 'requirements__item--completed'
                        )}
                    >
                        <i className="fa-solid fa-check requirements__check-icon" />
                        <p className="mb-0 pl-2 requirements__item-text">
                            {lang?.uppercase_helper_text}
                        </p>
                    </div>
                    <div
                        className={cx(
                            'row d-flex align-items-center mb-1 pl-3 requirements__item',
                            hasNumber && 'requirements__item--completed'
                        )}
                    >
                        <i className="fa-solid fa-check requirements__check-icon" />
                        <p className="mb-0 pl-2 requirements__item-text">
                            {lang?.number_helper_text}
                        </p>
                    </div>
                    <div
                        className={cx(
                            'row d-flex align-items-center mb-1 pl-3 requirements__item',
                            hasSpecial && 'requirements__item--completed'
                        )}
                    >
                        <i className="fa-solid fa-check requirements__check-icon" />
                        <p className="mb-0 pl-2 requirements__item-text">
                            {lang?.symbol_helper_text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrxInvRegistrarionForm;
