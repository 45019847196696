// React
import React, { useEffect, useState } from 'react';

// Components
import { Loader } from '../components';

// Packages
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Pages
import {
    CompanyContacts,
    ConnectionDetails,
    ContactDetails,
    Dashboard,
    EditTransaction,
    FormFilling,
    FormSigning,
    InvitationConfirmation,
    MemberTransactions,
    Messages,
    NewTransaction,
    NewDocument,
    NewPack,
    NewPipeline,
    NewConnection,
    PdfViewer,
    PipelineDetails,
    Preferences,
    Settings,
    TransactionDetails
} from '../pages';

//Router
import * as routes from '../router/config/routes';

// Utils
import { userTypes } from '../utils/Constants';
import MemberTransactionDetail from '../pages/MemberTransactionsDetails';

const AuthenticatedRouter = ({
    userTransactions,
    userData,
    userType,
    activeOrg,
    userInvitation,
    trxInvitationDetails,
    documentSigningRequestDetails,
    ...props
}) => {
    const [redirect, setRedirect] = useState(null);
    const [mount, setMount] = useState(false);
    const authorized = userType === userTypes.broker || userType === userTypes.owner;
    const super_authorized = userType === userTypes.super;

    useEffect(() => {
        if (!mount) {
            if (documentSigningRequestDetails) {
                setMount(true);
                setRedirect(
                    `${routes.AUTHENTICATED}${routes.FORM_SIGNING}/${documentSigningRequestDetails.docId}`
                );
            } else if (trxInvitationDetails) {
                setMount(true);
                setRedirect(routes.ACCEPT_TEAM_INVITATION);
            } else if (userInvitation) {
                if (userInvitation.existing) {
                    setMount(true);
                    setRedirect(`${props.match.url}${routes.INVITATION_CONFIRMATION}`);
                } else {
                    setMount(true);
                    setRedirect(routes.INVITATION_ERROR);
                }
            } else if (userType) {
                if (authorized) {
                    setMount(true);
                    setRedirect(
                        activeOrg?.member_count === 1
                            ? `${props.match.url}${routes.DASHBOARD}`
                            : `${props.match.url}${routes.DASHBOARD}${routes.ORGANIZATION}`
                    );
                } else if (userType === userTypes.client) {
                    if (userTransactions) {
                        setMount(true);
                        setRedirect(
                            userTransactions && userTransactions?.length === 1
                                ? `${props.match.url}${routes.TRANSACTION_DETAIL}/${userTransactions[0].id}`
                                : `${props.match.url}${routes.DASHBOARD}`
                        );
                    }
                } else {
                    setMount(true);
                    setRedirect(`${props.match.url}${routes.DASHBOARD}`);
                }
            }
        }
    }, [props, userType, userTransactions, activeOrg, mount, userInvitation, trxInvitationDetails, authorized, documentSigningRequestDetails]);

    if (!redirect || !mount) return <Loader />;

    return (
        <Switch>
            <Redirect exact from={`${props.match.url}`} to={redirect} />
            <Route
                path={`${props.match.url}${routes.COMPANY_CONTACTS}`}
                render={props => <CompanyContacts {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.CONNECTION_DETAIL}`}
                render={props => <ConnectionDetails {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.CONTACT_DETAIL}`}
                render={props => <ContactDetails {...props} />}
            />
            <Route
                authUser={props.authUser}
                userType={userType}
                authorized={authorized}
                path={`${props.match?.url}${routes.DASHBOARD}`}
                render={props => (
                    <Dashboard
                        userTransactions={userTransactions}
                        activeOrg={activeOrg}
                        userData={userData}
                        userType={userType}
                        authorized={authorized}
                        {...props}
                    />
                )}
            />
            <Route
                path={`${props.match.url}${routes.EDIT_TRANSACTION}`}
                render={props => <EditTransaction {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.INVITATION_CONFIRMATION}`}
                render={props => (
                    <InvitationConfirmation userData={userData} {...props} />
                )}
            />
            <Route
                path={`${props.match.url}${routes.ADD_PARTY}`}
                render={props => <NewConnection {...props} addingParty />}
            />
            <Route
                path={`${props.match.url}${routes.MESSAGES}`}
                render={props => <Messages {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.CONNECTION_DETAIL}`}
                render={props => <ConnectionDetails {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.NEW_CONNECTION}`}
                render={props => <NewConnection {...props} />}
            />
            <Route
                exact
                path={`${props.match.url}${routes.NEW_DOCUMENT}`}
                render={props => <NewDocument {...props} />}
            />
            {authorized && (
                <Route
                    exact
                    path={`${props.match.url}${routes.NEW_ORG_DOCUMENT}`}
                    render={props => <NewDocument {...props} />}
                />
            )}
            {super_authorized && (
                <Route
                    exact
                    path={`${props.match.url}${routes.NEW_SYSTEM_DOCUMENT}`}
                    render={props => <NewDocument {...props} />}
                />
            )}
            <Route
                exact
                path={`${props.match.url}${routes.NEW_PACK}`}
                render={props => <NewPack {...props} />}
            />
            {authorized && (
                <Route
                    exact
                    path={`${props.match.url}${routes.NEW_ORG_PACK}`}
                    render={props => <NewPack {...props} />}
                />
            )}
            {super_authorized && (
                <Route
                    exact
                    path={`${props.match.url}${routes.NEW_SYSTEM_PACK}`}
                    render={props => <NewPack {...props} />}
                />
            )}
            <Route
                exact
                path={`${props.match.url}${routes.EDIT_PACK_ID}`}
                render={props => <NewPack {...props} />}
            />
            {authorized && (
                <Route
                    exact
                    path={`${props.match.url}${routes.EDIT_ORG_PACK_ID}`}
                    render={props => <NewPack {...props} />}
                />
            )}
            {super_authorized && (
                <Route
                    exact
                    path={`${props.match.url}${routes.EDIT_SYSTEM_PACK_ID}`}
                    render={props => <NewPack {...props} />}
                />
            )}
            <Route
                path={`${props.match.url}${routes.NEW_PIPELINE}`}
                render={props => <NewPipeline {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.NEW_TRANSACTION}`}
                render={props => <NewTransaction {...props} />}
            />
            <Route
                path={[
                    `${props.match.url}${routes.VIEW_DOCUMENT_TRX_ID}`,
                    `${props.match.url}${routes.VIEW_DOCUMENT_ID}`
                ]}
                authorized={authorized}
                superAuthorized={super_authorized}
                render={props => (
                    <PdfViewer
                        {...props}
                        authorized={authorized}
                        superAuthorized={super_authorized}
                    />
                )}
            />
            <Route
                path={`${props.match.url}${routes.PIPELINE_DETAIL}`}
                render={props => <PipelineDetails {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.PREFERENCES}`}
                render={props => <Preferences {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.SETTINGS}`}
                render={props => (
                    <Settings {...props} authorized={authorized || super_authorized} />
                )}
            />
            <Route
                path={`${props.match.url}${routes.TRANSACTION_DETAIL}`}
                render={props => <TransactionDetails {...props} />}
            />
            {(authorized || super_authorized) && (
                <Route
                    path={`${props.match.url}${routes.MEMBER_TRANSACTIONS}${routes.MEMBER_TRANSACTIONS_DETAILS}`}
                    render={props => <MemberTransactionDetail {...props} />}
                />
            )}
            {(authorized || super_authorized) && (
                <Route
                    path={`${props.match.url}${routes.MEMBER_TRANSACTIONS}`}
                    render={props => <MemberTransactions {...props} />}
                />
            )}
            <Route
                path={`${props.match.url}${routes.FORM_FILLING_ID}`}
                render={props => <FormFilling {...props} />}
            />
            <Route
                path={`${props.match.url}${routes.FORM_SIGNING_ID}`}
                render={props => <FormSigning {...props} />}
            />
            <Redirect to={routes.NOT_FOUND} />
        </Switch>
    );
};

const mapStateToProps = ({
    transactions,
    user,
    org,
    invitation,
    trxInvitation,
    documentSigning
}) => {
    const { userTransactions } = transactions;
    const { userData } = user;
    const { activeOrg } = org;
    const { userInvitation } = invitation;
    const { trxInvitationDetails } = trxInvitation;
    const { requestDetails } = documentSigning;

    return {
        userTransactions,
        userData,
        activeOrg,
        userInvitation,
        trxInvitationDetails,
        documentSigningRequestDetails: requestDetails
    };
};

export default withRouter(connect(mapStateToProps)(AuthenticatedRouter));
