import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import SimpleBar from 'simplebar-react';
import cx from 'classnames';

import { useLang } from '../../../context/LangContext';
import { confirmationDialogTypes } from '../../../utils/Constants';

// Components
import StatusDropdown from '../OrganizationInfo/StatusDropdown';
import { MemberCard } from './MemberCard';
import { InviteMembersHeader } from './InviteMembersHeader';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import ActionButtons from '../shared/ActionButtons';
import { getUserAvatars } from '../../../utils/Helpers';

const AddNewMember = ({
    team,
    members,
    invitedUsers,
    companyContacts,
    addMembers,
    setAddMembers,
    setScreen,
    teamScreen,
    addTeamMember
}) => {
    const { teamList, no_available_invite } = useLang()['Settings']['Team'];
    const { invite_title } = useLang()['Settings']['Team'];
    const { save_label, cancel_label } = useLang()['Settings'];

    const contacts = useMemo(
        () =>
            companyContacts
                ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
                .filter(
                    contact =>
                        !members.map(member => member.id).includes(contact.id) &&
                        !invitedUsers.includes(contact.id)
                ),
        [companyContacts, members, invitedUsers]
    );

    // Getting users avatars
    const [imgByIds, setImgByIds] = useState([]);
    const ids = useMemo(
        () => companyContacts.map(member => member.id),
        [companyContacts]
    );

    useEffect(() => {
        getUserAvatars({ ids, setImgByIds });
    }, [ids]);

    const statusesItems = [
        {
            name: teamList?.statuses?.active_label
        },
        {
            name: teamList?.statuses?.inactive_label
        }
    ];

    const handleInvite = member => {
        const exist =
            addMembers && Object.keys(addMembers).find(key => key === member.id);
        if (exist) {
            delete addMembers[member.id];
            setAddMembers({
                ...addMembers
            });
        } else {
            setAddMembers({
                ...addMembers,
                [member.id]: member
            });
        }
    };

    if (!contacts.length)
        return <div className="container pr-0">{no_available_invite}</div>;

    return (
        <>
            <Formik
                initialValues={{ name: members?.name || '' }}
                onSubmit={() => {
                    addTeamMember({
                        team,
                        newMembers: addMembers
                    });

                    setAddMembers({});
                    setScreen(teamScreen.default);
                }}
                enableReinitialize
            >
                {({ handleSubmit, handleReset }) => (
                    <>
                        <ConfirmationDialog
                            modalDialogType={confirmationDialogTypes.success}
                            successHandler={() => {}}
                        />
                        <div className="card-header d-flex align-items-center">
                            <h2 className="h5 mb-0">{invite_title}</h2>
                        </div>
                        <div
                            className="card-body"
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '0'
                            }}
                        >
                            <SimpleBar
                                className="container"
                                style={{ overflowY: 'auto', height: '100%' }}
                            >
                                <>
                                    <InviteMembersHeader
                                        name={teamList?.name_label}
                                        status={teamList?.status_label}
                                        type={teamList?.type_label}
                                    />
                                    <div className="container pr-0">
                                        {contacts?.map(member => (
                                            <div
                                                key={member.id}
                                                className="row d-flex align-items-center w-100 mb-4 pr-0"
                                            >
                                                <div className="col-4">
                                                    <MemberCard
                                                        member={member}
                                                        imgByIds={imgByIds}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <StatusDropdown
                                                        statuses
                                                        editable={false}
                                                        form={{
                                                            values: {
                                                                status: statusesItems[0]
                                                                    .name
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-5 d-flex align-items-center pl-5 justify-content-between">
                                                    <div>{member?.role}</div>
                                                    <i
                                                        className={cx(
                                                            'fa-regular fa-plus removePhoneIcon pointer',
                                                            {
                                                                'removePhoneIcon--active':
                                                                    addMembers &&
                                                                    Object.keys(
                                                                        addMembers
                                                                    ).find(
                                                                        key =>
                                                                            key ===
                                                                            member.id
                                                                    )
                                                            }
                                                        )}
                                                        onClick={() => {
                                                            handleInvite(member);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            </SimpleBar>
                        </div>
                        <ActionButtons
                            disabled={!addMembers}
                            saveLabel={save_label}
                            cancelLabel={cancel_label}
                            handleSubmit={handleSubmit}
                            handleClose={() => {
                                handleReset();
                                if (!addMembers) {
                                    setScreen(teamScreen.default);
                                }
                                setAddMembers(null);
                            }}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};

const mapStateToProps = ({ contacts }) => {
    const { companyContacts } = contacts;
    return { companyContacts };
};

export default connect(mapStateToProps)(AddNewMember);
