import { combineReducers } from 'redux';
import AppReducer from './App';
import AuthReducer from './Auth';
import ConnectionsReducer from './Connections';
import ContactsReducer from './CompanyContacts';
import DocumentFillingReducer from './DocumentFilling';
import DocumentSigningReducer from './DocumentSigning';
import DocumentsReducer from './Documents';
import InitialReducer from './Initial';
import InvitationsReducer from './Invitations';
import LangReducer from './Lang';
import ModalReducer from './Modal';
import OrgReducer from './Org';
import PipelineReducer from './Pipeline';
import PreferencesReducer from './Preferences';
import PropertyReducer from './Property';
import SettingsReducer from './Settings';
import SuperAdminReducer from './SuperAdmin';
import TeamsReducer from './Teams';
import TransactionActivityReducer from './TransactionActivity';
import TrxInvitationsReducer from './TransactionInvitations';
import TransactionsReducer from './Transactions';
import UserReducer from './User';
import { LOGOUT_USER } from '../actions/types';
import MessagesReducer from './Messages';

const appReducer = combineReducers({
    activity: TransactionActivityReducer,
    app: AppReducer,
    auth: AuthReducer,
    connections: ConnectionsReducer,
    contacts: ContactsReducer,
    documents: DocumentsReducer,
    documentFilling: DocumentFillingReducer,
    documentSigning: DocumentSigningReducer,
    init: InitialReducer,
    invitation: InvitationsReducer,
    lang: LangReducer,
    messages: MessagesReducer,
    modal: ModalReducer,
    org: OrgReducer,
    pipeline: PipelineReducer,
    pref: PreferencesReducer,
    property: PropertyReducer,
    settings: SettingsReducer,
    super: SuperAdminReducer,
    transactions: TransactionsReducer,
    trxInvitation: TrxInvitationsReducer,
    teams: TeamsReducer,
    user: UserReducer
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_USER) {
        const { init } = state;
        state = { init };
    }
    return appReducer(state, action);
};

export default rootReducer;
