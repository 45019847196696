// React
import React from 'react';

// Constants
import { phoneRegExp, nameRegExp, streetAddressRegExp } from '../../utils/Constants';

// Context
import { useLang } from '../../context/LangContext';

// Store
import { addNewPipelineContact, editPipelineContact } from '../../store/actions/Pipeline';

// Packages
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Label, Button } from 'reactstrap';

import TextField from '../common/TextField';
import AddressSuggestion from '../common/AddressSuggestion';

const NewPipelineForm = ({
    addNewPipelineContact,
    editPipelineContact,
    userData,
    history,
    contact,
    handleCancel
}) => {
    const { errorMessage } = useLang()['Constants'];
    const {
        first_name_label,
        first_name_placeholder,
        last_name_label,
        last_name_placeholder,
        legal_name_label,
        legal_name_placeholder,
        address_label,
        address_placeholder,
        unit_label,
        unit_placeholder,
        city_label,
        city_placeholder,
        state_label,
        state_placeholder,
        zip_label,
        zip_placeholder,
        email_label,
        email_placeholder,
        phone_label,
        phone_placeholder,
        save_label,
        cancel_label
    } = useLang()['Pipeline']['NewPipelineForm'];

    const initialValues = {
        id: contact?.id || '',
        firstName: contact?.first_name || '',
        lastName: contact?.last_name || '',
        fullLegalName: contact?.legal_name || '',
        email: contact?.email || '',
        address: contact?.address?.address_1 || '',
        address2: contact?.address?.address_2 || '',
        phone: contact?.phone || '',
        city: contact?.address?.city || '',
        state: contact?.address?.state || '',
        zip: contact?.address?.zip || '',
        lat: contact?.address?.lat || '',
        lon: contact?.address?.lon || '',
        notes: contact?.notes || ''
    };

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.firstName.valid),
        lastName: Yup.string()
            .trim()
            .matches(nameRegExp.format, errorMessage.lastName.valid),
        fullLegalName: Yup.string(),
        email: Yup.string().trim().lowercase().email(errorMessage.email.valid),
        phone: Yup.string().trim().matches(phoneRegExp.format, errorMessage.phone.valid),
        address: Yup.string()
            .trim()
            .matches(streetAddressRegExp, errorMessage.address.valid),
        address2: Yup.string().trim(),
        city: Yup.string().trim().matches(nameRegExp.format, errorMessage.city.valid),
        state: Yup.string().trim(),
        zip: Yup.string().trim(),
        lat: Yup.number(),
        lon: Yup.number()
    });

    return (
        <div className="container mt-4">
            <div className="mx-lg-auto">
                <div className="card pt-3 pb-0">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            if (contact) {
                                editPipelineContact({
                                    userData: userData,
                                    contact: {
                                        ...values
                                    }
                                });
                            } else {
                                addNewPipelineContact({
                                    userData: userData,
                                    contact: {
                                        ...values
                                    }
                                });
                            }
                        }}
                        enableReinitialize
                    >
                        {({
                            errors,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldTouched,
                            setFieldValue,
                            dirty,
                            values
                        }) => (
                            <>
                                <Form>
                                    <div
                                        style={{
                                            animation: 'fadeIn .5s'
                                        }}
                                    >
                                        <div className="card-body p-5 w-lg-75 mx-lg-auto">
                                            <div className="row">
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {first_name_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="firstName"
                                                            id="firstName"
                                                            value={values.firstName}
                                                            placeholder={
                                                                first_name_placeholder
                                                            }
                                                            onChange={handleChange(
                                                                'firstName'
                                                            )}
                                                            error={errors.firstName}
                                                            invalid={
                                                                !!errors.firstName &&
                                                                dirty
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {last_name_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="lastName"
                                                            id="lastName"
                                                            value={values.lastName}
                                                            placeholder={
                                                                last_name_placeholder
                                                            }
                                                            onChange={handleChange(
                                                                'lastName'
                                                            )}
                                                            error={errors.lastName}
                                                            invalid={
                                                                !!errors.lastName && dirty
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {legal_name_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="fullLegalName"
                                                            id="fullLegalName"
                                                            value={values.fullLegalName}
                                                            placeholder={
                                                                legal_name_placeholder
                                                            }
                                                            onChange={handleChange(
                                                                'fullLegalName'
                                                            )}
                                                            error={errors.fullLegalName}
                                                            invalid={
                                                                !!errors.fullLegalName &&
                                                                dirty
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {email_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="email"
                                                            id="email"
                                                            value={values.email}
                                                            placeholder={
                                                                email_placeholder
                                                            }
                                                            onChange={handleChange(
                                                                'email'
                                                            )}
                                                            onBlur={e => {
                                                                handleBlur('email');
                                                                setFieldTouched(
                                                                    'email',
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    'email',
                                                                    e.target.value
                                                                        .toLowerCase()
                                                                        .trim()
                                                                );
                                                            }}
                                                            error={errors.email}
                                                            invalid={
                                                                !!errors.email && dirty
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {phone_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="phone"
                                                            id="phone"
                                                            value={values.phone}
                                                            placeholder={
                                                                phone_placeholder
                                                            }
                                                            onChange={handleChange(
                                                                'phone'
                                                            )}
                                                            error={errors.phone}
                                                            invalid={
                                                                !!errors.phone && dirty
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {address_label}
                                                        </Label>
                                                        <AddressSuggestion
                                                            value={values.shortAddress}
                                                            fieldName="shortAddress"
                                                            setFieldTouched={
                                                                setFieldTouched
                                                            }
                                                            setFieldValue={setFieldValue}
                                                            handleChange={handleChange}
                                                            placeholder={
                                                                address_placeholder
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {unit_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="address2"
                                                            id="address2"
                                                            placeholder={unit_placeholder}
                                                            value={values.address2}
                                                            onChange={handleChange(
                                                                'address2'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {city_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="city"
                                                            id="city"
                                                            placeholder={city_placeholder}
                                                            value={values.city}
                                                            onChange={handleChange(
                                                                'city'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {state_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="state"
                                                            id="state"
                                                            placeholder={
                                                                state_placeholder
                                                            }
                                                            value={values.state}
                                                            onChange={handleChange(
                                                                'state'
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mb-0">
                                                    <div className="js-form-message form-group">
                                                        <Label className="form-label">
                                                            {zip_label}
                                                        </Label>
                                                        <TextField
                                                            type="text"
                                                            className="form-control"
                                                            name="zip"
                                                            id="zip"
                                                            placeholder={zip_placeholder}
                                                            value={values.zip}
                                                            onChange={handleChange('zip')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex offset-8 col-4 justify-content-end pr-0">
                                                {contact && (
                                                    <div className="d-flex">
                                                        <button
                                                            className="btn btn-link text-danger mr-4"
                                                            onClick={handleCancel}
                                                            type="button"
                                                        >
                                                            {cancel_label}
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="d-flex">
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        onClick={handleSubmit}
                                                    >
                                                        {save_label}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { userData } = user;
    return { userData };
};

export default connect(mapStateToProps, { addNewPipelineContact, editPipelineContact })(
    NewPipelineForm
);
