import { SET_USER_COLLECTION } from '../actions/types';
import { userTypes } from '../../utils/Constants';

const INITIAL_STATE = {
    userData: null,
    type: null
};

const setType = user => {
    const isMember = () => {
        if (user.type === userTypes.member) {
            if (
                user.orgs.filter(org => org.id === user.active_org_id)[0].sub_type ===
                userTypes.broker
            ) {
                return userTypes.broker;
            } else {
                return userTypes.member;
            }
        } else if (user.type === userTypes.owner) {
            return userTypes.owner;
        } else if (user.type === userTypes.super) {
            return userTypes.super;
        } else {
            return userTypes.client;
        }
    };

    return isMember();
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_COLLECTION:
            return {
                ...state,
                userData: action.payload,
                type: setType(action.payload)
            };
        default:
            return state;
    }
};

export default userReducer;
